import app from "js/legacy-app";

app.directive('plTableHeader', [function () {
    return {
        templateUrl: "template/component/widget/pageable-list/pl-table-header.html",
        replace: true,
        scope: {
            headers: '=thModel'
        },
        link: function ($scope, element, $attrs) {

            $scope.plth = {};

        }
    };
}]);