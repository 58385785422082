import "./landing-page-main-actions.component.scss";

export default {
    template: require('./landing-page-main-actions.component.html'),
    bindings: {
        primaryAction: "<maPrimary",
        secondaryAction: "<maSecondary"
    },
    controller: function () {
        'ngInject';
    }
};
