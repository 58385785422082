import app from "js/legacy-app";

app.service('MainBackgroundStyle', [function () {

    var mainWrapper = document.getElementsByClassName('main')[0];

    var ua = navigator.userAgent.toLowerCase(), oldSafariDetect = false;
    if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {
            oldSafariDetect = false;
        } else {
            oldSafariDetect = true;
        }

        var splitVersion = navigator.appVersion.split("Version/");
        if (splitVersion && splitVersion.length > 1) {
            if (parseInt(splitVersion[1].split(" ")[0]) < 9 && oldSafariDetect) {
                oldSafariDetect = true;
            } else {
                oldSafariDetect = false;
            }
        } else {
            oldSafariDetect = false;
        }
    }

    mainWrapper.classesSiteMap = {
        '/default'          : '',
        '/'                 : 'home-page',
        '/home'             : 'home-page',
        '/dashboard'        : 'dashboard-page',
        '/profile'          : 'profile-page',
        '/add-bike'         : 'add-bike-page',
        '/bike'             : 'show-bike-page'
    };

    return {
        change: function (url, altUrl) {

            var pageStyleClass = mainWrapper.classesSiteMap[url];

            if (!pageStyleClass) {
                pageStyleClass = mainWrapper.classesSiteMap[altUrl];
            }
            if (!pageStyleClass) {
                pageStyleClass = mainWrapper.classesSiteMap['/default'];
            }

            var classNames = 'main ';
            if (oldSafariDetect) {
                classNames += 'safari-min-height-vh ';
            }

            mainWrapper.className = classNames + pageStyleClass;
        }
    };
}]);
