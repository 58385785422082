import app from "js/legacy-app";

app.filter("wheelSize", [function () {
    return function (wheel) {
        if (!wheel) {
            return 'Wheel size is not selected';
        }

        var result = "";
        if (wheel.inch) {
            result += wheel.inch;
            if (wheel.iso || wheel.bicycleStandard) {
                result += " / ";
            }
        }
        if (wheel.iso) {
            result += wheel.iso;
            if (wheel.bicycleStandard) {
                result += " / ";
            }
        }
        if (wheel.bicycleStandard) {
            result += wheel.bicycleStandard;
        }

        return result;
    };
}]);