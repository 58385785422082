import app from "js/legacy-app";
import angular from "angular";

app.directive('checkEmail', ['User', '$timeout', function (User, $timeout) {
    return {
        require: 'ngModel',
        link: function (scope, elem, attr, ngModel) {

            var countCall = 0,
                checkEmail = function (value) {
                    if (!value) {
                        setValidate(true);
                        return;
                    }
                    countCall++;
                    $timeout(function () {
                        countCall--;

                        if (countCall === 0) {
                            User.checkEmail({email: value}, function () {
                                delete ngModel.$error.$customMessage;
                                setValidate(true);
                            }, function (err) {

                                if (hasOtherErrors()) {
                                    setValidate(true);
                                } else {
                                    setValidate(false);
                                    if (err.data.field_errors && err.data.field_errors[0].field === 'email') {
                                        ngModel.$error.$customMessage = err.data.field_errors[0].message;
                                    } else {
                                        ngModel.$error.$customMessage = "This email is already in use";
                                    }
                                }
                            });
                        }
                    }, 1000);

                    function hasOtherErrors() {
                        var count = 0;
                        angular.forEach(ngModel.$error, function (v, k) {
                            if (k !== '$customMessage' && k !== 'checkEmail') {
                                count++;
                            }
                        });
                        return count > 0;
                    }
                },
                setValidate = function (valid) {
                    ngModel.$setValidity('checkEmail', valid);
                    ngModel.$formatters.unshift();
                    ngModel.$parsers.unshift();
                    ngModel.$validate();
                };


            //For DOM -> model validation
            ngModel.$parsers.unshift(function (value) {
                checkEmail(value);
                return value;
            });

            //For model -> DOM validation
            ngModel.$formatters.unshift(function (value) {
                checkEmail(value);
                return value;
            });
        }
    };
}]);

