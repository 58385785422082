import app from "js/legacy-app";

app.service('Organisation', ['$resource', function ($resource) {
    return $resource('/api/organisation/:param1/:param2', {}, {
        findByCreatorId: {
            method: "GET",
            params: {param1: "by-creator"},
            isArray: false
        },
        patch: {
            method: "PATCH",
            params: {param1: ""},
            isArray: false
        },
        clearAvatar: {
            method: "DELETE",
            params: {param1: "avatar"},
            isArray: false
        },
        getCurrent: {
            method: "GET",
            params: {param1: "current"},
            isArray: false
        },
        getTypes: {
            method: "GET",
            params: {param1: "/type/all"},
            isArray: true
        },
        clearBikePhoto: {
            method: "DELETE",
            params: {param2: "bike-photo"},
            isArray: false
        },
        getOrganisationAndStationByBikeId: {
            method: 'GET',
            params: {param1: "/organisation-station-by-bike"},
            isArray: false
        },
        findByName: {
            method: 'GET',
            params: {param1: "/search"},
            isArray: true
        },
        findByStationId: {
            method: "GET",
            params: {param1: "by-station"},
            isArray: false
        },
        list: {
            method: "GET",
            isArray: false,
            params: {param1: "list"}
        }
    });
}]);