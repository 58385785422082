import app from "js/legacy-app";

app.controller("CreateUserCtrl", ["$location", '$scope', 'User', 'Notification', 'LocationService', 'ErrorHandler',
    function ($location, $scope, User, Notification, LocationService, ErrorHandler) {

        $scope.userRoles = [];
        User.getAllRoles(function (data) {
            $scope.userRoles = data;
        });

        $scope.user = {
            company: {
                address: {}
            }
        };

        let cuc = {

            validAddress: function (address) {
                let error = LocationService.validateProfileAddress(address);
                if (error) {
                    Notification.error(error);
                    return false;
                }

                return true;
            }
        };

        $scope.save = function (form) {
            if (form.$valid && !$scope.user.phone.$error) {

                if (!cuc.validAddress($scope.user.company.address)) {
                    return;
                }

                let user = {
                    name: $scope.user.name,
                    email: $scope.user.email,
                    surname: $scope.user.surname,
                    phone: $scope.user.phone.number,
                    company: {
                        name: $scope.user.company.name,
                        address: LocationService.mapAddress($scope.user.company.address),
                        link: $scope.user.company.link
                    }
                };
                User.createBusinessAccount(user, function () {
                    $location.url('/admin/users');
                }, function (error) {
                    ErrorHandler.onError(error, "Failed to create user");
                });
            }
        };
    }
]);
