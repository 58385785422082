import angular from "angular";
import selectBikes from "../../select-bikes";

const component = {
    template: require("./select-bikes-dialog.html"),
    bindings: {
        filter: '<?vuiSbdFilter',
        title: '@?vuiSbdTitle',
        excludeBikes: '<?vuiSbdExcludeBikes',
        options: '<?vuiSbdOptions'
    },
    controller: function ($mdDialog) {
        'ngInject';

        this.$onInit = () => {
            this.title = this.title || "Select bicycles";
            this.bikes = [];
        };

        this.save = () => {
            $mdDialog.hide(this.bikes);
        };

        this.select = (bikes) => {
            this.bikes = [...bikes];
        };

        this.cancel = () => {
            $mdDialog.hide();
        };


    }

};

export default angular.module("vui-select-bike-dialog.module", [selectBikes.name])
    .component("vuiSelectBikeDialog", component);