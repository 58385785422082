import app from "js/legacy-app";
import angular from "angular";

app.directive('mechanicWorkAreaItem', ['ToggleLD', 'MechanicWorkArea', 'ErrorHandler', 'ModalService', 'AuthService', 'Notification',
    function (ToggleLD, MechanicWorkArea, ErrorHandler, ModalService, AuthService, Notification) {
        return {
            templateUrl: "components/mechanic-work-area/mechanic-work-area-item.html",
            replace: true,
            scope: {
                area: '=mwaiArea',
                href: '@mwaiHref',
                readOnly: '@mwaiReadOnly',
                onRemove: '&mwaiOnRemove'
            },
            controller: ['$scope', function ($scope) {
                $scope.Math = Math;

                $scope.mwai = {};

                $scope.mwai.remove = function ($event) {
                    if ($scope.readOnly) {
                        return;
                    }
                    ModalService.yesNoMessage($event, "Confirm action",
                        "Are you sure you want to remove selected work area? " +
                        "This operation can not be undone.", function (confirmed) {
                            if (confirmed) {
                                ToggleLD.show();
                                MechanicWorkArea.delete({
                                    id: $scope.area.id
                                }, function () {
                                    ToggleLD.hide();
                                    if (angular.isFunction($scope.onRemove)) {
                                        Notification.success('Work area is removed');
                                        $scope.onRemove();
                                    }
                                }, function (error) {
                                    ToggleLD.hide();
                                    ErrorHandler.onError(error, "Unable to delete work area");
                                });
                            }
                        },
                        "yes, remove"
                    );

                };

                $scope.mwai.changeActivity = function () {
                    if ($scope.readOnly) {
                        return;
                    }
                    ToggleLD.show();
                    MechanicWorkArea.update({
                        id: $scope.area.id
                    }, {active: $scope.area.active}, function () {
                        ToggleLD.hide();
                    }, function (error) {
                        ToggleLD.hide();
                        $scope.area.active = !$scope.area.active;
                        ErrorHandler.onError(error, "Unable to change work area activity");
                    });
                };

            }]
        };
    }]);