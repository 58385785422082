import angular from "angular";

export default angular
    .module("ngSentry", [])
    .factory('$exceptionHandler', function () {
        return function errorCatcherHandler(exception, cause) {
            // TODO need to pay
            console.error(exception.stack);
            /*try {
                Sentry.captureException(exception);
            } catch (e) {
                console.error(e.stack)
            }*/

        };
    });
