import app from "js/legacy-app";
import mechanicImg from 'img/logo-seo-velotooler.jpg';

app.service('MetaInfo', ['$rootScope', '$document', function ($rootScope, $document) {

    $rootScope.desc = '';
    $rootScope.keywords = '';

    var metaInfoMap = {
        '/': {
            title: 'Mobile Bicycle Service',
            desc: 'Velotooler is a place where bicycle owners can solve their bicycle issues through a network of peer bicycle mechanics',
            keywords: 'velotooler, mobile bicycle repair, bicycle service, cheapest bicycle service, local bicycle repair, mobile bicycle repair solution, bicycle service profile, bicycle certificate'
        },
        '/home': {
            title: 'Mobile Bicycle Service',
            desc: 'Velotooler is a place where bicycle owners can solve their bicycle issues through a network of peer bicycle mechanics',
            keywords: 'velotooler, mobile bicycle repair, bicycle service, cheapest bicycle service, local bicycle repair, mobile bicycle repair solution, bicycle service profile, bicycle certificate'
        },
        '/login': {
            title: 'Login to velotooler',
            desc: 'Sign up to your Velotooler account and start solving your bicycle issues',
            keywords: 'login to velotooler, sign in to velotooler'
        },
        '/dashboard': {
            title: 'Dashboard',
            desc: 'Dashboard',
            keywords: 'velotooler dashboard'
        },
        '/sign-up-user': {
            title: 'Create Bicycle Profile on Velotooler for Free',
            desc: 'Sign up to Velotooler for free to let our mechanics solve your bicycle issues where and when it suits you best',
            keywords: 'velotooler sign up, velotooler registration'
        },
        '/become-a-mechanic': {
            title: 'Become a Velotooler Mechanic',
            desc: 'Build your resume in the industry. Fill in gaps in your pay when you need. Schedule work along with your riding, family time etc. Be recognized for your skill. Get paid for what you accomplish',
            keywords: 'velotooler mechanic registration, mechanic sign up, become a mechanic, become a service provider'
        },
        '/profile': {
            title: 'Profile',
            desc: 'Your Velotooler profile',
            keywords: 'profile'
        },
        '/add-bike': {
            title: 'Create a New Bicycle on Velotooler',
            desc: 'Create a unique bicycle page that mechanics use to work on your bicycle. Track your bicycles maintenance history. Prevent your bicycle from being stolen and sold. Alert our network if your bicycle is stolen.',
            keywords: 'register bicycle, new bicycle, velotooler bicycle, bicycle profile, bicycle service history'
        },
        '/contact-us': {
            title: 'Contact Velotooler Team',
            desc: 'Let us know how Velotooler can help you',
            keywords: 'contact velotooler, ask about velotooler, who is velotooler, velotooler contacts'
        },
        '/terms-and-conditions': {
            title: 'Terms of Service',
            desc: 'Terms of Service',
            keywords: 'terms of service'
        },
        '/privacy-policy': {
            title: 'Privacy Policy',
            desc: 'Privacy Policy',
            keywords: 'privacy policy'
        },
        '/payment-process': {
            title: 'Payment Process',
            desc: 'Payment Process',
            keywords: 'payment process'
        },
        '/request': {
            title: 'Request a Service',
            desc: 'Place your service and we will find suitable bicycle mechanics in your area',
            keywords: 'bicycle repair request, hire a mechanic, bicycle service request'
        },
        '/bicycle-assembly': {
            title: 'Home Bicycle Assembly and Tune-up by Professional Mechanics',
            desc: 'Get your personal bicycle mechanic to put your brand new bicycle together where and when you want it',
            keywords: 'bicycle tuning, bicycle adjustment, assemble bicycle, bicycle assembly from box, at home assembly',
        },
        '/mechanics': {
            title: 'Bicycle Mechanics',
            desc: 'Search bicycle mechanics on Velotooler',
            keywords: 'mobile bicycle mechanics',
            robots: 'noindex,follow'
        },
        '/locations': {
            title: 'Mechanic Locations',
            desc: 'Locations covered by Velotooler mechanics',
            keywords: 'mobile bicycle service coverage'
        },
        '/for-bike-fleets': {
            title: 'Bicycle Fleet Maintenance',
            desc: 'Ensure the availability and safety of your fleet with regular preventative maintenance by professional, skilled and local bicycle mechanics',
            keywords: 'bicycle fleet, bicycle maintenance, bicycle tuning, bicycle adjustment, assemble bicycle'
        },
        '/for-bike-brands': {
            title: 'Bicycle Assembly Nationwide',
            desc: 'Reduce returns and improve customer service with Velotooler’s White Glove Delivery program.  We integrate into your POS',
            keywords: 'bicycle assembly, nationwide, reduce returns, customer service, customer satisfaction, white glove delivery, at home assembly',
        },
        '/quick-booking': {
            title: 'Bicycle mechanic service to your doorstep',
            desc: 'Discover the closest professional-grade care for your bike. With just a few clicks, get your bike expertly assembled and serviced at a reasonable price!',
            keywords: 'bicycle tuning, bicycle adjustment, assemble bicycle, bicycle assembly from box, at home assembly',
            image: require('img/logo-seo-velotooler.jpg')
        }
    };

    var setMetaInfo = function (info) {

        setTitle(info);
        setDescription(info);
        setKeywords(info);
        setUrl(info);
        setImage(info);
        setRobots(info);

        $rootScope.htmlReady();
    };

    var setTitle = function (info) {
        document.title = info.title;
        setMetaByProperty('og:title', info.title);
        setMetaByName('twitter:title', info.title);
        setMetaByItemprop('name', info.title);
    };

    var setDescription = function (info) {
        setMetaByName('description', info.desc);
        setMetaByProperty('og:description', info.desc);
        setMetaByName('twitter:description', info.desc);
        setMetaByItemprop('description', info.desc);
    };

    var setKeywords = function (info) {
        setMetaByName('keywords', info.keywords);
    };

    var setRobots = function (info) {
        if (info.robots) {
            setMetaByName('robots', info.robots);
        }
    };

    var setUrl = function (info) {
        setMetaByProperty('og:url', info.url);
    };

    var setImage = function (info) {
        setMetaByProperty('og:image', info.image);
        setMetaByItemprop('image', info.image);
        setMetaByName('twitter:image:src', info.image);
        setMetaByProperty('og:image:width', info.imgWidth);
        setMetaByProperty('og:image:height', info.imgHeight);
        setMetaByProperty('article:published_time', info.publishedTime);
        setMetaByProperty('article:modified_time', info.publishedTime);
    };

    var setMetaByName = function (name, content) {
        return document.querySelector("meta[name='" + name + "']").setAttribute('content', content);
    };

    var setMetaByItemprop = function (name, content) {
        return document.querySelector("meta[itemprop='" + name + "']").setAttribute('content', content);
    };

    var setMetaByProperty = function (name, content) {
        return document.querySelector("meta[property='" + name + "']").setAttribute('content', content);
    };

    var change = function (url, altUrl) {
        if (url === '/bike' || altUrl.match(/\/bike\//gi)) {
            return;
        }
        if (url.indexOf("/mechanic/") > -1) {
            return;
        }
        var info = metaInfoMap[url];
        if (!info) {
            info = metaInfoMap[altUrl];
        }
        if (!info) {
            info = metaInfoMap['/home'];
        }
        var image = info.image || mechanicImg;

        var metaInfo = {
            title: info.title,
            desc: info.desc,
            keywords: info.keywords,
            robots: info.robots,
            image: image,
            imgWidth: '1200px',
            imgHeight: '630px',
            publishedTime: new Date(),
            url: document.URL
        };
        setMetaInfo(metaInfo);
    };

    var manualChange = function (title, desc, keywords, imgUrl) {
        var metaInfo = {
            title: title,
            desc: desc,
            keywords: keywords,
            publishedTime: new Date(),
            url: document.URL
        };
        if (imgUrl) {
            metaInfo.image = imgUrl;
            metaInfo.imgWidth = '300px';
            metaInfo.imgHeight = '300px';
        } else {
            metaInfo.image = mechanicImg;
            metaInfo.imgWidth = '1200px';
            metaInfo.imgHeight = '630px';
        }

        setMetaInfo(metaInfo);
    };

    var createBikeSEO = function (bike, user, thumbnail) {
        var title = bike.make + ', ' + bike.model;
        var description = bike.make + ' ' + bike.model;
        var keywords = bike.make + ', ' + bike.model;

        if (bike.year) {
            description += ' ' + bike.year;
        }
        if (bike.frameMaterial) {
            description += ' ' + bike.frameMaterial;
        }

        if (bike.serialNumber) {
            title += ', ' + bike.serialNumber;
            description += ' - ' + bike.serialNumber;
            keywords += ', ' + bike.serialNumber;
        }

        return {
            title: title,
            description: description,
            keywords: keywords,
            thumbnail: thumbnail
        };
    };

    var addReviewMarkup = function (data) {

        var template = {
            "@context": "http://schema.org",
            "@type": "Review",
            "author": {
                "@type": "Person",
                "name": data.author
            },
            "datePublished": data.publishDate.toISOString(),
            "publisher": {
                "@type": "Organization",
                "name": "Velotooler",
                "sameAs": "http://www.velotooler.com"
            },
            "description": data.description,
            "inLanguage": "en",
            "itemReviewed": {
                "@type": "ProfessionalService",
                "name": data.item.name,
                "sameAs": data.item.url,
                "image": data.item.image,
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "ratingValue": data.item.rating,
                    "ratingCount": data.item.reviewCount
                }
            },
            "reviewRating": {
                "@type": "Rating",
                "ratingValue": data.rating
            }
        };


        var strTemplate = JSON.stringify(template);

        var markupTemplate = '<script type="application/ld+json">' + strTemplate + '</script>';
        var headElement = $document.find('head');
        headElement.append(markupTemplate);


    };

    return {
        change: change,
        manualChange: manualChange,
        createBikeSEO: createBikeSEO,
        setTitle: setTitle,
        setDescription: setDescription,
        setKeywords: setKeywords,
        addReviewMarkup: addReviewMarkup
    };

}])
;
