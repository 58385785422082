import app from "js/legacy-app";

app.directive('blOrganisationFilter', ['$q', 'Organisation', function ($q, Organisation) {
    return {
        template: '<pl-autocomplete-filter af-label="Organization" af-name="organisation" af-model="blof.filter.organisation"></pl-autocomplete-filter>',
        link: function ($scope) {

            $scope.blof = {};

            $scope.blof.filter = {
                organisation: {
                    search: function (query) {
                        var deferred = $q.defer();
                        Organisation.list({name: query, size: 100}, function (response) {
                            deferred.resolve(response.data);
                        }, function (error) {
                            deferred.reject();
                        });
                        return deferred.promise;
                    }
                }
            };
        }
    };
}]);