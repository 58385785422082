import app from "js/legacy-app";

app.controller("NotificationCtrl", ['$scope', '$mdToast', 'notification', '$rootScope',
    function ($scope, $mdToast, notification, $rootScope) {

        $scope.nCtrl = {
            notification: notification
        };

        $scope.nCtrl.closeNotification = function (event) {
            event.preventDefault();
            $mdToast.hide();
        };

        $scope.nCtrl.followNotification = function (event) {
            if (!event.defaultPrevented) {
                $rootScope.goToNegotiation($scope.nCtrl.notification);
                $mdToast.hide();
            }
        };
    }]);