import app from "js/legacy-app";
import moment from "moment";

app.config(['$mdThemingProvider', '$mdIconProvider', '$compileProvider', '$mdDateLocaleProvider',
    function ($mdThemingProvider, $mdIconProvider, $compileProvider, $mdDateLocaleProvider) {

        $compileProvider.debugInfoEnabled(false);

        // choose color style
        $mdThemingProvider.theme('default')
            .primaryPalette('grey')
            .accentPalette('yellow')
            .warnPalette('red');

        // choose font icons
        $mdIconProvider.fontSet('fa', 'fontawesome');

        $mdDateLocaleProvider.formatDate = function (date) {
            return moment(date).format('MMM DD, YYYY');
        };
    }
]);