import app from "js/legacy-app";

app.filter("emoji", ['EMOJI', 'HEX_EMOJI', function (EMOJI, HEX_EMOJI) {
    var rEmojis = new RegExp("(" + EMOJI.join("|") + ")", "g");

    return function (input) {
        if (!input || !input.replace) {
            return '';
        }
        return input.replace(rEmojis, function (match, text) {
            return `<img src="${require(`img/chat/opacity.png`)}" class="smile smile-` + HEX_EMOJI[text] + `" title="` + HEX_EMOJI[text] + `">`;
        });
    };
}]);