import app from "js/legacy-app";

app.controller('UsersCtrl', ['$location', 'User', '$scope', 'ModalService', '$q', 'Notification',
    'AuthService', 'ErrorHandler', '$http',
    function ($location, User, $scope, ModalService, $q, Notification, AuthService, ErrorHandler, $http) {

        $scope.users = [];
        $scope.selectedUsers = [];
        $scope.usersToSend = [];
        $scope.sharingUsersEmail = [];
        $scope.emails = [];
        $scope.message = {};

        let exportCSV = function (data, action) {
            $http.get('/api/user/export/csv', {responseType: 'arraybuffer'})
                .success(function (response, status, header) {
                    let file = new Blob([(response)]);
                    let url = URL.createObjectURL(file);
                    let anchor = document.createElement('a');
                    anchor.setAttribute('href', url);
                    anchor.setAttribute('download', header().filename);
                    anchor.click();
                    window.URL.revokeObjectURL(url);
                });
        };

        let viewFullProfile = function () {
            let id,
                data = $scope.initDataTable.getData();

            for (let i in data) {
                if (data[i].selected) {
                    id = data[i].id;
                }
            }

            if (id) {
                ModalService.fullProfileUser(id);
            }
        };

        let showPayInfo = function () {
            let id,
                data = $scope.initDataTable.getData();

            for (let i in data) {
                if (data[i].selected) {
                    id = data[i].id;
                }
            }

            if (id) {
                ModalService.showUserPayInfo(id);
            }
        };

        let unblockUsers = function () {
            let ids = [],
                data = $scope.initDataTable.getData();

            for (let i in data) {
                if (data[i].selected) {
                    ids.push(data[i].id);
                }
            }

            if (ids) {
                User.unblockUsers(ids[0], function () {
                    $scope.initDataTable.refresh();
                });
            }
        };

        let blockUsers = function () {
            let ids = [],
                data = $scope.initDataTable.getData();

            for (let i in data) {
                if (data[i].selected) {
                    ids.push(data[i].id);
                }
            }

            if (ids) {
                User.blockUsers(ids, function () {
                    $scope.initDataTable.refresh();
                });
            }
        };

        let countChecked = function () {
            if (!$scope.initDataTable.getData) {
                return;
            }
            let count = 0,
                data = $scope.initDataTable.getData();
            for (let i in data) {
                if (data[i].selected) {
                    count++;
                }
            }

            return count;
        };

        let create = function () {
            $location.url('/admin/create-user');
        };

        let remove = function () {
            let message = 'Are you sure you want to remove this user?';
            ModalService.yesNoMessage(null, '', message, function (response) {
                if (response === true) {
                    var promises = [],
                        data = $scope.initDataTable.getData();
                    for (var i in data) {
                        if (data[i].selected) {
                            if (data[i].role === "ADMIN") {
                                Notification.error("You can't remove admin user!");
                                continue;
                            }
                            promises.push(User.delete({id: data[i].id}).$promise);
                        }
                    }

                    $q.all(promises).finally($scope.initDataTable.refresh);
                }
            });
        };

        var loginAsUser = function () {
            var ids = [],
                data = $scope.initDataTable.getData();

            for (var i in data) {
                if (data[i].selected) {
                    ids.push(data[i].id);
                }
            }

            if (ids) {
                User.loginAsUser({id1: ids[0]}, {}, function (response) {
                    AuthService.backupAndDeleteToken(function () {
                        AuthService.authorize(response.token);
                    });
                }, function (error) {
                    ErrorHandler.onError(error, "Unable to login as this user");
                });
            }
        };

        $scope.initDataTable = {
            service: User,
            selected: true,
            path: 'search/all',
            name: 'admin-manage-user-list',
            showFilters: true,
            changeSize: true,
            customFilters: [
                {
                    label: 'Active',
                    filter: {
                        isBlocked: false,
                        deleted: false
                    }
                },
                {
                    label: 'Blocked',
                    filter: {
                        isBlocked: true
                    }
                },
                {
                    label: 'Deleted',
                    filter: {
                        deleted: true
                    }
                },
                {
                    label: 'All users',
                    filter: null
                }
            ],
            columns: [
                {
                    name: 'name',
                    columnName: 'Name',
                    getValue: function (value) {
                        return '<div style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" title="' + value + '">' +
                            value + '</div>';
                    },
                    show: true,
                    sort: true,
                    filterColumnName: 'name',
                    filter: 'string',
                    style: {
                        'max-width': '250px'
                    }
                },
                {
                    name: 'surname',
                    columnName: 'Surname',
                    getValue: function (value) {
                        return '<div style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" title="' + value + '">' +
                            value + '</div>';
                    },
                    show: true,
                    sort: true,
                    filterColumnName: 'surname',
                    filter: 'string',
                    style: {
                        'max-width': '250px'
                    }
                },
                {
                    name: 'email',
                    columnName: 'Email',
                    getValue: function (value) {
                        return '<div style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" title="' + value + '">' +
                            value + '</div>';
                    },
                    show: true,
                    sort: true,
                    filterColumnName: 'email',
                    filter: 'string',
                    style: {
                        'max-width': '250px'
                    }
                },
                {
                    name: 'address',
                    columnName: 'Address',
                    getValue: function (value) {
                        var address = value.address || 'Not specified';
                        return '<div style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" title="' + address + '">' +
                            address + '</div>';
                    },
                    show: true,
                    sort: true,
                    filterColumnName: 'locations.address.address',
                    filter: 'string',
                    style: {
                        'max-width': '250px'
                    }
                },
                {
                    name: 'role',
                    columnName: 'Role',
                    show: true,
                    sort: true,
                    filterColumnName: 'role',
                    filter: 'string'
                },
                {
                    name: 'phone',
                    columnName: 'Phone',
                    show: true,
                    sort: true,
                    filterColumnName: 'phone',
                    filter: 'string'
                }
            ],
            actions: [
                {
                    html: '<i class="fa fa-plus" aria-hidden="true"></i>',
                    title: 'Create',
                    apply: create,
                    disable: function () {
                        return false;
                    }
                },
                {
                    html: '<i class="fa fa-times" aria-hidden="true"></i>',
                    title: 'Remove',
                    apply: remove,
                    disable: function () {
                        return countChecked() === 0;
                    }
                },
                {
                    html: '<i class="fa fa-lock" aria-hidden="true"></i>',
                    title: 'Block user',
                    apply: blockUsers,
                    disable: function () {
                        return countChecked() === 0;
                    }
                },
                {
                    html: '<i class="fa fa-unlock" aria-hidden="true"></i>',
                    title: 'Unblock user',
                    apply: unblockUsers,
                    disable: function () {
                        return countChecked() === 0 || countChecked() > 1;
                    }
                },
                {
                    html: '<i class="fa fa-info" aria-hidden="true"></i>',
                    title: 'View full profile',
                    apply: viewFullProfile
                },
                {
                    html: '<i class="fa fa-usd" aria-hidden="true"></i>',
                    title: 'Pay info',
                    apply: showPayInfo
                },
                {
                    html: '<i class="fa fa-list" aria-hidden="true"></i>',
                    title: 'Export CSV',
                    apply: exportCSV,
                    disable: function () {
                        return false;
                    }
                },
                {
                    html: '<i class="fa fa-external-link" aria-hidden="true"></i>',
                    title: 'Login as this user',
                    apply: loginAsUser
                }
            ]
        };

    }
]);
