import "./content.component.scss";

export default {
    template: require('./content.component.html'),
    bindings: {
        fullScreen: "@?vuiFullScreen"
    },
    transclude: true,
    controller: function () {
        'ngInject';
    }
};
