import angular from "angular";
import "./copy-to-clipboard.component.scss";

const component = {
    template: require('./copy-to-clipboard.component.html'),
    bindings: {
        textToCopy: "@vuiTextToCopy"
    },
    controller: function () {

        this.onSuccess = (e) => {
            showTooltip(e.trigger, 'Copied');
        };

        this.onMouseLeave = (elem) => {
            let element = angular.element(elem.currentTarget).find('span')[0];
            element.innerText = 'Copy to clipboard';
        };

        function showTooltip(elem, msg) {
            let element = angular.element(elem).find('span')[0];
            element.innerText = msg;
        }

    }
};

export default angular.module("vui-clipboard.module", [])
    .component('vuiClipboard', component);
