import app from "js/legacy-app";

app.controller('AssignSupportResponsibleCtrl', [
    '$scope',
    '$mdDialog',
    'Notification',
    function ($scope, $mdDialog, Notification) {
        $scope.asrc = {};

        $scope.asrc.cancel = (event) => {
            $mdDialog.hide();
        };

        $scope.asrc.filter = {role: 'ADMIN'};
        $scope.asrc.onSelect = (user) => {
            $scope.asrc.assignee = {
                id: user.id,
                name: user.name + " " + user.surname,
            };
        };

        $scope.asrc.assign = (event) => {
            let assignee = $scope.asrc.assignee;
            if (!assignee) {
                Notification.warning('Please select user');
                return;
            }

            $mdDialog.hide({assignee: $scope.asrc.assignee});
        };
    }
]);
