import app from "js/legacy-app";

app.service('MechanicBikeResource', ['$resource', function ($resource) {
    return $resource('/api/mechanic-bike/:id/:id1/:id2', {}, {
        updateMainDesc: {
            method: "PUT",
            params: {id1: "main-description"},
            isArray: false
        },
        updateComponents: {
            method: "PUT",
            params: {id1: "components"},
            isArray: false
        },
        enterSN: {
            method: "PUT",
            isArray: false,
            params: {id1: "serial-number"}
        },
        list: {
            method: "GET",
            isArray: false,
            params: {id: "list"}
        }
    });
}]);
