import app from "js/legacy-app";
import angular from "angular";

app.directive('cardCvcInput', function () {

    'use strict';

    return {
        restrict: 'E',
        template: '<input type="text" x-autocompletetype="cc-csc" required restrict-numeric card-cvc-validator card-cvc-formatter maxlength="4" data-ng-pattern="/^\\d+$/" /> ',
        replace: true
    };
})
    .directive('cardCvcFormatter',['$timeout', function ($timeout) {

        'use strict';

        function setValue(elm, value, scope, ngModelCtrl) {
            elm.val(value);
            ngModelCtrl.$setViewValue(value);

            $timeout(function() {
                elm.val(value);
                scope.$apply();
            });
        }

        return {
            require: 'ngModel',
            link: function postLink(scope, element, attrs, ngModelCtrl) {
                element.on('input', restrictCvc);
                element.on('paste', restrictCvc);

                function restrictCvc(e) {
                    var elm = angular.element(e.currentTarget || e.srcElement);
                    var value = elm.val();

                    if(!value || value === ' ') {
                        setValue(elm, value, scope, ngModelCtrl);
                        return;
                    }

                    value = value.substr(0, 4);

                    setValue(elm, value, scope, ngModelCtrl);
                    e.preventDefault();
                }
            }
        };
    }])

    .directive('cardCvcValidator', ['Payment', function (Payment) {

        'use strict';

        return {
            require: 'ngModel',
            link: function (scope, elm, attrs, ngModelCtrl) {
                function validate(value) {
                    var valid = value ? Payment.validateCardCvc(value) : false;
                    ngModelCtrl.$setValidity('ccCvc', valid);
                    return valid;
                }

                ngModelCtrl.$parsers.unshift(function (value) {
                    return validate(value) ? value : undefined;
                });

                ngModelCtrl.$formatters.unshift(function (value) {
                    validate(value);
                });
            }
        };
    }]);
