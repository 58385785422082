import app from "js/legacy-app";

app.controller('CustomServicesPageCtrl', ['$scope', '$location', function ($scope, $location) {

    $scope.msc = {};

    $scope.msc.onClick = function (path) {
        $location.path('services/' + path).search({});
    };

}]);