export default function ($routeSegmentProvider) {

    "ngInject";

    $routeSegmentProvider
        .when('/login/:type', 'login')
        .when('/login', 'login')
        .when('/forgot-password', 'forgot-password')
        .when('/restore-password', 'restore-password')
        .when('/activate-account', 'activate-account')
        .when('/complete-registration', 'complete-registration')
        .when('/sign-up-user', 'sign-up-user')
        .when('/become-a-mechanic', 'become-a-mechanic')
        .when('/sign-up-mechanic', 'sign-up-mechanic')
        .when('/sign-up-bike-shop', 'sign-up-bike-shop')
        .when('/sign-up-mobile-mechanic', 'sign-up-mobile-mechanic')
        .when('/sign-up-individual-mechanic', 'sign-up-individual-mechanic')
        .when('/completed-registration', 'completed-registration')
        .when('/social/complete-sign-in', 'social-complete-sign-in')
        .when('/social/registration/select-role', 'social-registration-select-role')
        .when('/social/registration/complete', 'social-registration-complete')

        .segment('login', {
            access: {requireLogin: false},
            template: '<login-page></login-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "login.module" */ "./login/login.module");
            }
        })
        .segment('forgot-password', {
            access: {requireLogin: false},
            template: '<forgot-password-page></forgot-password-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "forgot-password.module" */ "./forgot-password/forgot-password.module");
            }
        })
        .segment('restore-password', {
            access: {requireLogin: false},
            template: '<restore-password-page></restore-password-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "restore-password.module" */ "./restore-password/restore-password.module");
            }
        })
        .segment('activate-account', {
            access: {requireLogin: false, requireLogout: true},
            template: '<activate-business-account-page></activate-business-account-page>', // TODO rename to activate account
            loadModule: () => {
                return import(/* webpackChunkName: "activate-business-account.module" */ "./activate-business-account/activate-business-account.module");
            }
        })
        .segment('complete-registration', {
            access: {requireLogin: false},
            template: '<complete-registration-page></complete-registration-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "complete-registration.module" */ "./complete-registration/complete-registration.module");
            }
        })
        .segment('sign-up-user', {
            access: {requireLogin: false},
            template: '<sign-up-user-page></sign-up-user-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "sign-up-user.module" */ "./sign-up-user/sign-up-user.module");
            }
        })
        .segment('become-a-mechanic', {
            access: {requireLogin: false},
            template: '<become-a-mechanic-page></become-a-mechanic-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "become-a-mechanic.module" */ "./become-a-mechanic/become-a-mechanic.module");
            }
        })
        .segment('sign-up-mechanic', {
            access: {requireLogin: false},
            template: '<sign-up-mechanic-page></sign-up-mechanic-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "sign-up-mechanic.module" */ "./sign-up-mechanic/sign-up-mechanic.module");
            }
        })
        .segment('sign-up-bike-shop', {
            access: {requireLogin: false},
            template: '<sign-up-bike-shop-page></sign-up-bike-shop-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "sign-up-bike-shop.module" */ "./sign-up-bike-shop/sign-up-bike-shop.module");
            }
        })
        .segment('sign-up-mobile-mechanic', {
            access: {requireLogin: false},
            template: '<sign-up-mobile-mechanic-page></sign-up-mobile-mechanic-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "sign-up-mobile-mechanic.module" */ "./sign-up-mobile-mechanic/sign-up-mobile-mechanic.module");
            }
        })
        .segment('sign-up-individual-mechanic', {
            access: {requireLogin: false},
            template: '<sign-up-individual-mechanic-page></sign-up-individual-mechanic-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "sign-up-individual-mechanic.module" */ "./sign-up-individual-mechanic/sign-up-individual-mechanic.module");
            }
        })
        .segment('completed-registration', {
            access: {requireLogin: false},
            template: '<completed-registration-page></completed-registration-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "completed-registration.module" */ "./completed-registration/completed-registration.module");
            }
        })
        .segment('social-complete-sign-in', {
            access: {requireLogin: false},
            template: '<social-complete-sign-in-page></social-complete-sign-in-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "social-complete-sign-in.module" */ "./social-complete-sign-in/social-complete-sign-in.module");
            }
        })
        .segment('social-registration-select-role', {
            access: {requireLogin: false},
            template: '<social-registration-select-role-page></social-registration-select-role-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "social-registration-select-role.module" */ "./social-registration-select-role/social-registration-select-role.module");
            }
        })
        .segment('social-registration-complete', {
            access: {requireLogin: false},
            template: '<social-registration-complete-page></social-registration-complete-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "social-registration-complete.module" */ "./social-registration-complete/social-registration-complete.module");
            }
        });
}
