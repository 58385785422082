import app from "js/legacy-app";
import bikeOwnerDefaultImage from "img/user-no-avatar.png";
import mechanicDefaultImage from "img/mechanic-default-picture.png";

const mechanicImages = require.context('img/icons/mechanic', true);

app.controller('ConfirmAppointmentDateDialogCtrl', ['$scope', '$mdDialog', '$mdMedia', 'request', 'User', 'AuthService',
    function ($scope, $mdDialog, $mdMedia, request, User, AuthService) {

        $scope.caddc = {
            mdMedia: $mdMedia,
            request: request,
            auth: AuthService
        };

        $scope.caddc.cancel = function () {
            $mdDialog.hide();
        };

        $scope.caddc.confirm = function () {
            $mdDialog.hide({action: 'CONFIRM'});
        };

        $scope.caddc.declineOffer = function () {
            $mdDialog.hide({action: 'DECLINE'});
        };

        $scope.caddc.offerTime = function () {
            $mdDialog.hide({action: 'OFFER_TIME'});
        };

        $scope.caddc.openMenu = function (mdMenu, event) {
            mdMenu.open(event);
        };

        $scope.caddc.getMechanicCategoryIcon = function () {
            if (!$scope.caddc.currentUser || !$scope.caddc.currentUser.mechanicCategory) {
                return '';
            }

            return mechanicImages('./' + $scope.caddc.currentUser.mechanicCategory.toLowerCase() + '-type.png', true);
        };

        $scope.caddc.getCurrentUserProfileLink = function () {
            let currentUser = $scope.caddc.currentUser;
            if (!currentUser) {
                return;
            }

            if (AuthService.isMechanic()) {
                return '/user/' + currentUser.id;
            }

            let userId = currentUser.nickname ? currentUser.nickname : currentUser.id;
            return '/mechanic-public/' + userId;
        };

        (function init() {
            var negotiation = request.negotiations[0];

            if (!negotiation) {
                $mdDialog.hide();
                return;
            }

            $scope.caddc.bikes = $scope.caddc.request.bikes
            $scope.caddc.activeNegotiation = negotiation;
            var currentUserId;
            if (!AuthService.isMechanic()) {
                currentUserId = negotiation.user.id;
            } else {
                currentUserId = request.bikeOwnerIdOfRequest || request.userId;
            }
            User.getPublicUserInfo({id1: currentUserId}, function (user) {
                $scope.caddc.currentUser = user;
                if (!user.avatar || !user.avatar.thumbnail) {
                    user.avatar = {
                        thumbnail: AuthService.isMechanic() ? bikeOwnerDefaultImage : mechanicDefaultImage
                    };
                }
            });
        })();
    }
]);
