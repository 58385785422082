import angular from 'angular';

(function () {

    'use strict';

    function cardExpiryInput() {
        return {
            restrict: 'E',
            template: '<input type="text" class="cc-exp" x-autocompletetype="cc-exp" maxlength="7" card-expiry-formatter>',
            replace: true
        };
    }

    function cardExpiryFormatter($timeout) {

        var year = new Date().getFullYear().toString();
        var maxYear = parseInt(year) + 10;
        var minDate = new Date().getTime();

        function formatDate (val) {
            if (val.length > 2) {
                var tmpMM = val.substr(0, 2);
                var tmpYY = val.substr(2, val.length);
                val = tmpMM + " / " + tmpYY;
            }
            return val;
        }

        function setValue(elm, value, scope, ngModelCtrl) {
            elm.val(value);
            ngModelCtrl.$setViewValue(value);

            $timeout(function() {
                elm.val(value);
                scope.$apply();
            });
        }

        return {
            require: 'ngModel',
            link: function postLink(scope, element,attr, ngModelCtrl) {
                element.on('input', function(e) {

                    var skipTest = false;

                    ngModelCtrl.monthInvalid = false;
                    ngModelCtrl.yearInvalid = false;
                    ngModelCtrl.expired = false;

                    var elm = angular.element(e.currentTarget || e.srcElement);
                    var val = elm.val();

                    val = val.replace(/\D/g, '');

                    if(!val || val === ' ') {
                        setValue(elm, val, scope, ngModelCtrl);
                        return;
                    }

                    if(val.length === 1) {
                        if(val !== '0' && val !== '1')
                            val = '0' + val;
                    }

                    if(val.length === 2 && (parseInt(val) > 12 || val === '00')) {
                        val = val.substr(0, 1);
                    } else {
                        if(val.length === 3) {
                            var thirdNum = parseInt(val.charAt(2));
                            var thirdNumYear = parseInt(year.charAt(2));
                            if(thirdNum > thirdNumYear + 1 || thirdNum < thirdNumYear)
                                val = val.substr(0, 2);
                        }

                        if(val.length === 4) {
                            if(parseInt(val.charAt(2) + val.charAt(3)) > (parseInt(year.charAt(2) + year.charAt(3)) + 10))
                                val = val.substr(0, 3);

                            if(parseInt(val.charAt(2) + val.charAt(3)) < parseInt(year.charAt(2) + year.charAt(3)))
                                val = val.substr(0, 3);

                            var minMonth = parseInt(val.substr(0,2)) === 12 ? '1' : parseInt(val.substr(0,2)) + 1;
                            var minYear = parseInt(val.substr(0,2)) === 12 ? parseInt(year.substr(0,2) + val.charAt(2) + val.charAt(3)) + 1 : year.substr(0,2) + val.charAt(2) + val.charAt(3);

                            if(val.length === 4 && (minDate > new Date(minYear + '-' + minMonth).getTime())) {
                                ngModelCtrl.$setValidity('cardExpiry', false);
                                ngModelCtrl.expired = true;
                                skipTest = true;
                            }
                        }
                    }

                    if(!skipTest) {
                        var monthValid = new RegExp("^((0|1)$|(0[1-9]|1[0-2]))").test(val);
                        var yearValid = (val.length === 3 ? val.charAt(2) === (parseInt(year.charAt(2)) + '') || val.charAt(2) === (parseInt(year.charAt(2)) + 1 + '') : true);

                        if(!monthValid) {
                            ngModelCtrl.monthInvalid = true;
                        }

                        if(!yearValid) {
                            ngModelCtrl.yearInvalid = true;
                        }

                        ngModelCtrl.$setValidity('cardExpiry', (monthValid && yearValid && val.length === 4));
                    }

                    val = formatDate(val);

                    setValue(elm, val, scope, ngModelCtrl);

                    e.preventDefault();
                });
                element.on('paste', function(e) {
                    e.preventDefault();
                });
            }
        };
    }

    var app = angular.module('legacy-app');
    app.directive('cardExpiryInput', [cardExpiryInput]);
    app.directive('cardExpiryFormatter', ['$timeout', cardExpiryFormatter]);
})();