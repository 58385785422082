import "./back-btn.component.scss";

export default {
    template: require('./back-btn.component.html'),
    bindings: {
        just: "@?just"
    },
    controller: function ($location) {

        'ngInject';

        const self = this;

        this.back = function () {
            if (self.just) {
                $location.path(self.just);
            }
        }
    }
};
