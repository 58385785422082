"use strict";

// old app dependencies
function requireAll(r) {
    r.keys().forEach(r);
}

requireAll(require.context('./app', true, /\.js$/));
requireAll(require.context('./app/components', true, /\.html$/));
requireAll(require.context('./app/template', true, /\.html$/));
