import app from "js/legacy-app";
import angular from "angular";

const mechanicImages = require.context('img/icons/mechanic', true);

app.directive('chooseMechanicStep', [function () {
    return {
        templateUrl: "components/request/create/steps/choose-mechanic-step.html",
        controller: ['$scope', 'Mechanics', 'LocationService', 'ToggleLD', 'ErrorHandler',
            'Notification', '$pageableList', '$pageScroll', '$q', '$timeout',
            function ($scope, Mechanics, LocationService, ToggleLD, ErrorHandler, Notification,
                      $pageableList, $pageScroll, $q, $timeout) {

                var mechanicsStep = {
                    needMechanicsScroll: false,

                    cleanup: function () {
                        $scope.mechanicsStep.selectedMechanic = null;
                        $scope.bcrc.request.mechanicId = null;
                        $scope.mechanicsStep.service = null;
                        $scope.mechanicsStep.items = null;
                        $scope.wizard.uncompleteStep('mechanics');
                    }
                };

                $scope.mechanicsStep = {
                    needMechanicsScroll: false,

                    options: {
                        id: "mechanics",
                        position: 2,
                        title: "Select your mechanic",
                        back: function () {
                            $scope.wizard.back();
                        },

                        next: function () {
                            if (!$scope.bcrc.request.mechanicId) {
                                Notification.warning("Please select a mechanic");
                                return;
                            }
                            $scope.wizard.next();
                        },

                        onEnter: function () {
                            mechanicsStep.needMechanicsScroll = true;
                        },

                        onLeave: function () {
                            mechanicsStep.needMechanicsScroll = false;
                        }
                    },

                    search: function (query) {
                        return loadMechanics(query);
                    },

                    filter: {},

                    onSelectMechanic: function (item) {
                        if (!this.isSelected(item)) {
                            $scope.mechanicsStep.selectedMechanic = item;
                            $scope.bcrc.request.mechanicId = item.id;
                            $scope.mechanicsStep.service = item.selectedService;
                            $scope.wizard.next();
                        } else {
                            $scope.mechanicsStep.selectedMechanic = null;
                            $scope.bcrc.request.mechanicId = null;
                            $scope.mechanicsStep.service = null;
                            $scope.wizard.uncompleteStep('mechanics');
                        }
                    },

                    isSelected: function (item) {
                        return $scope.bcrc.request.mechanicId === item.id;
                    },

                    updateMechanicList: function (newVal, oldVal) {
                        if (!angular.equals(newVal, oldVal)) {
                            mechanicsStep.cleanup();
                        }
                        if (newVal && !angular.equals(newVal, oldVal) && $scope.bcrc.services.selected) {
                            $pageableList.get('mechanic-list-step').refreshPage();
                        }
                    },

                    uncomplete: function () {
                        mechanicsStep.cleanup();
                        $pageableList.get('mechanic-list-step').refreshPage();
                    },

                    getMechanicCategoryIcon(item) {
                        return mechanicImages('./' + item.category.toLowerCase() + '-type.png', true);
                    },

                    getPriceClass(item) {
                        if (item.selectedService && item.selectedService.corePrice
                            && item.selectedService.price > item.selectedService.corePrice) {
                            return 'choose-mechanic-step__price--height'
                        }

                        return '';
                    }
                };

                $scope.mechanicsStep.selected = function () {
                    return $scope.mechanicsStep.selectedMechanic;
                };

                $scope.mechanicsStep.applyConcreteMechanic = function (mechanicId, onApplied, onNotProvideService) {
                    $q.when($scope.mechanicsStep.search({
                        mechanic: mechanicId,
                        page: 0,
                        size: 1
                    })).then(function (mechanics) {
                        if (!mechanics || !mechanics.data) {
                            return;
                        }
                        var currentMechanic = mechanics.data.filter(function (mechanic) {
                            return mechanicId === mechanic.id;
                        });

                        if (!currentMechanic || currentMechanic.length === 0) {
                            $scope.mechanicsStep.uncomplete();
                            if (onNotProvideService) {
                                onNotProvideService();
                            }
                            return;
                        }

                        $scope.mechanicsStep.service = currentMechanic[0].selectedService;
                        $scope.bcrc.request.mechanicId = currentMechanic[0].id;
                        $scope.mechanicsStep.selectedMechanic = currentMechanic[0];

                        if (onApplied) {
                            $timeout(function () {
                                onApplied();
                            }, 10);
                        }
                    }, function (error) {
                        $scope.bcrc.goToStep('mechanics');
                        $scope.mechanicsStep.uncomplete();
                        ErrorHandler.onError(null, "Mechanic does not provide this service any more");
                    });
                };

                var loadMechanics = function (query) {
                    var selected = $scope.bcrc.services.selected;
                    var serviceLocation = $scope.bcrc.serviceLocation;
                    if ((!serviceLocation || angular.equals({}, serviceLocation)) && !query.mechanic) {
                        return [];
                    }

                    if (selected && selected.online) {
                        query.country = serviceLocation.country;
                        query.location = null;
                    }

                    if (selected && !selected.online) {
                        query.country = null;
                    }

                    if (!selected || selected && !selected.online) {
                        query.location = LocationService.serializeAddress(serviceLocation);
                    }

                    if (selected) {
                        query.service = selected.taskStepId;
                    }

                    query.serviceCode = $scope.bcrc.taskCode;

                    if ($scope.autocomplete.selectedOrganisation) {
                        query.serviceBucket = $scope.autocomplete.selectedOrganisation.serviceBucket;
                    }

                    return Mechanics.search(query, function () {
                        if (mechanicsStep.needMechanicsScroll) {
                            $pageScroll.scrollTo(0, 300);
                        }
                    }).$promise;
                };

                mechanicsStep.applyMechanic = function () {
                    var mechanicId = $scope.bcrc.mechanicId;
                    if (mechanicsStep.needReapplyMechanic()) {
                        $scope.mechanicsStep.applyConcreteMechanic(mechanicId, function () {
                            $scope.wizard.disableStep("mechanics");
                        });
                    }
                };

                mechanicsStep.needReapplyMechanic = function () {
                    var mechanicId = $scope.bcrc.mechanicId;
                    return mechanicId &&
                        (!$scope.bcrc.request.mechanicId || $scope.bcrc.request.mechanicId !== mechanicId);
                };

                mechanicsStep.onServiceChange = function () {
                    return $scope.$watch("bcrc.serviceLocation", function (newVal, oldVal) {
                        if (newVal && !angular.equals(newVal, oldVal)) {
                            mechanicsStep.applyMechanic();
                        }
                        if (!$scope.bcrc.mechanicId) {
                            $scope.mechanicsStep.updateMechanicList(newVal, oldVal);
                        }
                    });
                };

                mechanicsStep.onServiceChangeToggle = function () {
                    var watchFn;
                    return function () {
                        if (watchFn) {
                            watchFn();
                            watchFn = undefined;
                        } else {
                            watchFn = mechanicsStep.onServiceChange();
                        }
                    };
                };

                $scope.mechanicsStep.onServiceChange = mechanicsStep.onServiceChangeToggle();
                $scope.mechanicsStep.onServiceChange();


                mechanicsStep.onLocationChange = function () {
                    return $scope.$watch('bcrc.services.selected', function (newVal, oldVal) {
                        var mechanicId = $scope.bcrc.mechanicId;
                        if (mechanicId && !$scope.bcrc.taskCode && !angular.equals(newVal, oldVal)) {
                            $scope.mechanicsStep.applyConcreteMechanic(mechanicId);
                        }
                        if (!mechanicId) {
                            $scope.mechanicsStep.updateMechanicList(newVal, oldVal);
                        }
                    });
                };
                mechanicsStep.onLocationChangeToggle = function () {
                    var watchFn;
                    return function () {
                        if (watchFn) {
                            watchFn();
                            watchFn = undefined;
                        } else {
                            watchFn = mechanicsStep.onLocationChange();
                        }
                    };
                };

                $scope.mechanicsStep.onLocationChange = mechanicsStep.onLocationChangeToggle();
                $scope.mechanicsStep.onLocationChange();
            }
        ]
    };
}]);
