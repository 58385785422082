import app from "js/legacy-app";

app.directive('changePassword', [function () {
    return {
        templateUrl: "template/settings/tabs/security/change-password.html",
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: ['$scope', 'User', 'ModalService', 'Notification', function ($scope, User, ModalService, Notification) {
            $scope.changePassword = function (form) {
                if (form.$valid) {
                    var message = 'Are you sure you want to change your password?';
                    ModalService.yesNoMessage(null, '', message, function (response) {
                        if (response === true) {
                            User.changePassword($scope.profile.changePassword, function (data) {
                                $scope.profile.changePassword = {};
                                Notification.success('Password was successfully changed');
                            }, function (err) {
                                if (err.data.field_errors[0]) {
                                    Notification.error(err.data.field_errors[0].message);
                                }
                            });
                        }
                    });
                }
            };
        }]
    };
}]);
