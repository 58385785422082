import app from "js/legacy-app";

app.directive('agreementAddBike', ['$routeParams', '$location', '$routeSegment',
    function ($routeParams, $location, $routeSegment) {
        return {
            templateUrl: "template/dashboard/bikes/add-bike/agreement-add-bike.html",
            scope: {
                agreementAddBike: '=agreementAddBike',
                next: '&'
            },
            controller: ['$scope', '$bikeService', '$bikeComponentService', 'ToggleLD', 'Notification', 'AuthService',
                'HistoryService', 'ErrorHandler',
                function ($scope, $bikeService, $bikeComponentService, ToggleLD, Notification, AuthService,
                          HistoryService, ErrorHandler) {

                    $scope.showBikeStatus = $bikeService.showBikeStatusDescription;
                    $scope.close = $bikeService.cancelCreateBike;
                    $scope.agreementAddBike.disabledSave = false;
                    $scope.stationId = $routeParams.stationId;
                    $scope.organisationId = $routeParams.organisationId || $routeParams.organisation;

                    if (!$scope.organisationId && AuthService.isBikeOrganisationOwner()) {
                        $scope.organisationId = AuthService.user().companyId;
                    }

                    var aab = {
                        resource: $bikeService.getResourceClass()
                    };

                    $scope.submit = function (form) {
                        if (form.$valid) {
                            if (AuthService.isLogin()) {
                                if ($scope.agreementAddBike.bike.id) {
                                    restoreBike();
                                } else {
                                    save();
                                }
                            } else {
                                $bikeService.saveBikeToLocalStorage($scope.agreementAddBike.bike);
                                $location.path('/sign-up-user');
                            }
                        }
                    };

                    var save = function () {
                        $scope.agreementAddBike.disabledSave = true;
                        ToggleLD.show();


                        var components = $scope.agreementAddBike.bike.components;
                        $scope.agreementAddBike.bike.frameCondition = {
                            condition: {
                                rate: $scope.agreementAddBike.bike.components[0].condition.rate
                            }
                        };
                        $scope.agreementAddBike.bike.components = $bikeComponentService.transrofmData(components);
                        $scope.agreementAddBike.bike.images = [];
                        for (var i in $scope.agreementAddBike.imageContainer.images) {
                            var img = {
                                id: $scope.agreementAddBike.imageContainer.images[i].id,
                                original: $scope.agreementAddBike.imageContainer.images[i].originUrl,
                                thumbnail: $scope.agreementAddBike.imageContainer.images[i].thumbnailUrl
                            };
                            $scope.agreementAddBike.bike.images.push(img);
                        }
                        if ($scope.stationId || $scope.organisationId) {
                            $scope.agreementAddBike.bike.owningOrganization = {
                                stationId: $scope.stationId,
                                organisationId: $scope.organisationId
                            };
                        }
                        saveBike();
                    };

                    var saveBike = function () {
                        aab.resource.save($scope.agreementAddBike.bike, function (data) {
                            ToggleLD.hide();
                            Notification.success('Bicycle was successfully created');
                            HistoryService.clear();
                            if ($scope.organisationId && !$scope.stationId) {
                                $location.path('/organisation/' + $scope.organisationId + '/bike/' + data.id).search({
                                    info: true
                                });
                            } else if ($scope.organisationId && $scope.stationId) {
                                $location.path('/organisation/' + $scope.organisationId + '/station/' + $scope.stationId + '/bike/' + data.id).search({
                                    info: true
                                });
                            } else {
                                $location.path('/bike/' + data.id).search({info: true});
                            }

                            $scope.agreementAddBike.disabledSave = false;
                        }, function (error) {
                            $scope.agreementAddBike.disabledSave = false;
                            ToggleLD.hide();
                            ErrorHandler.onError(error, 'Failed to save bicycle');
                        });
                    };

                    var restoreBike = function () {
                        var params = {
                            id: $scope.agreementAddBike.bike.id
                        };

                        if ($scope.stationId) {
                            params.stationId = $scope.stationId;
                        }

                        if ($scope.organisationId) {
                            params.organisationId = $scope.organisationId;
                        }

                        aab.resource.restore(params, function (data) {
                            if ($scope.organisationId && !$scope.stationId) {
                                $location.path('/organisation/' + $scope.organisationId + '/bike/' + data.id).search({
                                    info: true
                                });
                            } else if ($scope.organisationId && $scope.stationId) {
                                $location.path('/organisation/' + $scope.organisationId + '/station/' + $scope.stationId + '/bike/' + data.id).search({
                                    info: true
                                });
                            } else {
                                $location.path('/bike/' + data.id).search({info: true});
                            }
                            $routeSegment.chain[$routeSegment.chain.length - 1].reload();
                        }, function (error) {
                            ErrorHandler.onError(error, "Failed to restore this bicycle");
                        });
                    };
                }
            ]
        };
    }]);
