import app from "js/legacy-app";

app.controller('CoreServiceBucketsListCtrl',
    function ($scope, ErrorHandler, CoreServiceBuckets, $location) {
        'ngInject';

        $scope.$parent.smc.selectedTab = 2;

        $scope.ttlc = {

            search: function (query) {
                return CoreServiceBuckets.get(query).$promise;
            },

            view: function (item) {
                $location.url("/admin/view-bucket/" + item.id);
            },

            filter: {
                type: {
                    options: []
                }
            }
        };

        (function init() {
            CoreServiceBuckets.get({size: 100}, function (res) {
                $scope.ttlc.filter.type.options = [{val: null, display: 'All'}].concat(res.data.map((sb) => {
                    return {val: sb.type, display: sb.name};
                }));
            });
        })();
    }
);
