import angular from "angular";
import settingsSection from "../../../vt-generic/components/settings-section";
import mechanicCard from "../mechanic-card/default";
import backButton from "../../../vt-generic/components/back-button";
import mechanicStep from "../choose-mechanic";
import "./booking.scss";
import mechanicDefaultImage from "img/mechanic-default-picture.png";

const mechanicImages = require.context('img/icons/mechanic', true);

const component = {
    template: require('./reassign-booking.html'),
    transclude: true,
    controller: function ($routeParams, $requestService, LocationService, Notification, ErrorHandler,
                          $timeout, ModalService, $location, $q, MechanicSchedule, $availabilityDates,
                          $scope, HistoryService, ToggleLD, $pageableList, $window) {
        'ngInject';

        const self = this;

        this.mechanicDefaultImage = mechanicDefaultImage;

        this.wizardOptions = {
            completeOnNext: true,
            onComplete: function () {
                self.goToCheckout();
            },
            onClose: function () {
                self.close();
            }
        };

        this.reassign = () => {
            let selectedService = self.selectedMechanic.selectedService;

            if (!selectedService || !selectedService.id) {
                Notification.error("This service request cannot be reassigned to another mechanic");
                return;
            }

            let request = {
                mechanicId: self.selectedMechanic.id,
                serviceDetails: {
                    id: selectedService.id,
                    price: selectedService.price,
                    name: selectedService.name,
                    timeToComplete: selectedService.timeToComplete,
                },
                appointmentDate: self.availabilityStep.selectedTime
            };

            ToggleLD.show();
            $requestService.getRequestResource().reassign({id: $routeParams.bookingId}, request, function (data) {
                ToggleLD.hide();
                HistoryService.clear();
                $location.url('/request/' + data.value);
            }, function (error) {
                ToggleLD.hide();
                onError(error);
            });
        };

        function onError(error) {
            let errorCode = ErrorHandler.getErrorCode(error);
            let errorMsg = ErrorHandler.extractError(error);

            if (errorCode === 2001) {
                ModalService.mechanicNotPerformedServiceDialog(errorMsg).then(function (response) {
                    if (!response) {
                        return;
                    }

                    self.goToStep('mechanics');
                    $pageableList.get('mechanic-list').refreshPage();
                    uncompleteAll();
                    self.mechanicStep.cleanup();
                });
                return;
            }

            if (errorCode === 2002) {
                ModalService.mechanicChangedServicePriceDialog(errorMsg).then(function (response) {
                    if (!response) {
                        return;
                    }

                    Notification.warning("Please select an another mechanic");
                    self.goToStep('mechanics');
                    $pageableList.get('mechanic-list').refreshPage();
                    uncompleteAll();
                    self.mechanicStep.cleanup();
                });
                return;
            }

            ErrorHandler.onError(error, "Failed to reassign the request");
        }

        this.close = () => {
            let message = 'Are you sure you want to leave?';

            ModalService.yesNoMessage(null, '', message, function (response) {
                if (!response) {
                    return;
                }

                let prevUrl = HistoryService.getPrevUrl();
                if (!prevUrl) {
                    $window.history.back();
                } else {
                    HistoryService.back();
                    HistoryService.clear();
                }
            });
        };

        this.mechanicStep = {
            options: {
                id: "mechanics",
                position: 1,
                title: "Select a mechanic",

                back: function () {
                    self.close();
                },

                next: function () {
                    if (!self.selectedMechanic) {
                        Notification.warning("Please select a mechanic");
                        return;
                    }

                    self.wizard.next();
                }
            },

            onSelect: (mechanic) => {
                if (!mechanic) {
                    uncompleteAll();
                    self.selectedMechanic = mechanic;
                } else if (self.selectedMechanic && self.selectedMechanic.id !== mechanic.id || !self.selectedMechanic) {
                    uncompleteAppointmentStep();
                    self.selectedMechanic = mechanic;
                    self.wizard.next();
                }
            },

            cleanup: function () {
                self.selectedMechanic = null;
            }
        };

        this.availabilityStep = {
            options: {
                id: "availability",
                position: 2,
                title: "Select date & time",
                back: function () {
                    self.wizard.back();
                },
                next: function () {
                    let selectedTime = self.availabilityStep.selectedTime;

                    if (!selectedTime) {
                        Notification.warning('Please select a date');
                        return;
                    }

                    self.wizard.next();

                },
                onEnter: function () {
                    return updateMechanicAvailability();
                }
            },

            onChangeAppointmentDate: (selectedTime) => {
                self.availabilityStep.selectedTime = selectedTime;

                if (!selectedTime) {
                    uncompleteAppointmentStep();
                }
            }
        };

        this.getMechanicCategoryIcon = (item) => {
            return mechanicImages('./' + item.category.toLowerCase() + '-type.png', true);
        };

        this.goToCheckout = function () {
            this.checkoutStage = true;
        };

        this.goToWizard = function () {
            this.checkoutStage = false;
        };

        this.goToStep = function (stepName) {
            this.goToWizard();
            this.wizard.goTo(stepName);
        };

        this.$onInit = () => {
            let bookingId = $routeParams.bookingId;

            $requestService.getRequestResource().get({id: bookingId}, (booking) => {
                if (booking.permissions.indexOf('REASSIGN_BOOKING') === -1) {
                    let message = booking.status === 'CANCELED'
                        ? 'This request has been canceled'
                        : 'A new mechanic has already been assigned to this request';

                    $location.url('/request/' + bookingId);
                    Notification.warning(message);
                    return;
                }
                self.wizard = $scope.wizard;
                self.booking = booking;

                initMechanicsFilter(booking);
                initPreviousMechanic(booking);
                self.showContent = true;

                this.goToStep('mechanics');
            });
        };

        let uncompleteAll = () => {
            self.wizard.uncompleteStep('mechanics');
            self.wizard.uncompleteStep('availability');
        };

        let uncompleteAppointmentStep = () => {
            self.wizard.uncompleteStep('availability');
        };

        let initPreviousMechanic = (booking) => {
            let negotiation = booking.negotiations[0];
            if (!negotiation) {
                return;
            }

            self.previousMechanic = negotiation.user;
        };

        let initMechanicsFilter = (booking) => {
            let service = booking.task;
            let location = booking.serviceLocation;

            let filter = {
                excludeMechanics: booking.mechanicId
            };

            if (service && service.online) {
                filter.country = location.country;
                filter.location = null;
            }

            if (service && !service.online) {
                filter.country = null;
            }

            if (!service || service && !service.online) {
                filter.location = LocationService.serializeAddress(location);
            }

            if (service) {
                filter.service = service.velotoolerId;
                filter.priceRange = serializePriceRange(service);
            }

            self.mechanicStep.filter = filter;
        };

        function serializePriceRange(service) {
            let result = "";
            result += (service.price ? service.price : "") + ";";
            result += (service.price ? service.price : "");

            return result;
        }

        function loadMechanicAvailability() {
            let deferred = $q.defer();

            let query = {
                mechanicId: self.selectedMechanic.id,
                allowPastDates: false,
                ignoreMinPriorNotice: false,
                lat: self.booking.serviceLocation.latitude,
                lng: self.booking.serviceLocation.longitude,
                duration: self.booking.task.timeToComplete,
                detalization: 'L'
            };

            MechanicSchedule.getAvailability(query, function (response) {
                self.availabilityStep.availability = angular.extend({}, query);
                self.availabilityStep.availability.dates = response.dates;

                self.availabilityStep.dates = $availabilityDates.generate(
                    self.availabilityStep.availability,
                    response.weeklyAvailability,
                    response.vacationPeriod
                );

                $timeout(function () {
                    deferred.resolve(self.availabilityStep.dates);
                });
            }, function (error) {
                ErrorHandler.onError(error);
                deferred.reject(error);
            });

            return deferred.promise;
        }

        let updateMechanicAvailability = function () {
            if (needToCleanupAvailability() || needToLoadAvailability()) {

                self.wizard.uncompleteStep('availability');

                if (canLoadAvailability()) {
                    return loadMechanicAvailability();
                }
                cleanupMechanicAvailability();
            }
        };

        function cleanupMechanicAvailability() {
            self.availabilityStep.availability = null;
            self.availabilityStep.dates = null;
        }

        let canLoadAvailability = function () {
            return self.selectedMechanic &&
                self.booking.serviceLocation &&
                self.booking.task;
        };

        let needToLoadAvailability = function () {

            if (!self.availabilityStep.availability || !self.availabilityStep.dates) {
                return true;
            }

            return self.selectedMechanic.id !== self.availabilityStep.availability.mechanicId ||
                self.booking.task.timeToComplete !== self.availabilityStep.availability.duration;
        };

        let needToCleanupAvailability = function () {
            return !canLoadAvailability();
        };
    }
};

export default angular.module("vui-reassigning-booking", [
    settingsSection.name,
    mechanicCard.name,
    mechanicStep.name,
    backButton.name
]).component('vuiReassigningBooking', component);
