import app from "js/legacy-app";
import angular from "angular";

app.component('vtToggleButton', {
    templateUrl: "components/toggle-button/toggle-button.html",
    bindings: {
        enabledText: "@vtTbEnabledText",
        disabledText: "@vtTbDisabledText",
        state: "<vtTbInitialState",
        onChange: "<vtTbOnChange"
    },
    controller: function () {
        this.onChangeInternal = () => {
            this.state = !this.state;
            if (angular.isFunction(this.onChange)) {
                this.onChange(this.state);
            }
        }
    }
});
