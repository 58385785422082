import app from "js/legacy-app";

app.directive('blItemBasicActions', ['ModalService', 'Notification', '$pageableList',
    '$routeParams', '$bikeService',
    function (ModalService, Notification, $pageableList, $routeParams, $bikeService) {
        return {
            templateUrl: "components/bike/list/base/bl-item-basic-actions.html",
            replace: true,
            link: function ($scope) {

                $scope.bla = {
                    resource: $bikeService.getResourceClass()
                };

                $scope.bla.createRequestLink = function () {
                    var link = "/create-request?bikeId=" + $scope.bike.id;
                    if ($routeParams.organisation) {
                        link += "&organisationId=" + $routeParams.organisation;
                    }
                    if ($routeParams.station) {
                        link += "&stationId=" + $routeParams.station;
                    }

                    let fixLocation = $scope.bike.fixLocation;
                    if (fixLocation && fixLocation.address) {
                        link += "&address=" + fixLocation.address;
                    }

                    if (fixLocation && fixLocation.apartment) {
                        link += "&apartment=" + fixLocation.apartment;
                    }

                    return link;
                };

                $scope.bla.changeStatus = function ($event, bike) {
                    ModalService.changeBikeStatus($event, bike, function (newStatus) {
                        $scope.bla.resource.changeStatus({id: bike.id}, {
                            status: newStatus
                        }, function (data) {
                            $pageableList.get('bike-list').refreshPage();
                            Notification.success('Status was successfully changed');
                        });
                    });
                };
            }
        };
    }]);