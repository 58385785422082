import angular from "angular";
import mechanicLocationActiveMarker from "img/mechanic-location.png";
import mechanicLocationInactiveMarker from "img/mechanic-location-inactive.png";

export default {
    template: require("./mechanic-list.component.html"),
    controller: function ($mdMedia, $document, $mechanicService, MECHANIC_CATEGORIES, AuthService, User, $q, AdminMechanicsApi) {
        'ngInject';

        const self = this;

        let showScroll = () => {
            angular.element($document[0].body).css('overflow-y', 'auto');
        };

        let hideScroll = () => {
            angular.element($document[0].body).css('overflow-y', 'hidden');
        };

        let maybeUpdateScroll = () => {
            if (this.mapView) {
                hideScroll();
            } else {
                showScroll();
            }
        };

        this.authService = AuthService;
        this.mdMedia = $mdMedia;
        this.mapController = {};
        this.location = {};

        this.search = (query) => {
            searchForMap(query);
            return searchForList(query);
        };

        this.filter = {
            category: {
                options: [{val: null, display: 'Any'}].concat(Object.keys(MECHANIC_CATEGORIES).map(function (k) {
                    return {val: k, display: MECHANIC_CATEGORIES[k]};
                }))
            },
            mechanic: {
                displayItem: function (item) {
                    return item.name + " " + item.surname;
                },
                search: function (query) {
                    let deferred = $q.defer();
                    User.findByNameLike({name: query, role: 'MECHANIC'}, function (response) {
                        deferred.resolve(response.items);
                    }, function (error) {
                        deferred.reject(error);
                    });
                    return deferred.promise;
                }
            },
            mechanicEmail: {
                displayItem: function (item) {
                    return item.email.toLowerCase();
                },
                search: function (email) {
                    let deferred = $q.defer();
                    User.list({email: email, roles: ['MECHANIC']}, function (response) {
                        deferred.resolve(response.data);
                    }, function (error) {
                        deferred.reject(error);
                    });
                    return deferred.promise;
                }
            }
        };

        this.applyLocationFilter = function (location) {
            if (location) {
                self.mapController.map.fitBounds(location.bounds);
            }
        };

        this.highlightArea = function (mechanic) {
            self.mapController.highlightArea(mechanic.id);
        };

        this.unhighlightArea = function (mechanic) {
            self.mapController.unhighlightArea(mechanic.id);
        };

        this.toggleView = function () {
            self.mapView = !this.mapView;
            maybeUpdateScroll();
        };

        function searchForMap(query) {
            let queryClone = angular.copy(query);
            queryClone.page = 0;
            queryClone.size = 100;
            queryClone.detalization = 'S';
            if (queryClone.email) {
                queryClone.mechanic = queryClone.email;
            }
            return AdminMechanicsApi.list(queryClone, function (response) {
                maybeUpdateScroll();
                let areas = [].concat.apply([], response.data.map(function (m) {
                    return m.workArea.areas.map(function (a) {
                        let bounds = a.bounds;
                        let radius = bounds.radius;
                        let center = {
                            lng: bounds.center.coordinates[0],
                            lat: bounds.center.coordinates[1]
                        };
                        return {
                            center: center,
                            radius: radius,
                            entity: m
                        };
                    });

                }));

                self.mapController.addManyWorkAreas(areas, {
                    marker: {
                        activeIcon: mechanicLocationActiveMarker,
                        inactiveIcon: mechanicLocationInactiveMarker
                    },
                    infoWindow: function ($internalScope, entity) {
                        $internalScope.mechanic = entity;
                        $internalScope.mlc = self;

                        return `<div ng-cloak class="mechanic-list__info-window">
                               <a target="_blank" rel="noopener noreferrer" href="/mechanic-public/{{ mechanic.nickname }}" class="display-block">
                                   <img data-ng-if="mechanic.avatar" class="mechanic-list__info-window-avatar vert-middle" src="{{mechanic.avatar}}">
                                   <img data-ng-if="!mechanic.avatar" class="mechanic-list__info-window-avatar vert-middle" src="${require(`img/mechanic-default-picture.png`)}">
                                   <span class="mechanic-list__info-window-name vert-middle">{{mechanic.name}} {{mechanic.surname}}</span>
                               </a>
                            </div>`;
                    }
                });
            }, function (error) {
            }).$promise;
        }

        function searchForList(query) {
            query.detalization = 'L';
            if (query.email) {
                query.mechanic = query.email;
            }
            return AdminMechanicsApi.list(query).$promise;
        }
    }
};