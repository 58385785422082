import app from "js/legacy-app";
import angular from "angular";

app.directive('fabScroll', ['$window', '$document', ScrollDirective]);

function ScrollDirective($window, $document) {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            var $e = angular.element(element);

            var last_position = 0;
            var onScroll = function (ev) {
                var pos = $window.scrollY;
                // on click we get pos 1
                if (pos === 1)
                    return;
                // console.log("last pos " + last_position + " curr pos " + pos);
                if (pos > last_position) {
                    // console.log('go down');
                    $e.addClass('vt-fab_hidden');
                } else {
                    // console.log('go up');
                    $e.removeClass('vt-fab_hidden');
                }
                last_position = pos;
            };
            $document.on('scroll', onScroll);
            $document.on('$destroy', function () {
                // console.log('destroy');
                $document.off('scroll', onScroll);
            });
        }
    };
}
