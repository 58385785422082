import app from "js/legacy-app";

app.controller("ShowUserCtrl", ['$scope', '$routeParams', '$location', 'User',
    'Rating', '$temporaryStorage', 'ErrorHandler', 'AuthService',
    function ($scope, $routeParams, $location, User, Rating, $temporaryStorage, ErrorHandler, AuthService) {

        $scope.routeParams = {
            requestId: $routeParams.requestId,
            bikeId: $routeParams.bikeId,
            stationId: $routeParams.stationId,
            organisationId: $routeParams.organisationId,
            userId: $routeParams.id
        };

        if (!$scope.routeParams.requestId && $temporaryStorage.contains('requestId')) {
            $scope.routeParams.requestId = $temporaryStorage.poll('requestId');
        }

        if (!$scope.routeParams.bikeId && $temporaryStorage.contains('bikeId')) {
            $scope.routeParams.bikeId = $temporaryStorage.poll('bikeId');
        }

        $scope.showUser = {};

        User.getUserInfo({id1: $scope.routeParams.userId}, function (data) {
            $scope.showUser.user = data;
        }, function (error) {
            ErrorHandler.onError(error);
        });

        var size = 5,
            page = 0;
        $scope.refreshReview = function () {
            Rating.getUserReviews({param2: $scope.routeParams.userId, page: page, size: size}, function (data) {
                $scope.showUser.reviews = data;
            }, function (error) {
                ErrorHandler.onError(error);
            });
        };
        $scope.refreshReview();

        $scope.prev = function () {
            if ($scope.showUser.reviews.prev || (page !== 0 && !$scope.showUser.reviews.data)) {
                page--;
                $scope.refreshReview();
            }
        };

        $scope.next = function () {
            if ($scope.showUser.reviews.next) {
                page++;
                $scope.refreshReview();
            }
        };

        $scope.onTabSelected = function (tab) {
            var searchParams = {};
            searchParams[tab] = true;
            if ($scope.routeParams.requestId) {
                searchParams.requestId = $scope.routeParams.requestId;
            }
            if ($scope.routeParams.bikeId) {
                searchParams.bikeId = $scope.routeParams.bikeId;
            }
            if ($scope.routeParams.organisationId) {
                searchParams.organisationId = $scope.routeParams.organisationId;
            }
            if ($scope.routeParams.stationId) {
                searchParams.stationId = $scope.routeParams.stationId;
            }
            $location.search(searchParams);
        };

        $scope.changeIndexTab = function () {
            $scope.selectedTab = 0;
            $scope.title = 'Profile';

            if ($routeParams.feedback) {
                $scope.selectedTab = 1;
                $scope.title = 'Feedback';
            }
        };

        $scope.changeIndexTab();

        $scope.$on('$routeChangeSuccess', function () {
            $scope.changeIndexTab();
        });
    }]);
