import app from "js/legacy-app";

app.controller('EditStationCtrl', ['$scope', 'Station', '$routeParams', '$location', 'Notification',
    'ModalService', 'ToggleLD', 'AuthService', 'ErrorHandler', 'PhoneService', 'LocationService',
    function ($scope, Station, $routeParams, $location, Notification, ModalService,
              ToggleLD, AuthService, ErrorHandler, PhoneService, LocationService) {

        $scope.esc = {
            isSupervisor: AuthService.isBikeBusinessSupervisor(),
            organisationId: $routeParams.organisationId,
            stationId: $routeParams.stationId,

            get: function () {
                return {
                    name: $scope.esc.station.name,
                    phone: $scope.esc.station.phone.number,
                    address: LocationService.mapAddress($scope.esc.station.address)
                };
            },

            getId: function () {
                return $scope.esc.stationId;
            },

            validAddress: function (address) {

                if ($scope.esc.station.type !== 'WAREHOUSE' && $scope.esc.station.type !== 'BIKE_SHOP') {
                    return true;
                }

                var error = LocationService.validateProfileAddress(address);
                if (error) {
                    Notification.error(error);
                    return false;
                }

                return true;
            },

            update: function (form) {
                if (form.$valid && !$scope.esc.station.phone.$error) {
                    if (!this.validAddress($scope.esc.station.address)) {
                        return;
                    }

                    ToggleLD.show();
                    Station.patch({param1: $scope.esc.getId()}, $scope.esc.get(), function (data) {
                        ToggleLD.hide();
                        $scope.esc.station = data;
                        $scope.esc.address = data.address.address;

                        $scope.esc.station.phone = {
                            number: $scope.esc.station.phone
                        };
                        Notification.success('Station has been successfully changed');
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error, "Failed to update division");
                    });
                }
            },

            refresh: function () {
                Station.get({param1: $scope.esc.getId()}, function (data) {
                    $scope.esc.station = data;
                    if (data.address) {
                        $scope.esc.address = data.address.address;
                    }

                    $scope.esc.station.phone = {
                        number: $scope.esc.station.phone
                    };
                }, function (error) {
                    ErrorHandler.onError(error, "Failed to load division");
                    $location.path('/dashboard').search({});
                });
            },

            changeAvatar: function ($event) {
                ModalService.chooseUserAvatar($event, $scope.esc.station, 'station', null,
                    function (response) {
                        if (response) {
                            $scope.esc.station.image = response.image;
                        }
                    });
            },

            clearAvatar: function () {
                ToggleLD.show();
                Station.clearAvatar({param2: $scope.esc.getId()},
                    function (data) {
                        ToggleLD.hide();
                        $scope.esc.station.image = null;
                        Notification.success('Avatar was successfully removed');
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error, "Failed to remove avatar");
                    });
            }
        };

        $scope.esc.refresh();
    }
]);