import app from "js/legacy-app";

app.controller('CoreServiceCategoryListCtrl',
    function ($scope, CoreServiceCategories, $location, $q, CoreServiceBuckets) {

        'ngInject';

        $scope.$parent.smc.selectedTab = 1;

        $scope.tclc = {
            search: function (query) {
                return CoreServiceCategories.get(query).$promise;
            },

            view: function (item) {
                $location.url("/admin/view-category/" + item.id);
            },

            filter: {
                serviceBucket: {
                    options: []
                }
            }
        };

        (function init() {
            CoreServiceBuckets.get({size: 100}, function (res) {
                $scope.tclc.filter.serviceBucket.options = [{val: null, display: 'All'}].concat(res.data.map((sb) => {
                    return {val: sb.type, display: sb.name};
                }));

            });
        })();
    }
);
