import app from "js/legacy-app";

app.controller('CompanyProfileCtrl',
    ['$scope', '$timeout', 'Company', 'AuthService', 'LocationService', 'Notification', 'ErrorHandler',
        function ($scope, $timeout, Company, AuthService, LocationService, Notification, ErrorHandler) {

            $scope.$parent.selectedTab = 2;
            $scope.$parent.title = 'Company';

            var cpc = {
                validCompanyAddress: function (address) {
                    var error = LocationService.validateProfileAddress(address);
                    if (error) {
                        Notification.error(error);
                        return false;
                    }

                    return true;
                }
            };

            $scope.changeCompany = {
                company: {
                    address: {}
                }
            };

            $scope.company = {
                get: function () {
                    return {
                        name: $scope.changeCompany.company.name,
                        link: $scope.changeCompany.company.link,
                        address: LocationService.mapAddress($scope.changeCompany.company.address)
                    };
                },

                getId: function () {
                    return $scope.changeCompany.company.id;
                }
            };

            Company.getCurrent(function (data) {
                $scope.changeCompany.company = data;
                $scope.changeCompany.address = data.address.address;
            }, function (error) {
                ErrorHandler.onError(error, "Failed to load company");
            });

            $scope.$watch('changeCompany.company.address.address', function () {
                if (!($scope.changeCompany.company &&
                        $scope.changeCompany.company.address &&
                        $scope.changeCompany.company.address.address)) {
                    return;
                }

                var address = $scope.changeCompany.company.address;
                var lat = address.latitude;
                var lng = address.longitude;

                $scope.changeCompany.map = {
                    options: {
                        center: {lat: lat, lng: lng}
                    },

                    markers: [{
                        position: {lat: lat, lng: lng},
                        title: 'Company address',
                        icon: require('img/vt.png')
                    }]
                };
            });

            $scope.changeCompany.update = function (form) {
                if (form.$valid) {

                    if (!cpc.validCompanyAddress($scope.changeCompany.company.address)) {
                        return;
                    }

                    $scope.changeCompany.updateBtnDisabled = true;
                    Company.patch({param: $scope.company.getId()}, $scope.company.get(), function (data) {
                        $scope.changeCompany.updateBtnDisabled = false;
                        $scope.changeCompany.company = data;
                        Notification.success('Company was successfully changed');
                    }, function (error) {
                        $scope.changeCompany.updateBtnDisabled = false;
                        ErrorHandler.onError(error, "Failed to change company");
                    });
                }
            };
        }
    ]);