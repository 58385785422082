import app from "js/legacy-app";

app.controller('MechanicChangedPriceDialogCtrl', ['$scope', '$mdDialog', 'error', '$mdMedia',
    function ($scope, $mdDialog, error, $mdMedia) {

        $scope.mcpdc = {
            error: error,
            mdMedia: $mdMedia
        };

        $scope.mcpdc.cancel = function (event) {
            $mdDialog.hide();
        };

        $scope.mcpdc.changeMechanic = function (event) {
            $mdDialog.hide({action: 'CHANGE_MECHANIC'});
        };

        $scope.mcpdc.continue = function (event) {
            $mdDialog.hide({action: 'CONTINUE'});
        };
    }
]);