import app from "js/legacy-app";

app.directive('backgroundImg', [function () {
    return {
        restrict: 'A',
        scope: {
            backgroundImg: "="
        },
        link: function (scope, element) {

            scope.$watch('backgroundImg', function (val) {
                if (val) {
                    element.css({
                        'background-image': 'url(' + val + ')',
                        'background-size': 'cover'
                    });
                }
            });
        }
    };
}]);
