import app from "js/legacy-app";

app.controller('MechanicWorkAreaListController',
    ['$scope', 'MechanicWorkArea', '$mdDialog', 'AuthService', 'ErrorHandler', 'ToggleLD', 'LocationService', '$routeParams', '$location',
        function ($scope, MechanicWorkArea, $mdDialog, AuthService, ErrorHandler, ToggleLD, LocationService, $routeParams, $location) {
            var mwalc = $scope.mwalc = {
                    loaded: false
                },
                currentUser = AuthService.user();

            $scope.mwalc.refresh = function () {
                ToggleLD.show();
                MechanicWorkArea.get({
                    detalization: 'S'
                }, function (workArea) {
                    ToggleLD.hide();
                    $scope.mwalc.loaded = true;
                    $scope.mwalc.workArea = workArea;
                }, function (error) {
                    ToggleLD.hide();
                    $scope.mwalc.loaded = true;
                    ErrorHandler.onError(error, "Unable to load work areas");
                });
            };

            $scope.mwalc.refresh();

            $scope.mwalc.addNewArea = function ($event) {
                $mdDialog.show({
                    targetEvent: $event,
                    onComplete: function (scope) {
                        scope.$broadcast('rzSliderForceRender');
                    },
                    template: `<md-dialog aria-label="Description" vt-modal-dynamic-height class="vt-modal vt-modal-mobile">
                        <div class="vt-modal__header vt-modal-mobile__header">
                            <div class="display-inline-block">Enter location</div>
                            <div class="vt-modal-mobile__header-close-btn">
                                <i class="vt-ui-icon vt-ui-icon-close" aria-label="Cancel" data-ng-click="closeDialog()"></i>
                            </div>
                        </div>
                      <form name="addWorkAreaForm" data-ng-submit="addWorkArea(addWorkAreaForm)" novalidate>
                       <div class="vt-modal__body vt-modal-mobile__body tw-w-full">
                         <location-autocomplete
                                         la-label="Location"
                                         la-model="model.location"
                                         la-no-favorite="true"
                                         la-types="geocode"
                                         la-required="true"
                                         la-detect-current-location="true">
                            <div style="color: #aaa">Radius (miles)</div>
                            <rzslider rz-slider-model="model.radius" rz-slider-options="slider.options">
                            </rzslider>
                         </location-autocomplete>
                       </div>

                       <div class="vt-modal__footer vt-modal__footer-mobile">
                            <div class="align-center">
                                <md-button type="button" class="md-big" data-ng-click="closeDialog()">Cancel</md-button>
                                <md-button type="submit" class="md-raised md-primary md-big md-button-uppercase">Add</md-button>
                            </div>
                        </div>

                        <div class="vt-modal-mobile__footer">
                            <md-button type="submit" class="md-primary">Add</md-button>
                        </div>
                      </form>
                     </md-dialog>`,
                    locals: {
                        mechanicId: currentUser.id
                    },
                    controller: ['$scope', 'mechanicId', 'MechanicWorkArea', 'ToggleLD', 'ErrorHandler', 'Notification', DialogController]
                });

                function DialogController($scope, mechanicId, MechanicWorkArea, ToggleLD, ErrorHandler, Notification) {
                    $scope.model = {
                        radius: 25
                    };
                    $scope.slider = {
                        options: {
                            floor: 0,
                            ceil: 50,
                            translate: function (value) {
                                return value + " mi";
                            }
                        }
                    };

                    function validAddress(address) {
                        var error = LocationService.validateWorkAreaAddress(address);
                        if (error) {
                            Notification.error(error);
                            return false;
                        }

                        return true;
                    }

                    $scope.addWorkArea = function (form) {
                        if (form.$valid) {
                            if (!validAddress($scope.model.location)) {
                                return;
                            }

                            ToggleLD.show();
                            var request = {
                                location: LocationService.mapAddress($scope.model.location),
                                radius: $scope.model.radius
                            };
                            MechanicWorkArea.save(request, function () {
                                ToggleLD.hide();
                                $mdDialog.hide();
                                mwalc.refresh();
                                Notification.success("Work area is created");
                                if ($routeParams.verification) {
                                    $location.path('/dashboard').search({});
                                }
                            }, function (error) {
                                ToggleLD.hide();
                                ErrorHandler.onError(error, "Unable to create work area");
                            });
                        }
                    };

                    $scope.closeDialog = function () {
                        $mdDialog.hide();
                    };
                }
            };

        }]);
