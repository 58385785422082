import app from "js/legacy-app";

app.controller('CreateToolCtrl', ['$scope', 'ErrorHandler', 'Tools', 'User', 'ToggleLD', '$location', '$timeout', '$q',
    function ($scope, ErrorHandler, Tools, User, ToggleLD, $location, Notification) {

        $scope.tool = {

            get: function () {
                return {
                    name: $scope.tool.name,
                    type: $scope.tool.type
                };
            },

            refresh: function () {
                if ($scope.tool.id) {
                    ToggleLD.show();
                    Tools.get({id: $scope.tool.id}, function (t) {
                        ToggleLD.hide();
                        $scope.tool = t;
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error, "Failed to load tool details");
                    });
                }
            },

            save: function (form) {
                if (form.$valid) {
                    ToggleLD.show();
                    Tools.save(this.get(), function (t) {
                        ToggleLD.hide();
                        $location.url("/admin/view-tool/" + t.id);
                        Notification.success("Tool was successfully created");
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error, "Failed to save tool");
                    });

                }
            },

            close: function () {
                $location.path("/tools");
            },

            toolTypes: [
                'INTERMEDIATE',
                'EXPERT'
            ]
        };

        $scope.tool.refresh();
    }
]);