import app from "js/legacy-app";

app.service('TypeOfBikes', ['$resource', function($resource){
    return $resource('/api/type-of-bikes/:id', {}, {
        update: {
            method: "PUT",
            params: {id : ""},
            isArray: false
        },
        search: {
            method: "POST",
            isArray: false,
            params: {id: "search"}
        }
    });
}]);