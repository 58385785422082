import app from "js/legacy-app";

app.controller('LpMechanicsByCityController',
    ['$scope', '$rootScope', 'Mechanics', '$routeParams', 'ErrorHandler', 'ToggleLD', 'LocationService', '$location', '$pageHeader', 'MetaInfo',
        function ($scope, $rootScope, Mechanics, $routeParams, ErrorHandler, ToggleLD, LocationService, $location, $pageHeader, MetaInfo) {

            $pageHeader.hide();
            $scope.lpmbl = {
                location: {
                    city: $routeParams.city,
                    state: $routeParams.state,
                    country: 'United States'
                },
                map: {},
                goToSearch: function (location) {
                    $location.path("/mechanics").search({
                        location: LocationService.serializeAddress(location),
                        bounds: LocationService.serializeBounds(location.bounds)
                    });
                }
            };

            ToggleLD.show();
            Mechanics.getActiveMechanicsInCity({
                id: $scope.lpmbl.location.country,
                path: $scope.lpmbl.location.state,
                subId: $scope.lpmbl.location.city
            }, function (response) {
                ToggleLD.hide();
                if (!response.mechanics || response.mechanics.length === 0) {
                    $location.path('not-covered-location').search({
                        location: LocationService.serializeAddress($scope.lpmbl.location)
                    });
                } else {
                    $scope.lpmbl.mechanics = response.mechanics;
                    setUpArea(response.area);
                    setupPageMetaInfo();
                    $scope.lpmbl.loaded = true;
                }
            }, function (error) {
                ToggleLD.hide();
                ErrorHandler.onError(error, "Unable to load mechanics");
            });

            var setupPageMetaInfo = function () {
                MetaInfo.manualChange(
                    "Best mobile bicycle mechanics in " + $scope.lpmbl.location.city + " — Velotooler " + $scope.lpmbl.location.city + " " + (new Date()).getFullYear(),   // title
                    "Find best bicycle mechanics in " + $scope.lpmbl.location.city + " and nearby to repair, tune-up and maintain your bicycle at your home or workplace in suitable for you time",
                    "local bicycle mechanics in " + $scope.lpmbl.location.city + ", " + "mobile bicycle service in " + $scope.lpmbl.location.city,
                    require("img/bicycle-assembly/lp-header.png")
                );
            };

            var setUpArea = function (geometry) {
                if (!geometry) {
                    return;
                }
                ToggleLD.hide();
                var areas = $scope.lpmbl.map.addGeoJsonPolygon(geometry);
                var bounds = new window.google.maps.LatLngBounds();
                areas.forEach(function (a) {
                    a.getPath().forEach(function (point) {
                        bounds.extend(point);
                    });
                });
                $scope.lpmbl.map.setCenter(bounds);
            };

        }]);