import app from "js/legacy-app";
import angular from "angular";

app.directive('createCustomServiceCategory', ['$vtSidenav', function ($vtSidenav) {
    return {
        templateUrl: "components/service/create/create-custom-service-category.html",
        replace: true,
        restrict: 'E',
        transclude: true,
        scope: {
            onSave: '&ccscOnSave'
        },
        controller: ['$scope', 'CustomServiceCategories', 'SidenavLD', 'Notification',
            function ($scope, CustomServiceCategories, SidenavLD, Notification) {

                $scope.cscv = {};

                $scope.cscv.onOpen = function (model, onSuccess, onError) {
                    onSuccess();
                };

                $scope.cscv.save = function (onError) {
                    SidenavLD.show();
                    CustomServiceCategories.save({name: $scope.cscv.category.name}, function (data) {
                        SidenavLD.hide();
                        $vtSidenav("create-custom-service-category").close();
                        if (angular.isFunction($scope.onSave)) {
                            $scope.onSave();
                        }
                        Notification.success('New service category was created');
                    }, function (error) {
                        onError(error);
                        SidenavLD.hide();
                    });
                };
            }]
    };
}]);