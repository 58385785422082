import app from "js/legacy-app";

app.controller('ErrorDialogCtrl', ['$scope', '$mdDialog', 'errorMessages', 'title',
    function ($scope, $mdDialog, errorMessages, title) {
        $scope.edc = {
            errorMessages: errorMessages,
            title: title,

            close: function () {
                $mdDialog.hide();
            }
        };
    }]);