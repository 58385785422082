import app from "js/legacy-app";
import defaultBackgroundImage from "img/background-for-chat.jpg";
import defaultMechanicImage from "img/mechanic-default-picture.png";

app.controller('MechanicProfileViewCtrl', [
    '$scope',
    '$location',
    '$routeParams',
    '$dateTimeService',
    '$timeout',
    'AuthService',
    'User',
    'CoreServiceManagement',
    'ErrorHandler',
    'MetaInfo',
    'ModalService',
    'MechanicWorkArea',
    '$q',
    'ToggleLD',
    '$mechanicService',
    '$mechanicPublicServicesProvider',
    'Service',
    'MechanicSchedule',
    function (
        $scope, $location, $routeParams, $dateTimeService, $timeout, AuthService, User, CoreServiceManagement, ErrorHandler,
        MetaInfo, ModalService, MechanicWorkArea, $q, ToggleLD, $mechanicService, $mechanicPublicServicesProvider, Service,
        MechanicSchedule
    ) {

        const id = $routeParams.id;

        let loadWorkArea = function (mechanicId) {
            return MechanicWorkArea.get({
                mechanic: mechanicId,
                active: true,
                detalization: 'S'
            }, function (workArea) {
                if (workArea && workArea.areas && workArea.areas.length > 0) {
                    $scope.showMechanic.workingArea.disabled = false;
                    $scope.showMechanic.mechanic.addresses = workArea.areas.sort(function (a1, a2) {
                        return (a2.bounds.radius || 0) - (a1.bounds.radius || 0);
                    }).map(function (a) {
                        return a.bounds.centralLocation;
                    });
                    $scope.showMechanic.mechanic.cities = $scope.showMechanic.mechanic.addresses.map(function (l) {
                        return l.city;
                    }).join(", ");


                    $timeout(function () {
                        $scope.showMechanic.map.init(function () {
                            let bounds = new window.google.maps.LatLngBounds();
                            workArea.areas.forEach(function (area) {
                                let areaCenter = area.bounds.center.coordinates;

                                let circle = $scope.showMechanic.map.addCircle({
                                    lat: areaCenter[1],
                                    lng: areaCenter[0]
                                }, area.bounds.radius, {
                                    fillColor: "#ffec92",
                                    fillOpacity: 0.3
                                });
                                bounds.union(circle.getBounds());
                            });

                            $scope.showMechanic.workingArea.addresses = $scope.showMechanic.mechanic.addresses
                                .map(function (l) {
                                    return l.city + ", " + l.state + ", " + l.country;
                                })
                                .reduce((unique, item) => {
                                    return unique.includes(item) ? unique : [...unique, item];
                                }, []);
                            $scope.showMechanic.map.setCenter(bounds);
                        });
                    });

                } else {
                    $scope.showMechanic.workingArea.disabled = true;
                }
            }).$promise;
        };

        let loadServices = function (mechanicId) {
            return Service.getMechanicPopularServices({id1: mechanicId}).$promise;
        };

        let loadScheduleSettings = (mechanicId) => {
            return MechanicSchedule.loadPublicSettings({mechanicId: mechanicId}).$promise;
        };

        $scope.isPublicMechanic = true;

        $scope.showMechanic = {
            mechanic: {
                addresses: [],
                services: []
            },
            reviews: [],
            aboutSectionMinHeight: 110,
            map: {},
            active: false,
            workingArea: {
                disabled: true,
                addresses: []
            },

            auth: AuthService
        };

        $scope.showMechanic.showScheduleBtn = function () {
            let active = $scope.showMechanic.active;
            if (!active) {
                return false;
            }

            if (!AuthService.isLogin()) {
                return true;
            }

            return !(AuthService.isMechanic() || AuthService.isAdmin() || AuthService.isPartiallyRegistered());
        };

        $scope.showMechanic.getDisplayedServices = function () {
            let mainServices = $scope.showMechanic.mechanic.services.filter(function (s) {
                return s.main;
            });
            return mainServices.length > 0 ? mainServices : $scope.showMechanic.mechanic.services.slice(0, 7);
        };

        $scope.showMechanic.showServicesAndPrices = function ($event) {
            $mechanicPublicServicesProvider.showServicesAndPrices(
                $event, $scope.showMechanic.mechanic, null,
                !$scope.showMechanic.showScheduleBtn()
            );
        };

        $scope.showMechanic.schedule = function (service) {
            if (service) {
                $mechanicService.scheduleMechanic(
                    null, $scope.showMechanic.mechanic,
                    $scope.location, null, service
                );
            } else {
                $mechanicService.scheduleMechanic(
                    null, $scope.showMechanic.mechanic,
                    $scope.location, null
                );
            }
        };

        $scope.showMechanic.onRatingLoad = function (params) {
            if (!params.data) {
                return;
            }

            params.data.forEach(function (review) {
                MetaInfo.addReviewMarkup({
                    author: [review.user.name, review.user.surname].join(" "),
                    publishDate: new Date(review.createdAt),
                    description: review.comment,
                    item: {
                        name: [$scope.showMechanic.mechanic.name, $scope.showMechanic.mechanic.surname].join(" "),
                        url: $location.url(),
                        image: $scope.showMechanic.mechanic.avatar.thumbnail,
                        rating: $scope.showMechanic.mechanic.rating,
                        reviewCount: $scope.showMechanic.mechanic.reviewsCount
                    },
                    rating: review.overallService
                });
            });
        };

        (function loadMechanicInfo() {
            ToggleLD.show();
            User.getMechanicInfo({id1: id}, function (data) {
                ToggleLD.hide();

                $scope.ratingFilter = {
                    mechanicId: data.id
                };

                $scope.showMechanic.loaded = true;

                loadServices(data.id).then(function (data) {
                    $scope.showMechanic.mechanic.services = data;
                }, function (error) {
                    ErrorHandler.onError(error);
                });

                loadScheduleSettings(data.id).then(function (data) {
                    $scope.showMechanic.mechanic.scheduleSettings = data;
                }, function (error) {
                    ErrorHandler.onError(error);
                });

                for (var i in data) {
                    $scope.showMechanic.mechanic[i] = data[i];
                }

                $scope.showMechanic.active = $scope.showMechanic.mechanic.active;

                let name = $scope.showMechanic.mechanic.name + ' ' + $scope.showMechanic.mechanic.surname,
                    avatarUrl = null;

                if ($scope.showMechanic.mechanic.avatar) {
                    if ($scope.showMechanic.mechanic.avatar.thumbnail) {
                        avatarUrl = $scope.showMechanic.mechanic.avatar.thumbnail;
                    }
                } else {
                    $scope.showMechanic.mechanic.avatar = {thumbnail: defaultMechanicImage};
                }

                let backgroundImageUrl = $scope.showMechanic.mechanic.cover
                    ? $scope.showMechanic.mechanic.cover.original
                    : defaultBackgroundImage;

                $scope.showMechanic.coverBackground = {
                    'background': "url('" + backgroundImageUrl + "') center center",
                    'background-size': "cover"
                };

                let createdAt = data.createdAt;
                if (!isNaN(createdAt)) {
                    let day = $dateTimeService.day(createdAt);
                    let month = $dateTimeService.month(createdAt);
                    let year = $dateTimeService.year(createdAt);

                    $scope.showMechanic.mechanic.created_at = month + ' ' + day + ', ' + year.slice(2, 4);
                }

                adjustRating();

                $q.all([loadWorkArea(data.id)]).then(function () {

                    let mainCitiesOr = $scope.showMechanic.mechanic.addresses.map(function (l) {
                        return l.city;
                    }).join(" or ");

                    let mainCitiesComma = $scope.showMechanic.mechanic.addresses.map(function (l) {
                        return l.city;
                    }).join(", ");

                    MetaInfo.manualChange(
                        name + " - trusted bicycle mechanic in " + mainCitiesComma,   // title
                        "Schedule " + $scope.showMechanic.mechanic.name + ' to repair your bicycle at your home or workplace in suitable for you time', // desc
                        "bicycle mechanic " + name + ", local bicycle mechanics in " + mainCitiesOr + ", " +
                        "mobile bicycle service in " + mainCitiesOr, // keywords
                        avatarUrl
                    );
                });

            }, function (error) {
                ToggleLD.hide();
                $location.path("/mechanics");
                ErrorHandler.onError(error);
            });
        })();

        $scope.readMore = function () {
            $scope.showMechanic.aboutSectionShowMore = !$scope.showMechanic.aboutSectionShowMore;
        };
        $scope.showMorePhotos = function () {
            $scope.showMechanic.photosSectionShowMore = !$scope.showMechanic.photosSectionShowMore;
        };

        $scope.showPicture = function ($event, img) {
            ModalService.showPicture($event, function () {
                return [img];
            }, 0, null, null);
        };

        function adjustRating() {
            let rating = $scope.showMechanic.mechanic.rating;
            if (!isNaN(rating)) {
                rating = rating.toFixed(0);
            } else {
                rating = 0;
            }
            $scope.showMechanic.mechanic.rating = rating;
        }
    }

]);
