import angular from "angular";

(function () {

    'use strict';

    function SaleCertificateService(AuthService) {

        var self = this;

        this.isComponentCertified = function(component) {
            return !!component.certified;
        };

        this.getComponentCertificationState = function(component) {
            if(self.isComponentCertified(component)) {
                return parseInt(component.condition.rate);
            }
        };

        this.getCertificationInfo = function (totalCondition) {
            return (function () {
                if (this.status) {
                    this.img = certificationImages('./' + this.status + '.png');
                }
                return this;
            }).call({
                status: (function () {
                    return self.getCertificationStatus(totalCondition);
                })()
            });
        };

        this.getCertificationStatus = function(totalCondition) {

            totalCondition = !isNaN(totalCondition) ? parseInt(totalCondition) : undefined;

            if(!totalCondition)
                return;

            if(totalCondition < 1.5) {
                return '1';
            } else if(totalCondition < 2.5) {
                return '2';
            } else {
                return '3';
            }
        };
    }

    const certificationImages = require.context('img/certification', true);
    var app = angular.module('legacy-app');
    app.service('SaleCertificateService', ['AuthService', SaleCertificateService]);
})();