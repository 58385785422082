import angular from "angular";
import imageUploader from "../../image-uploader";

const uploadAction = {
    template: require("./image-slider-upload-action.html"),
    require: {
        parent: '^vuiImageSlider'
    },
    bindings: {
        validationOptions: "<vuiUaValidationOptions",
        options: "<vuiUaOptions"
    },

    controller: function ($mdDialog) {
        "ngInject";

        this.upload = (event) => {

            const ctrl = this;

            $mdDialog.show({
                targetEvent: event,
                template: require('./image-slider-upload-dialog.html'),
                locals: {
                    documents: ctrl.parent.documents,
                    validationOptions: ctrl.validationOptions,
                    options: ctrl.options
                },
                bindToController: true,
                autoWrap: false,
                controllerAs: '$ctrl',
                controller: function ($mdDialog, ToggleLD, Notification) {
                    "ngInject";

                    this.$onInit = () => {
                        this.finishedUpload = true;
                    };

                    this.onUpload = (images, finishedUpload) => {
                        this.images = images;
                        this.finishedUpload = finishedUpload;
                    };

                    this.beforeUpload = (finishedUpload) => {
                        this.finishedUpload = finishedUpload;
                    };

                    this.done = () => {
                        if (!this.documents.entityId) {
                            this.images = this.images || [];
                            $mdDialog.hide(this.images.map((image) => {
                                return {
                                    id: image.id,
                                    original: image.originUrl,
                                    thumbnail: image.thumbnailUrl
                                }
                            }));
                            return;
                        }

                        if (this.images.length === 0) {
                            $mdDialog.hide();
                            return;
                        }

                        ToggleLD.show();

                        let uploadRequest = {
                            images: this.images.map((item) => {
                                return {id: item.id};
                            })
                        };

                        this.documents.upload(uploadRequest).then(function (response) {
                            ToggleLD.hide();
                            $mdDialog.hide(response.data.images);
                        }, function (error) {
                            ToggleLD.hide();
                            Notification.error("Failed to upload");
                        });
                    };

                    this.cancel = () => {
                        if (this.images && this.images.length > 0) {
                            this.documents.removeAllTemp({
                                images: this.images.map((image) => {
                                    return {
                                        id: image.id,
                                        original: image.originUrl,
                                        thumbnail: image.thumbnailUrl
                                    }
                                })
                            });
                        }
                        $mdDialog.hide([]);
                    }
                }
            }).then(function (images) {
                ctrl.parent.applyUploadedImages(images);
            });
        };
    }
};


export default angular.module("vui-image-slider-actions.module", [imageUploader.name])
    .component("vuiIsUploadAction", uploadAction);