import app from "js/legacy-app";

app.service('Station', ['$resource', function ($resource) {
    return $resource('/api/station/:param1/:param2', {}, {
        findByOrganisationId: {
            method: "GET",
            params: {param1: "by-organisation"},
            isArray: false
        },
        patch: {
            method: "PATCH",
            params: {param1: ""},
            isArray: false
        },
        clearAvatar: {
            method: "DELETE",
            params: {param1: "avatar"},
            isArray: false
        },
        getTypes: {
            method: "GET",
            params: {param1: "/type/all"},
            isArray: true
        },
        getByBikeId: {
            method: 'GET',
            params: {param1: "/by-bike"},
            isArray: false
        },
        findByName: {
            method: 'GET',
            params: {param1: "/search/organisation"},
            isArray: true
        }
    });
}]);