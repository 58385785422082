import app from "js/legacy-app";

app.service('Mechanics', ['$resource', function ($resource) {
    return $resource('/api/mechanics/:id/:path/:subId', {}, {
        search: {
            method: "GET",
            params: {id: '', path: '', detalization: 'M'},
            isArray: false
        },

        serviceAvailability: {
            method: "GET",
            params: {id: 'service-availability', path: '', detalization: 'M'},
            isArray: false
        },

        checkCoverage: {
            method: "POST",
            params: {path: 'check-coverage'},
            isArray: false
        },

        checkZipCodeCoverage: {
            method: "GET",
            params: {path: 'check-coverage'},
            isArray: false
        },

        getCoverage: {
            method: "GET",
            params: {path: 'coverage'},
            isArray: false
        },

        groupedByCity: {
            method: "GET",
            params: {id: 'group-by-city', path: ''},
            isArray: true
        },

        getActiveMechanicsInCity: {
            method: "GET",
            isArray: false
        },
        listForAdmin: {
            method: "GET",
            params: {path: 'admin/search'},
            isArray: false
        }
    });
}]);
