import './landing-page-carousel-item.component.scss';

export default () => {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        template: require('./landing-page-carousel-item.component.html')
    };
}
