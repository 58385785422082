import app from "js/legacy-app";
import angular from "angular";

app.directive('plMapFilter', ['$location', '$timeout', function ($location, $timeout) {
    return {
        template: '',
        require: '^^plFilter',
        scope: {
            name: '@mfName',
            label: '@mfLabel',
            model: "=mfMap",
            disabled: "=mfDisabled",
            onDisable: "=mfOnDisable",
            onActivate: "=mfOnActivate",
            onChange: "=mfOnChange",
            dependsOn: "@mfDependsOn"
        },
        link: function ($scope, element, $attrs, plFilter) {
            $scope.mf = {};
            $scope.mf.filter = plFilter.filter;
            $scope.model.changeZoom = false;

            if ($scope.model) {
                $scope.model.fitBounds = function (bounds) {
                    $scope.model.map.fitBounds(bounds);
                };
            }

            $scope.mf.filter.register($scope.name, $scope.mf);

            $scope.mf.onItemChange = function () {
                $timeout(function () {
                    if (!$scope.disabled && !$scope.mf.locked) {
                            if (angular.isFunction($scope.onChange)) {
                                $scope.onChange($scope.mf.getValue());
                            }
                            $scope.mf.filter.forceApply();
                        }
                    unlock();
                    }
                );
            };

            $scope.mf.isSelected = function () {
                return true;
            };

            $scope.mf.isSilent = function () {
                return true;
            };

            $scope.mf.getValue = function () {
                return $scope.model.map.getBounds();
            };

            $scope.mf.isValidValue = function () {
                var bounds = $scope.mf.getValue();
                return bounds.getSouthWest().lat() !== bounds.getNorthEast().lat() &&
                    bounds.getSouthWest().lng() !== bounds.getNorthEast().lng();
            };

            $scope.mf.serialize = function () {

                var serialized = {};
                var results = [];

                var bounds = $scope.mf.getValue();
                if (bounds && $scope.mf.isValidValue()) {
                    results.push(bounds.getSouthWest().lat());
                    results.push(bounds.getSouthWest().lng());
                    results.push(bounds.getNorthEast().lat());
                    results.push(bounds.getNorthEast().lng());

                    serialized[$scope.name] = results.join(';');
                    serialized.z = $scope.model.map.getZoom();
                    return serialized;
                } else {
                    return null;
                }
            };

            $scope.mf.deserialize = function (queryString) {
                if (queryString[$scope.name]) {
                    var bounds = queryString[$scope.name];
                    var boundsParts = bounds.split(';');

                    if (boundsParts.length === 4) {
                        var deserialized = new window.google.maps.LatLngBounds(
                            new window.google.maps.LatLng(boundsParts[0], boundsParts[1]),
                            new window.google.maps.LatLng(boundsParts[2], boundsParts[3])
                        );
                        $scope.model.map.fitBounds(deserialized);
                        if (queryString.z) {
                            var zoom = parseInt(queryString.z);
                            $scope.model.map.setZoom(zoom);
                            if (zoom === 4) {
                                //unlock();
                            }
                        }
                    }
                }
            };

            function lock() {
                $scope.mf.locked = true;
            }

            function unlock() {
                $scope.mf.locked = false;
            }

            function clear() {

            }

            $scope.mf.clear = clear;
            $scope.mf.lock = lock;
            //$scope.mf.unlock = unlock;
            $scope.mf.init = init;

            function init() {
                $scope.mf.deserialize($location.search());
                $scope.mf.filter.initialized($scope.name);

                if ($scope.dependsOn) {
                    $scope.$watch('mf.filter.filters.' + $scope.dependsOn + '.getValue()', function (newVal, oldVal) {
                        if (newVal !== oldVal) {
                            clear();
                        }
                    });
                }

                $scope.$watch('disabled', function (newVal, oldVal) {
                    $scope.model.disabled = newVal;
                    if (newVal !== oldVal) {
                        if (newVal && angular.isFunction($scope.onDisable)) {
                            $scope.onDisable();
                        } else if (!newVal) {
                            if (angular.isFunction($scope.onActivate)) {
                                $scope.onActivate();
                            }
                        }
                    }
                });

                var frequencyReduce = function (delay, callback) {
                    var timer;
                    return function () {
                        clearTimeout(timer);
                        timer = setTimeout(callback, delay);
                    };
                };

                $scope.model.lock = true;
                $scope.model.map.addListener('idle', frequencyReduce(500, function () {
                    if (!$scope.model.lock && $scope.mf.isValidValue() &&
                        (!$scope.mf.filter.isMobile() || $scope.mf.filter.isMobile() && !$scope.mf.filter.expanded)) {
                        $scope.mf.onItemChange();
                    }
                    $scope.model.lock = false;
                }));
            }

            init();
        }
    };
}]);
