import app from "js/legacy-app";
import insuranceBlank from 'img/card-back-blank.png';

app.directive('addInsurance', [function () {
    return {
        templateUrl: "template/settings/tabs/insurance/add-insurance.html",
        scope: {
            windowTitle: '@aiTitle',
            onComplete: '&aiOnComplete'
        },
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: ['$scope', 'Insurance', 'Notification', 'FileService', '$timeout', '$location',
            'ToggleLD', 'AuthService', 'Upload', 'ModalService', 'User', 'Documents', 'ErrorHandler', '$window',
            function ($scope, Insurance, Notification, FileService, $timeout, $location,
                      ToggleLD, AuthService, Upload, ModalService, User, Documents, ErrorHandler, $window) {

                $scope.insurance = {
                    defaultImg: insuranceBlank,
                    showImg: true,
                    disabled: false,
                    images: [],
                    type: 'VALIDATE_POLICY'
                };

                AuthService.currentUser(function () {
                    $scope.user = AuthService.user();
                });

                $scope.chooseValidatePolicy = function () {
                    $scope.insurance.type = 'VALIDATE_POLICY';
                    $scope.changeIndexTab(2);
                };

                $scope.choosePurchasePolicy = function () {
                    $scope.insurance.type = 'PURCHASE_POLICY';
                    $scope.changeIndexTab(1);
                };

                $scope.changeIndexTab = function (tabIndex) {
                    if (!isNaN(tabIndex)) {
                        $scope.selectedTab = tabIndex;
                    }
                    if ($scope.selectedTab === 1) {
                        $scope.title = 'Group Policy';
                    } else if ($scope.selectedTab === 2) {
                        $scope.title = 'Upload your Certificate of Insurance';
                    } else if ($scope.selectedTab === 3) {
                        $scope.title = 'Policy price';
                    } else {
                        $scope.title = $scope.windowTitle;
                    }
                };

                $scope.nextTab = function () {
                    $scope.changeIndexTab($scope.selectedTab + 1);
                };

                $scope.prevTab = function () {
                    if ($scope.selectedTab === 0) {
                        $window.history.back();
                        return;
                    }

                    if ($scope.insurance.type === 'VALIDATE_POLICY' && $scope.selectedTab === 2) {
                        $scope.changeIndexTab(1);
                    }

                    $scope.changeIndexTab($scope.selectedTab - 1);
                };

                $scope.submitUploadForm = function () {
                    if (!$scope.insurance.document) {
                        Notification.error('Upload the front page of insurance');
                        return;
                    }

                    $scope.nextTab();
                };

                $scope.chooseFile = function (files, invalidFiles) {
                    var file;
                    if (files[0]) {
                        file = files[0];
                    } else if (invalidFiles[0]) {
                        file = invalidFiles[0];
                    }

                    if (!FileService.isValidFile(file)) {
                        return;
                    }

                    $scope.insurance.document = file;
                };

                $scope.submitAmount = function (form) {
                    if (form.$valid) {
                        upload();
                    }
                };

                $scope.changeIndexTab(0);

                $scope.joinGroupPolicy = function ($event) {
                    User.chooseGroupInsurance(function (data) {
                        ModalService.showMessage($event, 'Thank you for joining the Velotooler group policy.', function () {
                            $scope.onComplete();
                        });
                    }, function (error) {
                        ErrorHandler.onError(error, "Failed to choose group policy");
                    });
                };

                var upload = function () {
                    save();
                };

                var save = function () {

                    ToggleLD.show();
                    var insuranceVerification = {
                        price: $scope.insurance.amount,
                        document: $scope.insurance.document
                    };

                    Documents.insurance().upload(insuranceVerification, function (response) {
                        ToggleLD.hide();
                        Notification.success('Document was successfully uploaded');
                        $scope.insurance.disabled = false;
                        $scope.onComplete();
                    }, function (error) {
                        ToggleLD.hide();
                        onUploadError(error);
                    });
                };

                var onUploadError = function (error) {
                    ToggleLD.hide();
                    $scope.insurance.disabled = false;
                    Notification.error("Failed to upload insurance policy");
                };

            }
        ]
    };
}]);
