import app from "js/legacy-app";

app.directive('uiPanelToggleBtn', [function () {
    return {
        restrict: 'C',
        link: function ($scope, $element, $attrs, ngModel) {
            new window.ui.uiPanelToggleBtn($element[0]);
        }
    };
}]);
