import app from "js/legacy-app";

app.controller('ShowPictureDialogController', ['$scope', '$mdDialog', 'getImages', 'index', 'remove', 'setDefault', 'setCover',
    function ($scope, $mdDialog, getImages, index, remove, setDefault, setCover) {

        var images = getImages();

        $scope.showRemove = !!remove;
        $scope.showSetCover = !!setCover;
        $scope.showSetDefault = !!setDefault;
        $scope.showChange = !!(remove || setDefault || setCover);
        $scope.index = index;

        $scope.change = function() {
            $scope.showChange = false;
        };

        $scope.setDefault = function() {
            setDefault($scope.index, function() {
                $scope.index = 0;
                $scope.showChange = true;
                images = getImages();
                setCurrent();
            });
        };

        $scope.remove = function() {
            remove($scope.index, function() {
                images = getImages();

                if (images.length === 0) {
                    $scope.close();
                    return;
                }

                if ($scope.index === images.length) {
                    $scope.index = 0;
                }
                setCurrent();
                $scope.showChange = true;
            });
        };

        $scope.setCover = function() {
            setCover($scope.index, function() {
                $scope.showChange = true;
                images = getImages();
            });
        };


        $scope.next = function() {
            if (images.length !== 1) {
                $scope.showPicture = {};
            }
            if ($scope.index + 1 === images.length) {
                $scope.index = 0;
            } else {
                $scope.index++;
            }
            setCurrent();
        };

        $scope.prev = function() {
            if (images.length !== 1) {
                $scope.showPicture = {};
            }
            if ($scope.index === 0) {
                $scope.index = images.length - 1;
            } else {
                $scope.index--;
            }
            setCurrent();
        };

        $scope.isPaging = function() {
            return images.length > 1;
        };

        var setCurrent = function() {
            $scope.showPicture = images[$scope.index];
        };

        setCurrent();

        $scope.close = function() {
            $mdDialog.hide();
        };
    }
]);