import app from "js/legacy-app";
import angular from "angular";

app.directive('notificationList', ['$timeout', '$pageScroll', function ($timeout, $pageScroll) {
    return {
        templateUrl: "components/notification/template/notification-list.html",
        scope: {},
        replace: true,
        transclude: true,
        link: function (scope, element, attr) {

            angular.element(document.querySelector('.notification-list__content')).bind('scroll', function (e) {
                scope.$apply(function () {
                    scope.nl.needShowArrowButton = e.target.scrollTop > 0;
                });
            });

            scope.nl.scrollToTop = function () {
                var e = element[0].getElementsByClassName('notification-list__content')[0];
                $pageScroll.scrollTo(0, 500, e);
            };

        },
        controller: ['$scope', '$rootScope', 'AuthService', 'NotificationWSService', '$window', '$pageScroll',
            function ($scope, $rootScope, AuthService, NotificationWSService, $window, $pageScroll) {

                var nl = {
                    clickedTheActionButton: false,
                    userIsRegistered: false
                };

                $scope.nl = {

                    remove: function (index, id) {
                        if ($rootScope.notifications.length <= 0) {
                            return;
                        }

                        var needLoadData = false;
                        if ($rootScope.notificationPage &&
                            $rootScope.notificationPage.next &&
                            $rootScope.notifications.length <= 10) {
                            needLoadData = true;
                        }

                        NotificationWSService.send({
                            operation: 5,
                            token: AuthService.getToken().access_token,
                            notificationId: id,
                            page: 0,
                            size: 1,
                            needLoadData: needLoadData,
                            lastNotificationId: $rootScope.notifications[$rootScope.notifications.length - 1].id
                        });
                        $rootScope.notifications.splice(index, 1);
                    }
                };

                $scope.nl.close = function () {
                    $pageScroll.show();
                };

                $scope.nl.inetConnectionStatus = function () {
                    return $rootScope.inetConnectionStatus;
                };

                $scope.nl.getNotifications = function () {
                    return $rootScope.notifications;
                };

                $scope.nl.isNotificationScrollDisabled = function () {
                    return $rootScope.notificationScrollDisabled;
                };

                $scope.nl.followNotification = function (notification, event) {
                    if (!nl.clickedTheActionButton) {
                        $rootScope.goToNegotiation(notification);
                    }
                    nl.clickedTheActionButton = false;
                };

                $scope.nl.onActionClick = function (notification) {
                    nl.clickedTheActionButton = true;
                    var action = notification.actions.BUTTON[0];
                    if (action.targetBlank) {
                        $window.opener = null;
                        $window.open(action.url, "_blank");
                        if (!notification.read) {
                            NotificationWSService.readNotification(notification.id, AuthService.getToken());
                        }
                    } else {
                        $rootScope.onNotificationActionBtnClick(notification, action);
                    }
                };

                $scope.nl.loadMoreNotifications = function () {
                    if ($scope.nl.isNotificationScrollDisabled() || !nl.userIsRegistered) {
                        return;
                    }

                    if ($rootScope.notificationPage && !$rootScope.notificationPage.next) {
                        return;
                    }

                    $rootScope.notificationScrollDisabled = true;
                    var request = {
                        operation: 4,
                        token: AuthService.getToken().access_token,
                        page: 0,
                        size: 10
                    };
                    if ($rootScope.notifications && $rootScope.notifications.length > 0) {
                        request.lastNotificationId = $rootScope.notifications[$rootScope.notifications.length - 1].id;
                    }

                    NotificationWSService.send(request);
                };

                nl.onRegisterNotifications = function (message) {
                    nl.userIsRegistered = true;
                    if ($scope.nl.getNotifications().length === 0) {
                        $scope.nl.loadMoreNotifications();
                    }
                };

                $scope.$on("$destroy", function () {
                    nl.userIsRegistered = false;
                    NotificationWSService.unregisterHandler('REGISTRATION', 'general');
                    NotificationWSService.close();
                    $rootScope.notificationList = {};
                    $rootScope.notifications = [];
                    $rootScope.notificationTotalCount = 0;
                    $rootScope.notificationPage = null;
                });

                (function init() {
                    NotificationWSService.registerHandler('REGISTRATION', 'general', nl.onRegisterNotifications);
                    AuthService.currentUser(function (user) {
                        if (user) {
                            NotificationWSService.connect(AuthService.getToken());
                        }
                    });
                })();
            }]
    };
}]);
