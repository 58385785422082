import angular from "angular";
import "angular-plaid-link"

export default angular
    .module("ngPlaid", ['angular-plaid-link'])
    .config([
        'plaidLinkProvider',

        function(plaidLinkProvider) {
            plaidLinkProvider.init({
                clientName: 'Velotooler',
                env: 'sandbox',
                key: 'a0123a87346b5f6d67d0035bb6f6c1',
                product: 'auth',
                selectAccount: true
            });
        }
    ]);
