export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/contact-us', 'contact-us')
        .when('/terms-and-conditions', 'terms-and-conditions')
        .when('/privacy-policy', 'privacy-policy')
        .when('/payment-process', 'payment-process')

        .segment('contact-us', {
            access: {requireLogin: false},
            template: '<contact-us-page></contact-us-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "contact-us.module" */ "./contact-us/contact-us.module");
            }
        })
        .segment('terms-and-conditions', {
            access: {requireLogin: false},
            template: '<terms-and-conditions-page></terms-and-conditions-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "terms-and-conditions.module" */ "./terms-and-conditions/terms-and-conditions.module");
            }
        })
        .segment('privacy-policy', {
            access: {requireLogin: false},
            template: '<privacy-policy-page></privacy-policy-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "privacy-policy.module" */ "./privacy-policy/privacy-policy.module");
            }
        })
        .segment('payment-process', {
            access: {requireLogin: false},
            template: '<payment-process-page></payment-process-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "payment-process.module" */ "./payment-process/payment-process.module");
            }
        });
}
