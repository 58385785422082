import angular from "angular";

import MechanicServiceBookingComponent from "./service-booking.component";
import MechanicServiceBookingModuleRouting from "./service-booking.module.routing";
import ServiceBooking from "../../../vt-domain/components/create-booking";
import PageLayout from "../../../vt-generic/components/page";
import ServiceBookingActions from "../../../vt-domain/components/create-booking/service-booking-actions"

export default angular
    .module("vt-mechanic-service-booking", [
        ServiceBooking.name,
        ServiceBookingActions.name,
        PageLayout.name
    ])
    .config(MechanicServiceBookingModuleRouting)
    .component("mechanicServiceBooking", MechanicServiceBookingComponent);