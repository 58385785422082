import app from "js/legacy-app";

app.controller('ShowRepeatedSerialNumbersController', ['$scope', '$mdDialog', 'bikes',
    function ($scope, $mdDialog, bikes) {

        $scope.repeated = {
            bikes: bikes
        };

        $scope.close = function () {
            $mdDialog.hide();
        };
    }
]);