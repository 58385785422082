import app from "js/legacy-app";

app.controller('SelectOrganisationCtrl',
    ['$scope', '$mdDialog', 'Organisation', 'ErrorHandler', '$location', 'Notification', 'serviceBucket',
        function ($scope, $mdDialog, Organisation, ErrorHandler, $location, Notification, serviceBucket) {

            $scope.soc = {
                organisation: {}
            };

            Organisation.list({serviceBucket: serviceBucket, size: 100}, function (response) {
                $scope.soc.organisations = response.data;
            }, function (error) {
                ErrorHandler.onError(error, "Failed to load organisations");
            });

            $scope.close = function () {
                $mdDialog.hide("close");
                $location.path("/dashboard");
            };

            $scope.submit = function (form) {
                if (form.$valid) {
                    if ($scope.soc.organisation.id) {
                        $mdDialog.hide($scope.soc.organisation);
                    } else {
                        Notification.warning("Please select organisation");
                    }
                }
            };
        }
    ]);