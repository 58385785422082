// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/img/user-no-avatar.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"verification-tab\">\n    <form novalidate>\n        <div class=\"row\">\n            <div class=\"col-sm-6\">\n                <div class=\"avatar center\">\n                    <div data-ng-if=\"!showUser.user.avatar\">\n                        <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\">\n                    </div>\n                    <div data-ng-if=\"showUser.user.avatar\">\n                        <img data-ng-src=\"{{showUser.user.avatar.original}}\" class=\"current\">\n                    </div>\n                </div>\n            </div>\n\n            <div class=\"col-sm-6\">\n                <div class=\"row review-row\">\n                    <div class=\"col-xs-6\">\n                        <label class=\"md-label review-label\">First Name</label>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <label class=\"md-label\">{{ showUser.user.name }}</label>\n                    </div>\n                </div>\n\n                <div class=\"row review-row\">\n                    <div class=\"col-xs-6\">\n                        <label class=\"md-label review-label\">Last Name</label>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <label class=\"md-label\">{{ showUser.user.surname }}</label>\n                    </div>\n                </div>\n\n                <div class=\"row review-row\">\n                    <div class=\"col-xs-6\">\n                        <label class=\"md-label review-label\">Phone</label>\n                    </div>\n                    <div class=\"col-xs-6\">\n                        <label class=\"md-label\">\n                            <a class=\"phone-link\" href=\"tel:{{ showUser.user.phone }}\">{{ showUser.user.phone }}</a>\n                        </label>\n                    </div>\n                </div>\n            </div>\n        </div>\n    </form>\n</div>";
// Exports
var _module_exports = code;;
var path = 'components/request/view/user-profile.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;