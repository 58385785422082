export default () => {
    return {
        restrict: 'A',
        link: ($scope, $element, $attr) => {
            $element[0].addEventListener("click", (e) => {
                const element = e.currentTarget;
                const url = removeURLParameter(element.href, "_gl");
                element.href = removeURLParameter(url, "_ga");
            });

            const removeURLParameter = (url, parameter) => {
                //prefer to use l.search if you have a location/link object
                let urlparts = url.split('?');
                if (urlparts.length >= 2) {

                    let prefix = encodeURIComponent(parameter) + '=';
                    let pars = urlparts[1].split(/[&;]/g);

                    //reverse iteration as may be destructive
                    for (let i = pars.length; i-- > 0;) {
                        //idiom for string.startsWith
                        if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                            pars.splice(i, 1);
                        }
                    }

                    return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
                }
                return url;
            }
        }
    };
}
