import app from "js/legacy-app";

app.filter('componentsByPart', function () {
    return function (components, part) {

        if(components.length > 0) {
            var splitIndex = components.length / 2;

            return (part === 1 ? components.slice(0,splitIndex) : components.slice(splitIndex));
        }
    };
});