import app from "js/legacy-app";

app.service('ServiceExecutionFlow', ['$resource', function ($resource) {
    return $resource('/api/service-execution-flow/:id', {}, {
        list: {
            method: "GET",
            isArray: false,
            params: {id: ""}
        },
        patch: {
            method: "PATCH",
            params: {id: ""},
            isArray: false
        }
    });
}]);