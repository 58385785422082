import angular from "angular";

import "./landing-page-carousel.component.scss";

export default {
    template: require('./landing-page-carousel.component.html'),
    transclude: true,
    controller: function ($scope, $element, $timeout, $interval) {
        'ngInject';

        const timerDelay = 3000;
        let animating = false;

        $scope.next = () => {
            if (animating) {
                return;
            }

            animating = true;
            const lis = $element.children().find('li');
            const $slicedCard = angular.element(lis[lis.length - 1]);
            const $newCard = $slicedCard.clone();
            $slicedCard.removeClass('transformPrev').addClass('transformThis');
            angular.element(lis[lis.length - 2]).addClass('activeNow');

            $timeout(() => {
                $newCard.removeClass('transformThis activeNow');
                $element.children().find('ul').prepend($newCard);
            }, 200);
            $timeout(() => {
                $slicedCard.remove();
                restartTimer();
                animating = false;
            }, 500);
        }

        $scope.prev = () => {
            if (animating) {
                return;
            }

            animating = true;
            const lis = $element.children().find('li');
            lis.removeClass('transformPrev').removeClass('activeNow');
            const $last = angular.element(lis[lis.length - 1]);
            $last.addClass('activeNow');
            const $slicedCard = angular.element(lis[0]);
            const $newElement = $slicedCard.clone();
            $slicedCard.addClass('transformThis')
            $newElement.addClass('transformPrev');
            $element.children().find('ul').append($newElement);

            $timeout(() => {
                $slicedCard.remove();
            }, 200);
            $timeout(() => {
                $newElement.removeClass('transformPrev');
                $last.removeClass('activeNow');
                restartTimer();
                animating = false;
            }, 550);
        }

        const restartTimer = () => {
            if ($scope.stopTimer) {
                $interval.cancel($scope.stopTimer);
            }
            $scope.stopTimer = $interval($scope.next, timerDelay);
        }

        $element.on('$destroy', function () {
            $interval.cancel($scope.stopTimer);
        });

        restartTimer();
    }
};
