import angular from "angular";

import ComingSoonComponent from "./coming-soon.component";
import ComingSoonModuleRouting from "./coming-soon.module.routing";

import LandingPageModule from "../../components/landing-page/landing-page.module";
import VtSharedModule from '../../../vt-shared/vt-shared.module';

export default angular
    .module("vt-coming-soon", [
        LandingPageModule.name,
        VtSharedModule.name
    ])
    .config(ComingSoonModuleRouting)
    .component("comingSoon", ComingSoonComponent);
