import app from "js/legacy-app";
import mechanicDefaultImage from "img/mechanic-no-avatar.png";
import customerDefaultImage from "img/user-no-avatar.png";

app.directive('vtHeader', [function () {
    return {
        templateUrl: "template/component/vt-header.tpl.html",
        link: function ($scope, $element, $attrs, ngModel) {
            new window.ui.header($element[0].firstChild);

            $scope.isFullScreen = window.ui.resolutionService.isFullScreen();
            $scope.isMobile = window.ui.resolutionService.isMobile();
            $scope.isTablet = window.ui.resolutionService.isTablet();

            window.addEventListener('resize', function() {
                $scope.$apply(function() {
                    $scope.isFullScreen = window.ui.resolutionService.isFullScreen();
                    $scope.isMobile = window.ui.resolutionService.isMobile();
                    $scope.isTablet = window.ui.resolutionService.isTablet();
                });
            });
        },
        controller: ['$scope', '$location', '$window', '$mdSidenav', 'AuthService',
            function ($scope, $location, $window, $mdSidenav, AuthService) {
                var currentState = $location.path();

                $scope.auth = AuthService;

                $scope.logout = function () {
                    $scope.auth.logout();
                };

                $scope.isLoggedIn = function () {
                    if (!AuthService.isFullyLoggedIn()) {
                        return {'display': 'none'};
                    }
                };

                $scope.getUserAvatar = function () {
                    if (AuthService.user().avatar) {
                        return AuthService.user().avatar.thumbnail;
                    }

                    if (AuthService.isMechanic()) {
                        return mechanicDefaultImage;
                    }

                    return customerDefaultImage;
                };

                $scope.$on('$routeChangeSuccess', function () {
                    currentState = $location.path();
                    $scope.isHomePage = currentState === '/home' || currentState === '/';
                });
            }
        ]
    };
}]);
