import app from "js/legacy-app";

app.controller('AddQuizCtrl', ['$scope', 'Quiz', '$routeParams', '$location', function ($scope, Quiz, $routeParams, $location) {

    var id = $routeParams.id;

    $scope.types = [
        'INTERMEDIATE',
        'EXPERT'
    ];

    if (id) {
        $scope.title = 'Edit Quiz';
        Quiz.get({id: id}, function(data) {
            $scope.quiz = data;
        });
    } else {
        $scope.title = 'Add Quiz';
        $scope.quiz = {
            question: '',
            answers: [{
                answer: '',
                right: false
            }],
            type: 'INTERMEDIATE'
        };
    }

    $scope.addComponent = function() {
        $scope.quiz.answers.push({
            answer: '',
            right: false
        });
    };

    $scope.removeAnswer = function(index) {
        $scope.quiz.answers.splice(index, 1);
    };

    $scope.save = function(form) {
        if (form.$valid) {
            if (id) {
                Quiz.update({id: id}, $scope.quiz, function (data) {
                    $location.path('/admin/manage-quiz');
                });
            } else {
                Quiz.save($scope.quiz, function (data) {
                    $location.path('/admin/manage-quiz');
                });
            }
        }
    };

}]);