import app from "js/legacy-app";
import angular from "angular";

app.directive('ellipsis', ['$timeout', '$window', '$sce', function ($timeout, $window, $sce) {

    var AsyncDigest = function (delay) {
        var timeout = null;
        var queue = [];

        this.remove = function (fn) {
            if (queue.indexOf(fn) !== -1) {
                queue.splice(queue.indexOf(fn), 1);
                if (queue.length === 0) {
                    $timeout.cancel(timeout);
                    timeout = null;
                }
            }
        };
        this.add = function (fn) {
            if (queue.indexOf(fn) === -1) {
                queue.push(fn);
            }
            if (!timeout) {
                timeout = $timeout(function () {
                    var copy = queue.slice();
                    timeout = null;
                    // reset scheduled array first in case one of the functions throws an error
                    queue.length = 0;
                    copy.forEach(function (fn) {
                        fn();
                    });
                }, delay);
            }
        };
    };

    var asyncDigestImmediate = new AsyncDigest(0);
    var asyncDigestDebounced = new AsyncDigest(75);

    return {
        restrict: 'A',
        scope: {
            ngShow: '=',
            ngBind: '=',
            ngBindHtml: '=',
            ellipsisAnimate: '=',
            ellipsisMinHeight: '=',
            ellipsisShowMore: '=',
            ellipsisIsOverflowed: '=',
            useParent: "@"
        },
        compile: function () {

            return function (scope, element, attributes) {
                /* Window Resize Variables */
                attributes.lastWindowResizeTime = 0;
                attributes.lastWindowResizeWidth = 0;
                attributes.lastWindowResizeHeight = 0;
                attributes.lastWindowTimeoutEvent = null;

                element.css('overflow', 'hidden');
                element.css('text-overflow', 'ellipsis');
                if (scope.ellipsisAnimate) {
                    element.css('transition', 'max-height ' + scope.ellipsisAnimate + 's');
                }

                var calculateOverflow = function() {

                    var thisElement = scope.useParent ? element.parent() : element;
                    scope.ellipsisIsOverflowed = thisElement[0].scrollHeight > scope.ellipsisMinHeight;

                };

                /**
                 *    Watchers
                 */

                /**
                 *    Execute ellipsis truncate on ngShow update
                 */
                scope.$watch('ngShow', function () {
                    asyncDigestImmediate.add(calculateOverflow);
                });

                /**
                 *    Execute ellipsis truncate on ngBind update
                 */
                scope.$watch('ngBind', function () {
                    asyncDigestImmediate.add(calculateOverflow);
                });

                /**
                 *    Execute ellipsis truncate on ngBindHtml update
                 */
                scope.$watch('ngBindHtml', function () {
                    asyncDigestImmediate.add(calculateOverflow);
                });

                /**
                 *    Execute ellipsis truncate on ngBind update
                 */
                scope.$watch('ellipsisShowMore', function () {
                    if (scope.ellipsisShowMore) {
                        element.css('max-height', element[0].scrollHeight + 'px');
                    } else {
                        element.css('max-height', scope.ellipsisMinHeight + 'px');
                    }
                    calculateOverflow();

                });

                /**
                 *    Execute ellipsis truncate when element becomes visible
                 */
                scope.$watchGroup([
                    function () {
                        return element[0].offsetWidth;
                    },
                    function () {
                        return element[0].offsetHeight;
                    }
                ], function () {
                    asyncDigestDebounced.add(calculateOverflow);
                });

                function checkWindowForRebuild() {
                    if (attributes.lastWindowResizeWidth !== window.innerWidth || attributes.lastWindowResizeHeight !== window.innerHeight) {
                        calculateOverflow();
                    }

                    attributes.lastWindowResizeWidth = window.innerWidth;
                    attributes.lastWindowResizeHeight = window.innerHeight;
                }

                var unbindRefreshEllipsis = scope.$on('dibari:refresh-ellipsis', function () {
                    asyncDigestImmediate.add(calculateOverflow);
                });

                /**
                 *    When window width or height changes - re-init truncation
                 */

                function onResize() {
                    asyncDigestDebounced.add(checkWindowForRebuild);
                }

                var $win = angular.element($window);
                $win.bind('resize', onResize);

                /**
                 * Clean up after ourselves
                 */
                scope.$on('$destroy', function () {
                    $win.unbind('resize', onResize);
                    asyncDigestImmediate.remove(calculateOverflow);
                    asyncDigestDebounced.remove(checkWindowForRebuild);
                    if (unbindRefreshEllipsis) {
                        unbindRefreshEllipsis();
                        unbindRefreshEllipsis = null;
                    }
                });


            };
        }
    };
}]);