import app from "js/legacy-app";
import moment from "moment";

app.service('$availabilityDates', ['$dateTimeService', function ($dateTimeService) {

    let generate = function (availableDates, weeklyAvailability, vacationPeriod) {
        let dates = [];

        for (let i = 0; i < availableDates.dates.length; i++) {
            let date = availableDates.dates[i];
            let dayOfWeek = $dateTimeService.getDayOfWeek(date.date);
            let dayAvailability = weeklyAvailability.filter((d) => dayOfWeek === d.dayOfWeek)[0];

            let periods = dayAvailability.available
                ? date.periods
                : defaultPeriodsOfDay;

            let day = {
                weekDay: $dateTimeService.week(date.date),
                day: $dateTimeService.day(date.date),
                month: $dateTimeService.month(date.date),
                morning: {
                    available: true,
                    timeSlots: []
                },
                afternoon: {
                    available: true,
                    timeSlots: []
                },
                evening: {
                    available: true,
                    timeSlots: []
                },
                available: dayAvailability.available,
                date: moment(date.date).toDate()
            };

            if (periods) {
                day.morning.available = periods.filter(function (p) {
                    return p.periodOfDay === 'MORNING' && !p.available;
                }).length === 0;
                day.afternoon.available = periods.filter(function (p) {
                    return p.periodOfDay === 'AFTERNOON' && !p.available;
                }).length === 0;
                day.evening.available = periods.filter(function (p) {
                    return p.periodOfDay === 'EVENING' && !p.available;
                }).length === 0;
            }

            if (day.morning.available) {
                day.morning.timeSlots = periods && periods[0].available && periods[0].timeSlots.length ? periods[0].timeSlots : generateTimeSlots('MORNING');
            }

            if (day.afternoon.available) {
                day.afternoon.timeSlots = periods && periods[1].available && periods[1].timeSlots.length ? periods[1].timeSlots : generateTimeSlots('AFTERNOON');
            }

            if (day.evening.available) {
                day.evening.timeSlots = periods && periods[2].available && periods[2].timeSlots.length ? periods[2].timeSlots : generateTimeSlots('EVENING');
            }

            if (day.available && (day.morning.available || day.afternoon.available || day.evening.available)) {
                dates.push(day);
            }
        }

        return dates;
    };

    let generateTimeSlots = function (pod) {
        switch (pod) {
            case 'MORNING': {
                return ['08:00', '08:15', '08:30', '08:45', '09:00', '09:15', '09:30', '09:45', '10:00', '10:15', '10:30', '10:45', '11:00', '11:15', '11:30', '11:45'];
            }

            case 'AFTERNOON': {
                return ['12:00', '12:15', '12:30', '12:45', '13:00', '13:15', '13:30', '13:45', '14:00', '14:15', '14:30', '14:45', '15:00', '15:15', '15:30', '15:45'];
            }

            case 'EVENING': {
                return ['16:00', '16:15', '16:30', '16:45', '17:00', '17:15', '17:30', '17:45', '18:00', '18:15', '18:30', '18:45', '19:00', '19:15', '19:30', '19:45', '20:00'];
            }
        }
    };

    let defaultPeriodsOfDay = [
        {
            "periodOfDay": "MORNING",
            "timeSlots": [],
            "available": false,
        },
        {
            "periodOfDay": "AFTERNOON",
            "timeSlots": [],
            "available": false,
        },
        {
            "periodOfDay": "EVENING",
            "timeSlots": [],
            "available": false,
        }
    ];

    return {
        generate: generate
    };
}]);
