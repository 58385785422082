import app from "js/legacy-app";
import angular from "angular";

app.directive('locationAutocomplete', ['Notification', 'LocationResource', '$timeout', 'LocationService',
    'User', 'ErrorHandler', '$q', 'AuthService',
    function (Notification, LocationResource, $timeout, LocationService, User, ErrorHandler, $q, AuthService) {
        return {
            restrict: 'E',
            transclude: true,
            replace: true,
            templateUrl: 'template/component/geo/location-autocomplete.html',
            scope: {
                model: '=laModel',
                query: '=?laQuery',
                onLocationChange: '=laOnLocationChange',
                fireChangeOnClear: '@laFireChangeOnClear',
                label: '@laLabel',
                detectCurrentLocation: '=?laDetectCurrentLocation',
                noFavorite: '=?laNoFavorite',
                required: '=?laRequired',
                autofocus: '=?laAutofocus',
                setUpFn: '=?laSetUpFn',
                labelIconClass: '@?laLabelIconClass',
                queryMinLength: '=?laQueryMinLength',
                restrictions: '=?laRestrictions',
                types: '@?laTypes',
                dropdownPosition: '@?laDropdownPosition',
            },
            link: function ($scope, element) {

                if (!$scope.autofocus) {
                    $scope.autofocus = false;
                }

                if (!$scope.query) {
                    $scope.query = '';
                }

                var la = {
                    showSpinnerIndicator: function () {
                        var spinner = document.getElementsByClassName('fa-spinner')[0];
                        if (spinner) {
                            spinner.classList.remove('display-none');
                        }
                    },

                    hideSpinnerIndicator: function () {
                        var spinner = document.getElementsByClassName('fa-spinner')[0];
                        if (spinner) {
                            spinner.classList.add('display-none');
                        }
                    },

                    showCloseButton: function () {
                        var autocomplete = element.find('md-autocomplete-wrap');
                        var button = autocomplete.find('button')[0];
                        if (button) {
                            button.style.display = 'inline';
                        }
                    }
                };

                $scope.la = {

                    auth: AuthService,

                    highlightIcons: {
                        'HOME': 'fa fa-home',
                        'WORK': 'fa fa-briefcase',
                        'OTHER': 'fa fa-star',
                        'DETECT_CURRENT_LOCATION': 'current-location-marker'
                    },

                    getAddressClassByType: function (type) {
                        return $scope.la.highlightIcons[type];
                    },

                    required: !!$scope.required,

                    currentLocation: {
                        canDetectCurrentLocation: !!$scope.detectCurrentLocation
                    }
                };

                $scope.la.displayItem = function (item) {
                    if (item && item.addressType && 'DETECT_CURRENT_LOCATION' === item.addressType) {
                        la.showCloseButton();
                        return " ";
                    }
                    if (item && !item.address) {
                        la.showCloseButton();
                        return item;
                    }
                    if (item && item.address) {
                        la.showCloseButton();
                        return item.address;
                    }
                    if ($scope.model && $scope.model.address) {
                        la.showCloseButton();
                        return $scope.model.address;
                    }

                    return null;
                };

                $scope.la.onItemChange = function () {
                    var selectedAddress = $scope.la.selected;
                    if (selectedAddress && 'DETECT_CURRENT_LOCATION' === selectedAddress.addressType) {
                        $scope.la.detectCurrentLocation();
                    } else if (selectedAddress && selectedAddress.placeId) {
                        selectAddress(selectedAddress);
                    } else {
                        $scope.model = null;
                    }
                };

                $scope.la.unsetFavorite = function (address) {
                    User.unsetFavoriteAddress({id1: address.addressId}, {}, function () {
                        address.favorite = false;
                        delete address.addressId;
                    }, function (error) {
                        ErrorHandler.onError(error);
                    });
                };

                $scope.la.setFavorite = function () {
                    var address = LocationService.mapAddress($scope.model);
                    User.setFavoriteAddress(address, function (data) {
                        data.favorite = true;
                        $scope.model = data;
                    }, function (error) {
                        ErrorHandler.onError(error);
                    });
                };

                function selectAddress(location) {
                    if (!location.addressType) {
                        placeDetailsByPlaceId(location, location.placeId);
                    } else {
                        $scope.model = location;
                    }

                    if (location.addressId) {
                        $scope.model.favorite = true;
                    }
                }

                function placeDetailsByPlaceId(location, placeId) {
                    LocationResource.placeDetails({placeId: placeId}, function (l) {
                        l.address = location.address;
                        l.apartment = location.apartment;
                        $scope.model = l;
                    });
                }

                $scope.la.search = function (request) {
                    var deferred = $q.defer();
                    var query = {
                        address: request
                    };
                    if ($scope.restrictions) {
                        query.restrictions = $scope.restrictions;
                    }
                    if ($scope.types) {
                        query.types = $scope.types;
                    }

                    LocationResource.autocomplete(query, function (data) {
                        if ($scope.detectCurrentLocation) {
                            data.unshift({addressType: 'DETECT_CURRENT_LOCATION'});
                        }
                        deferred.resolve(data);
                    }, function (error) {
                        deferred.reject(error);
                    });

                    return deferred.promise;
                };

                $scope.la.detectCurrentLocation = function () {
                    if (navigator.geolocation) {
                        la.showSpinnerIndicator();
                        navigator.geolocation.getCurrentPosition(function (position) {
                            var request = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            };

                            try {
                                LocationResource.getLocationByLatLng(request, {}, function (l) {
                                    $scope.la.currentLocation.apply(l);
                                }, function (error) {
                                    ErrorHandler.onError(error, "Unable to detect current location");
                                });
                            } finally {
                                la.hideSpinnerIndicator();
                                la.showCloseButton();
                            }
                        }, function (error) {
                            la.hideSpinnerIndicator();
                            la.showCloseButton();
                            Notification.warning("Geolocation is not supported by this browser");
                        });
                    } else {
                        Notification.warning("Geolocation is not supported by this browser");
                    }
                };

                $scope.la.currentLocation.apply = function (location) {
                    $scope.la.query = location.address;
                    var address = LocationService.mapAddress(location);
                    address.addressType = 'CURRENT';
                    $scope.la.selected = address;
                };

                $scope.$watch('model', function (newVal, oldVal) {
                    if ((newVal || $scope.fireChangeOnClear) && newVal !== oldVal && !$scope.la.silent) {
                        if (angular.isFunction($scope.onLocationChange)) {
                            $scope.onLocationChange($scope.model);
                        }

                        $timeout(function () {
                            la.showCloseButton();
                        });
                    }
                    $scope.la.silent = false;

                });

                $scope.$watch('autocompleteForm.$$parentForm.$submitted', function (newValue, oldValue) {

                    if (!$scope.autocompleteForm) {
                        return;
                    }

                    if (!!newValue && !oldValue) {
                        $scope.autocompleteForm.$setSubmitted();
                    }

                    if (oldValue) {
                        $scope.autocompleteForm.$submitted = false;
                        $scope.autocompleteForm.$$parentForm.$submitted = false;
                    }
                });

                (function init() {
                    $scope.la.selected = $scope.la.displayItem();

                    if (!$scope.queryMinLength) {
                        $scope.queryMinLength = 0;
                    }

                    if ($scope.model && $scope.model.addressId) {
                        $scope.model.favorite = true;
                    }

                    if (!$scope.noFavorite) {
                        $timeout(function () {
                            var autocompleteInput = document.getElementById('location-autocomplete-id');
                            if (!autocompleteInput) {
                                return;
                            }
                            autocompleteInput.classList.add('padding-right60');
                        });
                    }

                    $scope.setUpFn = function (value, silent) {
                        $scope.la.selected = value;
                        $scope.la.silent = silent;
                    };
                })();
            }
        };
    }]);
