import app from "js/legacy-app";

app.directive('vtModalMenu', ['$document', function ($document) {
    return {
        templateUrl: "components/modal-menu/vt-modal-menu.html",
        bindToController: true,
        controllerAs: 'ctrl',
        scope: {
            btnName: '@vmBtnName',
            onClick: '&vmOnClick'
        },
        transclude: {
            content: 'vtMenuContent'
        },
        link: function (scope, element, attr) {

            $document.bind('click', function (event) {
                scope.ctrl.menuIsOpened = false;

                scope.$apply(function () {
                    scope.ctrl.menuIsOpened = false;
                });
            });
        },

        controller: ['$scope', function ($scope) {
            this.openMenu = (event) => {
                this.menuIsOpened = !this.menuIsOpened;
                event.stopPropagation();
            };

            this.submit = (event) => {
                this.onClick({event: event});
            };
        }]
    };
}]);