import app from "js/legacy-app";

app.controller('ViewStationCtrl', ['$scope', '$routeParams', 'ModalService', 'AuthService',
    function ($scope, $routeParams, ModalService, AuthService) {

        $scope.callRefresh = {};

        $scope.station = {

            auth: AuthService,

            stationId: $routeParams.stationId,

            organisationId: $routeParams.organisationId,

            importBikes: function ($event) {
                var request = {
                    organisationId: $scope.station.organisationId,
                    stationId: $scope.station.stationId
                };
                ModalService.importBikes($event, request, function (response) {
                    if (response) {
                        if (response.bikes && response.bikes.length > 0) {
                            ModalService.showRepeatedBikes($event, response.bikes);
                        }

                        $scope.callRefresh.refresh();
                    }
                });
            }
        };
    }]);