import app from "js/legacy-app";

app.service('ChangeUrlService',
    ['$location', '$rootScope', 'Security', 'MainBackgroundStyle', 'MetaInfo', 'ShowMenuService', '$window', '$timeout',
        function ($location, $rootScope, Security, MainBackgroundStyle, MetaInfo, ShowMenuService, $window, $timeout) {
            $rootScope.$on('$locationChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                var path = $location.path();
                var url = $location.url();
                var scroll = true;

                for (var l = 0; l < noScrollPages.length; l++) {
                    if (url.indexOf(noScrollPages[l]) >= 0) {
                        scroll = false;
                        break;
                    }
                }

                if (scroll) {
                    $window.scrollTo(0, 0);
                }

                $timeout(function () {
                    $timeout(function () {
                        MainBackgroundStyle.change(url, path);
                    });
                });
                MetaInfo.change(url, path);
            });

            var noScrollPages = ['pageSearch']; //Don't scroll for search location pages
        }
    ]);
