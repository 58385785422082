import app from "js/legacy-app";

app.controller('BusinessBikeListController', ['$scope', '$bikeService', '$routeParams',
    function ($scope, $bikeService, $routeParams) {
        $scope.bl = {
            search: function (query) {
                return $bikeService.getResourceClass().list(query, {}).$promise;
            }
        };

        $scope.bl.getViewBikeUrl = function (bike) {

            var firstPart = (($routeParams.organisation || $routeParams.organisationId) ? '/organisation/' +
                    ($routeParams.organisation || $routeParams.organisationId) : '') +
                ($routeParams.station ? '/station/' + $routeParams.station : '');

            firstPart += '/bike/' + bike.id;
            if ($routeParams.stationQuery && $routeParams.station) {
                firstPart += '?stationQuery=' + $routeParams.stationQuery;
            }

            return firstPart;
        };

        $scope.bl.getAddBikeUrl = function () {
            var firstPart = (($routeParams.organisation || $routeParams.organisationId) ? '/organisation/' +
                    ($routeParams.organisation || $routeParams.organisationId) : '') +
                ($routeParams.station ? '/station/' + $routeParams.station : '');

            firstPart += '/add-bike';
            if ($routeParams.stationQuery && $routeParams.station) {
                firstPart += '?stationQuery=' + $routeParams.stationQuery;
            }
            return firstPart;
        };

        $scope.bl.organisation = $routeParams.organisation || $routeParams.organisationId;

        $scope.bl.routeParams = $routeParams;

        this.bl = $scope.bl;
    }]);
