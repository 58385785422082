import app from "js/legacy-app";

app.service('Brand', ['$resource', function ($resource) {
    return $resource('/api/brand/:id', {}, {
        update: {
            method: "PUT",
            params: {id: ""},
            isArray: false
        },
        list: {
            method: "GET",
            params: {id: "list"},
            isArray: false
        },
        autocomplete: {
            method: "GET",
            params: {id: "autocomplete"},
            isArray: true
        }
    });
}]);