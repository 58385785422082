import app from "js/legacy-app";
import angular from "angular";

app.directive('bikeSnValidation', ['$timeout', function($timeout) {

    function setValue(elm, value, scope, ngModelCtrl) {
        elm.val(value);
        ngModelCtrl.$setViewValue(value);

        $timeout(function () {
            elm.val(value);
            scope.$apply();
        });
    }

    return {
        require: 'ngModel',
        link: function (scope, elm, attrs, ngModelCtrl) {

            function formatBikeSN(e) {

                var elm = angular.element(e.currentTarget || e.srcElement);
                var value = elm.val();

                if (!value) {
                    return;
                }

                value = value.replace(/[^A-Za-z0-9]/g, '').toUpperCase().substr(0, 30);

                setValue(elm, value, scope, ngModelCtrl);

                e.preventDefault();
            }

            elm.on('input', formatBikeSN);
            elm.on('paste', formatBikeSN);
        }
    };
}]);
