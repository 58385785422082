import app from "js/legacy-app";

app.controller('ChatImgUploadDialogCtrl', ['$scope', '$mdDialog', 'AuthService', 'Notification',
    'ToggleLD', 'FileService', 'documents', '$filter', '$mdMedia', '$mobileBodyHeightCalculator',
    function ($scope, $mdDialog, AuthService, Notification, ToggleLD, FileService, documents, $filter, $mdMedia, $mobileBodyHeightCalculator) {

        $scope.upload = {
            documents: documents,
            images: {}
        };

        $scope.udc = {
            imageSize: $mdMedia('max-width: 470px') ? {height: 120, width: 120} : null
        };

        $scope.udc.disabledDoneBtn = function () {
            var filtered = $scope.upload.images.images.filter(function (image) {
                return !image.failed;
            });

            return filtered.length === 0;
        };

        $scope.udc.done = function () {
            var filtered = $scope.upload.images.images.filter(function (image) {
                return !image.failed;
            });

            if (filtered.length === 0) {
                $mdDialog.hide();
                return;
            }

            if (filtered.length > 10) {
                Notification.error("Should be 10 images or less");
                return;
            }

            var imagesToUpload = filtered.map(function (item) {
                return {
                    id: item.id
                };
            });

            var request = {
                images: imagesToUpload,
                comment: $scope.udc.comment ? $filter("newlines")($scope.udc.comment) : null
            };

            $mdDialog.hide(request);
        };

        $scope.udc.cancel = function () {

            var filtered = $scope.upload.images.images.filter(function (image) {
                return !image.failed;
            });

            if (filtered.length === 0) {
                $mdDialog.hide();
                return;
            }

            var imagesToRemove = filtered.map(function (item) {
                return {
                    id: item.id
                };
            });

            var removeRequest = {
                images: imagesToRemove
            };

            $scope.upload.documents.removeAllTemp(removeRequest);
            $mdDialog.hide();
        };

        $scope.udc.bodyHeight = function () {
            var header = document.getElementsByClassName('img-upload-dialog__mobile-header')[0];
            var footer = document.getElementsByClassName('img-upload-dialog__mobile-footer')[0];
            return $mobileBodyHeightCalculator.calculate(header, footer);
        };
    }]);
