import app from "js/legacy-app";

app.service('CoreServiceCategories', ['$resource', function ($resource) {
    return $resource('/api/core-service-categories/:id/:id1', {}, {
        patch: {
            method: "PATCH",
            params: {id: ""},
            isArray: false
        },

        autocomplete: {
            method: "GET",
            isArray: true,
            params: {id: "autocomplete"}
        }
    });
}]);