export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/admin/organisations', 'admin-organisation-list')
        .when('/admin/organisation/:organisationId', 'admin-organisation-view')
        .when('/admin/organisation/:organisationId/services', 'admin-organisation-services')

        .segment('admin-organisation-list', {
            access: {requireLogin: true},
            template: '<admin-organisation-list></admin-organisation-list>',
            loadModule: () => {
                return import(/* webpackChunkName: "admin-organisation-list.module" */ "./organisation-list/organisation-list.module");
            }
        })
        .segment('admin-organisation-view', {
            access: {requireLogin: true},
            template: '<admin-organisation-view></admin-organisation-view>',
            loadModule: () => {
                return import(/* webpackChunkName: "admin-organisation-view.module" */ "./organisation-view/organisation-view.module");
            }
        })
        .segment('admin-organisation-services', {
            access: {requireLogin: true},
            template: '<admin-organisation-services></admin-organisation-services>',
            loadModule: () => {
                return import(/* webpackChunkName: "admin-organisation-services.module" */ "./organisation-services/organisation-services.module");
            }
        })
    ;
}
