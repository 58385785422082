import app from "js/legacy-app";

app.service('ManageCreateRequest', ['$resource', function($resource){
    return $resource('/api/manage/create-request/:params1/:params2', {}, {
        updateAll: {
            method: "POST",
            params: {params1 : "update-all"},
            isArray: true
        },
        updateBusinessTasks: {
            method: "POST",
            params: {params1 : "business/update-all"},
            isArray: true
        },
        getByType: {
            method: "GET",
            params: {params1 : "by-type"},
            isArray: true
        }
    });
}]);