import app from "js/legacy-app";
import angular from "angular";

app.directive('chooseServiceStep', ['$location', '$q', 'ModalService', 'AuthService', 'ToggleLD', 'ErrorHandler',
    function ($location, $q, ModalService, AuthService, ToggleLD, ErrorHandler) {
        return {
            templateUrl: "components/request/create/steps/choose-service-step.html",
            controller: ['$scope', 'Notification', 'Organisation', 'Station', 'Service', 'LocationService',
                function ($scope, Notification, Organisation, Station, Service, LocationService) {

                    var taskStep = {};

                    $scope.taskStep = {
                        options: {
                            id: "service",
                            position: 1,
                            title: "What type of a service do you need?",

                            back: function () {
                                $scope.wizard.back();
                            },

                            next: function () {
                                var selectedService = $scope.bcrc.services.selected;
                                if (!selectedService || !selectedService.taskStepId) {
                                    Notification.warning('Please, select your service');
                                    return;
                                }
                                $scope.wizard.next();
                            },

                            onEnter: function () {
                                return $scope.taskStep.loadServices();
                            }
                        },

                        chooseService: function (service) {
                            if ($scope.onlineOnly && !service.online) {
                                Notification.warning("At the moment we do not provide this service in your area." +
                                    " Please change your location in order to schedule this service.");
                                return;
                            }

                            if (service.nextStep) {
                                $scope.bcrc.services.selected = null;
                                $scope.bcrc.services.context.push(service);
                                $scope.bcrc.services.current = service.nextStep.view;
                            } else {
                                $scope.bcrc.services.selected = service;
                            }
                        },

                        goToSection: function (ci, index) {
                            $scope.bcrc.services.selected = null;
                            $scope.bcrc.services.current = index > 0 ?
                                $scope.bcrc.services.context[index - 1].nextStep.view :
                                $scope.bcrc.services.all;
                            $scope.bcrc.services.context.splice(index);
                        }
                    };

                    $scope.taskStep.selected = function () {
                        return $scope.bcrc.services.selected;
                    };

                    $scope.taskStep.loadServices = function () {
                        ToggleLD.show();
                        var promise = taskStep.loadServices();

                        promise.then(function (data) {
                            if ($scope.bcrc.taskId || $scope.bcrc.taskCode) {
                                taskStep.findServiceById($scope.bcrc.services.all, $scope.bcrc.taskId, $scope.bcrc.taskCode)
                            }
                            ToggleLD.hide();
                        }, function (error) {
                            ToggleLD.hide();
                            ErrorHandler.onError(/*error*/{}, "Failed to load services. Refresh page and try again later");
                        });
                        return promise;
                    };

                    taskStep.loadServices = function () {
                        if ($scope.bcrc.mechanicId) {
                            if (taskStep.businessServicesWithoutSelectOrganisation()) {
                                var id = $scope.bcrc.organisationId || $scope.autocomplete.selectedOrganisation.id;
                                return taskStep.getServicesByMechanic(id);
                            } else if (AuthService.isBikeBusinessSupervisor() && $scope.bcrc.bikeId) {
                                return Organisation.getOrganisationAndStationByBikeId({param2: $scope.bcrc.bikeId}, function (res) {
                                    $scope.autocomplete.selectedOrganisation = res.organisationShortView;
                                    $scope.autocomplete.selectedStation = res.stationShortView;
                                    return taskStep.getServicesByMechanic(res.organisationShortView.id);
                                }).$promise;
                            } else if (taskStep.organisationServices()) {
                                return $q.all([
                                    taskStep.getStation(), taskStep.getCurrentOrganisation(),
                                    taskStep.getServicesByMechanic()
                                ]);
                            } else {
                                return taskStep.getServicesByMechanic();
                            }
                        } else if (taskStep.businessServicesWithoutSelectOrganisation()) {
                            return $q.all([taskStep.getStation(), taskStep.getOrganisation()]);
                        } else if (AuthService.isBikeBusinessSupervisor() && $scope.bcrc.bikeId) {
                            return $q.all(taskStep.getOrganisationAndStation());
                        } else if (taskStep.organisationServices()) {
                            return $q.all([
                                taskStep.getStation(), taskStep.getCurrentOrganisation(),
                                taskStep.getBusinessServices(AuthService.user().companyId)
                            ]);
                        } else {
                            return taskStep.getCustomerServices();
                        }
                    };

                    taskStep.getOrganisationAndStation = function () {
                        return Organisation.getOrganisationAndStationByBikeId({param2: $scope.bcrc.bikeId}, function (res) {
                            $scope.autocomplete.selectedOrganisation = res.organisationShortView;
                            $scope.autocomplete.selectedStation = res.stationShortView;
                            return taskStep.getBusinessServices(res.organisationShortView.id);
                        }).$promise;
                    };

                    taskStep.getStation = function () {
                        if ($scope.bcrc.stationId) {
                            return Station.get({param1: $scope.bcrc.stationId}, function (res) {
                                $scope.autocomplete.selectedStation = res;
                                $scope.autocomplete.showSelectAllFromStationBtn = true;
                            }).$promise;
                        }
                    };

                    taskStep.getOrganisation = function () {
                        var deferred = $q.defer();
                        var selectedOrganisation = $scope.autocomplete.selectedOrganisation;
                        var id = $scope.bcrc.organisationId || selectedOrganisation.id;
                        Organisation.get({param1: id}, function (res) {
                            $scope.autocomplete.selectedOrganisation = res;
                            deferred.resolve(taskStep.getBusinessServices(res.id));
                        });

                        return deferred.promise;
                    };

                    taskStep.getCurrentOrganisation = function () {
                        return Organisation.getCurrent(function (data) {
                            $scope.autocomplete.selectedOrganisation = data;
                        }).$promise;
                    };

                    taskStep.getBusinessServices = function (id) {
                        return Service.getSteps({organisationId: id}, function (data) {
                            if (AuthService.isLogin() && $scope.bcrc.isBusiness) {
                                $scope.bcrc.services.all = taskStep.getServicesView(data);
                                if (angular.equals([], $scope.bcrc.services.context)) {
                                    $scope.bcrc.services.current = taskStep.getServicesView(data);
                                }
                            }
                        }).$promise;
                    };

                    taskStep.getCustomerServices = function () {
                        var serviceLocation = $scope.bcrc.serviceLocation;
                        var query = {};
                        query.location = LocationService.serializeAddress(serviceLocation);
                        return Service.getSteps(query, function (data) {
                            if (!AuthService.isLogin() || AuthService.isLogin() && AuthService.isUser()) {
                                $scope.bcrc.services.all = taskStep.getServicesView(data);
                                if (angular.equals([], $scope.bcrc.services.context)) {
                                    $scope.bcrc.services.current = taskStep.getServicesView(data);
                                }
                            }
                        }).$promise;
                    };

                    taskStep.getServicesByMechanic = function (organisationId) {
                        return Service.getSteps({
                            mechanicId: $scope.bcrc.mechanicId,
                            organisationId: organisationId
                        }, function (data) {
                            $scope.bcrc.services.all = taskStep.getServicesView(data);
                            if (angular.equals([], $scope.bcrc.services.context)) {
                                $scope.bcrc.services.current = taskStep.getServicesView(data);
                            }
                        }).$promise;
                    };

                    taskStep.getServicesView = function (data) {
                        if (data.view && data.view.length === 1 && data.view[0].stepType !== 'TASK') {
                            return taskStep.getServicesView(data.view[0].nextStep);
                        }

                        return data.view;
                    };

                    taskStep.businessServicesWithoutSelectOrganisation = function () {
                        var selectedOrganisation = $scope.autocomplete.selectedOrganisation;
                        return AuthService.isBikeBusinessSupervisor() &&
                            ($scope.bcrc.organisationId || selectedOrganisation && selectedOrganisation.id) &&
                            !$scope.bcrc.bikeId;
                    };

                    taskStep.organisationServices = function () {
                        return AuthService.isBikeOrganisationOwner();
                    };

                    taskStep.needShowBicycleTab = function () {
                        if ($scope.bcrc.needBike()) {
                            if ($scope.bcrc.requestIsGroup() || !$scope.bcrc.bikeId) {
                                return true;
                            }
                        }

                        return false;
                    };

                    taskStep.isDeliverySupported = function () {
                        let service = $scope.bcrc.service;
                        return service && service.selected && service.selected.deliverySupported
                    };


                    taskStep.onServiceChangeToggle = function () {
                        var watchFn;
                        return function () {
                            if (watchFn) {
                                watchFn();
                                watchFn = undefined;
                            } else {
                                watchFn = taskStep.onServiceChange();
                            }
                        };
                    };

                    taskStep.onServiceChange = function () {
                        return $scope.$watch('bcrc.services.selected', function (newVal, oldVal) {
                            if (!angular.equals(newVal, oldVal)) {
                                var taskCode = $scope.bcrc.taskCode;
                                if (!taskCode || newVal.code !== taskCode) {
                                    $scope.wizard.uncompleteStep("service");
                                    $scope.wizard.enableStep("service");
                                }
                                if (taskStep.needShowBicycleTab()) {
                                    $scope.wizard.enableStep("bicycles");
                                } else {
                                    $scope.wizard.disableStep("bicycles");
                                }

                                if (taskStep.isDeliverySupported()) {
                                    $scope.wizard.enableStep("returnLabel");
                                } else {
                                    $scope.wizard.disableStep("returnLabel");
                                }

                                if ($scope.bcrc.needBike()) {
                                    $scope.bicyclesStep.applyConcreteBike();
                                }
                            }
                        });
                    };

                    taskStep.findServiceById = (data, id, code) => {
                        if (data) {
                            for (let i = 0; i < data.length; i++) {
                                const service = data[i];
                                if (service.taskStepId === id || service.code === code) {
                                    $scope.bcrc.services.selected = service;
                                    return service;
                                } else if (service.nextStep && service.nextStep.view) {
                                    taskStep.findServiceById(service.nextStep.view, id, code);

                                    if ($scope.bcrc.services.selected) {
                                        return $scope.bcrc.services.selected;
                                    }
                                }
                            }
                        }
                    };

                    $scope.taskStep.onServiceChange = taskStep.onServiceChangeToggle();
                    $scope.taskStep.onServiceChange();
                }]
        };
    }]);

