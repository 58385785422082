export default {
    template: require('./landing-page-info-block.component.html'),
    bindings: {},
    transclude: {
        header: "infoBlockHeader",
        body: "infoBlockBody"
    },
    controller: function () {
        'ngInject';
    }
};
