import app from "js/legacy-app";
import angular from "angular";

app.directive('mechanicProfileRating', ['Rating', '$q',
    function (Rating, $q) {
        return {
            templateUrl: "template/profile/widget/mechanic-profile-rating.html",
            replace: true,
            scope: {
                parent: "=",
                minVisibleItems: "@",
                onLoad: "=",
                filter: "<mprFilter"
            },
            controller: ['$scope', function ($scope) {

                $scope.internalFilter = {
                    param2: $scope.filter.mechanicId
                };

                $scope.search = function (filter) {
                    var deferred = $q.defer();
                    Rating.getMechanicReviews(filter, function (data) {

                        if (angular.isFunction($scope.onLoad)) {
                            $scope.onLoad(data);
                        }
                        deferred.resolve(data);
                    }, function (error) {
                        deferred.reject(error);
                    });

                    return deferred.promise;
                };
            }]
        };
    }]);