import app from "js/legacy-app";

app.filter("textWithoutEmijiImg", ['EMOJI_MAP', 'EMOJI_HEX', function (EMOJI_MAP, EMOJI_HEX) {
    var rEmojis = new RegExp('<img .*? class="smile smile-(' + EMOJI_HEX.join('|') + ')".*?>', 'g');

    return function (input) {
        if (!input || !input.replace) {
            return input;
        }
        return input.replace(rEmojis, function (match, text) {
            return EMOJI_MAP[text];
        });
    };
}]);