import app from "js/legacy-app";

app.controller('ProfileCtrl', ['$scope', '$location', 'AuthService', 'Organisation',
    function ($scope, $location, AuthService, Organisation) {

        if ($location.$$path === '/profile') {
            $location.path('profile/change');
        }

        $scope.profile = {};

        $scope.changeLocation = function (segment) {
            $location.path('profile/' + segment);
        };

        $scope.$on('$locationChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
            if ($location.$$path === '/profile') {
                $location.path('profile/change');
            }
        });

        if (AuthService.isBikeOrganisationOwner()) {
            $scope.showOrganisation = true;
        }

    }]);
