import app from "js/legacy-app";

app.directive('blStationFilter', ['$q', 'Organisation', 'Station', function ($q, Organisation, Station) {
    return {
        template: '<pl-autocomplete-filter af-label="Group" af-depends-on="organisation" af-name="station" af-model="blsf.filter.station"></pl-autocomplete-filter>',
        link: function ($scope) {

            $scope.blsf = {};

            $scope.blsf.filter = {
                station: {
                    search: function (query, organisationId) {
                        return Station.findByName({param2: organisationId, name: query}).$promise;
                    }
                }
            };
        }
    };
}]);