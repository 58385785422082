import app from "js/legacy-app";

app.service('$frameMaterialService', ['FrameMaterial', function (FrameMaterial) {

    var materials = null,
        isLoaded = function() {
            return !!materials;
        },
        load = function (callBack) {
            if (!materials) {
                return FrameMaterial.query(function (data) {
                    materials = [];
                    for (var i = 0; i < data.length; i++) {
                        materials.push(data[i]);
                    }
                    if (callBack) {
                        callBack(materials);
                    }
                }).$promise;
            } else {
                if (callBack) {
                    callBack(materials);
                }

            }
        },
        clear = function () {
            materials = null;
        },
        getAll = function () {
            return materials;
        };

    return {
        isLoaded: isLoaded,
        load: load,
        clear: clear,
        getAll: getAll
    };
}]);