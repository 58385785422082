import app from "js/legacy-app";

app.service('ModalService', ['$mdDialog', '$pageScroll', '$window', '$mdMenu', '$pageableList', function ($mdDialog, $pageScroll, $window, $mdMenu, $pageableList) {

    let isOpen = false;

    function removeHtmlScrollInExplorer() {
        if (navigator.appName.indexOf('Explorer') === -1) {
            $pageScroll.hide();
        }
        isOpen = true;
    }

    function returnHtmlScrollInExplorer() {
        isOpen = false;
        if (navigator.appName.indexOf('Explorer') === -1) {
            $pageScroll.revert();
        }
    }

    function removeHtmlScroll() {
        $pageScroll.hide();
        isOpen = true;
    }

    function returnHtmlScroll() {
        isOpen = false;
        $pageScroll.revert();
    }

    let showMessage = function ($event, message, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/component/modal/show-message.html',
                locals: {
                    comment: message
                },
                controller: 'ShowMessageDialogController'
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        fullProfileUser = function (id) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                templateUrl: 'template/admin/users/full-profile-user.html',
                locals: {
                    id: id
                },
                controller: 'FullProfileUserCtrl'
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        showUserPayInfo = function (id) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                templateUrl: 'template/admin/users/show-user-pay-info.html',
                locals: {
                    id: id
                },
                controller: 'ShowUserPayInfoCtrl'
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        uploadImages = function ($event, documents, callback, validationOptions,
                                 choosePhotosBtnText, multiple, maxFiles, imageSize, title, extensions) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'components/upload-image/img-upload-dialog.html',
                controller: 'ImgUploadDialogCtrl',
                locals: {
                    documents: documents,
                    validationOptions: validationOptions,
                    choosePhotosBtnText: choosePhotosBtnText,
                    multiple: multiple,
                    maxFiles: maxFiles,
                    imageSize: imageSize,
                    title: title,
                    extensions: extensions,
                }
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        uploadChatImages = function ($event, documents, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'components/upload-image/upload-chat-images.html',
                controller: 'ChatImgUploadDialogCtrl',
                locals: {
                    documents: documents
                }
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        showPicture = function ($event, getImages, index, remove, setDefault, setCover) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/component/gallery/show-picture.html',
                clickOutsideToClose: true,
                escapeToClose: true,
                locals: {
                    getImages: getImages,
                    index: index,
                    remove: remove,
                    setDefault: setDefault,
                    setCover: setCover
                },
                controller: 'ShowPictureDialogController'
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        changeBikeStatus = function ($event, bike, callback) {
            let status = bike.status;
            if (status === 'RECYCLED') {
                return;
            }
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/dashboard/bikes/show-bike/change-status.html',
                locals: {
                    status: status
                },
                controller: 'ChangeBikeStatusDialogController'
            }).then(function (response) {
                if (response) {
                    let newStatus = response;
                    let message = 'Are you sure you want to change the bicycle status? ';

                    if (response === 'RECYCLED') {
                        message += '<br>After moving the bicycle to status Recycled it will be removed from your profile';
                    } else if (response === 'USED') {
                        message += '<br>After moving the bicycle to status Returned it will indicate that your bicycle was successfully returned to you';
                    } else if (response === 'IN_USED') {
                        newStatus = 'USED';
                    } else if (response === 'BROKEN') {
                        message += '<br>After moving the bicycle to status Broken it will be labeled as one';
                    }

                    yesNoMessage($event, '', message, function (response) {
                        if (response === true) {
                            if (callback) {
                                callback(newStatus);
                            }
                        }
                    });
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        selectUserForBikeTransfer = function ($event, bikeId, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/dashboard/bikes/change-bike-group-dialog.html',
                locals: {
                    bikeId: bikeId
                },
                controller: 'SelectUserForBikeTransferCtrl'
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        changeBikeGroup = function ($event, bikeId, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/dashboard/bikes/change-bike-group-dialog.html',
                locals: {
                    bikeId: bikeId
                },
                controller: 'ChangeBikeGroupCtrl'
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        yesNoMessage = function ($event, title, message, callback, yesButtonText, noButtonText) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/component/modal/yes-no-message.html',
                locals: {
                    message: message,
                    title: title || 'Confirm action',
                    yesButtonText: yesButtonText || 'yes',
                    noButtonText: noButtonText || 'no'
                },
                controller: 'YesNoMessageDialogController'
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        consultationMessage = function ($event, message, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/component/modal/consultation-message.html',
                locals: {
                    message: message,
                    title: 'Confirm action'
                },
                controller: 'ConsultationMessageDialogController'
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        showBikeSerialNumber = function ($event) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/dashboard/bikes/add-bike/show-serial-number.html',
                controller: 'ShowSerialNumberDialogCtrl'
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        chooseUserAvatar = function ($event, domain, type, title, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/profile/tabs/avatar/choose-avatar-dialog-controller.html',
                locals: {
                    domain: domain,
                    type: type,
                    title: title || 'Choose Avatar'
                },
                controller: 'ChooseAvatarDialogController'
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        rateTheMechanic = function (negotiation, callback) {
            removeHtmlScroll();
            $mdDialog.show({
                templateUrl: 'components/request/view/rate-the-mechanic-dialog.html',
                locals: {
                    negotiation: negotiation
                },
                controller: 'RateMechanicDialogController',
                onComplete: function () {
                    $window.addEventListener('click', function () {
                        $mdMenu.hide();
                    });
                }
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScroll();
            });
        },

        rateTheUser = function (callback) {
            removeHtmlScroll();
            $mdDialog.show({
                templateUrl: 'components/request/view/rate-the-user-dialog.html',
                locals: {},
                controller: 'RateUserDialogController',
                onComplete: function () {
                    $window.addEventListener('click', function () {
                        $mdMenu.hide();
                    });
                }
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScroll();
            });
        },

        leaveRequestReport = function ($event, additionalServices, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'components/request/view/request-report-dialog.html',
                locals: {
                    additionalServices: additionalServices
                },
                controller: 'RequestReportCtrl'
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        chooseColor = function ($event, colors, count, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/dashboard/bikes/choose-color-dialog.html',
                locals: {
                    colors: colors,
                    count: count
                },
                controller: 'ChooseColorDialogController'
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        showBikeStatusDescription = function ($event) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/dashboard/bikes/add-bike/show-bike-status-description.html',
                locals: {},
                controller: 'ShowBikeStatusDescriptionDialogController'
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        enterBikeSN = function ($event, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/dashboard/bikes/show-bike/enter-serial-number.html',
                locals: {},
                controller: 'EnterSerialNumberDialogController'
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        checkInsurance = function ($event, insurance, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/admin/verification/update-insurance.html',
                locals: {
                    insurance: insurance
                },
                controller: 'CheckInsuranceDialogController'
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        showHowVelotoolerWorksVideo = function ($event) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/component/modal/show-how-it-works-video.html',
                controller: 'ShowHowVelotoolerWorksVideoController'
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        showPublicBikeLink = function ($event, id) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/dashboard/bikes/show-bike/show-public-bike-link.html',
                locals: {
                    id: id
                },
                controller: 'ShowPublicBikeLinkController'
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        importBikes = function ($event, organisation, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/dashboard/bikes/import-bikes.html',
                locals: {
                    organisation: organisation
                },
                controller: 'ImportBikesDialogController'
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        showRepeatedBikes = function ($event, bikes) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/dashboard/bikes/show-repeated-serial-numbers.html',
                locals: {
                    bikes: bikes
                },
                controller: 'ShowRepeatedSerialNumbersController'
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        selectOrganisation = function ($event, serviceBucket) {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'components/request/create/select-organisation.html',
                locals: {
                    serviceBucket: serviceBucket
                },
                controller: 'SelectOrganisationCtrl',
                escapeToClose: false
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        notifyUserAboutServiceAvailability = function (params, callback) {
            removeHtmlScrollInExplorer();
            $mdDialog.show({
                templateUrl: 'components/request/create/modal/notify-user-dialog.html',
                locals: params,
                controller: 'NotifyUserDialogController',
                escapeToClose: false
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        showNotificationErrorDialog = function (errorMessages, title) {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                templateUrl: 'components/error/error-dialog.html',
                locals: {
                    title: title || 'This error could have occurred for one of these reasons:',
                    errorMessages: errorMessages
                },
                controller: 'ErrorDialogCtrl',
                escapeToClose: false
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },
        mechanicNotPerformedServiceDialog = function (error) {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                templateUrl: 'components/request/create/modal/mechanic-does-not-performed-service-dialog.html',
                locals: {
                    error: error
                },
                controller: 'MechanicNotPerformedServiceDialogCtrl',
                escapeToClose: false
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },
        mechanicChangedServicePriceDialog = function (error) {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                templateUrl: 'components/request/create/modal/mechanic-changed-price-dialog.html',
                locals: {
                    error: error
                },
                controller: 'MechanicChangedPriceDialogCtrl',
                escapeToClose: false
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        editRequestAppointments = function (dates, weeklyAvailability, vacationPeriod, autoacceptBookingEnabled) {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                templateUrl: 'components/request/view/change-appointment-date-dialog.html',
                locals: {
                    dates: dates,
                    weeklyAvailability: weeklyAvailability,
                    vacationPeriod: vacationPeriod,
                    autoacceptBookingEnabled: autoacceptBookingEnabled
                },
                controller: 'AvailabilityDialogCtrl',
                escapeToClose: false
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        confirmationAppointmentDateDialog = function (request) {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                templateUrl: 'components/request/view/confirmation-appointment-date-dialog.html',
                locals: {
                    request: request
                },
                controller: 'ConfirmAppointmentDateDialogCtrl',
                escapeToClose: true,
                onComplete: function () {
                    $window.addEventListener('click', function () {
                        $mdMenu.hide();
                    });
                }
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        confirmationAdditionalBookingDialog = function (request) {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                templateUrl: 'components/request/view/confirmation-additional-booking-dialog.html',
                locals: {
                    request: request
                },
                controller: 'ConfirmAdditionalBookingDialogCtrl',
                escapeToClose: true,
                onComplete: function () {
                    $window.addEventListener('click', function () {
                        $mdMenu.hide();
                    });
                }
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        showCustomerInvitationDialog = function ($event, bikeId, pageableListId) {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'template/dashboard/bikes/add-customer/add-customer-dialog.html',
                controller: 'AddCustomerDialogCtrl',
                escapeToClose: true,
                autoWrap: false,
                controllerAs: '$ctrl',
                bindToController: true,
                locals: {
                    bikeId: bikeId
                },
                onComplete: function () {
                    $window.addEventListener('click', function () {
                        $mdMenu.hide();
                    });
                },
                onRemoving: function () {
                    if (pageableListId) {
                        $pageableList.get(pageableListId).refreshPage();
                    }
                }
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        showMechanicCancellationReasonDialog = function ($event) {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'components/request/cancellation/mechanic-cancellation-reason-dialog.html',
                controller: 'MechanicCancellationReasonDialogCtrl',
                escapeToClose: true
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        showUnableToFixReasonDialog = function ($event) {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'components/request/cancellation/unable-to-fix-reason-dialog.html',
                controller: 'UnableToFixRequestReasonDialogCtrl',
                escapeToClose: true
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        assignSupportResponsibleDialog = function ($event) {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'components/request/view/assign-support-responsible-dialog.html',
                controller: 'AssignSupportResponsibleCtrl',
                escapeToClose: true
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        selectBikesForFixDialog = ($event, bikes) => {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                targetEvent: $event,
                templateUrl: 'components/request/view/select-bikes-for-fix-dialog.html',
                locals: {
                    bikes: bikes
                },
                controller: 'SelectBikesForFixDialogCtrl',
                escapeToClose: true
            }).then(function (response) {
                return response;
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        },

        addDealerDialog = (callback) => {
            removeHtmlScrollInExplorer();
            return $mdDialog.show({
                templateUrl: 'template/dashboard/bike-organization-owner/add-dealer-dialog.html',
                controller: 'AddDealerDialogCtrl',
                escapeToClose: true
            }).then(function (response) {
                if (callback) {
                    callback(response);
                }
            }).finally(function () {
                returnHtmlScrollInExplorer();
            });
        };

    return {
        showMessage: showMessage,
        fullProfileUser: fullProfileUser,
        showUserPayInfo: showUserPayInfo,
        showPicture: showPicture,
        changeBikeStatus: changeBikeStatus,
        selectUserForBikeTransfer: selectUserForBikeTransfer,
        changeBikeGroup: changeBikeGroup,
        yesNoMessage: yesNoMessage,
        showBikeSerialNumber: showBikeSerialNumber,
        chooseUserAvatar: chooseUserAvatar,
        rateTheMechanic: rateTheMechanic,
        rateTheUser: rateTheUser,
        consultationMessage: consultationMessage,
        leaveRequestReport: leaveRequestReport,
        chooseColor: chooseColor,
        showBikeStatusDescription: showBikeStatusDescription,
        enterBikeSN: enterBikeSN,
        checkInsurance: checkInsurance,
        showHowVelotoolerWorksVideo: showHowVelotoolerWorksVideo,
        showPublicBikeLink: showPublicBikeLink,
        uploadImages: uploadImages,
        importBikes: importBikes,
        showRepeatedBikes: showRepeatedBikes,
        selectOrganisation: selectOrganisation,
        notifyUserAboutServiceAvailability: notifyUserAboutServiceAvailability,
        uploadChatImages: uploadChatImages,
        showNotificationErrorDialog: showNotificationErrorDialog,
        mechanicNotPerformedServiceDialog: mechanicNotPerformedServiceDialog,
        mechanicChangedServicePriceDialog: mechanicChangedServicePriceDialog,
        editRequestAppointments: editRequestAppointments,
        confirmationAppointmentDateDialog: confirmationAppointmentDateDialog,
        confirmationAdditionalBookingDialog: confirmationAdditionalBookingDialog,
        showCustomerInvitationDialog: showCustomerInvitationDialog,
        showMechanicCancellationReasonDialog: showMechanicCancellationReasonDialog,
        showUnableToFixReasonDialog: showUnableToFixReasonDialog,
        assignSupportResponsibleDialog: assignSupportResponsibleDialog,
        selectBikesForFixDialog: selectBikesForFixDialog,
        addDealerDialog: addDealerDialog
    };

}]);
