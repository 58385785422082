import app from "js/legacy-app";

app.directive('profileAddress', [function () {
    return {
        restrict: 'E',
        replace: true,
        templateUrl: 'template/profile/address/profile-address.html',
        scope: {
            locations: "=paLocations"
        },

        link: function ($scope, element, $attr) {
        }
    };
}]);