import app from "js/legacy-app";

app.directive('plSelectFilter', ['$location', function ($location) {
    return {
        template: '<div class="pageable-list__filter-field">' +
        '               <md-input-container>' +
        '                   <md-select class="md-select"' +
        '                              name="{{ name }}"' +
        '                              ng-model="sf.selected"' +
        '                              ng-change="sf.onItemChange()"' +
        '                              ng-model-options="{trackBy: \'$value\'}"' +
        '                              md-on-close="sf.filter.updateScroll()">' +
        '                           <md-option ng-value="item.val" ng-repeat="item in model.options">' +
        '                               {{ item.display }}' +
        '                           </md-option>' +
        '                   </md-select>' +
        '               <label class="md-label">{{ label }}</label>' +
        '               </md-input-container>' +
        '           </div>',
        replace: true,
        require: '^^plFilter',
        scope: {
            label: '@sfLabel',
            name: '@sfName',
            model: "=sfModel",
            dependsOn: "@sfDependsOn"
        },
        link: function ($scope, element, $attrs, plFilter) {
            $scope.sf = {};
            $scope.sf.filter = plFilter.filter;
            $scope.sf.selected = null;

            $scope.sf.filter.register($scope.name, $scope.sf);

            $scope.sf.onItemChange = function () {
                $scope.sf.filter.apply();
            };

            $scope.sf.isSelected = function () {
                return !!$scope.sf.selected;
            };

            $scope.sf.getValue = function () {
                return $scope.sf.isSelected() ? $scope.sf.selected : null;
            };

            $scope.sf.serialize = function () {
                var serialized = {};
                serialized[$scope.name] = $scope.sf.getValue();
                return serialized;
            };

            $scope.sf.deserialize = function (queryString) {
                if (queryString[$scope.name]) {
                    $scope.sf.selected = queryString[$scope.name];
                }
            };

            $scope.sf.clear = clear;
            $scope.sf.init = init;

            function clear() {
                $scope.sf.selected = null;
            }

            function init() {
                $scope.sf.deserialize($location.search());

                $scope.sf.filter.initialized($scope.name);

                if ($scope.dependsOn) {
                    $scope.$watch('sf.filter.filters.' + $scope.dependsOn + '.getValue()', function (newVal, oldVal) {
                        if (newVal !== oldVal) {
                            clear();
                        }
                    });
                }
            }

            init();
        }
    };
}]);