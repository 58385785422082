import app from "js/legacy-app";

app.directive('progressBar', function () {
    return {
        scope: {
            progress: "=progressBar"
        },
        template: '<span style="width: {{progress}}%"></span>',
        link: function (scope, element, attrs) {
        }
    };
});