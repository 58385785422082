import app from "js/legacy-app";
import angular from "angular";

app.controller('AddTypeOfBikesCtrl', ['$scope', 'TypeOfBikes', '$location', '$routeParams', 'Brand', 'HistoryService',
    function ($scope, TypeOfBikes, $location, $routeParams, Brand, HistoryService) {

        var id = $routeParams.id;
        $scope.frameSizes = [];
        HistoryService.clear();

        if (id) {
            $scope.title = 'Edit bicycle types';
            TypeOfBikes.get({id: id}, function(data) {
                $scope.typeOfBike = data;
                for (var i = 0; i <$scope.typeOfBike.frameSizes.length; i++) {
                    $scope.frameSizes.push({value: $scope.typeOfBike.frameSizes[i]});
                }
            });
        } else {
            $scope.title = 'Add bicycle types';
            $scope.typeOfBike = {
                name: '',
                groupsets: [],
                components: [{ name: '' }],
                wheels: [{
                    inch: '',
                    iso: '',
                    bicycleStandard: ''
                }],
                frameSizes: ['']
            };
        }

        $scope.addFrameSize = function() {
            if (!$scope.frameSizes) {
                $scope.frameSizes = [];
            }
            $scope.frameSizes.push({value: ''});
        };

        $scope.removeFrameSize = function(index) {
            $scope.frameSizes.splice(index, 1);
        };

        $scope.addWheel = function() {
            if (!$scope.typeOfBike.wheels) {
                $scope.typeOfBike.wheels = [];
            }
            $scope.typeOfBike.wheels.push({
                inch: '',
                iso: '',
                bicycleStandard: ''
            });
        };

        $scope.removeWheel = function(index) {
            $scope.typeOfBike.wheels.splice(index, 1);
        };

        $scope.addComponent = function() {
            $scope.typeOfBike.components.push({
                name: ''
            });
        };

        $scope.addGroupsetComponent = function(groupset) {
            groupset.components.push({name: ''});
        };

        $scope.removeGroupsetComponent = function(groupset, index) {
            groupset.components.splice(index, 1);
        };

        $scope.addGroupset = function() {
            if (!$scope.typeOfBike.groupsets) {
                $scope.typeOfBike.groupsets = [];
            }
            $scope.typeOfBike.groupsets.push({
                name: '',
                brand: '',
                model: '',
                components: [
                    { name: 'Shifters' },
                    { name: 'Brakes' },
                    { name: 'Front Derailleur' },
                    { name: 'Rear Derailleur' },
                    { name: 'Bottom Bracket' },
                    { name: 'Crankset' },
                    { name: 'Chain' },
                    { name: 'Cassette' }
                ]
            });
        };

        $scope.removeGroupsets = function(index) {
            $scope.typeOfBike.groupsets.splice(index, 1);
        };

        $scope.removeComponent = function(index) {
            $scope.typeOfBike.components.splice(index, 1);
        };

        $scope.getBrands = function (brand) {
            return Brand.autocomplete({name: brand, page: 0, size: 10}).$promise;
        };

        $scope.save = function(form) {
            if (form.$valid) {
                $scope.typeOfBike.frameSizes = [];
                for (var i in $scope.frameSizes) {
                    $scope.typeOfBike.frameSizes.push($scope.frameSizes[i].value);
                }
                if (id) {
                    let data = angular.copy($scope.typeOfBike);
                    delete data.updatedAt;
                    delete data.createdAt;
                    delete data.deleted;
                    TypeOfBikes.update({id: id}, data, function (data) {
                        $location.url('/admin/bike-types');
                    });
                } else {
                    let data = angular.copy($scope.typeOfBike);
                    delete data.updatedAt;
                    delete data.createdAt;
                    delete data.deleted;
                    delete data.id;
                    TypeOfBikes.save(data, function (data) {
                        $location.url('/admin/bike-types');
                    });
                }
            }
        };

        $scope.next = function(form) {
            if (form.$valid) {
                $scope.selectedTab++;
            }
        };

        $scope.prev = function() {
            if ($scope.selectedTab === 0) {
                $location.url('/admin/bike-types');
            } else {
                $scope.selectedTab--;
            }
        };
    }
]);