import app from "js/legacy-app";

app.filter("colorWidthClass", [function () {
    return function (count, color) {
        var clazz = 'color show-color ';
        if (color) {
            clazz += color + ' ';
        }
        if (count === 1) {
            clazz += "width-100";
        }
        if (count === 2) {
            clazz += "width-50";
        }
        if (count === 3) {
            clazz += "width-33";
        }
        return clazz;
    };
}]);