// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/img/user-no-avatar.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"user-avatar-with-name\">\n    <div data-ng-if=\"model\">\n        <img replace-invalid-image=\"/img/user-no-avatar.png\"\n             data-ng-if=\"model.avatar.thumbnail\" class=\"user-avatar-with-name__avatar\"\n             data-ng-src=\"{{ model.avatar.thumbnail }}\"/>\n\n        <img data-ng-if=\"!model.avatar.thumbnail\" class=\"user-avatar-with-name__avatar\"\n             src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/>\n\n        <a href=\"/user/{{ model.id }}\" target=\"_blank\" rel=\"noopener noreferrer\"\n           class=\"vt-link vt-link_theme-white vt-link_bold user-avatar-with-name__name\">\n            {{ model.name }}\n            {{ model.surname }}\n        </a>\n    </div>\n    <div data-ng-if=\"!model\">\n        <img class=\"user-avatar-with-name__avatar user-avatar-with-name__avatar_no-user\"\n             src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/>\n\n        <span class=\"user-avatar-with-name__name user-avatar-with-name__name_no-user\">\n            {{ noUserText }}\n        </span>\n    </div>\n\n</div>";
// Exports
var _module_exports = code;;
var path = 'components/user-avatar-with-name/user-avatar-with-name.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;