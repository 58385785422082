import app from "js/legacy-app";
import angular from "angular";

app.directive('plPaging', ['$mdMedia', '$location', '$httpParamSerializer', function ($mdMedia, $location, $httpParamSerializer) {
    return {
        templateUrl: "template/component/widget/pageable-list/pl-paging.html",
        replace: true,
        link: function ($scope, element, $attrs) {

            var pl = $scope.pageableList;
            $scope.Math = window.Math;
            $scope.$mdMedia = $mdMedia;

            if (pl.id) {
                element.attr('id', pl.id + "-paging");
            }

            pl.prevPage = prevPage;
            pl.nextPage = nextPage;
            pl.goToPage = goToPage;
            pl.getPrevPageHref = getPrevPageHref;
            pl.getNextPageHref = getNextPageHref;
            pl.getPageHref = getPageHref;

            // methods definition
            function goToPage(index) {
                if (pl.totalPages > index) {
                    pl.currentPage = index;
                    pl.refreshPage();
                }
            }

            function prevPage() {
                if (pl.hasPrevPage || (pl.currentPage !== 0 && !pl.items)) {
                    pl.currentPage--;
                    pl.refreshPage();
                }
            }

            function nextPage() {
                if (pl.hasNextPage) {
                    pl.currentPage++;
                    pl.refreshPage();
                }
            }

            function getPageHref(index) {
                if (pl.totalPages > index) {
                    var searchString = angular.merge({}, $location.search());
                    searchString.page = index;
                    return $location.path() + "?" + $httpParamSerializer(searchString);
                }
            }

            function getPrevPageHref() {
                if (pl.hasPrevPage || (pl.curentPage !== 0 && !pl.items)) {
                    var searchString = angular.merge({}, $location.search());
                    searchString.page = pl.currentPage - 1;
                    return $location.path() + "?" + $httpParamSerializer(searchString);
                }
            }

            function getNextPageHref() {
                if (pl.hasNextPage) {
                    var searchString = angular.merge({}, $location.search());
                    searchString.page = pl.currentPage + 1;
                    return $location.path() + "?" + $httpParamSerializer(searchString);
                }
            }
        }
    };
}]);