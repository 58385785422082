import app from "js/legacy-app";

app.directive('chooseColors', [function () {
    return {
        template: '<div>' +
        '   <md-radio-group ng-model="changeColors.countColor" ng-change="changeColors.changeCountColor()">' +
        '      <div class="row">' +
        '          <div class="col-xs-4 color-panel">' +
        '              <md-radio-button ng-model="changeColors.countColor" value="1" aria-label="One">One</md-radio-button>' +
        '          </div>' +
        '          <div class="col-xs-4 color-panel">' +
        '              <md-radio-button ng-model="changeColors.countColor" value="2" aria-label="Two">Two</md-radio-button>' +
        '          </div>' +
        '          <div class="col-xs-4 color-panel">' +
        '              <md-radio-button ng-model="changeColors.countColor" value="3" aria-label="Three">Three</md-radio-button>' +
        '          </div>' +
        '      </div>' +
        '   </md-radio-group>' +
        '   <div class="width-100">' +
        '      <div class="color-panel">' +
        '          <md-button class="{{ colors | selectColorClass:changeColors.countColor }}" aria-label="Show colors"' +
        '                   ng-click="changeColors.chooseColor()">' +
        '               {{ colors.length != changeColors.countColor ? "Select" : "" }}' +
        '               <div data-ng-repeat="color in colors" class="{{ colors.length | colorWidthClass:color }}"' +
        '                       ng-show="colors.length == changeColors.countColor"></div>' +
        '          </md-button>' +
        '      </div>' +
        '   </div>' +
        '</div>',
        scope: {
            colors: '=chooseColors'
        },
        replace: true,
        controller: ['$scope', 'ModalService', function ($scope, ModalService) {
            $scope.changeColors = {
                countColor: 1,
                changeCountColor: function () {
                    while ($scope.colors.length > $scope.changeColors.countColor) {
                        $scope.colors.splice(0, 1);
                    }
                },
                chooseColor: function ($event) {
                    ModalService.chooseColor($event, $scope.colors, $scope.changeColors.countColor, function (response) {
                        if (response) {
                            while($scope.colors.length > 0) {
                                $scope.colors.splice(0, 1);
                            }
                            for (var i = 0; i < response.length; i++) {
                                $scope.colors.push(response[i]);
                            }
                        }
                    });
                }
            };

            if ($scope.colors && $scope.colors.length > 0) {
                $scope.changeColors.countColor = $scope.colors.length;
            }

        }]
    };
}]);