import app from "js/legacy-app";

app.directive('plToggleFilter', ['$location', '$timeout', function ($location, $timeout) {
    return {
        template: `<div class="pageable-list__filter-field">
                        <button class="display-inline-block vert-middle md-button md-raised tw-w-full" 
                                aria-label="Activate/Deactivate"
                                name="{{ name }}"
                                data-ng-model="tf.active"
                                data-ng-class="{'md-success': tf.active, 'md-neutral': !tf.active}"
                                data-ng-click="tf.onItemChange()">
                            <span data-ng-if="!tf.active" class="tw-uppercase xs:tw-text-11px sm:tw-text-xs lg:tw-text-13px">
                                {{ labelInactive }}
                                <i class="margin-left-6 vt-icon vt-icon__s"></i>
                            </span>
                            <span data-ng-if="tf.active" class="tw-uppercase xs:tw-text-11px sm:tw-text-xs lg:tw-text-13px">
                                {{ labelActive }}
                                <i class="vt-icon vt-icon__cancel vt-icon__s margin-left-6"></i>
                            </span>
                        </button>
                    </div>`,

        replace: true,
        require: '^^plFilter',
        scope: {
            labelActive: '@tfLabelActive',
            labelInactive: '@tfLabelInactive',
            name: '@tfName',
            initValue: '@tfInitValue',
            dependsOn: "@tfDependsOn"
        },
        link: function ($scope, element, $attrs, plFilter) {
            $scope.tf = {};
            $scope.tf.active = $scope.initValue === 'true' ? true : null;
            $scope.tf.filter = plFilter.filter;

            $scope.tf.filter.register($scope.name, $scope.tf);

            $scope.tf.onItemChange = function () {
                let dependencies = getDependencies();
                if (dependencies) {
                    dependencies.forEach(function (dependency) {
                        dependency.clear();
                    });
                }

                $scope.tf.toggle();
                $timeout(function () {
                    $scope.tf.filter.apply();
                });
            };

            $scope.tf.toggle = function () {
                $scope.tf.active = $scope.tf.active ? null : true;
            };

            $scope.tf.isSelected = function () {
                return JSON.parse($scope.tf.active);
            };

            $scope.tf.getValue = function () {
                return $scope.tf.isSelected();
            };

            $scope.tf.serialize = function () {
                var serialized = {};
                serialized[$scope.name] = $scope.tf.getValue();
                return serialized;
            };

            $scope.tf.deserialize = function (queryString) {
                if (queryString[$scope.name]) {
                    $scope.tf.active = JSON.parse(queryString[$scope.name]);
                }
            };

            $scope.tf.clear = clear;
            $scope.tf.init = init;

            function getDependencies() {
                if (!$scope.dependsOn) {
                    return null;
                }

                let dependsOn = $scope.dependsOn.split(",");

                return dependsOn.map(function (field) {
                    return $scope.tf.filter.filters[field];
                });
            }

            function clear() {
                if ($scope.tf.active) {
                    $scope.tf.active = null;
                }
            }

            function init() {
                $scope.tf.deserialize($location.search());

                $scope.tf.filter.initialized($scope.name);
            }

            init();
        }
    };
}]);
