import app from "js/legacy-app";

app.directive('showSession', [function () {
    return {
        templateUrl: "template/settings/tabs/security/show-sessions.html",
        link: function ($scope, $element, $attrs, ngModel) {
            $scope.profile = $scope.$eval($attrs.profile);
        },
        controller: ['$scope', 'User', 'ModalService', 'Notification', function ($scope, User, ModalService, Notification) {

            $scope.showSession = {
                tokens: [],
                checkedAll: false
            };

            var getSessions = function () {
                User.tokens(function (data) {
                    $scope.showSession.tokens = [];
                    for (var i = data.length - 1; i >= 0; i--) {
                        $scope.showSession.tokens.push(data[i]);
                    }
                });
            };
            getSessions();

            $scope.isChecked = function () {
                for (var i in $scope.showSession.tokens) {
                    if ($scope.showSession.tokens[i].checked) {
                        return true;
                    }
                }

                return false;
            };

            $scope.checkAll = function() {
                var newValue = $scope.showSession.checkedAll;

                for (var i in $scope.showSession.tokens) {
                    if (!$scope.showSession.tokens[i].current) {
                        $scope.showSession.tokens[i].checked = newValue;
                    }
                }
            };

            $scope.checkToken = function() {
                for (var i in $scope.showSession.tokens) {
                    if (!$scope.showSession.tokens[i].current) {
                        if (!$scope.showSession.tokens[i].checked) {
                            $scope.showSession.checkedAll = false;
                            return;
                        }
                    }
                }

                $scope.showSession.checkedAll = true;
            };

            $scope.closeSessions = function ($event) {
                var message = 'Are you sure you want to close this session?';
                ModalService.yesNoMessage($event, '', message, function (response) {
                    if (response === true) {
                        var sessions = [];
                        for (var i in $scope.showSession.tokens) {
                            if ($scope.showSession.tokens[i].checked) {
                                sessions.push($scope.showSession.tokens[i].id);
                            }
                        }

                        User.closeSessions(sessions, function (data) {
                            getSessions();
                            $scope.showSession.checkedAll = false;
                            Notification.success('Session was successfully closed');
                        });
                    }
                });
            };
        }]
    };
}]);
