import app from "js/legacy-app";

app.controller('ZipCodesCtrl', ['$scope', 'WorkArea', function ($scope, WorkArea) {

    $scope.setData = function (data) {
        $scope.size = data.size;
        $scope.data = data.data;
        $scope.next = data.next;
        $scope.current = data.current;
        $scope.count = data.total;
        $scope.prev = data.prev;
        $scope.pages = data.pages;
    };
    $scope.setData({
        size: 10,
        current: 0
    });

    $scope.changePageSize = function() {
        $scope.current = 0;
        $scope.search();
    };

    // get data for current page
    $scope.getPage = function (page) {
        if ($scope.current != page) {
            $scope.current = page;
            $scope.search();
        }
    };

    $scope.getPageInfo = function () {
        var from, to;
        if ($scope.count) {
            from = ($scope.count !== 0 ? ($scope.current * $scope.size + 1) : 0);
            to = ($scope.count < ($scope.current * $scope.size + $scope.size) ? $scope.count : ($scope.current * $scope.size + $scope.size));
            return 'From ' + from + ' to ' + to + ' of ' + $scope.count;
        }

        return 'No records';
    };

    // get data for next page
    $scope.getNextPage = function () {
        if ($scope.current != $scope.pages[$scope.pages.length - 1]) {
            $scope.current++;
            $scope.search();
        }
    };

    // get data for previous page
    $scope.getPrevPage = function () {
        if ($scope.current !== 0) {
            $scope.current--;
            $scope.search();
        }
    };

    $scope.search = function () {
        WorkArea.mechanicCountByZipCode({
            page: $scope.current,
            size: 10
        }, function(result) {
            $scope.setData(result);
        });
    };

    $scope.search();

}]);