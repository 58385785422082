import './landing-page-underlined-header.component.scss';

export default {
    template: require('./landing-page-underlined-header.component.html'),
    bindings: {
        title: "@?lpuhTitle",
        titleClass: "@?lpuhTitleClass"
    },
    controller: function () {
        'ngInject';
    }
};
