import app from "js/legacy-app";

app.service('ErrorHandler', ['Notification', function (Notification) {

    var defaultLevel = 'ERROR';

    var onError = function (errorResponse, defaultMessage, level, form) {
        console.log(errorResponse)
        if (!form) {
            var errorMessage = extractError(_getError(errorResponse), defaultMessage);
            level = level || defaultLevel;
            if (level === 'WARN') {
                Notification.warning(errorMessage);
            } else {
                Notification.error(errorMessage);
            }
        } else {
            var errors = getValidationErrors(errorResponse);
            if (!errors) {
                onError(errorResponse, defaultMessage, level);
                return;
            }
            for (var i = 0; i < errors.length; i++) {
                var err = errors[i];
                var element = findForm(form, err.field)[err.field];
                if (!element) {
                    onError(errorResponse, defaultMessage, level);
                    return;
                }

                element.$setValidity("validation", false);
                element.validationError = err.message;
            }
        }
    };

    var findForm = function (form, field) {

        if (form[field]) {
            return form;
        }

        for (var prop in form) {
            if (prop.indexOf('$') !== -1) {
                continue;
            }

            if (form[prop][field]) {
                return form[prop];
            }
        }
    };

    var onErrorDialog = function (errorResponse, defaultMessage) {
        var errorMessages = getErrorMessages(_getError(errorResponse), defaultMessage);
        return Notification.showNotificationErrorDialog(errorMessages);
    };

    var onPaymentError = function (status, data) {
        if (status === 402) {
            Notification.error('Your card was declined');
        } else if (status > 400 && status < 500) {
            Notification.error(data.error.message);
        }
    };

    function getErrorMessages(errorResponse, defaultMessage) {
        var errorMessages = [];
        if (defaultMessage) {
            errorMessages.push(defaultMessage);
        }
        if (errorResponse && errorResponse.messages) {
            errorMessages = [];
            for (var i = 0; i < errorResponse.messages.length; i++) {
                errorMessages.push(errorResponse.messages[i]);
            }
        }

        return errorMessages;
    }

    var extractError = function (errorResponse, defaultMessage) {
        var errorMessage = defaultMessage;
        errorResponse = _getError(errorResponse);
        if (errorResponse) {
            if (errorResponse.message) {
                errorMessage = errorResponse.message;
            } else if (errorResponse.messages) {
                errorMessage = errorResponse.messages[0];
            } else if (errorResponse.field_errors && errorResponse.field_errors[0].message) {
                errorMessage = errorResponse.field_errors[0].field + ": " + errorResponse.field_errors[0].message;
            }
        }
        return errorMessage;
    };

    var getValidationErrors = function (error) {
        error = _getError(error);
        if (error && error.field_errors && error.field_errors[0].message) {
            return error.field_errors;
        }
        return null;
    };

    var _getError = function (errorResponse) {
        if (errorResponse && errorResponse.data) {
            return errorResponse.data;
        } else {
            return errorResponse;
        }
    };

    var getErrorCode = function (err) {
        var error = _getError(err);
        return error.error_code ? error.error_code : 0;
    };

    return {
        onError: onError,
        extractError: extractError,
        onErrorDialog: onErrorDialog,
        onPaymentError: onPaymentError,
        getErrorCode: getErrorCode
    };
}
]);
