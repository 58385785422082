import app from "js/legacy-app";

app.controller('ShowUserPayInfoCtrl', ['User', '$scope', 'id', '$mdDialog', 'Notification', 'ErrorHandler',
    function (User, $scope, id, $mdDialog, Notification, ErrorHandler) {

        User.get({id: id}, function (data) {
            $scope.user = data;
        });

        $scope.close = function () {
            $mdDialog.hide();
        };

        $scope.save = function () {
            User.updateUserPay({id1: id}, {shouldPay: $scope.user.shouldPay}, function (date) {
                Notification.success("User pay option was updated");
                $scope.close();
            }, function (error) {
                ErrorHandler.onError(error);
            });
        };

    }]);