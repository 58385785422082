import app from "js/legacy-app";

app.directive('vtMainMenu', [function () {
    return {
        templateUrl: "template/component/main-menu.tpl.html",
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: ['$scope', function ($scope) {
        }]
    };
}]);
