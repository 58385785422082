export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/mechanic/app-marketplace', 'mechanic-app-marketplace')
        .when('/mechanic/app-marketplace/:applicationId', 'mechanic-app-marketplace-view')

        .segment('mechanic-app-marketplace', {
            access: {requireLogin: true},
            template: '<mechanic-app-marketplace></mechanic-app-marketplace>',
            loadModule: () => {
                return import(/* webpackChunkName: "mechanic-app-marketplace.module" */ "./app-marketplace-list/app-marketplace-list.module");
            }
        })
        .segment('mechanic-app-marketplace-view', {
            access: {requireLogin: true},
            template: '<mechanic-app-marketplace-view></mechanic-app-marketplace-view>',
            loadModule: () => {
                return import(/* webpackChunkName: "mechanic-app-marketplace-view.module" */ "./app-marketplace-view/app-marketplace-view.module");
            }
        });

}
