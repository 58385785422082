// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/img/user-no-avatar.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<md-dialog class=\"bike-transfer\">\n    <form class=\"vt-form\" name=\"bikeTransferForm\" data-ng-submit=\"submitForm(bikeTransferForm)\"\n          novalidate>\n        <md-dialog-content class=\"bike-transfer\">\n            <h1>\n                Select new bicycle owner\n            </h1>\n            <md-autocomplete\n                    md-selected-item=\"autocomplete.selectedItem\"\n                    md-search-text=\"autocomplete.searchText\"\n                    md-items=\"item in autocomplete.querySearch(autocomplete.searchText)\"\n                    md-item-text=\"autocomplete.displayText(item)\"\n                    md-min-length=\"3\"\n                    placeholder=\"Start typing user email ...\">\n                <md-item-template class=\"autocomplete-custom-template\">\n                    <img src=\"{{ item.image }}\" ng-if=\"item.image\">\n                    <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\" ng-if=\"!item.image\"/>\n                    <span md-highlight-text=\"autocomplete.searchText\" md-highlight-flags=\"^i\">{{autocomplete.displayText(item)}}</span>\n                </md-item-template>\n            </md-autocomplete>\n            <md-input-container class=\"align-right\">\n                <md-button ng-click=\"close()\" aria-label=\"Cancel\">Cancel\n                </md-button>\n                <md-button type=\"submit\" class=\"md-primary\"\n                           aria-label=\"Transfer\">Transfer\n                </md-button>\n            </md-input-container>\n        </md-dialog-content>\n    </form>\n</md-dialog>\n";
// Exports
var _module_exports = code;;
var path = 'template/dashboard/bikes/select-user-for-bike-transfer-dialog.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;