import angular from "angular";
import bookingCustomerActions from "./booking-customer-actions";
import customerDefaultImage from "img/user-no-avatar.png";

const component = {
    template: require('./booking-customer-card.html'),
    bindings: {
        onSubmit: "&?vuiBcOnSubmit",
        model: "<vuiBcCustomer"
    },
    transclude: {
        actions: "?vuiBookingCustomerActions"
    },
    controller: function () {
        const ctrl = this;

        ctrl.submit = function () {
            if (angular.isFunction(ctrl.onSubmit)) {
                ctrl.onSubmit({customer: ctrl.model});
            }
        };

        ctrl.$onInit = () => {
            this.model = this.model || {};
            this.customerDefaultImage = customerDefaultImage;
        };
    }
};

export default angular.module("vui-booking-customer.module", [bookingCustomerActions.name])
    .component('vuiBookingCustomer', component);
