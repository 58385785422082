import app from "js/legacy-app";
import angular from "angular";

app.controller('PersonalServiceListCtrl',
    ['$scope', 'CustomServiceManagement', '$vtSidenav', '$routeParams', '$timeout', 'ToggleLD', 'ErrorHandler',
        function ($scope, CustomServiceManagement, $vtSidenav, $routeParams, $timeout, ToggleLD, ErrorHandler) {

            $scope.mislc = {
                serviceGroup: 'PERSONAL',
            };

            let mislc = {};

            $scope.mislc.search = function () {
                return CustomServiceManagement.tree({group: $scope.mislc.serviceGroup}).$promise;
            };

            $scope.mislc.createNewCategory = function () {
                $vtSidenav("create-custom-service-category").open();
            };

            $scope.mislc.viewCategory = function (id) {
                $vtSidenav("custom-service-category-view").open(id);
            };

            $scope.mislc.createNewService = function (model) {
                $vtSidenav("create-custom-personal-service").open({category: model.category});
            };

            $scope.mislc.viewService = function (id) {
                $vtSidenav("custom-personal-service-view").open(id);
            };

            mislc.removeServiceFromPopular = function (serviceId) {
                let index = $scope.mislc.popular.indexOfObject("id", serviceId);
                if (index !== -1) {
                    $scope.mislc.popular.splice(index, 1);
                }

                $scope.mislc.nodes.map(function (node) {
                    return node.services.filter(function (service) {
                        return serviceId === service.id;
                    }).forEach(function (service) {
                        service.popular = false;
                    });
                });
            };

            mislc.addServiceToPopular = function (service) {
                $scope.mislc.popular.push(service);

                $scope.mislc.nodes.map(function (node) {
                    return node.services.filter(function (s) {
                        return service.id === s.id;
                    }).forEach(function (s) {
                        s.popular = true;
                    });
                });
            };

            $scope.mislc.updateServicePopularity = function (service, popularityFlag) {
                if (popularityFlag) {
                    mislc.addServiceToPopular(service);
                } else {
                    mislc.removeServiceFromPopular(service.id);
                }
            };

            $scope.mislc.refreshPage = function () {
                ToggleLD.show();
                $scope.mislc.search().then(function (response) {
                    let array = response.nodes.map(function (node) {
                        return node.services.filter(function (s) {
                            return s.popular;
                        });
                    });
                    $scope.mislc.popular = Array.prototype.concat(...array);
                    $scope.mislc.nodes = response.nodes;

                }, function (error) {
                    ErrorHandler.onError(error);
                }).finally(function () {
                    ToggleLD.hide();
                });
            };

            $scope.mislc.onSaveService = (data) => {
                let nodes = $scope.mislc.nodes;
                let categoryId = data.categoryId;

                let node = nodes.filter((node) => {
                    return node.category.id === categoryId;
                })[0];

                let service = node.services.filter((service) => {
                    return service.id === data.id;
                })[0];

                angular.copy(data, service);
                service.serviceState = service.state;
            };

            $scope.mislc.onCreateService = (data) => {
                let nodes = $scope.mislc.nodes;
                let categoryId = data.categoryId;

                let node = nodes.filter((node) => {
                    return node.category.id === categoryId;
                })[0];

                let services = node.services;
                if (!services || !services.length) {
                    node.services = [];
                }

                node.services.push(data);
            };

            $scope.mislc.onDeleteService = (data) => {
                let nodes = $scope.mislc.nodes;
                let categoryId = data.categoryId;

                let node = nodes.filter((node) => {
                    return node.category.id === categoryId;
                })[0];

                let index = node.services.indexOfObject("id", data.id);

                if (index !== -1) {
                    node.services.splice(index, 1);
                }
            };

            $scope.mislc.onResetDefaultService = (data) => {
                let nodes = $scope.mislc.nodes;
                let categoryId = data.categoryId;

                let node = nodes.filter((node) => {
                    return node.category.id === categoryId;
                })[0];

                let service = node.services.filter((service) => {
                    return service.id === data.id;
                })[0];

                angular.copy(data, service);
                service.serviceState = service.state;
            };

            $scope.mislc.onSaveCategory = (data) => {
                let nodes = $scope.mislc.nodes;
                let categoryId = data.id;

                let node = nodes.filter((node) => {
                    return node.category.id === categoryId;
                })[0];

                node.category.name = data.name;
            };

            $scope.mislc.onDeleteCategory = (categoryId) => {
                let nodes = $scope.mislc.nodes;
                for (let i = 0; i < nodes.length; i++) {
                    let node = nodes[i];

                    if (node.category.id === categoryId) {
                        $scope.mislc.nodes.splice(i, 1);
                        break;
                    }
                }
            };

            (function init() {
                let serviceId = $routeParams.serviceId;
                if (serviceId) {
                    $timeout(function () {
                        $scope.mislc.viewService({id: serviceId});
                    });
                }

                $scope.mislc.refreshPage();
            })();

        }]);
