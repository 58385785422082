import app from "js/legacy-app";

app.controller('SelectUserForBikeTransferCtrl', ['$scope', '$mdDialog', 'ToggleLD', '$bikeService',
    'Notification', 'bikeId', 'ErrorHandler', '$q', 'User',
    function ($scope, $mdDialog, ToggleLD, $bikeService, Notification, bikeId, ErrorHandler, $q, User) {

        var subtc = {
            resource: $bikeService.getResourceClass()
        };

        $scope.bikeTransfer = {
            email: null
        };

        $scope.autocomplete = {};

        $scope.close = function () {
            $mdDialog.cancel();
        };

        $scope.submitForm = function (form) {
            if (form.$valid) {
                transfer();
            }
        };

        $scope.autocomplete.querySearch = function (email) {
            var deferred = $q.defer();
            User.findByEmailLike({email: email}, function (response) {
                deferred.resolve(response.items);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        $scope.autocomplete.displayText = function (item) {
            return item.name + " " + item.surname + "(" + item.email + ")";
        };

        var transfer = function () {
            if ($scope.autocomplete.selectedItem) {
                $scope.bikeTransfer.email = $scope.autocomplete.selectedItem.email;
            } else if ($scope.autocomplete.searchText) {
                $scope.bikeTransfer.email = $scope.autocomplete.searchText;
            } else {
                Notification.error("Please enter new owner email");
            }
            ToggleLD.show();
            subtc.resource.transferBikeToNewOwner({id: bikeId}, $scope.bikeTransfer, function (response) {
                $mdDialog.hide({});
                ToggleLD.hide();
                Notification.success("The email message has been send to new owner in order to confirm transfer");
            }, function (error) {
                ToggleLD.hide();
                ErrorHandler.onError(error, "Failed to transfer bicycle");
            });
        };

    }]);