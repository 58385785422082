import angular from "angular";
import OrganisationsApi from "../../resources/organisations";

import AdminOrganisationModuleRouting from "./organisation.module.routing";

export default angular
    .module("vt-admin-organisation", [
        OrganisationsApi.name
    ])
    .config(AdminOrganisationModuleRouting);
