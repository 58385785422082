import app from "js/legacy-app";
import angular from "angular";

app.directive('vtWizard', ['$compile', '$window', '$q', 'ToggleLD', 'ErrorHandler', '$timeout',
    function ($compile, $window, $q, ToggleLD, ErrorHandler, $timeout) {
        return {
            templateUrl: "components/wizard/wizard.html",
            replace: true,
            transclude: true,
            scope: {
                id: "@wId",
                options: "=wOptions",
                continueButton: "@?wContinueButton",
                hideBackButton: "=?wHideBackButton"
            },

            controller: ['$scope', '$transclude', function ($scope, $transclude) {

                $scope.$transclude = $transclude;
                $scope.continueButton = $scope.continueButton || 'Continue';
                $scope.hideNextBtn = false;

                var cursor = $scope.cursor = {
                    next: function () {
                        $timeout(function () {
                            if (active(cursor.step)) {
                                leaveStep(cursor.step);
                                if ($scope.options.completeOnNext) {
                                    cursor.completeStep();
                                }
                            }

                            if (canComplete()) {
                                complete();
                                return;
                            }

                            var nextStepPosition = cursor.position + 1;
                            if (nextStepPosition >= chain.steps.length) {
                                nextStepPosition = 0;
                            }
                            var nextStep = chain.steps[nextStepPosition];

                            if (active(nextStep)) {
                                enterStep(nextStepPosition);
                            } else if (canComplete()) {
                                complete();
                            } else {
                                for (var i = nextStepPosition; i < chain.steps.length; i++) {
                                    nextStepPosition = i + 1;
                                    if (nextStepPosition >= chain.steps.length) {
                                        nextStepPosition = 0;
                                    }

                                    nextStep = chain.steps[nextStepPosition];

                                    if (active(nextStep)) {
                                        enterStep(nextStepPosition);
                                        break;
                                    } else if (canComplete()) {
                                        complete();
                                        break;
                                    }
                                }
                            }
                        });

                    },

                    back: function () {
                        $timeout(function () {
                            if (!cursor.step.disabled) {
                                leaveStep(cursor.step);
                            }

                            var prevStepPosition = cursor.position - 1;

                            if (prevStepPosition < 0) {
                                cursor.position = 0;
                                close();
                                return;
                            }

                            var prevStep = chain.steps[prevStepPosition];

                            if (!prevStep.disabled) {
                                enterStep(prevStepPosition);
                            } else {
                                for (var i = prevStepPosition; i >= 0; i--) {
                                    prevStepPosition = i - 1;

                                    if (prevStepPosition < 0) {
                                        cursor.position = 0;
                                        close();
                                        return;
                                    }

                                    prevStep = chain.steps[prevStepPosition];

                                    if (!prevStep.disabled) {
                                        enterStep(prevStepPosition);
                                        break;
                                    }
                                }
                            }
                        });
                    },

                    completeStep: function (id) {
                        if (id) {
                            doForStep(id, function (step) {
                                step.completed = true;
                            });
                        } else {
                            cursor.step.completed = true;
                        }
                    },

                    uncompleteStep: function (id) {
                        if (id) {
                            doForStep(id, function (step) {
                                step.completed = false;
                            });
                        } else {
                            cursor.step.completed = false;
                        }
                    },

                    disableStep: function (id) {
                        doForStep(id, function (step) {
                            step.disabled = true;
                        });
                    },

                    enableStep: function (id) {
                        doForStep(id, function (step) {
                            step.disabled = false;
                        });
                    },

                    goTo: function (id) {
                        $timeout(function () {
                            var prevStep = cursor.step;
                            chain.steps.forEach(function (step, index) {
                                if (step.id === id) {
                                    leaveStep(prevStep);
                                    enterStep(index);
                                }
                            });
                        });
                    },

                    isDisabled: function (id) {
                        if (id) {
                            doForStep(id, function (step) {
                                return step.disabled;
                            });
                        } else {
                            return cursor.step.disabled;
                        }
                    },

                    hideNextButton: function () {
                        $scope.hideNextBtn = true;
                    },

                    showNextButton: function () {
                        $scope.hideNextBtn = false;
                    },

                    position: 0
                };

                if ($scope.id) {
                    $scope.$parent[$scope.id] = cursor;
                }

                var chain = {
                    steps: []
                };

                var close = function () {
                    invokeWizardCloseCallback();
                };

                var invokeStepEnterCallback = function (step) {
                    if (step && angular.isFunction(step.onEnter)) {
                        return step.onEnter(cursor);
                    }
                };

                var invokeStepLeaveCallback = function (step) {
                    if (step && angular.isFunction(step.onLeave)) {
                        step.onLeave(cursor);
                    }
                };

                var invokeWizardCompleteCallback = function () {
                    if (angular.isFunction($scope.options.onComplete)) {
                        $scope.options.onComplete(cursor);
                    }
                };

                var invokeWizardCloseCallback = function () {
                    if (angular.isFunction($scope.options.onClose)) {
                        $scope.options.onClose(cursor);
                    } else {
                        $window.history.back();
                    }
                };

                var doForStep = function (id, callback) {
                    chain.steps.filter(function (step) {
                        return step.id === id;
                    }).forEach(callback);
                };

                var enterStep = function (position) {
                    var step = chain.steps[position];
                    var promise = invokeStepEnterCallback(step);
                    if (promise && promise.then) {
                        ToggleLD.show();
                        $q.when(promise).then(function () {
                            cursor.position = position;
                            cursor.step = step;
                            wizard.title = step.title;
                        }, function (error) {
                            ErrorHandler.onError(error);
                        }).finally(function () {
                            ToggleLD.hide();
                        });
                    } else {
                        cursor.position = position;
                        cursor.step = step;
                        wizard.title = step.title;
                    }
                };

                var leaveStep = function (step) {
                    invokeStepLeaveCallback(step);
                };

                var complete = function () {
                    invokeWizardCompleteCallback();
                };

                var canComplete = function () {
                    return chain.steps.filter(function (step) {
                        return active(step);
                    }).length === 0;
                };

                var active = function (step) {
                    return !step.disabled && !step.completed;
                };

                var wizard = this.wizard = $scope.wizard = {

                    next: function () {
                        if (cursor.step.completed) {
                            cursor.next();
                        } else if (angular.isFunction(cursor.step.next)) {
                            cursor.step.next(cursor);
                        }
                    },

                    back: function () {
                        if (angular.isFunction(cursor.step.back)) {
                            cursor.step.back(cursor);
                        } else if (cursor.position === 0) {
                            close();
                        }
                    },

                    registerStep: function (step) {
                        chain.steps.push(step);
                        chain.steps.sort(function (a, b) {
                            return a.position - b.position;
                        });
                        cursor.step = chain.steps[cursor.position];
                    },

                    isCurrentStepLast: function () {
                        return chain.steps.filter(function (step) {
                            return active(step) && step.id !== cursor.step.id;
                        }).length === 0;
                    },

                    nonDisabledSteps: function () {
                        return chain.steps.filter(function (step) {
                            return !step.disabled;
                        });
                    },

                    goTo: cursor.goTo,

                    isDisabled: cursor.isDisabled,

                    close: close
                };

            }]
        };
    }]);
