import angular from "angular";

const component = {
    template: require("./bicycle-part-creation-dialog.html"),
    bindings: {
        title: '@?vuiBpcdTitle'
    },
    controller: function ($mdDialog) {
        'ngInject';

        this.$onInit = () => {
            this.title = this.title || "Add new part";
        };

        this.save = (form) => {
            if (!form.$valid) return;

            $mdDialog.hide(this.part);
        };

        this.cancel = () => {
            $mdDialog.hide();
        };
    }

};

export default angular.module("vui-bicycle-part-creation-dialog.module", [])
    .component("vuiBicyclePartCreationDialog", component);