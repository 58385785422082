import app from "js/legacy-app";

app.controller('ViewOrganisationBikesCtrl', ['$scope', '$routeParams', 'ModalService', function ($scope, $routeParams, ModalService) {

    $scope.callRefresh = {};

    $scope.organisationId = $routeParams.organisationId;

    $scope.importBikes = function($event) {
        ModalService.importBikes($event, {organisationId: $scope.organisationId}, function (response) {
            if (response) {
                if (response.bikes && response.bikes.length > 0) {
                    ModalService.showRepeatedBikes($event, response.bikes);
                }
                $scope.callRefresh.refresh();
            }
        });
    };

}]);
