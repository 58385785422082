import app from "js/legacy-app";
import angular from "angular";

app.controller('TypeOfBikesCtrl', ['$scope', 'TypeOfBikes', '$q', '$location', 'ModalService',
    function ($scope, TypeOfBikes, $q, $location, ModalService) {

        var countChecked = function () {
            if (!$scope.initDataTable.getData) {
                return;
            }
            var count = 0;
            var data = $scope.initDataTable.getData();

            angular.forEach(data, function (item) {
                if (item.selected) {
                    count++;
                }
            });
            return count;
        };

        var update = function () {
            var id;
            var data = $scope.initDataTable.getData();
            angular.forEach(data, function (item) {
                if (item.selected) {
                    id = item.id;
                }
            });

            if (id) {
                $location.url('/admin/bike-types/' + id);
            }
        };

        var remove = function () {
            var message = 'Are you sure you want to remove this bicycle type?';
            ModalService.yesNoMessage(null, '', message, function (response) {
                if (response === true) {
                    var promises = [];
                    var data = $scope.initDataTable.getData();

                    angular.forEach(data, function (item) {
                        if (item.selected) {
                            promises.push(TypeOfBikes.delete({id: item.id}).$promise);
                        }
                    });

                    $q.all(promises).finally($scope.initDataTable.refresh);
                }
            });
        };

        $scope.initDataTable = {
            service: TypeOfBikes,
            selected: true,
            name: 'admin-bike-types-list',
            showFilters: true,
            columns: [
                {
                    name: 'name',
                    columnName: 'Name',
                    show: true,
                    sort: true,
                    filterColumnName: 'name',
                    filter: 'string'
                },
                {
                    name: 'groupsets',
                    columnName: 'Groupsets',
                    show: true,
                    sort: false,
                    getValue: function (value) {
                        return value.length;
                    }
                },
                {
                    name: 'components',
                    columnName: 'Components',
                    show: true,
                    sort: false,
                    getValue: function (value) {
                        return value.length;
                    }
                },
                {
                    name: 'frameSizes',
                    columnName: 'Frame Sizes',
                    show: true,
                    sort: false,
                    getValue: function (value) {
                        return value.length;
                    }
                }
            ],
            actions: [
                {
                    html: '<i class="fa fa-plus" aria-hidden="true"></i>',
                    title: 'Add',
                    apply: function (data, refrash) {
                        $location.path('/admin/bike-types/create');
                    },
                    disable: function () {
                        return false;
                    }
                },
                {
                    html: '<i class="fa fa-pencil" aria-hidden="true"></i>',
                    title: 'Edit',
                    apply: update
                },
                {
                    html: '<i class="fa fa-times" aria-hidden="true"></i>',
                    title: 'Remove',
                    apply: remove,
                    disable: function () {
                        return countChecked() === 0;
                    }
                }
            ]
        };

    }
]);