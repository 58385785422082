import app from "js/legacy-app";

app.config(['$httpProvider',
    function ($httpProvider) {
        var interceptor = ['$q', '$location', '$injector', function ($q, $location, $injector) {
            return {
                response: function (response) {
                    return response;
                },
                responseError: function (rejection) {
                    var AuthService = $injector.get('AuthService');

                    try {
                        // skip cancelled requests https://glebbahmutov.com/blog/catch-all-errors-in-angular-app/
                        if (rejection.status) {
                            // TODO need to pay
                            /*Sentry.captureException(new Error("Http response error " +  rejection.status), {
                                extra: {
                                    Response: rejection.data,
                                    Config: rejection.config,
                                    Status: rejection.status
                                }
                            });*/
                        }
                    } catch (e) {
                        console.error(e.stack)
                    }
                    var status = rejection.status;
                    var redirect = encodeURIComponent($location.url());
                    if ((status === 503 || status === 502) && $location.path() !== '/maintenance') {
                        $location.path('/maintenance').search({redirect: redirect});
                    } else if (status === 401 && AuthService.isLogin()
                        && rejection.config.url !== '/api/logout' && $location.path() !== '/login') {
                        var deferred = $q.defer();
                        AuthService.refreshToken((response) => {
                            var $http = $injector.get('$http');
                            var config = rejection.config;
                            config.headers['Authorization'] = response.token_type + ' ' + response.access_token;
                            $http(config).then((resp) => {
                                deferred.resolve(resp);
                            }, (resp) => {
                                deferred.reject(resp);
                                $location.path('/login').search({redirect: redirect});
                            });
                        }, (response) => {
                            deferred.reject(response);
                            $location.path('/login').search({redirect: redirect});
                        });
                        return deferred.promise;
                    }

                    return $q.reject(rejection);
                }
            };
        }];
        $httpProvider.interceptors.push(interceptor);
        $httpProvider.useApplyAsync(true);
        $httpProvider.defaults.timeout = 5000;
    }
]);
