export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/partner/app-marketplace', 'partner-app-marketplace')
        .when('/partner/app-marketplace/:applicationId', 'partner-app-marketplace-view')

        .segment('partner-app-marketplace', {
            access: {requireLogin: true},
            template: '<partner-app-marketplace></partner-app-marketplace>',
            loadModule: () => {
                return import(/* webpackChunkName: "partner-app-marketplace.module" */ "./app-marketplace-list/app-marketplace-list.module");
            }
        })
        .segment('partner-app-marketplace-view', {
            access: {requireLogin: true},
            template: '<partner-app-marketplace-view></partner-app-marketplace-view>',
            loadModule: () => {
                return import(/* webpackChunkName: "partner-app-marketplace-view.module" */ "./app-marketplace-view/app-marketplace-view.module");
            }
        });

}
