import app from "js/legacy-app";

app.directive('profileAbout', [function () {
    return {
        templateUrl: "template/profile/widget/profile-about.html",
        replace: true,
        scope: {
            image: "=",
            text: "=",
            userName: "="
        },
        controller: ['$scope', function ($scope) {

            $scope.profileAbout = {
                readMore: false,
                animate: "0.3",
                minHeight: 110
            };

            $scope.readMore = function () {
                $scope.profileAbout.readMore = !$scope.profileAbout.readMore;
            };
        }]
    };
}]);