import app from "js/legacy-app";

app.controller('ChooseToolsCtrl', ['$scope', 'User', 'Tools', '$location', 'ModalService', function ($scope, User, Tools, $location, ModalService) {

    $scope.toolsVerification = {
        saveDisabled: false,
        checkedAllIntermediate: false,
        checkedAllExpert: false
    };

    User.getVerification(function(data) {
        $scope.verification = data;

        Tools.get(function (data) {
            $scope.allTools = data.data;

            if ($scope.verification && $scope.verification.toolsVerification && $scope.verification.toolsVerification.toolIds) {
                for (var i in $scope.allTools) {
                    if ($scope.verification.toolsVerification.toolIds.indexOf($scope.allTools[i].id) > -1) {
                        $scope.allTools[i].checked = true;
                    }
                }
            }
            $scope.checkTool('INTERMEDIATE');
            $scope.checkTool('EXPERT');
        });
    });

    $scope.checkAllTools = function(level) {
        var newValue = false;
        if (level === 'INTERMEDIATE') {
            newValue = $scope.toolsVerification.checkedAllIntermediate;
        } else {
            newValue = $scope.toolsVerification.checkedAllExpert;
        }
        for (var i in $scope.allTools) {
            if ($scope.allTools[i].type === level) {
                $scope.allTools[i].checked = newValue;
            }
        }
    };

    $scope.checkTool = function(level) {
        for (var i in $scope.allTools) {
            if ($scope.allTools[i].type === level) {
                if (!$scope.allTools[i].checked) {
                    if (level === 'INTERMEDIATE') {
                        $scope.toolsVerification.checkedAllIntermediate = false;
                        return;
                    } else {
                        $scope.toolsVerification.checkedAllExpert = false;
                        return;
                    }
                }
            }
        }

        if (level === 'INTERMEDIATE') {
            $scope.toolsVerification.checkedAllIntermediate = true;
        } else {
            $scope.toolsVerification.checkedAllExpert = true;
        }
    };

    $scope.checkAllIntermediateTools = function() {
        for (var i in $scope.allTools) {
            if ($scope.allTools[i].type === 'INTERMEDIATE' && !$scope.allTools[i].checked) {
                return false;
            }
        }

        return true;
    };

    $scope.continue = function() {
        $scope.toolsVerification.disabled = true;
        var ids = [];
        for (var i in $scope.allTools) {
            if ($scope.allTools[i].checked) {
                ids.push($scope.allTools[i].id);
            }
        }

        if (!$scope.checkAllIntermediateTools()) {
            var message = 'You must have all required tools otherwise you will lose all checked mandatory tools. Save anyway?';
            ModalService.yesNoMessage(null, '', message, function (response) {
                if (response === true) {
                    for (var i in $scope.allTools) {
                        if ($scope.allTools[i].type == 'EXPERT') {
                            $scope.allTools[i].checked = false;

                            var indexOfElement = ids.indexOf($scope.allTools[i].id);
                            if(indexOfElement < 0) {
                                continue;
                            }

                            ids.splice(indexOfElement, 1);
                        }
                    }
                    saveTools(ids);
                }
                else{
                    $scope.toolsVerification.disabled = false;
                }
            });
        }
        else{
            saveTools(ids);
        }
    };

    var saveTools = function(toolsIds){
        User.chooseToolsVerification(toolsIds, function (data) {
            $location.path('/dashboard');
            $scope.checkTool('INTERMEDIATE');
            $scope.checkTool('EXPERT');
        }, function() {
            $scope.toolsVerification.disabled = false;
            $scope.checkTool('INTERMEDIATE');
            $scope.checkTool('EXPERT');
        });
    };

}]);