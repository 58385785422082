import app from "js/legacy-app";

app.controller('CreateCoreServiceCategoryCtrl',
    function ($scope, $location, ErrorHandler, ToggleLD, Notification, CoreServiceCategories, CoreServiceBuckets) {
        'ngInject';

        $scope.category = {};

        $scope.categoryCtrl = {

            save: function (form) {
                if (!form.$valid) {
                    return;
                }

                ToggleLD.show();
                CoreServiceCategories.save(this.get(), function (c) {
                    ToggleLD.hide();
                    $location.url("/admin/view-category/" + c.id);
                    Notification.success("Category was successfully created");
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error, "Failed to create service category", "ERROR", form);
                });
            },

            close: function () {
                $location.url("/admin/service-management/categories");
            },

            get: function () {
                var category = $scope.category;
                var dto = {};

                dto.name = category.name;
                dto.description = category.description;
                dto.serviceBucket = category.serviceBucket;
                return dto;
            }
        };

        (function init() {
            CoreServiceBuckets.get({size: 100}, function (res) {
                $scope.categoryCtrl.serviceBuckets = [].concat(res.data.map((sb) => {
                    return {id: sb.id, val: sb.type, display: sb.name};
                }));

            });
        })();
    }
);
