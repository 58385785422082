import app from "js/legacy-app";

app.directive('requestCitiesStatistic', [function () {
    return {
        template: '<div class="md-padding chart-container">' +
        '   <div>Request by cities</div>' +
        '   <div>' +
        '       <canvas class="chart chart-pie" chart-data="data" chart-labels="labels" chart-options="options"></canvas>' +
        '   </div>' +
        '</div>',
        replace: true,
        scope: true,
        controller: ['$scope', 'AdminRequest', function ($scope, AdminRequest) {

            $scope.labels = [];
            $scope.data = [];

            AdminRequest.countByCity(function(data) {
                for (var i = 0; i < data.length; i++) {
                    if (!data[i].city) {
                        continue;
                    }
                    $scope.labels.push(data[i].city);
                    $scope.data.push(data[i].count);
                }
            });

        }]
    };
}]);
