import angular from "angular";
import selectBikesDialog from "../../../select-bikes/dialog"

const addBicycleAction = {
    template: require("./booking-bicycles-add-action.html"),
    require: {
        parent: '^vuiBookingBicycles'
    },
    bindings: {
        filter: '<?vuiAaFilter',
        disabled: '<?vuiAaDisable'
    },
    controller: function ($mdDialog) {
        'ngInject';

        this.$onInit = () => {
            this.filter = this.filter || {};
        };

        this.add = (event) => {
            const ctrl = this;

            $mdDialog.show({
                targetEvent: event,
                template: `<vui-select-bike-dialog vui-sbd-options="$ctrl.options" 
                                                   vui-sbd-exclude-bikes="$ctrl.bikes" 
                                                   vui-sbd-filter="$ctrl.filter">
                            </vui-select-bike-dialog>`,
                autoWrap: false,
                controllerAs: '$ctrl',
                bindToController: true,
                locals: {
                    filter: ctrl.filter,
                    bikes: ctrl.parent.model,
                    options: ctrl.parent.options
                },
                controller: function () {
                }
            }).then((bikes) => {
                if (!bikes || bikes.length === 0) {
                    return;
                }

                this.parent.model.push(...bikes);
                this.parent.submit();
            });
        };
    }
};

export default angular.module("vui-booking-bicycles-actions.module", [selectBikesDialog.name])
    .component("vuiBbAddAction", addBicycleAction);