import angular from "angular";

const component = {
    template: require('./back-button.html'),
    bindings: {
        color: "@?vuiBbColor",
        onClick: "&vuiBbOnClick"
    },
    controller: function () {
        this.goTo = () => {
            this.onClick();
        };

        this.color = this.color || 'white';
    }
};

export default angular.module("vui-back-button", [])
    .component('vuiBackButton', component);
