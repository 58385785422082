export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/our-coverage', 'our-coverage')
        .when('/our-coverage/:state/:city', 'our-coverage')
        .when('/locations', 'locations')
        .when('/quick-booking', 'quick-booking')

        .segment('our-coverage', {
            access: {requireLogin: false},
            template: '<our-coverage-page></our-coverage-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "our-coverage.module" */ "./our-coverage/our-coverage.module");
            }
        })
        .segment('locations', {
            access: {requireLogin: false},
            template: '<locations-page></locations-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "locations.module" */ "./locations/locations.module");
            }
        })
        .segment('quick-booking', {
            access: {requireLogin: false},
            template: '<quick-booking-page></quick-booking-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "quick-booking.module" */ "./quick-booking/quick-booking.module");
            }
        });
}
