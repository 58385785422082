import app from "js/legacy-app";

app.directive('blChangeGroupAction', ['ModalService', 'Notification', 'AuthService', '$pageableList',
    function (ModalService, Notification, AuthService, $pageableList) {
        return {
            template: "<md-menu-item>" +
                "          <md-button ng-click='bcga.changeGroup($event, bike)'>Change group</md-button>" +
                "      </md-menu-item>",
            replace: true,
            link: function ($scope) {

                $scope.bcga = {};

                $scope.bcga.changeGroup = function ($event, bike) {
                    ModalService.changeBikeGroup($event, bike.id, function () {
                        $pageableList.get('bike-list').refreshPage();
                    });
                };
            }
        };
    }]);
