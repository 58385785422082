import app from "js/legacy-app";

app.filter("mechanicVerification", [function () {
    return function (verified, type) {
        switch (type) {
            case 'interview':
                return verified ? 'Completed' : 'N/A';
            case 'insurance':
                return verified ? 'Active' : 'N/A';
            default:
                return verified ? 'Verified' : 'N/A';
        }
    };
}]);