import app from "js/legacy-app";
import angular from "angular";

app.directive('profileGallery', ['Documents', function (Documents) {
    return {
        templateUrl: "template/profile/tabs/gallery/profile-gallery.html",
        link: function ($scope, $element, $attrs, ngModel) {
        },
        scope: {
            onUpload: "&?pgOnUpload"
        },
        controller: ['$scope', 'User', 'ModalService', 'Notification',
            function ($scope, User, ModalService, Notification) {

                $scope.profileGallery = {};

                $scope.profileGallery.refreshProfile = function (callback) {
                    User.current(function (data) {
                        $scope.profileGallery.user = data;
                        if (callback) {
                            callback($scope.profileGallery.user);
                        }
                    });
                };

                $scope.profileGallery.refreshProfile();

                $scope.profileGallery.remove = function (index, callback) {
                    Documents.profileImage().remove($scope.profileGallery.user.images[index].id).then(function () {
                        $scope.profileGallery.user.images.splice(index, 1);
                        if (callback) {
                            callback();
                        }
                        Notification.success("Image has been successfully removed");
                    }, function (error) {
                        Notification.error("Failed to remove the image");
                    });
                };

                $scope.profileGallery.setCover = function (index, callback) {
                    Documents.profileImage().setAsCover($scope.profileGallery.user.images[index].id).then(function () {
                        if (callback) {
                            callback();
                        }
                        Notification.success("Cover image has been successfully changed");
                    }, function (error) {
                        Notification.error("Failed to set the image as cover");
                    });
                };

                $scope.uploadNew = function ($event) {
                    ModalService.uploadImages($event, Documents.profileImage(), function (images) {
                        for (var i in images) {
                            $scope.profileGallery.user.images.push(images[i]);
                        }

                        if (angular.isFunction($scope.onUpload)) {
                            $scope.onUpload();
                        }
                    });
                };

            }]
    };
}]);
