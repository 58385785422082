import app from "js/legacy-app";

app.service('OrganisationServiceCategories', ['$resource', function ($resource) {
    return $resource('/api/admin-organisation/:id/custom-service-categories/:id1/:id2', {}, {
        patch: {
            method: "PUT",
            params: {},
            isArray: false
        },
        resetDefault: {
            method: "PUT",
            params: {id2: "reset-default"},
            isArray: false
        }
    });
}]);
