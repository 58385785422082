import app from "js/legacy-app";

app.controller('ProfileAddressEditDialogController', ['$scope', '$mdDialog', 'model', 'User', 'ToggleLD',
    'ErrorHandler', 'LocationService', 'Notification',
    function ($scope, $mdDialog, model, User, ToggleLD, ErrorHandler, LocationService, Notification) {

        $scope.model = model;

        $scope.pae = {
            cancel: function () {
                $mdDialog.hide();
            },

            validAddress: function (address) {
                var error = LocationService.validateProfileAddress(address);
                if (error) {
                    Notification.error(error);
                    return false;
                }

                return true;
            },

            save: function (form) {
                if (!this.validAddress($scope.model.address)) {
                    return;
                }

                if (form.$valid) {
                    ToggleLD.show();
                    var id = model.id;

                    if (id) {
                        update(model);
                    } else {
                        create(model);
                    }
                }
            }
        };

        function update(location) {
            User.updateProfileAddress({id1: location.id}, LocationService.mapProfileAddress(location), function (l) {
                onSuccess(l);
            }, function (error) {
                onError(error);
            });
        }

        function create(location) {
            User.saveProfileAddress(LocationService.mapProfileAddress(location), function (l) {
                onSuccess(l);
            }, function (error) {
                onError(error);
            });
        }

        function onSuccess(location) {
            ToggleLD.hide();
            $mdDialog.hide(location);
            Notification.success("Address has been updated");
        }

        function onError(error) {
            ToggleLD.hide();
            ErrorHandler.onError(error, "Failed to save address");
        }
    }
]);