import app from "js/legacy-app";

app.directive('collapsibleList', [function () {
    return {
        templateUrl: "template/component/widget/collapsible-list.html",
        require: "?ngModel",
        replace: true,
        transclude: true,
        scope: {
            list: "=",
            parent: "=",
            minVisibleItems: "@",
            widget: "="
        },
        link: function ($scope, $element, $attrs, ngModel) {


        },
        controller: ['$scope', function ($scope) {
            $scope.collapsibleList = {
                showMore: false
            };

            $scope.showMore = function () {
                $scope.collapsibleList.showMore = !$scope.collapsibleList.showMore;
            };
        }]
    };
}]);