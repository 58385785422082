import app from "js/legacy-app";

app.directive('nextImgButton', [function () {
    return {
        template: '<md-button type="button" class="md-raised vt-button non-border show-img-buttons" ng-click="nextImgButton()" ng-show="getHeight()" aria-label="Next">' +
        '<md-icon md-font-set="fa fa-angle-right"></md-icon>' +
        '</md-button>',
        scope: {
            nextImgButton: '='
        },
        link: function ($scope, $element, $attrs, ngModel) {
            var div = $element[0],
                btn = div.childNodes[0];

            $scope.getHeight = function() {
                var height = div.parentElement.offsetHeight - 2;
                btn.style.height = height + 'px';
                return true;
            };
        }
    };
}]);
