import app from "js/legacy-app";

app.service('$typeOfBikesService', ['TypeOfBikes', function (TypeOfBikes) {

    var types = null,
        isLoaded = function() {
            return !!types;
        },
        load = function (callBack) {
            if (!types) {
                return TypeOfBikes.query(function (data) {
                    types = [];
                    for (var i = 0; i < data.length; i++) {
                        types.push(data[i]);
                    }
                    if (callBack) {
                        callBack(types);
                    }
                }).$promise;
            } else {
                if (callBack) {
                    callBack(types);
                }
            }
        },
        clear = function () {
            types = null;
        },
        getAll = function () {
            return types;
        };

    return {
        isLoaded: isLoaded,
        load: load,
        clear: clear,
        getAll: getAll
    };
}]);