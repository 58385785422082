import app from "js/legacy-app";

app.directive('vtGoogleMap', ['$timeout', function ($timeout) {
    return {
        restrict: 'E',
        replace: true,
        template: '<div></div>',
        scope: {
            options: '=',
            markers: '=',
            route: '=',
            info: '=',
            resize: '='
        },
        link: function (scope, element, attrs) {
            var _map;
            var _markers = [];
            var _directionsService, _directionsDisplay;
            var _waypoints = [];
            var _bounds;
            var _infoWindow = null;

            function renderMap(callback) {
                var options = {};
                options.zoomControl = true;
                options.zoomControlOptions = {position: window.google.maps.ControlPosition.TOP_RIGHT};
                options.mapTypeId = window.google.maps.MapTypeId.ROADMAP;
                options.navigationControl = true;
                options.mapTypeControl = false;
                options.streetViewControl = false;
                options.fullscreenControl = true;
                options.minZoom = 3;
                options.scrollwheel = true;
                options.styles = [{"stylers": [{"saturation": -100}, {"gamma": 1}]}, {
                    "elementType": "labels.text.stroke",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "poi.business",
                    "elementType": "labels.text",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "poi.business",
                    "elementType": "labels.icon",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "poi.place_of_worship",
                    "elementType": "labels.text",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "poi.place_of_worship",
                    "elementType": "labels.icon",
                    "stylers": [{"visibility": "off"}]
                }, {
                    "featureType": "road",
                    "elementType": "geometry",
                    "stylers": [{"visibility": "simplified"}]
                }, {
                    "featureType": "water",
                    "stylers": [{"visibility": "on"}, {"saturation": 50}, {"gamma": 0}, {"hue": "#50a5d1"}]
                }, {
                    "featureType": "administrative.neighborhood",
                    "elementType": "labels.text.fill",
                    "stylers": [{"color": "#333333"}]
                }, {
                    "featureType": "road.local",
                    "elementType": "labels.text",
                    "stylers": [{"weight": 0.5}, {"color": "#333333"}]
                }, {
                    "featureType": "transit.station",
                    "elementType": "labels.icon",
                    "stylers": [{"gamma": 1}, {"saturation": 50}]
                }];

                getCurrentLocation(function (lat, lng) {
                    options.center = new window.google.maps.LatLng(lat, lng);
                    options.zoom = 10;
                    callback(new window.google.maps.Map(element[0], options));
                }, function (error) {
                    options.center = new window.google.maps.LatLng(41.742295, -73.355499);
                    options.zoom = 7;
                    callback(new window.google.maps.Map(element[0], options));
                });

            }

            function addMarker(marker) {
                return new window.google.maps.Marker({
                    position: marker.position,
                    title: marker.title ? marker.title : '',
                    animation: window.google.maps.Animation.DROP,
                    icon: marker.icon ? marker.icon : attrs.markerIcon ? attrs.markerIcon : ''
                });
            }

            function addGenericMarker(marker) {
                return new window.google.maps.Marker(marker);
            }

            function renderMarkers(markers) {
                _markers.map(function (mm) {
                    removeMarker(mm);
                });
                _markers = [];
                _bounds = new window.google.maps.LatLngBounds();
                markers.map(function (marker) {
                    var m = addMarker(marker);
                    if (marker.click && typeof marker.click === 'function')
                        addOnClickListener(m, marker.click);
                    if (marker.mouseover && typeof marker.mouseover === 'function')
                        addOnMouseoverListener(m, marker.mouseover);
                    _bounds.extend(m.position);
                    _markers.push(m);
                });
                window.setTimeout(function () {
                    _markers.map(function (m) {
                        scope.$apply(function () {
                            m.setMap(_map);
                        });
                    });
                }, 1000);
                if (_markers.length > 0) {
                    if (_markers.length === 1) {
                        _map.setCenter(_markers[0].position);
                        _map.setZoom(15);
                    }
                    else _map.fitBounds(_bounds);
                }
            }

            function removeMarker(marker) {
                marker.setMap(null);
            }

            function spliceMarker(marker) {
                var index = _markers.indexOf(marker);
                if (index >= 0) {
                    _markers[_markers.indexOf(marker)].setMap(null);
                    _markers.splice(_markers.indexOf(marker), 1);
                }
            }

            function addOnClickListener(marker, handler) {
                marker.addListener('click', handler);
            }

            function addOnMouseoverListener(marker, handler) {
                marker.addListener('mouseover', handler);
            }

            function enableRandomMarkers() {
                window.google.maps.event.addListener(_map, 'click', function (e) {
                    scope.$apply(function () {
                        var point = addMarker({
                            position: {lat: e.latLng.lat(), lng: e.latLng.lng()},
                            title: 'Clicked location'
                        });
                        point.setMap(_map);
                    });
                });
            }

            function calculateAndDisplayRoute(route) {
                if (route && route.length >= 2) {
                    _directionsService.route({
                        origin: new window.google.maps.LatLng(route[0].lat, route[0].lng),
                        destination: new window.google.maps.LatLng(route[route.length - 1].lat, route[route.length - 1].lng),
                        waypoints: _waypoints,
                        optimizeWaypoints: true,
                        travelMode: window.google.maps.TravelMode.DRIVING
                    }, function (response, status) {
                        if (status === window.google.maps.DirectionsStatus.OK) {
                            _map.setMapTypeId("roadmap");
                            _directionsDisplay.setMap(_map);
                            _directionsDisplay.setDirections(response);
                        }
                    });
                } else {
                    if (_directionsDisplay && _directionsDisplay.setMap) {
                        _directionsDisplay.setMap(null);
                    }
                }
            }

            function resizeMap(callback) {
                $timeout(function () {
                    window.google.maps.event.trigger(_map, 'resize');
                    callback();
                }, 100);
            }

            function setMapCenter(coords) {
                _map.setCenter(coords);
            }

            scope.$watchCollection('route', function (newValue, oldValue) {
                calculateAndDisplayRoute(newValue);
            });

            scope.$watchCollection('markers', function (newValue, oldValue) {
                if (_map) {
                    renderMarkers(newValue);
                }
            });

            scope.$watchCollection('resize', function (newValue, oldValue) {
                if (newValue) {
                    resizeMap(function () {
                        setMapCenter(_map.getCenter());
                    });
                }
            });

            scope.$watchCollection('info', function (newValue, oldValue) {
                if (newValue) {
                    if (_infoWindow) _infoWindow.close();
                    _infoWindow = new window.google.maps.InfoWindow(newValue);
                    if (newValue.index && _markers.length > 0) {
                        _infoWindow.open(_map, _markers[newValue.index]);
                    }
                    else if (newValue.marker) {
                        _infoWindow.open(_map, addGenericMarker(newValue.marker));
                    }
                    else {
                        _infoWindow.open(_map);
                    }
                }
            });

            renderMap(function (map) {
                _map = map;
                renderMarkers(scope.markers);
                _directionsService = new window.google.maps.DirectionsService();
                _directionsDisplay = new window.google.maps.DirectionsRenderer();
            });

            function getCurrentLocation(successCallback, errorCallback) {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        var lat = position.coords.latitude;
                        var lng = position.coords.longitude;
                        successCallback(lat, lng);
                    }, function (error) {
                        if (errorCallback) {
                            errorCallback(error);
                        }
                    });
                } else {
                    if (errorCallback) {
                        errorCallback({});
                    }
                }
            }
        }
    };
}])
;