import app from "js/legacy-app";

app.component('vtMenuButton', {
    templateUrl: "components/menu-button/menu-button.html",
    transclude: {
        menu: 'vtMbMenu'
    },
    bindings: {
        position: '@?vtMbPosition'
    },
    controller: function () {
        'ngInject';

        let originatorEv;

        this.openMenu = function ($mdMenu, ev) {
            originatorEv = ev;
            $mdMenu.open(ev);
        };

        this.$onInit = () => {
            this.position = this.position || 'default';
        };
    }
});
