export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/partner/referral-program', 'partner-referral-program')
        .when('/partner/:id/book-service', 'partner-book-service')

        .segment('partner-referral-program', {
            access: {requireLogin: true},
            module: () => {
                return import(/* webpackChunkName: "partner-referral-program-page.module" */ "./pages/referral-program");
            }
        })
        .segment('partner-book-service', {
            access: {requireLogin: false},
            module: () => {
                return import(/* webpackChunkName: "partner-referral-redirect-page.module" */ "./pages/referral-program-redirect");
            }
        })
    ;

}
