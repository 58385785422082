import app from "js/legacy-app";
import defaultUserImage from "img/user-no-avatar.png";

app.directive('blUserFilter', ['$q', 'User', function ($q, User) {
    return {
        template: '<pl-autocomplete-filter' +
        '                 af-label="Owner"' +
        '                 af-name="user"' +
        '                 af-model="bluf.filter.user">' +
        '                   <af-item-template>' +
        '                       <div class="col-xs-2 col-sm-2 padding0">' +
        '                           <img data-ng-src="{{ item.image }}" data-ng-if="item.image">' +
        '                           <img src="{{ model.defaultImage }}" data-ng-if="!item.image"/>' +
        '                       </div>' +
        '                       <div class="col-xs-10 col-sm-10 color-white padding-right-5 padding-left5" md-highlight-flags="^i" data-md-highlight-text="model.query">' +
        '                             {{model.displayItem(item)}}                                    ' +
        '                       </div>' +
        '                   </af-item-template>' +
        '           </pl-autocomplete-filter>',
        link: function ($scope) {

            $scope.bluf = {};

            $scope.bluf.filter = {
                user: {
                    defaultImage: defaultUserImage,
                    displayItem: function (item) {
                        return item.name + " " + item.surname;
                    },
                    search: function (query) {
                        var deferred = $q.defer();
                        User.findByNameLike({name: query}, function (response) {
                            deferred.resolve(response.items);
                        }, function (error) {
                            deferred.reject(error);
                        });
                        return deferred.promise;
                    }
                }
            };
        }
    };
}]);