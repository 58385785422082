import './radio-button.component.scss';

export default ['$timeout', ($timeout) => {
    return {
        restrict: 'E',
        replace: true,
        require: ['ngModel'],
        scope: {
            ngModel: "=ngModel",
            value: "@vuiValue",
            disabled: "@disabled",
            onChange: '=vuiChange',
        },
        transclude: true,
        template: require('./radio-button.component.html'),
        link: (scope, element) => {
            scope.setValue = (value) => {
                scope.ngModel = value;
                scope.onChangeValue(scope.ngModel);
            }

            scope.onChangeValue = (value) => {
                if (scope.onChange) {
                    $timeout(() => scope.onChange(value));
                }
            }
        }
    };
}]
