import angular from "angular";

import "oclazyload";
import ServiceBookingModule from "./modules/service-booking/service-booking.module";
import AppMarketplaceModule from "./modules/app-marketplace/app-marketplace.module";
import ApplicationsModule from "./modules/application/application.module";

export default angular
    .module("vt-mechanic-app", [
        ServiceBookingModule.name,
        AppMarketplaceModule.name,
        ApplicationsModule.name
    ]);
