/**
 * Created by eugineer on 2/24/17.
 */
import app from "js/legacy-app";

app.filter ('notSpecified', [ '$sce', function ($sce) {
    return function (value) {
        return $sce.trustAsHtml(value ? value.toString() : '<span class="not-specified">Not specified</span>');
     };
}]);
