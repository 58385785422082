import app from "js/legacy-app";

app.service('MechanicSchedule', ['$resource', function ($resource) {
    return $resource('/api/mechanic-schedule/:mechanicId/:path', {}, {
        getAvailability: {
            method: "GET",
            params: {path: 'availability'},
            isArray: false
        },
        loadSettings: {
            method: "GET",
            params: {mechanicId: '', path: 'settings'},
            isArray: false
        },
        loadPublicSettings: {
            method: "GET",
            params: {path: 'settings/public'},
            isArray: false
        },
        updateSettings: {
            method: "POST",
            params: {mechanicId: '', path: 'settings'},
            isArray: false
        }
    });
}]);