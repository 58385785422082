import app from "js/legacy-app";

app.directive('fab', ['$mdMedia', function ($mdMedia) {
    return {
        replace: true,
        template:
            `<div>
                <a data-ng-click="onClick($event)" fab-scroll data-ng-show="$mdMedia('max-width: 576px')" class="vt-fab md-raised md-primary md-button margin-0">{{ shortLabel }}</a>
                <a data-ng-click="onClick($event)" data-ng-show="$mdMedia('min-width: 577px')" class="md-big md-raised md-primary md-button margin-0">{{ fullLabel }}</a>
            </div>`,
        scope: {
            href: '@?fabHref',
            click: '=?fabClick',
            shortLabel: '@fabShortLabel',
            fullLabel: '@fabFullLabel'
        },
        link: function (scope, elem) {
            scope.$mdMedia = $mdMedia;
            if (scope.href) {
                applyHrefToElement();
            }

            scope.$watch('href', function () {
                applyHrefToElement();
            });

            scope.onClick = function ($event) {
                if (scope.click) {
                    scope.click($event);
                }
            };

            function applyHrefToElement() {
                elem.find('a').attr('href', scope.href);
            }
        }
    };
}]);


