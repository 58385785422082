import app from "js/legacy-app";

app.controller('ShowMessageDialogController', ['$scope', '$mdDialog', 'comment',
    function ($scope, $mdDialog, comment) {

        $scope.smdc = {
            comment: comment,

            title: 'Message',

            close: function () {
                $mdDialog.hide();
            }
        };

    }]);