import app from 'js/legacy-app';

app.controller('AddCustomerDialogCtrl', [
    '$scope',
    '$mdDialog',
    'LocationService',
    'PartnerBikeResource',
    'ErrorHandler',
    'Notification',
    'User',
    '$q',
    '$serviceCoverageChecker',
    'ModalService',
    'ToggleLD',
    function ($scope, $mdDialog, LocationService, PartnerBikeResource, ErrorHandler, Notification, User, $q, $serviceCoverageChecker, ModalService, ToggleLD) {

        let $ctrl = $scope.$ctrl;

        $ctrl.cancel = () => {
            $mdDialog.hide();
        };

        $ctrl.addCustomer = (form) => {
            if (!form.$valid) {
                return;
            }

            let user = {
                address: LocationService.mapAddress($ctrl.user.address),
                phone: $ctrl.user.phone.number,
                name: $ctrl.user.name,
                surname: $ctrl.user.surname,
                email: $ctrl.user.email,
                needDuplicateBike: $ctrl.needDuplicateBike
            };

            if (!isValidAddress()) {
                return;
            }

            function onCoveredWorkingArea() {
                setupBikeOwner().then((response) => {
                    response.address = user.address.address;
                    response.apartment = user.address.apartment;
                    $mdDialog.hide(response);
                });
            }

            function setupBikeOwner() {
                ToggleLD.show();
                return PartnerBikeResource.setBikeOwner({id: $ctrl.bikeId}, user, () => {
                        ToggleLD.hide();
                        Notification.success('Bicycle successfully has been assigned to new owner');
                    },
                    (error) => {
                        ToggleLD.hide();
                        ErrorHandler.onError(error);
                    }).$promise;
            }

            function onUncoveredWorkingArea() {
                setupBikeOwner().then(function () {
                    ModalService.showMessage(null, `At the moment we do not have our mechanic in your area. 
                    However, we are working hard to put a mechanic around every corner including yours.`);
                });
            }

            function onError(error) {
                ErrorHandler.onError(error, "Something went wrong");
            }

            $serviceCoverageChecker.checkCoverage(
                user.address,
                onCoveredWorkingArea,
                onUncoveredWorkingArea,
                onError
            );
        };

        $ctrl.searchByEmail = (email) => {
            let deferred = $q.defer();
            User.findByEmailLike({email: email}, function (response) {
                $ctrl.user.email = email;
                deferred.resolve(response.items);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        $ctrl.onChangeUser = (user) => {
            $ctrl.user = user || {};

            if (user) {
                $ctrl.user.phone = {
                    number: user.phone
                };
            }
        };

        let isValidAddress = (address) => {
            return LocationService.validateBikeAddress(address);
        };

        (function init() {
            $ctrl.needDuplicateBike = true;
        })();
    },
]);