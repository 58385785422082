export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/partner/applications', 'partner-applications')
        .when('/partner/applications/:applicationId', 'partner-applications-view')

        .segment('partner-applications', {
            access: {requireLogin: true},
            template: '<partner-applications></partner-applications>',
            loadModule: () => {
                return import(/* webpackChunkName: "partner-applications.module" */ "./application-list/application-list.module");
            }
        })
        .segment('partner-applications-view', {
            access: {requireLogin: true},
            template: '<partner-application-view></partner-application-view>',
            loadModule: () => {
                return import(/* webpackChunkName: "partner-applications-view.module" */ "./application-view/application-view.module");
            }
        });

}
