import app from "js/legacy-app";

app.controller('EditMechanicWorkAreaController',
    ['$scope', 'MechanicWorkArea', '$mdDialog', 'AuthService', 'ErrorHandler', 'ToggleLD', 'ModalService',
        '$routeParams', '$timeout', '$location', 'Notification', 'LocationService',
        function ($scope, MechanicWorkArea, $mdDialog, AuthService, ErrorHandler, ToggleLD, ModalService,
                  $routeParams, $timeout, $location, Notification, LocationService) {
            var emwac = $scope.emwac = {
                loaded: false,
                map: {},

                validAddress: function (address) {
                    var error = LocationService.validateWorkAreaAddress(address);
                    if (error) {
                        Notification.error(error);
                        return false;
                    }

                    return true;
                }
            };

            emwac.update = function () {

                if (!emwac.validAddress(emwac.location.value)) {
                    return;
                }

                ToggleLD.show();
                MechanicWorkArea.update(
                    {
                        id: emwac.workArea.id
                    },
                    {
                        location: emwac.location.value,
                        radius: emwac.radiusSlider.value
                    },
                    function () {
                        ToggleLD.hide();
                        emwac.refresh();
                        emwac.showUpdateBtn = false;
                        Notification.success("Work area is updated");
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error, "Unable to change work area");
                    });
            };

            emwac.radiusSlider = {
                options: {
                    floor: 0,
                    ceil: 50,
                    translate: function (value) {
                        return value + " mi";
                    },
                    onStart: function (sliderId, val) {
                        emwac.radiusSlider.oldValue = val;
                    },
                    onChange: function (sliderId, val) {
                        let radius = val * 1609.34;
                        if (radius === 0) {
                            radius = 25
                        }
                        emwac.circle.setRadius(radius);
                        emwac.map.setCenter(emwac.circle.getBounds());
                    },
                    onEnd: function (sliderId, val) {
                        if (val !== emwac.radiusSlider.oldValue) {
                            emwac.showUpdateBtn = true;
                        }
                    }
                }
            };

            emwac.location = {
                onChange: function () {
                    if (emwac.location.value) {
                        emwac.showUpdateBtn = true;
                        $timeout(function () {
                            emwac.circle.setCenter({
                                lat: emwac.location.value.latitude,
                                lng: emwac.location.value.longitude
                            });
                            emwac.map.setCenter(emwac.circle.getBounds());
                        });
                    } else {
                        emwac.showUpdateBtn = false;
                    }
                }
            };

            emwac.refresh = function () {
                ToggleLD.show();
                MechanicWorkArea.view({
                    id: $routeParams.id
                }, function (workArea) {
                    ToggleLD.hide();
                    emwac.loaded = true;
                    emwac.workArea = workArea;
                    emwac.radiusSlider.value = Math.round(emwac.workArea.bounds.radius / 1609.34);
                    $timeout(function () {
                        emwac.location.setUp(emwac.workArea.bounds.centralLocation, true);
                        updateMap(workArea);
                    });
                }, function (error) {
                    ToggleLD.hide();
                    emwac.loaded = true;
                    ErrorHandler.onError(error, "Unable to load work areas");
                });
            };

            emwac.toggleLocation = function (location) {
                var locationState = location.expanded;
                emwac.workArea.locations.forEach(function (l) {
                    l.expanded = false;
                });
                location.expanded = !locationState;
            };

            emwac.onRemove = function () {
                $location.path('work-area');
            };

            emwac.patchZipCode = function (zipCode) {
                MechanicWorkArea.updateZipCodeActivity({
                        id: $routeParams.id,
                        subId: zipCode.code
                    },
                    {
                        value: zipCode.active
                    },
                    function () {
                        ToggleLD.hide();
                    }, function (error) {
                        ToggleLD.hide();
                        zipCode.active = !zipCode.active;
                        ErrorHandler.onError(error, "Unable to update zip code activity");
                    });
            };

            emwac.getZipCodes = function (location, active) {
                return location.zipCodes.filter(function (z) {
                    return z.active === active;
                }).map(function (z) {
                    return z.code;
                }).join(", ");
            };

            emwac.hasInactiveZipCodes = function (location) {
                return location.zipCodes.filter(function (z) {
                    return !z.active;
                }).length > 0;
            };

            var updateMap = function (workArea) {
                emwac.map.clear();
                if (workArea) {
                    var areaCenter = workArea.bounds.center.coordinates;

                    emwac.circle = emwac.map.addCircle({
                        lat: areaCenter[1],
                        lng: areaCenter[0]
                    }, workArea.bounds.radius, {
                        fillColor: "#ffec92",
                        fillOpacity: 0.3
                    });

                    emwac.map.setCenter(emwac.circle.getBounds());
                }
            };

            emwac.refresh();


        }]);
