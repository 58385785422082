import app from "js/legacy-app";

app.service('$localStorage', function () {

    let tryToSet = (key, value) => {
        if (value === undefined) {
            return;
        }

        try {
            localStorage[key] = JSON.stringify(value);
        } catch (e) {
            throw e;
        }
    };

    let tryToRemove = (key) => {
        try {
            delete localStorage[key];
        } catch (e) {
            throw e;
        }
    };

    let set = (key, value) => {
        if (isSupported() && value !== undefined) {
            localStorage[key] = JSON.stringify(value);
        }
    };

    let get = (key) => {
        if (isSupported() && localStorage[key] !== undefined) {
            return parseValue(key);
        }

        return null;
    };

    let remove = (key) => {
        if (isExists(key)) {
            delete localStorage[key];
        }
    };

    let isSupported = () => {
        try {
            return 'localStorage' in window && window.localStorage !== null;
        } catch (e) {
            return false;
        }
    };

    let isExists = (key) => {
        if (isSupported()) {
            return !!localStorage[key];
        }

        return false;
    };

    let listeners = {};
    let listening = false;

    let listen = () => {
        if (global.addEventListener) {
            global.addEventListener('storage', change, false);
        } else if (global.attachEvent) {
            global.attachEvent('onstorage', change);
        } else {
            global.onstorage = change;
        }
    };

    function change(event) {
        if (!event) {
            event = global.event;
        }

        let listener = listeners[event.key];
        if (listener) {
            fire(listener);
        }

        function fire(listener) {
            listener(parseValue(event.newValue), parseValue(event.oldValue), event.url || event.uri);
        }
    }

    function on(key, fn) {
        listeners[key] = fn;

        if (listening === false) {
            listen();
        }
    }

    function off(key) {
        delete listeners[key];
    }

    function parseValue(key) {
        try {
            return JSON.parse(localStorage[key]);
        } catch (e) {
            return localStorage[key];
        }
    }

    return {
        set: set,
        get: get,
        remove: remove,
        tryToSet: tryToSet,
        tryToRemove: tryToRemove,
        isExists: isExists,
        on: on,
        off: off
    };
});
