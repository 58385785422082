import app from "js/legacy-app";

app.directive('vtComponentElement', [function () {
    return {
        templateUrl: "template/dashboard/bikes/component-element.html",
        scope: {
            bike: '=',
            component: '=',
            isCanEdit: '&'
        },
        controller: ['$scope', '$bikeComponentService', 'SaleCertificateService', 'ModalService', 'AuthService', 'Brand',
            function ($scope, $bikeComponentService, SaleCertificateService, ModalService, AuthService, Brand) {

                var certifiedRate = $scope.component.condition.rate;
                var componentName = $scope.component.name;
                var componentBrand = $scope.component.brand;
                var componentModel = $scope.component.model;

                $scope.ce = {};

                $scope.toggleComponent = function (e) {
                    var opened = document.getElementsByClassName('open-details')[0];
                    var target = e.currentTarget.parentElement.getElementsByClassName('vt-list-details-container')[0];

                    if (target.classList.contains('open-details')) {
                        return (closeDetails(target));
                    }

                    if (opened) {
                        closeDetails(opened);
                    }

                    openDetails(target);
                };

                $scope.removeComponent = function () {
                    ModalService.consultationMessage(null, 'Would you like to remove the component?', function (response) {
                        if (response === true) {
                            $bikeComponentService.removeComponent($scope.bike, $scope.component.id);
                        }
                    });
                };

                $scope.isComponentCertified = function () {
                    if ($scope.component.certified) {
                        return ['certified-component', 'fa-check'];
                    } else {
                        return ['non-certified-component', 'fa-close'];
                    }
                };

                $scope.isConfirmed = function () {
                    return $scope.component.certified;
                };

                $scope.certifyComponent = function () {
                    $scope.component.certified = true;
                };

                $scope.uncertifyComponent = function () {
                    $scope.component.certified = false;
                };

                $scope.ce.getBrands = function (brand) {
                    return Brand.autocomplete({name: brand, page: 0, size: 10}).$promise;
                };

                $scope.getCertifiedIcon = function () {
                    var state = SaleCertificateService.getComponentCertificationState($scope.component);
                    switch (state) {
                        case 1:
                            return 'vt-certified-new';
                        case 2:
                            return 'vt-certified-ok';
                        case 3:
                            return 'vt-certified-bad';
                    }
                };

                $scope.getSelector = function () {
                    return 'selector-' + $scope.component.id;
                };

                $scope.isMechanic = function () {
                    return AuthService.isVerifiedMechanic();
                };

                $scope.isCanEditComponent = function () {
                    return !!$scope.isCanEdit();
                };

                $scope.onChangeCertifiedModel = function (type) {
                    if ($scope.component.certified === true && !$scope.isMechanic()) {
                        var selected;

                        switch (type) {
                            case 'name':
                                selected = $scope.component.name;
                                $scope.component.name = componentName;
                                break;
                            case 'brand':
                                selected = $scope.component.brand;
                                $scope.component.brand = componentBrand;
                                break;
                            case 'model':
                                selected = $scope.component.model;
                                $scope.component.model = componentModel;
                                break;
                            case 'condition':
                                selected = $scope.component.condition.rate;
                                $scope.component.condition.rate = certifiedRate;
                                break;
                        }

                        ModalService.consultationMessage(null, 'Would you like to change the ' + type + '? \nNote: if you change it the ' + ($scope.component.name || "component") + ' will lost status "certified"', function (response) {
                            if (response === true) {

                                switch (type) {
                                    case 'name':
                                        $scope.component.name = selected;
                                        break;
                                    case 'brand':
                                        $scope.component.brand = selected;
                                        break;
                                    case 'model':
                                        $scope.component.model = selected;
                                        break;
                                    case 'condition':
                                        $scope.component.condition.rate = selected;
                                        break;
                                }

                                $scope.component.certified = false;

                            }
                        });
                    }
                };

                function closeDetails(elem) {
                    elem.classList.remove('open-details');
                    var icon = getIcon(elem);
                    icon.classList.remove('fa-minus-circle');
                    icon.classList.add('fa-plus-circle');
                }

                function openDetails(elem) {
                    elem.classList.add('open-details');
                    var icon = getIcon(elem);
                    icon.classList.add('fa-minus-circle');
                    icon.classList.remove('fa-plus-circle');
                }

                function getIcon(elem) {
                    return elem.parentElement.getElementsByClassName('vt-list-comp-toggle')[0];
                }
            }]
    };
}]);
