import app from "js/legacy-app";
import angular from "angular";

app.directive('negotiation', ['AuthService', 'ModalService', function (AuthService, ModalService) {
    return {
        templateUrl: "components/request/view/tabs/negotiation/negotiation.html",
        scope: {
            negotiation: '=negotiation',
            setRequest: '=setRequest',
            request: '=request',
            alwaysShowChat: '=alwaysShowChat'
        },

        controller: [
            '$scope',
            '$timeout',
            'ToggleLD',
            'Notification',
            '$location',
            '$temporaryStorage',
            '$filter',
            'EMOJI_HEX',
            'Documents',
            '$routeParams',
            'ErrorHandler',
            '$requestService',
            '$q',
            'ChatWSService',
            'NotificationWSService',
            function (
                $scope, $timeout, ToggleLD, Notification, $location, $temporaryStorage, $filter, EMOJI_HEX,
                Documents, $routeParams, ErrorHandler, $requestService, $q, ChatWSService, NotificationWSService
            ) {

                let nCtrl = {
                    firstCommentsIsLoaded: false,
                    isLoading: false,
                    chatHistoryDeferred: [],
                    sockets: {}
                };

                $scope.nCtrl = {
                    requestResource: $requestService.getRequestResource(AuthService),
                    currentUser: AuthService.user(),
                    hideChatPanel: $scope.negotiation.permissions.indexOf('SEND_CHAT_MESSAGE'),
                    smiles: [],
                    smilesContainerIsOpened: false,
                    chatInput: {},
                    connected: false
                };

                $scope.nPage = {};

                $scope.nDialog = {
                    currentUserId: $scope.nCtrl.currentUser.id,
                    requestId: $scope.request.id,
                    negotiationId: $scope.negotiation.id,
                    recipientUserId: getRecipientId(),
                    comments: []
                };

                $scope.nCtrl.scrollToDown = function () {
                    let chatContainer = document.getElementsByClassName('chat__history')[0];
                    chatContainer.scrollTop = chatContainer.scrollHeight;
                };

                $scope.nCtrl.assessmentOfUser = function () {
                    if ($scope.request.mechanicRatee) {
                        return;
                    }


                    ModalService.rateTheUser(function (data) {
                        if (!data) return;

                        nCtrl.setRating(data);
                    });
                };

                $scope.nCtrl.assessmentOfMechanic = function () {
                    if (!$scope.request.userRatee) {
                        ModalService.rateTheMechanic($scope.negotiation, function (data) {
                            if (!data) return;

                            if (data && data.status === "UNSATISFIED") {
                                ModalService.consultationMessage(null, 'By choosing the "I did not like it" option' +
                                    'you are claiming that <br>the service the mechanic provided did not meet your expectations. <br>' +
                                    'We want you to be happy with any bicycle service provided by our mechanics.<br><br>' +
                                    ' Are you sure you want to choose "I did not like it" option?', function (response) {
                                    if (response === true) {
                                        ToggleLD.show();
                                        delete data.status;
                                        nCtrl.notSatisfiedWithService(data);
                                    }
                                });
                            } else {
                                nCtrl.setRating(data);
                            }
                        });
                    }
                };

                $scope.nCtrl.getNegotiationStatus = function () {
                    if ($scope.negotiation && $scope.negotiation.status) {
                        return $scope.negotiation.status.toLowerCase().replace(/_/g, '-');
                    }
                };

                $scope.nCtrl.refund = function () {
                    ToggleLD.show();
                    $scope.nCtrl.requestResource.refund({id: $scope.request.id}, {}, function (response) {
                        ToggleLD.hide();
                        Notification.success("Refund was successfully done");
                        $scope.setRequest(response);
                    }, function (error) {
                        nCtrl.refreshRequest(error);
                    });
                };

                $scope.nCtrl.transfer = function () {
                    ToggleLD.show();
                    $scope.nCtrl.requestResource.transfer({id: $scope.request.id}, {}, function (response) {
                        ToggleLD.hide();
                        Notification.success("Transfer was successfully done");
                        $scope.setRequest(response);
                    }, function (error) {
                        nCtrl.refreshRequest(error);
                    });
                };

                $scope.nCtrl.loadMoreMessages = function () {
                    let deferred = $q.defer();

                    if (!nCtrl.isLoading && $scope.nCtrl.connected) {
                        nCtrl.isLoading = true;
                        $timeout(function () {
                            nCtrl.getChatHistory(deferred);
                        }, 50);
                    } else {
                        deferred.reject();
                        nCtrl.isLoading = false;
                    }
                    return deferred.promise;
                };

                $scope.nCtrl.canSetRatingAsBikeOwner = function () {
                    if ($scope.request.bikeOwnerIdOfRequest) {
                        return $scope.nCtrl.currentUser.id === $scope.request.bikeOwnerIdOfRequest;
                    }
                    return $scope.nCtrl.currentUser.id === $scope.request.userId;
                };

                $scope.nCtrl.toggleSmiles = function () {
                    $timeout(function () {
                        $scope.nCtrl.smilesContainerIsOpened = !$scope.nCtrl.smilesContainerIsOpened;
                    }, 30);
                };

                $scope.nCtrl.showUser = function () {
                    $temporaryStorage.put('requestId', $scope.request.id);
                    $location.path('/user/' + $scope.nCtrl.negotiationUser.id).search({requestId: $scope.request.id});
                };

                $scope.nCtrl.showMechanic = function () {
                    $temporaryStorage.put('requestId', $scope.request.id);
                    let user = $scope.negotiation.user;
                    let userId = user.nickname ? user.nickname : user.id;
                    $location.url('/mechanic-public/' + userId).search({requestId: $scope.request.id});
                };

                $scope.nCtrl.uploadImages = function ($event) {
                    ModalService.uploadChatImages($event, Documents.chat(), function (response) {
                        if (!response) {
                            return;
                        }

                        let images = response.images;
                        if (!images) {
                            return;
                        }

                        let newImages = [];
                        angular.forEach(images, function (image) {
                            newImages.push({id: image.id});
                        });
                        $scope.nCtrl.addComment(response.comment, newImages);
                    });
                };

                $scope.nCtrl.viewChatImage = function ($event, index, message) {
                    var images = function () {
                        return message.images;
                    };
                    ModalService.showPicture($event, images, index, null, null);
                };

                $scope.nCtrl.addSmile = function (cssClass) {
                    $scope.nCtrl.chatInput.addSmile(cssClass);
                };

                $scope.nCtrl.showChat = function () {
                    $timeout($scope.nCtrl.scrollToDown);
                    $scope.nCtrl.hideChatPanel = !$scope.nCtrl.hideChatPanel;
                };

                $scope.nCtrl.addComment = function (text, images) {
                    angular.element(document).ready(function () {
                        let msg = text || $scope.nCtrl.chatInput.getText();
                        if (!msg && !images) {
                            return;
                        }

                        let comment = {
                            text: $filter("textWithoutEmijiImg")(msg)
                        };

                        comment = {
                            text: $filter("xssFilter")(comment.text),
                            operation: 1,
                            token: AuthService.getToken().access_token,
                            recipientId: $scope.nDialog.recipientUserId,
                            requestId: $scope.nDialog.requestId,
                            negotiationId: $scope.nDialog.negotiationId,
                            updatedAt: new Date().getTime()
                        };

                        if (images) {
                            comment.images = images;
                        }

                        nCtrl.chatService.send(comment);
                        $scope.nCtrl.smilesContainerIsOpened = false;
                        $scope.nCtrl.chatInput.clearChatInput();
                    });
                };

                $scope.nCtrl.chatInput = {
                    addComment: $scope.nCtrl.addComment
                };

                nCtrl.setRating = function (data) {
                    ToggleLD.show();
                    $scope.nCtrl.requestResource.setRating({id: $scope.request.id, id2: $scope.negotiation.id}, data,
                        function (response) {
                            $scope.setRequest(response);
                            ToggleLD.hide();
                        }, function (error) {
                            nCtrl.refreshRequest(error);
                        });
                };

                nCtrl.notSatisfiedWithService = function (data) {
                    $scope.nCtrl.requestResource.notSatisfied({id: $scope.request.id, id2: $scope.negotiation.id}, data,
                        function (response) {
                            $scope.setRequest(response);
                            ToggleLD.hide();
                        }, function (error) {
                            nCtrl.refreshRequest(error);
                        });
                };

                nCtrl.handleMessage = function (comment, last) {
                    if (!hasMessage($scope.nDialog.comments, comment)) {
                        if (last === true) {
                            $scope.nDialog.comments.push(comment);
                        } else {
                            $scope.nDialog.comments.unshift(comment);
                        }
                    }
                };

                function hasMessage(comments, newMessage) {
                    for (let i = 0; i < comments.length; i++) {
                        if (comments[i].id === newMessage.id) {
                            return true;
                        }
                    }

                    return false;
                }

                function getRecipientId() {
                    return $scope.negotiation.user.id !== $scope.nCtrl.currentUser.id ?
                        $scope.negotiation.user.id : $scope.request.userId;
                }

                nCtrl.getChatHistory = function (defer) {
                    let now = new Date().getTime();
                    nCtrl.chatHistoryDeferred[now] = defer;
                    nCtrl.chatService.send({
                        operation: 3,
                        updatedAt: now,
                        requestId: $scope.request.id,
                        negotiationId: $scope.negotiation.id,
                        token: AuthService.getToken().access_token,
                        lastCommentId: $scope.nDialog.comments[0] ? $scope.nDialog.comments[0].id : null,
                        page: 0,
                        size: 10
                    });
                };

                nCtrl.readLastMessage = function () {
                    nCtrl.chatService.send({
                        operation: 6,
                        requestId: $scope.request.id,
                        negotiationId: $scope.negotiation.id,
                        token: AuthService.getToken().access_token
                    });
                };

                nCtrl.refreshRequest = function (error) {
                    $scope.nCtrl.requestResource.get({id: $scope.request.id},
                        function (data) {
                            nCtrl.onSuccess(data, error);
                        }, function (err) {
                            nCtrl.onError(err, error);
                        });
                };

                nCtrl.onSuccess = function (data, error) {
                    $scope.setRequest(data);
                    ToggleLD.hide();
                    if (error) {
                        ErrorHandler.onErrorDialog(error);
                    }
                };

                nCtrl.onError = function (err, error) {
                    ToggleLD.hide();
                    if (err.status) {
                        ErrorHandler.onErrorDialog(error).then(function () {
                            $location.path("/dashboard");
                        });
                    }
                };

                nCtrl.sendComment = function (comment) {
                    if ($scope.nDialog.negotiationId === comment.data.negotiationId) {
                        $scope.$apply(function () {
                            nCtrl.handleMessage(comment.data, true);
                            nCtrl.readLastMessage();
                            $timeout($scope.nCtrl.scrollToDown);
                        });
                    }
                };

                nCtrl.sendComments = function (comment) {
                    $scope.$apply(function () {
                        comment.data.data.sort((m1, m2) => m2.createdAt - m1.createdAt);
                        $scope.nPage = comment.data
                        for (let i = 0; i < $scope.nPage.data.length; i++) {
                            if ($scope.nDialog.negotiationId === comment.data.data[i].negotiationId) {
                                nCtrl.handleMessage(comment.data.data[i], false);
                            }
                        }
                        nCtrl.readLastMessage();

                        if (nCtrl.firstCommentsIsLoaded === false) {
                            nCtrl.firstCommentsIsLoaded = true;
                            $timeout($scope.nCtrl.scrollToDown);
                        }

                        socketPromise(comment);
                    });
                };

                nCtrl.changeInetConnectionStatus = function (connected) {
                    $scope.nCtrl.connected = connected;
                };

                nCtrl.connectionStatus = function (status) {
                    $scope.nCtrl.negotiationUser.online = status.data;
                };

                function socketPromise(request) {
                    if (angular.isDefined(nCtrl.chatHistoryDeferred[request.socketRequestId])) {
                        let callback = nCtrl.chatHistoryDeferred[request.socketRequestId];
                        delete nCtrl.chatHistoryDeferred[request.socketRequestId];
                        nCtrl.isLoading = false;
                        callback.resolve();
                    }
                }

                nCtrl.readNewNotification = function (message) {
                    if (!message.data || !message.data.metadata || !message.data.metadata.requestId || !message.data.metadata.negotiationId || !$routeParams.negotiation) {
                        return;
                    }

                    if ($scope.nDialog.negotiationId === message.data.metadata.negotiationId &&
                        $scope.nDialog.requestId === message.data.metadata.requestId && !message.data.read) {
                        NotificationWSService.readNotification(message.data.id, AuthService.getToken().access_token);
                    }
                };

                nCtrl.onRegisterWs = function () {
                    if ($scope.nDialog.comments.length === 0) {
                        $scope.nCtrl.loadMoreMessages();
                    }
                };

                (function init() {
                    if (AuthService.isMechanic()) {
                        $scope.nCtrl.negotiationUser = $scope.request.user;
                        $scope.nCtrl.negotiationUser.defaultImage = require("img/user-no-avatar.png");
                    } else {
                        $scope.nCtrl.negotiationUser = $scope.negotiation.user;
                        $scope.nCtrl.negotiationUser.defaultImage = require("img/mechanic-no-avatar.png");
                    }

                    nCtrl.chatService = ChatWSService.newInstance({
                        operation: 0,
                        token: AuthService.getToken(),
                        negotiationId: $scope.nDialog.negotiationId,
                        requestId: $scope.nDialog.requestId,
                        recipientId: $scope.nCtrl.negotiationUser.id
                    }, $scope.nCtrl.negotiationUser.id, $scope.nDialog.negotiationId, AuthService.getToken());

                    nCtrl.chatService.registerHandler('REGISTRATION', nCtrl.onRegisterWs);
                    nCtrl.chatService.registerHandler('NEW', nCtrl.sendComment);
                    nCtrl.chatService.registerHandler('LIST', nCtrl.sendComments);
                    nCtrl.chatService.registerHandler('CONNECTION_STATUS', nCtrl.connectionStatus);
                    nCtrl.chatService.registerInternetConnectionHandler(nCtrl.changeInetConnectionStatus);
                    NotificationWSService.registerHandler("NEW", "nMsg" + $scope.nDialog.negotiationId, nCtrl.readNewNotification);

                    for (let i = 0; EMOJI_HEX.length > i; i++) {
                        $scope.nCtrl.smiles.push('smile-' + EMOJI_HEX[i]);
                    }

                    $scope.$on("$destroy", function () {
                        nCtrl.chatService.close(AuthService.getToken());
                        NotificationWSService.unregisterHandler("NEW", "nMsg" + $scope.nDialog.negotiationId);
                        nCtrl.chatService = {};
                    });
                })();
            }
        ]
    };
}]);
