import app from "js/legacy-app";

app.service('$sessionStorage', function () {

    let tryToSet = (key, value) => {
        if (value === undefined) {
            return;
        }

        try {
            sessionStorage[key] = JSON.stringify(value);
        } catch (e) {
            throw e;
        }
    };

    let tryToRemove = (key) => {
        try {
            delete sessionStorage[key];
        } catch (e) {
            throw e;
        }
    };

    let set = (key, value) => {
        if (isSupported() && value !== undefined) {
            sessionStorage[key] = JSON.stringify(value);
        }
    };

    let get = (key) => {
        if (isSupported() && sessionStorage[key] !== undefined) {
            return parseValue(key);
        }

        return null;
    };

    let remove = (key) => {
        if (isExists(key)) {
            delete sessionStorage[key];
        }
    };

    let isSupported = () => {
        try {
            return 'sessionStorage' in window && window.sessionStorage !== null;
        } catch (e) {
            return false;
        }
    };

    let isExists = (key) => {
        if (isSupported()) {
            return !!sessionStorage[key];
        }

        return false;
    };

    function parseValue(key) {
        try {
            return JSON.parse(sessionStorage[key]);
        } catch (e) {
            return sessionStorage[key];
        }
    }

    return {
        set: set,
        get: get,
        remove: remove,
        tryToSet: tryToSet,
        tryToRemove: tryToRemove,
        isExists: isExists
    };
});
