import app from "js/legacy-app";

app.directive('dashboardAdmin', [function () {
    return {
        templateUrl: "template/dashboard/admin/dashboard-admin.html",
        link: function ($scope, $element, $attrs, ngModel) {},
        controller: ['$scope', '$location', function ($scope, $location) {
            $location.search('admin');
        }]
    };
}]);
