import app from "js/legacy-app";
import angular from "angular";

app.directive('plOnFinishRender', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        require: '^^plItems',
        priority: 100,
        link: function (scope, element, attr, plItems) {
            $timeout(function () {
                if (scope.$last) {
                    onFinishRendering(1);
                }
            });

            function onFinishRendering(count) {
                if (!plItems.pl.smoothRendering ||
                    element[0].querySelector('[last-rendered-element]') ||
                    count >= (plItems.pl.smoothRenderingMaxIterations || 10)) {

                    plItems.pl.onFinishRenderCallbacks.forEach(function (fn) {
                        fn();
                    });

                    var container = angular.element(plItems.el[0].querySelector('.pageable-list__items'));
                    container.children().removeClass('pageable-list__item_rendering');
                    plItems.pl.isRendering = false;
                } else {
                    $timeout(function () {
                        onFinishRendering(++count);
                    });
                }
            }
        }
    };
}]);