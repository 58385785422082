import app from "js/legacy-app";
import angular from "angular";

app.directive('plTableCell', [function () {
    return {
        templateUrl: "template/component/widget/pageable-list/pl-table-cell.html",
        replace: true,
        transclude: true,
        scope: {
            sizes: '@tcSizes',
            header: '@tcHeader'
        },
        link: function ($scope, element, $attrs) {
            let parent = $scope.$parent,
                el = angular.element(element);

            $scope.pltc = {};
        }
    };
}]);