import app from "js/legacy-app";

app.directive('totalCount', function ($localStorage) {
    'ngInject';

    return {
        template: '<div class="vt-total-count">' +
            '   <div class="vt-total-count__internal vt-shadow" data-ng-class="{\'vt-total-count_highlight\': highlight}">' +
            '      <div class="vt-total-count__header">Total {{ entityName }}</div>' +
            '       <div class="vt-total-count__body">' +
            '           <span>{{ totalCount | number }}</span>' +
            '       </div>' +
            '       <div ng-if="includeChart" class="vt-total-count__chart">' +
            '           <canvas height="100" class="chart chart-line" chart-data="data" chart-labels="labels" chart-options="options" ' +
            '              chart-options="options" chart-dataset-override="datasetOverride"></canvas>' +
            '       </div>' +
            '       <div class="vt-total-count__footer">' +
            '           <span ng-show="plusCount" class="vt-total-count__plus">+{{ plusCount | number }}</span>' +
            '           <span ng-show="minusCount" class="vt-total-count__minus">-{{ minusCount | number }}</span>' +
            '       </div>' +
            '   </div>' +
            '</div>',
        replace: true,
        scope: {
            entityName: '@tcEntityName',
            calculateFunction: '=tcCalculateFn',
            refreshInterval: '@?tcRefreshIntervalSec',
            includeChart: '@?tcIncludeChart'
        },
        controller: function ($scope, $interval, $timeout) {
            'ngInject';

            $scope.labels = [];
            $scope.data = [[]];

            if ($scope.includeChart) {
                $scope.datasetOverride = [{yAxisID: 'y-axis-total-count'}];
                $scope.options = {
                    maintainAspectRatio: false,
                    responsive: true,
                    elements: {
                        point: {
                            radius: 0
                        }
                    },
                    animation: {
                        easing: 'linear'
                    },
                    scales: {
                        yAxes: [
                            {
                                fill: false,
                                id: 'y-axis-total-count',
                                type: 'linear',
                                display: true,
                                position: 'left',
                                ticks: {
                                    beginAtZero: true
                                },
                                scaleLabel: {
                                    display: false
                                }
                            }
                        ]
                    }
                };
                $scope.series = ['Total count'];
            }

            $scope.totalCount = 0;
            $scope.plusCount = 0;
            $scope.minusCount = 0;
            try {
                $scope.refreshInterval = $scope.refreshInterval ? parseInt($scope.refreshInterval) + 1000 : 60000;
            } catch (e) {
                $scope.refreshInterval = 60000;
            }

            function calculate(init) {
                $scope.calculateFunction().then(function (data) {
                    if (!init) {
                        var sub = data.count - $scope.totalCount;
                        if (sub > 0) {
                            $scope.plusCount += sub;
                        } else {
                            $scope.minusCount -= sub;
                        }
                        if ($scope.totalCount !== data.count) {
                            $scope.highlight = true;
                            $timeout(function () {
                                $scope.highlight = false;
                            }, 1000);
                        }
                        $scope.data[0].shift();
                        $scope.data[0].push(data.count);

                        $localStorage.set(getStorageKey(), $scope.data[0]);
                    }

                    if (init) {
                        $scope.data[0] = populateArray(20, $localStorage.get(getStorageKey()), data.count);
                        $scope.labels = generateArray($scope.data[0].length, "");
                        $scope.data[0].shift();
                        $scope.data[0].push(data.count);
                    }

                    $scope.totalCount = data.count;
                });
            }

            function getStorageKey() {
                return 'total-count-' + $scope.entityName;
            }

            function generateArray(count, value) {
                var result = [];
                for (var i = 0; i < count; i++) {
                    result.push(value);
                }
                return result;
            }

            function populateArray(count, array, defaultValue) {
                var newArray = array;
                if (!array || array.length === 0) {
                    newArray = [defaultValue];
                }
                var sub = count - newArray.length;
                if (sub > 0) {
                    var firstElement = newArray[0];
                    for (var i = 0; i < sub; i++) {
                        newArray.unshift(firstElement);
                    }
                }
                return newArray;
            }

            calculate(true);

            $interval(function () {
                calculate();
            }, $scope.refreshInterval);

        }
    };
});
