import app from "js/legacy-app";

app.directive('showCards', [function () {
    return {
        templateUrl: "template/settings/tabs/payment/show-card.html",
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: function ($scope, AuthService, User, ModalService, Notification, PaymentSources, ErrorHandler, ToggleLD) {
            'ngInject';
            'use strict';

            $scope.sc = {
                brands: {
                    'visa': {icon: require('img/payment/visa.png'), label: 'Visa'},
                    'maestro': {icon: require('img/payment/maestro.png'), label: 'Maestro'},
                    'visaelectron': {icon: require('img/payment/visa-electron.png'), label: 'Visa Electron'},
                    'visa electron': {icon: require('img/payment/visa-electron.png'), label: 'Visa Electron'},
                    'visamaster': {icon: require('img/payment/visa-electron.png'), label: 'Visa Master'},
                    'mastercard': {icon: require('img/payment/master-card.png'), label: 'MasterCard'},
                    'amex': {icon: require('img/payment/amex.png'), label: 'Amex'},
                    'americanexpress': {icon: require('img/payment/amex.png'), label: 'Amex'},
                    'american express': {icon: require('img/payment/amex.png'), label: 'Amex'},
                    'bcglobal': {icon: require('img/payment/amex.png'), label: 'BCGlobal'},
                    'discover': {icon: require('img/payment/discover.png'), label: 'Discover'},
                    'dinersclub': {icon: require('img/payment/dinners-club.png'), label: 'DinersClub'},
                    'diners club': {icon: require('img/payment/dinners-club.png'), label: 'DinersClub'},
                    'jcb': {icon: require('img/payment/JCB.png'), label: 'JCB'},
                    'carteblanche': {icon: require('img/payment/unknown.png'), label: 'CarteBlanche'},
                    'instapayment': {icon: require('img/payment/unknown.png'), label: 'InstaPayment'},
                    'koreanlocal': {icon: require('img/payment/unknown.png'), label: 'KoreanLocal'},
                    'laser': {icon: require('img/payment/unknown.png'), label: 'Laser'},
                    'solo': {icon: require('img/payment/unknown.png'), label: 'Solo'},
                    'switch': {icon: require('img/payment/unknown.png'), label: 'Switch'},
                    'unionpay': {icon: require('img/payment/unknown.png'), label: 'UnionPay'},
                    'velotooler': {icon: require('img/payment/vt-card.png'), label: 'Velotooler Balance'},
                    'unknown': {icon: require('img/payment/unknown.png'), label: 'Unknown Brand'}
                },
                sources: []
            };

            $scope.sc.refresh = function () {
                ToggleLD.show();
                PaymentSources.list(function (response) {
                    ToggleLD.hide();
                    $scope.sc.sources = response;
                    for (let i = 0; i < $scope.sc.sources.length; i++) {
                        if ($scope.sc.sources[i].brand) {
                            $scope.sc.sources[i].icon = ($scope.sc.brands[$scope.sc.sources[i].brand.toLowerCase()]
                                || $scope.sc.brands['unknown']).icon;
                            $scope.sc.sources[i].label = ($scope.sc.brands[$scope.sc.sources[i].brand.toLowerCase()]
                                || $scope.sc.brands['unknown']).label;
                        }
                    }
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error, "Unable to load payment sources");
                });
            };

            $scope.sc.makeSourceDefault = function ($event, sourceId) {
                var message = 'Are you sure you want to make default this card?';
                ModalService.yesNoMessage($event, '', message, function (response) {
                    if (response === true) {
                        ToggleLD.show();
                        PaymentSources.makeSourceDefault({id: sourceId}, {}, function () {
                            ToggleLD.hide();
                            $scope.sc.refresh();
                            Notification.success('Default payment source was successfully changed');
                        }, function (error) {
                            ToggleLD.hide();
                            ErrorHandler.onError(error);
                        });
                    }
                });
            };

            $scope.sc.deleteSource = function ($event, sourceId) {
                var message = 'Are you sure you want to remove this card?';
                ModalService.yesNoMessage($event, '', message, function (response) {
                    if (response === true) {
                        ToggleLD.show();
                        PaymentSources.delete({id: sourceId}, function () {
                            ToggleLD.hide();
                            $scope.sc.refresh();
                            Notification.success('Payment source was successfully removed');
                        }, function (error) {
                            ToggleLD.hide();
                            ErrorHandler.onError(error);
                        });
                    }
                });
            };

            $scope.sc.hasAdminToken = AuthService.hasAdminToken()

            $scope.sc.markSourceAsVelotooler = function (model, state) {
                ToggleLD.show();
                PaymentSources.markAsVelotooler({id: model}, {value: state}, function () {
                    ToggleLD.hide();
                    $scope.sc.refresh();
                    if (state) {
                        Notification.success('Payment source was marked as Velotooler');
                    } else {
                        Notification.success('Velotooler was unmarked as the payment source');
                    }
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error);
                });
            }

            $scope.sc.refresh();
        }
    };
}]);
