import app from "js/legacy-app";

app.directive('bikeList', ['$bikeService', function ($bikeService) {
    return {
        templateUrl: "components/bike/list/base/bike-list.html",
        replace: true,
        transclude: true,
        controller: ['$scope', function ($scope) {
            var resource = $bikeService.getResourceClass();
            $scope.bl = {
                search: function (query) {
                    return resource.list(query, {}).$promise;
                }
            };

            $scope.bl.getViewBikeUrl = function (bike) {
                return "/bike/" + bike.id;
            };

            this.bl = $scope.bl;
        }]
    };
}]);