import app from "js/legacy-app";

app.service("StringUtils", [function () {
    var isBlank = function (str) {
        return (!str || /^\s*$/.test(str));
    };

    var isEmpty = function (str) {
        return (!str || 0 === str.length);
    };

    return {
        isBlank: isBlank,
        isEmpty: isEmpty
    };
}]);
