import "./landing-page-section-content.component.scss";

export default {
    template: require('./landing-page-section-content.component.html'),
    transclude: true,
    bindings: {
        backgroundClass: "@?backgroundClass"
    },
    controller: function () {
        'ngInject';
    }
};
