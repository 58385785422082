import app from "js/legacy-app";
import angular from "angular";

app.directive('availabilityStep', function () {
    'ngInject';
    return {
        templateUrl: "components/request/create/steps/availability-step.html",
        controller: function ($scope, $requestService, $dateTimeService, $routeParams, AuthService, $location,
                              Notification, MechanicSchedule, ErrorHandler, $availabilityDates, $q, $timeout) {

            'ngInject';
            $scope.availabilityStep = {
                dateController: {},
                options: {
                    id: "availability",
                    position: 3,
                    title: "Select date & time",
                    back: function () {
                        $scope.wizard.back();
                    },
                    next: function () {
                        var selectedTime = $scope.availabilityStep.selectedTime;
                        if (!selectedTime) {
                            Notification.error('Please select date');
                            return;
                        }

                        if (AuthService.isFullyLoggedIn()) {
                            $scope.bcrc.checkUserActivation(function () {
                                if (!$scope.bcrc.serviceLocation.address) {
                                    $scope.wizard.goTo('location');
                                    return;
                                }

                                if (!$scope.bcrc.services.selected) {
                                    $scope.wizard.goTo('task');
                                    return;
                                }

                                if (!$scope.bcrc.user.activated) {
                                    $scope.bcrc.saveDraftRequestToLocalStorage();
                                    $scope.bcrc.goToActivationPage();
                                    return;
                                }

                                $scope.wizard.next();
                            });
                            return;
                        }

                        if (!AuthService.isFullyLoggedIn()) {
                            $scope.bcrc.saveDraftRequestToLocalStorage();
                            $scope.bcrc.goToRegistrationPage();
                        }
                    },
                    onEnter: function () {
                        return updateMechanicAvailability();
                    }
                },

                selected: function () {
                    return $scope.availabilityStep.selectedTime;
                }
            };

            let canLoadAvailability = function () {
                return $scope.bcrc.request.mechanicId &&
                    $scope.bcrc.serviceLocation &&
                    $scope.bcrc.services.selected;
            };


            let needToLoadAvailability = function () {

                if (!$scope.availabilityStep.availability || !$scope.availabilityStep.dates) {
                    return true;
                }

                return $scope.bcrc.request.mechanicId !== $scope.availabilityStep.availability.mechanicId ||
                    $scope.bcrc.services.selected.timeToComplete !== $scope.availabilityStep.availability.duration ||
                    $scope.bcrc.serviceLocation.latitude !== $scope.availabilityStep.availability.lat ||
                    $scope.bcrc.serviceLocation.longitude !== $scope.availabilityStep.availability.lng;
            };

            let needToCleanupAvailability = function () {
                return !canLoadAvailability();
            };

            function loadMechanicAvailability() {
                let deferred = $q.defer();

                let query = {
                    mechanicId: $scope.bcrc.request.mechanicId,
                    lat: $scope.bcrc.serviceLocation.latitude,
                    lng: $scope.bcrc.serviceLocation.longitude,
                    duration: $scope.bcrc.services.selected.timeToComplete,
                    detalization: 'L'
                };

                MechanicSchedule.getAvailability(query, function (response) {
                    $scope.availabilityStep.availability = angular.extend({}, query);
                    $scope.availabilityStep.availability.dates = response.dates;

                    $scope.availabilityStep.dates = $availabilityDates.generate(
                        $scope.availabilityStep.availability,
                        response.weeklyAvailability,
                        response.vacationPeriod
                    );

                    $timeout(function () {
                        deferred.resolve($scope.availabilityStep.dates);
                    });
                }, function (error) {
                    ErrorHandler.onError(error);
                    deferred.reject(error);
                });

                return deferred.promise;
            }

            function cleanupMechanicAvailability() {
                $scope.availabilityStep.availability = null;
                $scope.availabilityStep.dates = null;
            }

            let updateMechanicAvailability = function () {
                if (needToCleanupAvailability() || needToLoadAvailability()) {

                    $scope.wizard.uncompleteStep('availability');

                    if (canLoadAvailability()) {
                        return loadMechanicAvailability();
                    } else {
                        cleanupMechanicAvailability();
                    }

                }
            };

            let toggleWatchers = function () {
                let locationWatcher;
                let serviceWatcher;
                let mechanicsWatcher;
                return function () {
                    if (locationWatcher) {
                        locationWatcher();
                        locationWatcher = undefined;
                    } else {
                        locationWatcher = $scope.$watch("bcrc.serviceLocation",
                            function (newVal, oldVal) {
                                if (!newVal || !angular.equals(newVal, oldVal)) {
                                    updateMechanicAvailability();
                                }
                            });
                    }

                    if (serviceWatcher) {
                        serviceWatcher();
                        serviceWatcher = undefined;
                    } else {
                        serviceWatcher = $scope.$watch('bcrc.services.selected',
                            function (newVal, oldVal) {
                                if (!newVal || !angular.equals(newVal, oldVal)) {
                                    updateMechanicAvailability();
                                }
                            });
                    }

                    if (mechanicsWatcher) {
                        mechanicsWatcher();
                        mechanicsWatcher = undefined;
                    } else {
                        mechanicsWatcher = $scope.$watch('bcrc.request.mechanicId',
                            function (newVal, oldVal) {
                                if (!newVal || !angular.equals(newVal, oldVal)) {
                                    $scope.wizard.uncompleteStep('availability');
                                }
                            });
                    }
                };
            };

            $scope.availabilityStep.toggleWatchers = toggleWatchers();
            $scope.availabilityStep.toggleWatchers();
        }
    };
});
