import app from "js/legacy-app";

app.service('$staticService', [
    '$mdSidenav',
    'ChangeUrlService',
    '$pageHeader',
    '$pageFooter',
    '$notificationListener',
    '$multiBrowser',
    function ($mdSidenav, ChangeUrlService, $pageHeader, $pageFooter, $notificationListener, $i18n, $multiBrowser) {
    }]);