import app from "js/legacy-app";
import angular from "angular";
import mechanicLocationMarker from "img/mechanic-location.png";

app.controller('MechanicListController', ['$scope', 'Mechanics', 'User', '$mdMedia', '$document', 'ErrorHandler',
    'ToggleLD', '$mdDialog', '$q', 'MECHANIC_CATEGORIES', '$location', 'AuthService', '$mechanicService',
    function ($scope, Mechanics, User, $mdMedia, $document, ErrorHandler,
              ToggleLD, $mdDialog, $q, MECHANIC_CATEGORIES, $location, AuthService, $mechanicService) {

        $scope.authService = AuthService;
        $scope.mdMedia = $mdMedia;

        var mlc = $scope.mlc = {

            search: function (query) {
                searchForMap(query);
                return searchForList(query);
            },

            filter: {
                category: {
                    options: [{val: null, display: 'Any'}].concat(Object.keys(MECHANIC_CATEGORIES).map(function (k) {
                        return {val: k, display: MECHANIC_CATEGORIES[k]};
                    }))
                },
                mechanic: {
                    displayItem: function (item) {
                        return item.name + " " + item.surname;
                    },
                    search: function (query) {
                        var deferred = $q.defer();
                        User.findByNameLike({name: query, role: 'MECHANIC'}, function (response) {
                            deferred.resolve(response.items);
                        }, function (error) {
                            deferred.reject(error);
                        });
                        return deferred.promise;
                    }
                }
            }
        };

        $scope.mlc.mapController = {};
        $scope.mlc.location = {};
        $scope.mlc.applyLocationFilter = function (location) {
            if (location) {
                $scope.mlc.mapController.map.fitBounds(location.bounds);
            }
        };

        $scope.mlc.highlightArea = function (mechanic) {
            $scope.mlc.mapController.highlightArea(mechanic.id);
        };

        $scope.mlc.unhighlightArea = function (mechanic) {
            $scope.mlc.mapController.unhighlightArea(mechanic.id);
        };

        $scope.mlc.schedule = function (mechanic, $event) {
            $mechanicService.scheduleMechanic($event, mechanic, $scope.mlc.location, $scope.mlc.setUpLocation);
        };

        $scope.mlc.toggleView = function () {
            $scope.mlc.mapView = !$scope.mlc.mapView;
            maybeUpdateScroll();
        };

        var maybeUpdateScroll = function () {
            if ($scope.mlc.mapView) {
                hideScroll();
            } else {
                showScroll();
            }
        };

        var hideScroll = function () {
            angular.element($document[0].body).css('overflow-y', 'hidden');
        };

        var showScroll = function () {
            angular.element($document[0].body).css('overflow-y', 'auto');
        };

        function searchForMap(query) {
            var queryClone = angular.copy(query);
            queryClone.page = 0;
            queryClone.size = 100;
            queryClone.detalization = 'S';
            return Mechanics.search(queryClone, function (response) {
                maybeUpdateScroll();
                var areas = [].concat.apply([], response.data.map(function (m) {
                    return m.workArea.areas.map(function (a) {
                        var bounds = a.bounds;
                        var radius = bounds.radius;
                        var center = {
                            lng: bounds.center.coordinates[0],
                            lat: bounds.center.coordinates[1]
                        };
                        return {
                            center: center,
                            radius: radius,
                            entity: m
                        };
                    });

                }));

                $scope.mlc.mapController.addManyWorkAreas(areas, {
                    marker: {
                        icon: mechanicLocationMarker
                    },
                    infoWindow: function ($internalScope, entity) {
                        $internalScope.mechanic = entity;
                        $internalScope.mlc = $scope.mlc;

                        return `<div ng-cloak class="mechanic-list__info-window">
                               <a target="_blank" rel="noopener noreferrer" href="/mechanic-public/{{ mechanic.nickname }}" class="display-block">
                                   <img data-ng-if="mechanic.avatar" class="mechanic-list__info-window-avatar vert-middle" src="{{mechanic.avatar}}">
                                   <img data-ng-if="!mechanic.avatar" class="mechanic-list__info-window-avatar vert-middle" src="${require(`img/mechanic-default-picture.png`)}">
                                   <span class="mechanic-list__info-window-name vert-middle">{{mechanic.name}} {{mechanic.surname}}</span>
                               </a>
                               <div class="center">
                                   <button ng-click="mlc.schedule(mechanic, $event)"
                                       class="md-button mechanic-list__info-window-schedule-btn">
                                       <span class="vert-middle">Schedule {{ mechanic.name }}</span>
                                   </button>
                               </div>
                            </div>`;
                    }
                });
            }, function (error) {


            }).$promise;
        }


        function searchForList(query) {
            return Mechanics.search(query).$promise;
        }

    }]);
