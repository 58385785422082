export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/reassign-booking', 'reassign-booking')

        .segment('reassign-booking', {
            access: {requireLogin: true},
            template: '<vui-reassign-booking></vui-reassign-booking>',
            loadModule: () => {
                return import(/* webpackChunkName: "reassign-booking.module" */ "./reassing-booking.module");
            }
        });

}
