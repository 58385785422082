import app from "js/legacy-app";

app.directive('sidenavLoadingIndicator', ['SidenavLD', function (SidenavLD) {
    return {
        templateUrl: "template/component/widget/sidenav/sidenav-loading-indicator.html",
        replace: true,
        link: function ($scope, element, $attrs) {
            $scope.sli.loadingText = $attrs.sliLoadingText;
        },
        controller: ['$scope', function ($scope) {
            $scope.sli = {
                sidenavLD: SidenavLD
            };
        }]
    };
}]);