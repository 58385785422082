import app from "js/legacy-app";

app.service('$notificationListener', ['$rootScope', 'NotificationWSService', 'AuthService', 'Notification',
    '$location', '$routeSegment', '$route', 'HistoryService', '$pageScroll',
    function ($rootScope, NotificationWSService, AuthService, Notification, $location,
              $routeSegment, $route, HistoryService, $pageScroll) {

        $rootScope.notificationList = {};
        $rootScope.notifications = [];
        $rootScope.notificationTotalCount = 0;

        var setList = function (message) {
            $rootScope.$apply(function () {
                addNotificationsToList(message);
                $rootScope.notificationTotalCount = message.data.unreadNotificationResponse.countUnreadNotifications;
                $rootScope.notificationPage = message.data;
                $rootScope.notificationScrollDisabled = false;
            });
        };

        function addNotificationsToList(message) {
            var notificationList = message.data.data;
            for (var i = 0; i < notificationList.length; i++) {
                if (!$rootScope.notificationList[notificationList[i].id]) {
                    $rootScope.notificationList[notificationList[i].id] = true;
                    $rootScope.notifications.push(notificationList[i]);
                }
            }
        }

        var addNew = function (message) {
            $rootScope.$apply(function () {
                $rootScope.notifications.unshift(message.data);
                $rootScope.notificationTotalCount++;
                markNewNotificationAsLastViewed();
            });
            Notification.notification(message.data);
        };

        function markNewNotificationAsLastViewed() {
            var listIsActive = document.getElementsByClassName('ui-submenu-active')[0];
            if (!listIsActive) {
                return;
            }

            AuthService.currentUser(function (user) {
                if (user) {
                    NotificationWSService.send({
                        operation: 3,
                        token: AuthService.getToken().access_token
                    });
                }
            });
        }

        var updateCount = function (message) {
            $rootScope.$apply(function () {
                $rootScope.notificationTotalCount = message.data;
            });
        };

        var changeInetConnectionStatus = function (connected) {
            $rootScope.$apply(function () {
                $rootScope.inetConnectionStatus = connected;
            });
        };

        var readNotification = function (message) {
            $rootScope.$apply(function () {
                $rootScope.notificationTotalCount = message.data.countNotRead;
                var ids = message.data.ids;
                if (!ids) {
                    return;
                }
                var idx = indexOf($rootScope.notifications, ids[0]);
                if (idx !== -1) {
                    $rootScope.notifications[idx].read = true;
                }
            });
        };

        var readNotifications = function (message) {
            $rootScope.$apply(function () {
                $rootScope.notificationTotalCount = message.data.countNotRead;
                var ids = message.data.ids;
                if (!ids) {
                    return;
                }
                for (var i = 0; i < ids.length; i++) {
                    var index = indexOf($rootScope.notifications, ids[i]);
                    if (index !== -1) {
                        $rootScope.notifications[index].read = true;
                    }
                }
            });
        };

        NotificationWSService.registerHandler('LIST', 'general', setList);
        NotificationWSService.registerHandler('NEW', 'general', addNew);
        NotificationWSService.registerHandler('COUNT', 'general', updateCount);
        NotificationWSService.registerHandler('READ_SINGLE', 'general', readNotification);
        NotificationWSService.registerHandler('READ_LIST', 'general', readNotifications);
        NotificationWSService.registerInternetConnectionHandler(changeInetConnectionStatus);

        $rootScope.goToNegotiation = function (notification) {
            $pageScroll.show();
            if (!notification.read) {
                NotificationWSService.readNotification(notification.id, AuthService.getToken());
            }
            HistoryService.clear();
            var actions = notification.actions.DEFAULT;
            if (actions && actions.length > 0) {
                $location.url(actions[0].url);
                reloadSegmentIfNeeded(actions[0]);
            }
        };

        $rootScope.onNotificationActionBtnClick = function (notification, action) {
            $pageScroll.show();
            if (!notification.read) {
                NotificationWSService.readNotification(notification.id, AuthService.getToken());
            }
            HistoryService.clear();
            $location.url(action.url);
            reloadSegmentIfNeeded(action);
        };

        var reloadSegmentIfNeeded = function (action) {
            var urlComponents = action.url.split('?');
            if (urlComponents && urlComponents[0] && urlComponents[0].match($route.current.$$route.regexp)) {
                $routeSegment.chain[$routeSegment.chain.length - 1].reload();
                $route.reload();
            }
        };

        function indexOf(arr, id) {
            for (var idx = 0, l = arr.length; arr[idx] && arr[idx].id !== id; idx++) ;
            return idx === l ? -1 : idx;
        }
    }
]);
