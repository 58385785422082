import app from "js/legacy-app";

app.filter("insuranceStatus", [function () {
    return function (status) {
        switch (status) {
            case 'ACTIVE':
                return 'Active';
            case 'EXPIRED':
                return 'Expired';
            case 'PENDING_CONFIRMATION':
                return 'Pending Confirmation';
            case 'IN_FUTURE':
                return 'In Future';
        }
    };
}]);