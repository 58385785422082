import app from "js/legacy-app";

app.directive('plSwitchFilter', ['$location', function ($location) {
    return {
        template: '<div class="pageable-list__filter-field">' +
        '               <md-switch class="vert-middle display-inline-block"' +
        '                   aria-label="Activate/Deactivate"' +
        '                   name="{{ name }}"' +
        '                   ng-change="sf.onItemChange()"' +
        '                   ng-model="sf.active">' +
        '                       <span data-ng-if="sf.active"' +
        '                       class="pageable-list__filter-field-label">{{ labelActive }}</span>' +
        '                       <span data-ng-if="!sf.active"' +
        '                       class="pageable-list__filter-field-label">{{ labelInactive }}</span>' +
        '               </md-switch>' +
        '           </div>',

        replace: true,
        require: '^^plFilter',
        scope: {
            labelActive: '@sfLabelActive',
            labelInactive: '@sfLabelInactive',
            name: '@sfName',
            dependsOn: "@sfDependsOn"
        },
        link: function ($scope, element, $attrs, plFilter) {
            $scope.sf = {};
            $scope.sf.active = false;
            $scope.sf.filter = plFilter.filter;

            $scope.sf.filter.register($scope.name, $scope.sf);

            $scope.sf.onItemChange = function () {
                $scope.sf.filter.apply();
            };

            $scope.sf.isSelected = function () {
                return JSON.parse($scope.sf.active);
            };

            $scope.sf.getValue = function () {
                return $scope.sf.isSelected();
            };

            $scope.sf.serialize = function () {
                var serialized = {};
                serialized[$scope.name] = $scope.sf.getValue();
                return serialized;
            };

            $scope.sf.deserialize = function (queryString) {
                if (queryString[$scope.name]) {
                    $scope.sf.active = JSON.parse(queryString[$scope.name]);
                }
            };

            $scope.sf.clear = clear;
            $scope.sf.init = init;

            function clear() {
                $scope.sf.active = false;
            }

            function init() {
                $scope.sf.deserialize($location.search());

                $scope.sf.filter.initialized($scope.name);

                if ($scope.dependsOn) {
                    $scope.$watch('sf.filter.filters.' + $scope.dependsOn + '.getValue()', function (newVal, oldVal) {
                        if (newVal !== oldVal) {
                            clear();
                        }
                    });
                }
            }

            init();
        }
    };
}]);
