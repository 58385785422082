import app from "js/legacy-app";

app.controller('ChangeBikeGroupCtrl', ['$scope', '$routeParams', '$mdDialog', 'ToggleLD', '$bikeService',
    'Notification', 'bikeId', 'ErrorHandler', '$q', 'Station', 'AuthService',
    function ($scope, $routeParams, $mdDialog, ToggleLD, $bikeService, Notification, bikeId, ErrorHandler, $q, Station, AuthService) {

        const cbgdc = {
            resource: $bikeService.getResourceClass(),
            organisationId: AuthService.isBikeBusinessSupervisor() ? $routeParams.organisationId : AuthService.user().companyId
        };

        $scope.autocomplete = {};

        $scope.close = function () {
            $mdDialog.cancel();
        };

        $scope.submitForm = function (form) {
            if (form.$valid) {
                changeGroup();
            }
        };

        $scope.autocomplete.querySearch = function (query) {
            return Station.findByName({param2: cbgdc.organisationId, name: query}).$promise;
        };

        const changeGroup = function () {
            const data = {};
            if ($scope.autocomplete.selectedItem) {
                data.stationId = $scope.autocomplete.selectedItem.id;
            } else {
                Notification.error("Please enter new bicycle group");
                return;
            }
            ToggleLD.show();
            cbgdc.resource.changeBikeGroup({id: bikeId}, data, function (response) {
                $mdDialog.hide({});
                ToggleLD.hide();
                Notification.success("This bicycle has been changed successfully");
            }, function (error) {
                ToggleLD.hide();
                ErrorHandler.onError(error, "Failed to change bicycle group");
            });
        };

    }]);
