import app from "js/legacy-app";
import noOrganisationAvatarImg from 'img/no-organization-avatar.png';

app.directive('dashboardStation', [function () {
    return {
        templateUrl: "template/dashboard/bike-business-supervisor/organisation/station/dashboard-station.html",
        controller: ['$scope', 'Station', '$routeParams', '$location', 'AuthService',
            function ($scope, Station, $routeParams, $location, AuthService) {
                var size = 9,
                    page = 0;

                $scope.dashboard = {};
                $scope.organisationId = $routeParams.organisationId;
                $scope.AuthService = AuthService;

                $scope.getViewBikesUrl = function (station) {
                    if (AuthService.isBikeOrganisationOwner()) {
                        return 'company-dashboard/bicycles?station=' + station.id +
                            '&organisation=' + $scope.organisationId + '&stationQuery=' + station.name;
                    } else {
                        return 'organisation/' + $scope.organisationId + '/bikes?' +
                            'station=' + station.id +
                            '&stationQuery=' + station.name;
                    }
                };

                if (!$scope.organisationId && AuthService.isBikeOrganisationOwner()) {
                    $scope.organisationId = AuthService.user().companyId;
                }

                if ($routeParams.page) {
                    page = $routeParams.page;
                }

                $scope.refresh = function () {
                    var searchParam = {
                        page: page
                    };

                    $location.search(searchParam);
                    $scope.getStations();
                };

                $scope.getStations = function () {
                    var id = $scope.organisationId;
                    if (AuthService.isBikeOrganisationOwner()) {
                        id = 'current';
                    }
                    Station.findByOrganisationId({
                        param2: id,
                        size: size,
                        page: page
                    }, function (data) {
                        getStationsCallback(data);
                    });
                };

                $scope.prev = function () {
                    if ($scope.dashboard.prev || (page !== 0 && !$scope.dashboard.data)) {
                        page--;
                        $scope.refresh();
                    }
                };

                $scope.next = function () {
                    if ($scope.dashboard.next) {
                        page++;
                        $scope.refresh();
                    }
                };

                $scope.refresh();

                var getStationsCallback = function (data) {
                    $scope.dashboard = data;
                    for (var i in $scope.dashboard.data) {
                        if ($scope.dashboard.data[i].image) {
                            $scope.dashboard.data[i].avatar = $scope.dashboard.data[i].image.thumbnail;
                        } else {
                            $scope.dashboard.data[i].avatar = noOrganisationAvatarImg;
                        }
                    }

                    if (data.data.length === 0) {
                        $scope.prev();
                    }
                };

            }
        ]
    };
}]);