import app from "js/legacy-app";

app.service('$bookingCancellationService', [function () {
    let getCancellationPolicy = (cancellationRules) => {
        if (!cancellationRules) {
            return;
        }

        let flexible = cancellationRules[0];
        let moderate = cancellationRules[1];
        let strict = cancellationRules[2];

        let flexibleTime = flexible.timeToBookingStart;
        let moderateTime = moderate.timeToBookingStart;
        let strictTime = strict.timeToBookingStart;

        let moderatePair = flexibleTime === moderateTime ? null : [flexibleTime, moderateTime];

        let strictPair = moderateTime === strictTime ? null : [moderateTime, strictTime];

        if (!moderatePair && !strictPair) {
            return 'You have flexible cancellation rules and can cancel this booking any time and get a full refund.';
        }

        if (moderatePair && !strictPair) {
            return 'Cancel up to ' + flexibleTime + ' hours before your booking and get a full refund. <br>' +
                'Cancel within ' + flexibleTime + ' hours before your booking and get a 50% refund.';
        }

        if (!moderatePair && strictPair) {
            return 'Cancel up to ' + flexibleTime + ' hours before your booking and get a full refund. <br>' +
                'Cancel within ' + flexibleTime + ' hour(s) before your booking and the booking is non-refundable.';
        }

        return 'Cancel up to ' + flexibleTime + ' hours before your booking and get a full refund. <br>' +
            'Cancel within ' + flexibleTime + ' hours and up to ' + moderateTime + ' hours before your booking and get a 50% refund. ' +
            'Cancel within ' + moderateTime + ' hour(s) before your booking and the booking is non-refundable.';
    };

    let cancellationMessage = (penalties, group) => {
        if ('FLEXIBLE' === penalties) {
            return 'Are you sure you want to cancel this booking?';
        }

        if ('MODERATE' === penalties) {
            if (group) {
                return `Given the cancellation policy of your mechanic,
                        you will be refunded the entire amount minus 50% of the cost of one bike.
                        <br> <br> Would you like to cancel this booking?`;
            }

            return `Given the cancellation policy of your mechanic,
                    you will be charged 50% of your booking.
                    <br> <br> Would you like to cancel this booking?`;
        }

        if ('STRICT' === penalties) {
            if (group) {
                return `Given the cancellation policy of your mechanic,
                        you will be refunded the entire amount minus 100% of the cost of one bike.
                        <br> <br> Would you like to cancel this booking?`;
            }

                return `Given the cancellation policy of your mechanic,
                        you will be charged 100% of your booking.
                        <br> <br> Would you like to cancel this booking?`;
        }
    };

    return {
        getCancellationPolicy: getCancellationPolicy,
        getCancellationMessage: cancellationMessage
    }
}]);
