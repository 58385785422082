import app from "js/legacy-app";

app.directive('dpMaxDate', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            var maxDpValidator = function (value) {
                var max = new Date(attr.dpMaxDate);
                var newValue = new Date(value);
                if (newValue && newValue.getTime() > max.getTime()) {
                    ctrl.$setValidity('dpMaxDate', false);
                    return value;
                } else {
                    ctrl.$setValidity('dpMaxDate', true);
                    return value;
                }
            };

            ctrl.$parsers.push(maxDpValidator);
            ctrl.$formatters.push(maxDpValidator);
        }
    };
});