import app from "js/legacy-app";

app.controller('RequestReportCtrl', ['$scope', '$mdDialog', 'ModalService', 'Documents', 'additionalServices', '$pageFooter',
    function ($scope, $mdDialog, ModalService, Documents, additionalServices, $pageFooter) {

        $scope.rrc = {
            documents: Documents.bikeHistoryImage(),
            images: [],
            additionalServices: additionalServices
        };

        $scope.requestReport = {
            comment: '',
            images: []
        };

        $scope.rrc.submit = function (form) {
            if (form.$valid) {
                $scope.rrc.images.images.filter(function (image) {
                    return !image.failed;
                }).forEach(function (img) {
                    var image = {
                        id: img.id,
                        original: img.originUrl,
                        thumbnail: img.thumbnailUrl
                    };
                    $scope.requestReport.images.push(image);
                });
                $mdDialog.hide($scope.requestReport);
            }
        };

        $scope.rrc.close = function () {
            $mdDialog.hide();
        };

        (function init() {
            $pageFooter.hide();
        })();
    }]);