import app from "js/legacy-app";
import angular from "angular";

app.service('VtSupportWSService', function ($timeout, $wsService) {
    'ngInject';

    var Chat = function Chat(taskId, rId, token) {
        var ws;
        var closeWS = false;
        var handlers = [];
        var connection;
        const self = this;

        this.send = function (data) {
            if (ws && ws.readyState === ws.OPEN) {
                ws.send(angular.toJson(data));
            }
        };

        this.close = function () {
            closeWS = true;
            if (ws && ws.CLOSED !== ws.readyState) {
                ws.close();
            }

            $wsService.remove(ws);
        };

        this.registerInternetConnectionHandler = function (fn) {
            connection = fn;
        };

        this.registerHandler = function (label, fn) {
            handlers.push({
                label: label,
                fn: fn
            });
        };

        var connect = function () {
            if ($wsService.exists(ws)) {
                return;
            }

            var protocol = location.protocol === 'https:' ? 'wss://' : 'ws://';
            var socketUrl = protocol + document.location.host + '/help-chat';
            self.ws = ws = new WebSocket(socketUrl);

            ws.onclose = function (evt) {
                checkStatusConnection(false);
                if (closeWS === true) {
                    ws = null;
                    closeWS = false;
                    rId = null;
                } else {
                    ws = null;
                    timeout(15);
                }
            };

            ws.onmessage = function (evt) {
                var response = angular.fromJson(evt.data);
                if (handlers) {
                    for (var i in handlers) {
                        if (handlers[i].label === response.type) {
                            handlers[i].fn(response);
                        }
                    }
                }
            };

            ws.onopen = function (evt) {
                ws.send(angular.toJson({
                    operation: 0,
                    token: token,
                    taskId: taskId,
                    recipientId: rId
                }));
                pingWs();
                checkStatusConnection(true);
            };

            ws.onerror = function (evt) {
                checkStatusConnection(false);
            };

            $wsService.add(self);
        };

        function pingWs() {
            setTimeout(function () {
                if (closeWS === false && ws && ws.readyState === ws.OPEN) {
                    ws.send(angular.toJson({
                        operation: 3,
                        recipientId: rId,
                        token: token
                    }));
                    pingWs();
                }
            }, 55 * 1000);
        }

        function checkStatusConnection(connected) {
            if (angular.isDefined(connection) && angular.isFunction(connection)) {
                connection(connected);
            }
        }

        function timeout(seconds) {
            $timeout(function () {
                connect();
            }, 1000 * seconds);
        }

        connect();
    };

    return {
        newInstance: function (taskId, rId, token) {
            return new Chat(taskId, rId, token);
        }
    };
});
