import app from "js/legacy-app";

app.directive('vtWizardTransclude', [function () {
    return {
        link: function (scope, elem) {
            scope.$transclude(scope.$parent, function (clone) {
                elem.empty();
                elem.append(clone);
            });
        }
    };
}]);