import app from "js/legacy-app";

app.service('FrameMaterial', ['$resource', function($resource){
    return $resource('/api/frame-material/:id', {}, {
        update: {
            method: "PUT",
            params: {id : ""},
            isArray: false
        }
    });
}]);