import app from "js/legacy-app";
import mechanicDefaultImage from "img/mechanic-no-avatar.png";
import customerDefaultImage from "img/user-no-avatar.png";

app.directive('uploadAvatar', [function () {
    return {
        replace: true,
        scope: {
            profile: '='
        },
        templateUrl: "template/profile/tabs/avatar/upload-avatar.html",
        controller: ['$scope', 'User', 'ModalService', 'Notification', 'ToggleLD', 'AuthService',
            function ($scope, User, ModalService, Notification, ToggleLD, AuthService) {

                $scope.ua = {};

                $scope.$watch('profile.user.avatar.original', function () {
                    updateAvatarImage();
                });

                var updateAvatarImage = function () {
                    if ($scope.profile && $scope.profile.user && $scope.profile.user.avatar && $scope.profile.user.avatar.original) {
                        $scope.ua.avatar = $scope.profile.user.avatar.original;
                        $scope.ua.defaultAvatar = false;
                    } else if (AuthService.user() && AuthService.user().role === 'MECHANIC') {
                        $scope.ua.avatar = mechanicDefaultImage;
                        $scope.ua.defaultAvatar = true;
                    } else {
                        $scope.ua.avatar = customerDefaultImage;
                        $scope.ua.defaultAvatar = true;
                    }
                };

                $scope.changeAvatar = function ($event) {
                    ModalService.chooseUserAvatar($event, $scope.profile.user, 'user', null,
                        function (response) {
                            if (response) {
                                User.current(function (data) {
                                    setUpAvatar(data.avatar);
                                });
                            }
                        });
                };

                $scope.clear = function () {
                    ToggleLD.show();
                    User.clearAvatar(function (data) {
                        ToggleLD.hide();
                        setUpAvatar(data.avatar);
                        Notification.success('Avatar was successfully cleared');
                    }, function (error) {
                        ToggleLD.hide();
                        Notification.error("Avatar wasn't cleared");
                    });
                };

                var setUpAvatar = function (avatar) {
                    $scope.profile.user.avatar = avatar;
                    AuthService.user().avatar = avatar;
                };
            }]
    };
}]);
