export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/admin/mechanic/:mechanicId/verification', 'admin-mechanic-verification-details')
        .when('/admin/mechanics-verification', 'admin-mechanics-verification')

        .segment('admin-mechanic-verification-details', {
            access: {requireLogin: true},
            template: '<mechanic-verification-details></mechanic-verification-details>',
            loadModule: () => {
                return import(/* webpackChunkName: "mechanic-verification-details.module" */ "./mechanic-verification-details/mechanic-verification-details.module");
            }
        })
        .segment('admin-mechanics-verification', {
            access: {requireLogin: true},
            template: '<mechanic-verification-list></mechanic-verification-list>',
            loadModule: () => {
                return import(/* webpackChunkName: "mechanic-verification-list.module" */ "./mechanic-verification-list/mechanic-verification-list.module");
            }
        });

}
