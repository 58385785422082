import app from "js/legacy-app";

app.controller('TaskFlowDescriptionsCtrl', ['$scope', 'ServiceExecutionFlow', '$location', 'ModalService', '$q',
    function ($scope, ServiceExecutionFlow, $location, ModalService, $q) {

        let countChecked = function() {
            if (!$scope.initDataTable.getData) {
                return;
            }
            let count = 0,
                data = $scope.initDataTable.getData();
            for (let i in data) {
                if (data[i].selected) {
                    count++;
                }
            }

            return count;
        };

        let update = function() {
            let id,
                data = $scope.initDataTable.getData();

            for (let i in data) {
                if (data[i].selected) {
                    id = data[i].id;
                }
            }

            if (id) {
                $location.url('/admin/service-flow/' + id);
            }
        };

        let remove = function() {
            let message = 'Are you sure you want to remove this task flow description?';
            ModalService.yesNoMessage(null, '', message, function(response) {
                if (response === true) {
                    let promises = [],
                        data = $scope.initDataTable.getData();
                    for (let i in data) {
                        if (data[i].selected) {
                            promises.push(ServiceExecutionFlow.delete({id: data[i].id}).$promise);
                        }
                    }

                    $q.all(promises).finally($scope.initDataTable.refresh);
                }
            });
        };

        $scope.initDataTable = {
            service: ServiceExecutionFlow,
            defaultFilters: function () {
                return {detalization: 'L'};
            },
            filtersAsSeparateFields: true,
            name: 'admin-service-flow',
            selected: true,
            columns: [
                {
                    name: 'steps',
                    columnName: 'Tasks',
                    show: true,
                    getValue: function (value) {
                        let tasks = [];
                        for (let i = 0; i < value.length; i++) {
                            tasks.push(value[i].description);
                        }
                        return tasks.join(', ');
                    }
                },
                {
                    name: 'steps',
                    columnName: 'Count steps',
                    show: true,
                    getValue: function (value) {
                        return value.length;
                    }
                }
            ],
            actions: [
                {
                    html: '<i class="fa fa-plus" aria-hidden="true"></i>',
                    title: 'Add',
                    apply: function (data, refrash) {
                        $location.url("/admin/service-flow/create");
                        return;
                    },
                    disable: function () {
                        return false;
                    }
                },
                {
                    html: '<i class="fa fa-pencil" aria-hidden="true"></i>',
                    title: 'Edit',
                    apply: update
                },
                {
                    html: '<i class="fa fa-times" aria-hidden="true"></i>',
                    title: 'Remove',
                    apply: remove,
                    disable: function () {
                        return countChecked() === 0;
                    }
                }
            ]
        };

    }
]);
