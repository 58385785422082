import app from "js/legacy-app";

app.directive ("bicycleAssembly", function () {
    return {
        restrict: 'A',
        link: function () {
        },
        controller: ['$scope', function ($scope) {
            $scope.scrollTo = false;
            $scope.scroll = function () {
                $scope.scrollTo = true;
            };
        }]
    };
});