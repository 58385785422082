import app from "js/legacy-app";
import angular from "angular";

app.directive('customServiceCategoryView', ['$vtSidenav', function ($vtSidenav) {
    return {
        templateUrl: "components/service/view/custom-service-category-view.html",
        replace: true,
        restrict: 'E',
        transclude: true,
        scope: {
            onSave: '&cscvOnSave',
            readonly: '<?cscvReadonly'
        },
        controller: ['$scope', 'AuthService', '$routeParams', 'CustomServiceCategories', 'OrganisationServiceCategories', 'ErrorHandler', 'SidenavLD', 'Notification', 'ModalService',
            function ($scope, AuthService, $routeParams, CustomServiceCategories, OrganisationServiceCategories, ErrorHandler, SidenavLD, Notification, ModalService) {

                $scope.mcv = {
                    title: $scope.readonly ? 'View category' : 'Edit category',
                    service: AuthService.isMechanic() ? CustomServiceCategories : OrganisationServiceCategories
                };

                $scope.mcv.getParams = function (id) {
                    if (AuthService.isMechanic()) {
                        return {id: id};
                    } else {
                        let organisationId = $routeParams.organisationId;
                        return {
                            id: organisationId,
                            id1: id
                        };
                    }
                };

                $scope.mcv.onOpen = function (id, onSuccess, onError) {
                    return $scope.mcv.service.get($scope.mcv.getParams(id), function (category) {
                        $scope.mcv.category = category;
                        $scope.mcv.category.readOnly = AuthService.isAdmin() ? false : !!$scope.mcv.category.coreCategoryId;
                        if (AuthService.isAdmin()) {
                            $scope.mcv.category.coreCategoryId = $scope.mcv.category.id;
                        }
                    }, function (error) {
                        ErrorHandler.onError(error);
                    }).$promise;
                };

                $scope.mcv.save = function (onError) {
                    SidenavLD.show();
                    $scope.mcv.service.patch($scope.mcv.getParams($scope.mcv.category.id), {name: $scope.mcv.category.name}, function (data) {
                        SidenavLD.hide();
                        $vtSidenav("custom-service-category-view").close();
                        if (angular.isFunction($scope.onSave)) {
                            $scope.onSave({data: data});
                        }
                        Notification.success('Category was updated');
                    }, function (error) {
                        onError(error);
                        SidenavLD.hide();
                    });
                };

                $scope.mcv.resetDefaultCustom = function (event) {
                    let message = `Are you sure you want reset '${$scope.mcv.category.name}' category?`;
                    ModalService.yesNoMessage(event, null, message, function (response) {

                        if (!response) {
                            return;
                        }

                        SidenavLD.show();
                        $scope.mcv.service.resetDefault($scope.mcv.getParams($scope.mcv.category.id), {}, function (data) {
                            SidenavLD.hide();
                            $vtSidenav("custom-service-category-view").close();
                            if (angular.isFunction($scope.onSave)) {
                                $scope.onSave({data: data});
                            }
                            Notification.success('Category was reset');
                        }, function (error) {
                            ErrorHandler.onError(error);
                            SidenavLD.hide();
                        });
                    });
                }
            }]
    };
}]);
