import app from "js/legacy-app";

app.directive('shareButtons', [function () {
    return {
        templateUrl: "template/component/share-buttons.html",
        replace: true,
        scope: {
            text: "@",
            url: "@",
            hashtags: "@"
        },
        controller: ['$scope', function ($scope) {
        }]
    };
}]);