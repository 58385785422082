import app from "js/legacy-app";

app.directive( 'transcope', function() {
    return {
        link: function ($scope, $element, $attrs, controller, $transclude) {
            if ( !$transclude ) {
                throw new Error(
                    'Illegal use of ngTransclude directive in the template! ' +
                    'No parent directive that requires a transclusion found.');
            }
            var innerScope = $scope.$new();
            innerScope.parent = $scope.$parent.parent;

            $transclude( innerScope, function( clone ) {
                $element.empty();
                $element.append( clone );
                $element.on( '$destroy', function() {
                    innerScope.$destroy();
                });
            });
        }
    };
}); 