import app from "js/legacy-app";

app.controller('EnterSerialNumberDialogController', ['$scope', '$mdDialog', function ($scope, $mdDialog) {

    $scope.enterSN = {};

    $scope.close = function() {
        $mdDialog.hide();
    };

    $scope.save = function(form) {
        if (form.$valid) {
            $mdDialog.hide($scope.enterSN.serialNumber);
        }
    };

}]);