import app from "js/legacy-app";

app.service('CustomServiceManagement', ['$resource', function ($resource) {
    return $resource('/api/custom-services/:id/:id1', {}, {
        patch: {
            method: "PATCH",
            params: {id: ""},
            isArray: false
        },
        updateCorporateFlag: {
            method: "PUT",
            params: {id: "corporate/activity"},
            isArray: false
        },
        updateActivityFlag: {
            method: "PUT",
            params: {id1: "activity"},
            isArray: false
        },
        tree: {
            method: "GET",
            params: {id: "tree"},
            isArray: false,
        },
        updatePopularityFlag: {
            method: "PUT",
            params: {id1: "popular"},
            isArray: false,
        },
        resetDefault: {
            method: "PUT",
            params: {id1: "reset-default"},
            isArray: false,
        }
    });
}]);
