import app from "js/legacy-app";

app.controller('ChooseColorDialogController', ['$scope', '$mdDialog', 'colors', 'count', function ($scope, $mdDialog, colors, count) {

    $scope.chooseColor = {
        colors: [],
        count: count
    };
    if (colors) {
        for (var i in colors) {
            $scope.chooseColor.colors.push(colors[i]);
        }
    }

    if (!$scope.chooseColor.count) {
        $scope.chooseColor.count = 1;
    }

    $scope.colors = [
        {name: 'Dark Red',    color: '#800000', id: 'dark-red'},
        {name: 'Red',         color: '#ff0000', id: 'red'},
        {name: 'White',       color: '#ffffff', id: 'white'},
        {name: 'Green',       color: '#008000', id: 'green'},
        {name: 'Light Green', color: '#00ff00', id: 'light-green'},
        {name: 'Blue',        color: '#0000ff', id: 'blue'},
        {name: 'Dark Blue',   color: '#000090', id: 'dark-blue'},
        {name: 'Light Blue',  color: '#00ffff', id: 'light-blue'},
        {name: 'Black',       color: '#000000', id: 'black'},
        {name: 'Grey',        color: '#7f7f7f', id: 'grey'},
        {name: 'Pink',        color: '#ff00ff', id: 'pink'},
        {name: 'Purple',      color: '#800080', id: 'purple'},
        {name: 'Brown',       color: '#996633', id: 'brown'},
        {name: 'Yellow',      color: '#ffff00', id: 'yellow'},
        {name: 'Orange',      color: '#ff8000', id: 'orange'},
        {name: 'Carbon',      color: '#ff8000', id: 'carbon'}
    ];

    $scope.isActive = function(color) {
        if (!$scope.chooseColor.colors) {
            return '';
        }
        return $scope.chooseColor.colors.indexOf(color.id) > -1 ? 'active' : '';
    };

    $scope.getNumberColor = function(id) {
        if ($scope.chooseColor.count === 1) {
            return '';
        }

        for(var i in $scope.chooseColor.colors) {
            if ($scope.chooseColor.colors[i] === id) {
                return $scope.chooseColor.colors.length - i;
            }
        }

        return '';
    };

    $scope.chooseColors = function(id) {
        if ($scope.chooseColor.colors.indexOf(id) > -1) {
            return;
        }

        while ($scope.chooseColor.colors.length >= $scope.chooseColor.count) {
            $scope.chooseColor.colors.splice(0, 1);
        }

        $scope.chooseColor.colors.push(id);
    };

    $scope.close = function() {
        $mdDialog.hide();
    };

    $scope.change = function() {
        $mdDialog.hide($scope.chooseColor.colors);
    };

}]);