export default () => {
    return {
        restrict: 'A',
        link: ($scope, $element, $attr) => {
            const element = $element[0];
            if (element.name) {
                element.autocomplete = 'new-password';
            }
        }
    };
}
