import app from "js/legacy-app";

app.directive('vtWizardStep', [function () {
    return {
        templateUrl: "components/wizard/wizard-step.html",
        replace: true,
        require: '^^vtWizard',
        transclude: true,
        scope: {
            options: "=wsOptions"
        },
        controller: ['$scope', '$transclude', function ($scope, $transclude) {
            $scope.$transclude = $transclude;
        }],
        link: function (scope, elem, attrs, wc) {
            wc.wizard.registerStep(scope.options);
        }
    };
}]);