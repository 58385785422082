import "./landing-page-steps-item.component.scss";

export default {
    template: require('./landing-page-steps-item.component.html'),
    transclude: {
        icon: "landingPageStepsItemIcon",
        desc: "landingPageStepsItemDesc"
    },
    controller: function () {
        'ngInject';
    }
};
