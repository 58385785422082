import app from "js/legacy-app";

app.directive('dashboardSearchRequest', [function () {
    return {
        templateUrl: "components/request/search/search-request.html",
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: ['$scope', 'MechanicRequest', '$routeParams', '$location', 'AuthService', '$timeout',
            function ($scope, MechanicRequest, $routeParams, $location, AuthService, $timeout) {
                var size = 10,
                    page = 0;

                $scope.searchRequest = {};
                $scope.loadingData = false;
                $scope.searchParam = {
                    priceFrom: 0,
                    priceTo: 500,
                    timeTo: 23,
                    timeFrom: 7,
                    mechanicLocation: {},
                    mechanicLocationPlace: {},
                    weekdays: [
                        'Sun',
                        'Mon',
                        'Tue',
                        'Wed',
                        'Thu',
                        'Fri',
                        'Sat'
                    ],
                    selectedWeekdays: [],
                    radius: [
                        {label: '1 mile', value: 1},
                        {label: '5 miles', value: 5},
                        {label: '10 miles', value: 10},
                        {label: '25 miles', value: 25},
                        {label: '50 miles', value: 50}
                    ],
                    experience: [
                        {label: 'Both', value: 'none'},
                        {label: 'Basic', value: 'INTERMEDIATE'},
                        {label: 'Expert', value: 'EXPERT'}
                    ],
                    currentExperience: 'none',
                    currentRadius: 50,
                    requestsMap: {
                        isInitChangeMap: false
                    },
                    changePosition: false
                };

                if ($routeParams.pageSearch) {
                    page = $routeParams.pageSearch;
                }
                if ($routeParams.experience) {
                    $scope.searchParam.currentExperience = $routeParams.experience;
                }
                if ($routeParams.priceFrom) {
                    $scope.searchParam.priceFrom = $routeParams.priceFrom;
                }
                if ($routeParams.priceTo) {
                    $scope.searchParam.priceTo = $routeParams.priceTo;
                }
                if ($routeParams.weekdays) {
                    $scope.searchParam.selectedWeekdays = $routeParams.weekdays.split(',');
                    for (var i = 0; i < $scope.searchParam.selectedWeekdays.length; i++) {
                        $scope.searchParam.selectedWeekdays[i] = parseInt($scope.searchParam.selectedWeekdays[i]);
                    }
                }
                if ($routeParams.timeFrom) {
                    $scope.searchParam.timeFrom = $routeParams.timeFrom;
                }
                if ($routeParams.timeTo) {
                    $scope.searchParam.timeTo = $routeParams.timeTo;
                }
                if ($routeParams.address) {
                    $scope.searchParam.mechanicLocation.address = $routeParams.address;
                }
                if ($routeParams.radius) {
                    $scope.searchParam.currentRadius = $routeParams.radius;
                }

                var countSearch = 0;
                $scope.search = function () {
                    countSearch++;
                    $timeout(function () {
                        countSearch--;
                        if (countSearch === 0) {
                            page = 0;
                            $scope.refresh();
                        }
                    }, 500);
                };

                $scope.refresh = function () {
                    var searchParam = {
                        pageSearch: page,
                        experience: $scope.searchParam.currentExperience,
                        priceFrom: $scope.searchParam.priceFrom,
                        priceTo: $scope.searchParam.priceTo,
                        weekdays: $scope.searchParam.selectedWeekdays.join(','),
                        timeFrom: $scope.searchParam.timeFrom,
                        timeTo: $scope.searchParam.timeTo,
                        address: $scope.searchParam.mechanicLocation.address,
                        radius: $scope.searchParam.currentRadius
                    };
                    $scope.searchParam.changePosition = false;
                    $location.search(searchParam);
                    $scope.getRequests();
                };

                $scope.getRequests = function (lat, lng) {
                    $scope.loadingData = true;
                    var address = {},
                        changeUserPosition = !(lat && lng);

                    if (lat && lng) {
                        address.latitude = lat;
                        address.longitude = lng;
                    } else {
                        address.address = $scope.searchParam.mechanicLocation.address;
                    }

                    var query = {
                        status: 'OPEN',
                        experience: $scope.searchParam.currentExperience,
                        priceFrom: $scope.searchParam.priceFrom,
                        priceTo: $scope.searchParam.priceTo,
                        weekdays: $scope.searchParam.selectedWeekdays,
                        timeFrom: parseInt($scope.searchParam.timeFrom),
                        timeTo: parseInt($scope.searchParam.timeTo),
                        address: address,
                        radius: $scope.searchParam.currentRadius
                    };

                    MechanicRequest.search({
                        size: size,
                        page: page,
                        requestQuery: query,
                        query: "",
                        sort: {
                            field: 'updatedAt',
                            direction: 'DESC'
                        }
                    }, function (data) {
                        getRequestsCallback(data, changeUserPosition);
                    }, function (err) {
                        getRequestsCallback({data: [], prev: false}, changeUserPosition);
                    });
                };

                $scope.prev = function () {
                    if ($scope.searchRequest.prev || (page !== 0 && !$scope.searchRequest.data)) {
                        page--;
                        $scope.refresh();
                    }
                };

                $scope.next = function () {
                    if ($scope.searchRequest.next) {
                        page++;
                        $scope.refresh();
                    }
                };

                $scope.refresh();

                var getRequestsCallback = function (data, changeUserPosition) {
                    $scope.searchRequest = data;

                    if (data.data.length === 0 && data.prev) {
                        $scope.prev();
                    }

                    if (!$scope.searchParam.requestsMap.isInitChangeMap) {
                        $scope.searchParam.requestsMap.isInitChangeMap = true;
                        $scope.searchParam.requestsMap.init(function () {
                            $scope.searchParam.requestsMap.map.addListener('center_changed', function () {
                                if ($scope.searchParam.changePosition) {
                                    changeMapCenter();
                                }
                            });
                        });
                    }
                    $scope.searchParam.requestsMap.clear();
                    addRequestMarkers(data.data, changeUserPosition);
                    $scope.loadingData = false;
                };

                var countChangeMapPosition = 0;
                var changeMapCenter = function () {
                    countChangeMapPosition++;
                    $timeout(function () {
                        countChangeMapPosition--;
                        if (countChangeMapPosition === 0) {
                            $scope.getRequests($scope.searchParam.requestsMap.map.center.lat(),
                                $scope.searchParam.requestsMap.map.center.lng());
                        }
                    }, 1000);
                };

                $scope.changePriceFrom = function () {
                    if ($scope.searchParam.priceFrom > $scope.searchParam.priceTo) {
                        $scope.searchParam.priceTo = $scope.searchParam.priceFrom;
                    }
                };

                $scope.changePriceTo = function () {
                    if ($scope.searchParam.priceFrom > $scope.searchParam.priceTo) {
                        $scope.searchParam.priceFrom = $scope.searchParam.priceTo;

                    }
                };

                $scope.changeTimeFrom = function () {
                    if ($scope.searchParam.timeFrom > $scope.searchParam.timeTo) {
                        $scope.searchParam.timeTo = $scope.searchParam.timeFrom;
                    }
                };

                $scope.changeTimeTo = function () {
                    if ($scope.searchParam.timeFrom > $scope.searchParam.timeTo) {
                        $scope.searchParam.timeFrom = $scope.searchParam.timeTo;
                    }
                };

                $scope.selectWeekday = function (index) {
                    var i = $scope.searchParam.selectedWeekdays.indexOf(index);
                    if (i < 0) {
                        $scope.searchParam.selectedWeekdays.push(index);
                    } else {
                        $scope.searchParam.selectedWeekdays.splice(i, 1);
                    }
                    $scope.search();
                };

                $scope.selectRadius = function (value) {
                    $scope.searchParam.currentRadius = value;
                    $scope.search();
                };

                $scope.selectExperience = function (value) {
                    $scope.searchParam.currentExperience = value;
                    $scope.search();
                };

                $scope.searchParam.requestsMap.onFocusRequest = function (id) {
                    for (var i = 0; i < $scope.searchRequest.data.length; i++) {
                        if ($scope.searchRequest.data[i].id === id) {
                            $scope.searchRequest.data[i].focusMarker = true;
                            return;
                        }
                    }
                };

                $scope.searchParam.requestsMap.offFocusRequest = function (id) {
                    for (var i = 0; i < $scope.searchRequest.data.length; i++) {
                        if ($scope.searchRequest.data[i].id === id) {
                            $scope.searchRequest.data[i].focusMarker = false;
                            return;
                        }
                    }
                };

                var addRequestMarkers = function (data, changeUserPosition) {
                    var user = AuthService.user();
                    if (changeUserPosition) {
                        if ($scope.searchParam.mechanicLocationPlace &&
                            $scope.searchParam.mechanicLocationPlace.geometry) {
                            $scope.searchParam.userLocation = {
                                lat: $scope.searchParam.mechanicLocationPlace.geometry.location.lat(),
                                lng: $scope.searchParam.mechanicLocationPlace.geometry.location.lng()
                            };
                        } else if ($scope.searchParam.mechanicLocation.address) {
                            var geocoder = new window.google.maps.Geocoder();
                            geocoder.geocode({'address': $scope.searchParam.mechanicLocation.address},
                                function (results, status) {
                                    if (status === window.google.maps.GeocoderStatus.OK) {
                                        $scope.searchParam.userLocation = {
                                            lat: results[0].geometry.location.lat(),
                                            lng: results[0].geometry.location.lng()
                                        };
                                    } else {
                                        if (user.locations && user.locations.length > 0) {
                                            $scope.searchParam.mechanicLocation = user.locations[0].address;
                                            $scope.searchParam.userLocation = {
                                                lat: user.locations[0].address.latitude,
                                                lng: user.locations[0].address.longitude
                                            };
                                        }
                                    }
                                });
                        } else {
                            if (user.locations && user.locations.length > 0) {
                                $scope.searchParam.mechanicLocation = user.locations[0].address;
                                $scope.searchParam.userLocation = {
                                    lat: user.locations[0].address.latitude,
                                    lng: user.locations[0].address.longitude
                                };
                            }
                        }
                    }

                    addUserPositionMarker();

                    for (var i = 0; i < data.length; i++) {
                        var message = data[i].taskName + (data[i].requestCost ? ' (' + data[i].requestCost + '$)' : '');
                        $scope.searchParam.requestsMap.addMarker(
                            data[i].serviceLocation.latitude,
                            data[i].serviceLocation.longitude,
                            message, null, data[i].id
                        );
                    }

                    if (changeUserPosition) {
                        $scope.searchParam.requestsMap.zoomAll();
                        $timeout(function () {
                            $scope.searchParam.changePosition = true;
                        }, 1000);
                    }
                };

                var addUserPositionMarker = function () {
                    if ($scope.searchParam.userLocation && $scope.searchParam.userLocation.lat) {
                        $scope.searchParam.requestsMap.addMarker(
                            $scope.searchParam.userLocation.lat,
                            $scope.searchParam.userLocation.lng,
                            'Your location', require('img/your-location.png')
                        );
                    }
                };

                $scope.hoverRequestIn = function (id) {
                    $scope.searchParam.requestsMap.hoverRequest(id);
                };

                $scope.hoverRequestOut = function (id) {
                    $scope.searchParam.requestsMap.unhoverRequest(id);
                };

            }
        ]
    };
}]);

