import app from "js/legacy-app";

app.controller('ImportBikesDialogController', ['$scope', '$mdDialog', 'FileService', 'organisation', 'Documents', 'Notification', 'ToggleLD',
    function ($scope, $mdDialog, FileService, organisation, Documents, Notification, ToggleLD) {

        $scope.importBikes = {
            disabledUpload: false
        };

        $scope.close = function () {
            $mdDialog.hide();
        };

        $scope.chooseFile = function (files, invalidFiles) {
            var file;
            if (files[0]) {
                file = files[0];
            } else if (invalidFiles[0]) {
                file = invalidFiles[0];
            }

            if (!FileService.isValidCsvFile(file)) {
                return;
            }

            $scope.importBikes.file = file;
        };

        $scope.import = function () {
            $scope.importBikes.disabledUpload = true;
            ToggleLD.show();
            Documents.importBikes().upload({
                    file: $scope.importBikes.file,
                    organisationId: organisation.organisationId,
                    stationId: organisation.stationId
                },
                function (data) {
                    $scope.importBikes.disabledUpload = false;
                    ToggleLD.hide();
                    Notification.success('Bicycles have been successfully imported');
                    $mdDialog.hide(data);
                }, function (error) {
                    $scope.importBikes.disabledUpload = false;
                    ToggleLD.hide();
                    Notification.error("Failed to import bicycles");
                    $mdDialog.hide();
                });
        };
    }
]);
