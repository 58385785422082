import app from "js/legacy-app";

app.filter("serviceFlow", [function () {
    return function (status) {
        switch (status) {
            case 'WHITE_GLOVE_DELIVERY':
                return 'WHITE GLOVE DELIVERY';
            case 'DEFAULT':
                return 'DEFAULT';
            default:
                return 'DEFAULT';
        }
    };
}]);