import app from "js/legacy-app";

app.service('Insurance', ['$resource', function ($resource) {
    return $resource('/api/insurance/:param1/:param2', {}, {
        getByUser: {
            method: "GET",
            params: {param1: "by-user"},
            isArray: false
        },
        getTypes: {
            method: "GET",
            params: {param1: "types"},
            isArray: true
        },
        update: {
            method: "PUT",
            params: {param1: ""},
            isArray: false
        },
        hasInsurance: {
            method: "GET",
            params: {param1: "has-insurance/check"},
            isArray: false
        }
    });
}]);
