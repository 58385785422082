import angular from "angular";

(function () {

    'use strict';

    function phoneNumber(PhoneService, $parse) {

        function link($scope, $element, $attrs) {
            var errorModel = $parse($attrs.ngPhoneNumberModel + ".$error");
            var numberModel = $parse($attrs.ngPhoneNumberModel + ".number");
            var countryModel = $parse($attrs.ngPhoneNumberModel + ".country");
            var defaultCountry = PhoneService.getCountryById('US');

            $scope.countries = (function () {
                return PhoneService.getCountries();
            })();

            $scope.$watch(numberModel, function (newValue) {

                if (!newValue) {
                    countryModel.assign($scope, defaultCountry);
                    numberModel.assign($scope, PhoneService.getNumberPrefix(defaultCountry.id));
                } else {
                    var country = PhoneService.getCountryById(PhoneService.getCountryFromNumber(newValue)) || defaultCountry;
                    countryModel.assign($scope, country);
                    numberModel.assign($scope, PhoneService.formNumber(country.id, newValue));
                }

                if ($attrs.ngPhoneNumberMinLength) {
                    errorModel.assign($scope, !PhoneService.validateNumber($scope.$eval(numberModel), $attrs.ngPhoneNumberMinLength));
                }

            });


            $scope.$watch(countryModel, function (newValue) {

                var phone,
                    prevCountry,
                    viewPhoneNumber,
                    selectedCountry;

                phone = $scope.$eval(numberModel);

                if (!newValue && !phone)
                    return;

                if (!newValue && phone) {
                    var country = PhoneService.getCountryById(PhoneService.getCountryFromNumber(phone)) || defaultCountry;
                    countryModel.assign($scope, country);
                }

                selectedCountry = $scope.$eval(countryModel);

                prevCountry = PhoneService.getCountryFromNumber(phone);
                viewPhoneNumber = PhoneService.formNumber(selectedCountry.id, phone, true, prevCountry);

                numberModel.assign($scope, viewPhoneNumber);

            });
        }

        return {
            restrict: 'A',
            link: link
        };
    }

    var app = angular.module('legacy-app');
    app.directive('ngPhoneNumber', ['PhoneService', '$parse', phoneNumber]);
}());