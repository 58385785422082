import app from "js/legacy-app";
import angular from "angular";

app.directive('customPersonalServiceView', ['$vtSidenav', '$location',
    function ($vtSidenav, $location) {
        return {
            templateUrl: "components/service/view/custom-personal-service-view.html",
            replace: true,
            restrict: 'E',
            transclude: true,
            scope: {
                onSave: '&cpsvOnSave',
                onDelete: '&cpsvOnDelete',
                onResetDefault: '&cpsvOnResetDefault',
                readOnly: '<cpsvReadOnly'
            },
            controller: ['$scope', '$routeParams', 'AuthService', 'CustomServiceManagement', 'OrganisationServiceManagement', 'ErrorHandler', 'SidenavLD', 'Notification', 'ModalService',
                function ($scope, $routeParams, AuthService, CustomServiceManagement, OrganisationServiceManagement, ErrorHandler, SidenavLD, Notification, ModalService) {

                    var misvc = {
                        service: AuthService.isMechanic() ? CustomServiceManagement : OrganisationServiceManagement
                    };

                    misvc.getParams = function (id) {
                        if (AuthService.isMechanic()) {
                            return {id: id};
                        } else {
                            let organisationId = $routeParams.organisationId;
                            return {
                                id: organisationId,
                                id1: id
                            };
                        }
                    };

                    misvc.get = function () {
                        return {
                            name: $scope.misvc.service.name,
                            description: $scope.misvc.service.description,
                            price: $scope.misvc.service.price,
                            mechanicPrice: $scope.misvc.service.mechanicPrice,
                            timeToComplete: $scope.misvc.service.timeToComplete,
                            autoaccept: $scope.misvc.service.autoaccept,
                            dealersOnly: $scope.misvc.service.dealersOnly
                        };
                    };

                    misvc.set = function (service) {
                        $scope.misvc.service = {};
                        $scope.misvc.service.id = service.id;
                        $scope.misvc.service.name = service.name;
                        $scope.misvc.service.state = service.state;
                        $scope.misvc.service.description = service.description;
                        $scope.misvc.service.price = service.price;
                        $scope.misvc.service.mechanicPrice = service.mechanicPrice;
                        $scope.misvc.service.timeToComplete = service.timeToComplete;
                        $scope.misvc.service.categoryId = service.categoryId;
                        $scope.misvc.service.canDelete = AuthService.isAdmin() ? false : !service.coreServiceId;
                        $scope.misvc.service.readOnly = AuthService.isAdmin() ? false : !!service.coreServiceId;
                        $scope.misvc.service.customized = service.customized;
                        $scope.misvc.service.autoaccept = service.autoaccept;
                        $scope.misvc.service.dealersOnly = service.dealersOnly;
                        $scope.misvc.showMechanicPrice = Number.isInteger(service.mechanicPrice) ? true : !!service.mechanicPrice;
                        $scope.misvc.showDealersOnly = !(typeof service.dealersOnly === 'undefined' || service.dealersOnly === null);
                    };

                    $scope.misvc = {};

                    $scope.misvc.onOpen = function (id, onSuccess, onError) {
                        return misvc.service.get(misvc.getParams(id), function (service) {
                            misvc.set(service);
                        }, function (error) {
                            ErrorHandler.onError(error);
                        }).$promise;
                    };

                    $scope.misvc.onClose = function () {
                        $location.search("serviceId", null);
                    };

                    $scope.misvc.save = function (onError) {
                        SidenavLD.show();
                        misvc.service.patch(misvc.getParams($scope.misvc.service.id), misvc.get(), function (data) {
                            SidenavLD.hide();
                            $vtSidenav("custom-personal-service-view").close();
                            $location.search("serviceId", null);
                            if (angular.isFunction($scope.onSave)) {
                                $scope.onSave({data: data});
                            }
                            Notification.success('Service was updated');
                        }, function (error) {
                            onError(error);
                            SidenavLD.hide();
                        });
                    };

                    $scope.misvc.deleteCustom = function (event) {
                        let message = `Are you sure you want to delete '${$scope.misvc.service.name}' service?`;
                        ModalService.yesNoMessage(event, null, message, function (response) {

                            if (!response) {
                                return;
                            }

                            misvc.delete();
                        });
                    };

                    misvc.delete = function () {
                        SidenavLD.show();
                        misvc.service.delete(misvc.getParams($scope.misvc.service.id), function () {
                            SidenavLD.hide();
                            $vtSidenav("custom-personal-service-view").close();
                            $location.search("serviceId", null);
                            if (angular.isFunction($scope.onDelete)) {
                                $scope.onDelete({data: $scope.misvc.service});
                            }
                            Notification.success('Service was deleted');
                        }, function (error) {
                            ErrorHandler.onError(error);
                            SidenavLD.hide();
                        });
                    }

                    $scope.misvc.resetDefaultCustom = function (event) {
                        let message = `Are you sure you want reset '${$scope.misvc.service.name}' service?`;
                        ModalService.yesNoMessage(event, null, message, function (response) {

                            if (!response) {
                                return;
                            }

                            misvc.resetDefault();
                        });
                    }

                    misvc.resetDefault = function () {
                        SidenavLD.show();
                        misvc.service.resetDefault(misvc.getParams($scope.misvc.service.id), {}, function (data) {
                            SidenavLD.hide();
                            $vtSidenav("custom-personal-service-view").close();
                            $location.search("serviceId", null);
                            if (angular.isFunction($scope.onResetDefault)) {
                                $scope.onResetDefault({data: data});
                            }
                            Notification.success('Service was reset');
                        }, function (error) {
                            ErrorHandler.onError(error);
                            SidenavLD.hide();
                        });
                    }
                }]
        };
    }]);
