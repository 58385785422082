import app from "js/legacy-app";

app.service('CoreServiceManagement', ['$resource', function ($resource) {
    return $resource('/api/core-service-management/:id/:id1', {}, {
        update: {
            method: "PUT",
            params: {id: ""},
            isArray: false
        },
        getFlows: {
            method: "GET",
            params: {id: "flows"},
            isArray: true
        },
        autocomplete: {
            method: "GET",
            isArray: true,
            params: {id: "autocomplete"}
        },
        updateActivityFlag: {
            method: "PUT",
            params: {id1: "activity"},
            isArray: false
        }
    });
}]);