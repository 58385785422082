import app from "js/legacy-app";
import angular from 'angular';

app.directive('changeServicePopularityAction', [
    function () {
        return {
            templateUrl: "components/service/actions/change-service-popularity-action.html",
            replace: true,
            scope: {
                model: "<cspModel",
                onChangeState: "&cspOnChangeState"
            },
            controller: ['$scope', 'CustomServiceManagement', 'ToggleLD', 'ErrorHandler',
                function ($scope, CustomServiceManagement, ToggleLD, ErrorHandler) {

                    $scope.cspa = {};

                    $scope.cspa.togglePopularity = function () {
                        ToggleLD.show();
                        CustomServiceManagement.updatePopularityFlag(
                            {
                                id: $scope.model.id
                            },
                            {
                                value: !$scope.model.popular
                            },
                            function () {
                                if (angular.isFunction($scope.onChangeState)) {
                                    $scope.onChangeState({
                                        service: $scope.model,
                                        popularityFlag: !$scope.model.popular
                                    });
                                }
                                ToggleLD.hide();
                            }, function (error) {
                                ToggleLD.hide();
                                ErrorHandler.onError(error);
                            });
                    };

                }]
        };
    }]);