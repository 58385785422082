/**
 * Created by eugineer on 10/24/16.
 */
import app from "js/legacy-app";
import angular from "angular";

app.service('tooltipHandler', ['$window', '$bodyHandler', '$timeout', function ($window, $bodyHandler, $timeout) {

    var checkElement = function(element, attr) {
        var current = element;
        while (current != window && current.nodeName != '#document') {
            if (current.hasAttribute(attr)) {
                return true;
            }
            current = current.parentNode;
        }

        return false;
    };

    var checkElementByClass = function(element, className) {
        var current = element;
        while (current != window && current.nodeName != '#document') {
            if (current.classList && current.classList.contains(className)) {
                return true;
            }
            current = current.parentNode;
        }

        return false;
    };

    var tooltipElements = [],
        tooltipPopup = angular.element('<div id="tooltip"></div>'),
        checkedMdDialogContainer = false,
        onTooltipHide = function (event) {
            // if tooltip is invisible
            if (!tooltipPopup.hasClass("tooltip_visible")) {
                return;
            }

            // if tooltip element was triggered this event
            if (event.currentTarget.hasOwnProperty('tooltip')) {
                return;
            }

            // if tooltip was triggered this event
            if (checkElementByClass(event.target, "tooltip_visible")) {
                return;
            }

            // if child tooltip element was triggered this event
            if (checkElement(event.target, 'tooltip')) {
                return;
            }

            tooltipPopup
                .removeClass("tooltip_visible")
                .text('')
                .css({
                    top: 0,
                    left: 0
                });
        },
        onTooltipShow = function (event, text) {
            if (tooltipPopup[0].classList.contains('tooltip_visible')) {
                return;
            }

            tooltipPopup.text(text);

            var left = 0;
            if ( event.clientX >= 150 ){
                if ( (window.innerWidth - event.clientX) >= 150 ){
                    left = event.clientX - 150;
                } else {
                    left = window.innerWidth - (150 + 150);
                }
            } else {
                left = 0;
            }

            tooltipPopup
                .css({
                    top:  event.clientY   + 'px',
                    left: left  + 'px',
                    visibility: 'hidden'
                })
                .addClass("tooltip_visible");

            $timeout(function () {
                var tooltipElement = tooltipPopup[0],
                    bottom = tooltipElement.offsetTop + tooltipElement.offsetHeight;

                if (window.innerHeight < bottom) {
                    tooltipElement.style.top = (tooltipElement.offsetTop - (bottom - window.innerHeight) - 10) + 'px';
                }

                tooltipPopup.css({ visibility: 'visible' });
            });
        },
        setTooltip = function (element, text) {
            var tooltipElement = {
                element: element,
                text: text
            };

            if (tooltipElements.length === 0) {
                angular.element(document.body).append(tooltipPopup);
                $window.onscroll = onTooltipHide;
                $window.onresize = onTooltipHide;
            }

            tooltipElement.element.on('mousemove', function (event) {
                onTooltipShow(event, text);
            });
            tooltipElement.element.on('mouseout', function (event) {
                onTooltipHide(event);
            });
            $bodyHandler.add(tooltipElement.element, onTooltipHide);

            tooltipElements.push(tooltipElement.element);
        },
        onElementDestruction = function (element) {
            var index = tooltipElements.indexOf(element);
            if (index >= 0) {
                tooltipElements.splice(index, 1);
            }

            if (tooltipElements.length === 0) {
                angular.element(document.getElementById("tooltip")).remove();
                checkedMdDialogContainer = false;
                $window.removeEventListener('onscroll', onTooltipHide);
                $window.removeEventListener('onresize', onTooltipHide);
            }

            $bodyHandler.remove(element);
        };

    return {
        setTooltip: setTooltip,
        onTooltipHide: onTooltipHide,
        onElementDestruction: onElementDestruction,
        checkedMdDialogContainer: checkedMdDialogContainer
    };
}]);