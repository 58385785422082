import app from "js/legacy-app";

app.service('UserRequest', ['$resource', function ($resource) {
    return $resource('/api/user-request/:id/:id1/:id2', {}, {
        history: {
            method: "GET",
            params: {id: "history/list"},
            isArray: false
        },
        updateLocation: {
            method: "PUT",
            params: {id1: "update-location"},
            isArray: false
        },
        setRating: {
            method: "PUT",
            params: {id1: "set-rating"},
            isArray: false
        },
        cancel: {
            method: "PUT",
            params: {id1: "cancel"},
            isArray: false
        },
        notSatisfied: {
            method: "PUT",
            params: {id1: "unsatisfied"},
            isArray: false
        },
        updateDescription: {
            method: "PUT",
            params: {id1: "update-description"},
            isArray: false
        },
        checkCancellationWithCharges: {
            method: "GET",
            params: {id1: "check-cancellation-with-charges"},
            isArray: false
        },
        updateAppointmentDate: {
            method: "PUT",
            params: {id1: "appointment-date"},
            isArray: false
        },
        confirmAppointmentDate: {
            method: "PUT",
            params: {id1: "appointment-date/confirm"},
            isArray: false
        },
        confirmAssignedBooking: {
            method: "PUT",
            params: {id1: "confirm-assigned-booking"},
            isArray: false
        },
        reassign: {
            method: "PUT",
            params: {id1: "reassign"},
            isArray: false
        }
    });
}]);