/**
 * Created by eugineer on 2/24/17.
 */
import app from "js/legacy-app";

app.filter ('cardMonthFilter', function () {
    return function (monthNumber) {
        if ( isNaN(monthNumber) ) {
            return monthNumber;
        }

        if (monthNumber > 0 && monthNumber < 10 ) {
            monthNumber = "0" + monthNumber;
        }
        return monthNumber;
     };
});
