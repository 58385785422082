import app from "js/legacy-app";

app.service('$multiBrowser', ['$rootScope', '$location', function ($rootScope, $location) {

    $rootScope.isIE = function () {
        return window.navigator.userAgent.indexOf('MSIE ') > 0;
    };

    var ua = navigator.userAgent.toLowerCase(), oldSafariDetect = false;
    if (ua.indexOf('safari') != -1) {
        if (ua.indexOf('chrome') > -1) {
            oldSafariDetect = false;
        } else {
            oldSafariDetect = true;
        }

        var splitVersion = navigator.appVersion.split("Version/");
        if (splitVersion && splitVersion.length > 1) {
            if (parseInt(splitVersion[1].split(' ')[0]) < 9 && oldSafariDetect) {
                oldSafariDetect = true;
            } else {
                oldSafariDetect = false;
            }
        } else {
            oldSafariDetect = false;
        }
    }

    $rootScope.getHeightSafariClass = function () {
        if (oldSafariDetect) {
            return 'safari-height-vh';
        }

        return '';
    };

    $rootScope.getMinHeightSafariClass = function () {
        if (oldSafariDetect) {
            return 'safari-min-height-vh';
        }

        return '';
    };

    $rootScope.getBodyClass = function () {
        if (navigator.appName.indexOf('Explorer') > -1) {
            return 'ie-body-overflow';
        }

        return '';
    };

}]);
