import app from "js/legacy-app";
import defaultDocumentImg from 'img/card-back-blank.png';

app.controller('UploadVerificationDocumentCtrl', ['$scope', 'AuthService',
    'User', '$location', 'Notification', 'ToggleLD', 'FileService', 'Upload', 'Documents',
    function ($scope, AuthService, User,
              $location, Notification, ToggleLD, FileService, Upload, Documents) {

        $scope.uploadDocument = {
            showImg: true,
            type: 'DRIVERS_LICENSE'
        };

        AuthService.currentUser(function () {
            $scope.user = AuthService.user();
        });

        $scope.refresh = function () {
            User.getVerification(function (data) {
                $scope.verification = data;

                $scope.uploadDocument.showImg = false;
                if ($scope.verification && $scope.verification.identityVerification) {
                    $scope.uploadDocument.defaultImg = $scope.verification.identityVerification.thumbnail;
                    if ($scope.verification.identityVerification.type) {
                        $scope.uploadDocument.type = $scope.verification.identityVerification.type;
                    }
                }
                if (!$scope.uploadDocument.defaultImg) {
                    $scope.uploadDocument.defaultImg = defaultDocumentImg;
                }
                $scope.uploadDocument.showImg = true;
            });
        };

        $scope.chooseFile = function (files, invalidFiles) {
            var file;
            if (files[0]) {
                file = files[0];
            } else if (invalidFiles[0]) {
                file = invalidFiles[0];
            }

            if (!FileService.isValidFile(file)) {
                return;
            }

            $scope.uploadDocument.document = file;
        };

        $scope.upload = function () {

            save();
        };

        var save = function () {
            ToggleLD.show();
            $scope.verification.identityVerification = {
                type: $scope.uploadDocument.type,
                document: $scope.uploadDocument.document
            };

            Documents.idVerification().upload($scope.verification.identityVerification, function () {
                ToggleLD.hide();
                Notification.success("The document has been successfully uploaded");
                $location.path("/dashboard");
            }, function () {
                ToggleLD.hide();
                onUploadError();
            });
        };

        var onUploadError = function () {
            ToggleLD.hide();
            Notification.error("Failed to upload the document");
            $scope.uploadDocument.disabledUpload = false;
        };

        $scope.refresh();

    }
])
;