import app from "js/legacy-app";

app.service("SidenavLD", [function () {

    var self = this;
    self.ld = {
        show: false
    };

    var get = function () {
        return self.ld;
    };

    var toggle = function () {
        self.ld.show = !self.ld.show;
    };

    var show = function () {
        self.ld.show = true;
    };

    var hide = function () {
        self.ld.show = false;
    };

    return {
        get: get,
        toggle: toggle,
        show: show,
        hide: hide
    };
}]);
