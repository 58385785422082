import app from "js/legacy-app";
import angular from "angular";

app.directive('plLocationFilter', ['$location', 'ErrorHandler', '$timeout', 'AuthService',
    function ($location, ErrorHandler, $timeout, AuthService) {
        return {
            template: '<div class="pageable-list__filter-field">' +
            '               <location-autocomplete' +
            '                   class="location-autocomplete_with-max-padding"' +
            '                   la-label="{{ label }}"' +
            '                   la-model="lf.selected"' +
            '                   la-query="lf.query"' +
            '                   la-set-up-fn="setUpFn"' +
                '                   la-dropdown-position="{{ dropdownPosition }}"' +
            '                   la-no-favorite="noFavorite"' +
            '                   la-on-location-change="lf.onItemChange"' +
            '                   la-detect-current-location="true">' +
            '               </location-autocomplete>' +
            '          </div>',
            replace: true,
            require: '^^plFilter',
            scope: {
                name: '@lfName',
                label: '@lfLabel',
                selected: '=?lfSelected',
                setUpFn: '=?lfSetUpFn',
                options: '=lfOptions',
                dropdownPosition: '@?lfDropdownPosition',
                dependsOn: "@lfDependsOn",
                onChange: "=lfOnChange",
                applyFn: "=lfApplyFn",
                noFavorite: "=?lfNoFavorite"
            },
            controller: ['$scope', function ($scope) {
                $scope.lf = {};
            }],
            link: function ($scope, element, $attrs, plFilter) {
                $scope.lf.filter = plFilter.filter;

                $scope.lf.filter.register($scope.name, $scope.lf);

                $scope.lf.onItemChange = function () {
                    $scope.selected = $scope.lf.getValue();
                    $timeout(function () {
                        if (!$scope.lf.locked) {
                            if (angular.isFunction($scope.applyFn)) {
                                $scope.applyFn($scope.lf.getValue(), function () {
                                    $scope.lf.filter.apply();
                                });
                            } else {
                                $scope.lf.filter.apply();
                            }

                            if (angular.isFunction($scope.onChange)) {
                                $scope.onChange($scope.lf.getValue());
                            }
                        }
                        unlock();
                    });
                };

                $scope.lf.isSelected = function () {
                    return !!$scope.lf.selected;
                };

                $scope.lf.getValue = function () {
                    return $scope.lf.isSelected() ? $scope.lf.selected : null;
                };

                $scope.lf.serialize = function () {
                    let serialized = {};
                    serialized[$scope.name] = $scope.lf.isSelected() ? serializeAddress($scope.lf.getValue()) : null;
                    return serialized;
                };

                $scope.lf.deserialize = function (queryString) {
                    if (queryString[$scope.name]) {
                        let deserialized = deserializeAddress(queryString[$scope.name]);
                        $scope.lf.selected = deserialized;
                        $scope.selected = deserialized;
                        $scope.lf.query = deserialized.address;
                    }
                };

                $scope.lf.clear = clear;
                $scope.lf.init = init;
                $scope.lf.lock = lock;
                $scope.lf.unlock = unlock;

                function lock() {
                    $scope.lf.locked = true;
                }

                function unlock() {
                    $scope.lf.locked = false;
                }

                function clear() {
                    $scope.lf.selected = null;
                    $scope.lf.query = "";
                }

                function serializeAddress(address) {
                    let result = "";
                    result += (address.address ? address.address : "") + ";";
                    result += (address.zip ? address.zip : "") + ";";
                    result += (address.city ? address.city : "") + ";";
                    result += (address.state ? address.state : "") + ";";
                    result += (address.country ? address.country : "") + ";";
                    result += address.longitude + ";";
                    result += address.latitude + ";";
                    result += (address.bounds ? address.bounds.east : "") + ";";
                    result += (address.bounds ? address.bounds.north : "") + ";";
                    result += (address.bounds ? address.bounds.south : "") + ";";
                    result += (address.bounds ? address.bounds.west : "");

                    return result;
                }

                function deserializeAddress(string) {
                    let parts = string.split(';'), result = {};

                    if (parts.length !== 11) {
                        return null;
                    }

                    result.address = parts[0];
                    result.zip = parts[1];
                    result.city = parts[2];
                    result.state = parts[3];
                    result.country = parts[4];
                    result.longitude = parts[5];
                    result.latitude = parts[6];

                    if (parts[7] && parts[8] && parts[9] && parts[10]) {
                        result.bounds = {};
                        result.bounds.east = parts[7];
                        result.bounds.north = parts[8];
                        result.bounds.south = parts[9];
                        result.bounds.west = parts[10];
                    }


                    return result;
                }

                function init() {
                    $scope.lf.deserialize($location.search());
                    if (!$scope.lf.isSelected() && $scope.options && $scope.options.initCurrentUserLocation) {
                        let user = AuthService.user();
                        if (user && user.address) {
                            $scope.lf.selected = user.address;
                            $scope.lf.query = $scope.lf.getValue().address;
                            $scope.lf.filter.initialized($scope.name);
                        } else {
                            $scope.lf.filter.initialized($scope.name);
                        }
                    } else if ($scope.lf.isSelected()) {
                        $scope.lf.query = $scope.lf.getValue().address;
                        $scope.lf.filter.initialized($scope.name);
                    } else {
                        $scope.lf.filter.initialized($scope.name);
                    }

                    if ($scope.dependsOn) {
                        $scope.$watch('lf.filter.filters.' + $scope.dependsOn + '.getValue()', function (newVal, oldVal) {
                            if (newVal !== oldVal) {
                                clear();
                            }
                        });
                    }
                }

                init();
            }
        };
    }]);