export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/oauth/grant', 'grant')
        .when('/oauth/error', 'error')

        .segment('grant', {
            template: '<oauth-grant-page></oauth-grant-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "oauth-grant.module" */ "./grant/oauth-grant.module");
            }
        })
        .segment('error', {
            template: '<oauth-error-page></oauth-error-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "oauth-error.module" */ "./error/oauth-error.module");
            }
        });
}
