import app from "js/legacy-app";
import angular from "angular";
import noUserAvatarImg from 'img/user-no-avatar.png';

app.controller("ShowRequestCtrl", [
    "$window",
    '$scope',
    '$routeParams',
    '$location',
    'ModalService',
    'Notification',
    '$filter',
    'AuthService',
    '$timeout',
    'Tools',
    'User',
    '$rootScope',
    '$q',
    '$mdDialog',
    '$dateTimeService',
    'ToggleLD',
    'Images',
    'Documents',
    'Brand',
    'SaleCertificateService',
    '$bikeComponentService',
    '$temporaryStorage',
    'Organisation',
    'ErrorHandler',
    '$requestService',
    'ServiceExecutionFlow',
    '$routeSegment',
    'LocationService',
    'NotificationWSService',
    '$serviceCoverageChecker',
    'MechanicSchedule',
    '$mdMedia',
    '$bookingCancellationService',
    'HistoryService',
    '$route',
    function (
        $window, $scope, $routeParams, $location, ModalService, Notification, $filter, AuthService, $timeout,
        Tools, User, $rootScope, $q, $mdDialog, $dateTimeService, ToggleLD, Images, Documents, Brand,
        SaleCertificateService, $bikeComponentService, $temporaryStorage, Organisation, ErrorHandler, $requestService,
        ServiceExecutionFlow, $routeSegment, LocationService, NotificationWSService, $serviceCoverageChecker,
        MechanicSchedule, $mdMedia, $bookingCancellationService, HistoryService, $route
    ) {

        var COUNT_BIKES = 5;

        var srCtrl = {
            tabs: {
                info: {name: 'info', index: 0},
                negotiation: {name: 'negotiation', index: 1},
                flow: {name: 'flow', index: 2},
                history: {name: 'history', index: 3}
            },

            localOffset: $dateTimeService.getLocalOffset() || 0
        };

        $scope.srCtrl = {
            noUserAvatarImg: noUserAvatarImg,
            auth: AuthService,
            selectedTab: 0,
            scheduledNegotiation: null,
            mdMedia: $mdMedia
        };

        $scope.certificationNotes = '';
        $scope.bike = {
            components: []
        };
        $scope.showRequest = {
            map: {
                options: {
                    center: {lat: 41.742295, lng: -73.355499}
                },

                markers: [{
                    position: {lat: 41.742295, lng: -73.355499},
                    title: 'Service location',
                    icon: require('img/breaking-dot.png')
                }]
            },
            locationPlace: {
                utc_offset: $dateTimeService.getLocalOffset()
            },
            isAdmin: AuthService.isAdmin(),
            isMechanic: AuthService.isMechanic(),
            isUser: AuthService.isUser(),
            isBusinessAccount: AuthService.isBikeOrganisationOwner() || AuthService.isBikeBusinessSupervisor(),
            userTools: [],
            rateDialogIsBeingDisplayed: false,
            activeNegotiations: [],
            negotiationHistory: [],
            id: $routeParams.id,
            countVisibleBikes: COUNT_BIKES
        };
        $scope.positionImages = {};
        $scope.disabledDescription = true;

        $scope.timeOptions = {
            floor: 700,
            ceil: 2300,
            step: (100 / 12),
            translate: $dateTimeService.translateTime
        };
        $scope.currentDate = new Date();
        $scope.disableChangeLocationBtn = false;

        var requestResource = $requestService.getRequestResource(AuthService);

        function onError(error) {
            ToggleLD.hide();
            if (error.status) {
                ErrorHandler.onErrorDialog(error).then(function () {
                    $location.path('/dashboard');
                });
            }
        }

        srCtrl.getRequest = function (excludedProperties) {
            if ($scope.showRequest.isMechanic && $routeSegment.name === 'request.history') {
                return requestResource.findOneFromHistory({id: $scope.showRequest.id}, function (data) {
                    $scope.setRequest(data, excludedProperties);
                }).$promise;
            } else {
                return requestResource.get({id: $scope.showRequest.id}, function (data) {
                    $scope.setRequest(data, excludedProperties);
                }).$promise;
            }
        };

        if ($scope.showRequest.isMechanic) {
            User.tools(function (data) {
                if (data) {
                    $scope.showRequest.userTools = data;
                }
            }, function (error) {
                ErrorHandler.onError(error);
            });
        }

        function setRatingInternal(data, negotiationId) {
            requestResource.setRating({id: $scope.showRequest.id, id2: negotiationId}, data,
                function (response) {
                    ToggleLD.hide();
                    $scope.setRequest(response);
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onErrorDialog(error);
                });
        }

        function forwardToHistory(requestId) {
            $location.path("/request/" + requestId + "/history");
        }

        function setRatingByCustomer(negotiation) {
            if (!negotiation.userRated) {
                $timeout(function () {
                    ModalService.rateTheMechanic(negotiation, function (data) {
                        if (!data) return;

                        if (data && data.status === "UNSATISFIED") {
                            ModalService.consultationMessage(null, 'By choosing the "I did not like it" option ' +
                                'you are claiming that<br> the service the mechanic provided did not meet your expectations. <br> ' +
                                'We want you to be happy with any bike service provided by our mechanics. <br> <br>' +
                                'Are you sure you want to choose "I did not like it" option?', function (response) {
                                if (response === true) {
                                    ToggleLD.show();
                                    delete data.status;
                                    $scope.showRequest.rateDialogIsBeingDisplayed = false;
                                    notSatisfiedWithService(data, negotiation.id);
                                }
                            });
                        } else {
                            $scope.showRequest.rateDialogIsBeingDisplayed = false;
                            ToggleLD.show();
                            setRatingInternal(data, negotiation.id);
                        }
                    });
                });
            }
        }

        function setRatingByMechanic(negotiation) {
            if (!negotiation.mechanicRated) {
                $timeout(function () {
                    ModalService.rateTheUser(function (data) {
                        $scope.showRequest.rateDialogIsBeingDisplayed = false;
                        if (data) {
                            ToggleLD.show();
                            setRatingInternal(data, negotiation.id);
                        }
                    });
                });
            }
        }

        function setRating() {
            var negotiation = $scope.showRequest.request.negotiations[0];
            if ($scope.showRequest.request.status === 'RESOLVED' && !$scope.showRequest.rateDialogIsBeingDisplayed) {
                if (!$scope.showRequest.isMechanic &&
                    $scope.showRequest.canSetRatingAsBikeOwner() &&
                    negotiation.permissions.indexOf('SET_REQUEST_RATING') !== -1) {
                    setRatingByCustomer(negotiation);
                } else if ($scope.showRequest.isMechanic && negotiation.permissions.indexOf('SET_REQUEST_RATING') !== -1) {
                    setRatingByMechanic(negotiation);
                }
                $scope.showRequest.rateDialogIsBeingDisplayed = true;
            }
        }

        function notSatisfiedWithService(data, negotiationId) {
            requestResource.notSatisfied({id: $scope.showRequest.id, id2: negotiationId}, data,
                function (response) {
                    ToggleLD.hide();
                    $scope.setRequest(response);
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onErrorDialog(error);
                });
        }

        function handleCommentsFromMechanic() {
            if ($scope.showRequest.request.negotiations) {
                for (var i = 0; i < $scope.showRequest.request.negotiations.length; i++) {
                    var negotiation = $scope.showRequest.request.negotiations[i];
                    if (negotiation.commentFromMechanic) {
                        while (negotiation.commentFromMechanic.indexOf('\n') > -1) {
                            negotiation.commentFromMechanic = negotiation.commentFromMechanic.replace('\n', '<br>');
                        }
                    }

                    var bikes = negotiation.fixedBikes;
                    if (bikes && bikes.length > 0) {
                        for (var j = 0; j < bikes.length; j++) {
                            if (bikes[j].commentFromMechanic) {
                                while (bikes[j].commentFromMechanic.indexOf('\n') > -1) {
                                    bikes[j].commentFromMechanic = bikes[j].commentFromMechanic.replace('\n', '<br>');
                                }
                            }
                        }
                    }
                }
            }
        }

        var calculateNegotiationTabName = function (request) {
            var mechanic = AuthService.isMechanic();

            if (mechanic && request.permissions.indexOf('ADD_NEGOTIATION') !== -1) {
                return "Date & Time";
            }

            if (AuthService.isAdmin() || $scope.showRequest.activeNegotiations[0] &&
                $scope.showRequest.activeNegotiations[0].permissions.indexOf('SEND_CHAT_MESSAGE') !== -1) {
                return "Messages";
            }

            if (!mechanic && (request.permissions.indexOf('READ_NEGOTIATIONS') !== -1 || AuthService.isAdmin()) && request.status === 'OPEN') {
                return "Offers";
            }

            return false;
        };

        function updateRequestIdRouteParam(requestId) {
            if ($routeParams.id !== requestId) {
                $route.updateParams({id: requestId});
            }
        }

        $scope.setRequest = function (request, excludedProperties) {
            updateRequestIdRouteParam(request.id);

            var showChats = [];
            var i, isOnline;

            $scope.showRequest.activeNegotiations = [];
            $scope.showRequest.negotiationHistory = [];


            if ($scope.showRequest.request && $scope.showRequest.request.negotiations) {
                for (i = 0; i < $scope.showRequest.request.negotiations.length; i++) {

                    var negotiation = $scope.showRequest.request.negotiations[i];

                    showChats.push({
                        id: negotiation.id,
                        showChatPanel: negotiation.showChatPanel
                    });
                }
            }

            var timeToComplete = 15;

            if (request.task.timeToComplete) {
                timeToComplete = request.task.timeToComplete;
            }

            timeToComplete = (timeToComplete / 60) * 100;
            $scope.timeOptions.minRange = timeToComplete;
            $scope.showRequest.request = $scope.showRequest.request || {};
            copyRequest(request, $scope.showRequest.request, excludedProperties);
            $scope.showRequest.request.deadline = new Date($scope.showRequest.request.deadline);
            $scope.radio.checked = -1;
            var countEnabledTime = 0;

            setRating();
            handleCommentsFromMechanic();

            if (showChats && $scope.showRequest.request.negotiations && showChats.length > 0) {
                for (i = 0; i < $scope.showRequest.request.negotiations.length; i++) {
                    for (var j = 0; j < showChats.length; j++) {
                        if (showChats[j].id === $scope.showRequest.request.negotiations[i].id) {
                            $scope.showRequest.request.negotiations[i].showChatPanel = showChats[j].showChatPanel;
                        }
                    }
                }
            } else if ($scope.showRequest.request.negotiations && $scope.showRequest.request.negotiations.length === 1) {
                $scope.showRequest.request.negotiations[0].showChatPanel = true;
            }

            if ($scope.showRequest.request) {
                getLocationOffset($scope.showRequest.request.serviceLocation).then(function (locationOffset) {
                    $scope.isOnline = isOnline = $scope.showRequest.request.task.online;

                    if ($scope.showRequest.request.negotiations) {
                        $scope.showRequest.request.negotiations.forEach(function (negotiation) {
                            if (negotiation.dateRange) {

                                var from = $dateTimeService.getTimeByOffset(locationOffset, negotiation.dateRange.from);
                                var to = $dateTimeService.getTimeByOffset(locationOffset, negotiation.dateRange.to);

                                if (AuthService.isMechanic() && isOnline) {
                                    from = $dateTimeService.getTimeByOffset(srCtrl.localOffset, negotiation.dateRange.from);
                                    to = $dateTimeService.getTimeByOffset(srCtrl.localOffset, negotiation.dateRange.to);
                                }
                                negotiation.dateRange.from = from;
                                negotiation.dateRange.to = to;
                            }

                            if (!AuthService.isMechanic() && negotiation.appointmentDateConfirmation && negotiation.appointmentDateConfirmation.lastRejectedDateRange) {
                                let lastRejectedDateRange = negotiation.appointmentDateConfirmation.lastRejectedDateRange;
                                from = $dateTimeService.getTimeByOffset(locationOffset, lastRejectedDateRange.from);
                                to = $dateTimeService.getTimeByOffset(locationOffset, lastRejectedDateRange.to);

                                negotiation.appointmentDateConfirmation.lastRejectedDateRange.from = from;
                                negotiation.appointmentDateConfirmation.lastRejectedDateRange.to = to;
                            }

                            if (negotiation.status === 'IN_PROGRESS' ||
                                negotiation.status === 'PENDING' ||
                                negotiation.status === 'AWAITING_CONFIRMATION') {
                                $scope.showRequest.activeNegotiations.push(negotiation);

                            } else {
                                $scope.showRequest.negotiationHistory.push(negotiation);
                            }
                        });
                    }

                    $scope.srCtrl.scheduledNegotiation = $scope.showRequest.request.negotiations.filter(function (n) {
                        return n.status === 'IN_PROGRESS' || n.status === 'AWAITING_CONFIRMATION';
                    })[0];

                    $scope.showRequest.negotiationTabName = calculateNegotiationTabName(request);
                    $scope.disableAcceptJob = countEnabledTime === 0;
                    setBikes($scope.showRequest.request.bikes);
                    srCtrl.getTabFromRouteParams();
                    srCtrl.showConfirmationAppointmentDateDialogIfNeeded($scope.showRequest.request);
                    srCtrl.showConfirmationAdditionalBookingDialogIfNeeded($scope.showRequest.request);
                });
            }
        };

        let getLocationOffset = (location) => {
            let locationOffset = $scope.showRequest.request.serviceLocation.offset;
            const deferred = $q.defer();

            if (!locationOffset) {
                LocationService.getLocationOffset($scope.showRequest.request.serviceLocation, function (offset) {
                    if (isNaN(offset)) {
                        deferred.resolve(0);
                    } else {
                        deferred.resolve(offset);
                    }
                })
            } else {
                deferred.resolve(locationOffset);
            }

            return deferred.promise;
        };

        $scope.srCtrl.editAppointmentDate = function (event) {

            var query = {
                mechanicId: $scope.showRequest.request.negotiations[0].user.id,
                lat: $scope.showRequest.request.serviceLocation.latitude,
                lng: $scope.showRequest.request.serviceLocation.longitude,
                duration: $scope.showRequest.request.task.timeToComplete,
                detalization: 'L'
            };

            ToggleLD.show();
            return MechanicSchedule.getAvailability(query, function (response) {
                $scope.showRequest.availability = angular.extend({}, query);
                $scope.showRequest.availability.dates = response.dates;

                ToggleLD.hide();
                ModalService.editRequestAppointments(
                    $scope.showRequest.availability,
                    response.weeklyAvailability,
                    response.vacationPeriod,
                    response.autoacceptBookingEnabled
                ).then(function (data) {
                    if (!data) return;
                    ToggleLD.show();
                    srCtrl.updateAppointmentDate(data);
                }, function (error) {
                    ErrorHandler.onError(error);
                });
            }, function (error) {
                ToggleLD.hide();
                ErrorHandler.onError(error);
            }).$promise;
        };

        $scope.srCtrl.declineAppointmentDate = function ($event) {
            $scope.cancelRequest();
        };

        $scope.srCtrl.declineAdditionalBooking = function ($event) {
            $scope.cancelRequest();
        };

        $scope.srCtrl.offerAppointmentDate = function ($event) {
            $scope.srCtrl.editAppointmentDate();
        };

        $scope.srCtrl.agreeAppointmentDate = function ($event) {
            srCtrl.agreeAppointmentDate();
        };

        $scope.srCtrl.cancellationPolicy = () => {
            let request = $scope.showRequest.request;

            if (!request || !request.cancellationInfo) {
                return;
            }

            return $bookingCancellationService.getCancellationPolicy(request.cancellationInfo.cancellationRules);
        };

        $scope.srCtrl.retryTransfer = function ($event) {
            $scope.retryTransfer();
        };

        srCtrl.updateAppointmentDate = function (date) {
            requestResource.updateAppointmentDate({id: $scope.showRequest.id}, date,
                function (data) {
                    $scope.setRequest(data);
                    ToggleLD.hide();
                    Notification.success("Appointment date was changed");
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onErrorDialog(error);
                });
        };

        $scope.resolved = function ($event) {
            ModalService.consultationMessage(null, 'By resolving this service you confirm that you ' +
                'successfully completed the service. <br>', function (response) {
                if (response === true) {
                    if (!$scope.showRequest.request.group || $scope.showRequest.request.bikes.length <= 1) {
                        var services = $scope.showRequest.request.task.additionalServices;
                        ModalService.leaveRequestReport($event, services, function (data) {
                            if (!data) return;

                            resolve(data);
                        });
                    } else {
                        resolve();
                    }
                }
            });
        };

        function resolve(data) {
            ToggleLD.show();
            requestResource.resolve({
                id: $scope.showRequest.request.id,
                id2: $scope.showRequest.request.negotiations[0].id
            }, data || {}, function (data) {
                $scope.setRequest(data);
                ToggleLD.hide();
            }, function (error) {
                refreshRequest(error);
            });
        }

        $scope.close = function (event) {
            ModalService.showUnableToFixReasonDialog(event).then(function (report) {
                if (!report) {
                    return;
                }

                ToggleLD.show();
                requestResource.couldNotFix({
                    id: $scope.showRequest.request.id,
                    id2: $scope.showRequest.request.negotiations[0].id
                }, report || {}, function () {
                    ToggleLD.hide();
                    forwardToHistory($scope.showRequest.request.id);
                }, function (error) {
                    refreshRequest(error);
                });
            });
        };

        function refreshRequest(error) {
            requestResource.get({id: $scope.showRequest.request.id},
                function (data) {
                    onSuccess(data, error);
                }, function (err) {
                    onError(err, error);
                });
        }

        function onSuccess(data, error) {
            $scope.setRequest(data);
            ToggleLD.hide();
            if (error) {
                ErrorHandler.onErrorDialog(error);
            }
        }


        function setBikes(bikes) {
            $scope.showRequest.allBikes = bikes;
            searchBike($scope.showRequest.allBikes);
        }

        function searchBike(bikes, sn) {
            sn = sn || $scope.showRequest.serialNumber;
            if (sn) {
                sn = sn.toUpperCase();
            }
            var filteredBikes = [];
            for (var i = 0; i < bikes.length; i++) {
                if (!sn || (bikes[i].serialNumber && bikes[i].serialNumber.indexOf(sn) !== -1)) {
                    filteredBikes.push(bikes[i]);
                }
            }
            $scope.showRequest.filteredBikes = filteredBikes;

            if (sn) {
                $scope.showRequest.countVisibleBikes = filteredBikes.length;
            } else if ($scope.showRequest.pagingBikes && bikes.length === $scope.showRequest.pagingBikes.length) {
                $scope.showRequest.countVisibleBikes = bikes.length;
            } else {
                $scope.showRequest.countVisibleBikes = COUNT_BIKES;
            }

            $scope.showRequest.pagingBikes = [];
            pagingBikes($scope.showRequest.filteredBikes, $scope.showRequest.countVisibleBikes);
        }

        function pagingBikes(bikes, count) {
            for (var startCount = $scope.showRequest.pagingBikes.length, i = startCount; i < bikes.length && i < startCount + count; i++) {
                $scope.showRequest.pagingBikes.push(bikes[i]);
            }
        }

        $scope.currentUser = AuthService.user();
        $scope.radio = {};

        $scope.uploadReturnLabel = function ($event) {
            ModalService.uploadImages($event, Documents.requestReturnLabel($scope.showRequest.id), function (documents) {
                    if (documents && documents.length) {
                        $scope.showRequest.request.returnLabel = documents[0];

                        srCtrl.showConfirmationAppointmentDateDialogIfNeeded($scope.showRequest.request);
                        srCtrl.showConfirmationAdditionalBookingDialogIfNeeded($scope.showRequest.request);
                    }
                },
                null,
                "Choose document",
                false,
                1,
                null,
                'Upload return label',
                '.jpg,.jpeg,.png,.pdf'
            );
        };

        $scope.removeReturnLabel = function ($event, documentId) {
            let message = 'Are you sure you want to delete the return label document?';
            ModalService.yesNoMessage($event, null, message, function (response) {
                if (!response) return;

                Documents.requestReturnLabel($scope.showRequest.id).remove(documentId).then(function () {
                        Notification.success('The return label document has been removed');
                        $scope.showRequest.request.returnLabel = null;
                    }, function (error) {
                        Notification.error("Failed to remove the return label document");
                    }
                );
            });
        };

        // START images
        $scope.uploadNewImage = function ($event) {
            ModalService.uploadImages($event, Documents.requestImage($scope.showRequest.id), function (images) {
                for (var i in images) {
                    var image = {
                        id: images[i].id,
                        original: images[i].original,
                        thumbnail: images[i].thumbnail
                    };
                    $scope.showRequest.request.images.push(image);

                    srCtrl.showConfirmationAppointmentDateDialogIfNeeded($scope.showRequest.request);
                    srCtrl.showConfirmationAdditionalBookingDialogIfNeeded($scope.showRequest.request);
                }
            });
        };

        $scope.showPicture = function ($event, index) {
            let request = $scope.showRequest.request;
            let getImages = function () {
                return request.images;
            };
            let hasPermission = request.permissions.indexOf('UPDATE_REQUEST_IMAGES') !== -1;

            if (hasPermission && (request.userId === $scope.currentUser.id || $scope.currentUser.id === request.bikeOwnerIdOfRequest) || hasPermission && AuthService.isAdmin()) {
                ModalService.showPicture($event, getImages, index, $scope.showRequest.remove);
            } else {
                ModalService.showPicture($event, getImages, index);
            }
        };

        $scope.showRequest.remove = function (index, callback) {
            Documents.requestImage($scope.showRequest.id).remove($scope.showRequest.request.images[index].id).then(
                function () {
                    $scope.showRequest.request.images.splice(index, 1);
                    if (callback) {
                        callback();
                    }
                    Notification.success("Image has been successfully removed");
                }, function (error) {
                    Notification.error("Failed to remove the image");
                }
            );
        };

        // START edit description
        $scope.toggleDescription = function () {
            $scope.disabledDescription = !$scope.disabledDescription;
        };

        $scope.saveDescription = function () {
            var description = $filter('newlines')($scope.showRequest.request.description);
            requestResource.updateDescription({id: $scope.showRequest.id}, {description: description},
                function (data) {
                    Notification.success('Notes has been updated');
                    $scope.setRequest(data);
                    $scope.toggleDescription();
                }, function (error) {
                    ErrorHandler.onErrorDialog(error);
                });
        };

        $scope.saveBikeLocation = function (form) {
            form.$setSubmitted();

            if (!$scope.showRequest.locationPlace) {
                Notification.warning('Please, enter a valid address');
                return;
            }

            $scope.disableChangeLocationBtn = true;
            if (!$scope.showRequest.request.task.online) {
                $serviceCoverageChecker.checkCoverage($scope.showRequest.request.serviceLocation,
                    function () {
                        updateLocation();
                    }, function () {
                        ModalService.showMessage(null, 'At the moment we do not have our mechanic in your area. However, ' +
                            'we are working hard to put a mechanic around every corner including yours.');
                        $scope.disableChangeLocationBtn = false;
                    }, function (error) {
                        ErrorHandler.onError(error, "Something went wrong");
                        $scope.disableChangeLocationBtn = false;
                    });
                $scope.disableChangeLocationBtn = false;
            } else {
                updateLocation();
            }
        };

        var updateLocation = function () {
            $scope.showRequest.request.serviceLocation = LocationService.mapAddress($scope.showRequest.request.serviceLocation);
            requestResource.updateLocation({id: $scope.showRequest.id}, $scope.showRequest.request.serviceLocation,
                function (data) {
                    Notification.success('Location was successfully saved');
                    $scope.setRequest(data);
                    $scope.disableChangeLocationBtn = false;
                }, function (error) {
                    ErrorHandler.onErrorDialog(error);
                    $scope.disableChangeLocationBtn = false;
                });
        };

        function addDropdownClass($event, className) {
            var element = angular.element($event.currentTarget),
                showDatesClassName = className;

            if (element.hasClass(showDatesClassName)) {
                element.removeClass(showDatesClassName);
            } else {
                element.addClass(showDatesClassName);
            }
        }

        $scope.showBikeComponent = function ($event, bike) {
            bike.showComponent = !bike.showComponent;
            bike.loadComponents();
            var className = 'rg__components-dropdown--opened';
            addDropdownClass($event, className);
        };

        $scope.openNegotiationsTab = function () {
            $scope.srCtrl.onTabSelected('negotiation');
        };

        $scope.writeMessage = function () {
            $scope.srCtrl.onTabSelected('negotiation');
            $scope.showRequest.request.negotiations[0].hideChatPanel = false;
        };

        $scope.isHaveTool = function (id) {
            if ($scope.showRequest.userTools) {
                for (var i = 0; i < $scope.showRequest.userTools.length; i++) {
                    if ($scope.showRequest.userTools[i].id === id) {
                        return true;
                    }
                }
            }

            return false;
        };

        let backParams = function () {
            if ($scope.showRequest.request) {
                switch ($scope.showRequest.request.status) {
                    case 'DRAFT':
                    case 'OPEN':
                    case 'IN_PROGRESS':
                    case 'RESOLVED':
                    case 'IN_DISPUTE':
                    case 'PENDING':
                    case 'REJECTED':
                        return 'requests';
                    case 'DONE':
                    case 'COULD_NOT_BE_FIXED':
                    case 'EXPIRED':
                    case 'CANCELED':
                    case 'REFUNDED':
                    case 'CHARGE_FAILED':
                        return 'request-history';
                }
            } else {
                return 'requests';
            }
        };

        $scope.backLink = function () {
            if (AuthService.isBikeBusinessSupervisor() && $routeParams.organisationId) {
                $location.url("/organisation/" + $routeParams.organisationId + "/" + backParams());
                return;
            }
            if (AuthService.isBikeBusinessSupervisor()) {
                $location.url("dashboard/organisations");
                return;
            }
            if (AuthService.isAdmin()) {
                HistoryService.back('/admin/requests', {page: 0, size: 10});
                return;
            }

            $location.url("/dashboard/" + backParams());
        };

        $scope.isChosenAvailableTime = function (time) {
            return $scope.showRequest.request.negotiations &&
                $scope.showRequest.request.negotiations.length === 1 &&
                $scope.showRequest.request.negotiations[0].status === 'IN_PROGRESS' &&
                $scope.showRequest.request.negotiations[0].dateRange.from >= time.from &&
                $scope.showRequest.request.negotiations[0].dateRange.to <= time.to;
        };

        $scope.showMechanic = function () {
            $temporaryStorage.put('requestId', $scope.showRequest.id);
            let user = $scope.srCtrl.scheduledNegotiation.user;
            let userId = user.nickname ? user.nickname : user.id;
            $location.path('/mechanic-public/' + userId).search({requestId: $scope.showRequest.id});
        };

        $scope.showRequest.canSetRatingAsBikeOwner = function () {
            if ($scope.showRequest.isUser && $scope.showRequest.request.bikeOwnerIdOfRequest) {
                return AuthService.user().id === $scope.showRequest.request.bikeOwnerIdOfRequest;
            }
            return true;
        };

        // TODO
        $scope.$watch('bike.certificationNotes', function () {
            var input = document.querySelector('textarea[ng-model="bike.certificationNotes"]');
            if (!input) {
                return;
            }

            var inputText = input.value;
            if (inputText.length > 500) {
                $scope.bike.certificationNotes = inputText.substr(0, 500);
            }
        });

        function defaultCancel(event) {
            if (AuthService.isMechanic()) {
                ModalService.showMechanicCancellationReasonDialog(event).then(function (reason) {
                    if (!reason) {
                        return;
                    }

                    cancel(reason);
                });
            } else {

                let msg = 'Are you sure you want to cancel this booking?';

                ModalService.yesNoMessage(event, '', msg, function (response) {
                    if (!response) return;

                    cancel();
                });
            }
        }

        function cancelWithCharges(event, penalties) {
            let groupRequest = !!$scope.showRequest.request.group;
            let message = $bookingCancellationService.getCancellationMessage(penalties, groupRequest);

            ModalService.yesNoMessage(event, '', message, function (response) {
                if (!response) return;

                cancel();
            });
        }

        $scope.cancelRequest = function (event) {
            if (!AuthService.isMechanic() && $scope.showRequest.request.phase === 'ACTIVE') {
                requestResource.checkCancellationWithCharges({id: $scope.showRequest.id}, function (response) {
                    if (response.cancellationWithCharges) {
                        cancelWithCharges(event, response.penalties);
                    } else {
                        defaultCancel(event);
                    }
                }, function (error) {
                    ErrorHandler.onErrorDialog(error);
                });
            } else {
                defaultCancel(event);
            }
        };

        $scope.regenerateReport = function (event) {
            if (AuthService.isAdmin() && $scope.showRequest.request.phase === 'COMPLETED') {
                let msg = 'Are you sure you want to generate reports again?';

                ModalService.yesNoMessage(event, '', msg, function (response) {
                    if (!response) {
                        return;
                    }

                    ToggleLD.show();
                    const params = {id: $scope.showRequest.id, id2: $scope.showRequest.request.negotiations[0].id};
                    requestResource.regenerateRequest(params, {}, function (response) {
                        Notification.success('Report was successfully generated');
                        location.reload()
                        ToggleLD.hide();
                    }, function (error) {
                        ErrorHandler.onErrorDialog(error);
                        ToggleLD.hide();
                    });
                })
            }
        };

        $scope.retryTransfer = function (event) {
            let msg = 'Are you sure you want to retry transfer?';

            ModalService.yesNoMessage(event, '', msg, function (response) {
                if (!response) {
                    return;
                }

                ToggleLD.show();
                requestResource.retryTransfer({id: $scope.showRequest.id}, {}, function (response) {
                    $scope.setRequest(response);
                    ToggleLD.hide();
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onErrorDialog(error);
                });
            });
        };

        $scope.fixBike = function ($event, bike) {
            let services = $scope.showRequest.request.task.additionalServices;
            ModalService.leaveRequestReport($event, services, function (data) {
                if (!data) return;

                ToggleLD.show();

                requestResource.fixBike({id: $scope.showRequest.id, id2: bike.id}, data || {}, function (data) {
                    $scope.setRequest(data);
                    ToggleLD.hide();
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onErrorDialog(error);
                });
            });
        };

        $scope.fixAllBikes = ($event) => {
            const bikes = getNotFixedBikes().map((bike) => {
                return {
                    id: bike.id,
                    displayName: bike.displayName,
                    serialNumber: bike.serialNumber,
                    selected: true
                }
            })

            ModalService.selectBikesForFixDialog($event, bikes).then((selectedBikes) => {
                if (selectedBikes.length > 0) {
                    let services = $scope.showRequest.request.task.additionalServices;
                    ModalService.leaveRequestReport($event, services, function (data) {
                        if (!data) return;

                        ToggleLD.show();

                        const request = {
                            comment: data.comment,
                            images: data.images,
                            bikes: selectedBikes
                        };

                        requestResource.fixAllBikes({id: $scope.showRequest.id}, request, function (data) {
                            $scope.setRequest(data);
                            ToggleLD.hide();
                        }, function (error) {
                            ToggleLD.hide();
                            ErrorHandler.onErrorDialog(error);
                        });
                    });
                }
            });
        }

        $scope.countBikes = function () {
            var count = 0;
            if ($scope.showRequest.request && $scope.showRequest.request.bikes) {
                return $scope.showRequest.request.bikes.length;
            }

            return count;
        };

        function cancel(cancellationReason) {
            ToggleLD.show();
            cancellationReason = cancellationReason || {};
            requestResource.cancel({id: $scope.showRequest.id}, cancellationReason, function (data) {
                Notification.success('Request was successfully canceled');
                $scope.setRequest(data);
                ToggleLD.hide();
            }, function (error) {
                ToggleLD.hide();
                ErrorHandler.onErrorDialog(error);
            });
        }

        $scope.changeSerialNumber = function () {
            searchBike($scope.showRequest.allBikes, $scope.showRequest.serialNumber);
        };

        $scope.loadMoreBikes = function () {
            pagingBikes($scope.showRequest.filteredBikes, $scope.showRequest.filteredBikes.length);
        };

        $scope.showLessBikes = function () {
            $scope.showRequest.pagingBikes = [];
            pagingBikes($scope.showRequest.filteredBikes, COUNT_BIKES);
        };

        $scope.getRequestStatus = function () {
            if ($scope.showRequest && $scope.showRequest.request && $scope.showRequest.request.status) {
                return $scope.showRequest.request.status.toLowerCase().replace(/_/g, '-');
            }
        };

        $scope.bookAdditionalService = () => {
            $location.path('service-booking').search({relatedBookingId: $scope.showRequest.request.id});
        };

        $scope.$watch('showRequest.request.serviceLocation.address', function () {
            if (!($scope.showRequest.request && $scope.showRequest.request.serviceLocation)) {
                return;
            }

            var address = $scope.showRequest.request.serviceLocation;
            $scope.showRequest.queryAddress = address.address;
            var lat = address.latitude;
            var lng = address.longitude;

            $scope.showRequest.map = {
                options: {
                    center: {lat: lat, lng: lng}
                },

                markers: [{
                    position: {lat: lat, lng: lng},
                    title: 'Service location',
                    icon: require('img/breaking-dot.png')
                }]
            };
        });

        srCtrl.readNewNotification = function (message) {
            if (!message.data || !message.data.metadata || !message.data.metadata.requestId || message.data.metadata.negotiationId) {
                return;
            }

            if ($scope.showRequest.id === message.data.metadata.requestId && !message.data.read) {
                srCtrl.getRequest(['description']);
                NotificationWSService.readNotification(message.data.id, AuthService.getToken());
            }
        };

        srCtrl.readRequestNotifications = function () {
            NotificationWSService.send({
                operation: 2,
                requestId: $scope.showRequest.id,
                token: AuthService.getToken().access_token
            });
        };

        srCtrl.readNegotiationNotifications = function () {
            NotificationWSService.send({
                operation: 2,
                requestId: $scope.showRequest.id,
                type: 'ADD_COMMENT_TO_NEGOTIATION',
                token: AuthService.getToken().access_token
            });
        };

        srCtrl.needShowNegotiationTab = function () {
            return !!($scope.showRequest.request &&
                ($scope.showRequest.request.permissions.indexOf('READ_NEGOTIATIONS') !== -1 && $scope.showRequest.negotiationTabName ||
                    $scope.showRequest.request.permissions.indexOf('ADD_NEGOTIATION') !== -1 ||
                    AuthService.isAdmin()));
        };

        srCtrl.needShowFlowTab = function () {
            return !!(AuthService.isMechanic() &&
                $scope.showRequest.taskFlowDescription &&
                $scope.showRequest.taskFlowDescription.id);
        };

        srCtrl.needShowHistoryTab = function () {
            return $scope.showRequest.negotiationHistory.length > 0;
        };

        srCtrl.getTaskFlow = function () {
            var request = $scope.showRequest.request;
            if (AuthService.isMechanic() && request.phase === 'ACTIVE' && request.task.id) {
                return ServiceExecutionFlow.list({taskId: $scope.showRequest.request.task.id}, function (response) {
                    if (response) {
                        $scope.showRequest.taskFlowDescription = response.data[0];
                    }
                }).$promise;
            }
        };

        $scope.srCtrl.onTabSelected = function (tab) {
            if (!$scope.showRequest.request) {
                return;
            }

            if ($routeParams[tab]) {
                return;
            }

            var searchParams = {};
            if ($routeParams.organisationId) {
                searchParams.organisationId = $routeParams.organisationId;
            }
            if ($routeParams.requestId) {
                delete searchParams.requestId;
            }
            searchParams[tab] = true;
            $location.search(searchParams);
            $location.replace();
        };

        function readNegotiationNotifications() {
            $timeout(function () {
                var input = document.getElementById('n-chat-input');
                if (input) {
                    srCtrl.readNegotiationNotifications();
                    input.focus();
                }
            }, 500);
        }

        $scope.srCtrl.changeIndexTab = function (tab) {
            var foundTab = srCtrl.tabs[tab];
            if (tab && foundTab) {
                if (tab === 'info') {
                    $scope.srCtrl.selectedTab = foundTab.index;
                    $scope.title = 'Request info';
                } else if (tab === 'negotiation') {
                    if (srCtrl.needShowNegotiationTab()) {
                        $scope.srCtrl.selectedTab = foundTab.index;
                        $scope.title = 'Negotiation';
                        readNegotiationNotifications();
                    } else if (srCtrl.needShowHistoryTab()) {
                        $scope.srCtrl.changeIndexTab('history');
                        srCtrl.readNegotiationNotifications();
                    } else {
                        $scope.srCtrl.changeIndexTab('info');
                    }
                } else if (tab === 'flow') {
                    if (srCtrl.needShowFlowTab()) {
                        $scope.title = 'Request execution flow';
                        $scope.srCtrl.selectedTab = foundTab.index;
                    } else {
                        $scope.srCtrl.changeIndexTab('info');
                    }
                } else if (tab === 'history') {
                    if (srCtrl.needShowHistoryTab()) {
                        $scope.title = 'History';
                        $scope.srCtrl.selectedTab = foundTab.index;
                    } else {
                        $scope.srCtrl.changeIndexTab('info');
                    }
                }
            } else {
                $scope.srCtrl.selectedTab = srCtrl.tabs.info.index;
                $scope.title = 'Request info';
            }
        };

        srCtrl.getTabFromRouteParams = function () {
            var tabName;
            for (var param in $routeParams) {
                if (srCtrl.tabs[param]) {
                    tabName = srCtrl.tabs[param].name;
                    break;
                }
            }
            if (!tabName) {
                tabName = 'info';
            }
            return tabName;
        };

        srCtrl.agreeAppointmentDate = function () {
            ToggleLD.show();
            requestResource.confirmAppointmentDate({id: $scope.showRequest.id}, {}, function (data) {
                $scope.setRequest(data);
                ToggleLD.hide();
                Notification.success("Appointment date was confirmed");
            }, function (error) {
                ToggleLD.hide();
                ErrorHandler.onError(error);
            });
        };

        srCtrl.showConfirmationAppointmentDateDialogIfNeeded = function (request) {
            if ($scope.srCtrl.needApproveAppointmentFromCurrentUser()) {
                ModalService.confirmationAppointmentDateDialog(request).then(function (data) {
                    if (!data) return;

                    if (data.action === 'CONFIRM') {
                        srCtrl.agreeAppointmentDate();
                    } else if (data.action === 'OFFER_TIME') {
                        $scope.srCtrl.editAppointmentDate();
                    } else if (data.action === 'DECLINE') {
                        $scope.srCtrl.declineAppointmentDate();
                    }
                });
            }
        };

        srCtrl.showConfirmationAdditionalBookingDialogIfNeeded = function (request) {
            if ($scope.srCtrl.needConfirmAssignedBooking()) {
                ModalService.confirmationAdditionalBookingDialog(request).then(function (data) {
                    if (!data) return;

                    if (data.action === 'CONFIRM') {
                        $scope.srCtrl.confirmAssignedBooking();
                    } else if (data.action === 'OFFER_TIME') {
                        $scope.srCtrl.editAppointmentDate();
                    } else if (data.action === 'DECLINE') {
                        $scope.srCtrl.declineAdditionalBooking();
                    }
                });
            }
        };

        $scope.srCtrl.needApproveAppointmentFromCurrentUser = function () {
            var negotiation = $scope.srCtrl.scheduledNegotiation;
            if (!$scope.showRequest.request || !negotiation
                || ($scope.showRequest.request.phase !== 'ACTIVE' && $scope.showRequest.request.phase !== 'DRAFT')) {
                return false;
            }

            var hasPermission = $scope.showRequest.request.permissions.indexOf('CONFIRM_APPOINTMENT_DATE') !== -1;
            var mechanic = AuthService.isMechanic();
            var confirmation = negotiation.appointmentDateConfirmation;
            return hasPermission && (mechanic && confirmation.needConfirmFromMechanic || !mechanic && confirmation.needConfirmFromCustomer);
        };

        $scope.srCtrl.needWaitApproveAppointment = function () {
            var negotiation = $scope.srCtrl.scheduledNegotiation;
            var request = $scope.showRequest.request;
            if (!request || !negotiation ||
                (request.phase !== 'ACTIVE' && request.phase !== 'DRAFT')) {
                return false;
            }

            var mechanic = $scope.showRequest.isMechanic;
            var confirmation = negotiation.appointmentDateConfirmation;
            return (mechanic || $scope.showRequest.isAdmin) && confirmation.needConfirmFromCustomer || !mechanic && confirmation.needConfirmFromMechanic;
        };

        $scope.srCtrl.needConfirmAssignedBooking = function () {
            var negotiation = $scope.srCtrl.scheduledNegotiation;
            var request = $scope.showRequest.request;
            if (!request || !negotiation || request.status !== 'DRAFT') {
                return false;
            }

            return $scope.showRequest.request.permissions.indexOf('CONFIRM_ASSIGNED_BOOKING') !== -1;
        };

        $scope.srCtrl.confirmAssignedBooking = function () {
            ToggleLD.show();
            requestResource.confirmAssignedBooking({id: $scope.showRequest.id}, {}, function (data) {
                $scope.setRequest(data);
                ToggleLD.hide();
                Notification.success("Booking was confirmed");
            }, function (error) {
                ToggleLD.hide();
                ErrorHandler.onError(error);
            });
        };

        $scope.srCtrl.reassignBooking = function (event) {
            $location.path("/reassign-booking").search({bookingId: $scope.showRequest.id});
        };

        $scope.srCtrl.assignSupportResponsible = function (event) {
            ModalService.assignSupportResponsibleDialog(event).then(function (data) {
                if (!data || !data.assignee) return;

                ToggleLD.show();
                requestResource.assignSupportResponsible({id: $scope.showRequest.id}, {assignee: data.assignee.id}, function (req) {
                    $scope.setRequest(req);
                    ToggleLD.hide();
                    Notification.success(data.assignee.name + " has been successfully assigned to this request");
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error);
                });
            });
        };

        $scope.srCtrl.showFixAll = () => {
            return $scope.showRequest.request.phase === 'ACTIVE'
                && $scope.showRequest.request.group
                && $scope.showRequest.request.bikes.length > 1
                && getNotFixedBikes().length > 1
        }

        $scope.srCtrl.regenerateReport = () => {
            $scope.regenerateReport();
        }

        $scope.$on('$routeChangeSuccess', function () {
            var tabName = srCtrl.getTabFromRouteParams();
            $scope.srCtrl.changeIndexTab(tabName);
        });

        function copyRequest(from, to, excludeProperties) {
            for (var key in from) {
                if (!excludeProperties || !excludeProperties.length || excludeProperties.indexOf(key) === -1) {
                    to[key] = from[key];
                }
            }
        }

        function getNotFixedBikes() {
            let bikes = [];
            if (!$scope.showRequest.request.bikes && $scope.showRequest.request.bikes.length <= 1) {
                return bikes;
            }

            for (let i = 0; i < $scope.showRequest.request.bikes.length; i++) {
                if ($scope.showRequest.request.bikes[i].permissions.indexOf('FIX_BIKE') !== -1) {
                    bikes.push($scope.showRequest.request.bikes[i]);
                }
            }

            return bikes;
        }

        (function init() {
            ToggleLD.show();
            var tabName = srCtrl.getTabFromRouteParams();
            $q.all([srCtrl.getRequest()]).then(function () {
                $q.when(srCtrl.getTaskFlow()).then(function () {
                    $scope.srCtrl.changeIndexTab(tabName);
                }).finally(function () {
                    ToggleLD.hide();
                    $timeout(function () {
                        $scope.srCtrl.loaded = true;
                    }, 10);
                });
            }).catch(function (error) {
                $timeout(function () {
                    $scope.srCtrl.loaded = true;
                }, 10);
                ToggleLD.hide();
                onError(error);
            });

            NotificationWSService.registerHandler("NEW", "request", srCtrl.readNewNotification);
            srCtrl.readRequestNotifications();
            $scope.$on("$destroy", function () {
                NotificationWSService.unregisterHandler("NEW", "request");
            });
        })();
    }
]);
