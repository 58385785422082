import app from "js/legacy-app";

app.directive('blTransferAction', ['ModalService', 'Notification', 'AuthService', '$pageableList',
    function (ModalService, Notification, AuthService, $pageableList) {
        return {
            template: "     <md-menu-item" +
                "                   ng-show=\"bike.status == 'USED' && blta.canTransferBike(bike)\">" +
            "                   <md-button ng-click='blta.selectUserForBikeTransfer($event, bike)'>Transfer to new owner" +
            "                   </md-button>" +
            "               </md-menu-item>",
            replace: true,
            link: function ($scope) {

                $scope.blta = {};

                AuthService.currentUser(function (user) {
                    if (user) {
                        $scope.currentUserId = user ? user.id : null;
                    }
                });

                $scope.blta.selectUserForBikeTransfer = function ($event, bike) {
                    ModalService.selectUserForBikeTransfer($event, bike.id, function () {
                        $pageableList.get('bike-list').refreshPage();
                    });
                };

                $scope.blta.canTransferBike = function (bike) {
                    return $scope.currentUserId === bike.userId;
                };
            }
        };
    }]);