import app from "js/legacy-app";
import angular from "angular";

app.directive('vtFormHeader', ['$timeout', '$window', function ($timeout, $window) {
    return {
        templateUrl: "components/form/vt-form-header.html",
        replace: true,
        transclude: true,
        require: '^^vtForm',
        scope: {
            title: "@fhTitle",
            backBtn: "=?fhBackBtn",
        },

        link: function (scope, elem, attrs) {
        },

        controller: ['$scope', function ($scope) {
            $scope.fh = {};

            $scope.fh.back = function () {
                if (angular.isFunction($scope.backBtn)) {
                    $scope.backBtn();
                } else {
                    $window.history.back();
                }
            };
        }]
    };
}]);