import app from "js/legacy-app";

app.controller('NotCoveredLocationController',
    ['$scope', 'ErrorHandler', 'ToggleLD', '$location', 'UncoatedArea', 'Notification', '$routeParams', 'LocationService',
        function ($scope, ErrorHandler, ToggleLD, $location, UncoatedArea, Notification, $routeParams, LocationService) {
            var nclc = $scope.nclc = {};

            nclc.cancel = function () {
                $location.path("/");
            };

            nclc.ok = function () {
                if (!nclc.email) {
                    Notification.error("Please enter valid email address");
                    return;
                }
                var location = $routeParams.location ? LocationService.deserializeAddress($routeParams.location) : null;
                ToggleLD.show();
                UncoatedArea.save({
                    location: location,
                    email: nclc.email
                }, function () {
                    ToggleLD.hide();
                    $location.path("/");
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error);
                });
            };

        }]);