import angular from "angular";

const removeAction = {
    template: require("./image-viewer-remove-action.html"),
    require: {
        parent: '^vuiImageViewer'
    },
    bindings: {},

    controller: function (Notification) {
        "ngInject";

        this.$onInit = () => {
            this.image = this.parent.images[this.parent.currentImageIdx];
        };

        this.onClick = () => {
            if (this.parent.documents.entityId) {
                this.parent.documents.remove(this.image.id).then(() => {
                    this.parent.onRemove();
                    Notification.success("Image has been removed");
                }, (error) => {
                    Notification.error("Failed to remove the image");
                });
            } else {
                this.parent.documents.removeTemp(this.image.id);
                this.parent.onRemove();
            }
        };
    }
};

const setupCoverAction = {
    template: require("./image-viewer-setup-cover-action.html"),
    require: {
        parent: '^vuiImageViewer'
    },

    controller: function (Notification) {
        "ngInject";

        this.$onInit = () => {
            this.image = this.parent.images[this.parent.currentImageIdx];
        };

        this.onClick = () => {
            this.parent.documents.setAsCover(this.image.id).then(() => {
                Notification.success("Cover image has been changed");
            }, (error) => {
                Notification.error("Failed to set the image as cover");
            });
        };
    }
};

const setupAsAvatarAction = {
    template: require("./image-viewer-setup-default-action.html"),
    require: {
        parent: '^vuiImageViewer'
    },

    controller: function (Notification) {
        "ngInject";

        this.$onInit = () => {
            this.image = this.parent.images[this.parent.currentImageIdx];
        };

        this.onClick = () => {
            this.parent.documents.setAsAvatar(this.image.id).then((images) => {
                Notification.success('Image was successfully set as avatar');
            }, (error) => {
                Notification.error("Failed to set the image as avatar");
            });
        };
    }
};

const closeAction = {
    template: require("./image-viewer-close-action.html"),

    controller: function ($mdDialog) {
        "ngInject";

        this.onClick = () => {
            $mdDialog.hide();
        };
    }
};

export default angular.module("vui-image-viewer-actions.module", [])
    .component("vuiIvRemoveAction", removeAction)
    .component("vuiIvSetupCoverAction", setupCoverAction)
    .component("vuiIvSetupDefaultAction", setupAsAvatarAction)
    .component("vuiIvCloseAction", closeAction);