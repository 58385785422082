import app from "js/legacy-app";

app.controller('CoreServiceBucketsViewCtrl', function ($scope, $location, $routeParams, ErrorHandler, CoreServiceBuckets, ToggleLD, Notification) {

        'ngInject';

        $scope.serviceBucket = {
            id: $routeParams.id,
            mechanicsCustomizationAvailable: false,
            enableByDefault: false
        };

        $scope.serviceBucketCtrl = {

            refresh: function () {
                ToggleLD.show();
                if ($scope.serviceBucket.id) {
                    CoreServiceBuckets.get({id: $scope.serviceBucket.id}, function (t) {
                        ToggleLD.hide();
                        $scope.serviceBucket = t;
                        $scope.serviceBucket.enableByDefault = t.defaultState === 'ENABLED';
                    }, function (error) {
                        ToggleLD.hide();
                        $location.path("/admin/service-management/service-buckets");
                        ErrorHandler.onError(error, "Failed to load service buckets");
                    });
                }
            },

            update: function (form) {
                if (form.$valid) {
                    ToggleLD.show();
                    if ($scope.serviceBucket.id) {
                        CoreServiceBuckets.update({id: $routeParams.id}, this.get(), function (t) {
                            ToggleLD.hide();
                            $scope.serviceBucket = t;
                            $scope.serviceBucket.enableByDefault = t.defaultState === 'ENABLED';
                            Notification.success("Service bucket was successfully updated");
                        }, function (error) {
                            ToggleLD.hide();
                            ErrorHandler.onError(error, "Failed to update service bucket");
                        });
                    }
                }
            },

            close: function () {
                $location.path("/admin/service-management/service-buckets");
            },

            get: function () {
                let type = $scope.serviceBucket;
                let dto = {};
                dto.description = type.description;
                dto.name = type.name;
                dto.mechanicsCustomizationAvailable = type.mechanicsCustomizationAvailable;
                dto.defaultState = $scope.serviceBucket.enableByDefault ? 'ENABLED' : 'DISABLED';
                return dto;
            }
        };

        $scope.serviceBucketCtrl.refresh();
    }
);
