import app from "js/legacy-app";

app.service('Agreement', ['$resource', function ($resource) {
    return $resource('/api/agreement/:id', {}, {
        update: {
            method: "PUT",
            params: {id:""},
            isArray: false
        },
        getTypes: {
            method: "GET",
            params: {id:"types"},
            isArray:true
        },
        search: {
            method: "POST",
            isArray: false,
            params: {id: "search"}
        }
    });
}]);