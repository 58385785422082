import app from "js/legacy-app";

app.directive('tpNegotiationMaxTime', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            var maxTpNegotiationValidator = function (value) {
                var max = new Date('1/01/2000' + ' ' + attr.tpNegotiationMaxTime);
                var newValue = new Date('1/01/2000' + ' ' + value.split(' - ')[1]);
                var newValue2 = new Date('1/01/2000' + ' ' + value.split(' - ')[0]);
                if (newValue && newValue.getTime() > max.getTime() || newValue2 && newValue2.getTime() > max.getTime()) {
                    ctrl.$setValidity('tpNegotiationMaxTime', false);
                    return value;
                } else {
                    ctrl.$setValidity('tpNegotiationMaxTime', true);
                    return value;
                }
            };

            ctrl.$parsers.push(maxTpNegotiationValidator);
            ctrl.$formatters.push(maxTpNegotiationValidator);
        }
    };
});