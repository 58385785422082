import app from "js/legacy-app";

app.service('AdminBikeResource', ['$resource', function ($resource) {
    return $resource('/api/admin-bike/:id/:id1/:id2', {}, {
        list: {
            method: "GET",
            isArray: false,
            params: {id: "list"}
        },
        countByType: {
            method: "GET",
            params: {id: "count/by-type"},
            isArray: true
        },
        countByCity: {
            method: "GET",
            params: {id: "count/by-city"},
            isArray: true
        },
        countRegistration: {
            method: "GET",
            params: {id: "count/by-registration-date"},
            isArray: true
        },
        totalCount: {
            method: "GET",
            params: {id: "total-count"},
            isArray: false
        }
    });
}]);
