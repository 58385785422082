import app from "js/legacy-app";

app.controller('CreateStationCtrl', ['$scope', 'ModalService', 'Station', 'ToggleLD', 'Notification',
    '$location', '$routeParams', 'AuthService', 'ErrorHandler', 'LocationService',
    function ($scope, ModalService, Station, ToggleLD, Notification, $location,
              $routeParams, AuthService, ErrorHandler, LocationService) {

        $scope.station = {
            disabledCreate: false,
            organisationId: $routeParams.organisationId,
            isSupervisor: AuthService.isBikeBusinessSupervisor(),
            address: {},
            types: [
                'STATION',
                'TERRITORY',
                'WAREHOUSE',
                'BIKE_SHOP'
            ],

            save: function (form) {
                if (form.$valid) {
                    if (!$scope.station.validAddress($scope.station.address)) {
                        return;
                    }

                    $scope.station.disabledCreate = true;
                    ToggleLD.show();
                    Station.save(this.get(), function () {
                        ToggleLD.hide();
                        Notification.success('New bicycle group has been created');
                        $scope.station.disabledCreate = false;
                        $location.path('/organisation/' + $scope.station.organisationId + "/bike-groups").search({});
                    }, function (error) {
                        ToggleLD.hide();
                        $scope.station.disabledCreate = false;
                        ErrorHandler.onError(error, "Failed to create station");
                    });
                }
            },

            get: function () {
                return {
                    organisationId: $scope.station.organisationId,
                    name: $scope.station.name,
                    type: $scope.station.type,
                    address: LocationService.mapAddress($scope.station.address)
                };
            },

            validAddress: function (address) {

                if ($scope.station.type !== 'WAREHOUSE' && $scope.station.type !== 'BIKE_SHOP') {
                    return true;
                }

                var error = LocationService.validateProfileAddress(address);
                if (error) {
                    Notification.error(error);
                    return false;
                }

                return true;
            }
        };
    }
]);