"use strict";

import angular from "angular";
import "angular-animate";
import "angular-aria";
import "angular-cookies";
import "angular-resource";
import "angular-route";
import "angular-route-segment";
import "ngclipboard";
import "angular-feature-flags";
import "angular-sanitize";
import "angular-i18n/angular-locale_en-001.js";

export default angular
    .module("angular-basic-app", [
        'ngRoute',
        'ngResource',
        'ngAnimate',
        'ngAria',
        'route-segment',
        'view-segment',
        'ngCookies',
        'feature-flags',
        'ngSanitize',
        'ngclipboard'
    ]);
