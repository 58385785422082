import app from "js/legacy-app";

app.controller('CoreServiceViewCtrl',
    function ($scope, $location, $routeParams, ErrorHandler, CoreServiceManagement, $q, ToggleLD,
              Tools, ModalService, Notification, CoreServiceCategories, Documents, CoreServiceBuckets) {

        'ngInject';

        $scope.task = {
            id: $routeParams.id,
            mechanicsCustomizationAvailable: false,
            enableByDefault: false
        };

        $scope.taskController = {
            refresh: function () {
                ToggleLD.show();
                if ($scope.task.id) {
                    CoreServiceManagement.get({id: $scope.task.id}, function (t) {
                        ToggleLD.hide();
                        $scope.task = t;
                        $scope.task.enableByDefault = t.defaultState === 'ENABLED';
                        $scope.task.additionalServices = t.additionalServices.map(service => {
                            return {
                                name: service
                            }
                        });
                        $scope.task.selectedCategory = t.category;
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error, "Failed to load service");
                    });
                }
            },

            update: function (form) {
                if (!form.$valid) {
                    return;
                }

                ToggleLD.show();
                if ($scope.task.id) {
                    if ($scope.task.serviceBucket !== 'GENERAL' && (!$scope.task.selectedCategory || !$scope.task.selectedCategory.id)) {
                        Notification.error("Service category is required");
                        ToggleLD.hide();
                        return;
                    }

                    CoreServiceManagement.update({id: $routeParams.id}, this.get(), function (t) {
                        ToggleLD.hide();
                        $scope.task = t;
                        $scope.task.enableByDefault = t.defaultState === 'ENABLED';
                        $scope.task.additionalServices = t.additionalServices.map(service => {
                            return {
                                name: service
                            }
                        });
                        $scope.task.selectedCategory = t.category;
                        Notification.success("Service was successfully updated");
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error, "Failed to update service", "ERROR", form);
                    });
                }
            },

            remove: function (taskId) {
                if ($scope.task.id) {
                    var msg = 'Are you sure you want to remove \'' + $scope.task.name + '\' task?';
                    ModalService.yesNoMessage(null, '', msg, function (response) {
                        if (response === true) {
                            ToggleLD.show();
                            CoreServiceManagement.delete({id: taskId}, function () {
                                ToggleLD.hide();
                                $location.path("/admin/service-management/services");
                                Notification.success("Service was successfully removed");
                            }, function (error) {
                                ToggleLD.hide();
                                ErrorHandler.onError(error, "Failed to remove service");
                            });
                        }
                    });
                }
            },

            close: function () {
                $location.path("/admin/service-management/services");
            },

            addTool: function () {
                $scope.task.tools.push({
                    name: ''
                });
            },

            removeTool: function (index) {
                $scope.task.tools.splice(index, 1);
            },

            addService: function () {
                $scope.task.additionalServices.push({
                    name: ''
                });
            },

            removeService: function (index) {
                $scope.task.additionalServices.splice(index, 1);
            },

            searchTools: function (query) {
                var deferred = $q.defer();
                Tools.searchByNameLike({name: query}, function (response) {
                    deferred.resolve(response.items);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },

            searchCategories: function (query) {
                return CoreServiceCategories.autocomplete({
                    name: query,
                    serviceBucket: $scope.task.serviceBucket
                }).$promise;
            },

            disabledSwitch: function () {
                return $scope.task.serviceBucket && $scope.task.serviceBucket !== 'GENERAL';
            },

            onSwitchChange: function () {
                if ($scope.task.main) {
                    $scope.viewTaskForm.categoryId.validationError = null;
                    $scope.viewTaskForm.categoryId.$setValidity('validation', true);
                }
            },

            onChangeServiceBucket: (serviceBucket) => {
                if (serviceBucket !== 'GENERAL') {
                    $scope.task.main = false;
                }
            },

            flows: [
                'DEFAULT',
                'WHITE_GLOVE_DELIVERY'
            ],

            get: function () {
                var task = $scope.task;
                var dto = {};
                var tools = [];
                if (task.tools && task.tools.length > 0) {
                    for (var i = 0; i < $scope.task.tools.length; i++) {
                        if ($scope.task.tools[i].id) {
                            tools.push($scope.task.tools[i].id);
                        } else {
                            if ($scope.task.tools[i].selectedTool && $scope.task.tools[i].selectedTool.id) {
                                tools.push($scope.task.tools[i].selectedTool.id);
                            }
                        }
                    }
                }

                var additionalServices = [];
                if (task.additionalServices && task.additionalServices.length > 0) {
                    for (let i = 0; i < $scope.task.additionalServices.length; i++) {
                        if ($scope.task.additionalServices[i].name) {
                            additionalServices.push($scope.task.additionalServices[i].name);
                        }
                    }
                }

                dto.tools = tools;
                dto.additionalServices = additionalServices;
                dto.name = task.name;
                dto.description = task.description;
                dto.price = task.price;
                dto.timeToComplete = task.timeToComplete;
                dto.partProvider = task.partProvider;
                dto.flow = task.flow;
                dto.needPart = task.needPart;
                dto.needBike = task.needBike;
                dto.online = task.online;
                dto.group = task.group;
                dto.deliverySupported = task.deliverySupported;
                dto.serviceBucket = task.serviceBucket;
                dto.main = task.main;
                dto.mechanicNotes = task.mechanicNotes;
                dto.mechanicPrice = task.mechanicPrice;
                dto.mechanicsCustomizationAvailable = task.mechanicsCustomizationAvailable;
                dto.defaultState = task.enableByDefault ? 'ENABLED' : 'DISABLED';
                if (!task.main && $scope.task.selectedCategory) {
                    dto.categoryId = $scope.task.selectedCategory.id;
                } else if (!task.main && $scope.task.category && $scope.task.category.id) {
                    dto.categoryId = $scope.task.category.id;
                }
                return dto;
            }
        };

        $scope.taskController.refresh();

        (function init() {
            CoreServiceBuckets.get({size: 100}, function (res) {
                $scope.taskController.types = [].concat(res.data.map((sb) => {
                    return {val: sb.type, display: sb.name};
                }));
            });
        })();
    }
);
