import app from "js/legacy-app";
import angular from "angular";

app.directive('plItems', [function () {
    return {
        templateUrl: "template/component/widget/pageable-list/pl-items.html",
        replace: true,
        transclude: true,
        controller: ['$element', '$scope', function ($element, $scope) {
            this.el = $element;
            this.pl = $scope.pageableList;
        }],
        link: function ($scope, element, $attrs, controller, $transclude) {

            var pl = $scope.pageableList;
            $scope.pageableList.emptyDataText = $attrs.plEmptyDataText;
            $scope.addNewLink = $attrs.plAddNewLink;
            $scope.addNewFn = $attrs.plAddNewFn ? $scope.$eval($attrs.plAddNewFn) : null;
            $scope.addNewLabel = $attrs.plAddNewLabel || 'Add New';

            $scope.pageableList.onStartLoad(function () {
                var container = angular.element(element[0].querySelector('.pageable-list__items'));
                container.children().addClass('pageable-list__item_leave');
            });

            $scope.pageableList.onFinishRender(function () {
                angular.element(element[0].getElementsByClassName('pageable-list__item_leave')).remove();
            });

            $scope.pageableList.onFinishLoad(function () {
                var container = angular.element(element[0].querySelector('.pageable-list__items'));

                if (!pl.items || pl.items.length === 0) {
                    return;
                }

                pl.items.forEach(function (item, index) {

                    var innerScope = $scope.$new();
                    innerScope[pl.itemName] = item;
                    innerScope.$index = index;
                    innerScope.$first = index === 0;
                    innerScope.$last = index === pl.items.length - 1;
                    innerScope.$even = index % 2 === 0;
                    innerScope.$odd = index % 2 !== 0;

                    $transclude( innerScope, function( clone ) {
                        if (pl.id) {
                            clone.attr('id', pl.id + '-item-' + index);
                        }
                        container.append( clone );
                        clone.on( '$destroy', function() {
                            innerScope.$destroy();
                        });
                    });
                });
            });
        }
    };
}]);