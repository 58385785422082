import angular from "angular";

(function () {

    'use strict';

    function Payment($document) {
        var defaultFormat = /(\d{1,4})/g;

        var cards = [
            {
                type: 'maestro',
                pattern: /^(5(018|0[23]|[68])|6(39|7))/,
                format: defaultFormat,
                length: [12, 13, 14, 15, 16, 17, 18, 19],
                cvcLength: [3],
                luhn: true
            },
            {
                type: 'mastercard',
                pattern: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[0-1]|2720)/,
                format: defaultFormat,
                length: [16],
                cvcLength: [3],
                luhn: true
            }, {
                type: 'amex',
                pattern: /^3[47]/,
                format: defaultFormat,
                length: [15],
                cvcLength: [3, 4],
                luhn: true
            }, {
                type: 'visa',
                pattern: /^4/,
                format: defaultFormat,
                length: [13, 14, 15, 16],
                cvcLength: [3],
                luhn: true
            }, {
                type: 'visaelectron',
                pattern: /^4(026|17500|405|508|844|91[37])/,
                format: defaultFormat,
                length: [16],
                cvcLength: [3],
                luhn: true
            }, {
                type: 'discover',
                pattern: /^6(44|45|46|47|48|49|22|5|011)/,
                format: defaultFormat,
                length: [16],
                cvcLength: [3],
                luhn: true
            }, {
                type: 'dinersclub',
                pattern: /^(3(0[0-5])|54|36|38)/,
                format: defaultFormat,
                length: [14],
                cvcLength: [3],
                luhn: true
            }, {
                type: 'jcb',
                pattern: /^(2131|1800|35)/,
                format: defaultFormat,
                length: [15,16],
                cvcLength: [3],
                luhn: true
            }
        ];

        var trim = function (str) {
            return str.toString().replace(/^\s+|\s+$/g, '');
        };

        var hasTextSelected = function (elm) {
            var ref;
            if (elm.prop('selectionStart') && elm.prop('selectionStart') !== elm.prop('selectionEnd')) {
                return true;
            }
            return $document !== undefined && $document !== null ? (ref = $document.selection) ? typeof ref.createRange === "function" ? ref.createRange().text : undefined : undefined : undefined;
        };

        var formatCardName = function(name) {
            name = name.replace(/[^A-Za-z\s-\\.]/g, '');
            name = name.replace(/\s(?=\s)/g, ''); /* Delete double spaces */
            name = name.replace(/^\s+/, ''); /* Delete first spaces */
            name = name.replace(/^-+/, ''); /* Delete first hyphens */
            name = name.replace(/-(?=-)/g, ''); /* Delete double hyphens */
            name = name.replace(/^\.+/, ''); /* Delete first dots */
            name = name.replace(/\.(?=\.)/g, ''); /* Delete double dots */

            return name.toUpperCase().substr(0, 22);
        };

        var validateCardName = function(name) {

            if(!name)
                return false;

            if(!/[^A-Z]/g.test(name))
                return false;

            if(name.length < 3)
                return false;

            return true;

        };

        var cardFromNumber = function (num) {
            var card, i, len;
            if (!num) {
                return null;
            }
            num = num.toString().replace(/\W/g, '');
            for (i = 0, len = cards.length; i < len; i++) {
                card = cards[i];
                if (card.pattern.test(num)) {
                    return card;
                }
            }

            return null;
        };

        var luhnCheck = function (num) {
            var digit, digits = num.toString().split('').reverse(), sum = 0, i, odd = true;
            for (i = 0; i < digits.length; i++) {
                digit = digits[i];
                digit = parseInt(digit, 10);
                if ((odd = !odd)) {
                    digit *= 2;
                }
                if (digit > 9) {
                    digit -= 9;
                }
                sum += digit;
            }

            return sum % 10 === 0;
        };

        var validateCardNumber = function (num) {
            if (!num) {
                return false;
            }

            var card;
            num = num.toString().replace(/\s+|-/g, '');
            if (!/^\d+$/.test(num)) {
                return false;
            }
            card = cardFromNumber(num);

            return card ? (card.length.indexOf(num.length) >= 0) && (card.luhn === false || luhnCheck(num)) : false;
        };

        var formatCardNumber = function (num) {
            if(!num)
                return;

            num = num.replace(/\D/g, '');
            var card = cardFromNumber(num), groups, upperLength, ref, result;
            if (!card) {

                var length = num.length;
                num = num.split('');

                //Add separator
                if(length > 4) {
                    num.splice(4,0,' ');
                    length++;
                }
                if(length > 9) {
                    num.splice(9,0,' ');
                    length++;
                }
                if(length > 14) {
                    num.splice(14,0,' ');
                    length++;
                }
                if(length > 19) {
                    num.splice(19,0,' ');
                }

                return num.join('').substr(0, 19);
            }

            upperLength = card.length[card.length.length - 1];

            num = num.slice(0, +upperLength || 9e9);

            if (card.format.global) {
                result = (ref = num.match(card.format)) !== null ? ref.join(' ') : undefined;
            } else {
                groups = card.format.exec(num);
                if (groups !== null) {
                    groups.shift();
                }
                result = groups !== null ? groups.join(' ') : undefined;
            }

            return result;
        };

        var validateCardExpiry = function (month, year) {
            var currentTime, expiry, prefix;
            if (typeof month === 'object' && month.hasOwnProperty('month')) {
                year = month.year;
                month = month.month;
            }

            if (!(month && year)) {
                return false;
            }

            month = trim(month);
            year = trim(year);
            if (!/^\d+$/.test(month)) {
                return false;
            }
            if (!/^\d+$/.test(year)) {
                return false;
            }
            if (parseInt(month, 10) > 12) {
                return false;
            }
            if (year.length === 2) {
                prefix = (new Date()).getFullYear();
                prefix = prefix.toString().slice(0, 2);
                year = prefix + year;
            }

            expiry = new Date(year, month);
            currentTime = new Date();
            expiry.setMonth(expiry.getMonth() - 1);
            expiry.setMonth(expiry.getMonth() + 1, 1);

            return expiry > currentTime;
        };

        var cardFromType = function (type) {
            var i;
            for (i = 0; i < cards.length; i++) {
                if (cards[i].type === type) {
                    return cards[i];
                }
            }
            return undefined;
        };

        var validateCardCVC = function (cvc, type) {
            var card;
            cvc = trim(cvc);
            if (!/^\d+$/.test(cvc)) {
                return false;
            }
            if (type) {
                card = cardFromType(type);
                return card ? card.cvcLength.indexOf(cvc.length) >= 0 : false;
            }

            return cvc.length >= 3 && cvc.length <= 4;
        };

        return {
            hasTextSelected: hasTextSelected,
            cardFromNumber: cardFromNumber,
            validateCardName: validateCardName,
            validateCardNumber: validateCardNumber,
            validateCardExpiry: validateCardExpiry,
            validateCardCvc: validateCardCVC,
            formatCardName: formatCardName,
            formatCardNumber: formatCardNumber
        };
    }

    var app = angular.module('legacy-app');
    app.factory('Payment', ['$document', Payment]);
})();