import app from "js/legacy-app";
import angular from "angular";

app.directive('vtTabset', function () {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        scope: {},
        controller: ['$scope', '$location', function ($scope, $location) {
            var tabs = $scope.tabs = [];

            this.selectTab = function (tab) {
                angular.forEach(tabs, function (tab) {
                    tab.active = false;
                });
                tab.active = true;
                $location.path(tab.url);
            };

            this.addTab = function (tab) {
                tab.active = !!$location.path().match(tab.url);
                tabs.push(tab);
            };
        }],
        template:
        '<div class="container-fluid">' +
        '<div class="vt-tabset" ng-transclude></div>' +
        '</div>'
    };
});