import app from "js/legacy-app";
import angular from "angular";

app.directive('textEllipsis', ['$timeout', function ($timeout) {
    return {
        replace: true,
        transclude: true,
        templateUrl: 'template/component/text-ellipsis.html',
        scope: {
            animateSeconds: '@?teAnimateSeconds',
            minHeight: '@teMinHeight',
            overflowedClass: '@?teOverflowedClass',
            showMoreBtnClass: '@?teShowMoreBtnClass',
            showMoreFn: '&?teShowMoreFn',
            showLessFn: '&?teShowLessFn'
        },
        link: function (scope, elem, attr) {

            scope.animateSeconds = scope.animateSeconds || "0.3";

            scope.te = {};

            scope.te.readMore = function () {
                if (scope.te.expanded) {
                    if (angular.isFunction(scope.showLessFn)) {
                        scope.showLessFn();
                    } else {
                        collapse();
                    }
                } else {
                    if (angular.isFunction(scope.showMoreFn)) {
                        scope.showMoreFn();
                    } else {
                        expand();
                    }
                }
            };

            function expand() {
                scope.te.expanded = true;
            }

            function collapse() {
                scope.te.expanded = false;
            }


            if (scope.overflowedClass) {
                scope.$watch("te.overflowed && !te.showMore", function (nVal, oVal) {
                    if (nVal === true) {
                        elem.addClass(scope.overflowedClass);
                    } else {
                        elem.removeClass(scope.overflowedClass);
                    }
                });
            }

        }
    };
}]);