import app from "js/legacy-app";

app.service("$requestStorage", function ($localStorage) {
    'ngInject';

    function remove(key) {
        $localStorage.remove(key);
    }

    function isExist(key) {
        return $localStorage.isExists(key);
    }

    function get(key) {
        return $localStorage.get(key);
    }

    function put(key, value) {
        $localStorage.set(key, value);
    }

    function putDraftRequest(data) {
        put('draft-request', data);
    }

    function putDraftedBike(data) {
        put('draft-bike', data);
    }

    function getDraftedRequest() {
        return get('draft-request');
    }

    function getDraftedBike() {
        return get('draft-bike');
    }

    function hasDraftRequest() {
        return isExist('draft-request');
    }

    function hasDraftedBike() {
        return isExist('draft-bike');
    }

    function removeDraftBike() {
        remove('draft-bike');
    }

    function removeDraftRequest() {
        remove('draft-request');
    }


    function removeAcceptCookiesPolicy() {
        remove('accept-cookies-policy');
    }

    function isExistAcceptCookiesPolicy() {
        return isExist('accept-cookies-policy');
    }

    function getAcceptCookiesPolicy() {
        return get('accept-cookies-policy');
    }

    function putAcceptCookiesPolicy(value) {
        put('accept-cookies-policy', value);
    }

    return {
        hasDraftRequest: hasDraftRequest,
        removeDraftRequest: removeDraftRequest,
        hasDraftedBike: hasDraftedBike,
        removeDraftBike: removeDraftBike,
        getDraftedRequest: getDraftedRequest,
        getDraftedBike: getDraftedBike,
        putDraftRequest: putDraftRequest,
        putDraftedBike: putDraftedBike,
        removeAcceptCookiesPolicy: removeAcceptCookiesPolicy,
        isExistAcceptCookiesPolicy: isExistAcceptCookiesPolicy,
        getAcceptCookiesPolicy: getAcceptCookiesPolicy,
        putAcceptCookiesPolicy: putAcceptCookiesPolicy
    };
});
