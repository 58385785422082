"use strict";

import angular from "angular";
import "angular-material-app";
import "vt-misc/angular-seo";
import "moment";

import "font-awesome/scss/font-awesome.scss";
import "vt-misc/grid.css";
import "tailwindcss/tailwind.css";
import "vt-misc/tailwind-adjustments.css";

import "oclazyload";
// route config
import angularSanitizeConfig from "angular-sanitize.config";
import baseRouteConfig from "base-route-config";
import mechanicAppRoutes from "vt-mechanic/mechanic-app-routes";
import customerAppRoutes from "vt-customer/customer-app-routes";
import partnerAppRoutes from "vt-partner/partner-app-routes";
import PublicApp from "vt-public/public-app.module";
import AdminApp from "vt-admin/admin-app.module";
import MechanicApp from "vt-mechanic/mechanic-app.module";
import PartnerApp from "vt-partner/partner-app.module";
import CommonApp from "vt-common/common-app.module";

//misc
import NgSentry from "./vt-misc/sentry";
import NgPlaid from "./vt-misc/plaid"

export default angular
    .module("velotooler-app", [
        'angular-material-app',
        'seo',
        'oc.lazyLoad',
        PublicApp.name,
        AdminApp.name,
        MechanicApp.name,
        PartnerApp.name,
        CommonApp.name,
        NgSentry.name,
        NgPlaid.name
    ])
    .config(partnerAppRoutes)
    .config(baseRouteConfig)
    .config(mechanicAppRoutes)
    .config(customerAppRoutes)
    .config(angularSanitizeConfig);
