import app from "js/legacy-app";

app.service('$bikeService', ['ModalService', 'User', 'AuthService', '$requestStorage', 'BIKE_STATUS',
    'HistoryService', '$location', '$typeOfBikesService', '$frameMaterialService', '$q',
    'CustomerBikeResource', 'MechanicBikeResource', 'AdminBikeResource', 'PartnerBikeResource', '$routeParams',
    function (ModalService, User, AuthService, $requestStorage, BIKE_STATUS, HistoryService, $location, $typeOfBikesService, $frameMaterialService, $q,
              CustomerBikeResource, MechanicBikeResource, AdminBikeResource, PartnerBikeResource, $routeParams) {

        var currentType = null,
            currentBrand = null,
            currentModel = null,
            currentGroupset = null,
            setBikeType = function (type) {
                currentType = type;
            },
            getBikeType = function () {
                return currentType;
            },
            setBrand = function (brand) {
                currentBrand = brand;
            },
            getBrand = function () {
                return currentBrand;
            },
            setModel = function (model) {
                currentModel = model;
            },
            getModel = function () {
                return currentModel;
            },
            setGroupset = function (groupset) {
                currentGroupset = groupset;
            },
            getGroupset = function () {
                return currentGroupset;
            },
            getGroupsetByName = function (name) {
                var groupsets = currentType.groupsets;
                for (var i = 0; i < currentType.groupsets.length; i++) {
                    if (name === currentType.groupsets[i].name) {
                        return groupsets[i];
                    }
                }
            },
            getFrameMaterialByName = function (name) {
                var materials = $frameMaterialService.getAll();
                for (var i = 0; i < materials.length; i++) {
                    if (name === materials[i].name) {
                        return materials[i];
                    }
                }
            },
            getWheelSizeByName = function (wheelSize) {
                var wheels = currentType.wheels;
                for (var i = 0; i < wheels.length; i++) {
                    if (wheelSize.bicycleStandard === wheels[i].bicycleStandard &&
                        wheelSize.inch === wheels[i].inch &&
                        wheelSize.iso === wheels[i].iso) {
                        return wheels[i];
                    }
                }
            },
            cleatTempData = function () {
                currentType = null;
                currentBrand = null;
                currentModel = null;
                currentGroupset = null;
            },
            checkBikeStatus = function (serialNumber, continueCallback, recycledCallback) {
                if (serialNumber) {
                    CustomerBikeResource.checkSN({id1: serialNumber}, function (data) {
                        if (data.bikeId) {
                            foundBike(data, serialNumber, recycledCallback);
                        } else {
                            continueCallback();
                        }
                    });
                } else {
                    noSerialNumberMessage(continueCallback);
                }
            },
            foundBike = function (bike, serialNumber, recycledCallback) {
                if (AuthService.isLogin() && bike.userId === AuthService.user().id) {
                    foundMyBike(bike, serialNumber);
                } else if (bike.status === BIKE_STATUS.USED || bike.status === BIKE_STATUS.RECYCLED && !recycledCallback) {
                    foundInUsedBike(bike, serialNumber);
                } else if (bike.status === BIKE_STATUS.RECYCLED) {
                    fountRecycledBike(bike, recycledCallback);
                } else if (bike.status === BIKE_STATUS.BROKEN) {
                    foundInUsedBike(bike, serialNumber);
                }
            },
            foundInUsedBike = function (bike, sn) {
                ModalService.showMessage(null, 'Bicycle with the serial number ' + sn + ' is already registered.');
            },
            fountRecycledBike = function (bike, recycledCallback) {
                ModalService.yesNoMessage(null, null,
                    'This bicycle was marked as recycled by its previous owner. <br>Do you want to restore and own ' +
                    'this bicycle?', function (response) {
                        if (response) {
                            recycledCallback(bike);
                        }
                    });
            },
            foundMyBike = function (bike, sn) {
                ModalService.showMessage(null, 'You already have a bicycle with the serial number ' + sn + '.');
            },
            noSerialNumberMessage = function (continueCallback) {
                ModalService.yesNoMessage(null, null,
                    'We recommended to enter bicycle serial number.<br><br>' +
                    'Do you want to continue?', function (response) {
                        if (response) {
                            continueCallback();
                        }
                    });
            },
            saveBikeToLocalStorage = function (bike) {
                var container = {
                    bike: bike,
                    components: bike.components,
                    groupset: currentGroupset,
                    brand: currentBrand,
                    model: currentModel
                };
                $requestStorage.putDraftedBike(container);
            },
            parseBikeFromLocalStorage = function () {
                let draftedBikeJson = $requestStorage.getDraftedBike();
                $requestStorage.removeDraftBike();
                return draftedBikeJson;
            },

            cancelCreateBike = function () {
                ModalService.yesNoMessage(null, '', 'Are you sure you want to cancel create your bicycle?', function (response) {
                    if (response === true) {
                        HistoryService.clear();
                        $location.search({});

                        var route = {
                            organisationId: $routeParams.organisationId,
                            stationId: $routeParams.stationId,
                            stationQuery: $routeParams.stationQuery
                        };

                        if (AuthService.isLogin()) {
                            if (AuthService.isBikeBusinessSupervisor() || AuthService.isBikeOrganisationOwner()) {
                                if (route.organisationId && !route.stationId && AuthService.isBikeBusinessSupervisor()) {
                                    $location.path('/organisation/' + route.organisationId + '/bikes').search({});
                                } else if (route.organisationId && !route.stationId  && AuthService.isBikeOrganisationOwner()) {
                                    $location.path('/company-dashboard/bicycles').search({organisation: route.organisationId});
                                } else if (route.organisationId && route.stationId && AuthService.isBikeOrganisationOwner()) {
                                    $location.url(
                                        '/company-dashboard/bicycles?organisation=' + route.organisationId +
                                        '&station=' + route.stationId +
                                        '&stationQuery=' + route.stationQuery
                                    );
                                } else if (route.organisationId && route.stationId && AuthService.isBikeBusinessSupervisor()) {
                                    $location.path('/organisation/' + route.organisationId + '/bikes')
                                        .search({stationId: route.stationId, stationQuery: route.stationQuery});
                                } else {
                                    $location.path('/dashboard');
                                }
                            } else {
                                $location.path('/dashboard');
                            }
                        } else {
                            $location.path('/');
                        }
                    }
                });
            },

            loadBikeDescDependencies = function (callbacl) {
                var promises = [];
                var response = {};

                promises.push($typeOfBikesService.load(function (types) {
                    response.types = types;
                }));

                promises.push($frameMaterialService.load(function (materials) {
                    response.materials = materials;
                }));

                $q.all(promises).finally(function () {
                    if (callbacl) {
                        callbacl(response);
                    }
                });
                return promises;
            },
            createDraftedBike = function (callback) {
                ModalService.yesNoMessage(null, '', 'You have already created an uncompleted bicycle profile.<br><br>' +
                    'Do you want to complete?', function (response) {
                    if (response !== true) {
                        $requestStorage.removeDraftBike();
                    }
                    callback();
                });
            },
            chooseColor = function ($event, colors, count, callback) {
                ModalService.chooseColor($event, colors, count, function (response) {
                    if (response) {
                        callback(response);
                    }
                });
            },
            showBikeStatusDescription = function ($event) {
                ModalService.showBikeStatusDescription($event);
            },
            getResourceClass = function () {
                var resource = CustomerBikeResource;
                if (AuthService.isMechanic()) {
                    resource = MechanicBikeResource;
                } else if (AuthService.isAdmin()) {
                    resource = AdminBikeResource;
                } else if (AuthService.isBusinessAccount()) {
                    resource = PartnerBikeResource;
                }

                return resource;
            };

        return {
            getBikeType: getBikeType,
            setBikeType: setBikeType,
            getBrand: getBrand,
            setBrand: setBrand,
            getModel: getModel,
            setModel: setModel,
            getGroupset: getGroupset,
            getGroupsetByName: getGroupsetByName,
            setGroupset: setGroupset,
            getFrameMaterialByName: getFrameMaterialByName,
            getWheelSizeByName: getWheelSizeByName,
            cleatTempData: cleatTempData,
            checkBikeStatus: checkBikeStatus,
            chooseColor: chooseColor,
            showBikeStatusDescription: showBikeStatusDescription,
            saveBikeToLocalStorage: saveBikeToLocalStorage,
            parseBikeFromLocalStorage: parseBikeFromLocalStorage,
            cancelCreateBike: cancelCreateBike,
            loadBikeDescDependencies: loadBikeDescDependencies,
            createDraftedBike: createDraftedBike,
            getResourceClass: getResourceClass
        };
    }
]);
