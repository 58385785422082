import app from "js/legacy-app";

app.directive('vtTab', function () {
    return {
        require: '^vtTabset',
        restrict: 'E',
        transclude: true,
        replace: true,
        scope: {
            url: '@'
        },
        link: function (scope, element, attrs, tabsetCtrl) {
            tabsetCtrl.addTab(scope);

            scope.select = function () {
                tabsetCtrl.selectTab(scope);
            };
        },
        template:
        '<button class="vt-tabset__button md-button" ' +
        'ng-click="select()"' +
        'ng-class="{ \'vt-tabset__button_active\' : active }" ng-transclude>' +
        '</button>'
    };
});