import app from "js/legacy-app";

app.constant("MECHANIC_CATEGORIES", {
    INDIVIDUAL: 'Individual mechanic',
    SHOP: 'Bike shop',
    MOBILE: 'Mobile bike shop'
});

app.constant('COMPONENT_CONDITIONS', [
    {id: 1, value: 'new'},
    {id: 2, value: 'ok'},
    {id: 3, value: 'bad'}
]);

app.constant('ADD_BIKE_TABS', {
    0: {
        selectedTab: 0,
        title: 'Add Bicycle'
    },
    1: {
        selectedTab: 1,
        title: 'Bicycle Description'
    },
    2: {
        selectedTab: 2,
        title: 'Bicycle Components'
    },
    3: {
        selectedTab: 3,
        title: 'Bicycle Photos'
    },
    4: {
        selectedTab: 4,
        title: 'Bicycle Review'
    },
    5: {
        selectedTab: 5,
        title: 'Bicycle Agreement'
    }
});

app.constant('BIKE_STATUS', {
    USED: 'USED',
    BROKEN: 'BROKEN',
    RECYCLED: 'RECYCLED'
});

Math.easeInOutQuad = function (t, s, c, d) {
    t /= d / 2;
    if (t < 1) {
        return c / 2 * t * t + s;
    }

    t--;
    return -c / 2 * (t * (t - 2) - 1) + s;
};

Object.defineProperty(Array.prototype, 'indexOfObject', {
    enumerable: false,
    value: function (prop, value) {
        for (var i = 0; i < this.length; i++) {
            if (this[i][prop] === value) {
                return i;
            }
        }

        return -1;
    }
});
