import app from "js/legacy-app";

app.controller('CreateOrganisationCtrl',
    function ($scope, ModalService, Organisation, ToggleLD, Notification, $location, ErrorHandler,
              LocationService, CoreServiceBuckets) {

        'ngInject';

        $scope.coc = {

            disabledCreate: false,

            user: {
                organisation: {
                    address: {}
                }
            },

            validAddress: function (address) {
                if (!address || !address.address) {
                    return true;
                }

                var error = LocationService.validateProfileAddress(address);
                if (error) {
                    Notification.error(error);
                    return false;
                }

                return true;
            },

            get: function () {
                return {
                    name: $scope.coc.user.name,
                    surname: $scope.coc.user.surname,
                    email: $scope.coc.user.email,
                    phone: $scope.coc.user.phone.number,
                    organisation: {
                        name: $scope.coc.user.organisation.name,
                        link: $scope.coc.user.organisation.link,
                        address: LocationService.mapAddress($scope.coc.user.organisation.address),
                        serviceBucket: $scope.coc.user.organisation.serviceBucket
                    }
                }
            },

            create: function (form) {
                if (form.$valid && !$scope.coc.user.phone.$error) {
                    $scope.coc.disabledCreate = true;
                    if (!$scope.coc.validAddress($scope.coc.user.organisation.address)) {
                        return;
                    }

                    ToggleLD.show();
                    Organisation.save($scope.coc.get(), function (data) {
                        ToggleLD.hide();
                        Notification.success('Organisation has been successfully created');
                        $scope.coc.disabledCreate = false;
                        $location.path('/dashboard').search({});
                    }, function (error) {
                        ToggleLD.hide();
                        $scope.coc.disabledCreate = false;
                        ErrorHandler.onError(error, "Failed to create organisation");
                    });
                }
            }
        };

        (function init() {
            CoreServiceBuckets.get({size: 100}, function (res) {
                $scope.coc.serviceBuckets = [].concat(res.data.map((sb) => {
                    return {val: sb.type, display: sb.name};
                }));
            });
        })();
    }
);
