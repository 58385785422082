import angular from "angular";

export default function ($routeSegmentProvider, $routeProvider, $locationProvider) {
    'ngInject';

    // enable html5 mode
    $locationProvider.html5Mode(true).hashPrefix('!');

    $routeSegmentProvider.options.autoLoadTemplates = true;


    const originalWhen = $routeSegmentProvider.segment;

    $routeSegmentProvider.segment = function (name, params) {
        resolveTemplate(params);
        params.resolve = params.resolve || {};
        params.resolveFailed = {};
        angular.extend(params.resolve, {
            auth: ['accessCheckingService', function (accessCheckingService) {
                return accessCheckingService.check(params.access);
            }]
        });

        return originalWhen.call($routeSegmentProvider, name, params);
    };

    const resolveTemplate = function (params) {
        if (angular.isFunction(params.module)) {
            params.template = function ($ocLazyLoad, $q) {
                const deferred = $q.defer();
                params.module()
                    .then(mod => {
                        $ocLazyLoad.load(mod.default.module);
                        deferred.resolve(mod.default.template);
                    })
                    .catch(err => {
                        deferred.reject(err);
                    });
                return deferred.promise;
            };
            params.template.$inject = ['$ocLazyLoad', '$q'];
        } else if (angular.isFunction(params.loadModule)) {
            const template = params.template;
            params.template = function ($ocLazyLoad, $q) {
                const deferred = $q.defer();
                params.loadModule()
                    .then(mod => {
                        $ocLazyLoad.load(mod.default);
                        deferred.resolve(template);
                    })
                    .catch(err => {
                        deferred.reject(err);
                    });
                return deferred.promise;
            };
            params.template.$inject = ['$ocLazyLoad', '$q'];
        }
    }
}

