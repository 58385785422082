import app from "js/legacy-app";

app.controller('RealtimeStatisticsCtrl', ['$scope', 'AdminBikeResource', 'AdminRequest', 'User',
    function ($scope, AdminBikeResource, AdminRequest, User) {

        $scope.totalBikes = function () {
            return AdminBikeResource.totalCount({}).$promise;
        };

        $scope.totalRequests = function () {
            return AdminRequest.totalCount({}).$promise;
        };

        $scope.totalAwaitingRequests = function () {
            return AdminRequest.totalCount({status: 'IN_PROGRESS', notConfirmed: true}).$promise;
        };

        $scope.totalResolvedRequests = function () {
            return AdminRequest.totalCount({status: 'RESOLVED'}).$promise;
        };

        $scope.totalInProgressRequests = function () {
            return AdminRequest.totalCount({status: 'IN_PROGRESS', notConfirmed: false}).$promise;
        };

        $scope.totalUsers = function () {
            return User.totalCount({roles: ['USER']}).$promise;
        };

        $scope.totalMechanics = function () {
            return User.totalCount({roles: ['MECHANIC']}).$promise;
        };

        $scope.totalActiveUsers = function () {
            return User.totalCount({online: true, roles: ['USER']}).$promise;
        };

        $scope.totalActiveMechanics = function () {
            return User.totalCount({online: true, roles: ['MECHANIC']}).$promise;
        };

    }]);
