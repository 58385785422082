import app from "js/legacy-app";

app.service('Tools', ['$resource', function ($resource) {
    return $resource('/api/tools/:id', {}, {
        update: {
            method: "PUT",
            params: {id: ""},
            isArray: false
        },
        searchByNameLike: {
            method: "GET",
            isArray: false,
            params: {id: "search/by-name-like"}
        },
        list: {
            method: "GET",
            isArray: false,
            params: {id: "list"}
        }
    });
}]);