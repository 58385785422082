import app from "js/legacy-app";

app.service('AdminRequest', ['$resource', function ($resource) {
    return $resource('/api/admin-request/:id/:id1/:id2', {}, {
        countByStatus: {
            method: "GET",
            params: {id: "count/by-status"},
            isArray: true
        },
        countByCity: {
            method: "GET",
            params: {id: "count/by-city"},
            isArray: true
        },
        countCreated: {
            method: "GET",
            params: {id: "count/by-created-date"},
            isArray: true
        },
        transfer: {
            method: "PUT",
            isArray: false,
            params: {id1: "transfer"}
        },
        retryTransfer: {
            method: "PUT",
            isArray: false,
            params: {id1: "retry-transfer"}
        },
        refund: {
            method: "PUT",
            isArray: false,
            params: {id1: "refund"}
        },
        totalCount: {
            method: "GET",
            params: {id: "total-count"},
            isArray: false
        },
        reassign: {
            method: "PUT",
            params: {id1: "reassign"},
            isArray: false
        },
        updateAppointmentDate: {
            method: "PUT",
            params: {id1: "appointment-date"},
            isArray: false
        },
        updateDescription: {
            method: "PUT",
            params: {id1: "update-description"},
            isArray: false
        },
        updateLocation: {
            method: "PUT",
            params: {id1: "update-location"},
            isArray: false
        },
        assignSupportResponsible: {
            method: "PUT",
            params: {id1: "assign-support-responsible"},
            isArray: false
        },
        regenerateRequest: {
            method: "PUT",
            params: {id1: "regenerate-report"},
            isArray: false
        }
    });
}]);
