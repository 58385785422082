import app from "js/legacy-app";

app.service('$serviceCategories', ['CustomServiceCategories', 'CoreServiceCategories', 'AuthService',
    function (CustomServiceCategories, CoreServiceCategories, AuthService) {

        var getCategoryResource = function () {
            if (AuthService.isAdmin()) {
                return CoreServiceCategories;
            }
            if (AuthService.isMechanic()) {
                return CustomServiceCategories;
            }

            return CustomServiceCategories;
        };

        return {
            getCategoryResource: getCategoryResource
        };
    }
]);

