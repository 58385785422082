import app from "js/legacy-app";

app.service('FileService', ['$mdDialog', 'Notification',
    function ($mdDialog, Notification) {

        var mimePdf = 'application/pdf',
            mimeImages = 'image/';

        var isValidFile = function (file, mimeTypes) {
                if (!file) {
                    return false;
                }

                if (file.$error) {
                    if (file.$error === 'pattern') {
                        Notification.error("Supported file formats are: " + file.$errorParam);
                    } else {
                        Notification.error("File is not supported");
                    }
                    return false;
                }

                if (!isCorrectSize(file.size)) {
                    Notification.error('File must be less than 10 Mb.');
                    return false;
                }

                if (mimeTypes) {
                    var contentType = file.contentType ? file.contentType : file.type;
                    for (var i = 0; i < mimeTypes.length; ++i) {
                        if (contentType.match(mimeTypes[i])) {
                            return true;
                        }
                    }

                    Notification.error('Unsupported file format.');
                    return false;
                }

                return true;
            },
            isCorrectSize = function (fileSize) {
                return (fileSize > 0 && fileSize <= 10000000);
            },
            isImage = function (fileType) {
                return fileType.match(mimeImages);
            },
            isPdf = function (fileType) {
                return fileType.match(mimePdf);
            },
            isValidCsvFile = function (file) {
                if (!file) {
                    return false;
                }

                if (file.$error) {
                    if (file.$error === 'pattern') {
                        Notification.error("Supported file formats are: " + file.$errorParam);
                    } else {
                        Notification.error("File is not supported");
                    }
                    return false;
                }

                var contentType = file.contentType ? file.contentType : file.type;
                if (!contentType.match('csv') && !contentType.match('excel')) {
                    Notification.error('Unsupported file format.');
                    return false;
                }

                return true;
            };

        var convertImageToCanvas = function (file, callback) {
            if (isPdf(file.type)) {
                return;
            }

            var image = {
                original: "",
                thumbnail: "",
                extension: "",
                mimeType: ""
            };
            var reader = new FileReader();
            var quality = chooseQuality(file.size);
            var img = document.createElement("img");
            reader.onload = function (e) {
                img.onload = function () {
                    var transform = "none";
                    window.EXIF.getData(img, function () {
                        if (this.exifdata.Orientation === 8) {
                            transform = "left";
                        } else if (this.exifdata.Orientation === 6) {
                            transform = "right";
                        }
                        image.extension = file.name.substr(file.name.lastIndexOf("."));
                        compress(img, quality, image.extension.substr(1), function (result) {
                            image.original = result.src;
                            var canvasThumbnail = document.createElement("canvas");
                            var ctxThumbnail = canvasThumbnail.getContext("2d");
                            ctxThumbnail.drawImage(img, 0, 0);
                            var MAX_WIDTH = 1000;
                            var MAX_HEIGHT = 1000;
                            var width = img.width;
                            var height = img.height;

                            if (width > height) {
                                if (width > MAX_WIDTH) {
                                    height *= MAX_WIDTH / width;
                                    width = MAX_WIDTH;
                                }
                            } else {
                                if (height > MAX_HEIGHT) {
                                    width *= MAX_HEIGHT / height;
                                    height = MAX_HEIGHT;
                                }
                            }
                            canvasThumbnail.width = width;
                            canvasThumbnail.height = height;
                            ctxThumbnail = canvasThumbnail.getContext("2d");
                            ctxThumbnail.drawImage(img, 0, 0, width, height);
                            image.thumbnail = canvasThumbnail.toDataURL(file.type, 0.5);


                            image.mimeType = file.type;

                            if (callback) {
                                callback(image);
                            }
                        }, transform);
                    });
                };

                img.onerror = function () {
                    callback(image, 'invalid_format');
                };

                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        };

        var convertImageToCanvasNew = function (file, callback) {

            var image = {
                file: "",
                extension: "",
                mimeType: ""
            };
            var reader = new FileReader();
            var quality = chooseQuality(file.size);
            var img = document.createElement("img");
            reader.onload = function (e) {
                img.onload = function () {
                    var transform = "none";
                    window.EXIF.getData(img, function () {
                        if (this.exifdata.Orientation === 8) {
                            transform = "left";
                        } else if (this.exifdata.Orientation === 6) {
                            transform = "right";
                        }
                        image.extension = file.name.substr(file.name.lastIndexOf("."));
                        compress(img, quality, image.extension.substr(1), function (result) {
                            image.original = result.src;
                            var canvas = document.createElement("canvas");
                            var ctx = canvas.getContext("2d");
                            ctx.drawImage(img, 0, 0);
                            var MAX_WIDTH = 1000;
                            var MAX_HEIGHT = 1000;
                            var width = img.width;
                            var height = img.height;

                            if (width > height) {
                                if (width > MAX_WIDTH) {
                                    height *= MAX_WIDTH / width;
                                    width = MAX_WIDTH;
                                }
                            } else {
                                if (height > MAX_HEIGHT) {
                                    width *= MAX_HEIGHT / height;
                                    height = MAX_HEIGHT;
                                }
                            }
                            canvas.width = width;
                            canvas.height = height;
                            ctx = canvas.getContext("2d");
                            ctx.drawImage(img, 0, 0, width, height);
                            image.file = canvas.toDataURL(file.type, 0.5);

                            image.mimeType = file.type;

                            if (callback) {
                                callback(image);
                            }
                        }, transform);
                    });
                };

                img.src = e.target.result;
            };
            reader.readAsDataURL(file);
        };

        var convertOriginalImageToCanvas = function (file, thumbnail, callback) {
            if (isPdf(file.type)) {
                return;
            }

            var image = {
                original: "",
                thumbnail: thumbnail,
                extension: file.name.substr(file.name.lastIndexOf(".")),
                mimeType: file.type
            };

            var reader = new FileReader();
            var quality = chooseQuality(file.size);
            var img = document.createElement("img");
            var transform = "none";
            window.EXIF.getData(file, function () {
                /* if (this.exifdata.Orientation === 8) {
                 transform = "left";
                 } else if (this.exifdata.Orientation === 6) {
                 transform = "right";
                 }*/
                reader.onload = function (e) {
                    img.onload = function () {
                        compress(img, quality, image.extension.substr(1), function (result) {
                            image.original = result.src;
                            if (callback) {
                                callback(image);
                            }
                        }, transform);
                    };

                    img.onerror = function () {
                        callback(image, 'invalid_format');
                    };

                    img.src = e.target.result;

                };
                reader.readAsDataURL(file);
            });
        };

        var compress = function (source_img_obj, quality, output_format, callback, transform) {

            var mime_type = "image/jpeg";
            if (typeof output_format !== "undefined" && output_format === "png") {
                mime_type = "image/png";
            }

            var cvs = document.createElement('canvas');
            var width = source_img_obj.naturalWidth;
            var height = source_img_obj.naturalHeight;
            var ctx = cvs.getContext("2d");

            cvs.width = width;
            cvs.height = height;

            var scale;
            if (transform === 'left') {
                scale = width / height;
                cvs.width = width;
                cvs.height = height * scale * scale;
                ctx.setTransform(0, -1 * scale * scale, 1, 0, 0, height * scale * scale);
                ctx.drawImage(source_img_obj, 0, 0, height, width);
            } else if (transform === 'right') {
                scale = width / height;
                cvs.width = width;
                cvs.height = height * scale * scale;
                ctx.setTransform(0, 1 * scale, -1 * scale, 0, width, 0);
                ctx.drawImage(source_img_obj, 0, 0, width, height);
            } else if (transform === 'flip') {
                ctx.setTransform(1, 0, 0, -1, 0, height);
                ctx.drawImage(source_img_obj, 0, 0, width, height);
            } else {
                ctx.setTransform(1, 0, 0, 1, 0, 0);
                ctx.drawImage(source_img_obj, 0, 0, width, height);
            }
            ctx.setTransform(1, 0, 0, 1, 0, 0);

            var newImageData = cvs.toDataURL(mime_type, quality / 100);
            var result_image_obj = new Image();

            result_image_obj.onload = function () {
                callback(result_image_obj);
            };

            result_image_obj.src = newImageData;
        };

        var chooseQuality = function (size) {
            var quality = 0;
            if (size >= 5000000) {
                quality = 27;
            } else if (size < 5000000 && size > 2000000) {
                quality = 35;
            } else {
                quality = 40;
            }
            return quality;
        };

        return {
            mimePdf: mimePdf,
            mimeImages: mimeImages,
            isValidFile: isValidFile,
            isValidCsvFile: isValidCsvFile,
            isCorrectSize: isCorrectSize,
            isImage: isImage,
            isPdf: isPdf,
            convertImageToCanvas: convertImageToCanvas,
            convertOriginalImageToCanvas: convertOriginalImageToCanvas,
            convertImageToCanvasNew: convertImageToCanvasNew
        };
    }
]);
