import angular from "angular";

import PageLayout from "../../../vt-generic/components/page";
import CommonReassignBookingModuleRouting from "./reassing-booking.module.routing";
import ReassignBookingComponent from "../../../vt-domain/components/reassign-booking";
import ReassignBookingPageComponent from "./reassing-booking.component";

export default angular
    .module("vt-common-booking-reassign", [
        PageLayout.name,
        ReassignBookingComponent.name
    ])
    .config(CommonReassignBookingModuleRouting)
    .component("vuiReassignBooking", ReassignBookingPageComponent);

