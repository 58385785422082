import app from "js/legacy-app";

app.directive('glItem', [function () {
    return {
        templateUrl: "template/component/widget/pageable-list/grid-view/gl-item.html",
        replace: true,
        transclude: {
            body: 'glItemBody',
            actions: '?glItemActions',
            details: 'glItemDetails',
            footer: '?glItemFooter'
        },
        scope: {
            viewItemUrl: '@glViewItemUrl'
        },
        controller: ['$scope', function ($scope) {
            this.viewItemUrl = $scope.viewItemUrl;
        }],
        link: function ($scope, $elem, $attrs, $ctrl, $transclude) {
            $scope.showActions = $transclude.isSlotFilled('actions');
        }
    };
}]);