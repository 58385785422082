import app from "js/legacy-app";

app.directive('changeProfile', [function () {
    return {
        templateUrl: "template/profile/tabs/change-profile/profile.html",
        replace: true,
        link: function ($scope, $element, $attrs) {
            $scope.profile = $scope.$eval($attrs.profile);
        },

        controller: ['$scope', 'User', 'AuthService', 'Notification', 'PhoneService', 'ToggleLD', 'ErrorHandler', 'MECHANIC_CATEGORIES',
            function ($scope, User, AuthService, Notification, PhoneService, ToggleLD, ErrorHandler, MECHANIC_CATEGORIES) {

                $scope.changeProfile = {
                    maxDate: new Date()
                };

                $scope.changeProfile.mechanicCategories = MECHANIC_CATEGORIES;

                $scope.changeProfile.onChangeNickname = (text) => {
                    $scope.profile.user.nickname = text;
                };

                $scope.changeProfile.refresh = function (callback) {
                    User.current(function (data) {
                        $scope.profile = {
                            user: data
                        };

                        $scope.$parent.profile.user = data;

                        $scope.profile.user.phone = {
                            number: $scope.profile.user.phone
                        };

                        if ($scope.profile.user.dateOfBirthday) {
                            $scope.profile.user.dateOfBirthday = new Date($scope.profile.user.dateOfBirthday);
                        }

                        if (callback) {
                            callback($scope.profile.user);
                        }
                    });
                };

                $scope.changeProfile.resendActivationEmail = function () {
                    ToggleLD.show();
                    User.reactivationEmail({}, {}, function () {
                        ToggleLD.hide();
                        Notification.success("Go to this email and click on the link to finish your account registration");
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error, "Failed to resend activation email");
                    });
                };

                $scope.changeProfile.update = function (form) {
                    if (form.$valid && !$scope.profile.user.phone.$error) {

                        var userRequest = {
                            name: $scope.profile.user.name,
                            surname: $scope.profile.user.surname,
                            nickname: $scope.profile.user.nickname,
                            phone: $scope.profile.user.phone.number,
                            mechanicCategory: $scope.profile.user.mechanicCategory,
                            dateOfBirthday: new Date($scope.profile.user.dateOfBirthday)
                        };

                        ToggleLD.show();
                        User.updateProfile({}, userRequest, function (data) {
                            $scope.profile.user = data;
                            $scope.$parent.profile.user = data;
                            $scope.profile.user.phone = {
                                number: $scope.profile.user.phone
                            };

                            if ($scope.profile.user.dateOfBirthday) {
                                $scope.profile.user.dateOfBirthday = new Date($scope.profile.user.dateOfBirthday);
                            }

                            AuthService.updateUser({
                                name: data.name,
                                surname: data.surname,
                                nickname: data.nickname,
                                phone: data.phone,
                                mechanicCategory: data.mechanicCategory,
                                dateOfBirthday: data.dateOfBirthday
                            });
                            ToggleLD.hide();
                            Notification.success('Profile was successfully changed');
                        }, function (error) {
                            ToggleLD.hide();
                            ErrorHandler.onError(error, 'Failed to change profile', 'ERROR', form);
                        });
                    }

                };

                (function init() {
                    $scope.changeProfile.maxDate.setYear($scope.changeProfile.maxDate.getFullYear() - 18);
                    $scope.changeProfile.refresh();
                })();
            }
        ]
    };
}]);
