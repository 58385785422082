import app from "js/legacy-app";
import angular from "angular";

app.service('$vtSidenav', ['$mdSidenav', '$pageScroll', 'SidenavLD', function ($mdSidenav, $pageScroll, SidenavLD) {

    var sidenavs = {};

    var register = function (id, sidenav) {
        if (!sidenav || !angular.isFunction(sidenav.options.onOpen)) {
            throw new Error("Invalid sidenav definition");
        }

        sidenavs[id] = angular.extend({id: id}, baseSidenav, sidenav);
    };

    var baseSidenav = {
        open: function (model) {
            var id = this.id;
            $pageScroll.hide();
            SidenavLD.show();
            $mdSidenav(id).open().then(function () {
                var promise = sidenavs[id].options.onOpen(model, onSuccess, onError);
                if (promise && angular.isFunction(promise.then)) {
                    promise.finally(function () {
                        SidenavLD.hide();
                    });
                } else {
                    SidenavLD.hide();
                }
            }).catch(function () {
                SidenavLD.hide();
            });

            $mdSidenav(id).onClose(function () {
                $pageScroll.show();
            });

            function onSuccess() {
                SidenavLD.hide();
            }

            function onError() {
                SidenavLD.hide();
            }
        },

        close: function () {
            var id = this.id;
            if (sidenavs[id]) {
                $mdSidenav(id).close().then(function () {
                    if (sidenavs[id].options.onClose) {
                        sidenavs[id].options.onClose();
                    }
                });
            }
        }
    };

    return function (id, sidenav) {
        return sidenav ? register(id, sidenav) : sidenavs[id];
    };
}]);

