import app from "js/legacy-app";

app.service('Service', ['$resource', function ($resource) {
    return $resource('/api/services/:id/:id1/:id2/:id3', {}, {
        getStep: {
            method: "GET",
            isArray: false,
            params: {id: "steps"}
        },
        getSteps: {
            method: "GET",
            isArray: false,
            params: {id: "steps"}
        },
        getByCode: {
            method: "GET",
            isArray: false,
            params: {id: "by-code"}
        },
        getMechanicServiceTree: {
            method: "GET",
            isArray: false,
            params: {id: "tree"}
        },
        getMechanicPopularServices: {
            method: "GET",
            isArray: true,
            params: {id: "by-mechanic", id2: "popular"}
        },
        autocomplete: {
            method: "GET",
            isArray: true,
            params: {id: "autocomplete"}
        }
    });
}]);