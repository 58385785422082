import app from "js/legacy-app";

app.directive('randomBackgroundImg', [function () {
    return {
        restrict: 'A',
        scope: {
            options: "=randomBackgroundImg"
        },
        link: function (scope, element) {
            var options = scope.options;
            var baseUrl = options.baseUrl;
            var ext = options.ext;
            var min = 1;
            var max = options.max;

            var index = Math.round(Math.random() * (max - min)) + min;

            var url = baseUrl + index + "." + ext;
            element.css({
                'background-image': 'url(' + url + ')',
                'background-size': 'cover'
            });
        }
    };
}]);
