import app from "js/legacy-app";
import mechanicDefaultImage from "img/mechanic-default-picture.png";

const mechanicImages = require.context('img/icons/mechanic', true);

app.controller('ConfirmAdditionalBookingDialogCtrl', ['$scope', '$mdDialog', '$mdMedia', 'request', 'User', 'AuthService',
    function ($scope, $mdDialog, $mdMedia, request, User, AuthService) {

        $scope.cabdc = {
            mdMedia: $mdMedia,
            request: request,
            auth: AuthService
        };

        $scope.cabdc.cancel = function () {
            $mdDialog.hide();
        };

        $scope.cabdc.confirm = function () {
            $mdDialog.hide({action: 'CONFIRM'});
        };

        $scope.cabdc.declineOffer = function () {
            $mdDialog.hide({action: 'DECLINE'});
        };

        $scope.cabdc.offerTime = function () {
            $mdDialog.hide({action: 'OFFER_TIME'});
        };

        $scope.cabdc.openMenu = function (mdMenu, event) {
            mdMenu.open(event);
        };

        $scope.cabdc.getMechanicCategoryIcon = function () {
            if (!$scope.cabdc.currentUser || !$scope.cabdc.currentUser.mechanicCategory) {
                return '';
            }

            return mechanicImages('./' + $scope.cabdc.currentUser.mechanicCategory.toLowerCase() + '-type.png', true);
        };

        $scope.cabdc.getCurrentUserProfileLink = function () {
            let currentUser = $scope.cabdc.currentUser;

            if (!currentUser) {
                return;
            }

            if (AuthService.isMechanic()) {
                return '/user/' + $scope.cabdc.currentUser.id;
            }

            let id = currentUser.nickname ? currentUser.nickname : currentUser.id;
            return '/mechanic-public/' + id;
        };

        (function init() {
            var activeNegotiation = request.negotiations.filter(function (n) {
                return n.status === 'AWAITING_CONFIRMATION';
            })[0];

            if (!activeNegotiation) {
                $mdDialog.hide();
                return;
            }

            $scope.cabdc.activeNegotiation = activeNegotiation;
            var currentUserId;

            currentUserId = activeNegotiation.user.id;

            User.getPublicUserInfo({id1: currentUserId}, function (user) {
                $scope.cabdc.currentUser = user;
                if (!user.avatar || !user.avatar.thumbnail) {
                    user.avatar = {
                        thumbnail: mechanicDefaultImage
                    };
                }
            });
        })();
    }
]);