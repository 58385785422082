import app from "js/legacy-app";

app.controller('UploadInsuranceCtrl', ['$scope', '$location', function ($scope, $location) {

    $scope.windowTitle = 'Upload new insurance';

    $scope.onComplete = () => {
        $location.path('/settings/insurance').search({});
    }

}]);