export default {
    template: require('./service-booking.component.html'),
    transclude: true,
    controller: function ($routeParams, $requestService, AuthService, $location, Notification, LocationService, $dateTimeService) {
        'ngInject';

        this.onSave = (booking) => {
            $location.path('request/' + booking.id).search({});
        };

        this.onSubmit = (serviceBooking) => {
            this.serviceBooking = serviceBooking;
            this.serviceBookingRequest = buildBookingRequest();
        };

        this.valid = () => {
            let serviceBooking = this.serviceBooking;
            let bikes = serviceBooking.bikes;

            if (!serviceBooking.serviceDetails) {
                Notification.warning("Service details must be specified");
                return false;
            }

            if (serviceBooking.serviceDetails.needBike && (!bikes || bikes.length === 0)) {
                Notification.warning("At least one bicycle must be specified");
                return false;
            }

            if (!serviceBooking.serviceDetails.group && !serviceBooking.serviceDetails.online && bikes && bikes.length > 1) {
                Notification.warning("Amount of bicycles must be no more than 1");
                return false;
            }

            return true;
        };

        this.$onInit = () => {
            let self = this;

            if ($routeParams.relatedBookingId) {
                $requestService.getRequestResource().get({id: $routeParams.relatedBookingId}, (booking) => {
                    LocationService.getLocationOffset(booking.serviceLocation, function (offset) {
                        booking.serviceLocation.offset = offset;
                        self.serviceBooking = buildModelFromRelatedBooking(booking);
                    });
                });
            } else {
                self.serviceBooking = customBookingMode();
            }
        };

        let buildModelFromRelatedBooking = (booking) => {
            if (booking.bikes) {
                booking.bikes.forEach((b) => {
                    b.old = true;
                });
            }

            return {
                serviceLocation: LocationService.mapAddress(booking.serviceLocation),
                appointmentDate: getCustomAppointmentDate(booking.serviceLocation.offset),
                customer: {
                    user: booking.user,
                    whoPays: booking.userId,
                    organisation: booking.organizationName ? {
                        name: booking.organizationName,
                        organisationId: booking.organisation.organisationId,
                        stationId: booking.organisation.stationId
                    } : null
                },
                bikes: booking.bikes,
            }
        };

        let customBookingMode = () => {
            return {
                appointmentDate: getCustomAppointmentDate()
            };
        };

        let getCustomAppointmentDate = (offset) => {
            let timeByOffset;
            if (offset) {
                timeByOffset = $dateTimeService.getTimeByOffset(offset, new Date());
            } else {
                timeByOffset = new Date();
            }

            return {
                currently: true,
                date: timeByOffset.toISOString().slice(0, 10),
                time: timeByOffset.toLocaleTimeString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: false
                })
            };
        };

        let buildBookingRequest = () => {
            let bikes = [];

            this.serviceBooking.bikes.forEach((b) => {
                if (b.old) {
                    bikes.push({id: b.id, old: true});
                    return;
                }

                if (b.new) {
                    bikes.push({
                        serialNumber: b.serialNumber,
                        make: b.make,
                        model: b.model,
                        type: b.type,
                        new: true,
                        owningOrganization: b.owningOrganization
                    });
                }
            });

            let customer = this.serviceBooking.customer;
            let serviceDetails = this.serviceBooking.serviceDetails;
            let notes = this.serviceBooking.notes;

            return {
                relatedRequestId: $routeParams.relatedBookingId,
                serviceLocation: this.serviceBooking.serviceLocation,
                appointmentDate: {
                    date: this.serviceBooking.appointmentDate.date,
                    time: this.serviceBooking.appointmentDate.time
                },
                userId: customer ? customer.whoPays : null,
                organisationId: customer && customer.organisation ? customer.organisation.organisationId : null,
                serviceDetails: serviceDetails ? {
                    id: serviceDetails.id,
                    name: serviceDetails.name,
                    timeToComplete: serviceDetails.timeToComplete,
                    price: serviceDetails.price
                } : null,
                notes: {
                    images: notes ? notes.photos : null,
                    description: notes ? notes.description : null
                },
                bikes: serviceDetails && serviceDetails.online ? [] : bikes
            }
        };
    }
};

