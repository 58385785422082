import app from "js/legacy-app";

app.directive('gliBody', [function () {
    return {
        templateUrl: "template/component/widget/pageable-list/grid-view/gli-body.html",
        replace: true,
        transclude: true,
        require: '^^glItem',
        scope: {
            title: '@gliTitle',
            defaultImage: '@gliDefaultImg',
            image: '@gliImg'
        },
        link: function ($scope, $elem, $attrs, glItem) {
            $scope.viewItemUrl = glItem.viewItemUrl;
        }
    };
}]);