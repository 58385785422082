import app from "js/legacy-app";

app.directive('cookieBar', ['$requestStorage', function ($requestStorage) {
    return {
        template: '<div class="cc_banner-wrapper" ng-if="showMessage">' +
            '   <div class="cc_banner cc_container cc_container--open">' +
            '       <a href="" class="cc_btn cc_btn_accept_all" ng-click="getIt()">Got it!</a>' +
            '       <p class="cc_message">' +
            '           Velotooler uses cookies to ensure you get the best experience on our website. See our' +
            '           <a target="_blank" rel="noopener noreferrer" class="cc_more_info" href="/privacy-policy">Privacy Policy</a>' +
            '       </p>' +
            '   </div>' +
            '</div>',
        link: function ($scope, $element, $attrs) {
            if ($requestStorage.isExistAcceptCookiesPolicy()) {
                $scope.showMessage = !$requestStorage.getAcceptCookiesPolicy();
            } else {
                $scope.showMessage = true;
                $requestStorage.putAcceptCookiesPolicy(!$scope.showMessage);
            }

            $scope.getIt = function () {
                $scope.showMessage = false;
                $requestStorage.putAcceptCookiesPolicy(!$scope.showMessage);
            };
        }
    };
}]);
