import app from "js/legacy-app";

app.directive('plConstantFilter', ['$location', function ($location) {
    return {
        template: "",
        require: '^^plFilter',
        scope: {
            name: '@cfName',
            model: "=cfModel"
        },
        link: function ($scope, element, $attrs, plFilter) {
            $scope.cf = {};
            $scope.cf.filter = plFilter.filter;

            $scope.cf.filter.register($scope.name, $scope.cf);

            $scope.cf.isSelected = function () {
                return true;
            };

            $scope.cf.isSilent = function () {
                return true;
            };

            $scope.cf.getValue = function () {
                return $scope.model;
            };

            $scope.cf.serialize = function () {
                var serialized = {};
                serialized[$scope.name] = $scope.cf.getValue();
                return serialized;
            };

            $scope.cf.deserialize = function (queryString) {
                if (queryString[$scope.name]) {
                    $scope.model = queryString[$scope.name];
                }
            };

            $scope.cf.clear = function () {};
            $scope.cf.init = init;


            function init() {
                $scope.cf.deserialize($location.search());
                $scope.cf.filter.initialized($scope.name);
            }

            init();
        }
    };
}]);