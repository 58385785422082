import app from "js/legacy-app";

app.directive('vtFormBody', ['$mobileBodyHeightCalculator',
    function ($mobileBodyHeightCalculator) {
        return {
            templateUrl: "components/form/vt-form-body.html",
            replace: true,
            transclude: true,
            require: '^^vtForm',

            link: function ($scope, elem, attrs, form) {
                $scope.vtForm = form.vf.vtForm;
            },

            controller: ['$scope', function ($scope) {
                $scope.fb = {};

                $scope.fb.height = function () {
                    var footer = document.querySelector('.vtform__footer');
                    var header = document.querySelector('.vtform__header');
                    return $mobileBodyHeightCalculator.calculate(header, footer);
                };


                $scope.fb.top = function () {
                    var header = document.querySelector('.vtform__header');
                    return header.clientHeight + 'px';
                };
            }]
        };
    }]);