import app from "js/legacy-app";
import angular from "angular";

app.directive('plFilter', ['$location', '$parse', '$rootScope', '$mdMedia', '$document', '$timeout',
    function ($location, $parse, $rootScope, $mdMedia, $document, $timeout) {
        return {
            templateUrl: "template/component/widget/pageable-list/pl-filter.html",
            replace: true,
            require: '^^pageableList',
            transclude: true,
            controller: ['$scope', function ($scope) {
                var pl = $scope.pageableList;

                $scope.filter = {};
                $scope.filter.filters = {};
                $scope.filter.expanded = false;
                $scope.$mdMedia = $mdMedia;

                $scope.filter.apply = function () {
                    if (!$scope.filter.isMobile()) {
                        $scope.filter.forceApply();
                    }
                };

                $scope.filter.forceApply = function () {
                    if ($mdMedia('(max-width: ' + $scope.filter.maxMobileWidth + 'px)')) {
                        $scope.filter.expanded = false;
                    }
                    updateScroll();
                    refreshFilter();
                    pl.currentPage = 0;
                    pl.refreshPage();
                };

                $scope.filter.refresh = refreshFilter;
                $scope.filter.init = init;

                $scope.filter.clickFilterButton = function () {
                    $scope.filter.expanded = !$scope.filter.expanded;
                    updateScroll();
                };

                $scope.filter.clear = function () {
                    forEachFilter(function (filter) {
                        filter.clear();
                    });
                    $scope.filter.forceApply();
                };

                $scope.filter.updateScroll = updateScroll;
                $scope.filter.isMobile = function () {
                    return $mdMedia('(max-width: ' + $scope.filter.maxMobileWidth + 'px)');
                };

                $scope.filter.register = function (key, filter) {
                    $scope.filter.filters[key] = filter;
                };

                $scope.filter.initialized = function (key) {
                    $timeout(function () {
                        $timeout(function () {
                            $scope.filter.filters[key].initialized = true;
                            var notCompletedFilters = 0;
                            forEachFilter(function (f) {
                                if (!f.initialized) {
                                    notCompletedFilters++;
                                }
                            });
                            if (notCompletedFilters === 0) {
                                refreshFilter();
                                pl.refreshPage({
                                    rewriteSearchParams: true
                                });
                            }
                        });

                    });
                };

                this.filter = $scope.filter;

                function init() {

                    forEachFilter(function (f) {
                        if (angular.isFunction(f.lock)) {
                            f.lock();
                        }
                    });

                    forEachFilter(function (f) {
                        f.clear();
                        f.deserialize($location.search());
                    });

                    refreshFilter();
                    pl.refreshPage({
                        rewriteSearchParams: true
                    });

                    $timeout(function () {
                        $timeout(function () {
                            forEachFilter(function (f) {
                                if (angular.isFunction(f.unlock)) {
                                    f.unlock();
                                }
                            });
                        });
                    });
                }

                function refreshFilter() {
                    var newFilter = buildFilter();
                    pl.filter = newFilter.filter;
                    $scope.filter.countSelected = newFilter.countSelected;
                    pl.filterApplied = $scope.filter.countSelected > 0;
                }

                function forEachFilter(callback) {
                    for (var property in $scope.filter.filters) {
                        if ($scope.filter.filters.hasOwnProperty(property)) {
                            callback($scope.filter.filters[property]);
                        }
                    }
                }

                function buildFilter() {
                    var result = {
                        filter: {},
                        countSelected: 0
                    };
                    forEachFilter(function (f) {
                        angular.merge(result.filter, f.serialize());
                        if (f.isSelected() && (!angular.isFunction(f.isSilent) || !f.isSilent())) {
                            result.countSelected++;
                        }
                    });

                    return result;
                }

                function removeHtmlScroll() {
                    angular.element($document[0].body).css('overflow-y', 'hidden');
                }

                function returnHtmlScroll() {
                    angular.element($document[0].body).css('overflow-y', 'auto');
                }

                function updateScroll() {
                    if ($mdMedia('(max-width: ' + $scope.filter.maxMobileWidth + 'px)')) {
                        $timeout(function () {
                            if ($scope.filter.expanded) {
                                removeHtmlScroll();
                            } else {
                                returnHtmlScroll();
                            }
                        });
                    }
                }
            }],
            link: function (scope, elem, attrs, plCtrl) {
                scope.filter.maxMobileWidth = attrs.plMaxMobileWidth || 470;
                plCtrl.pl.filterController = scope.filter;
            }
        };
    }]);