import app from "js/legacy-app";

app.directive('plAutocompleteFilter', ['$location', 'ErrorHandler', '$timeout',
    function ($location, ErrorHandler, $timeout) {
        return {
            template: function (elem, attr) {
                var getItemTemplateHtml = function () {
                    return elem.find('af-item-template').html() ||
                        '<span md-highlight-text="af.query" md-highlight-flags="^i">{{af.displayItem(item)}}</span>';
                };
                return '<div class="pageable-list__filter-field">' +
                    '<md-autocomplete md-selected-item="af.selected"' +
                    ' md-search-text="af.query"' +
                    ' md-items="item in af.search(af.query)"' +
                    ' md-item-text="af.displayItem(item)"' +
                    ' md-min-length="2"' +
                    ' md-floating-label="{{ label }}"' +
                    ' name="{{ name }}"' +
                    ' md-no-cache="!!dependsOn || !!noCache"' +
                    ' md-select-on-match="true"' +
                    ' md-match-case-insensitive="true"' +
                    ' md-dropdown-position="{{ ::dropdownPosition }}"' +
                    ' md-selected-item-change="af.onItemChange()">' +
                    '<md-item-template>' + getItemTemplateHtml() + '</md-item-template>' +
                    '</md-autocomplete>' +
                    '</div>';
            },

            require: '^^plFilter',
            scope: {
                label: '@afLabel',
                name: '@afName',
                model: "=afModel",
                dropdownPosition: '@?afDropdownPosition',
                dependsOn: "@afDependsOn",
                noCache: "@?afNoCache"
            },
            link: function ($scope, element, $attrs, plFilter) {
                $scope.af = {};
                $scope.af.filter = plFilter.filter;

                $scope.af.filter.register($scope.name, $scope.af);

                $scope.af.search = function (query) {
                    if (getDependency()) {
                        if (getDependency().isSelected()) {
                            return $scope.model.search(query, getDependency().getValue());
                        } else {
                            return {};
                        }
                    } else {
                        return $scope.model.search(query);
                    }
                };

                $scope.af.displayItem = function (item) {
                    if (item.displayName) {
                        return item.displayName;
                    } else if ($scope.model.displayItem) {
                        return $scope.model.displayItem(item);
                    } else {
                        return item.name;
                    }
                };

                $scope.af.template = function () {
                    return element.find('af-item-template').html();
                };

                $scope.af.hasTemplate = function () {
                    return element.find('af-item-template')[0];
                };

                $scope.af.onItemChange = function () {
                    $timeout(function () {
                        if (!$scope.af.locked) {
                            $scope.af.filter.apply();
                        }
                        unlock();
                    });
                };

                $scope.af.isSelected = function () {
                    return !!$scope.af.selected;
                };

                $scope.af.getValue = function () {
                    return $scope.af.isSelected() ? $scope.af.selected.id : null;
                };

                $scope.af.serialize = function () {
                    var serialized = {};
                    serialized[$scope.name] = $scope.af.getValue();
                    serialized[$scope.name + 'Query'] = $scope.af.isSelected() ?
                        $scope.af.displayItem($scope.af.selected) : null;
                    return serialized;
                };

                $scope.af.deserialize = function (queryString) {
                    if (queryString[$scope.name]) {
                        $scope.af.selected = {
                            id: queryString[$scope.name],
                            displayName: queryString[$scope.name + 'Query']
                        };
                    }
                    $scope.af.query = queryString[$scope.name + 'Query'];
                };

                $scope.af.clear = lockAndClear;
                $scope.af.init = init;
                $scope.af.lock = lock;
                $scope.af.unlock = unlock;

                function getDependency() {
                    return $scope.dependsOn ? $scope.af.filter.filters[$scope.dependsOn] : null;
                }

                function lockAndClear() {
                    if ($scope.af.selected !== null) {
                        lock();
                    }
                    clear();
                }

                function lock() {
                    $scope.af.locked = true;
                }

                function unlock() {
                    $scope.af.locked = false;
                }

                function clear() {
                    $scope.af.selected = null;
                    $scope.af.query = "";
                }

                function init() {
                    $scope.af.deserialize($location.search());

                    $scope.af.filter.initialized($scope.name);

                    if ($scope.dependsOn) {
                        $scope.$watch('af.filter.filters.' + $scope.dependsOn + '.getValue()', function (newVal, oldVal) {
                            if (newVal !== oldVal) {
                                lockAndClear();
                            }
                        });
                    }
                }

                init();
            }
        };
    }]);