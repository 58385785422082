import app from "js/legacy-app";

app.directive('userRoleStatistic', [function () {
    return {
        template: '<div class="md-padding chart-container">' +
        '   <div>Users by role</div>' +
        '   <div>' +
        '       <canvas class="chart chart-pie" chart-data="data" chart-labels="labels" chart-options="options"></canvas>' +
        '   </div>' +
        '</div>',
        replace: true,
        scope: true,
        controller: ['$scope', 'User', function ($scope, User) {

            $scope.labels = [];
            $scope.data = [];

            User.countUserByRole(function (data) {
                for (var i = 0; i < data.length; i++) {
                    var role = data[i].role.replace(/_/g, ' ').toLowerCase();
                    $scope.labels.push(role);
                    $scope.data.push(data[i].count);
                }
            });

        }]
    };
}]);
