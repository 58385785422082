import app from "js/legacy-app";

app.directive('autoWidthGallery', [function () {
    var totalOffset = 0;
    return {
        scope: {
            autoWidthGallery: '=autoWidthGallery'
        },
        link: function ($scope, $element, $attrs, ngModel) {
            $scope.autoWidthGallery.change = function (offset) {
                var size = 0,
                    div = $element[0],
                    childNodes = div.childNodes,
                    parent = div.parentElement;

                for (var i in childNodes) {
                    size += childNodes[i].offsetWidth ? childNodes[i].offsetWidth : 0;
                }

                totalOffset += offset;
                if (parent.offsetWidth - totalOffset > size) {
                    totalOffset = parent.offsetWidth - size;
                } else if (totalOffset > 0) {
                    totalOffset = 0;
                }
                div.style.transform = 'translateX(' + totalOffset + 'px)';
            };

            $scope.autoWidthGallery.isNext = function() {
                var size = 0,
                    div = $element[0],
                    childNodes = div.childNodes,
                    parent = div.parentElement;

                for (var i in childNodes) {
                    size += childNodes[i].offsetWidth ? childNodes[i].offsetWidth : 0;
                }

                if (size <= parent.offsetWidth) {
                    return true;
                }

                if (parent.offsetWidth - totalOffset == size) {
                    return true;
                }

                return false;
            };

            $scope.autoWidthGallery.isPrev = function() {
                var size = 0,
                    div = $element[0],
                    childNodes = div.childNodes,
                    parent = div.parentElement;

                for (var i in childNodes) {
                    size += childNodes[i].offsetWidth ? childNodes[i].offsetWidth : 0;
                }

                if (size <= parent.offsetWidth) {
                    return true;
                }

                if (totalOffset === 0) {
                    return true;
                }

                return false;
            };
        },
        controller: ['$scope', function ($scope) {
        }]
    };
}]);
