import angular from "angular";

const services = function ($resource) {
    'ngInject';

    return $resource('/api/services/:id', {}, {
        getSteps: {
            method: "GET",
            isArray: false,
            params: {id: "steps"}
        }
    })
}

export default angular.module('vt-public-resource.services', [])
    .service('ServicePublicApi', services);
