// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/img/no-organization-avatar.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div>\n    <md-autocomplete class=\"station-autocomplete\"\n                     ng-show=\"autocomplete.needShowStationAutocomplete()\"\n                     md-selected-item=\"autocomplete.selectedStation\"\n                     md-search-text=\"searchStation\"\n                     md-items=\"station in autocomplete.searchStation(searchStation)\"\n                     md-item-text=\"station.name\"\n                     md-input-maxlength=\"250\"\n                     md-min-length=\"0\"\n                     md-no-cache=\"false\"\n                     md-input-id=\"searchStation__bike-step\"\n                     md-selected-item-change=\"autocomplete.selectStation(autocomplete.selectedStation)\"\n                     placeholder=\"Start typing group name...\">\n\n        <md-item-template class=\"autocomplete-custom-template\">\n            <div class=\"autocomplete-item\">\n                <img class=\"item__icon icon--left\" src=\"{{ station.image.thumbnail }}\"\n                     data-ng-if=\"station.image && station.image.thumbnail\">\n                <img class=\"item__icon icon--left\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"\n                     data-ng-if=\"!station.image || !station.image.thumbnail\"/>\n\n                <div class=\"item__text item__text--icon-left-margin\">\n                <span>\n                    <label class=\"organisation-type-label\">{{station.type | stationType}}</label>\n                    <label class=\"organisation-label\">{{station.name}}</label>\n                </span>\n                </div>\n            </div>\n        </md-item-template>\n\n        <md-not-found>\n            No stations matching \"{{searchStation}}\" were found.\n        </md-not-found>\n\n    </md-autocomplete>\n</div>";
// Exports
var _module_exports = code;;
var path = 'components/request/create/steps/station-autocomplete.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;