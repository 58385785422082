import app from "js/legacy-app";

app.controller('UnableToFixRequestReasonDialogCtrl', function ($scope, $mdDialog, Notification, Documents) {
        'ngInject';

        $scope.ctrl = {
            documents: Documents.bikeHistoryImage(),
            images: []
        };

        let descriptions = {
            PROBLEM_PERSISTS_AFTER_REPAIR: 'Problem Persists after Repair',
            DO_NOT_HAVE_SKILLS_TO_REPAIR: 'Don\'t have skills to repair',
            OTHER: ''
        };

        $scope.ctrl.cancel = () => {
            $mdDialog.hide();
        };

        $scope.ctrl.submit = (form) => {
            if (!form.$valid) {
                return;
            }

            if (!isValid()) {
                Notification.warning("Please select the reason");
                return;
            }

            let report = {
                images: []
            };

            $scope.ctrl.images.images.filter(function (image) {
                return !image.failed;
            }).forEach(function (img) {
                let image = {
                    id: img.id,
                    original: img.originUrl,
                    thumbnail: img.thumbnailUrl
                };
                report.images.push(image);
            });

            if (!$scope.ctrl.reasonDescription) {
                $scope.ctrl.reasonDescription = descriptions[$scope.ctrl.reason];
            }

            report.comment = $scope.ctrl.reasonDescription;
            $mdDialog.hide(report);
        };

        $scope.ctrl.onSelectReason = (reason) => {
            $scope.ctrl.reason = reason;
            $scope.ctrl.reasonDescription = '';
        };

        let isValid = () => {
            if (!$scope.ctrl.reason) {
                return false;
            }

            return !($scope.ctrl.reason === 'OTHER' && !$scope.ctrl.reasonDescription);
        };

    }
);