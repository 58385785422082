import app from "js/legacy-app";
import angular from "angular";

app.directive('selectAppointmentDate', function ($mdMedia, $mdDialog, $dateTimeService, $timeout) {
    'ngInject';

    return {
        templateUrl: "components/appointment-date/select-appointment-date.html",
        scope: {
            availability: '=sadAvailabilityDates',
            selectedTime: '=sadSelectedTime',
            onChange: '&?sadOnChange'
        },
        transclude: {
            info: '?sadInfo'
        },
        controller: function ($scope, AuthService) {
            'ngInject';

            var self = this;
            self.dateController = {};
            self.auth = AuthService;

            $scope.$watch('ctrl.availability', function (newEl, oldEl) {
                if (newEl && oldEl || !newEl) {
                    cleanup();
                }

                if (!self.availability || !self.availability.length) {
                    return;
                }

                if (newEl && !self.selectedTime) {
                    self.selectFirstAvailableDate();
                }

                if (self.selectedTime) {
                    var selectedDateTime = self.availability.filter(function (date) {
                        return $dateTimeService.formatDate(date.date) === self.selectedTime.date;
                    })[0];

                    if (selectedDateTime) {
                        self.selectDate(selectedDateTime);
                    } else {
                        self.selectFirstAvailableDate();
                    }
                }
            });

            function cleanup() {
                self.currentlySelectedDate = null;
                self.selectedTime = null;
                self.appointmentDates = null;
            }

            self.onSelectDate = function (item) {
                self.currentlySelectedDate.selected = false;
                item.selected = true;
                self.currentlySelectedDate = item;

                var date = $dateTimeService.formatDate(self.currentlySelectedDate.date);
                if (self.selectedTime && date !== self.selectedTime.date) {
                    self.selectedTime = null;
                }

                if (!item.selectedPod) {
                    var availablePods = getAvailablePeriodsOfDay(item);
                    item.selectedPod = availablePods[0];
                }
            };

            self.showYear = (index) => {
                let currentAppointmentDate = self.availability[index];

                let nextAppointmentDate = self.availability[index + 1];
                if (nextAppointmentDate) {
                    let nextAppointmentYear = nextAppointmentDate.date.getFullYear();

                    if (nextAppointmentYear !== currentAppointmentDate.date.getFullYear()) {
                        return true;
                    }
                }
            };

            self.selectConcreteTime = function (timeSlot, currentSelectedDate, pod) {
                var time = self.availability.filter(function (d) {
                    return d.date === currentSelectedDate.date;
                }).map(function (d) {
                    return {
                        date: $dateTimeService.formatDate(d.date),
                        periodOfDay: pod,
                        time: timeSlot
                    };
                })[0];

                if (!self.selectedTime || !angular.equals(time, self.selectedTime)) {
                    self.selectedTime = time;
                } else {
                    self.selectedTime = null;
                }

                if (angular.isFunction(self.onChange)) {
                    self.onChange({selectedTime: self.selectedTime});
                }
            };

            self.isSelectedTimeSlot = function (pod, timeSlot) {
                return self.selectedTime && self.selectedTime.time === timeSlot &&
                    self.selectedTime.date === $dateTimeService.formatDate(self.currentlySelectedDate.date) &&
                    self.selectedTime.periodOfDay === pod;
            };

            self.isNotSelectedTimeSlot = function (pod, timeSlot) {
                return self.selectedTime && (self.selectedTime.time !== timeSlot ||
                    self.selectedTime.date !== $dateTimeService.formatDate(self.currentlySelectedDate.date) ||
                    self.selectedTime.periodOfDay !== pod);
            };

            self.onSelectPeriodOfDay = function (pod) {
                if (self.selectedTime && self.selectedTime.periodOfDay !== pod) {
                    self.selectedTime = null;
                }
                self.currentlySelectedDate.selectedPod = pod;
            };

            self.selectDate = function (date) {
                date.selected = !date.selected || self.currentlySelectedDate !== date;
                self.currentlySelectedDate = date;

                if (!date.selectedPod) {
                    var availablePods = getAvailablePeriodsOfDay(date);
                    date.selectedPod = availablePods[0];
                }
            };

            var getAvailablePeriodsOfDay = function (date) {
                var availablePods = [];
                if (date.morning.available) {
                    availablePods.push('MORNING');
                }
                if (date.afternoon.available) {
                    availablePods.push('AFTERNOON');
                }
                if (date.evening.available) {
                    availablePods.push('EVENING');
                }
                return availablePods;
            };

            self.selectFirstAvailableDate = function () {
                let weekDay = $dateTimeService.week();
                let day = $dateTimeService.day();
                let month = $dateTimeService.month();
                let year = $dateTimeService.year();

                let index;
                for (let i = 0; i < self.availability.length; i++) {
                    let date = self.availability[i];
                    if (date.day === day && date.weekDay === weekDay && date.month === month && date.date.getFullYear() === year) {
                        index = i;
                    }
                }

                $timeout(function () {
                    if (index && index !== 0) {
                        self.selectDate(self.availability[index]);
                        self.dateController.scroll(index + 1);
                    } else {
                        self.selectDate(self.availability[0]);
                    }
                });
            };
        },

        bindToController: true,
        controllerAs: 'ctrl'
    };
});
