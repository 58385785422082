import app from "js/legacy-app";
import angular from "angular";

app.service('NotificationWSService', function ($timeout, $wsService) {
    'ngInject';

    var ws, closeWS = false, connection,
        handlers = {};

    let self;

    var connect = function (token) {
        if ($wsService.exists(ws)) {
            return;
        }

        if (!ws) {
            var protocol = location.protocol === 'https:' ? 'wss://' : 'ws://';
            var socketUrl = protocol + document.location.host + '/notification-websocket';
            if (!self) {
                self = this;
            }
            self.ws = ws = new WebSocket(socketUrl);

            ws.onclose = function (evt) {
                checkStatusConnection(false);
                if (closeWS === true) {
                    ws = null;
                    closeWS = false;
                } else {
                    ws = null;
                    timeout(token, 15);
                }
            };

            ws.onmessage = function (evt) {
                var data = angular.fromJson(evt.data);
                var handler = handlers[data.type];
                if (handler) {
                    for (var i = 0; i < handler.length; i++) {
                        handler[i].fn(data);
                    }
                }
            };

            ws.onopen = function (evt) {
                ws.send(angular.toJson({
                    operation: 0,
                    token: token.access_token
                }));
                pingWs();
                checkStatusConnection(true);
            };

            ws.onerror = function (evt) {
                checkStatusConnection(false);
            };
        }

        $wsService.add(self);
    };

    var pingWs = function () {
        setTimeout(function () {
            if (closeWS === false && ws && ws.readyState === ws.OPEN) {
                ws.send(angular.toJson({operation: 6}));
                pingWs();
            }
        }, 55 * 1000);
    };

    var close = function () {
        closeWS = true;
        if (ws && ws.CLOSED !== ws.readyState) {
            ws.close();
        }

        $wsService.remove(ws);
    };

    var send = function (data) {
        if (ws && ws.readyState === ws.OPEN) {
            ws.send(angular.toJson(data));
        }
    };

    var readNotification = function (notificationId, token) {
        var request = {
            operation: 1,
            notificationId: notificationId,
            token: token.access_token
        };
        send(request);
    };

    var registerHandler = function (id, label, fn) {
        var handler = handlers[id];
        if (handler) {
            var found = false;
            for (var i = 0; i < handler.length; i++) {
                if (handler[i].label === label) {
                    found = true;
                    handler[i] = {
                        label: label,
                        fn: fn
                    };
                    break;
                }
            }
            if (!found) {
                handler.push({
                    label: label,
                    fn: fn
                });
            }
        } else {
            handlers[id] = [{
                label: label,
                fn: fn
            }];
        }
    };

    var unregisterHandler = function (id, label) {
        var handler = handlers[id];
        if (!handler) {
            return;
        }

        for (var i = 0; i < handler.length; i++) {
            if (handler[i].label === label) {
                handler.splice(i, 1);
                if (handler.length === 0) {
                    delete handlers[id];
                }
                break;
            }
        }
    };

    var registerInternetConnectionHandler = function (fn) {
        connection = fn;
    };

    function timeout(token, timeoutSeconds) {
        $timeout(function () {
            connect(token);
        }, timeoutSeconds * 1000);
    }

    function checkStatusConnection(connected) {
        if (angular.isDefined(connection) && angular.isFunction(connection)) {
            connection(connected);
        }
    }

    return {
        connect: connect,
        close: close,
        send: send,
        registerHandler: registerHandler,
        unregisterHandler: unregisterHandler,
        readNotification: readNotification,
        registerInternetConnectionHandler: registerInternetConnectionHandler
    };
});
