import app from "js/legacy-app";

app.directive('confirmationBookingStep', function () {
    return {
        templateUrl: "components/request/create/steps/confirmation-booking-step.html",
        controller: ['$scope', function ($scope) {

            $scope.confirmationStep = {
                options: {
                    id: "confirmation",
                    position: 6,
                    title: "Important Notice: Please Read Before Booking Your Service",

                    back: function () {
                        $scope.wizard.back();
                    },
                    next: function () {
                        $scope.wizard.next();
                    },
                    onEnter: function () {
                    }
                },
            };
        }]
    };
});

