import app from "js/legacy-app";
import angular from 'angular';

app.directive('customServiceItem', [
    function () {
        return {
            templateUrl: "components/service/item/custom-service-item.html",
            replace: true,
            transclude: {
                actions: "csiActions"
            },
            scope: {
                model: '=msiModel',
                viewService: '=msiView',
                detailed: '@msiDetailed'
            },
            controller: ['$scope', 'AuthService',
                function ($scope, AuthService) {

                    $scope.msic = {
                        auth: AuthService
                    };

                    $scope.msic.view = function (event) {
                        if (angular.isFunction($scope.viewService)) {
                            if (event.defaultPrevented) {
                                event.preventDefault();
                                return;
                            }

                            $scope.viewService($scope.model.id);
                        }
                    };
                }]
        };
    }]);