import app from "js/legacy-app";

app.controller('BiographyVerificationCtrl', ['$scope', 'User', '$location', 'FrameMaterial', 'Notification',
    function ($scope, User, $location, FrameMaterial, Notification) {

        $scope.yearsOfExperience = [
            '0',
            '<1',
            '1-2',
            '3-4',
            '5+',
            '10+',
            '15+'
        ];

        User.getVerification(function (data) {
            $scope.verification = data;

            if ($scope.verification && $scope.verification.biographyVerification && $scope.verification.biographyVerification.biography) {
                $scope.changeBiography.biography = $scope.verification.biographyVerification.biography;
            }
        });

        $scope.changeBiography = {
            maxYear: new Date().getFullYear(),
            biography: {
                standoutExperience: {
                    teams: [],
                    cyclingAchievements: []
                },
                bicycleMaintenanceEducation: [],
                qualificationCore: {
                    mechanicExperience: "",
                    mechanicExperienceBikeShop: ""
                }
            },
            disabledChange: false
        };


        $scope.bikes = ['All-around', 'Mountain', 'Road'];


        $scope.addAchievement = function () {
            $scope.changeBiography.biography.standoutExperience.cyclingAchievements.push({
                year: '',
                race: ''
            });
        };

        $scope.addTeam = function () {
            $scope.changeBiography.biography.standoutExperience.teams.push({
                name: '',
                fromYear: '',
                toYear: ''
            });
        };

        $scope.addEducation = function () {
            $scope.changeBiography.biography.bicycleMaintenanceEducation.push({
                name: ''
            });
        };

        $scope.removeEducation = function (index) {
            $scope.changeBiography.biography.bicycleMaintenanceEducation.splice(index, 1);
        };

        $scope.removeTeam = function (index) {
            $scope.changeBiography.biography.standoutExperience.teams.splice(index, 1);
        };

        $scope.removeAchievement = function (index) {
            $scope.changeBiography.biography.standoutExperience.cyclingAchievements.splice(index, 1);
        };

        FrameMaterial.query(function (data) {
            $scope.materials = [];
            for (var i = 0; i < data.length; i++) {
                $scope.materials.push(data[i].name);
            }
        });

        $scope.convertStringToDate = function (data, startIndex, endIndex) {
            if (endIndex !== 'undefined') {
                return data.substring(startIndex, endIndex);
            } else {
                return data.substring(startIndex);
            }
        };

        $scope.continue = function (form) {
            if (form.$valid) {
                $scope.changeBiography.disabledChange = true;
                if ($scope.changeBiography.biography.standoutExperience.frameBuilding &&
                    $scope.changeBiography.biography.standoutExperience.frameBuilding.bicycleFrameBuildingYearsOld) {
                    $scope.changeBiography.biography.standoutExperience.frameBuilding.bicycleFrameBuildingYearsOld =
                        parseInt($scope.changeBiography.biography.standoutExperience.frameBuilding.bicycleFrameBuildingYearsOld);
                }

                User.biographyVerification({
                    biography: $scope.changeBiography.biography
                }, function () {
                    $location.path('/dashboard');
                    Notification.success('Biography was successfully updated');
                    $scope.changeBiography.disabledChange = false;
                }, function (error) {
                    $scope.changeBiography.disabledChange = false;
                    if (error.status == 409) {
                        for (var i = 0; i < error.data.length; i++) {
                            Notification.error(error.data[i].message);
                        }
                    } else {
                        Notification.error('Biography could not be updated');
                    }
                });
            }
        };

    }
]);