import app from "js/legacy-app";
import angular from "angular";

app.service('Documents', ['$http', 'Upload', 'AuthService', function ($http, Upload, AuthService) {

    var avatar = function () {
        return {
            upload: function (image, cropArea, onSuccess, onError) {
                return uploadImage(image, '/api/user/current/avatar', null, null, onSuccess, onError, null, {
                    original: {
                        quality: 0.8,
                        width: 1000,
                        height: 1000
                    },
                    thumbnail: {
                        crop: cropArea,
                        width: 300,
                        height: 300
                    }
                });
            }
        };
    };

    var userProfileAvatar = function (userId) {
        return {
            upload: function (image, cropArea, onSuccess, onError) {
                return uploadImage(image, '/api/user/avatar/' + userId, "PUT", null, onSuccess, onError, null, {
                    original: {
                        quality: 0.8,
                        width: 1000,
                        height: 1000
                    },
                    thumbnail: {
                        crop: cropArea
                    }
                });
            }
        };
    };

    var businessAvatar = function (entity) {
        return {
            upload: function (image, cropArea, id, onSuccess, onError) {
                return uploadImage(image, '/api/' + entity + '/avatar/' + id, 'PUT', null, onSuccess, onError, null, {
                    original: {
                        quality: 0.8,
                        width: 1000,
                        height: 1000
                    },
                    thumbnail: {
                        crop: cropArea
                    }
                });
            }
        };
    };

    var organisationAvatar = function () {
        return businessAvatar('organisation');
    };

    var stationAvatar = function () {
        return businessAvatar('station');
    };

    var bikeImage = function (bikeId) {
        return extendGallery({
            upload: function (images) {
                return $http.put("/api/" + getBikeResourcePath() + bikeId + "/images", images);
            },
            remove: function (imageId) {
                return $http.delete("/api/" + getBikeResourcePath() + bikeId + "/images/" + imageId);
            },
            setAsAvatar: function (imageId) {
                return $http.post("/api/" + getBikeResourcePath() + bikeId + "/images/" + imageId + "/set-as-avatar");
            },
            folder: function () {
                return "BIKE_IMAGE";
            },
            entityId: bikeId
        });
    };

    var bikeHistoryImage = function () {
        return extendGallery({
            folder: function () {
                return "BIKE_HISTORY_IMAGE";
            }
        });
    };

    var requestImage = function (requestId) {

        return extendGallery({
            upload: function (images) {
                return $http.put("/api/" + getRequestResourcePath() + requestId + "/images", images);
            },
            remove: function (imageId) {
                return $http.delete("/api/" + getRequestResourcePath() + requestId + "/images/" + imageId);
            },
            setAsAvatar: function (imageId) {
                return $http.post("/api/" + getRequestResourcePath() + requestId + "/images/" + imageId + "/set-as-avatar");
            },
            folder: function () {
                return "REQUEST_IMAGE";
            },
            entityId: requestId
        });
    };

    var applicationImage = function (appId) {
        return extendGallery({
            upload: function (document) {
                return $http.put("/api/app-marketplace/" + appId + "/icon", document);
            },
            remove: function (iconId) {
                return $http.delete("/api/app-marketplace" + appId + "/icon/" + iconId);
            },
            folder: function () {
                return "APPLICATION_IMAGE";
            },
            entityId: appId
        });
    };

    var requestReturnLabel = function (requestId) {
        return extendGallery({
            upload: function (document) {
                return $http.put("/api/" + getRequestResourcePath() + requestId + "/return-label", document);
            },
            remove: function (documentId) {
                return $http.delete("/api/" + getRequestResourcePath() + requestId + "/return-label/" + documentId);
            },
            folder: function () {
                return "REQUEST_RETURN_LABEL";
            },
            entityId: requestId
        });
    };

    var taskImage = function (taskId) {

        return extendGallery({
            upload: function (images) {
                return $http.put("/api/general-service-management/" + taskId + "/image", images);
            },
            remove: function (imageId) {
                return $http.delete("/api/general-service-management/" + taskId + "/image");
            },
            folder: function () {
                return "SERVICE_IMAGE";
            },

            entityId: taskId,

            uploadTemp: function (image, onResize, onSuccess, onError, onProgress) {
                uploadImage(image, '/api/images/' + this.folder(), null, onResize, onSuccess, onError, onProgress, {
                    original: {
                        quality: 1,
                        width: 128,
                        height: 128
                    }
                });
            }
        });
    };

    function getRequestResourcePath() {
        if (AuthService.isUser()) {
            return 'user-request/';
        } else if (AuthService.isBikeBusinessSupervisor()) {
            return 'supervisor-request/';
        } else if (AuthService.isBikeOrganisationOwner()) {
            return 'organization-request/';
        } else if (AuthService.isMechanic()) {
            return 'mechanic-request/';
        } else if (AuthService.isAdmin()) {
            return 'admin-request/';
        }
    }

    function getBikeResourcePath() {
        if (AuthService.isMechanic()) {
            return 'mechanic-bike/';
        } else if (AuthService.isAdmin()) {
            return 'admin-bike/';
        } else if (AuthService.isBusinessAccount()) {
            return 'partner-bike/'
        }

        return 'customer-bike/';
    }

    var profileImage = function () {
        return extendGallery({
            upload: function (images) {
                return $http.put("/api/user/current/images", images);
            },
            remove: function (imageId) {
                return $http.delete("/api/user/current/images/" + imageId);
            },
            setAsAvatar: function (imageId) {
                return $http.post("/api/user/current/images/" + imageId + "/set-as-avatar");
            },
            setAsCover: function (imageId) {
                return $http.post("/api/user/current/images/" + imageId + "/set-as-cover");
            },
            folder: function () {
                return "PROFILE_IMAGE";
            },
            entityId: AuthService.user().id,
        });
    };

    var extendGallery = function (obj) {
        return angular.extend({}, gallery, obj);
    };

    var gallery = {
        removeTemp: function (imageId) {
            return removeTemp(this.folder(), imageId);
        },
        removeAllTemp: function (images) {
            return removeAllTemp(this.folder(), images);
        },
        uploadTemp: function (file, onResize, onSuccess, onError, onProgress) {
            if (file.type && file.type !== 'application/pdf') {
                uploadImage(file, '/api/images/' + this.folder(), null, onResize, onSuccess, onError, onProgress, {
                    original: {
                        quality: 0.8,
                        width: 1200,
                        height: 1200
                    }
                });
            } else {
                let uploadRequest = {
                    image: file
                };
                return uploadDocument('/api/images/' + this.folder(), uploadRequest, null, onSuccess, onError, onProgress);
            }
        }
    };

    var removeTemp = function (folder, imageId) {
        $http.delete("/api/images/" + folder + "/" + imageId);
    };

    var removeAllTemp = function (folder, images) {
        $http.delete("/api/images/" + folder, {
            data: images,
            headers: {
                "Content-Type": "application/json"
            }
        });
    };

    var uploadImage = function (image, url, method, onResize, onSuccess, onError, onProgress, imageOptions) {
        var img = new Image();
        img.onload = function () {
            imageOptions.original.width = Math.min(img.width, imageOptions.original.width);
            imageOptions.original.height = Math.min(img.height, imageOptions.original.height);
            Upload.resize(image, imageOptions.original)
                .then(function (resizedFile) {
                    if (onResize) {
                        onResize(resizedFile);
                    }
                    let uploadRequest = {
                        image: resizedFile
                    };
                    if (imageOptions.thumbnail && imageOptions.thumbnail.crop) {
                        calculateCropArea(image, resizedFile, imageOptions.thumbnail, function () {
                            uploadRequest.thumbnail = angular.toJson(imageOptions.thumbnail);
                            uploadDocument(url, uploadRequest, method, onSuccess, onError, onProgress);
                        });
                    } else if (imageOptions.thumbnail) {
                        uploadRequest.thumbnail = angular.toJson(imageOptions.thumbnail);
                        uploadDocument(url, uploadRequest, method, onSuccess, onError, onProgress);
                    } else {
                        uploadDocument(url, uploadRequest, method, onSuccess, onError, onProgress);
                    }
                });
        };
        img.onerror = function () {
            if (onError) {
                onError();
            }
        };
        img.src = URL.createObjectURL(image);
    };

    var calculateCropArea = function (originalFile, resizedFile, thumbnailConfig, callback) {
        getImageDimensions(originalFile, function (oW, oH) {
            getImageDimensions(resizedFile, function (rW, rH) {
                var wRatio = rW / oW;
                var hRatio = rH / oH;

                var cropArea = thumbnailConfig.crop;

                thumbnailConfig.crop = {
                    x: Math.floor(cropArea.x * wRatio),
                    y: Math.floor(cropArea.y * hRatio),
                    w: Math.floor(cropArea.w * wRatio),
                    h: Math.floor(cropArea.h * hRatio)
                };
                if (thumbnailConfig.width && thumbnailConfig.height) {
                    thumbnailConfig.width = Math.min(thumbnailConfig.width, thumbnailConfig.crop.w);
                    thumbnailConfig.height = Math.min(thumbnailConfig.height, thumbnailConfig.crop.h);

                    var scaleX = thumbnailConfig.width / thumbnailConfig.crop.w;
                    var scaleY = thumbnailConfig.height / thumbnailConfig.crop.h;

                    thumbnailConfig.scale = Math.max(scaleX, scaleY);
                }

                callback(thumbnailConfig);
            });
        });
    };

    var getImageDimensions = function (image, callback) {
        var img = new Image();
        var url = URL.createObjectURL(image);

        img.onload = function () {
            callback(img.width, img.height);
        };

        img.src = url;
    };

    var experienceVerification = function () {
        return {
            upload: function (data, onSuccess, onError) {
                return uploadDocument('/api/user/verification/update-experience', data, null, onSuccess, onError);
            }
        };
    };

    var billingAddressVerification = function () {
        return {
            upload: function (data, onSuccess, onError) {
                return uploadDocument('/api/user/verification/update-billing-address', data, null, onSuccess, onError);
            }
        };
    };
    var idVerification = function () {
        return {
            upload: function (data, onSuccess, onError) {
                return uploadDocument('/api/user/verification/update-identity', data, null, onSuccess, onError);
            }
        };
    };

    var insurance = function () {
        return {
            upload: function (data, onSuccess, onError) {
                return uploadDocument('/api/insurance', data, null, onSuccess, onError);
            }
        };
    };

    var importBikes = function () {
        return {
            upload: function (data, onSuccess, onError) {
                return uploadDocument('/api/partner-bike/import', data, null, onSuccess, onError);
            }
        };
    };

    var chat = function () {
        return extendGallery({
            folder: function () {
                return "CHAT_IMAGE";
            }
        });
    };

    var uploadDocument = function (url, data, method, onSuccess, onError, onProgress) {
        if (!method) {
            method = 'POST';
        }
        Upload.upload({
            url: url,
            method: method,
            data: data
        }).success(function (response) {
            if (onSuccess) {
                onSuccess(response);
            }
        }).error(function (error) {
            if (onError) {
                onError(error);
            }
        }).progress(function (progressEvt) {
            if (onProgress) {
                onProgress(progressEvt);
            }
        });
    };

    return {
        avatar: avatar,
        userProfileAvatar: userProfileAvatar,
        organisationAvatar: organisationAvatar,
        stationAvatar: stationAvatar,
        experienceVerification: experienceVerification,
        idVerification: idVerification,
        billingAddressVerification: billingAddressVerification,
        bikeImage: bikeImage,
        requestImage: requestImage,
        applicationImage: applicationImage,
        requestReturnLabel: requestReturnLabel,
        profileImage: profileImage,
        insurance: insurance,
        chat: chat,
        importBikes: importBikes,
        bikeHistoryImage: bikeHistoryImage,
        taskImage: taskImage
    };
}]);
