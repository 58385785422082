import app from "js/legacy-app";
import angular from "angular";

app.controller('MainCtrl', function ($scope, AuthService, ToggleLD, $staticService, $rootScope, $mdDialog, $localStorage, $wsService) {
        'ngInject';
        $scope.AuthService = AuthService;
        $scope.toggleLD = ToggleLD;

        window.onpopstate = function () {
            if (angular.element(document).find('md-dialog').length > 0) {
                event.preventDefault();
                $mdDialog.cancel();
            }
        };

        function onRemoveToken(newValue, oldValue, url) {
            if (!newValue && oldValue) {
                $wsService.closeAll();
            }
        }

        $localStorage.on("x-token", onRemoveToken);
        $localStorage.on("x-admin-token", onRemoveToken);
    }
);
