import app from "js/legacy-app";
import moment from "moment";

app.factory('$dateTimeService', ['$filter', function ($filter) {

    /**
     * add method 'get date in UTC' to Date object
     * @returns {Date}
     */
    Date.prototype.getUTCTime = function () {
        return new Date(
            this.getUTCFullYear(),
            this.getUTCMonth(),
            this.getUTCDate(),
            this.getUTCHours(),
            this.getUTCMinutes(),
            this.getUTCSeconds()
        );
    };

    /**
     * get timezone offset for current user location
     * @returns {number} mins offset
     */
    var getLocalOffset = function () {
        return -(new Date().getTimezoneOffset()) / 60 || 0;
    };

    /**
     * convert integer to string time
     * for example number 1550 -> string '3:30 PM'
     * @param value time in number
     * @returns {string} time
     */
    var translateTime = function (value) {
        var delta = 60,
            min = '00',
            zzz = ' AM';
        value = value / 100;

        if (value >= 12) {
            zzz = ' PM';
        }

        if (value % 1 !== 0) {
            min = Math.round((value % 1) * delta);

            value = value - value % 1;

            if (min === 60) {
                min = 0;
                value += 1;
            }

            if (min < 10) {
                min = '0' + min;
            }
        }

        return checkHour(value) + ':' + min + zzz;
    };

    /**
     * convert number to USA formatted hours
     * number range should be between 0 and 86400
     *
     * output format 1:00 PM, 2:00 AM
     *
     * @param number
     * @returns {string}
     */
    let toHours = function (number) {
        number /= 3600;

        let timeFormat = 'AM';

        if (number >= 12) {
            timeFormat = 'PM';
            number -= 12;
        }

        if (number >= 1 && number <= 9) {
            number = '0' + number;
        }

        if (number === 0) {
            number = '12';
        }

        return number + ':00 ' + timeFormat;
    };

    let fromSecondsToHours = (seconds) => {
        return seconds / 3600 + ' hours';
    };

    let fromSecondsToMinutes = (seconds) => {
        let minutes = seconds / 60;
        let hours = 0;

        while (minutes >= 60) {
            minutes -= 60;
            hours++;
        }

        if (hours === 0) {
            return minutes + ' minutes';
        }

        if (minutes === 0) {
            return hours + ' hours';
        }

        return hours + ' hours ' + minutes + ' minutes';
    };

    /**
     * convert string time to number
     * for example string '3:30 PM' -> number 1550
     * @param time string
     * @returns {number}
     */
    var untranslateTime = function (time) {
        var mas = time.split(' '),
            hours = 0;
        if (mas[1] === 'PM') {
            hours += 12;
        }
        mas = mas[0].split(':');
        hours += parseInt(mas[0]);
        hours *= 100;

        if (mas[1] === '00') {
            return hours;
        } else {
            var min = parseInt(mas[1]);
            hours += min * (10 / 6);

            return hours;
        }
    };

    /**
     * join date and time to one object
     * @param date Date object
     * @param time number
     * @returns {Date}
     */
    var joinDateAndTime = function (date, time) {
        return new Date($filter('date')(date) + ' ' + translateTime(time));
    };

    /**
     * get date string by format 'yyyy-MM-dd'
     * @param date object
     * @returns string in 'yyyy-MM-dd' format
     */
    var formatDate = function (date) {
        return $filter('date')(date, 'yyyy-MM-dd');
    };

    /**
     * normalize hours to USA format
     * @param value - hours in number
     * @returns string between 0 and 12
     */
    var checkHour = function (value) {
        if (value >= 12) {
            value = value - 12;
        }
        return (value === 12 || value === 0) ? '12' : value;
    };

    /**
     * get UTC time
     * @param date Date
     */
    var getUTCTime = function (date) {
        return date ? new Date(date).getUTCTime() : new Date().getUTCTime();
    };

    /**
     * add current timezone offset to date
     * @param date object
     * @returns {number} date with offset in number format
     */
    var recalculateTime = function (date) {
        var hours = date.getHours();
        var localOffset = getLocalOffset();

        return new Date(date.setHours(hours + localOffset)).getTime();
    };

    /**
     * get time with timezone offset
     * @param offset current timezone
     * @param date Date
     * @returns Date in utc
     */
    var getTimeByOffset = function (offset, date) {
        // create Date object for current location
        var d = date ? new Date(date) : new Date();

        // get UTC time in msec
        var utc = getUTCTime(d).getTime();

        return new Date(utc + (3600000 * offset));
    };

    /**
     * get current day number + 5 days with current timezone offset
     * @param offset
     * @returns Date
     */
    var getLastWeekDay = function (offset) {
        var d = offset ? getTimeByOffset(offset) : new Date();
        return new Date(d.setDate(d.getDate() + 5));
    };

    /**
     * get time to complete for request
     * min time = 15 min
     * @param service
     */
    var getTimeToComplete = function (service) {
        var timeToComplete = 15;
        if (service.timeToComplete) {
            timeToComplete = service.timeToComplete;
        }
        return (timeToComplete / 60) * 100;
    };

    /**
     * get start time for date
     * min date = 7AM, max date = 11PM
     * @param date
     * @param create
     * @returns {number}
     */
    var getStartTime = function (date, create) {
        var hours = date.getHours();
        var startTime = untranslateTime($filter('date')(date, 'h:mm a'));

        if (create) {
            if (startTime !== 700) {
                startTime += 5;
            }
        }

        return (hours < 7 || hours >= 23 ? 700 : (startTime > 700 ? (startTime > 2300 ? startTime - 1200 : startTime) : 700));
    };

    /**
     * get end time for date
     * min date = 7AM, max date = 11PM
     * @param date
     * @returns {number}
     */
    var getEndTime = function (date) {
        var hours = date.getHours();
        var endTime = untranslateTime($filter('date')(date, 'h:mm a'));
        return (hours < 7 || hours > 23 ? 2300 : endTime);
    };

    /**
     * return start date with current offset
     * @param date
     * @param offset
     * @returns date with offset (min date may by between 7AM and  11PM)
     */
    var setDateByOffset = function (date, offset) {
        var currentHours = date.getHours();

        if (currentHours + offset >= 23) {
            date.setHours(7);
            date.setMinutes(0);
            date.setDate(date.getDate() + 1);
        }

        return date;
    };

    var getNewAvailabilityDate = function (offset, minRange) {
        var timeByOffset = getTimeByOffset(offset, getTomorrow());
        var startTime = getStartTime(timeByOffset, true);

        if ((2300 - startTime < minRange) || timeByOffset.getHours() >= 23) {
            timeByOffset.setDate(timeByOffset.getDate() + 1);
            timeByOffset.setHours(7);
            startTime = 700;
        }

        return {
            date: timeByOffset,
            startTime: startTime,
            endTime: 2300,
            locationOffset: offset
        };
    };

    var getEndTimeTask = function (date, minTimeToComplete) {
        var endTimeTask = new Date(date.from);
        endTimeTask.setMinutes(endTimeTask.getMinutes() + minTimeToComplete);
        endTimeTask.setSeconds(0);
        endTimeTask.setMilliseconds(0);

        return endTimeTask;
    };

    var getTomorrow = function () {
        var d = new Date();
        return new Date(d.setDate(d.getDate() + 1));
    };

    var month = function (date) {
        return moment(date).format('MMM');
    };

    var week = function (date) {
        return moment(date).format('dddd');
    };

    var day = function (date) {
        return moment(date).date();
    };

    function getNextDay(date) {
        return new Date(date.getTime() + 1000 * 60 * 60 * 24);
    }

    var getDayOfWeek = (date) => {
        return week(date).toUpperCase();
    };

    var year = function (date) {
        return moment(date).year();
    };

    return {
        getLocalOffset: getLocalOffset,
        formatDate: formatDate,
        translateTime: translateTime,
        untranslateTime: untranslateTime,
        joinDateAndTime: joinDateAndTime,
        getUTCTime: getUTCTime,
        recalculateTime: recalculateTime,
        getTimeByOffset: getTimeByOffset,
        getLastWeekDay: getLastWeekDay,
        getTimeToComplete: getTimeToComplete,
        getStartTime: getStartTime,
        getEndTime: getEndTime,
        getEndTimeTask: getEndTimeTask,
        setDateByOffset: setDateByOffset,
        getNewAvailabilityDate: getNewAvailabilityDate,
        getTomorrow: getTomorrow,
        month: month,
        week: week,
        day: day,
        year: year,
        getNextDay: getNextDay,
        toHours: toHours,
        fromSecondsToHours: fromSecondsToHours,
        fromSecondsToMinutes: fromSecondsToMinutes,
        getDayOfWeek: getDayOfWeek
    };

}]);
