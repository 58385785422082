import app from "js/legacy-app";

app.controller('CoreServiceManagementCtrl', ['$scope', '$location', function ($scope, $location) {

    $scope.smc = {};

    $scope.smc.href = function (segment) {
        $location.path('/admin/service-management/' + segment).search({});
    };

}]);