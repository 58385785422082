export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/coming-soon', 'coming-soon')

        .segment('coming-soon', {
            access: {requireLogin: false},
            template: '<coming-soon></coming-soon>',
            loadModule: () => {
                return import(/* webpackChunkName: "coming-soon.module" */ "./coming-soon.module");
            }
        });

}
