import app from "js/legacy-app";

app.directive('mechanicAgreement', [function () {
    return {
        templateUrl: "template/dashboard/mechanic/mechanic-agreement.html",
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: ['$scope', 'User', '$sce', 'AuthService', 'ToggleLD', '$http', '$location', 'ErrorHandler',
            function ($scope, User, $sce, AuthService, ToggleLD, $http, $location, ErrorHandler) {

                $scope.agree = function () {
                    ToggleLD.show();
                    User.acceptMechanicAgreement(function () {
                        AuthService.currentUser(function (user) {
                            $location.path('/dashboard/requests').search({});
                            ToggleLD.hide();
                        }, true);
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error);
                    });
                };

                $scope.trustAsHtml = function (html) {
                    return $sce.trustAsHtml(html + '');
                };

                (function init() {
                    AuthService.currentUser(function () {
                        if (AuthService.isVerifiedMechanic()) {
                            if (AuthService.isAcceptAgreement() || AuthService.isProbationaryMechanic() || AuthService.isDealer()) {
                                $location.path('/dashboard/requests').search({});
                                return;
                            }

                            if (AuthService.isFullyVerifiedMechanic() && !AuthService.isAcceptAgreement()) {
                                $http.get('/api/user/verification/mechanic-agreement').then(function (response) {
                                    $scope.agreement = response.data;
                                });

                                return;
                            }
                        }

                        if (AuthService.isNotVerifiedMechanic()) {
                            $location.path('/dashboard/verification').search({});
                            return;
                        }

                        $location.path('/dashboard').search({});
                    }, true);
                })();
            }]
    };
}]);
