import app from "js/legacy-app";
import angular from "angular";

app.directive('mechanicWorkAreaMap', [function () {
    return {
        templateUrl: "components/common/user-avatar-with-name/user-avatar-with-name.html",
        replace: true,
        scope: {
            onInit: "&wamOnInit"
        },
        controller: ['$scope', '$element', function ($scope, $element) {
            $scope.mwam = {};
            var allOverlays = [], drawingManager;

            var selectedShape;

            function init() {
                var mapOptions = {
                    zoom: 7,
                    center: new window.google.maps.LatLng(41.742295, -73.355499),
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    mapTypeControl: false,
                    navigationControl: false,
                    streetViewControl: false,
                    fullscreenControl: true,
                    minZoom: 3,
                    scrollwheel: true,
                    styles: [{"stylers": [{"saturation": -100}, {"gamma": 1}]}, {
                        "elementType": "labels.text.stroke",
                        "stylers": [{"visibility": "off"}]
                    }, {
                        "featureType": "poi.business",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "off"}]
                    }, {
                        "featureType": "poi.business",
                        "elementType": "labels.icon",
                        "stylers": [{"visibility": "off"}]
                    }, {
                        "featureType": "poi.place_of_worship",
                        "elementType": "labels.text",
                        "stylers": [{"visibility": "off"}]
                    }, {
                        "featureType": "poi.place_of_worship",
                        "elementType": "labels.icon",
                        "stylers": [{"visibility": "off"}]
                    }, {
                        "featureType": "road",
                        "elementType": "geometry",
                        "stylers": [{"visibility": "simplified"}]
                    }, {
                        "featureType": "water",
                        "stylers": [{"visibility": "on"}, {"saturation": 50}, {"gamma": 0}, {"hue": "#50a5d1"}]
                    }, {
                        "featureType": "administrative.neighborhood",
                        "elementType": "labels.text.fill",
                        "stylers": [{"color": "#333333"}]
                    }, {
                        "featureType": "road.local",
                        "elementType": "labels.text",
                        "stylers": [{"weight": 0.5}, {"color": "#333333"}]
                    }, {
                        "featureType": "transit.station",
                        "elementType": "labels.icon",
                        "stylers": [{"gamma": 1}, {"saturation": 50}]
                    }]
                };

                $scope.mwam.map = new window.google.maps.Map($element[0], mapOptions);

                // Creates a drawing manager attached to the map that allows the user to draw
                // markers, lines, and shapes.
                drawingManager = new window.google.maps.drawing.DrawingManager({
                    drawingMode: window.google.maps.drawing.OverlayType.CIRCLE,
                    drawingControlOptions: {
                        position: window.google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: ['circle', 'polygon', 'rectangle']
                    },
                    rectangleOptions: polyOptions,
                    circleOptions: polyOptions,
                    polygonOptions: polyOptions,
                    map: $scope.mwam.map
                });

                window.google.maps.event.addListener(drawingManager, 'overlaycomplete', function (e) {
                    allOverlays.push(e);
                    if (e.type !== window.google.maps.drawing.OverlayType.MARKER) {
                        // Switch back to non-drawing mode after drawing a shape.
                        drawingManager.setDrawingMode(null);

                        // Add an event listener that selects the newly-drawn shape when the user
                        // mouses down on it.
                        var newShape = e.overlay;
                        newShape.type = e.type;
                        window.google.maps.event.addListener(newShape, 'click', function () {
                            setSelection(newShape);
                        });
                        setSelection(newShape);
                    }
                });

                window.google.maps.event.addListener(drawingManager, 'drawingmode_changed', clearSelection);
                window.google.maps.event.addListener($scope.mwam.map, 'click', clearSelection);

                if (angular.isFunction($scope.onInit)) {
                    $scope.onInit($scope.mwam);
                }
            }

            function clearSelection() {
                if (selectedShape) {
                    selectedShape.setEditable(false);
                    selectedShape.setDraggable(false);
                    selectedShape = null;
                }
            }

            function setSelection(shape) {
                clearSelection();
                selectedShape = shape;
                shape.setEditable(true);
                shape.setDraggable(true);
            }

            function deleteSelectedShape() {
                if (selectedShape) {
                    selectedShape.setMap(null);
                }
            }

            function deleteAllShape() {
                for (var i = 0; i < allOverlays.length; i++) {
                    allOverlays[i].overlay.setMap(null);
                }
                allOverlays = [];
            }

            var polyOptions = {
                strokeWeight: 0,
                fillOpacity: 0.3,
                editable: true
            };


            init();

            /*var draw_circle = new google.maps.Circle({
             center: new google.maps.LatLng(41.385826, -72.904243),
             radius: 10000,
             strokeColor: "#000000",
             strokeOpacity: 0.8,
             strokeWeight: 1,
             fillColor: "#000000",
             fillOpacity: 0.35,
             map: $scope.googleMap.map
             });

             google.maps.event.addListener(draw_circle, 'click', function() {
             setSelection(draw_circle);
             });*/

            // Clear the current selection when the drawing mode is changed, or when the
            // map is clicked.
        }]
    };
}]);