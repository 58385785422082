import app from "js/legacy-app";

app.filter('mechanicCategory', function () {
    return function (duration) {
        switch (duration) {
            case 'INDIVIDUAL':
                return 'Individual mechanic';
            case 'MOBILE':
                return 'Mobile service';
            case 'SHOP':
                return 'Bike shop';
        }
    };
});
