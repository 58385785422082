/**
 * Created by eugineer on 2/24/17.
 */
import app from "js/legacy-app";

app.filter ('cardYearFilter', function () {
   return function (cardYear) {
       if ( isNaN(cardYear) ) {
           return cardYear;
       }

       var stringYear = cardYear.toString();
       if ( stringYear.length === 4) {
           stringYear = stringYear.substr (stringYear.length - 2);
       }
       return stringYear;
   };
});