import app from "js/legacy-app";
import angular from "angular";

app.directive('plExportAction', function ($q, Notification) {
    'ngInject';

    return {
        template: `<md-button class="md-neutral md-raised m-0"
                        ng-csv="plea.export()"
                        csv-header="header()"
                        filename="{{ filename }}">
                            <i class="fa fa-cloud-download" aria-hidden="true"></i>
                        <span>Export</span>
                   </md-button>`,
        require: '^^pageableList',
        scope: {
            filename: "@",
            mapper: "=",
            header: "=",
        },
        link: function ($scope, element, $attrs, plCtrl) {

            const pl = plCtrl.pl;

            $scope.plea = {};

            $scope.plea.export = function () {
                const deferred = $q.defer();

                const query = angular.merge({}, angular.isFunction(pl.filter) ? $scope.$eval(pl.filter) : pl.filter, {
                    page: 0,
                    size: 0
                });

                const items = pl.evalQuery(query), isPromise = !!items.then;

                if (isPromise) handleAsyncResults(items);
                else handleResults(items);

                function handleResults(items) {
                    deferred.resolve(angular.isFunction($scope.mapper) ? items.map($scope.mapper) : items);
                }

                function handleAsyncResults(items) {
                    if (!items) {
                        onError();
                    }

                    $q.when(items)
                        .then(function (response) {
                            handleResults(response.data);
                        }, function () {
                            onError();
                        });
                }

                function onError() {
                    deferred.reject();
                    Notification.error("Unable to export " + $scope.filename);
                }

                return deferred.promise;
            }
        }
    };
});