import app from 'js/legacy-app';

app.directive('blAddCustomerAction', ['ModalService', '$location',
    function (ModalService, $location) {
        return {
            template: `<div class="tw-flex tw-my-auto tw-justify-center tw-pt-px">
                            <md-button class="md-raised md-big tw-w-full" 
                               type="button" aria-label="Add customer"
                               data-ng-click="ctrl.addCustomer($event)">
                                Add customer
                            </md-button></div>`,
            scope: {
                bikeId: '@blAcaBikeId',
            },

            link: function ($scope) {

                $scope.ctrl = {};

                function goToRequestCreationPage(data) {
                    let msg = 'Would you like to schedule service for this customer (Assembly/Repair)?';
                    ModalService.yesNoMessage(null, '', msg, function (res) {
                        if (!res) return;

                        $location.path('/create-request').search({
                            address: data.address,
                            bikeId: data.bikeId,
                            customerId: data.customerId,
                            apartment: data.apartment
                        });
                    });
                }

                $scope.ctrl.addCustomer = ($event) => {
                    ModalService.showCustomerInvitationDialog($event, $scope.bikeId, 'bike-list').then(function (data) {
                        if (!data) {
                            return;
                        }

                        goToRequestCreationPage(data);
                    });
                };
            }
        };
    }]);