import app from "js/legacy-app";

app.directive('mainDescription', [function () {
    return {
        templateUrl: "template/dashboard/bikes/add-bike/main-description.html",
        scope: {
            bike: '=mainDescription',
            next: '&'
        },
        controller: ['$scope', '$bikeService', '$typeOfBikesService', 'LocationService', 'Brand', 'Notification',
            function ($scope, $bikeService, $typeOfBikesService, LocationService, Brand, Notification) {

                $scope.close = $bikeService.cancelCreateBike;
                $scope.mainDesc = {
                    maxYear: new Date().getFullYear(),
                    types: $typeOfBikesService.getAll()
                };

                if ($bikeService.getBikeType()) {
                    $scope.mainDesc.currentType = $bikeService.getBikeType();
                }

                $scope.submit = function (form) {
                    form.$setSubmitted();
                    if (form.$valid) {
                        $bikeService.checkBikeStatus($scope.bike.serialNumber, continueCallback, recycledCallback);
                    }
                };

                $scope.changeType = function () {
                    $scope.bike.type = $scope.mainDesc.currentType.name;
                    $bikeService.setBikeType($scope.mainDesc.currentType);
                };

                $scope.mainDesc.getBrands = function (brand) {
                    return Brand.autocomplete({name: brand, page: 0, size: 10}).$promise;
                };

                var continueCallback = function () {
                    if (validBikeAddress($scope.bike.fixLocation)) {
                        $scope.bike.fixLocation = LocationService.mapAddress($scope.bike.fixLocation);
                        $scope.bike.id = undefined;
                        $scope.bike.make = $scope.selectedBrand.name;
                        $scope.next()(1);
                    }
                };

                var recycledCallback = function (bike) {
                    if (validBikeAddress($scope.bike.fixLocation)) {
                        $scope.bike.fixLocation = LocationService.mapAddress($scope.bike.fixLocation);
                        $scope.bike.id = bike.bikeId;
                        $scope.next()(5);
                    }

                };

                var validBikeAddress = function (address) {
                    var error = LocationService.validateBikeAddress(address);
                    if (error) {
                        Notification.error(error);
                        return false;
                    }

                    return true;
                };

            }
        ]
    };
}]);