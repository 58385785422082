import app from "js/legacy-app";

app.filter("requestStatus", [function () {
    return function (status) {
        switch (status) {
            case 'OPEN':
                return 'Open';
            case 'DRAFT':
                return 'Awaiting Payment';
            case 'IN_PROGRESS':
                return 'Scheduled';
            case 'RESOLVED':
                return 'Resolved';
            case 'IN_DISPUTE':
                return 'In Dispute';
            case 'PENDING':
                return 'Pending';
            case 'DONE':
                return 'Done';
            case 'COULD_NOT_BE_FIXED':
                return 'Could Not Be Fixed';
            case 'EXPIRED':
                return 'Expired';
            case 'CANCELED':
                return 'Canceled';
            case 'REFUNDED':
                return 'Refunded';
            case 'CHARGE_FAILED':
                return 'Charge Failed';
            case 'REFUND_FAILED':
                return 'Refund Failed';
            case 'TRANSFER_FAILED':
                return 'Transfer Failed';
            case 'REJECTED':
                return 'Rejected';
            case 'AWAITING_CONFIRMATION':
                return 'Awaiting Confirmation';
            case 'TRANSFER_AND_REFUND_FAILED':
                return 'Transfer & Refund Failed';
            case 'REASSIGNED':
                return 'Reassigned';
            case 'READY_FOR_REASSIGN':
                return 'Ready For Reassign';
        }
    };
}]);