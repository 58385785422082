import app from "js/legacy-app";

app.controller('AddCardCtrl', ['$scope', '$location', 'PaymentSources', 'Notification', 'ModalService', 'ToggleLD', 'ErrorHandler', 'RecaptchaService',
    function ($scope, $location, PaymentSources, Notification, ModalService, ToggleLD, ErrorHandler, RecaptchaService) {
        $scope.card = {};

        $scope.save = function (form) {
            if (form.$valid) {
                ToggleLD.show();

                const cardAddRequest = {
                    number: $scope.card.number,
                    cvc: $scope.card.cvc,
                    name: $scope.card.name,
                    expiryDate: '20' + '' + $scope.card.expiry.substr(5, 2) + '-' + $scope.card.expiry.substr(0, 2),
                    provider: 'Stripe',
                    address: {
                        postalCode: $scope.card.addressZip
                    }
                };

                RecaptchaService.execute('save_payment_source',
                    function (token) {
                        cardAddRequest['g-recaptcha-response'] = token;
                        cardAddRequest['g-recaptcha-action'] = 'save_payment_source';
                        PaymentSources.addCard(cardAddRequest, function () {
                            ToggleLD.hide();
                            $location.path("/settings/payment").search({});
                            Notification.success(
                                'Payment card was successfully added');
                        }, function (error) {
                            ToggleLD.hide();
                            ErrorHandler.onError(error, "Unable to add card");
                        });
                });
            }
        };
    }
]);
