import angular from "angular";
import "./bike-autocomplete.scss";

const autocomplete = {
    template: require('./bike-autocomplete.html'),
    bindings: {
        excludeBikes: '<?vuiBaExcludeBikes',
        selectedBikes: '<?vuiBaSelectedBikes',
        filter: '<vuiBaFilter',
        options: '<?vuiBaOptions',
        onSelect: '&vuiBaOnSelect',
        onDismiss: '&vuiBaOnDismiss'
    },
    controller: function ($bikeService) {
        'ngInject';

        this.$onInit = () => {
            this.resource = $bikeService.getResourceClass();
            this.selectedBikes = this.selectedBikes || [];
            this.excludeBikes = this.excludeBikes || [];
            this.refresh();
        };

        this.refresh = () => {
            this.paging = {
                current: -1,
                hasNext: true,
                hasPrev: false
            };

            this.bikeList = {};
            this.allBikes = [];
            excludeBikes();
            return this.search(this.filter);
        };

        this.search = (filter) => {
            if (!this.paging.hasNext) {
                return;
            }

            filter.page = this.paging.current + 1;
            let promise = loadBikes(filter);
            this.paging.hasNext = false;
            return promise;
        };

        this.clearInput = () => {
            this.filter.name = '';
            this.refresh();
        };

        this.isSelected = (item) => {
            return this.allBikes.filter(function (bike) {
                return bike.id === item.id && bike.selected;
            })[0];
        };

        this.select = (item) => {
            let bike = this.allBikes.filter(function (bike) {
                return bike.id === item.id;
            })[0];

            if (!bike) return;

            if (bike.selected) {
                bike.selected = false;
                let index = this.allBikes.indexOfObject("id", bike.id);
                if (index !== -1) {
                    this.selectedBikes.splice(index, 1);
                    dismiss(bike);
                }
            } else {
                bike.selected = true;
                this.selectedBikes.push(bike);

                if (this.options.maxBikes === 1) {
                    this.selectedBikes.forEach((bike) => {
                        dismiss(bike);
                    });
                    this.selectedBikes = [];
                    this.allBikes.forEach((bike) => {
                        bike.selected = false
                    });
                    bike.selected = true;
                    this.selectedBikes.push(bike);
                }

                select(bike);
            }
        };

        let excludeBikes = () => {
            this.excludeBikes.forEach((eb) => {
                if (!this.bikeList[eb.id]) {
                    this.bikeList[eb.id] = true;
                }
            });
        };

        let loadBikes = (query) => {
            return this.resource.list(query, function (data) {
                onLoadBikes(data);
            }).$promise;
        };

        let onLoadBikes = (data) => {
            this.paging = {
                hasNext: data.next,
                hasPrev: data.prev,
                current: data.current,
            };

            let bikeList = data.data;
            bikeList.forEach((bl) => {
                if (!this.bikeList[bl.id]) {
                    this.bikeList[bl.id] = true;
                    if (this.selectedBikes.indexOfObject("id", bl.id) !== -1) {
                        bl.selected = true;
                    }
                    this.allBikes.push(bl);
                }
            });

            this.selectedBikes.forEach((sb) => {
                if (this.allBikes.indexOfObject("id", sb.id) === -1) {
                    this.allBikes.unshift(sb);
                }
            });
        };

        let dismiss = (bike) => {
            this.onDismiss({bike: bike});
        };

        let select = (bike) => {
            this.onSelect({bike: bike});
        };
    }
};

export default angular.module("vui-bike-autocomplete.module", [])
    .component('vuiBikeAutocomplete', autocomplete);
