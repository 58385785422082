import app from "js/legacy-app";

app.controller('AdminRequestListCtrl',
    ['$scope', '$location', '$q', 'User', 'AdminRequest', '$dateTimeService', '$filter', 'CoreServiceManagement',
        function ($scope, $location, $q, User, AdminRequest, $dateTimeService, $filter, CoreServiceManagement) {

            var arlc = {};

            arlc.userAutocomplete = function (query) {
                var deferred = $q.defer();
                User.autocomplete({name: query}, function (response) {
                    deferred.resolve(response.items);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            };

            arlc.userAutocompleteByNameAndRole = function (name, role) {
                const deferred = $q.defer();
                User.findByNameLike({name: name, role: role}, function (response) {
                    deferred.resolve(response.items);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            };

            $scope.arlc = {
                search: function (query) {
                    return AdminRequest.get(query).$promise;
                },

                view: function (item) {
                    $location.path("/request/" + item.id).search({});
                },

                filter: {

                    user: {
                        displayItem: function (item) {
                            return item.name + " " + item.surname;
                        },
                        search: function (item) {
                            let role = 'USER,BIKE_BUSINESS_SUPERVISOR,BIKE_ORGANIZATION_OWNER';
                            return arlc.userAutocompleteByNameAndRole(item, role);
                        }
                    },

                    mechanic: {
                        displayItem: function (item) {
                            return item.name + " " + item.surname;
                        },
                        search: function (item) {
                            return arlc.userAutocompleteByNameAndRole(item, 'MECHANIC');
                        }
                    },

                    assignee: {
                        displayItem: function (item) {
                            return item.name + " " + item.surname;
                        },
                        search: function (item) {
                            return arlc.userAutocompleteByNameAndRole(item, 'ADMIN');
                        }
                    },

                    service: {
                        displayItem: function (item) {
                            return item.name;
                        },
                        search: function (query) {
                            return CoreServiceManagement.autocomplete({name: query}).$promise;
                        }
                    },

                    status: {
                        options: [
                            {val: null, display: 'All'},
                            {val: 'IN_PROGRESS', display: 'Scheduled'},
                            {val: 'PENDING', display: 'Pending'},
                            {val: 'RESOLVED', display: 'Resolved'},
                            {val: 'CANCELED', display: 'Canceled'},
                            {val: 'COULD_NOT_BE_FIXED', display: 'Could Not Be Fixed'},
                            {val: 'DONE', display: 'Done'},
                            {val: 'DRAFT', display: 'Awaiting Payment'},
                            {val: 'IN_DISPUTE', display: 'In Dispute'},
                            {val: 'REASSIGNED', display: 'Reassigned'},
                            {val: 'READY_FOR_REASSIGN', display: 'Ready For Reassign'},
                            {val: 'REFUNDED', display: 'Refunded'},
                            {val: 'TRANSFER_FAILED', display: 'Transfer Failed'},
                            {val: 'CHARGE_FAILED', display: 'Charge Failed'},
                            {val: 'REFUND_FAILED', display: 'Refund Failed'}
                        ]
                    },

                    chargeSource: {
                        options: [
                            {val: null, display: 'All'},
                            {val: 'USER', display: 'User'},
                            {val: 'ORGANISATION', display: 'Organisation'},
                            {val: 'SUPERVISOR', display: 'Supervisor'},
                            {val: 'VELOTOOLER', display: 'Velotooler'},
                        ]
                    },

                    sort: {
                        options: [
                            {val: "{orders:[{field:'createdAt',direction:'DESC'}]}", display: 'First Last Created'},
                            {val: "{orders:[{field:'createdAt',direction:'ASC'}]}", display: 'Created Long Time Ago'},
                            {val: "{orders:[{field:'updatedAt',direction:'DESC'}]}", display: 'First Last Updated'},
                            {val: "{orders:[{field:'updatedAt',direction:'ASC'}]}", display: 'Not Updated'},
                            {val: "{orders:[{field:'negotiation.dateRange.from',direction:'DESC'}]}", display: 'Starting Soon'},
                            {val: "{orders:[{field:'negotiation.dateRange.from',direction:'ASC'}]}", display: 'Long Time To Do'},
                        ]
                    }
                }
            };

            $scope.arlc.getDateInLocalTimeZone = function (request) {
                var from = null;
                var to = null;
                var locationOffset = request.serviceLocation.offset || 0;
                if (!request.appointmentDate) {
                    if (request.dateOfComplete) {
                        from = $dateTimeService.getTimeByOffset(locationOffset, request.dateOfComplete);
                    } else if (request.deadline) {
                        from = $dateTimeService.getTimeByOffset(locationOffset, request.deadline);
                    }
                } else {
                    from = $dateTimeService.getTimeByOffset(locationOffset, request.appointmentDate.startDate);
                    to = $dateTimeService.getTimeByOffset(locationOffset, request.appointmentDate.endDate);
                }

                var date = '';

                if (from) {
                    date += $filter('date')(from, 'mediumDate');
                    date += ', ';
                    date += $filter('date')(from, 'h:mma');
                }

                if (to) {
                    date += " - ";
                    date += $filter('date')(to, 'h:mma');
                }

                return date;

            };

            $scope.arlc.getCreatedAtInLocalTimeZone = function (request) {
                var locationOffset = request.serviceLocation.offset || 0;
                var createdAt = $dateTimeService.getTimeByOffset(locationOffset, request.createdAt);
                return $filter('date')(createdAt, 'dd MMM');

            };
        }
    ]);
