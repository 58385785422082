import app from "js/legacy-app";

app.directive('sendMessage', [function () {
    return {
        restrict: 'A',
        link: function (scope, element, attrs) {
            element.bind("keydown", function (event) {
                var code = event.keyCode || event.which;

                if (code === 13) {
                    if (!event.shiftKey) {
                        event.preventDefault();
                        scope.$apply(attrs.sendMessage);
                    }
                }
            });
        }
    };
}]);
