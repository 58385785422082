import app from "js/legacy-app";
import defaultExperienceImg from 'img/diploma.svg';

app.controller('ExperienceVerificationCtrl', ['$scope', 'User', '$location', 'AuthService',
    'Notification', 'ToggleLD', 'FileService', 'Upload', 'Documents',
    function ($scope, User, $location, AuthService, Notification,
              ToggleLD, FileService, Upload, Documents) {

        $scope.experienceVerification = {
            showImg: true,
            type: 'BIKE_SCHOOL_DIPLOMA',
            disabledUpload: true
        };

        AuthService.currentUser(function () {
            $scope.user = AuthService.user();
        });

        $scope.refresh = function () {
            User.getVerification(function (data) {
                $scope.verification = data;

                $scope.experienceVerification.showImg = false;
                if ($scope.verification && $scope.verification.experienceVerification) {
                    if ($scope.verification.experienceVerification.original) {
                        $scope.experienceVerification.defaultImg = $scope.verification.experienceVerification.thumbnail;
                    } else {
                        $scope.experienceVerification.defaultImg = defaultExperienceImg;
                    }
                    if ($scope.verification.experienceVerification.type) {
                        $scope.experienceVerification.type = $scope.verification.experienceVerification.type;
                    }
                } else {
                    $scope.experienceVerification.defaultImg = defaultExperienceImg;
                }
                $scope.experienceVerification.showImg = true;
            });
        };

        $scope.images = [];

        $scope.chooseFile = function (files, invalidFiles) {
            var file;
            if (files[0]) {
                file = files[0];
            } else if (invalidFiles[0]) {
                file = invalidFiles[0];
            }

            if (!FileService.isValidFile(file)) {
                return;
            }

            $scope.experienceVerification.disabledUpload = false;
            $scope.experienceVerification.showImg = false;
            $scope.experienceVerification.document = file;
            $scope.experienceVerification.showImg = true;
        };

        $scope.upload = function () {
            if (!$scope.experienceVerification.document && !$scope.verification.experienceVerification.original) {
                Notification.warning("Please, upload document");
                return;
            }

            save();
        };

        var save = function () {
            ToggleLD.show();
            $scope.verification.experienceVerification = {
                type: $scope.experienceVerification.type,
                document: $scope.experienceVerification.document
            };

            Documents.experienceVerification().upload($scope.verification.experienceVerification, function () {
                ToggleLD.hide();
                Notification.success("Education has been successfully updated");
                $location.path("/dashboard");
            }, function (error) {
                ToggleLD.hide();
                onUploadError(error);
            });
        };

        var onUploadError = function (error) {
            Notification.error("Failed to update education");
            $location.path('/dashboard');
            $scope.experienceVerification.disabledUpload = false;
        };

        $scope.refresh();

    }
]);