import app from "js/legacy-app";

app.controller('ViewUncoatedAreaCtrl', ['$scope', 'UncoatedArea', '$routeParams', 'ErrorHandler', '$location',
    function ($scope, UncoatedArea, $routeParams, ErrorHandler, $location) {
        $scope.uac = {
            uncoatedArea: {
                id: $routeParams.id,

                refresh: function () {
                    UncoatedArea.get({id: $scope.uac.uncoatedArea.id}, function (ua) {
                        $scope.uac.uncoatedArea.data = ua;
                    }, function (error) {
                        ErrorHandler.onError(error, "Failed to load uncoated area");
                        $location.url('/admin/analytics/uncoated-areas');
                    });
                }


            }
        };

        $scope.uac.uncoatedArea.refresh();
    }]);