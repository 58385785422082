import app from "js/legacy-app";

app.directive('plLoadingIndicator', [function () {
    return {
        templateUrl: "template/component/widget/pageable-list/pl-loading-indicator.html",
        replace: true,
        link: function ($scope, element, $attrs) {
            $scope.pageableList.loadingText = $attrs.plLoadingText;
        }
    };
}]);