import app from "js/legacy-app";
import angular from "angular";

app.service('Notification', ['$mdToast', '$timeout', 'ModalService', function ($mdToast, $timeout, ModalService) {
    var showNotificationSuccess = function (text) {
        showAlert(template('success', text));
    };

    var showNotificationInfo = function (text) {
        showAlert(template('info', text));
    };

    var showNotificationWarning = function (text) {
        showAlert(template('warning', text));
    };

    var showSingleNotificationError = function (text) {
        showAlert(template('error', text));
    };

    var showNotificationErrorDialog = function (errorMessages, title) {
        return ModalService.showNotificationErrorDialog(errorMessages, title);
    };

    var notification = function (notification) {
        showNotificationAlert(notification);
    };

    var showAlert = function (template) {
        $mdToast.show({
            controller: 'NotificationCtrl',
            template: template,
            hideDelay: 3000,
            locals: {
                notification: null
            },
            position: "top left",
            autoWrap: false
        });
    };

    function showNotificationAlert(notification) {

        $mdToast.show({
            controller: 'NotificationCtrl',
            templateUrl: 'components/notification/template/notification-toast.html',
            hideDelay: 4000,
            locals: {
                notification: notification
            },
            position: "bottom right",
            autoWrap: false
        });

        $timeout(function () {
            var btn = document.getElementsByClassName('ui-toast-btn')[0];
            if (angular.isDefined(btn)) {
                btn.addEventListener('click', onButtonClick);
            }
        }, 500);

    }

    function onButtonClick(e) {
        closeSubMenu();
    }

    function closeSubMenu() {
        var submenuList = document.documentElement.getElementsByClassName('ui-submenu');
        if (submenuList) {
            for (var i = 0; i < submenuList.length; i++) {
                submenuList[i].classList.remove('ui-submenu-active');
            }
        }

        document.documentElement.removeEventListener('click', closeSubMenu, true);
    }

    var template = function (templateClass, text) {
        return '<md-toast>' +
            '<div class="md-toast-content ' + templateClass + '">' +
            '<div class="md-toolbar-tools">' +
            '<span  class="md-toast-text">' + text + '</span>' +
            '<md-button class="md-icon-button" ng-click="nCtrl.closeNotification($event)">' +
            '<md-icon aria-label="Close notification">' +
            '<i class="vt-icon vt-icon__cancel"></i>' +
            '</md-icon>' +
            '</md-button>' +
            '</div>' +
            '</div>' +
            '</md-toast>';
    };

    return {
        success: showNotificationSuccess,
        info: showNotificationInfo,
        warning: showNotificationWarning,
        error: showSingleNotificationError,
        showNotificationErrorDialog: showNotificationErrorDialog,
        notification: notification
    };
}]);
