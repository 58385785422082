import app from "js/legacy-app";

app.directive('vtSubmenu', [function () {
    return {
        templateUrl: "template/component/vt-submenu.tpl.html",
        link: function ($scope, $element) {
            var submenu = $element[0].firstChild;
            new window.ui.submenu(submenu);
            window.ui.eventService.scrollMenu();
            $scope.isMobile = window.ui.resolutionService.isMobile();
            $scope.isTablet = window.ui.resolutionService.isTablet();
            $scope.isFullScreen = window.ui.resolutionService.isFullScreen();

            window.addEventListener('resize', function () {
                $scope.$apply(function () {
                    $scope.isMobile = window.ui.resolutionService.isMobile();
                    $scope.isTablet = window.ui.resolutionService.isTablet();
                    $scope.isFullScreen = window.ui.resolutionService.isFullScreen();
                });
            });
        },
        controller: ['$scope', 'AuthService', '$rootScope', '$route', '$routeParams', 'Organisation',
            function ($scope, AuthService, $rootScope, $route, $routeParams, Organisation) {

                if (isOpenOrganisation()) {
                    $scope.organisationId = $routeParams.organisationId;
                }

                $scope.auth = AuthService;

                $scope.isLoggedIn = function () {
                    if (!AuthService.isFullyLoggedIn()) {
                        return {'display': 'none'};
                    }
                };

                $scope.$on('$routeChangeStart', function (next, current) {
                    if (isOpenOrganisation()) {
                        $scope.organisationId = $routeParams.organisationId;
                    }
                });

                $scope.isActive = function(url) {
                    if ($route.current.$$route && $route.current.$$route.originalPath) {
                        return $route.current.$$route.originalPath === url ? 'active-element' : '';
                    }
                    return '';
                };

                function isOpenOrganisation() {
                    return $route.current.segment &&
                        ($route.current.segment.match(/^organisation.*/) &&
                            $route.current.segment.match(/^organisation.*/).length > 0);
                }

                if (AuthService.isBikeOrganisationOwner()) {
                    $scope.showOrganisation = true;
                }
            }
        ]
    };
}]);
