import "ng-wistia-components/ng-wistia-components";
import angular from "angular";

const playerComponent = {
    template: require('./wistia-video-player.html'),
    transclude: true,
    bindings: {
    }
};

export default angular.module("vui-wistia-video-player", ["ngWistiaComponents"])
    .component("vuiWistiaVideoPlayer", playerComponent);
