export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/partner/dealers', 'partner-dealers')
        .when('/partner/dealers/:mechanicId', 'partner-dealer-details')

        .segment('partner-dealers', {
            access: {requireLogin: true},
            template: '<partner-dealer-list></partner-dealer-list>',
            loadModule: () => {
                return import(/* webpackChunkName: "partner-dealer-list.module" */ "./dealer-list/dealer-list.module");
            }
        })
        .segment('partner-dealer-details', {
            access: {requireLogin: true},
            template: '<partner-dealer-details></partner-dealer-details>',
            loadModule: () => {
                return import(/* webpackChunkName: "partner-dealer-details.module" */ "./dealer-details/dealer-details.module");
            }
        });

}
