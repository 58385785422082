import app from "js/legacy-app";

app.directive('profileReview', [function () {
    return {
        templateUrl: "template/profile/tabs/review/review.html",
        link: function ($scope, $element, $attrs, ngModel) {},
        controller: ['$scope', '$routeParams', 'Rating', 'AuthService', function ($scope, $routeParams, Rating, AuthService) {
            var size = 5,
                page = 0;

            var isPublicMechanicProfile = $scope.isPublicMechanic;

            $scope.profileReview = {};

            $scope.refreshReview = function () {
                if(isPublicMechanicProfile) {
                    Rating.getMechanicReviews({param2: $routeParams.id, page: page, size: size}, function (data) {
                        $scope.profileReview.reviews = data;
                        $scope.showMechanic.feedbacks = data.total;
                    });
                } else {
                    if (AuthService.isMechanic()) {
                        Rating.getMechanicReviews({param2: AuthService.user().id, page: page, size: size}, function (data) {
                            $scope.profileReview.reviews = data;
                        });
                    } else {
                        Rating.getUserReviews({param2: AuthService.user().id, page: page, size: size}, function (data) {
                            $scope.profileReview.reviews = data;
                        });
                    }
                }
            };

            if(isPublicMechanicProfile) {
                $scope.refreshReview();
            } else {
                AuthService.currentUser($scope.refreshReview);
            }

            $scope.prev = function () {
                if ($scope.profileReview.reviews.prev || (page !== 0 && !$scope.profileReview.reviews.data)) {
                    page--;
                    $scope.refreshReview();
                }
            };

            $scope.next = function () {
                if ($scope.profileReview.reviews.next) {
                    page++;
                    $scope.refreshReview();
                }
            };

        }]
    };
}]);
