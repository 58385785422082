/**
 * Created by eugineer on 2/14/17.
 */
import app from "js/legacy-app";

app.controller("ShowHowVelotoolerWorksVideoController", ['$scope', '$mdDialog',
    function ($scope, $mdDialog) {
        $scope.close = function() {
            $mdDialog.hide();
        };
    }
]);