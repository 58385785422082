import angular from "angular";
import "./booking-pricing-details.scss";

const component = {
    template: require('./booking-pricing-info.html'),
    bindings: {
        model: "<vuiBpiModel"
    },
    controller: function () {
        this.$onChanges = (changes) => {
            let value = changes.model.currentValue;
            this.totalServicePrice = value.servicePrice * value.quantity;
            this.totalServiceFees = this.totalServicePrice * value.serviceFees;
            this.totalPartsFees = value.totalPartsPrice * value.partsFees;
            this.totalPayout = this.totalServicePrice - this.totalServiceFees + value.totalPartsPrice - this.totalPartsFees;
        };
    }
};

export default angular.module("vui-booking-pricing-info.module", [])
    .component('vuiBookingPricingInfo', component);
