import app from "js/legacy-app";

app.filter("stationType", [function () {
    return function (type) {
        switch (type) {
            case 'STATION':
                return 'Station';
            case 'TERRITORY':
                return 'Territory';
            case 'WAREHOUSE':
                return 'Warehouse';
            case 'BIKE_SHOP':
                return 'Bicycle shop';
            default:
                return 'N/A';
        }
    };
}]);