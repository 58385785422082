import app from "js/legacy-app";

app.directive('requestStatusStatistic', ['$filter', function ($filter) {
    return {
        template: '<div class="md-padding chart-container">' +
        '   <div>Request by status</div>' +
        '   <div>' +
        '       <canvas class="chart chart-pie" chart-data="data" chart-labels="labels" chart-options="options"></canvas>' +
        '   </div>' +
        '</div>',
        replace: true,
        scope: true,
        controller: ['$scope', 'AdminRequest', function ($scope, AdminRequest) {

            $scope.labels = [];
            $scope.data = [];

            AdminRequest.countByStatus(function(data) {
                for (var i = 0; i < data.length; i++) {
                    if (!data[i].status) {
                        continue;
                    }
                    var status = $filter('requestStatus')(data[i].status).toLowerCase();
                    $scope.labels.push(status);
                    $scope.data.push(data[i].count);
                }
            });

        }]
    };
}]);
