import './required-label.component.scss';

export default () => {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        template: (element, attr) => {
            return '<span class="vui-required-label">*</span>';
        },
        link: (scope, element, attr) => {
        }
    };
}
