import angular from 'angular';

(function () {

    'use strict';

    function cardZipValidator($timeout) {

        function setValue(elm, value, scope, ngModelCtrl) {
            elm.val(value);
            ngModelCtrl.$setViewValue(value);

            $timeout(function() {
                elm.val(value);
                scope.$apply();
            });
        }

        return {
            require: 'ngModel',
            link: function (scope, elm, attrs, ngModelCtrl) {
                function formateCardZip(e) {
                    var elm = angular.element(e.currentTarget || e.srcElement);
                    var value = elm.val();
                    if (!value || value === ' ') {
                        setValue(elm, value, scope, ngModelCtrl);
                        return;
                    }

                    value = value.replace(/^\s+/, '');
                    /* Delete first spaces */
                    value = value.replace(/\s(?=\s)/g, '');
                    /* Delete double spaces */
                    value = value.replace(/^-+/, '');
                    /* Delete first hyphens */
                    value = value.replace(/-(?=-)/g, '');
                    /* Delete double hyphens */
                    value = value.replace(/[^A-Za-z0-9-\s]/g, '').substr(0, 10).toUpperCase();

                    var valid = (value.length > 3 && /\d/g.test(value));
                    ngModelCtrl.$setValidity('ccAddressZip', valid);


                    setValue(elm, value, scope, ngModelCtrl);
                    e.preventDefault();
                }

                elm.on('input', formateCardZip);
                elm.on('paste', formateCardZip);
            }
        };
    }

    var app = angular.module('legacy-app');
    app.directive('cardZipValidator', ['$timeout', cardZipValidator]);
})();