import app from "js/legacy-app";

app.directive('insurance', [function () {
    return {
        templateUrl: "template/settings/tabs/insurance/insurance.html",
        scope: {
            insurance: '=',
            userId: '&'
        },
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: ['$scope', 'Notification', 'Insurance', 'ModalService', 'NotificationWSService',
            '$rootScope', '$location', 'ErrorHandler',
            function ($scope, Notification, Insurance, ModalService, NotificationWSService,
                      $rootScope, $location, ErrorHandler) {

                $scope.profileInsurance = {
                    insurances: {},
                    current: 0,
                    size: 10
                };

                function hasInsurances() {
                    Insurance.hasInsurance({param2: $scope.userId()}, function (data) {
                        $scope.profileInsurance.hasInsurance = data.hasInsurance;
                        $scope.profileInsurance.hasGroupInsurance = data.hasGroupInsurance;
                    });
                }

                hasInsurances();

                $scope.profileInsurance.find = function () {
                    var paging = {
                        page: $scope.profileInsurance.current,
                        size: $scope.profileInsurance.size
                    };

                    if ($scope.insurance === 'admin') {
                        paging.param2 = $scope.userId();
                        Insurance.getByUser(paging, function (data) {
                            $scope.profileInsurance.setDate(data);
                        });
                    } else {
                        Insurance.get(paging, function (data) {
                            $scope.profileInsurance.setDate(data);
                        });
                    }
                };

                $scope.profileInsurance.setDate = function (data) {
                    $scope.profileInsurance.insurances = data.data;
                    $scope.profileInsurance.size = data.size;
                    $scope.profileInsurance.next = data.next;
                    $scope.profileInsurance.current = data.current;
                    $scope.profileInsurance.count = data.total;
                    $scope.profileInsurance.prev = data.prev;
                    $scope.profileInsurance.pages = data.pages;
                };

                $scope.profileInsurance.getPage = function (page) {
                    if ($scope.profileInsurance.current !== page) {
                        $scope.profileInsurance.current = page;
                        $scope.profileInsurance.find();
                    }
                };

                $scope.profileInsurance.getPageInfo = function () {
                    var from, to;
                    if ($scope.profileInsurance.count) {
                        from = ($scope.profileInsurance.count !== 0 ? ($scope.profileInsurance.current * $scope.profileInsurance.size + 1) : 0);
                        to = ($scope.profileInsurance.count < ($scope.profileInsurance.current * $scope.profileInsurance.size + $scope.profileInsurance.size) ?
                            $scope.profileInsurance.count : ($scope.profileInsurance.current * $scope.profileInsurance.size + $scope.profileInsurance.size));
                        return 'From ' + from + ' to ' + to + ' of ' + $scope.profileInsurance.count;
                    }

                    return 'No records';
                };

                $scope.profileInsurance.getNextPage = function () {
                    if ($scope.profileInsurance.current !== $scope.profileInsurance.pages[$scope.profileInsurance.pages.length - 1]) {
                        $scope.profileInsurance.current++;
                        $scope.profileInsurance.find();
                    }
                };

                $scope.profileInsurance.getPrevPage = function () {
                    if ($scope.profileInsurance.current !== 0) {
                        $scope.profileInsurance.current--;
                        $scope.profileInsurance.find();
                    }
                };

                $scope.profileInsurance.find();

                $scope.profileInsurance.delete = function ($event, id, index) {
                    ModalService.yesNoMessage($event, '', 'Are you sure you want to remove this insurance?', function (responce) {
                        if (responce) {
                            Insurance.delete({id: id}, function () {
                                hasInsurances();
                                $scope.profileInsurance.insurances.splice(index, 1);
                                if ($scope.profileInsurance.insurances.length === 1) {
                                    $scope.profileInsurance.getPrevPage();
                                } else {
                                    $scope.profileInsurance.find();
                                }
                            }, function (error) {
                                ErrorHandler.onError(error);
                            });
                        }
                    });
                };

                $scope.profileInsurance.getStatusClass = function (status) {
                    switch (status) {
                        case 'ACTIVE':
                            return 'active';
                        case 'EXPIRED':
                            return 'expired';
                        case 'PENDING_CONFIRMATION':
                            return 'pending';
                        case 'IN_FUTURE':
                            return 'in-future';
                        default:
                            return 'in-future';
                    }
                };

                $scope.profileInsurance.checkInsurance = function ($event, insurance) {
                    var ins = {
                        id: insurance.id,
                        createdAt: insurance.createdAt,
                        updatedAt: insurance.updatedAt,
                        registrationDate: insurance.registrationDate,
                        expirationDate: insurance.expirationDate,
                        price: insurance.price,
                        status: insurance.status,
                        type: insurance.type,
                        document: insurance.document
                    };
                    ModalService.checkInsurance($event, ins, function (response) {
                        if (response) {
                            var request = {
                                price: response.price,
                                registrationDate: response.registrationDate,
                                expirationDate: response.expirationDate,
                                type: response.type
                            };
                            Insurance.update({param1: response.id}, request, function () {
                                $scope.profileInsurance.find();
                            }, function (error) {
                                ErrorHandler.onError(error);
                            });
                        }
                    });
                };
            }
        ]
    };
}]);
