import app from "js/legacy-app";

app.controller('UncoatedAreaListCtrl', function ($scope, UncoatedArea, $location) {
    'ngInject';

    $scope.alc = {

        search: function (query) {
            query.sort = {
                orders: [{
                    field: "createdAt",
                    direction: 'DESC'
                }]
            };
            return UncoatedArea.get(query).$promise;
        },

        view: function (item) {
            $location.url("/admin/analytics/uncoated-areas/" + item.id);
        },

        exportHeader: () => ['Email', 'Address', 'Date'],

        exportMapper: item => {
            return [
                item.email,
                item.location ?
                    item.location.address ||
                    item.location.googleAddress ||
                    item.location.zip + ", " + item.location.city + ", " + item.location.state + ", " + item.location.country :
                    "",
                item.createdAt
            ];
        }
    };

});