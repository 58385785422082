import app from "js/legacy-app";

app.service('Company', ['$resource', function ($resource) {
    return $resource('/api/company/:param', {}, {
        getCurrent: {
            method: "GET",
            params: {param: "current"},
            isArray: false
        },
        patch: {
            method: "PATCH",
            params: {param: ""},
            isArray: false
        }
    });
}]);