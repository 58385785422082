import app from "js/legacy-app";
import defaultBikeImage from "img/no-bike.png";

app.directive('blItemBody', ['Notification', 'SaleCertificateService',
    function (Notification, SaleCertificateService) {
        return {
            templateUrl: "components/bike/list/base/bl-item-body.html",
            link: function ($scope) {

                $scope.blib = {};

                $scope.blib.defaultBikeImage = defaultBikeImage;

                $scope.blib.getCertificationImageUrl = function (bike) {
                    return SaleCertificateService.getCertificationInfo(bike.totalCondition).img;
                };
            }
        };
    }]);