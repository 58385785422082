import app from "js/legacy-app";

app.directive('copyInputContent', ['$timeout', function ($timeout) {
    return {
        link: function (scope, element, attrs) {
            $timeout(function() {
                $timeout(function() {
                    element[0].select();
                });
            });
        }
    };
}]);