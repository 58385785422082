import app from "js/legacy-app";

app.service('HistoryService', ['$location', '$rootScope', function ($location, $rootScope) {

    var history = [];
    $rootScope.$on('$locationChangeSuccess', function () {
        if (history.length > 0 && history[history.length - 1].path == $location.$$path) {
            history[history.length - 1].params = $location.$$search;
            history[history.length - 1].url = $location.$$url;
        } else {
            history.push({
                path: $location.$$path,
                params: $location.$$search || {},
                url: $location.$$url
            });
        }
        if (history.length > 100) {
            history = history.slice(1, history.length);
        }
    });

    return {
        getPrevUrl: function (altUrl) {
            return history.length > 1 ? history[history.length - 2].url : altUrl;
        },
        back: function (altUrl, altParams) {
            if (history.length > 1) {
                var prevPage = history[history.length - 2];
                history = history.slice(0, history.length - 1);
                $location.path(prevPage.path).search(prevPage.params);
                return;
            }
            var q = $location.path(altUrl);
            if (altParams) {
                q.search(altParams);
            }
            history = history.slice(0, history.length - 1);
        },
        clear: function () {
            history = [];
        }
    };

}]);