import app from "js/legacy-app";

app.controller('ShowPublicBikeLinkController', ['$scope', '$mdDialog', '$bikeService', 'id',
    function ($scope, $mdDialog, $bikeService, id) {

        $bikeService.getResourceClass().generatePublicId({id: id}, function (response) {
            $scope.showPublicLink = {
                link: window.location.protocol + '//' + document.domain +
                (document.location.port ? (':' + document.location.port) : '') + '/bike/public/' + response.fakeId
            };
        });

        $scope.close = function () {
            $mdDialog.hide();
        };

    }
]);