import app from "js/legacy-app";

app.controller('CreateBikeCtrl', ['$scope', '$location', 'ToggleLD', '$bikeService', 'AuthService',
    '$routeParams', '$requestStorage', 'ADD_BIKE_TABS', '$typeOfBikesService',
    function ($scope, $location, ToggleLD, $bikeService, AuthService, $routeParams, $requestStorage,
              ADD_BIKE_TABS, $typeOfBikesService) {

        function init() {
            $scope.addBike = {
                isLogin: AuthService.isLogin() && !$requestStorage.hasDraftedBike(),
                disabledSave: false,
                bike: {
                    colors: [],
                    components: [{
                        name: 'frame',
                        brand: '',
                        model: '',
                        id: 0,
                        condition: {
                            rate: 1
                        }
                    }],
                    fixLocation: {}
                },
                loadedData: false,
                stationId: $routeParams.stationId,
                organisationId: $routeParams.organisationId,
                stationQuery: $routeParams.stationQuery
            };

            ToggleLD.show();
            $bikeService.loadBikeDescDependencies(function (response) {
                if (AuthService.isUser() && $requestStorage.hasDraftedBike()) {
                    $bikeService.createDraftedBike(function () {
                        if (setDataFromLocalStorageToBike()) {
                            $scope.changeForm(4);
                            $scope.addBike.loadedData = true;
                        } else {
                            $scope.changeForm(0);
                            $scope.addBike.loadedData = true;
                        }
                    });
                } else {
                    $scope.changeForm(0);
                    $scope.addBike.loadedData = true;
                }
                ToggleLD.hide();
            });
        }

        $scope.changeForm = function (indexTab) {
            if (indexTab === 3 && !$scope.addBike.isLogin) {
                indexTab += 1;
            }
            var tab = ADD_BIKE_TABS[indexTab];
            if (!tab) {
                return;
            }
            $scope.selectedTab = tab.selectedTab;
            $scope.title = tab.title;
        };

        $scope.back = function () {
            if ($scope.selectedTab === 0) {
                $bikeService.cancelCreateBike();
            } else if ($scope.addBike.bike.id && $scope.selectedTab === 5) {
                $scope.changeForm(0);
            } else if ($scope.selectedTab === 4 && !$scope.addBike.isLogin) {
                $scope.changeForm($scope.selectedTab - 2);
            } else {
                $scope.changeForm($scope.selectedTab - 1);
            }
        };

        function setDataFromLocalStorageToBike() {
            var response = $bikeService.parseBikeFromLocalStorage();

            if (!response) {
                return false;
            }

            $scope.addBike.bike = response.bike;
            $scope.addBike.bike.components = response.components;
            $bikeService.setGroupset(response.groupset);
            $bikeService.setBrand(response.brand);
            $bikeService.setModel(response.model);
            var types = $typeOfBikesService.getAll();
            for (var i = 0; i < types.length; i++) {
                if (types[i].name === $scope.addBike.bike.type) {
                    $bikeService.setBikeType(types[i]);
                    break;
                }
            }

            return true;
        }

        init();

        $scope.$watch('addBike.bike.make', function (val) {
            $scope.addBike.bike.components[0].brand = val;
        });

        $scope.$watch('addBike.bike.model', function (val) {
            $scope.addBike.bike.components[0].model = val;
        });

        $scope.$on("$destroy", function () {
            $bikeService.cleatTempData();
        });
    }
]);
