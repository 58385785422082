import app from "js/legacy-app";

app.directive('profileAddressFavorite', ['User', 'ErrorHandler', 'ToggleLD', 'LocationService',
    function (User, ErrorHandler, ToggleLD, LocationService) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'template/profile/address/profile-address-favorite.html',
            scope: {
                locations: "=pafLocations"
            },

            link: function ($scope, element, $attr) {

                $scope.paf = {};

                refreshLocations();

                $scope.paf.onAdd = function (location) {
                    $scope.locations.push(location);
                };

                $scope.paf.onRemove = function (location) {
                    var removeIdx = -1;
                    for (var i = 0; i < $scope.locations.length; i++) {
                        if ($scope.locations[i].id === location.id) {
                            removeIdx = i;
                            break;
                        }
                    }
                    if (removeIdx > -1) {
                        $scope.locations.splice(removeIdx, 1);
                    }
                };


                $scope.$watchCollection('locations', function (newVal, oldVal) {
                    refreshLocations();
                });

                function refreshLocations() {
                    $scope.paf.locations = {
                        home: LocationService.filterLocationByType($scope.locations, 'HOME'),
                        work: LocationService.filterLocationByType($scope.locations, 'WORK')
                    };
                }

            }
        };
    }]);