import app from "js/legacy-app";
import angular from "angular";

app.directive('createCustomPersonalService', ['$vtSidenav', 'Notification', 'SidenavLD', function ($vtSidenav, Notification, SidenavLD) {
    return {
        templateUrl: "components/service/create/create-custom-personal-service.html",
        replace: true,
        restrict: 'E',
        transclude: true,
        scope: {
            onSave: '&ccpsOnSave'
        },
        controller: ['$scope', 'CustomServiceManagement',
            function ($scope, CustomServiceManagement) {

                var cis = {};

                cis.get = function () {
                    return {
                        name: $scope.cis.service.name,
                        description: $scope.cis.service.description,
                        price: $scope.cis.service.price,
                        timeToComplete: $scope.cis.service.timeToComplete,
                        categoryId: $scope.cis.service.selectedCategory.id,
                        autoaccept: $scope.cis.service.autoaccept
                    };
                };

                $scope.cis = {};

                $scope.cis.onOpen = function (model, onSuccess, onError) {
                    $scope.cis.service = {
                        selectedCategory: model.category,
                        autoaccept: false
                    };

                    onSuccess();
                };
                $scope.cis.save = function (onError) {
                    SidenavLD.show();
                    CustomServiceManagement.save(cis.get(), function (data) {
                        SidenavLD.hide();
                        $vtSidenav("create-custom-personal-service").close();
                        if (angular.isFunction($scope.onSave)) {
                            $scope.onSave({data: data});
                        }
                        Notification.success('Service was created');
                    }, function (error) {
                        onError(error);
                        SidenavLD.hide();
                    });
                };
            }]
    };
}]);
