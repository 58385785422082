import app from "js/legacy-app";

app.controller('AddUpdateTaskFlowDescriptionsCtrl', function ($scope, ServiceExecutionFlow, $location, $q, $routeParams, User, CoreServiceManagement) {
    'ngInject';

    $scope.serviceFlow = {
        description: {
            steps: [],
            tasks: []
        }
    };

    (function getLanguages() {
        User.query({id: "languages-code"}, function (data) {
            $scope.serviceFlow.languages = data;
            $scope.serviceFlow.description.selectedLanguage = data[0];
        });
    })();


    let id = $routeParams.id;
    if (id) {
        ServiceExecutionFlow.get({id: id}, function (response) {
            $scope.serviceFlow.description.selectedLanguage = response.language;
            let i;
            for (i = 0; i < response.steps.length; i++) {
                $scope.serviceFlow.description.steps.push(response.steps[i]);
            }
            for (i = 0; i < response.services.length; i++) {
                $scope.serviceFlow.description.tasks.push({
                    searchText: response.services[i].name,
                    selectedItem: response.services[i]
                });
            }
        });
    }

    $scope.addStep = function () {
        $scope.serviceFlow.description.steps.push({
            description: ''
        });
    };

    $scope.addTask = function () {
        $scope.serviceFlow.description.tasks.push({
            autocomplete: {}
        });
    };

    $scope.querySearch = function (name) {
        let deferred = $q.defer();
        CoreServiceManagement.autocomplete({name: name}, function (response) {
            let tasks = [];
            for (let i = 0; i < response.length; i++) {
                let contains = false;
                for (let j = 0; j < $scope.serviceFlow.description.tasks.length; j++) {
                    if ($scope.serviceFlow.description.tasks[j].selectedItem &&
                        $scope.serviceFlow.description.tasks[j].selectedItem.id === response[i].id) {
                        contains = true;
                    }
                }

                if (!contains) {
                    tasks.push(response[i]);
                }
            }
            deferred.resolve(tasks);
        }, function (error) {
            deferred.reject(error);
        });
        return deferred.promise;
    };

    $scope.removeTask = function (index) {
        $scope.serviceFlow.description.tasks.splice(index, 1);
    };

    $scope.removeStep = function (index) {
        $scope.serviceFlow.description.steps.splice(index, 1);
    };

    $scope.save = function (form) {
        if (form.$valid) {
            let flowDesc = {
                steps: [],
                taskIds: [],
                language: $scope.serviceFlow.description.selectedLanguage
            };

            let i;
            for (i = 0; i < $scope.serviceFlow.description.steps.length; i++) {
                if ($scope.serviceFlow.description.steps[i].description) {
                    flowDesc.steps.push({description: $scope.serviceFlow.description.steps[i].description});
                }
            }
            for (i = 0; i < $scope.serviceFlow.description.tasks.length; i++) {
                if ($scope.serviceFlow.description.tasks[i].selectedItem) {
                    flowDesc.taskIds.push($scope.serviceFlow.description.tasks[i].selectedItem.id);
                }
            }

            if (id) {
                ServiceExecutionFlow.patch({id: id}, flowDesc, function (data) {
                    $location.url('/admin/service-flow').search({id: id});
                });
            } else {
                ServiceExecutionFlow.save(flowDesc, function (data) {
                    $location.url('/admin/service-flow').search({id: id});
                });
            }


        }
    };

});
