import app from "js/legacy-app";

app.controller('CreateCoreServiceBucketCtrl',
    function ($scope, $location, ErrorHandler, ToggleLD, Notification, CoreServiceBuckets) {
        'ngInject';

        $scope.serviceBucket = {
            mechanicsCustomizationAvailable: false,
            enableByDefault: true
        };

        $scope.ctrl = {

            save: function (form) {
                if (!form.$valid) {
                    return;
                }

                ToggleLD.show();
                CoreServiceBuckets.save(this.get(), function (c) {
                    ToggleLD.hide();
                    $location.url("/admin/view-bucket/" + c.id);
                    Notification.success("Service bucket has been created");
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error, "Failed to create service bucket", "ERROR", form);
                });
            },

            close: function () {
                $location.path("/admin/service-management/service-buckets");
            },

            get: function () {
                let serviceBucket = $scope.serviceBucket;
                let dto = {};

                dto.name = serviceBucket.name;
                dto.description = serviceBucket.description;
                dto.mechanicsCustomizationAvailable = serviceBucket.mechanicsCustomizationAvailable;
                dto.defaultState = $scope.serviceBucket.enableByDefault ? 'ENABLED' : 'DISABLED';
                return dto;
            }
        };
    }
);
