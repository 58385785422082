import app from "js/legacy-app";

app.service('$serviceCoverageChecker', ['Mechanics', 'Notification', 'LocationService',
    function (Mechanics, Notification, LocationService) {

        var checkCoverage = function (address, coveredCallback, uncoveredCallback, errorCallback) {

            var error = LocationService.validateAddress(address);

            if (error) {
                errorCallback({messages: [error]});
                return;
            }

            Mechanics.checkCoverage(
                {
                    latitude: address.latitude,
                    longitude: address.longitude,
                    city: address.city,
                    country: address.country,
                    state: address.state,
                    zip: address.zip,
                    address: address.address
                },
                function (data) {
                    if (data.covered) {
                        coveredCallback(data);
                    } else {
                        uncoveredCallback(data);
                    }
                }, function (error) {
                    errorCallback(error);
                });
        };

        return {
            checkCoverage: checkCoverage
        };
    }
]);

