import app from "js/legacy-app";
import angular from "angular";

app.directive('mechanicSearchMap', ['$compile', '$timeout', function ($compile, $timeout) {
    return {
        scope: {
            controller: '=msmController'
        },
        controller: ['$scope', function ($scope) {
            $scope.showNativeMaps = function () {
                alert('showNativeMaps');
            };
        }],
        link: function ($scope, $element) {

            var existingAreas = [], currentInfoWindow = null;

            $scope.controller.addWorkArea = function (area, options) {
                var defaultAreaOptions = {
                    center: area.center,
                    map: $scope.controller.map,
                    strokeColor: '#777777',
                    fillColor: '#777777',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillOpacity: 0.4,
                    radius: area.radius
                };

                var defaultMarkerOptions = {
                    position: area.center,
                    map: $scope.controller.map
                };

                options = options || {};

                var markerOptions = angular.extend({}, defaultMarkerOptions, options.marker || {});
                var areaOptions = angular.extend({}, defaultAreaOptions, options.area || {});

                var a = new window.google.maps.Circle(areaOptions);
                var m = new window.google.maps.Marker(markerOptions);

                if (options.infoWindow) {
                    assignClickListener(a, options.infoWindow, area.entity);
                    assignClickListener(m, options.infoWindow, area.entity);
                }

                assignMouseOverListener(m, area.entity);
                assignMouseOutListener(m, area.entity);

                var workArea = {
                    key: area,
                    marker: m,
                    area: a
                };
                existingAreas.push(workArea);

                return workArea;
            };

            $scope.controller.addManyWorkAreas = function (workAreas, options) {

                var areasToAdd = workAreas.filter(function (nc) {
                    return existingAreas.filter(function (ec) {
                        return ec.key.center.lat === nc.center.lat &&
                            ec.key.center.lng === nc.center.lng &&
                            ec.key.radius === nc.radius;
                    }).length === 0;
                });

                var areasToRemove = existingAreas.filter(function (ec) {
                    return workAreas.filter(function (nc) {
                        return ec.key.center.lat === nc.center.lat &&
                            ec.key.center.lng === nc.center.lng &&
                            ec.key.radius === nc.radius &&
                            (hasId(ec.key.entity) && hasId(nc.entity) ? ec.key.entity.id === nc.entity.id : true);
                    }).length === 0;
                });

                areasToRemove.forEach(function (ctr) {
                    ctr.area.setMap(null);
                    ctr.marker.setMap(null);
                });

                existingAreas = existingAreas.filter(function (ec) {
                    return ec.area.getMap() || ec.marker.getMap();
                });

                areasToAdd.forEach(function (cta) {
                    if (angular.isDefined(cta.entity.active) && !cta.entity.active) {
                        options.marker.icon = options.marker.inactiveIcon
                    } else {
                        options.marker.icon = options.marker.activeIcon || options.marker.icon
                    }
                    $scope.controller.addWorkArea(cta, options);
                });

                onDataChange();
            };

            function hasId(entity) {
                return entity && entity.id;
            }

            $scope.controller.highlightArea = function (id) {
                existingAreas.filter(function (area) {
                    return area.key.entity.id === id;
                }).forEach(function (wa) {
                    wa.area.set('fillColor', "#ffec92");
                    wa.marker.set('animation', window.google.maps.Animation.BOUNCE);
                    wa.area.setVisible(true);
                });
            };

            $scope.controller.unhighlightArea = function (id) {
                existingAreas.filter(function (area) {
                    return area.key.entity.id === id;
                }).forEach(function (wa) {
                    wa.area.set('fillColor', "#777");
                    wa.marker.set('animation', null);
                    wa.area.setVisible(false);
                });
            };

            var onDataChange = function () {
                existingAreas.forEach(function (c) {
                    c.area.setVisible(false);
                });
            };

            function assignMouseOverListener(m, entity) {
                m.addListener('mouseover', function (event) {
                    $scope.controller.highlightArea(entity.id);
                });
            }

            function assignMouseOutListener(m, entity) {
                m.addListener('mouseout', function (event) {
                    $scope.controller.unhighlightArea(entity.id);
                });
            }

            var assignClickListener = function (area, template, entity) {
                area.addListener('click', function (event) {

                    var innerScope = $scope.$new();

                    if (currentInfoWindow) {
                        currentInfoWindow.close();
                    }
                    currentInfoWindow = new window.google.maps.InfoWindow({
                        position: event.latLng,
                        content: '<div id="mechanic-info-window-' + entity.id + '">' + template(innerScope, entity) + '</div>'
                    });

                    window.google.maps.event.addListener(currentInfoWindow, 'domready', function () {
                        $scope.$apply(function () {
                            var element = $compile(document.getElementById("mechanic-info-window-" + entity.id))(innerScope);
                            element.on('$destroy', function () {
                                innerScope.$destroy();
                            });
                        });
                    });

                    currentInfoWindow.open($scope.controller.map);
                });
            };

            var init = function () {

                var mapOptions = {
                    zoom: 5,
                    center: new window.google.maps.LatLng(38.0000, -97.0000),
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    mapTypeControl: false,
                    navigationControl: false,
                    streetViewControl: false,
                    fullscreenControl: true,
                    minZoom: 4,
                    zoomControlOptions: {
                        position: window.google.maps.ControlPosition.LEFT_TOP
                    },
                    scrollwheel: true,
                    gestureHandling: 'greedy',
                    styles: [
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.business",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#bad294"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "color": "#4c9948"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#fbfbfb"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#a5d7e0"
                                }
                            ]
                        }
                    ]
                };

                $scope.controller.map = new window.google.maps.Map($element[0], mapOptions);
                $timeout(function () {
                    window.google.maps.event.trigger($scope.controller.map, 'resize');
                });
                window.google.maps.event.addListener($scope.controller.map, "click", function (event) {
                    if (currentInfoWindow) {
                        currentInfoWindow.close();
                    }
                });

            };

            init();
        }
    };
}]);
