import app from "js/legacy-app";

app.service('PaymentSources', ['$resource', function ($resource) {
    return $resource('/api/payment-sources/:id/:action', {}, {
        addCard: {
            method: "POST",
            params: {id: "cards"},
            headers : {
                'g-recaptcha-response': function (config) {
                    let recapcha = config.data['g-recaptcha-response'];
                    delete config.data['g-recaptcha-response'];
                    return recapcha;
                },
                'g-recaptcha-action': function (config) {
                    let recaptchaAction = config.data['g-recaptcha-action'];
                    delete config.data['g-recaptcha-action'];
                    return recaptchaAction;
                }
            },
            isArray: false
        },
        list: {
            method: "GET",
            params: {id: "", action: ""},
            isArray: true
        },
        makeSourceDefault: {
            method: "PUT",
            params: {action: "make-default"},
            isArray: false
        },
        delete: {
            method: "DELETE",
            params: {action: ""},
            isArray: false
        },
        markAsVelotooler: {
            method: "PUT",
            params: {action: "make-velotooler"},
            isArray: false
        }
    });
}]);
