import angular from "angular";
import imageUploaderActions from "./image-uploader-actions";

const component = {
    template: require("./image-uploader.html"),
    bindings: {
        documents: "<vuiIuDocuments",
        options: "<vuiIuOptions",
        validationOptions: "<vuiIuValidationOptions",
        onUpload: "&vuiIuOnUpload",
        beforeUpload: "&?vuiIuBeforeUpload",
        hideImageContainer: "@?vuiIuHideImageContainer"
    },
    transclude: {
        actions: "vuiImageUploaderActions"
    },

    controller: function (ModalService, Upload, FileService, Notification) {
        "ngInject";

        this.onSelectFiles = function (files, invalidFiles) {
            clearImageContainer();

            files.filter((file) => {
                return FileService.isValidFile(file);
            }).forEach((file) => {
                this.upload(file);
            });

            files.filter((file) => {
                return !FileService.isValidFile(file);
            }).forEach((file) => {
                Notification.warning("File " + file + " has invalid format");
            });

            invalidFiles.forEach((file) => {
                let errorMsg = "File " + file.name + " has invalid " + file.$error + ". Valid " +
                    file.$error + " is " + file.$errorParam + (needPxPostfix(file.$error) ? 'px' : '');
                Notification.warning(errorMsg);
            });

            function needPxPostfix(error) {
                return error === 'maxWidth' || error === 'minWidth' || error === 'maxHeight' || error === 'minHeight';
            }
        };

        this.upload = function (file) {
            const ctrl = this;

            let imageUpload = {
                id: "",
                origin: file,
                thumbnail: "",
                originUrl: "",
                thumbnailUrl: "",
                progress: 0,
                failed: false,
                done: false
            };

            ctrl.imageContainer.images.push(imageUpload);
            ctrl.imageContainer.activeUploadsCount++;

            ctrl.finishedUpload = ctrl.imageContainer.activeUploadsCount === 0;

            if (angular.isFunction(ctrl.beforeUpload)) {
                ctrl.beforeUpload({finishedUpload: ctrl.finishedUpload});
            }

            ctrl.documents.uploadTemp(file, () => {
            }, function (response) {
                imageUpload.id = response.imageId;
                imageUpload.originUrl = response.origin;
                imageUpload.thumbnailUrl = response.thumbnail;
                imageUpload.done = true;
                ctrl.imageContainer.activeUploadsCount--;
                ctrl.onUpload({
                    images: ctrl.loadedImages(ctrl.imageContainer.images),
                    finishedUpload: ctrl.finishedUpload = ctrl.imageContainer.activeUploadsCount === 0
                });
            }, function (error) {
                imageUpload.failed = true;
                imageUpload.done = true;
                ctrl.imageContainer.activeUploadsCount--;
                ctrl.onUpload({
                    images: ctrl.loadedImages(ctrl.imageContainer.images),
                    finishedUpload: ctrl.finishedUpload = ctrl.imageContainer.activeUploadsCount === 0
                });
                Notification.error("Failed to upload image");
            }, function (progress) {
                imageUpload.progress = parseInt(100.0 * progress.loaded / progress.total);
            });
        };

        this.removeImage = function (index) {
            const ctrl = this;

            if (this.imageContainer.images[index].id) {
                this.documents.removeTemp(this.imageContainer.images[index].id);
            }

            this.imageContainer.images.splice(index, 1);
            this.onUpload({
                images: this.loadedImages(this.imageContainer.images),
                finishedUpload: ctrl.finishedUpload = this.imageContainer.activeUploadsCount === 0
            });
        };

        this.retryUpload = function (index) {
            const ctrl = this;

            let imageUpload = ctrl.imageContainer.images[index];
            imageUpload.failed = false;
            imageUpload.done = false;
            ctrl.imageContainer.activeUploadsCount++;

            ctrl.beforeUpload({finishedUpload: ctrl.finishedUpload = ctrl.imageContainer.activeUploadsCount === 0});

            ctrl.documents.uploadTemp(imageUpload.origin, () => {
            }, function (response) {
                imageUpload.id = response.imageId;
                imageUpload.originUrl = response.origin;
                imageUpload.thumbnailUrl = response.thumbnail;
                imageUpload.done = true;
                ctrl.imageContainer.activeUploadsCount--;
                ctrl.onUpload({images: ctrl.loadedImages(ctrl.imageContainer.images)});
            }, function (error) {
                imageUpload.failed = true;
                imageUpload.done = true;
                ctrl.imageContainer.activeUploadsCount--;
                Notification.error("Failed to upload image");
            }, function (progress) {
                imageUpload.progress = parseInt(100.0 * progress.loaded / progress.total);
            });
        };

        this.loadedImages = (images) => {
            return images.filter(function (image) {
                return !image.failed;
            });
        };

        this.$onInit = () => {
            this.options = this.options || {};
            this.validationOptions = this.validationOptions || {};
            this.options.imageSize = this.options.imageSize || {width: 140, height: 140};

            this.options.imageStyle = "{width: '" + this.options.imageSize.width + "px', height: '" + this.options.imageSize.height + "px'}";
            this.options.maxFiels = this.options.maxFiels || 10;

            if (angular.isUndefined(this.options.multiple)) {
                this.options.multiple = true;
            }

            this.finishedUpload = true;

            this.imageContainer = {
                images: [],
                activeUploadsCount: 0
            };
        };

        let clearImageContainer = () => {
            this.imageContainer = {
                images: [],
                activeUploadsCount: 0
            };
        }
    }
};

export default angular.module("vui-image-uploader.module", [imageUploaderActions.name])
    .component("vuiImageUploader", component);