import app from "js/legacy-app";
import angular from "angular";

app.service('$pageScroll', ['$mdMedia', '$document', '$timeout', '$window', function ($mdMedia, $document, $timeout, $window) {

    let body = angular.element($document[0].body),
        html = angular.element(document.getElementsByTagName('html')[0]);

    function hideOnMobile() {
        if ($mdMedia('(max-width: 470px)')) {
            hide();
        }
    }

    function hide() {
        body.addClass("no-scroll");
        html.addClass("no-scroll");
    }

    function show() {
        body.removeClass("no-scroll");
        html.removeClass("no-scroll");
    }

    function scrollTo(to, duration, container) {
        if (!container) {
            container = $document[0].getElementsByTagName('html')[0];
        }

        let start = container.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;

        let animateScroll = function () {
            currentTime += increment;
            container.scrollTop = Math.easeInOutQuad(currentTime, start, change, duration);
            if (currentTime < duration) {
                $timeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    return {
        hide: hide,
        show: show,
        revert: show,
        hideOnMobile: hideOnMobile,
        scrollTo: scrollTo
    };
}
]);

