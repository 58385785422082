import app from "js/legacy-app";
import angular from "angular";

app.controller('AddBikeOwnerCtrl', ['$scope', '$routeParams', '$location',
    'Notification', 'ErrorHandler', 'AuthService', '$window', '$bikeService',
    function ($scope, $routeParams, $location, Notification, ErrorHandler, AuthService, $window, $bikeService) {

        $scope.bikeId = $routeParams.bikeId;
        $scope.organisationId = $routeParams.organisationId;
        $scope.stationId = $routeParams.stationId;

        $scope.bikeOwner = {
            user: {}
        };
        $scope.disabledInvite = false;


        $scope.invite = function (form) {
            if (form.$valid && !$scope.bikeOwner.user.phone.$error) {
                var user = angular.extend({}, $scope.bikeOwner.user);
                user.phone = user.phone && user.phone.number ? user.phone.number : "";
                $bikeService.getResourceClass().setBikeOwner({id: $scope.bikeId}, user, function (response) {
                    Notification.success('Bicycle was successfully transferred');
                    $window.history.back();
                }, function (error) {
                    ErrorHandler.onError(error, "Failed to transfer bicycle");
                });
            }
        };

        $scope.cancel = function () {
            $window.history.back();
        };
    }
]);
