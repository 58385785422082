import app from "js/legacy-app";

app.directive('bikeHistory', [function () {
    return {
        templateUrl: "template/dashboard/bikes/show-bike/tabs/history.html",
        scope: {
            bike: '=bikeHistory',
            type: '&'
        },
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: ['$scope', 'BikeHistory', 'Notification', 'ErrorHandler',
            function ($scope, BikeHistory, Notification, ErrorHandler) {
                var size = 10,
                    page = 0;

                $scope.bikeHistory = {
                    history: {},
                    loading: true
                };

                $scope.bike.refreshHistory = $scope.refresh = function () {
                    $scope.bikeHistory.loading = true;
                    var request = {
                        size: size,
                        page: page
                    };

                    if ($scope.type() === 'public') {
                        request.publicBikeId = $scope.bike.id;
                    } else {
                        request.bikeId = $scope.bike.id;
                    }

                    BikeHistory.get(request, function (data) {
                        $scope.bikeHistory.history = data;
                        $scope.bikeHistory.loading = false;
                    }, function (error) {
                        ErrorHandler.onError(error);
                        $scope.bikeHistory.loading = false;
                    });
                };

                $scope.prev = function () {
                    if ($scope.bikeHistory.history.prev || (page !== 0 && !$scope.bikeHistory.history.data)) {
                        page--;
                        $scope.refresh();
                    }
                };

                $scope.next = function () {
                    if ($scope.bikeHistory.history.next) {
                        page++;
                        $scope.refresh();
                    }
                };

                $scope.refresh();
            }]
    };
}]);
