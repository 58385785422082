import app from "js/legacy-app";

app.directive('profilePhotos', [function () {
    return {
        templateUrl: "template/profile/widget/profile-photos.html",
        replace: true,
        scope: {
            images: "="
        },
        controller: ['$scope', function ($scope) {


            $scope.profilePhotos = {
                showMore: false,
                animate: "0.3",
                minHeight: 123
            };

            $scope.showMorePhotos = function () {
                $scope.profilePhotos.showMore = !$scope.profilePhotos.showMore;
            };
        }]
    };
}]);