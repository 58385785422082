import angular from "angular";
import bookingAppointmentDateActions from "./booking-appointment-date-actions";

const component = {
    template: require('./booking-appointment-date-card.html'),
    bindings: {
        onSubmit: "&?vuiBadOnSubmit",
        appointmentDate: "<?vuiBadModel",
        loadAvailabilityDates: '&vuiBadLoadAvailabilityDates'
    },
    transclude: {
        actions: "vuiBookingAppointmentDateActions"
    },

    controller: function () {
        const ctrl = this;

        ctrl.submit = function (date) {
            if (angular.isFunction(ctrl.onSubmit)) {
                this.appointmentDate = date;
                this.appointmentDate.time = this.appointmentDate.time.substring(0, 5);
                ctrl.onSubmit({date: date});
            }
        };
    }
};

export default angular.module("vui-booking-appointment-date.module", [bookingAppointmentDateActions.name])
    .component('vuiBookingAppointmentDate', component);
