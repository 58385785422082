export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/admin/app-marketplace', 'admin-app-marketplace')
        .when('/admin/app-marketplace/create', 'admin-app-marketplace-create')
        .when('/admin/app-marketplace/:applicationId', 'admin-app-marketplace-view')
        .when('/admin/app-marketplace/:applicationId/installations', 'admin-app-marketplace-installations')

        .segment('admin-app-marketplace', {
            access: {requireLogin: true},
            template: '<admin-app-marketplace></admin-app-marketplace>',
            loadModule: () => {
                return import(/* webpackChunkName: "admin-app-marketplace.module" */ "./app-marketplace-list/app-marketplace-list.module");
            }
        })
        .segment('admin-app-marketplace-create', {
            access: {requireLogin: true},
            template: '<admin-application-create></admin-application-create>',
            loadModule: () => {
                return import(/* webpackChunkName: "admin-app-marketplace-create.module" */ "./app-marketplace-create/app-marketplace-create.module");
            }
        })
        .segment('admin-app-marketplace-view', {
            access: {requireLogin: true},
            template: '<admin-application-view></admin-application-view>',
            loadModule: () => {
                return import(/* webpackChunkName: "admin-app-marketplace-view.module" */ "./app-marketplace-view/app-marketplace-view.module");
            }
        })
        .segment('admin-app-marketplace-installations', {
            access: {requireLogin: true},
            template: '<admin-application-installations></admin-application-installations>',
            loadModule: () => {
                return import(/* webpackChunkName: "admin-application-installations.module" */ "./application-installations/application-installations.module");
            }
        })
    ;

}
