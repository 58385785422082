import app from "js/legacy-app";

app.directive('userCitiesStatistic', [function () {
    return {
        template: '<div class="md-padding chart-container">' +
        '   <div>Users by cities</div>' +
        '   <div>' +
        '       <canvas class="chart chart-pie" chart-data="data" chart-labels="labels" chart-options="options"></canvas>' +
        '   </div>' +
        '</div>',
        replace: true,
        scope: true,
        controller: ['$scope', 'User', function ($scope, User) {

            $scope.labels = [];
            $scope.data = [];

            User.countByCity(function(data) {
                for (var i = 0; i < data.length; i++) {
                    $scope.labels.push(data[i].city ? data[i].city : 'none');
                    $scope.data.push(data[i].count);
                }
            });

        }]
    };
}]);
