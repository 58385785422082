import app from "js/legacy-app";
import defaultImage from "img/no-organization-avatar.png";

app.directive('olItemBody', [function () {
    return {
        templateUrl: "components/organisation/list/base/ol-item-body.html",
        link: function ($scope) {
            $scope.olib = {
                defaultImage: defaultImage
            };
        }
    };
}]);
