import app from "js/legacy-app";
import angular from "angular";
import defaultDocumentImg from 'img/no-mail-document.png';

app.controller('ConfirmBillingAddressCtrl', ['$scope', 'FileService', 'User', '$location', 'AuthService',
    'Notification', 'ToggleLD', 'PhoneService', 'Documents',
    function ($scope, FileService, User, $location, AuthService, Notification, ToggleLD, PhoneService, Documents) {

        $scope.billingAddress = {
            showImg: true,
            type: 'DRIVERS_LICENSE',
            suchAs: false,
            disabled: false,
            showZipInfo: false,
            address: {}
        };

        $scope.refresh = function () {
            User.getVerification(function (data) {
                $scope.verification = data;

                $scope.billingAddress.showImg = false;
                if ($scope.verification && $scope.verification.billingAddressVerification) {
                    $scope.billingAddress.defaultImg = $scope.verification.billingAddressVerification.thumbnail;
                    if ($scope.verification.billingAddressVerification.type) {
                        $scope.billingAddress.type = $scope.verification.billingAddressVerification.type;
                    }
                    $scope.billingAddress.address = $scope.verification.billingAddressVerification.address;
                }
                if (!$scope.billingAddress.defaultImg) {
                    $scope.billingAddress.defaultImg = defaultDocumentImg;
                }
                $scope.billingAddress.showImg = true;

                if (!$scope.billingAddress.address) {
                    $scope.billingAddress.address = {};
                }

                if (!$scope.billingAddress.address.fullName) {
                    $scope.billingAddress.address.fullName = $scope.user.name + ' ' + $scope.user.surname;
                }
                var phoneNumber = $scope.user.phone;

                if (!phoneNumber) {
                    PhoneService.getCurrentCountry($scope);
                    /* Get Current Country Async*/
                } else {
                    PhoneService.setCountry(PhoneService.getCountryFromNumber(phoneNumber), $scope);
                }
                if (!$scope.billingAddress.address.phone) {
                    $scope.billingAddress.address.phone = $scope.user.phone;
                }
            });
        };

        $scope.images = [];

        $scope.chooseFile = function (files, invalidFiles) {
            var file;
            if (files[0]) {
                file = files[0];
            } else if (invalidFiles[0]) {
                file = invalidFiles[0];
            }

            if (!FileService.isValidFile(file)) {
                return;
            }

            $scope.billingAddress.document = file;
        };

        AuthService.currentUser(function () {
            $scope.user = AuthService.user();
            $scope.refresh();
        }, true);

        $scope.changeSuchAsAddress = function () {
            if ($scope.billingAddress.suchAs) {
                $scope.currentUser = $scope.user;
            } else {
                $scope.billingAddress.address = {};
            }
        };

        $scope.continue = function (form) {
            if (!form.$valid || !$scope.isValidNumber) {
                return;
            }

            save();
        };

        var documentIsAlreadyExist = function () {
            return $scope.verification &&
                $scope.verification.billingAddressVerification &&
                $scope.verification.billingAddressVerification.original;
        };

        var save = function () {
            if (!documentIsAlreadyExist() && !$scope.billingAddress.document) {
                Notification.warning("Please upload verification document");
                return;
            }

            ToggleLD.show();
            $scope.verification.billingAddressVerification = {
                address: angular.toJson($scope.billingAddress.address),
                type: $scope.billingAddress.type,
                document: $scope.billingAddress.document
            };

            Documents.billingAddressVerification().upload($scope.verification.billingAddressVerification, function () {
                ToggleLD.hide();
                Notification.success("Billing address has been successfully updated");
                $location.path("/dashboard");
            }, function (error) {
                onUploadError(error);
            });
        };

        function onUploadError(errorMsg) {
            Notification.error("Failed to upload document");
            $location.path('/dashboard');
            $scope.billingAddress.disabled = false;
        }
    }
]);