import app from "js/legacy-app";

app.controller('ViewToolCtrl', ['$scope', '$location', '$routeParams', 'ErrorHandler',
    'CoreServiceManagement', '$q', 'ToggleLD', 'Tools', 'ModalService', 'Notification',
    function ($scope, $location, $routeParams, ErrorHandler, CoreServiceManagement, $q, ToggleLD,
              Tools, ModalService, Notification) {

        $scope.tool = {
            id: $routeParams.id
        };

        $scope.toolController = {

            refresh: function () {
                ToggleLD.show();
                if ($scope.tool.id) {
                    Tools.get({id: $scope.tool.id}, function (t) {
                        ToggleLD.hide();
                        $scope.tool = t;
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error, "Failed to load tool details");
                    });
                }
            },

            update: function (form) {
                if (form.$valid) {
                    ToggleLD.show();
                    if ($scope.tool.id) {
                        Tools.update({id: $routeParams.id}, this.mapToDto($scope.tool), function (t) {
                            ToggleLD.hide();
                            $scope.tool = t;
                            Notification.success("Tool was successfully updated");
                        }, function (error) {
                            ToggleLD.hide();
                            ErrorHandler.onError(error, "Failed to update tool details");
                        });
                    }
                }
            },

            remove: function (toolId) {
                if ($scope.tool.id) {
                    var msg = 'Are you sure you want to remove \'' + $scope.tool.name + '\' tool?';
                    ModalService.yesNoMessage(null, '', msg, function (response) {
                        if (response === true) {
                            ToggleLD.show();
                            Tools.delete({id: toolId}, function () {
                                ToggleLD.hide();
                                $location.path("/admin/tools");
                                Notification.success("Tool was successfully removed");
                            }, function (error) {
                                ToggleLD.hide();
                                ErrorHandler.onError(error, "Failed to remove tool");
                            });
                        }
                    });
                }
            },

            close: function () {
                $location.path("/admin/tools");
            },

            searchTools: function (query) {
                var deferred = $q.defer();
                Tools.searchByNameLike({name: query}, function (response) {
                    deferred.resolve(response.items);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },

            toolTypes: [
                'INTERMEDIATE',
                'EXPERT'
            ],

            mapToDto: function (tool) {
                var dto = {};
                dto.name = tool.name;
                dto.type = tool.type;
                return dto;
            }
        };

        $scope.toolController.refresh();
    }
]);