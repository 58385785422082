import app from "js/legacy-app";

app.service('PolygonOfZipCode', ['$resource', function ($resource) {
    return $resource('/api/polygon/zip-code/:param1/:param2', {}, {
        getByCompanyType: {
            method: "GET",
            params: {param1 : "by-company-type"},
            isArray: true
        },
        getByCurrentUser: {
            method: "GET",
            params: {param1 : "by-user/current"},
            isArray: true
        },
        getAll: {
            method: "GET",
            params: {},
            isArray: false
        },
        getAllUsa: {
            method: "GET",
            params: {param1: "/usa"},
            isArray: false
        }
    });
}]);
