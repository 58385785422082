import app from "js/legacy-app";

app.directive('shortAddressView', [function () {

    return {
        replace: true,
        template: '<div class="short-address-view">' +
        '               <i class="short-address-view__location-marker fa fa-map-marker" aria-hidden="true"></i>' +
        '               <span class="short-address-view__location-text">' +
        '                           <span ng-if="model.city">{{ model.city }}, </span>' +
        '                           <span ng-if="model.country">{{ model.country }}</span>' +
        '                           <span ng-if="!model.city && !model.country">Location not specified</span>' +
        '               </span>' +
        '           </div>',
        scope: {
            model: '=savModel'
        }
    };
}]);