import app from "js/legacy-app";

app.controller('FullProfileUserCtrl', ['User', '$scope', 'id', '$mdDialog', function (User, $scope, id, $mdDialog) {

    User.get({id: id}, function (data) {
        $scope.user = data;
    });

    $scope.activate = function () {
        User.activateByAdmin({id1: id}, {}, function (data) {
            $scope.user = data;
        });
    };

    $scope.close = function () {
        $mdDialog.hide();
    };

}]);
