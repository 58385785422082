import app from "js/legacy-app";

app.directive('plSliderFilter', ['$location', function ($location) {
    return {
        template: '<div class="pageable-list__filter-field">' +
        '               <div class="pageable-list__range-filter-field-label-container">' +
        '                   <label class="pageable-list__filter-field-label">{{ label }}</label>' +
        '                   <label data-ng-if="sf.isSelected()" class="pageable-list__range-filter-field-value">{{ sf.displayRange() }}</label>' +
        '               </div>' +
        '               <rzslider rz-slider-model="sf.min" rz-slider-high="sf.max" rz-slider-options="options">' +
        '               </rzslider>' +
        '           </div>',
        require: ['^^plFilter', '^^pageableList'],
        scope: {
            label: '@sfLabel',
            name: '@sfName',
            options: "=?sfOptions",
            dependsOn: "@sfDependsOn"
        },
        link: function ($scope, element, $attrs, $ctrls) {
            $scope.sf = {};
            $scope.sf.filter = $ctrls[0].filter;

            $scope.sf.filter.register($scope.name, $scope.sf);

            $scope.options = $scope.options || {};
            $scope.options.ceil = $scope.options.ceil || 100;
            $scope.options.floor = $scope.options.floor || 0;
            $scope.options.step = $scope.options.step || 1;
            $scope.options.minRange = $scope.options.minRange || 1;
            $scope.options.translate = $scope.options.translate || function (val) {
                return val;
            };
            if ($scope.options.maxUnlimited) {
                var translateFn = $scope.options.translate;
                $scope.options.translate = function (value, sliderId, label) {
                    var newValue = label === 'ceil' ? value + '+' : value;
                    return translateFn(newValue, sliderId, label);
                };
            }
            $scope.options.mergeRangeLabelsIfSame = true;
            $scope.options.hidePointerLabels = true;
            $scope.options.onEnd = function () {
                $scope.sf.filter.apply();
            };

            $scope.sf.displayRange = function () {
                var tr = $scope.options.translate;
                if ($scope.sf.isMinSelected() && $scope.sf.isMaxSelected()) {
                    return tr($scope.sf.min) + ' - ' + tr($scope.sf.max);
                } else if ($scope.sf.isMinSelected()) {
                    return tr($scope.sf.min) + "+";
                } else if ($scope.sf.isMaxSelected()) {
                    return "Less than " + tr($scope.sf.max);
                }
            };

            $scope.sf.isMinSelected = function () {
                return $scope.sf.min !== $scope.options.floor;
            };

            $scope.sf.isMaxSelected = function () {
                return $scope.sf.max !== $scope.options.ceil;
            };

            $scope.sf.isSelected = function () {
                return $scope.sf.isMinSelected() || $scope.sf.isMaxSelected();
            };

            $scope.sf.getValue = function () {
                return {
                    min: $scope.sf.isMinSelected() ? $scope.sf.min : null,
                    max: $scope.sf.isMaxSelected() ? $scope.sf.max : null
                };
            };

            $scope.sf.serialize = function () {
                var serialized = {};
                serialized[$scope.name + "Min"] = $scope.sf.getValue().min;
                serialized[$scope.name + "Max"] = $scope.sf.getValue().max;
                return serialized;
            };

            $scope.sf.deserialize = function (queryString) {
                $scope.sf.min = queryString[$scope.name + "Min"] ?
                    queryString[$scope.name + "Min"] : $scope.options.floor;
                $scope.sf.max = queryString[$scope.name + "Max"] ?
                    queryString[$scope.name + "Max"] : $scope.options.ceil;
            };

            $scope.sf.clear = clear;
            $scope.sf.init = init;

            function clear() {
                $scope.sf.min = $scope.options.floor;
                $scope.sf.max = $scope.options.ceil;
            }

            function init() {
                $scope.sf.deserialize($location.search());

                $scope.sf.filter.initialized($scope.name);

                if ($scope.dependsOn) {
                    $scope.$watch('sf.filter.filters.' + $scope.dependsOn + '.getValue()', function (newVal, oldVal) {
                        if (newVal !== oldVal) {
                            clear();
                        }
                    });
                }

                $ctrls[1].pl.onFinishRender(function () {
                    $scope.$broadcast('rzSliderForceRender');
                });
            }

            init();
        }
    };
}]);