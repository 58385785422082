import app from "js/legacy-app";
import angular from "angular";

app.controller('TransferBikeController', ['$scope', '$location', 'ToggleLD', '$routeParams',
    'Notification', 'NotificationWSService', '$rootScope', 'AuthService', '$bikeService',
    function ($scope, $location, ToggleLD, $routeParams, Notification,
              NotificationWSService, $rootScope, AuthService, $bikeService) {

        var tbc = {
            refresh: function () {
                $bikeService.getResourceClass().getTransferDetails({id: $scope.tbc.bikeId}, function (details) {
                    $scope.tbc.details = details;
                }, function (error) {
                    Notification.error("This link is not valid any more");
                    $location.url("/dashboard");
                });
            },

            accept: function () {
                ToggleLD.show();
                $bikeService.getResourceClass().acceptBikeTransfer({id: $scope.tbc.bikeId}, {}, function (response) {
                    ToggleLD.hide();
                    Notification.success("Bicycle transfer was successfully confirmed");
                    $location.url("/bike/" + $scope.tbc.bikeId + "?info");
                }, function (error) {
                    Notification.error("This link is not valid any more");
                    ToggleLD.hide();
                });
            },

            readNewNotification: function (message) {
                if (!message.data || !message.data.metadata || !message.data.metadata.bikeId || 'CONFIRM_BIKE_TRANSFER' !== message.data.type) {
                    return;
                }

                if (tbc.bikeId === message.data.metadata.bikeId && !message.data.read) {
                    tbc.refresh();
                    NotificationWSService.readNotification(message.data.id, AuthService.getToken());
                }
            },

            readBikeNotifications: function () {
                NotificationWSService.send({
                    operation: 2,
                    bikeId: $scope.tbc.bikeId,
                    type: 'CONFIRM_BIKE_TRANSFER',
                    token: AuthService.getToken().access_token
                });
            }
        };

        $scope.tbc = {
            bikeId: $routeParams.bikeId
        };

        $scope.tbc.submitForm = function (form) {
            if (form.$valid) {
                tbc.accept();
            }
        };

        $scope.tbc.decline = function () {
            ToggleLD.show();
            $bikeService.getResourceClass().declineBikeTransfer({id: $scope.tbc.bikeId}, {}, function (response) {
                ToggleLD.hide();
                $location.url("/dashboard");
                Notification.success("Bicycle transfer was declined");
            }, function (error) {
                Notification.error("This link is not valid any more");
                ToggleLD.hide();
            });
        };

        (function init() {
            tbc.refresh();
            angular.element(document).ready(function () {
                NotificationWSService.registerHandler("NEW", "transfer-bike", tbc.readNewNotification);
                tbc.readBikeNotifications();
            });
            $scope.$on("$destroy", function () {
                NotificationWSService.unregisterHandler("NEW", "transfer-bike");
            });
        })();
    }

]);
