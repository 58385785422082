import angular from "angular";

const selectFilesAction = {
    template: require("./image-uploader-select-files-action.html"),
    require: {
        parent: '^vuiImageUploader'
    },
    bindings: {
        title: '@vuiSfaTitle'
    },
    controller: function () {

        this.$onInit = () => {
            this.title = this.title || 'Choose images';
        };

        this.chooseFile = function (files, invalidFiles) {
            this.parent.onSelectFiles(files, invalidFiles);
        };
    }
};

export default angular.module("vui-image-uploader-actions", [])
    .component("vuiIuSelectFilesAction", selectFilesAction);