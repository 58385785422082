import angular from "angular";
import "./settings-section.scss";

const settingsSection = {
    template: require('./settings-section.html'),
    transclude: {
        title: "vuiSettingsSectionTitle",
        description: "vuiSettingsSectionDescription",
        content: "vuiSettingsSectionContent",

    }
};

const settingsSectionSeparator = {
    template: require('./settings-section-separator.html')
};

export default angular.module("vui-settings-section.module", [])
    .component('vuiSettingsSection', settingsSection)
    .component('vuiSettingsSectionSeparator', settingsSectionSeparator);
