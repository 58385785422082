import app from "js/legacy-app";
import angular from "angular";

app.directive('restrictNumeric', [function () {
    'use strict';

    var restrictNumericOnKeyUp = function (e) {
        // mobile chrome doesn't recognise keypress event, and for other actions sends 229 keyCode
        // keyup - because with keydown input doesn't contains the key yet
        var elm = angular.element(e.currentTarget);
        var text = elm.val();
        if (!/^\d*$/g.test(text)) {
            elm.val (text.replace (/\D/g, ''));
        }
    };

    return {
        restrict: 'A',
        link: function postLink(scope, element) {
            element.on('keyup', restrictNumericOnKeyUp);
        }
    };
}]);
