import angular from "angular";

import MechanicListModuleRouting from "./mechanic-list.module.routing";
import MechanicListCard from "../../components/mechanic-list-card/mechanic-list-card.component";
import MechanicListComponent from "./mechanic-list.component";
import MechanicsApi from "../../resources/mechanics";
import "./mechanic-list.scss";

export default angular
    .module("vt-mechanics-list", [
        MechanicListCard.name,
        MechanicsApi.name
    ])
    .config(MechanicListModuleRouting)
    .component("allMechanicsMap", MechanicListComponent);

