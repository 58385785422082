import app from "js/legacy-app";

app.directive('vtSidenavHeader', ['$vtSidenav', function ($vtSidenav) {
    return {
        templateUrl: "template/component/widget/sidenav/vt-sidenav-header.html",
        replace: true,
        require: '^^vtSidenav',
        link: function ($scope, element, attr, sidenav) {
            $scope.vsh = {
                cancel: function () {
                    $vtSidenav(sidenav.sidenavId).close();
                }
            };

            (function init() {
                $scope.vsh.title = attr.vshTitle;
            })();
        }
    };
}]);