import angular from "angular";

const bookingSaveAction = {
    template: require('./booking-save-action.html'),
    bindings: {
        booking: '<vuiSaBooking',
        onSave: '&?vuiSaOnSave',
        valid: '&?vuiSaValidate'
    },
    controller: function ($requestService, ToggleLD, ErrorHandler) {
        'ngInject';

        this.$onInit = () => {
            this.resource = $requestService.getRequestResource();
        };

        this.submit = () => {
            if (angular.isFunction(this.valid) && !this.valid()) {
                return;
            }

            ToggleLD.show();
            this.resource.save(this.booking, (createdBooking) => {
                    ToggleLD.hide();

                    if (angular.isFunction(this.onSave)) {
                        this.onSave({booking: createdBooking});
                    }
                },
                (error) => {
                    ToggleLD.hide();
                    ErrorHandler.onError(error);
                });
        };
    }
};

const bookingCancelAction = {
    template: require('./booking-cancel-action.html'),
    bindings: {
        onCancel: '&?vuiCaOnCancel'
    },
    controller: function ($window) {
        'ngInject';

        this.cancel = () => {
            if (angular.isFunction(this.onCancel)) {
                this.onCancel({});
            } else {
                $window.history.back();
            }
        };
    }
};

const bookingActions = {
    template: require('./booking-actions.html'),
    transclude: true
};

export default angular.module("vui-service-booking-actions.module", [])
    .component("vuiServiceBookingActions", bookingActions)
    .component("vuiSbSaveAction", bookingSaveAction)
    .component("vuiSbCancelAction", bookingCancelAction);