export default ($resource) => {
    'ngInject';

    return $resource('/api/location/:id', {}, {
        getTimezone: {
            method: "GET",
            params: {id: "get-timezone"},
            isArray: false
        },
        autocomplete: {
            method: 'GET',
            params: {id: 'autocomplete'},
            isArray: true
        },
        placeDetails: {
            method: 'GET',
            params: {id: 'place/details'},
            isArray: false
        },
        geocode: {
            method: 'GET',
            params: {id: 'geocode'},
            isArray: false
        },
        getLocationByLatLng: {
            method: 'GET',
            params: {id: 'by-lat-lng'},
            isArray: false
        }
    });
};
