import app from "js/legacy-app";

app.service('User', ['$resource', function ($resource) {
    return $resource('/api/user/:id/:id1/:id2', {}, {
        save: {
            method: 'POST',
            params: {id: ""},
            headers: {
                'g-recaptcha-response': function (config) {
                    let recapcha = config.data.gRecaptchaResponse;
                    delete config.data.gRecaptchaResponse;
                    return recapcha;
                },
                'g-recaptcha-action': function (config) {
                    let recaptchaAction = config.data.gRecaptchaAction;
                    delete config.data.gRecaptchaAction;
                    return recaptchaAction;
                }
            },
            isArray: false
        },
        current: {
            method: "GET",
            params: {id: "current/profile"},
            isArray: false
        },
        updateProfile: {
            method: "POST",
            params: {id: "current/profile"},
            isArray: false
        },
        clearAvatar: {
            method: "DELETE",
            params: {id: "current/avatar"},
            isArray: false
        },
        changePassword: {
            method: "POST",
            params: {id: "current/change-password"},
            isArray: false
        },
        tokens: {
            method: "GET",
            params: {id: "current/tokens"},
            isArray: true
        },
        closeSessions: {
            method: "POST",
            params: {id: "current/close-sessions"},
            isArray: false
        },
        uploadAvatar: {
            method: "POST",
            params: {id: "current/avatar"},
            isArray: false
        },
        forgotPassword: {
            method: "POST",
            params: {id: "forgot-password"},
            isArray: false
        },
        restorePassword: {
            method: "PUT",
            params: {id: "restore-password"},
            isArray: false
        },
        deleteUsers: {
            method: "PUT",
            params: {id: "delete/all"},
            isArray: true
        },
        updateUser: {
            method: "PATCH",
            params: {id: ""},
            isArray: false
        },
        getAllRoles: {
            method: "GET",
            params: {id: "roles"},
            isArray: true
        },
        blockUsers: {
            method: "PUT",
            params: {id: "block/all"},
            isArray: false
        },
        unblockUsers: {
            method: "PUT",
            params: {id: "unblock"},
            isArray: false
        },
        saveCard: {
            method: "POST",
            params: {id: "current/credit-card"},
            isArray: false
        },
        getVerification: {
            method: "GET",
            params: {id: "verification/current-state"},
            isArray: false
        },
        getVerificationState: {
            method: "GET",
            params: {id1: "verification/state"},
            isArray: false
        },
        bikeShopExperienceVerification: {
            method: "POST",
            params: {id: "verification/bike-shop-experience"},
            isArray: false
        },
        profileVerification: {
            method: "POST",
            params: {id: "verification/profile"},
            isArray: false
        },
        chooseToolsVerification: {
            method: "POST",
            params: {id: "verification/choose-tools"},
            isArray: false
        },
        biographyVerification: {
            method: "POST",
            params: {id: "verification/biography"},
            isArray: false
        },
        searchMechanicsForMap: {
            method: "POST",
            isArray: false,
            params: {id: "search-mechanics-for-map"}
        },
        confirmRegistration: {
            method: "GET",
            isArray: false,
            params: {id: "confirm-registration"}
        },
        cancelRegistration: {
            method: "PUT",
            isArray: false,
            params: {id: "registration/cancel"}
        },
        updateExperience: {
            method: "POST",
            isArray: false,
            params: {id: "verification/update-experience"}
        },
        updateBillingAddress: {
            method: "POST",
            isArray: false,
            params: {id: "verification/update-billing-address"}
        },
        updateIdentity: {
            method: "POST",
            isArray: false,
            params: {id: "verification/update-identity"}
        },
        getCustomer: {
            method: "GET",
            isArray: false,
            params: {id: "current/customer"}
        },
        getMechanicInfo: {
            method: "GET",
            isArray: false,
            params: {id: "mechanic-info"}
        },
        getUserInfo: {
            method: "GET",
            isArray: false,
            params: {id: "info"}
        },
        getOrganisationOwnerInfo: {
            method: "GET",
            isArray: false,
            params: {id: "info/by-organisation"}
        },
        tools: {
            method: "GET",
            params: {id: "current/tools"},
            isArray: true
        },
        registrationAccount: {
            method: "POST",
            params: {id: "current/stripe-account"},
            isArray: false
        },
        getStripeAccount: {
            method: "GET",
            params: {id: "current/stripe-account"},
            isArray: false
        },
        getStripeAccountById: {
            method: "GET",
            params: {id1: "stripe-account"},
            isArray: false
        },
        checkEmail: {
            method: "POST",
            params: {id: "check-email"},
            isArray: false
        },
        getMechanicAgreement: {
            method: "GET",
            params: {id: "verification/mechanic-agreement"},
            isArray: false
        },
        acceptMechanicAgreement: {
            method: "GET",
            params: {id: "verification/accept-mechanic-agreement"},
            isArray: false
        },
        chooseGroupInsurance: {
            method: "GET",
            params: {id: "/verification/choose-group-insurance"},
            isArray: false
        },
        countUserByRole: {
            method: "GET",
            params: {id: "count/by-role"},
            isArray: true
        },
        countByCity: {
            method: "GET",
            params: {id: "count/by-city"},
            isArray: true
        },
        countRegistration: {
            method: "GET",
            params: {id: "count/by-registration-date"},
            isArray: true
        },
        setCardDefault: {
            method: "PUT",
            params: {id: ""},
            isArray: false
        },
        reactivationEmail: {
            method: "POST",
            params: {id: "current/activation"},
            isArray: false
        },
        createBusinessAccount: {
            method: "POST",
            params: {id: "business-account"},
            isArray: false
        },
        activateAccount: {
            method: "PUT",
            params: {id: "activate-account"},
            isArray: false
        },
        activateByAdmin: {
            method: "PUT",
            params: {id: "activate-by-admin"},
            isArray: false
        },
        completeRegistration: {
            method: "POST",
            params: {id: "current/complete-registration"},
            isArray: false
        },
        findByEmailLike: {
            method: "GET",
            params: {id: "search/by-email-like"},
            isArray: false
        },
        findByNameLike: {
            method: "GET",
            params: {id: "search/by-name-like"},
            isArray: false
        },
        autocomplete: {
            method: "GET",
            params: {id: "autocomplete"},
            isArray: false
        },
        updateUserPay: {
            method: "PUT",
            params: {id: "update-user-pay"},
            isArray: false
        },
        clearUserAvatar: {
            method: "DELETE",
            params: {id: "avatar"},
            isArray: false
        },
        checkActivation: {
            method: "GET",
            params: {id: "current/check-activation"},
            isArray: false
        },
        loginAsUser: {
            method: "POST",
            params: {id: "login-as-user"},
            isArray: false
        },
        totalCount: {
            method: "GET",
            params: {id: "total-count"},
            isArray: false
        },
        getProfileLocations: {
            method: "GET",
            params: {id: "current/profile-locations"},
            isArray: false
        },

        unsetFavoriteAddress: {
            method: "PUT",
            params: {id: "current/favorite-address", id2: "unset"},
            isArray: false
        },

        setFavoriteAddress: {
            method: "POST",
            params: {id: "current/favorite-address/set"},
            isArray: false
        },

        updateProfileAddress: {
            method: "PUT",
            params: {id: "current/profile-address"},
            isArray: false
        },

        removeProfileAddress: {
            method: "DELETE",
            params: {id: "current/profile-address"},
            isArray: false
        },

        saveProfileAddress: {
            method: "POST",
            params: {id: "current/profile-address"},
            isArray: false
        },

        getPublicUserInfo: {
            method: "GET",
            isArray: false,
            params: {id: "public-info"}
        },
        search: {
            method: "POST",
            isArray: false,
            params: {id: ""}
        },
        updateVerificationLevel: {
            method: "PUT",
            params: {id1: "verification-level"},
            isArray: false
        },
        updateMechanicActivity: {
            method: "PUT",
            params: {id1: "activity"},
            isArray: false
        },
        updateVerificationStep: {
            method: "PUT",
            params: {id1: "verification-step"},
            isArray: false
        },
        unverifyMechanic: {
            method: "PUT",
            params: {id1: "unverify"},
            isArray: false
        },
        verifyMechanic: {
            method: "PUT",
            params: {id1: "verify"},
            isArray: false
        },
        verifyOnProbation: {
            method: "PUT",
            params: {id1: "verify-on-probation"},
            isArray: false
        },
        checkNickname: {
            method: 'POST',
            params: {id: 'check-nickname'},
            isArray: false
        },
        list: {
            method: 'GET',
            params: {id: 'list'},
            isArray: false
        }
    });
}]);
