import app from "js/legacy-app";

app.directive('profileBiography', [function () {
    return {
        templateUrl: "template/profile/tabs/biography/profile-biography.html",
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: ['$scope', 'User', 'Notification', '$routeParams', 'FrameMaterial', 'ModalService', 'ErrorHandler',
            function ($scope, User, Notification, $routeParams, FrameMaterial, ModalService, ErrorHandler) {

                $scope.yearsOfExperience = [
                    '0',
                    '<1',
                    '1-2',
                    '3-4',
                    '5+',
                    '10+',
                    '15+'
                ];

                User.getVerification(function (data) {
                    $scope.verification = data;

                    if ($scope.verification && $scope.verification.biographyVerification && $scope.verification.biographyVerification.biography) {
                        $scope.changeBiography.biography = $scope.verification.biographyVerification.biography;
                    }
                });

                $scope.changeBiography = {
                    maxYear: new Date().getFullYear(),
                    biography: {
                        standoutExperience: {
                            teams: [],
                            cyclingAchievements: []
                        },
                        bicycleMaintenanceEducation: [],
                        qualificationCore: {}
                    }
                };

                $scope.bikes = ['All-around', 'Mountain', 'Road'];

                $scope.addAchievement = function () {
                    $scope.changeBiography.biography.standoutExperience.cyclingAchievements.push({});
                };

                $scope.addTeam = function () {
                    $scope.changeBiography.biography.standoutExperience.teams.push({});
                };

                $scope.addEducation = function () {
                    $scope.changeBiography.biography.bicycleMaintenanceEducation.push({});
                };

                $scope.removeEducation = function (index) {
                    $scope.changeBiography.biography.bicycleMaintenanceEducation.splice(index, 1);
                };

                $scope.removeTeam = function (index) {
                    $scope.changeBiography.biography.standoutExperience.teams.splice(index, 1);
                };

                $scope.removeAchievement = function (index) {
                    $scope.changeBiography.biography.standoutExperience.cyclingAchievements.splice(index, 1);
                };

                FrameMaterial.query(function (data) {
                    $scope.materials = [];
                    for (var i = 0; i < data.length; i++) {
                        $scope.materials.push(data[i].name);
                    }
                });

                $scope.changeBiography.update = function (form) {
                    if (form.$valid) {
                        var message = 'Are you sure you want to change your biography?';
                        ModalService.yesNoMessage(null, '', message, function (response) {
                            if (response === true) {
                                if ($scope.changeBiography.biography.standoutExperience.frameBuilding &&
                                    $scope.changeBiography.biography.standoutExperience.frameBuilding.bicycleFrameBuildingYearsOld) {
                                    $scope.changeBiography.biography.standoutExperience.frameBuilding.bicycleFrameBuildingYearsOld =
                                        parseInt($scope.changeBiography.biography.standoutExperience.frameBuilding.bicycleFrameBuildingYearsOld);
                                }

                                User.biographyVerification({
                                    biography: $scope.changeBiography.biography
                                }, function () {
                                    Notification.success('Biography was successfully updated');
                                }, function (error) {
                                    ErrorHandler.onError(error);
                                });
                            }
                        });
                    }
                };
            }
        ]
    };
}]);
