import angular from "angular";
import userAutocomplete from "../index";

const component = {
    template: require("./user-autocomplete-dialog.html"),
    bindings: {
        filter: '<?vuiUadFilter',
        title: '@?vuiUadTitle'
    },
    controller: function ($mdDialog) {
        'ngInject';

        this.$onInit = () => {
            this.title = this.title || "Select user";
        };

        this.select = (user) => {
            $mdDialog.hide(user);
        };

        this.cancel = () => {
            $mdDialog.hide();
        };
    }

};

export default angular.module("vui-user-autocomplete-dialog.module", [userAutocomplete.name])
    .component("vuiUserAutocompleteDialog", component);