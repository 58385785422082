import app from "js/legacy-app";

app.config(['$provide', function ($provide) {
    $provide.decorator('$mdSelect', ['$delegate', '$timeout', '$interval', '$pageScroll',
        function ($delegate, $timeout, $interval, $pageScroll) {
            var origShow = $delegate.show;
            var body = document.body;
            var stop;
            $delegate.show = function () {
                var onShow = origShow.apply(this, arguments);

                onShow.then(function () {
                    $interval.cancel(stop);
                    stop = undefined;
                    $pageScroll.show();
                });

                var selectInput = arguments[0].target;
                var selectDropdownElement = arguments[0].element;

                $timeout(function () {
                    var selectRect = selectInput.getBoundingClientRect();
                    var selectDropdown = selectDropdownElement[0];
                    selectDropdown.style.minWidth = selectRect.width + 'px';
                    selectDropdown.style.left = selectRect.left + 'px';
                    selectDropdown.style.top = (selectRect.top + selectRect.height) + "px";

                    var dropDownBoundingRect = selectDropdown.getBoundingClientRect();
                    var scrollBy = calculateScrollForSelect(selectDropdown, selectInput);
                    $pageScroll.hide();
                    selectDropdown.style.minWidth = selectInput.getBoundingClientRect().width + 'px';
                    selectDropdown.style.left = selectInput.getBoundingClientRect().left + 'px';

                    if (dropDownBoundingRect.width < selectRect.width) {
                        selectDropdown.style.width = selectRect.width + "px";
                    }

                    stop = $interval(function () {
                        var selectDropdown = selectDropdownElement[0];
                        var selectRect = selectInput.getBoundingClientRect();
                        selectDropdown.style.left = selectRect.left + 'px';
                        if (!scrollBy) {
                            selectDropdown.style.top = (selectRect.top + selectRect.height) + "px";
                        } else {
                            selectDropdown.style.top = (selectRect.bottom - scrollBy) + 'px';
                        }
                        selectDropdown.style.minWidth = selectRect.width + 'px';
                        if (scrollBy) {
                            scrollBy = calculateScrollForSelect(selectDropdown, selectInput);
                        }
                    }, 13);
                }, 60);
                return onShow;
            };

            return $delegate;

            function maxScrollHeight() {
                return Math.max(
                    document.body.scrollHeight, document.documentElement.scrollHeight,
                    document.body.offsetHeight, document.documentElement.offsetHeight,
                    document.body.clientHeight, document.documentElement.clientHeight
                );
            }

            function calculateScrollForSelect(selectDropdown, selectInput) {
                var clientHeight = document.documentElement.clientHeight;
                var dropdownRect = selectDropdown.getBoundingClientRect();
                var scrollHeight = maxScrollHeight();
                var scrollBy;
                if (clientHeight < dropdownRect.bottom) {
                    var scrollTop = document.documentElement.scrollTop;
                    var scrollYet = scrollHeight - scrollTop - clientHeight;
                    var padding = 0;
                    var needScroll = dropdownRect.bottom - clientHeight;
                    if (scrollYet < needScroll) {
                        padding = dropdownRect.bottom - clientHeight - scrollYet;
                    }
                    if (padding > 0) {
                        body.style.cssText = 'padding-bottom:' + padding + 'px !important';
                    }

                    if (padding > 0) {
                        scrollBy = scrollYet + padding;
                    } else {
                        scrollBy = needScroll;
                    }
                    window.scrollBy(0, scrollBy);
                    selectDropdown.style.top = (selectDropdown.style.top - scrollBy) + 'px';
                }

                return scrollBy;
            }
        }

    ]);
}]);