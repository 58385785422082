import app from "js/legacy-app";

app.service('CustomServiceCategories', ['$resource', function ($resource) {
    return $resource('/api/custom-service-categories/:id/:id1', {}, {
        patch: {
            method: "PATCH",
            params: {id: ""},
            isArray: false
        },
        resetDefault: {
            method: "PUT",
            params: {id1: "reset-default"},
            isArray: false
        }
    });
}]);
