import app from "js/legacy-app";
import locationMarkerImg from 'img/breaking-dot.png';

app.directive('searchRequestMap', ['$location', function ($location) {
    return {
        scope: {
            searchRequestMap: '='
        },
        link: function ($scope, $element, $attrs, ngModel) {
            var markers = [];

            $scope.searchRequestMap.init = function (callback) {
                if (!$scope.searchRequestMap.map) {
                    var mapOptions = {
                        zoom: 10,
                        center: new window.google.maps.LatLng(40.162083, -98.723144),
                        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                        mapTypeControl: false,
                        streetViewControl: false,
                        minZoom: 3,
                        maxZoom: 17,
                        scrollwheel: false,
                        styles: [{"stylers":[{"saturation":-100},{"gamma":1}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi.business","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"poi.place_of_worship","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"poi.place_of_worship","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"geometry","stylers":[{"visibility":"simplified"}]},{"featureType":"water","stylers":[{"visibility":"on"},{"saturation":50},{"gamma":0},{"hue":"#50a5d1"}]},{"featureType":"administrative.neighborhood","elementType":"labels.text.fill","stylers":[{"color":"#333333"}]},{"featureType":"road.local","elementType":"labels.text","stylers":[{"weight":0.5},{"color":"#333333"}]},{"featureType":"transit.station","elementType":"labels.icon","stylers":[{"gamma":1},{"saturation":50}]}]
                    };
                    $scope.searchRequestMap.map = new window.google.maps.Map($element[0], mapOptions);
                }
                if (callback) {
                    callback();
                }
            };

            $scope.searchRequestMap.addMarker = function(lat, lng, title, image, id) {
                var location = new window.google.maps.LatLng(lat, lng);
                $scope.searchRequestMap.addLocationToMap(location, title, image, id);
            };

            $scope.searchRequestMap.addLocationToMap = function(location, title, image, id) {
                var params = {
                    position: location,
                    map: $scope.searchRequestMap.map,
                    animation: window.google.maps.Animation.DROP,
                    title: title,
                    icon: locationMarkerImg
                };
                if (image) {
                    params.icon = image;
                }
                var marker = new window.google.maps.Marker(params);
                marker.requestId = id;

                marker.addListener('click', function() {
                    new window.google.maps.InfoWindow({
                        content: '<div class="marker-info">' + title + '</div>'
                    }).open($scope.searchRequestMap.map, marker);
                });

                marker.addListener('mouseover', function() {
                    var self = this;
                    $scope.$apply(function() {
                        $scope.searchRequestMap.onFocusRequest(self.requestId);
                    });
                });

                marker.addListener('mouseout', function() {
                    var self = this;
                    $scope.$apply(function() {
                        $scope.searchRequestMap.offFocusRequest(self.requestId);
                    });
                });

                markers.push(marker);
            };

            $scope.searchRequestMap.clear = function() {
                for (var i in markers) {
                    markers[i].setMap(null);
                }

                markers = [];
            };

            $scope.searchRequestMap.zoomAll = function() {
                var bounds = new window.google.maps.LatLngBounds();
                for (var i = 0; i < markers.length; i++) {
                    bounds.extend(markers[i].getPosition());
                }

                $scope.searchRequestMap.map.fitBounds(bounds);
            };

            $scope.searchRequestMap.hoverRequest = function(id) {
                for (var i = 0; i < markers.length; i++) {
                    if (markers[i].requestId === id) {
                        markers[i].setAnimation(window.google.maps.Animation.BOUNCE);
                        return;
                    }
                }
            };

            $scope.searchRequestMap.unhoverRequest = function(id) {
                for (var i = 0; i < markers.length; i++) {
                    if (markers[i].requestId === id) {
                        markers[i].setAnimation(null);
                        return;
                    }
                }
            };

            $scope.goToRequest = function(id) {
                $location.path('/request/' + id);
            };

        }
    };
}]);