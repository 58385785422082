import app from "js/legacy-app";

app.directive('vtGallery', [function () {
    return {
        templateUrl: "template/component/gallery/gallery.tpl.html",
        scope: {
            vtGallery: '='
        },
        link: function ($scope, $element, $attrs, ngModel) {},
        controller: ['$scope', 'ModalService', function ($scope, ModalService) {
            $scope.remove = $scope.vtGallery.remove;
            $scope.setCover = $scope.vtGallery.setCover;
            $scope.showPicture = function($event, index) {
                ModalService.showPicture($event, function() {
                    return $scope.vtGallery.images;
                }, index, $scope.remove, null, $scope.setCover);
            };
        }]
    };
}]);
