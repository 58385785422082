import app from "js/legacy-app";

app.directive('replaceInvalidImage', [function () {
    return {
        restrict: 'A',
        scope: {
            replaceInvalidImage: '@'
        },
        link: function ($scope, $element, $attrs) {
            $element.on("error", function () {
                $element.attr('src', $scope.replaceInvalidImage);
            });
        }
    };
}]);
