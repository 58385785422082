// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/img/icons/maintenance.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<div class=\"maintenance-page\">\n    <div class=\"maintenance-page__header\">\n        <div class=\"maintenance-page__logo-container\">\n            <div class=\"maintenance-page__logo\"></div>\n        </div>\n    </div>\n    <div class=\"maintenance-page__body\">\n        <article>\n            <h1 class=\"center\">We&rsquo;ll be back soon!</h1>\n            <div class=\"center\">\n                <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment.\n                    If you need our immediate assistance, <a class=\"vt-link vt-link_bold vt-link_theme-yellow\"\n                                      href=\"mailto:team@velotooler.com\">contact us</a>,\n                    otherwise we'll be back online shortly!</p>\n            </div>\n        </article>\n        <div class=\"maintenance-page__icon-container\">\n            <img src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\">\n        </div>\n    </div>\n\n</div>";
// Exports
var _module_exports = code;;
var path = 'components/error-pages/maintenance/maintenance-page.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;