import angular from "angular";

const addBicycleAction = {
    template: require("./simple-bike-form-add-action.html"),
    require: {
        parent: '^vuiSimpleBikeForm'
    },
    bindings: {
        title: '@vuiSbfTitle'
    },
    controller: function () {

        this.$onInit = () => {
            this.title = this.title || 'Add bicycle';
        };

        this.add = () => {
            this.parent.submit();
        };
    }
};

export default angular.module("vui-simple-bike-form-actions", [])
    .component("vuiSbfAddAction", addBicycleAction);