import app from "js/legacy-app";

app.directive('vtForm', [function () {
    return {
        templateUrl: "components/form/vt-form.html",
        replace: true,
        transclude: {
            header: 'vtFormHeader',
            body: 'vtFormBody',
            footer: '?vtFormFooter'
        },
        scope: {},

        link: function (scope, elem, attrs) {
            scope.vf.vtForm = scope.vtForm;
        },

        controller: ['$scope', function ($scope) {
            $scope.vf = {};

            this.vf = $scope.vf;
        }]
    };
}]);