import app from "js/legacy-app";
import angular from "angular";

app.directive('profileLocation', ['User', 'ErrorHandler', 'ToggleLD', 'LocationService', '$location',
    function (User, ErrorHandler, ToggleLD, LocationService, $location) {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'template/profile/address/profile-location.html',
            scope: {
                model: "=plModel",
                addBtnLabel: "@plAddBtnLabel",
                onAdd: "=?plOnAdd",
                onRemove: "=?plOnRemove"
            },

            link: function ($scope, element, $attr) {

                $scope.pl = {};

                $scope.pl.iconClass = LocationService.getLocationIconClass($scope.model.type);

                $scope.pl.isSpecified = function () {
                    return !!$scope.model.address;
                };

                $scope.pl.add = function ($event) {
                    LocationService.editProfileAddress({
                            type: $scope.model.type,
                            description: $scope.model.type.charAt(0).toUpperCase() + $scope.model.type.slice(1).toLowerCase()
                        },
                        $event
                    ).then(function (location) {
                        if (angular.isFunction($scope.onAdd) && location) {
                            $scope.onAdd(location);
                        }
                    });
                };
            }
        };
    }]);