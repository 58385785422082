import app from "js/legacy-app";

app.controller('ConsultationMessageDialogController', ['$scope', '$mdDialog', 'message', '$sce', 'title',
    function ($scope, $mdDialog, message, $sce, title) {

        while (message.indexOf('\n') > -1) {
            message = message.replace('\n', '<br>');
        }

        $scope.title = title;
        $scope.message = message;

        $scope.no = function () {
            $mdDialog.hide();
        };

        $scope.submit = function () {
            $mdDialog.hide(true);
        };
    }
]);