import app from "js/legacy-app";

app.controller('DashboardBikesController', ['$scope', 'ModalService', 'AuthService', function ($scope, ModalService, AuthService) {

    $scope.dashboardBike = {
        isOrganisation: AuthService.isBikeOrganisationOwner()
    };

    if ($scope.dashboardBike.isOrganisation) {
        $scope.callRefresh = {};

        $scope.organisationId = AuthService.user().companyId;

        $scope.importBikes = function ($event) {
            ModalService.importBikes($event, {organisationId: $scope.organisationId}, function (response) {
                if (response) {
                    if (response.bikes && response.bikes.length > 0) {
                        ModalService.showRepeatedBikes($event, response.bikes);
                    }
                    $scope.callRefresh.refresh();
                }
            });
        };
    }

}]);