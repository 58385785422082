import app from "js/legacy-app";
import angular from "angular";

app.directive('pageableList', ['$mdUtil', '$parse', '$q', '$location', '$httpParamSerializer', '$window', '$pageableList', '$timeout', 'ErrorHandler',
    function ($mdUtil, $parse, $q, $location, $httpParamSerializer, $window, $pageableList, $timeout, ErrorHandler) {
        return {
            templateUrl: "template/component/widget/pageable-list/pageable-list.html",
            require: "?ngModel",
            replace: true,
            transclude: true,
            controller: ['$scope', function ($scope) {
                var pl = $scope.pageableList = {};

                pl.onStartLoadCallbacks = [];
                pl.onFinishLoadCallbacks = [];
                pl.onFinishRenderCallbacks = [];
                pl.onSearchStringUpdateCallbacks = [];
                pl.onFinishLoad = onFinishLoad;
                pl.onStartLoad = onStartLoad;
                pl.onFinishRender = onFinishRender;
                pl.onSearchStringUpdate = onSearchStringUpdate;

                function onFinishLoad(fn) {
                    pl.onFinishLoadCallbacks.push(fn);
                }

                function onSearchStringUpdate(fn) {
                    pl.onSearchStringUpdateCallbacks.push(fn);
                }

                function onStartLoad(fn) {
                    pl.onStartLoadCallbacks.push(fn);
                }

                function onFinishRender(fn) {
                    pl.onFinishRenderCallbacks.push(fn);
                }

                this.pl = pl;
            }],
            link: function ($scope, element, $attrs, $ctrl) {
                var plId = $attrs.plId,
                    plItemsExpr = $attrs.plDataItems,
                    plPageSize = $attrs.plPageSize ? parseInt($attrs.plPageSize) : 10,
                    plCtrl = $attrs.plCtrl,
                    isGlobal = $attrs.plGlobal === 'true',
                    type = $attrs.plType,
                    viewTotalCount = $attrs.plViewTotalCount,
                    itemParts = plItemsExpr.split(/ in /i),
                    itemExpr = itemParts[1],
                    itemName = itemParts[0],
                    queryExpr = itemExpr.split('(')[0],
                    filterExpr = itemExpr.split('(')[1].split(')')[0],
                    filterModel = filterExpr ? $parse(filterExpr) : "",
                    ctrlModel = plCtrl ? $parse(plCtrl) : null,
                    smoothRendering = $attrs.plSmoothRendering === 'true',
                    smoothRenderingMaxIterations = $attrs.plSmoothRenderingMaxIterations ?
                        parseInt($attrs.plSmoothRenderingMaxIterations) : 10,
                    fetchesInProgress = 0,
                    pl = $scope.pageableList;

                pl.items = [];
                pl.hasNextPage = false;
                pl.hasPrevPage = false;
                pl.totalAmount = 0;
                pl.itemName = itemName;
                pl.isLoading = true;
                pl.isRendering = true;
                pl.type = type;
                pl.viewTotalCount = viewTotalCount;
                pl.global = isGlobal;
                pl.smoothRendering = smoothRendering;
                pl.id = plId;
                pl.smoothRenderingMaxIterations = smoothRenderingMaxIterations;
                pl.evalQuery = evalQuery;
                pl.refreshPage = refreshPage;

                $pageableList.register(plId, pl);
                if (plId) {
                    element.attr('id', plId);
                }

                init();

                function refreshPage(options) {
                    return fetchResults(options);
                }

                function evalQuery(query) {
                    return $scope.$eval(queryExpr + "(query)", {
                        query: query
                    })
                }

                function fetchResults(options) {

                    options = options || {};

                    pl.onStartLoadCallbacks.forEach(function (fn) {
                        fn();
                    });

                    pl.isLoading = true;
                    pl.isRendering = true;
                    pl.error = null;

                    var filter = angular.isFunction(pl.filter) ? $scope.$eval(pl.filter) : pl.filter;

                    if (isGlobal && options && options.initialLoad) {
                        angular.extend(filter, $location.search());
                        filter.sort = filter.sort && typeof filter.sort === 'string' ? JSON.parse(filter.sort) : filter.sort;
                    }

                    angular.extend(filter, {
                        page: pl.currentPage,
                        size: pl.pageSize
                    });

                    var items = evalQuery(filter),
                        isPromise = !!items.then; // Every promise should contain a `then` property


                    if (isPromise) handleAsyncResults(items);
                    else handleResults(items);

                    function handleResults(items) {
                        pl.isLoading = false;
                        pl.error = null;
                        pl.items = items.slice(0, pl.pageSize);
                        pl.totalAmount = items.length;
                        pl.pages = [];
                        pl.totalPages = Math.ceil(items.length / pl.pageSize);
                        pl.hasNextPage = pl.currentPage < pl.totalPages;
                        pl.hasPrevPage = pl.currentPage > 0;

                        $timeout(function () {
                            pl.isRendering = false;
                            pl.onFinishLoadCallbacks.forEach(function (fn) {
                                fn();
                            });
                            pl.onFinishRenderCallbacks.forEach(function (fn) {
                                fn();
                            });
                        });
                    }

                    function handleAsyncResults(items) {
                        if (!items) return;

                        items = $q.when(items);
                        fetchesInProgress++;

                        $mdUtil.nextTick(function () {
                            items
                                .then(function (response) {
                                    onSuccess(response);
                                }, function (error) {
                                    onError(error);
                                }).finally(function () {
                                pl.onFinishLoadCallbacks.forEach(function (fn) {
                                    fn();
                                });
                                if (--fetchesInProgress === 0) {
                                    pl.isLoading = false;
                                }
                                if (isGlobal && !options.initialLoad) {
                                    var searchParams = {};
                                    angular.extend(searchParams, $location.search(), filter);
                                    pl.serializedFilter = $httpParamSerializer(searchParams);
                                    $location.search(pl.serializedFilter);
                                    pl.onSearchStringUpdateCallbacks.forEach(function (c) {
                                        c($location.search());
                                    });
                                    if (options.rewriteSearchParams) {
                                        $location.replace();
                                    }
                                }
                            });
                        }, true, $scope);
                    }

                    return filter;
                }

                function onSuccess(response) {
                    pl.error = null;
                    pl.items = response.data;
                    pl.totalAmount = response.total;
                    pl.pages = response.pages;
                    pl.totalPages = Math.ceil(response.total / pl.pageSize);
                    pl.hasNextPage = response.next;
                    pl.hasPrevPage = response.prev;

                    if (!pl.items || pl.items.length === 0) {
                        $timeout(function () {
                            pl.isRendering = false;
                            $scope.pageableList.onFinishRenderCallbacks.forEach(function (fn) {
                                fn();
                            });
                        });
                    }
                }

                function onError(error) {
                    pl.error = ErrorHandler.extractError(error);
                    pl.items = [];
                    pl.pages = [];
                    pl.totalPages = 0;
                    pl.totalAmount = 0;
                    pl.hasNextPage = false;
                    pl.hasPrevPage = false;

                    ErrorHandler.onError(error, 'Something went wrong');

                    $timeout(function () {
                        pl.isRendering = false;
                        $scope.pageableList.onFinishRenderCallbacks.forEach(function (fn) {
                            fn();
                        });
                    });

                }

                function initFilter() {
                    if (filterModel) {
                        pl.filter = $scope.$eval(filterModel);
                        if (!pl.filter) {
                            pl.filter = {};
                            filterModel.assign($scope, pl.filter);
                        }
                    }
                }

                function initController() {
                    if (ctrlModel) {
                        var ctrl = $scope.$eval(ctrlModel);
                        if (!ctrl) {
                            ctrl = {};
                            ctrlModel.assign($scope, ctrl);
                        }

                        ctrl.refreshPage = pl.refreshPage;
                        ctrl.nextPage = pl.nextPage;
                        ctrl.prevPage = pl.prevPage;
                    }
                }

                function validateType() {
                    if (pl.type !== 'table' && pl.type !== 'grid' && pl.type !== 'none') {
                        throw new Error('Invalid list type. Valid values: "table" ,"grid"');
                    }
                }

                function initPaging() {
                    pl.currentPage = isGlobal && $location.search().page ? parseInt($location.search().page) : 0;
                    pl.pageSize = isGlobal && $location.search().size ? parseInt($location.search().size) : (plPageSize || 10);
                }

                function init() {
                    validateType();
                    if (isGlobal) {
                        // for purposes of back button click handling
                        var jumpingToAnotherPage;
                        $scope.$on('$routeChangeStart', function (e, newRoute, oldRoute) {
                            jumpingToAnotherPage = newRoute.segment !== oldRoute.segment;
                        });
                        $scope.$on('$locationChangeSuccess', function (evt, a, b, c, d) {

                            if ($httpParamSerializer($location.search()) !== pl.serializedFilter &&
                                !jumpingToAnotherPage &&
                                !pl.isRendering &&
                                !pl.isLoading) {
                                initPaging();
                                if (pl.filterController) {
                                    pl.filterController.init();
                                } else {
                                    fetchResults({
                                        initialLoad: true
                                    });
                                }
                            }
                        });
                    }
                    initController();
                    initFilter();
                    initPaging();
                    if (pl.filter) {
                        fetchResults({
                            initialLoad: true
                        });
                    }
                }

            }
        };
    }]);