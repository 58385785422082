import app from "js/legacy-app";
import videojs from 'video.js'
import 'video.js/dist/video-js.min.css'

app.directive('videoAutoPlay', [function () {
    return {
        link: function ($scope, $element, $attrs, ngModel, $timeout) {
            const video = $element[0];
            const player = videojs(video, {});
        }
    };
}]);
