import app from "js/legacy-app";

app.directive('uiSidebarBtn', [function () {
    return {
        restrict: 'C',
        link: function ($scope, $element, $attrs, ngModel) {
            new window.ui.sidebarBtn($element[0]);
        }
    };
}]);
