import app from "js/legacy-app";

app.filter("bikeStatus", [function () {
    return function (status) {
        switch (status) {
            case 'USED':
                return 'IN USE';
            case 'BROKEN':
                return 'REPAIRING';
            case 'RECYCLED':
                return 'RETIRED';
            case 'IN_TRANSFER':
                return 'IN TRANSFER';
        }
    };
}]);