import app from "js/legacy-app";

app.service('SsoResource', ['$resource', function ($resource) {
    return $resource('/api/sso/:id/:id1', {}, {
        completeRegistration: {
            method: "PUT",
            params: {id: "complete-registration"},
            isArray: false
        }
    });
}]);
