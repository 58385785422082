import app from "js/legacy-app";

app.service("$temporaryStorage", [function () {

    var self = {};

    function put(key, value) {
        self[key] = value;
    }

    function get(key) {
        return self[key];
    }

    function contains(key) {
        return !!self[key];
    }

    function remove(key) {
        delete self[key];
    }

    function poll(key) {
        var value = self[key];
        delete self[key];
        return value;
    }

    return {
        put: put,
        get: get,
        contains: contains,
        remove: remove,
        poll: poll
    };
}]);
