/**
 * Created by eugineer on 10/17/16.
 */
import app from "js/legacy-app";
import angular from "angular";

app.directive('tooltip', ['tooltipHandler', function (tooltipHandler) {
    var checkMdDialogContainer = function () {
        var dialogContainers = document.getElementsByClassName('md-dialog-container');

        if (dialogContainers.length === 0) {
            return;
        }

        angular.element(dialogContainers[0]).on('scroll', tooltipHandler.onTooltipHide);
        tooltipHandler.checkedMdDialogContainer = true;
    };

    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            tooltipHandler.setTooltip(element, attrs.tooltip);

            if (!tooltipHandler.checkedMdDialogContainer) {
                checkMdDialogContainer();
            }

            scope.$on('$destroy', function() {
                tooltipHandler.onElementDestruction(element);
            });
        }
    };
}]);