import app from "js/legacy-app";

app.directive('scheduleServiceAction', [
    function () {
        return {
            templateUrl: "components/mechanic-public-services/actions/schedule-service-action.html",
            replace: true,
            scope: {
                service: "=ssaService",
                mechanic: "=ssaMechanic",
                location: "=ssaLocation"
            },
            controller: ['$scope', '$mechanicService',
                function ($scope, $mechanicService) {
                    $scope.ssa = {};

                    $scope.ssa.schedule = function (service) {
                        $mechanicService.scheduleMechanic(
                            null, $scope.mechanic,
                            $scope.location, null, $scope.service
                        );
                    };

                }]
        };
    }]);