import './input-container.component.scss';

export default () => {
    return {
        restrict: 'E',
        replace: true,
        transclude: true,
        template: require('./input-container.component.html'),
        link: (scope, element, attr) => {
            element.find('input').on('focusin', (e) => {
                element.addClass('vui-input-container--focused');
            });
            element.find('input').on('focusout', (e) => {
                element.removeClass('vui-input-container--focused');
            });
        }
    };
}
