import app from "js/legacy-app";

app.directive('bikeRegistrationStatistic', [function () {
    return {
        template: '<div class="md-padding chart-container">' +
        '   <div>Bicycles registration</div>' +
        '   <div>' +
        '       <canvas class="chart chart-line" chart-data="data" chart-labels="labels" chart-options="options" ' +
        '           chart-options="options" chart-dataset-override="datasetOverride"></canvas>' +
        '   </div>' +
        '</div>',
        replace: true,
        scope: true,
        controller: ['$scope', 'AdminBikeResource', function ($scope, AdminBikeResource) {

            $scope.labels = [];
            $scope.data = [[]];

            $scope.datasetOverride = [{ yAxisID: 'y-axis-bike-registration' }];
            $scope.options = {
                scales: {
                    yAxes: [
                        {
                            id: 'y-axis-bike-registration',
                            type: 'linear',
                            display: true,
                            position: 'left'
                        }
                    ]
                }
            };
            $scope.series = ['Bicycle registration'];

            AdminBikeResource.countRegistration(function (data) {
                for (var i = 0; i < data.length; i++) {
                    $scope.labels.push(data[i].label);
                    $scope.data[0].push(data[i].count);
                }
            });

        }]
    };
}]);
