import app from "js/legacy-app";

app.directive('additionalDescription', ['Notification', function (Notification) {
    return {
        templateUrl: "template/dashboard/bikes/add-bike/additional-description.html",
        scope: {
            bike: '=additionalDescription',
            next: '&'
        },
        controller: ['$scope', '$bikeService', '$frameMaterialService',
            function ($scope, $bikeService, $frameMaterialService) {

                $scope.additionalDesc = {
                    materials: $frameMaterialService.getAll(),
                    bikeType: $bikeService.getBikeType
                };

                if ($scope.bike.frameMaterial) {
                    $scope.additionalDesc.frameMaterial = $bikeService.getFrameMaterialByName($scope.bike.frameMaterial);
                }
                if ($scope.bike.wheelSize) {
                    $scope.additionalDesc.wheelSize = $bikeService.getWheelSizeByName($scope.bike.wheelSize);
                }

                $scope.changeFrameMaterial = function () {
                    if ($scope.additionalDesc.frameMaterial) {
                        $scope.bike.frameMaterial = $scope.additionalDesc.frameMaterial.name;
                    } else {
                        $scope.bike.frameMaterial = undefined;
                    }
                };

                $scope.changeWheelSize = function () {
                    if ($scope.additionalDesc.wheelSize) {
                        $scope.bike.wheelSize = {
                            inch: $scope.additionalDesc.wheelSize.inch,
                            iso: $scope.additionalDesc.wheelSize.iso,
                            bicycleStandard: $scope.additionalDesc.wheelSize.bicycleStandard
                        };
                    } else {
                        $scope.bike.wheelSize = undefined;
                    }
                };

                $scope.close = $bikeService.cancelCreateBike;

                $scope.submit = function (form) {
                    if (form.$valid && isValid()) {
                        $scope.next()(2);
                    }

                    function isValid() {
                        var bike = $scope.bike;
                        if (!bike.colors || bike.colors.length === 0) {
                            Notification.warning('Please select bicycle color');
                            return false;
                        }

                        return true;
                    }
                };
            }
        ]
    };
}]);