import "./header-link.component.scss";

export default {
    template: require('./header-link.component.html'),
    transclude: true,
    bindings: {
        path: '@?vuiPath',
        altPath: '@?vuiAltPath',
        type: '@?vuiType'
    },
    controller: function ($location) {
        'ngInject';

        this.location = $location
    }
};
