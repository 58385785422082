import app from "js/legacy-app";

app.service('$mobileBodyHeightCalculator', ['$mdMedia', function ($mdMedia) {

    var calculate = function (header, footer) {
        if (!$mdMedia('(max-width: 470px)')) {
            return;
        }

        if (!header) {
            return;
        }

        var clientHeight = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
        var footerHeight = footer ? footer.clientHeight : 0;
        var headerHeight = header.clientHeight;
        return (clientHeight - footerHeight - headerHeight) + 'px';
    };

    return {
        calculate: calculate,
    };
}
]);

