import app from "js/legacy-app";

app.controller('SettingsCtrl', ['$scope', '$location', function ($scope, $location) {

    if ($location.$$path === '/settings') {
        $location.path('settings/security');
    }

    $scope.$on('$locationChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
        if ($location.$$path === '/settings') {
            $location.path('settings/security');
        }
    });

}]);