import app from "js/legacy-app";
import angular from "angular";

app.directive('loadCurrentCity', ['$compile', 'Notification', function ($compile, Notification) {
    return {
        require: "?ngModel",
        link: function (scope, element, attrs, ngModel) {
            var input;
            input = element[0];
            var mapMarkerClass = 'fa-crosshairs iconed-button right-icon';
            var spinnerClass = 'fa-spin fa-spinner';

            element.addClass("input-indent-for-icon");

            scope.markerClass = mapMarkerClass;
            var el = angular.element('<i class="fa fa-lg {{ markerClass }} toggle-button" aria-hidden="true" ng-click="getCurrentLocation()" />');
            $compile(el)(scope);
            element.after(el);

            scope.getCurrentLocation = function () {
                if (navigator.geolocation) {
                    scope.markerClass = spinnerClass;
                    navigator.geolocation.getCurrentPosition(function (position) {
                        var lat = position.coords.latitude;
                        var lng = position.coords.longitude;
                        var latlng = new window.google.maps.LatLng(lat, lng);
                        new window.google.maps.Geocoder().geocode({'latLng': latlng}, function (results, status) {
                            if (status === window.google.maps.GeocoderStatus.OK) {
                                scope.place = results[0];
                                if (scope.place.address_components) {
                                    for (var i in scope.place.address_components) {
                                        for (var j in scope.place.address_components[i].types) {
                                            if (scope.place.address_components[i].types[j] === 'locality') {
                                                element.val(scope.place.address_components[i].short_name);
                                                ngModel.$setViewValue(scope.place.address_components[i].short_name);
                                                scope.markerClass = mapMarkerClass;
                                                scope.$apply();
                                                return;
                                            }
                                        }
                                    }
                                }
                            }
                        });
                    }, function (error) {
                        scope.markerClass = mapMarkerClass;
                        Notification.warning("Geolocation is not supported by this browser");
                    });
                } else {
                    Notification.warning("Geolocation is not supported by this browser");
                }
            };
        }
    };
}]);