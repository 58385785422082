import app from "js/legacy-app";

app.directive('vtAlert', [function () {
    return {
        templateUrl: "components/alert/alert.html",
        transclude: {
            title: "vtAlertTitle",
            content: "vtAlertContent"
        },
        scope: {
            type: '@vtAlertType'
        },

        controller: ['$scope', function ($scope) {
            $scope.type = $scope.type || 'info';
        }]
    };
}]);
