import app from "js/legacy-app";

app.directive('profileCancellationPolicy', [function () {
    return {
        templateUrl: "template/profile/widget/profile-cancellation-policy.html",
        replace: true,
        scope: {
            model: '<pcpModel'
        },
        controller: ['$scope', '$bookingCancellationService', function ($scope, $bookingCancellationService) {

            $scope.$watch('model', function (value) {
                if (!value) {
                    return;
                }

                $scope.cancellationPolicy = $bookingCancellationService.getCancellationPolicy(value.rules);
            });
        }]
    };
}]);