import app from "js/legacy-app";

app.controller('BikeShopExperienceCtrl', ['$scope', 'User', '$location', function ($scope, User, $location) {

    $scope.bikeShopExperience = {
        shops: [],
        disabledChange: false
    };

    $scope.addOrganisation = function() {
        $scope.bikeShopExperience.shops.push({
            name: '',
            email: '',
            phone: ''
        });
    };

    $scope.removeOrganisation = function(index) {
        $scope.bikeShopExperience.shops.splice(index, 1);
    };

    $scope.continue = function(form) {
        if (form.$valid) {
            $scope.bikeShopExperience.disabledChange = true;
            User.bikeShopExperienceVerification($scope.bikeShopExperience.shops, function() {
                $location.path('/dashboard');
                $scope.bikeShopExperience.disabledChange = false;
            });
        }
    };

    $scope.refresh = function() {
        User.getVerification(function(data) {
            $scope.verification = data;

            if ($scope.verification && $scope.verification.bikeShopExperience) {
                $scope.bikeShopExperience.shops = $scope.verification.bikeShopExperience.shops;
            }
            if (!$scope.bikeShopExperience.shops || $scope.bikeShopExperience.shops.length === 0) {
                $scope.bikeShopExperience.shops = [];
                $scope.addOrganisation();
            }
        });
    };

    $scope.refresh();
}]);