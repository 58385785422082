import angular from "angular";
import settingsSection from "../../../vt-generic/components/settings-section";
import card from "../../../vt-generic/components/card";
import bookingServiceDetails from "../service-booking/booking-service-details-card";
import bookingNotes from "../service-booking/booking-notes-card";
import bookingAppointmentDate from "../service-booking/booking-appointment-date-card";
import bookingBicycles from "../service-booking/booking-bicycles-card";
import bookingCustomer from "../service-booking/booking-customer-card";
import bookingLocation from "../service-booking/booking-location-card";
import bookingActions from "./service-booking-actions";
import bookingPricingInfo from "../service-booking/booking-pricing-info";

const component = {
    template: require('./service-booking.html'),
    bindings: {
        onSubmit: "&?vuiSbOnSubmit",
        model: "<vuiServiceBooking",
    },
    transclude: {
        actions: "?vuiServiceBookingActions",
    },
    controller: function ($requestService, MechanicSchedule, AuthService, Insurance) {
        'ngInject';

        const ctrl = this;

        ctrl.$onInit = () => {
            this.mechanicId = AuthService.user().id;
            ctrl.serviceFees = 0.1;
        };

        ctrl.save = function () {
            if (angular.isFunction(ctrl.onSubmit)) {
                ctrl.onSubmit({serviceBooking: ctrl.model});
            }
        };

        ctrl.onSubmitServiceDetails = (serviceDetails) => {
            ctrl.model.serviceDetails = serviceDetails;
            ctrl.save();
        };

        ctrl.onSubmitBikes = (bikes) => {
            ctrl.model.bikes = [...bikes];
            ctrl.save();
        };

        ctrl.onSubmitLocation = (location) => {
            ctrl.model.serviceLocation = location;
            ctrl.save();
        };

        ctrl.onSubmitNotes = (notes) => {
            ctrl.model.notes = notes;
            ctrl.save();
        };

        function updateServiceFees() {
            let r = {
                startDate: ctrl.model.appointmentDate.date
            };

            ctrl.serviceFees = 0.1;
        }

        ctrl.onSubmitAppointmentDate = (date) => {
            ctrl.model.appointmentDate = date;
            updateServiceFees();
            ctrl.save();
        };

        ctrl.onSubmitCustomer = (customer) => {
            ctrl.model.customer = customer;
            ctrl.save();
        };

        ctrl.loadMechanicAvailabilityDates = () => {
            let query = {
                mechanicId: this.mechanicId,
                lat: 41.3328812 || ctrl.model.serviceLocation.latitude,
                lng: -72.8993376 || ctrl.model.serviceLocation.longitude,
                duration: 30 || ctrl.model.serviceDetails.timeToComplete,
                detalization: 'L'
            };

            return MechanicSchedule.getAvailability(query).$promise;
        };
    }
};

export default angular.module("vui-service-booking", [
    settingsSection.name,
    card.name,
    bookingServiceDetails.name,
    bookingNotes.name,
    bookingAppointmentDate.name,
    bookingBicycles.name,
    bookingCustomer.name,
    bookingLocation.name,
    bookingActions.name,
    bookingPricingInfo.name
]).component('vuiServiceBooking', component);
