import app from "js/legacy-app";
import angular from "angular";

app.directive('vtPrefixedInput', ['$timeout', function ($timeout) {
    return {
        templateUrl: "components/input/vt-prefixed-input.html",
        replace: true,
        scope: {
            prefix: '@vtInputPrefix',
            label: '@?vtInputLabel',
            model: '<vtInputModel',
            onChange: '&vtInputOnChange',
            options: '<vtInputOptions',
            inputName: '@vtInputName'
        },

        link: function (scope, elem, attrs) {
            let prefix = elem[0].querySelector('.vt-prefixed-input__prefix');
            let body = elem[0].querySelector('.vt-prefixed-input__body');
            let wrappedBody = angular.element(body);

            window.addEventListener('resize', onResize);

            function onResize() {
                let width = prefix.clientWidth;
                wrappedBody.css({'margin-left': -width + "px", 'padding-left': width + "px"});
            }

            $timeout(function () {
                onResize();
            }, 10);
        },

        controller: ['$scope', function ($scope) {
            $scope.change = (text) => {
                $scope.onChange({text: text});
            }
        }]
    };
}]);
