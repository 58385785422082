import app from "js/legacy-app";

app.directive('addBikePhotos', [function () {
    return {
        templateUrl: "template/dashboard/bikes/add-bike/photos.html",
        scope: {
            imageContainer: '=addBikePhotos',
            next: '&'
        },
        controller: ['$scope', 'Documents', '$bikeService', function ($scope, Documents, $bikeService) {

            $scope.addBikePhotos = {
                documents: Documents.bikeImage()
            };

            $scope.close = $bikeService.cancelCreateBike;

            $scope.submit = function () {
                $scope.next()(4);
            };

        }]
    };
}]);