import app from "js/legacy-app";

app.directive('dashboardOrganisation', [function () {
    return {
        templateUrl: "template/dashboard/bike-business-supervisor/organisation/dashboard-organisation.html",
        controller: ['$scope', '$filter', 'Organisation', 'CoreServiceBuckets', 'AuthService',
            function ($scope, $filter, Organisation, CoreServiceBuckets, AuthService) {

                $scope.ol = {
                    search: function (query) {
                        return Organisation.findByCreatorId({
                            param2: AuthService.user().id,
                            size: query.size,
                            page: query.page,
                            name: query.name,
                            serviceBucket: query.serviceBucket
                        }, {}).$promise;
                    },

                    filter: {
                        serviceBucket: {
                            options: []
                        }
                    }
                };

                $scope.ol.getViewOrganisationUrl = function (organisation) {
                    return '/organisation/' + organisation.id + '/bikes';
                };

                $scope.ol.getOrganisationProfileUrl = function (organisation) {
                    return '/organisation/' + organisation.id + '/edit';
                };

                CoreServiceBuckets.get({size: 100}, function (res) {
                    $scope.ol.filter.serviceBucket.options = [{
                        val: null,
                        display: 'All'
                    }].concat(res.data.map((sb) => {
                        return {val: sb.type, display: sb.name};
                    }));
                });

            }
        ]
    };
}]);
