import app from "js/legacy-app";

app.controller('MaintenancePageController', ['$scope', 'HealthCheckResource', '$routeParams', '$location', '$interval',
    function ($scope, HealthCheckResource, $routeParams, $location, $interval) {
        var refresh = function () {
            HealthCheckResource.get({}, function () {
                if ($routeParams.redirect) {
                    $location.url($routeParams.redirect);
                } else {
                    $location.path('/');
                }
            });
        };

        refresh();

        var promise = $interval(function () {
            refresh();
        }, 10000);

        $scope.$on('$destroy', function () {
            $interval.cancel(promise);
        });
    }
]);
