export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/mechanic/applications', 'mechanic-applications')
        .when('/mechanic/applications/:applicationId', 'mechanic-applications-view')

        .segment('mechanic-applications', {
            access: {requireLogin: true},
            template: '<mechanic-applications></mechanic-applications>',
            loadModule: () => {
                return import(/* webpackChunkName: "mechanic-applications.module" */ "./application-list/application-list.module");
            }
        })
        .segment('mechanic-applications-view', {
            access: {requireLogin: true},
            template: '<mechanic-application-view></mechanic-application-view>',
            loadModule: () => {
                return import(/* webpackChunkName: "mechanic-applications-view.module" */ "./application-view/application-view.module");
            }
        });

}
