import app from "js/legacy-app";
import angular from "angular";

app.directive('vtSidenavFooter', ['ErrorHandler', '$mdMedia', function (ErrorHandler, $mdMedia) {
    return {
        templateUrl: "template/component/widget/sidenav/vt-sidenav-footer.html",
        replace: true,
        require: '^^vtSidenav',
        link: function ($scope, element, attr, sidenav) {

            $scope.vsf = {};

            $scope.vsf.save = function () {
                sidenav.vs.sidenavForm.$setSubmitted();
                if (sidenav.vs.sidenavForm.$valid && angular.isFunction(sidenav.vs.submit)) {
                    sidenav.vs.submit(function (error) {
                        ErrorHandler.onError(error, null, null, sidenav.vs.sidenavForm);
                    });
                }
            };

            $scope.vsf.bottom = function () {
                var maxBottom = $mdMedia('(max-width: 343px)') ? 59 : 64;
                var scrollTop = document.body.scrollTop + document.getElementsByTagName('html')[0].scrollTop;
                if (scrollTop > maxBottom) {
                    return 0;
                }
                return maxBottom - scrollTop + 'px';
            };
        }
    };
}]);