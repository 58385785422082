import app from "js/legacy-app";

app.directive('virtualRepeatController', ['$timeout', function ($timeout) {
    return {
        scope: {
            ctrl: '=virtualRepeatController'
        },
        link: function ($scope, $element) {

            var scroller = $element[0].getElementsByClassName('md-virtual-repeat-scroller')[0];
            var offsetter = $element[0].getElementsByClassName('md-virtual-repeat-offsetter')[0];

            $scope.ctrl.right = function () {
                let scrollerWidth = scroller.clientWidth;
                let elementWidth = offsetter.firstElementChild.clientWidth;
                let countElementsInVisibleArea = Math.floor(scrollerWidth / elementWidth);
                scroll((countElementsInVisibleArea - 1) * elementWidth);
            };

            var scroll = function (change) {
                var start = scroller.scrollLeft,
                    duration = 500,
                    currentTime = 0,
                    increment = 20;

                var animateScroll = function () {
                    currentTime += increment;
                    scroller.scrollLeft = Math.easeInOutQuad(currentTime, start, change, duration);
                    if (currentTime < duration) {
                        $timeout(animateScroll, increment);
                    }
                };
                animateScroll();
            };

            $scope.ctrl.left = function () {
                let scrollerWidth = scroller.clientWidth;
                let elementWidth = offsetter.firstElementChild.clientWidth;
                let countElementsInVisibleArea = Math.floor(scrollerWidth / elementWidth);
                scroll(-(countElementsInVisibleArea - 1) * elementWidth);
            };

            $scope.ctrl.scroll = function (countElements) {
                $timeout(function () {
                    let elementWidth = offsetter.firstElementChild.clientWidth;
                    scroll((countElements - 1) * elementWidth);
                });
            };

            $scope.ctrl.hasElementsFromLeft = function () {
                return scroller.scrollLeft > 0;
            };

            $scope.ctrl.hasElementsFromRight = function () {
                return scroller.scrollLeft < scroller.scrollWidth;
            };
        },
        controller: ['$scope', function ($scope) {
        }]
    };
}]);
