import angular from "angular";
import bookingLocationActions from "./booking-location-actions";

const component = {
    template: require('./booking-location-card.html'),
    bindings: {
        onSubmit: "&?vuiBlOnSubmit",
        location: "<vuiBookingLocation"
    },
    transclude: {
        actions: "vuiBookingLocationActions"
    },

    controller: function () {
        const ctrl = this;

        ctrl.$onInit = () => {
            this.location = this.location || {};
        };

        ctrl.submit = function (location) {
            if (angular.isFunction(ctrl.onSubmit)) {
                ctrl.onSubmit({location: location});
            }
        }
    }
};

export default angular.module("vui-booking-location.module", [bookingLocationActions.name])
    .component('vuiBookingLocation', component);
