import app from "js/legacy-app";
import angular from "angular";

app.service('$mechanicService', ['$location', '$mdDialog', 'ToggleLD', 'ErrorHandler', 'Mechanics',
    'LocationService', '$pageScroll', 'AuthService',
    function ($location, $mdDialog, ToggleLD, ErrorHandler, Mechanics, LocationService, $pageScroll, AuthService) {

        var scheduleMechanic = function ($event, mechanic, location, showMechanicsFn, service) {

            function getOrganisationId() {
                return AuthService.isBikeOrganisationOwner() ? AuthService.user().id : null;
            }

            if (location && location.zip) {
                $location.path('/create-request').search({
                    mechanicId: mechanic.id,
                    address: location.address,
                    taskCode: service ? service.code : null,
                    taskId: service ? service.id : null,
                    serviceBucket: service && service.serviceBucket ? service.serviceBucket : null,
                    organisationId: getOrganisationId(),
                    back: 'useBrowserHistory'
                });
                return;
            }
            $pageScroll.hide();
            $mdDialog.show({
                targetEvent: $event,
                template: `<md-dialog ng-cloak aria-label="Schedule {{mechanic.name}} {{mechanic.surname}}" vt-modal-dynamic-height class="vt-modal vt-modal-mobile">

                      <div class="vt-modal__header vt-modal-mobile__header">
                        <div class="display-inline-block">Enter full address</div>
                        <div class="vt-modal-mobile__header-close-btn">
                            <i class="vt-ui-icon vt-ui-icon-close" aria-label="Close" data-ng-click="closeDialog()"></i>
                        </div>
                       </div>

                      <form name="addressForm" data-ng-submit="checkCoverage(addressForm)" novalidate>
                          <div data-ng-show="!notCovered" class="vt-modal__body vt-modal-mobile__body">
                            <div>Let us check if {{ mechanic.name }} is working in your area. </div>
                            <div class="tw-pt-3">Please enter your full address (including street and building numbers)</div>
                            <div class="tw-py-4">
                                <location-autocomplete
                                                la-label="Full address"
                                                la-model="address"
                                                la-no-favorite="true"
                                                la-on-location-change="checkAddress"
                                                la-autofocus="true"
                                                la-detect-current-location="true">
                                   <div data-ng-if="error" class="color-error">{{ error }}</div>
                                </location-autocomplete>
                            </div>
                          </div>

                          <div data-ng-show="notCovered" class="vt-modal__body vt-modal-mobile__body">
                            <div>Mechanic does not service address: <strong class="color-primary">{{ address.address }}</strong>.</div>
                            <div data-ng-if="showMechanicsFn" class="tw-pt-4">Do you want us to show mechanics who service this area?</div>
                          </div>

                          <div class="vt-modal__footer vt-modal__footer-mobile">
                            <div class="align-center">
                                <md-button type="button" class="md-big" ng-click="closeDialog()" aria-label="Close">Close</md-button>
                                <md-button data-ng-if="!notCovered" type="submit" class="md-raised md-primary md-big md-button-uppercase" aria-label="Schedule">
                                    Schedule
                                </md-button>
                                <md-button data-ng-if="notCovered && showMechanicsFn" data-ng-click="mechanicSearchCallback()" class="md-primary">
                                    Yes, show more mechanics
                                </md-button>
                            </div>
                          </div>

                          <div class="vt-modal-mobile__footer">
                            <md-button data-ng-if="!notCovered" type="submit" class="md-primary" aria-label="Schedule">Schedule</md-button>
                            <md-button data-ng-if="notCovered && showMechanicsFn" data-ng-click="mechanicSearchCallback()" class="md-primary">
                                Yes, show more mechanics
                            </md-button>
                          </div>
                      </form>
                </md-dialog>`,
                locals: {
                    mechanic: mechanic,
                    service: service
                },
                controller: ['$scope', 'mechanic', 'service', DialogController]
            }).finally(function () {
                $pageScroll.show();
            });

            function DialogController($scope, mechanic, service) {
                $scope.mechanic = mechanic;
                $scope.service = service;
                $scope.showMechanicsFn = showMechanicsFn;

                $scope.closeDialog = function () {
                    $mdDialog.hide();
                };

                $scope.checkAddress = function (address) {
                    $scope.error = LocationService.validateAddress(address);
                    return !$scope.error;
                };

                $scope.mechanicSearchCallback = function () {
                    if (angular.isFunction(showMechanicsFn)) {
                        showMechanicsFn($scope.address);
                    }
                    $mdDialog.hide();
                };

                $scope.checkCoverage = function () {
                    var address = $scope.address;
                    if (!$scope.checkAddress(address)) {
                        return;
                    }
                    if (!address.zip) {
                        $scope.notCovered = true;
                        return;
                    }

                    ToggleLD.show();
                    Mechanics.checkCoverage(
                        {
                            id: $scope.mechanic.id
                        },
                        {
                            latitude: address.latitude,
                            longitude: address.longitude,
                            city: address.city,
                            country: address.country,
                            state: address.state,
                            zip: address.zip,
                            address: address.address
                        }, function (response) {
                            ToggleLD.hide();
                            if (response.covered === true) {
                                $mdDialog.hide();
                                $location.path('/create-request').search({
                                    mechanicId: $scope.mechanic.id,
                                    address: address.address,
                                    taskCode: service ? service.code : null,
                                    taskId: service ? service.id : null,
                                    serviceBucket: service && service.serviceBucket ? service.serviceBucket : null,
                                    organisationId: getOrganisationId(),
                                    back: 'useBrowserHistory'
                                });
                            } else {
                                $scope.notCovered = true;
                            }
                        }, function (error) {
                            ToggleLD.hide();
                            $mdDialog.hide();
                            ErrorHandler.onError(error, "Something went wrong");
                        });
                };
            }

        };

        return {
            scheduleMechanic: scheduleMechanic
        };
    }
]);

