import app from "js/legacy-app";

app.service('Payments', ['$resource', function ($resource) {
    return $resource('/api/payments/:id/:action', {}, {
        hasSufficientFunds: {
            method: "POST",
            params: {id: "has-sufficient-funds", action: ""},
            isArray: false
        }
    });
}]);
