import app from "js/legacy-app";
import angular from "angular";

app.directive('googleMap', ['$timeout', function ($timeout) {
    return {
        scope: {
            googleMap: '=',
            scale: '&'
        },
        controller: ['$scope', '$element', function ($scope, $element) {
            var existingShapes = [];
            var infoWindow = null;

            $scope.googleMap.addMarker = function (lat, lng, icon) {
                if (!lat || !lng)
                    return;

                var location = new window.google.maps.LatLng(lat, lng);
                return $scope.googleMap.addLocationToMap(location, icon);
            };

            $scope.googleMap.addLocationToMap = function (location, icon) {
                var params = {
                    position: location,
                    map: $scope.googleMap.map,
                    title: 'Bicycle home'
                };

                if (icon) {
                    params.icon = icon;
                }

                var marker = new window.google.maps.Marker(params);

                if ($scope.googleMap.changeZoom !== false && $scope.googleMap.map) {
                    $scope.googleMap.map.panTo(location);
                    $scope.googleMap.map.setZoom(16);
                }

                existingShapes.push(marker);
                return marker;
            };

            $scope.googleMap.clear = function () {
                for (var i in existingShapes) {
                    existingShapes[i].setMap(null);
                }

                existingShapes = [];
            };

            $scope.googleMap.addPoligon = function (points, additionalInfo) {
                var area = new window.google.maps.Polygon({
                    paths: points,
                    strokeColor: '#545454',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillColor: '#ffec92',
                    fillOpacity: 0.35
                });
                area.setMap($scope.googleMap.map);

                existingShapes.push(area);

                if (additionalInfo) {
                    $scope.googleMap.addPolygonInfoWindow(area, additionalInfo);
                }

                return area;
            };

            $scope.googleMap.addGeoJsonPolygon = function (geojson) {
                var result = [];
                if (geojson.type === 'MultiPolygon') {
                    geojson.coordinates.forEach(function (polyCoords) {
                        result.push($scope.googleMap.addPoligon(geojsonPolyToGooglePoly(polyCoords)));
                    });
                } else {
                    result.push($scope.googleMap.addPoligon(geojsonPolyToGooglePoly(geojson.coordinates)));
                }

                return result;
            };

            var geojsonPolyToGooglePoly = function (polyCoords) {
                return polyCoords.map(function (ringCoords) {
                    return ringCoords.map(function (coord) {
                        return {
                            lat: coord[1],
                            lng: coord[0]
                        };
                    });
                });
            };

            $scope.googleMap.addCircle = function (center, radius, options) {
                var defaultOptions = {
                    map: $scope.googleMap.map,
                    strokeColor: '#777777',
                    fillColor: '#777777',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillOpacity: 0.4
                };

                options = options || {};

                var circleOptions = angular.extend({}, defaultOptions, options || {});
                circleOptions.center = center;
                circleOptions.radius = radius;

                var circle = new window.google.maps.Circle(circleOptions);

                existingShapes.push(circle);

                return circle;
            };

            $scope.googleMap.addPolygonInfoWindow = function (area, additionalInfo) {
                area.addListener('click', function (event) {
                    if (infoWindow) {
                        infoWindow.close();
                    }
                    var template = '<div style="color: #545454; font-size: 1.3em">' +
                        '<b>Country: </b>' + additionalInfo.country + '<br />' +
                        '<b>City: </b>' + additionalInfo.city + '<br />' +
                        '<b>Zip code: </b>' + additionalInfo.zip + '</div>';

                    infoWindow = new window.google.maps.InfoWindow({
                        content: template,
                        position: event.latLng
                    });

                    infoWindow.open($scope.googleMap.map);
                });
            };

            $scope.googleMap.getBounds = function (points) {
                var bounds = new window.google.maps.LatLngBounds();
                for (var p = 0; p < points.length; p++) {
                    bounds.extend(points[p]);
                }

                return bounds;
            };

            $scope.googleMap.setCenter = function (bounds) {
                if ($scope.googleMap.map) {
                    $scope.googleMap.map.fitBounds(bounds);
                }
            };

            $scope.googleMap.init = function (callback) {

                var mapOptions = {
                    zoom: 7,
                    center: new window.google.maps.LatLng(41.742295, -73.355499),
                    mapTypeId: window.google.maps.MapTypeId.ROADMAP,
                    mapTypeControl: false,
                    navigationControl: false,
                    streetViewControl: false,
                    fullscreenControl: true,
                    minZoom: 3,
                    scrollwheel: false,
                    styles: [
                        {
                            "featureType": "poi",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.business",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#bad294"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "poi.park",
                            "elementType": "labels.text",
                            "stylers": [
                                {
                                    "color": "#4c9948"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "visibility": "simplified"
                                }
                            ]
                        },
                        {
                            "featureType": "road.highway",
                            "elementType": "labels",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#ffffff"
                                }
                            ]
                        },
                        {
                            "featureType": "road.arterial",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry.fill",
                            "stylers": [
                                {
                                    "color": "#fbfbfb"
                                }
                            ]
                        },
                        {
                            "featureType": "road.local",
                            "elementType": "geometry.stroke",
                            "stylers": [
                                {
                                    "visibility": "off"
                                }
                            ]
                        },
                        {
                            "featureType": "transit",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "visibility": "on"
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "all",
                            "stylers": [
                                {
                                    "color": "#a5d7e0"
                                }
                            ]
                        }
                    ]
                };

                if ($scope.googleMap.styles) {
                    mapOptions.styles = $scope.googleMap.styles;
                }

                $scope.googleMap.map = new window.google.maps.Map($element[0], mapOptions);

                if (callback) {
                    $scope.$apply(callback);
                }
            };

            $scope.googleMap.init();
        }]
    };
}]);