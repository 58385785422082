import angular from "angular";
import dialog from "../../../user-autocomplete/dialog";

const changeCustomerAction = {
    template: require("./booking-customer-change-action.html"),
    require: {
        parent: '^vuiBookingCustomer'
    },
    bindings: {
        title: '@vuiCaTitle'
    },

    controller: function ($mdDialog) {
        'ngInject';

        this.change = (event) => {
            $mdDialog.show({
                targetEvent: event,
                template: `<vui-user-autocomplete-dialog vui-uad-filter="{role: 'USER'}" vui-uad-title="Search customer"></vui-user-autocomplete-dialog>`,
                autoWrap: false
            }).then((user) => {
                if (!user) {
                    return;
                }

                this.parent.model = {
                    user: {
                        id: user.id,
                        name: user.name,
                        surname: user.surname,
                        avatar: {
                            thumbnail: user.image
                        }
                    }
                };
                this.parent.submit();
            });
        };
    }
};

export default angular.module("vui-booking-customer-actions.module", [dialog.name])
    .component("vuiBcChangeAction", changeCustomerAction);