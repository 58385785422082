import app from "js/legacy-app";

app.controller('SelectBikesForFixDialogCtrl', ['$scope', '$mdDialog', 'ModalService', 'bikes', '$pageFooter',
    function ($scope, $mdDialog, ModalService, bikes, $pageFooter) {

        $scope.bff = {
            bikes: bikes
        };

        $scope.bff.submit = function (form) {
            const selectedBikes = $scope.bff.bikes
                .filter(bike => bike.selected)
                .map(bike => bike.id)
            $mdDialog.hide(selectedBikes);
        };

        $scope.bff.close = function () {
            $mdDialog.hide();
        };

        (function init() {
            $pageFooter.hide();
        })();
    }
]);
