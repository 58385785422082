import app from "js/legacy-app";
import angular from "angular";

app.controller('ImgUploadDialogCtrl',
    function ($scope, $mdDialog, AuthService, Notification, $mdMedia, ToggleLD, FileService, documents,
              validationOptions, choosePhotosBtnText, multiple, maxFiles, title, extensions, imageSize, $mobileBodyHeightCalculator) {
        'ngInject';

        $scope.upload = {
            documents: documents,
            images: {},
            validationOptions: validationOptions || {},
            choosePhotosBtnText: choosePhotosBtnText || "Upload photos",
            title: title || 'Add photos',
            extensions: extensions || '.jpg,.jpeg,.png,.bmp,.gif',
            multiple: angular.isUndefined(multiple) ? true : multiple,
            maxFiles: maxFiles || 10,
            imageSize: imageSize || $mdMedia('max-width: 470px') ? {width: 120, height: 120} : {width: 140, height: 140}
        };

        $scope.iudc = {};

        $scope.iudc.cancel = function () {
            var imgContainer = $scope.upload.images;
            var filtered = imgContainer.images.filter(function (image) {
                return !image.failed;
            });

            if (filtered.length === 0) {
                $mdDialog.hide();
                return;
            }

            var imagesToRemove = filtered.map(function (item) {
                return {
                    id: item.id
                };
            });

            var removeRequest = {
                images: imagesToRemove
            };

            $scope.upload.documents.removeAllTemp(removeRequest);
            $mdDialog.hide();
        };

        $scope.iudc.done = function () {
            var filtered = $scope.upload.images.images.filter(function (image) {
                return !image.failed;
            });

            if (filtered.length === 0) {
                $mdDialog.hide();
                return;
            }

            ToggleLD.show();

            var imagesToUpload = filtered.map(function (item) {
                return {
                    id: item.id
                };
            });

            var uploadRequest = {
                images: imagesToUpload
            };

            $scope.upload.documents.upload(uploadRequest).then(function (response) {
                ToggleLD.hide();
                $mdDialog.hide(response.data.images);
            }, function (error) {
                ToggleLD.hide();
                Notification.error("Failed to upload");
            });
        };

        $scope.iudc.bodyHeight = function () {
            var header = document.getElementsByClassName('img-upload-dialog__mobile-header')[0];
            var footer = document.getElementsByClassName('img-upload-dialog__mobile-footer')[0];
            return $mobileBodyHeightCalculator.calculate(header, footer);
        };
    });