import angular from "angular";
import imageViewerActions from "./image-viewer-actions";
import "./image-viewer.scss";

const component = {
    template: require("./image-viewer.html"),
    bindings: {
        images: "<vuiIvImages",
        readonly: "<vuiIvReadonly",
        currentImageIdx: "<vuiIvCurrentImageIndex",
        documents: "<vuiIvDocuments"
    },
    transclude: {
        actions: "?vuiImageViewerActions"
    },

    controller: function ($pageScroll, $mdDialog) {
        'ngInject';

        this.$onInit = () => {
            $pageScroll.hide();
        };

        this.$onDestroy = () => {
            $pageScroll.show();
        };

        this.close = () => {
            $mdDialog.hide();
        };

        this.next = () => {
            if (this.images.length !== 1) {
                this.currentImage = {};
            }

            if (this.currentImageIdx + 1 === this.images.length) {
                this.currentImageIdx = 0;
            } else {
                this.currentImageIdx++;
            }

            setupCurrentImage();
        };

        this.prev = () => {
            if (this.images.length !== 1) {
                this.currentImage = {};
            }

            if (this.currentImageIdx === 0) {
                this.currentImageIdx = this.images.length - 1;
            } else {
                this.currentImageIdx--;
            }

            setupCurrentImage();
        };

        this.isPaged = () => {
            return this.images.length > 1;
        };

        this.totalImagesSize = () => {
            return this.images.length;
        };

        this.onRemove = () => {
            this.images.splice(this.currentImageIdx, 1);

            if (this.images.length === 0) {
                this.close();
                return;
            }

            this.currentImageIdx = this.currentImageIdx === 0 ? 0 : this.currentImageIdx--;

            if (this.currentImageIdx === this.images.length) {
                this.currentImageIdx = 0;
            }

            setupCurrentImage();
        };

        const setupCurrentImage = () => {
            this.currentImage = this.images[this.currentImageIdx];
        };

        setupCurrentImage();
    }
};

export default angular.module("vui-image-viewer.module", [imageViewerActions.name])
    .component("vuiImageViewer", component);