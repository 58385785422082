import app from "js/legacy-app";

app.service('$wsService', function () {
    'ngInject';

    let vtSockets = {};

    let add = (socket) => {
        let ws = socket.ws;
        let url = ws.url;
        if (!vtSockets[url]) {
            vtSockets[url] = socket;
        }
    };

    let remove = (socket) => {
        if (!socket) {
            return;
        }

        let url = socket.url;
        delete vtSockets[url];
    };

    let exists = (socket) => {
        if (!socket) {
            return false;
        }

        let url = socket.url;
        return !!vtSockets[url];
    };

    let closeAll = () => {
        for (const property in vtSockets) {
            if (!vtSockets[property]) {
                continue;
            }

            vtSockets[property].close();
            delete vtSockets[property];
        }

        vtSockets = {};
    };

    return {
        add: add,
        remove: remove,
        exists: exists,
        closeAll: closeAll
    }
});
