import app from "js/legacy-app";

app.directive('showSnButton', [function () {
    return {
        template: '<div class="add-buttons-fab serial-number-button">' +
        '   <md-fab-trigger>' +
        '       <md-button aria-label="Info" ng-click="showSerialNumber($event)" class="md-fab addon show-error" title="Info">' +
        '           <md-icon md-font-set="fa fa-info-circle hint"></md-icon>' +
        '       </md-button>' +
        '   </md-fab-trigger>' +
        '</div>',
        controller: ['$scope', 'ModalService', function ($scope, ModalService) {
            $scope.showSerialNumber = function ($event) {
                ModalService.showBikeSerialNumber($event);
            };
        }]
    };
}]);