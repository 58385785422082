import angular from "angular";

import "oclazyload";
import BikeBrandModule from "./modules/bike-brand/bike-brand.module";
import MechanicVerificationModule from "./modules/mechanic-verification/mechanic-verification.module";
import MechanicListModule from "./modules/mechanic-list/mechanic-list.module";
import AppMarketplaceModule from "./modules/app-marketplace/app-marketplace.module";
import OrganisationModule from "./modules/organisation/organisation.module";

export default angular
    .module("vt-admin-app", [
        BikeBrandModule.name,
        MechanicVerificationModule.name,
        MechanicListModule.name,
        AppMarketplaceModule.name,
        OrganisationModule.name
    ]);
