import app from "js/legacy-app";

app.controller('RateMechanicDialogController', ['$scope', '$mdDialog', 'negotiation', '$mobileBodyHeightCalculator', '$pageFooter',
    function ($scope, $mdDialog, negotiation, $mobileBodyHeightCalculator, $pageFooter) {

        $scope.rateMechanicDialog = {
            rating: {
                overallService: 5,
                punctuality: 5,
                helpfulness: 5,
                appearance: 5,
                comment: ''
            },
            negotiation: negotiation
        };

        $scope.rateMechanicDialog.submit = function (form) {
            if (form.$valid) {
                $mdDialog.hide($scope.rateMechanicDialog.rating);
            }
        };

        $scope.rateMechanicDialog.close = function () {
            $mdDialog.hide();
        };

        $scope.rateMechanicDialog.openMenu = function (mdMenu, event) {
            mdMenu.open(event);
        };

        $scope.rateMechanicDialog.unsatisfied = function (form) {
            if (form.$valid) {
                var rateMechanic = {
                    rating: {
                        overallService: 1,
                        punctuality: 1,
                        helpfulness: 1,
                        appearance: 1,
                        comment: '',
                        status: 'UNSATISFIED'
                    },
                    negotiation: negotiation
                };

                if (!$scope.rateMechanicDialog.rating.comment || $scope.rateMechanicDialog.rating.comment === '') {
                    rateMechanic.rating.comment = 'I am not happy with a bicycle mechanic';
                } else {
                    rateMechanic.rating.comment = $scope.rateMechanicDialog.rating.comment;
                }

                $mdDialog.hide(rateMechanic.rating);
            }
        };

        $scope.rateMechanicDialog.bodyHeight = function () {
            var footer = document.getElementsByClassName('request-rate-dialog__mobile-footer')[0];
            var header = document.getElementsByClassName('request-rate-dialog__mobile-header')[0];
            return $mobileBodyHeightCalculator.calculate(header, footer);
        };

        (function init() {
            $pageFooter.hide();
        })();
    }]);
