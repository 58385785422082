import app from "js/legacy-app";

app.service('$pageFooter',
    ['$rootScope', '$timeout', '$location', '$mdMedia',
        function ($rootScope, $timeout, $location, $mdMedia) {

            var pagesWithoutFooter = [
                {
                    path: "/create-request",
                    media: "(max-width: 1000px)"
                },
                {
                    path: "/mechanics",
                    media: "(max-width: 1100px)"
                },
                {
                    path: "/maintenance"
                },
                {
                    path: '/add-card',
                    mobileOnly: true
                }
            ];

            $rootScope.$on('$locationChangeSuccess', function () {
                $timeout(function () {
                    var filtered = pagesWithoutFooter.filter(function (p) {
                        return $location.path().startsWith(p.path);
                    }).filter(function (p) {
                        if (p.mobileOnly) {
                            return $mdMedia('(max-width: 470px)');
                        } else if (p.media) {
                            return $mdMedia(p.media);
                        }
                        return p;
                    });
                    if (filtered.length > 0) {
                        hide();
                    } else {
                        show();
                    }
                });
            });


            var hide = function () {
                $rootScope.noFooter = true;
            };

            var show = function () {
                $rootScope.noFooter = false;
            };

            return {
                hide: hide,
                show: show
            };
        }
    ]);

