import app from "js/legacy-app";

app.controller('CoreServiceListCtrl',
    function ($scope, CoreServiceManagement, CoreServiceCategories, CoreServiceBuckets, $location) {
        'ngInject';

        $scope.$parent.smc.selectedTab = 0;

        $scope.tlc = {

            adminsOnly: true,

            search: function (query) {
                return CoreServiceManagement.get(query).$promise;
            },

            view: function (item) {
                $location.url("/admin/view-service/" + item.id);
            },

            filter: {
                category: {
                    search: function (query) {
                        return CoreServiceCategories.autocomplete({
                            name: query,
                            type: $scope.pageableList.filter.type
                        }).$promise;
                    }
                },

                serviceBucket: {
                    options: []
                }
            }
        };

        (function init() {
            CoreServiceBuckets.get({size: 100}, function (res) {
                $scope.tlc.filter.serviceBucket.options = [{val: null, display: 'All'}].concat(res.data.map((sb) => {
                    return {val: sb.type, display: sb.name};
                }));

            });
        })();
    }
);
