import app from "js/legacy-app";

app.service('OrganisationServiceManagement', ['$resource', function ($resource) {
    return $resource('/api/admin-organisation/:id/custom-services/:id1/:id2', {}, {
        patch: {
            method: "PUT",
            params: {id1: ""},
            isArray: false
        },
        updateActivityFlag: {
            method: "PUT",
            params: {id2: "activity"},
            isArray: false
        },
        tree: {
            method: "GET",
            params: {id1: "tree"},
            isArray: false,
        },
        resetDefault: {
            method: "PUT",
            params: {id2: "reset-default"},
            isArray: false,
        }
    });
}]);
