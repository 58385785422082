import app from "js/legacy-app";

app.directive('editBikeComponents', [function () {
    return {
        templateUrl: "template/dashboard/bikes/show-bike/tabs/info/additional-components.html",
        scope: {
            bike: '=editBikeComponents',
            isCanEditComponent: '&isCanEditComponent'
        },
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: ['$scope', '$bikeComponentService', 'AuthService', 'Brand',
            function ($scope, $bikeComponentService, AuthService, Brand) {
                $scope.bike.loadComponents = function() {
                    if (!$scope.bike.components) {
                        $bikeComponentService.getBikeComponents($scope.bike.id, $scope.bike);
                    }
                };

                $scope.currentUserIsMechanic = function () {
                    return AuthService.isMechanic();
                };

                $scope.saveAdditionalDescription = function (form) {
                    $bikeComponentService.saveAdditionalDescription({
                        bike: $scope.bike,
                        form: form
                    });
                };

                if ($scope.isCanEditComponent && $scope.isCanEditComponent()) {

                    $scope.disabledAdditionalDescription = true;

                    $scope.getBrands = function (brand) {
                        return Brand.autocomplete({name: brand, page: 0, size: 10}).$promise;
                    };

                    $scope.getComponents = function (searchText) {
                        return $bikeComponentService.getComponents($scope, searchText);
                    };

                    $scope.addComponent = function () {
                        $bikeComponentService.addComponent($scope.bike.components);
                    };

                    $scope.isSaleCertificate = true;
                }
            }]
    };
}]);