import app from "js/legacy-app";
import angular from 'angular';

app.directive('manageServiceStateAction', [
    function () {
        return {
            templateUrl: "components/service/actions/manage-service-state-action.html",
            replace: true,
            scope: {
                model: "=mssaModel",
                onChangeState: "&mssaOnChangeState"
            },
            controller: ['$scope', '$routeParams', 'AuthService', 'CustomServiceManagement', 'OrganisationServiceManagement', 'ToggleLD', 'ErrorHandler',
                function ($scope, $routeParams, AuthService, CustomServiceManagement, OrganisationServiceManagement, ToggleLD, ErrorHandler) {
                    $scope.mssac = {};

                    $scope.mssac.onStateChange = function (event) {
                        if ($scope.mssac.enabledService) {
                            $scope.model.serviceState = 'ENABLED';
                        } else {
                            $scope.model.serviceState = 'DISABLED';
                        }

                        ToggleLD.show();
                        if (AuthService.isMechanic()) {
                            CustomServiceManagement.updateActivityFlag({id: $scope.model.id}, {value: $scope.model.serviceState},
                                function (data) {
                                    ToggleLD.hide();
                                    if (angular.isFunction($scope.onChangeState)) {
                                        $scope.onChangeState();
                                    }
                                }, function (error) {
                                    ToggleLD.hide();
                                    ErrorHandler.onError(error);
                                });
                        } else {
                            const params = {id: $routeParams.organisationId, id1: $scope.model.id};
                            OrganisationServiceManagement.updateActivityFlag(params, {value: $scope.model.serviceState},
                                function (data) {
                                    ToggleLD.hide();
                                    if (angular.isFunction($scope.onChangeState)) {
                                        $scope.onChangeState();
                                    }
                                }, function (error) {
                                    ToggleLD.hide();
                                    ErrorHandler.onError(error);
                                });
                        }
                    };

                    (function init() {
                        if ($scope.model.state === 'ENABLED') {
                            $scope.mssac.enabledService = true;
                        }
                        if ($scope.model.state === 'DISABLED') {
                            $scope.mssac.enabledService = false;
                        }
                    })();
                }]
        };
    }]);
