import app from "js/legacy-app";

app.directive('vtStepWrapper', [function () {
    return {
        templateUrl: "components/wizard/wizard-step-wrapper.html",
        replace: true,
        scope: {
            options: "=wsOptions"
        },
        link: function (scope, elem, attrs, wc) {

        }
    };
}]);