export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/admin/create-bicycle-brand', 'admin-bike-brand-create')
        .when('/admin/bicycle-brand/:brandId', 'admin-bike-brand-view')
        .when('/admin/bicycle-brands', 'admin-bike-brand-list')

        .segment('admin-bike-brand-create', {
            access: {requireLogin: false},
            template: '<bike-brand-create-page></bike-brand-create-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "bike-brand-create.module" */ "./bike-brand-create/bike-brand-create.module");
            }
        })
        .segment('admin-bike-brand-view', {
            access: {requireLogin: false},
            template: '<bike-brand-view-page></bike-brand-view-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "bike-brand-view.module" */ "./bike-brand-view/bike-brand-view.module");
            }
        })
        .segment('admin-bike-brand-list', {
            access: {requireLogin: false},
            template: '<bike-brand-list></bike-brand-list>',
            loadModule: () => {
                return import(/* webpackChunkName: "bike-brand-list.module" */ "./bike-brand-list/bike-brand-list.module");
            }
        });

}
