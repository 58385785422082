import "./landing-page-desc-block.component.scss"

export default {
    template: require('./landing-page-desc-block.component.html'),
    bindings: {
        color: "@?dotColor",
        needHide: "@?hideBodyIsSmallContent",
        theme: "@?lpTheme"
    },
    transclude: {
        title: "?lpDescBlockTitle",
        body: "?lpDescBlockBody",
        action: "?lpDescBlockAction"
    },
    controller: function () {

        'ngInject';

        this.dotColor = 'landing-page-desc-block__dot-' + this.color;
        this.bodyClass = this.needHide ? 'hidden-sm hidden-xs' : '';
        this.themeClass = this.theme === 'dark' ? 'landing-page-desc-block__theme-dark' : 'landing-page-desc-block__theme-light';
    }
};
