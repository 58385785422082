import angular from "angular";
import serviceAutocomplete from "../index";

const component = {
    template: require("./service-autocomplete-dialog.html"),
    bindings: {
        filter: '<?vuiSadFilter',
        title: '@?vuiSadTitle'
    },
    controller: function ($mdDialog) {
        'ngInject';

        this.$onInit = () => {
            this.title = this.title || "Search service";
        };

        this.select = (service) => {
            $mdDialog.hide(service);
        };

        this.cancel = () => {
            $mdDialog.hide();
        };
    }

};

export default angular.module("vui-service-autocomplete-dialog.module", [serviceAutocomplete.name])
    .component("vuiServiceAutocompleteDialog", component);