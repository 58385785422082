import "./landing-page-scrolling-list.component.scss";

export default {
    template: require('./landing-page-scrolling-list.component.html'),
    bindings: {
        items: "=",
        cols: "@?cols"
    },
    controller: function () {
        'ngInject';

        this.itemClass = 'landing-page-scrolling-list__item--col-' + (this.cols ? this.cols : 3);
    }
};
