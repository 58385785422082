import app from "js/legacy-app";
import angular from 'angular';

app.component('vtSwitchButton', {
    templateUrl: "components/switch-button/switch-button.html",
    bindings: {
        model: "<vtSbModel",
        enabledText: "@vtSbEnabledText",
        disabledText: "@vtSbDisabledText",
        state: "<vtSbInitialState",
        onChange: "&vtSbOnChange"
    },
    controller: function () {
        'ngInject';

        const self = this;

        self.onStateChange = () => {
            if (angular.isFunction(self.onChange)) {
                this.onChange({model: self.model, state: self.state});
            }
        }

    }
});
