export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/', 'home-page')
        .when('/home', 'home-page')
        .when('/confirm-registration', 'home-page')
        .when('/cancel-registration', 'home-page')
        .when('/for-bike-brands', 'bike-brand-landing-page')
        .when('/for-bike-fleets', 'bike-fleet-landing-page')
        .when('/bicycle-assembly', 'bicycle-assembly-landing-page')
        .when('/diagnostic-and-repair', 'allstate-landing-page')
        .when('/nbda', 'nbda-landing-page')

        .segment('home-page', {
            access: {requireLogin: false},
            template: '<home-landing-page></home-landing-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "home-landing.module" */ "./home-landing/home-landing.module");
            }
        })
        .segment('bike-brand-landing-page', {
            access: {requireLogin: false},
            template: '<bike-brand-landing-page></bike-brand-landing-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "bike-brand-landing.module" */ "./bike-brand-landing/bike-brand-landing.module");
            }
        })
        .segment('bike-fleet-landing-page', {
            access: {requireLogin: false},
            template: '<bike-fleet-landing-page></bike-fleet-landing-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "bike-fleet-landing.module" */ "./bike-fleet-landing/bike-fleet-landing.module");
            }
        })
        .segment('allstate-landing-page', {
            access: {requireLogin: false},
            template: '<allstate-landing-page></allstate-landing-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "allstate-landing.module" */ "./allstate-landing/allstate-landing.module");
            }
        })
        .segment('bicycle-assembly-landing-page', {
            access: {requireLogin: false},
            template: '<bicycle-assembly-landing-page></bicycle-assembly-landing-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "bicycle-assembly.module" */ "./bicycle-assembly/bicycle-assembly.module");
            }
        })
        .segment('nbda-landing-page', {
            access: {requireLogin: false},
            template: '<nbda-landing-page></nbda-landing-page>',
            loadModule: () => {
                return import(/* webpackChunkName: "nbda.module" */ "./nbda-landing/nbda-landing.module");
            }
        })
    ;

}
