import angular from "angular";
import actions from "./bicycle-parts-actions";

const component = {
    template: require('./bicycle-parts.html'),
    bindings: {
        model: "<vuiBpModel",
        bikeId: "@?vuiBpBikeId",
        onUpdate: '&vuiBpOnUpdate'
    },
    transclude: {
        title: "vuiBicyclePartsTitle",
        actions: "vuiBicyclePartsActions"
    },
    controller: function () {
        'ngInject';


        this.$onInit = () => {
            this.parts = {
                showMore: false,
                animate: "0.3",
                minHeight: 158
            };
            this.model = this.model || [];
        };

        this.submit = () => {
            if (angular.isFunction(this.onUpdate)) {
                this.onUpdate({bikeId: this.bikeId, parts: this.model});
            }
        };

        this.addNewPart = (part) => {
            this.model.push(part);
            this.submit();
        };
    }
};

export default angular.module("vui-bicycle-parts.module", [actions.name])
    .component("vuiBicycleParts", component);