import angular from "angular";
import imageSlider from "../../../../vt-generic/components/image-slider";
import bookingNotesImageViewerDialog from './booking-notes-image-viewer-dialog.html';

const component = {
    template: require('./booking-notes-card.html'),
    bindings: {
        onSubmit: "&?bnOnSubmit",
        notes: "<?bookingNotes"
    },
    controller: function (Documents) {
        "ngInject";

        this.$onInit = () => {
            this.notes = this.notes || {
                photos: []
            };
            this.documents = Documents.requestImage();
            this.bookingNotesImageViewerDialog = bookingNotesImageViewerDialog;
        };

        this.submit = function () {
            const ctrl = this;

            if (angular.isFunction(ctrl.onSubmit)) {
                ctrl.onSubmit({notes: ctrl.notes});
            }
        };

        this.onUploadImages = (images) => {
            this.notes.photos = [...images];
            this.submit();
        };

        this.onChangeDescription = () => {
            this.submit();
        };
    }
};

export default angular.module("booking-notes", [imageSlider.name])
    .component('bookingNotes', component);
