import app from "js/legacy-app";

app.controller('CheckInsuranceDialogController', ['$scope', '$mdDialog', 'insurance', 'Insurance', 'Notification',
    function ($scope, $mdDialog, insurance, Insurance, Notification) {

        $scope.updateInsurance = {};
        $scope.updateInsurance.insurance = insurance;
        if (!$scope.updateInsurance.insurance.registrationDate) {
            $scope.updateInsurance.insurance.registrationDate = new Date();
        } else {
            $scope.updateInsurance.insurance.registrationDate = new Date($scope.updateInsurance.insurance.registrationDate);
        }
        if (!$scope.updateInsurance.insurance.expirationDate) {
            $scope.updateInsurance.insurance.expirationDate = new Date();
        } else {
            $scope.updateInsurance.insurance.expirationDate = new Date($scope.updateInsurance.insurance.expirationDate);
        }

        Insurance.getTypes(function(data) {
            $scope.updateInsurance.types = data;
        });

        $scope.close = function() {
            $mdDialog.hide();
        };

        $scope.update = function(form) {
            if (form.$valid) {
                if ($scope.updateInsurance.insurance.registrationDate.getTime() >=
                    $scope.updateInsurance.insurance.expirationDate.getTime()) {
                    Notification.error("Expiration date must be more then registration date");
                    return;
                }

                $mdDialog.hide($scope.updateInsurance.insurance);
            }
        };

    }
]);