import app from "js/legacy-app";

app.directive('vtCompare', function () {
    return {
        require: 'ngModel',
        scope: {
            vtCompare: '='
        },
        link: function (scope, elem, attr, ngModel) {
            //For DOM -> model validation
            ngModel.$parsers.unshift(function (value) {
                var valid = scope.vtCompare === value;
                ngModel.$setValidity('vtCompare', valid);
                return valid ? value : undefined;
            });

            //For model -> DOM validation
            ngModel.$formatters.unshift(function (value) {
                ngModel.$setValidity('vtCompare', scope.vtCompare === value);
                return value;
            });

            scope.$watch("vtCompare", function (newValue, oldValue) {
                ngModel.$setValidity('vtCompare', scope.vtCompare === ngModel.$viewValue);
                ngModel.$formatters.unshift();
                ngModel.$parsers.unshift();
                ngModel.$validate();
            });
        }
    };
});

