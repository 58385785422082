// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/img/icons/error.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<md-dialog aria-label=\"error dialog\" class=\"vt-modal error-dialog\">\n\n    <div class=\"vt-modal__header error-dialog__header\">Sorry, something went wrong</div>\n\n    <div class=\"vt-modal__header error-dialog__mobile-header\">\n        <div class=\"display-inline-block\">Sorry, something went wrong</div>\n        <div class=\"display-inline-block right margin-top-5 margin-bottom-5\">\n            <i class=\"vt-ui-icon vt-ui-icon-close\" data-ng-click=\"edc.close()\"></i>\n        </div>\n    </div>\n\n    <form name=\"confirmationDialogForm\" novalidate>\n\n        <div class=\"vt-modal__body error-dialog__body\">\n            <div class=\"error-dialog__image\">\n                <img class=\"error-dialog__image-icon\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/>\n            </div>\n\n            <div class=\"error-dialog__title\" data-ng-if=\"edc.errorMessages.length > 1\">\n                {{ edc.title }}\n            </div>\n\n            <div class=\"error-dialog__title tw-text-center\" data-ng-if=\"edc.errorMessages.length === 1\">\n                {{ edc.errorMessages[0] }}\n            </div>\n\n            <div class=\"error-dialog__text\" data-ng-if=\"edc.errorMessages.length > 1\">\n                <ul>\n                    <li data-ng-repeat=\"error in edc.errorMessages\">\n                        {{ error }}\n                    </li>\n                </ul>\n            </div>\n        </div>\n\n        <div class=\"vt-modal__footer error-dialog__footer\">\n            <div class=\"align-right\">\n                <md-button type=\"button\" ng-click=\"edc.close()\" aria-label=\"close\">Close</md-button>\n            </div>\n        </div>\n    </form>\n</md-dialog>";
// Exports
var _module_exports = code;;
var path = 'components/error/error-dialog.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;