import app from "js/legacy-app";

app.directive('serverValidationError', [function () {
    return {
        restrict: 'A',
        require: '^^form',
        link: function (scope, element, attrs, form) {
            var fieldName = attrs.serverValidationError;

            var elementName = element[0].localName;
            if (elementName === 'md-select') {
                return element.on('click', function (event) {
                    return scope.$apply(function () {
                        form[fieldName].validationError = null;
                        return form[fieldName].$setValidity('validation', true);
                    });
                });
            } else
                return element.on('change', function (event) {
                    return scope.$apply(function () {
                        form[fieldName].validationError = null;
                        return form[fieldName].$setValidity('validation', true);
                    });
                });
        }
    };
}]);