import app from "js/legacy-app";
import angular from "angular";

app.component('vtUserAutocomplete', {
        templateUrl: "components/autocomplete/user/user-autocomplete.component.html",
        bindings: {
            onSelect: '&?vtUaOnSelect',
            filter: '<?vtUaFilter'
        },
        controller: function (User, $q) {
            'ngInject';

            this.$onInit = () => {
                this.filter = this.filter || {};
            };

            this.select = (user) => {
                if (angular.isFunction(this.onSelect)) {
                    this.onSelect({user: user});
                }
            };

            this.search = () => {
                let deferred = $q.defer();
                User.findByNameLike(this.filter, function (response) {
                    deferred.resolve(response.items);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            };

            this.displayItem = function (item) {
                return item.name + " " + item.surname;
            };
        }
    }
);
