import app from "js/legacy-app";

app.service('$serviceManagement', ['CoreServiceManagement', 'CustomServiceManagement', 'AuthService',
    function (CoreServiceManagement, CustomServiceManagement, AuthService) {

        var getServiceResource = function () {
            if (AuthService.isAdmin()) {
                return CoreServiceManagement;
            }
            if (AuthService.isMechanic()) {
                return CustomServiceManagement;
            }

            return CustomServiceManagement;
        };

        return {
            getServiceResource: getServiceResource
        };
    }
]);

