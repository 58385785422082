import app from "js/legacy-app";

app.config(['$provide', function ($provide) {
    $provide.decorator('mdDatepickerDirective', ['$delegate', '$pageScroll',
        function ($delegate, $pageScroll) {

            var directive = $delegate[0];
            var link = directive.link;

            directive.compile = function () {
                return function (scope, element, attrs) {
                    link.apply(this, arguments);
                    scope.$watch('ctrl.isCalendarOpen', function (newValue, oldValue) {
                        if (newValue && !oldValue) {
                            $pageScroll.hide();
                        } else if (!newValue && oldValue) {
                            $pageScroll.show();
                        }
                    });
                };
            };

            return $delegate;
        }
    ]);
}]);