import app from "js/legacy-app";
import angular from "angular";

app.service('$requestService',
    function (Notification, $requestStorage, $dateTimeService, UserRequest, AuthService, SupervisorRequest,
              MechanicRequest, AdminRequest, OrganizationRequest, $routeParams, $pageableList, $location) {

        'ngInject';

        let getRequestResource = function () {
            let resource = UserRequest;
            if (AuthService.isBikeBusinessSupervisor()) {
                resource = SupervisorRequest;
            } else if (AuthService.isBikeOrganisationOwner()) {
                resource = OrganizationRequest;
            } else if (AuthService.isMechanic()) {
                resource = MechanicRequest;
            } else if (AuthService.isAdmin()) {
                resource = AdminRequest;
            }

            return resource;
        };

        let getTips = [
            {label: 'Tip $1', value: 1},
            {label: 'Tip $2', value: 2},
            {label: 'Tip $3', value: 3},
            {label: 'Tip $5', value: 5},
            {label: 'Tip $10', value: 10}
        ];

        let getTimeOptions = {
            floor: 700,
            ceil: 2300,
            step: (100 / 12),
            translate: $dateTimeService.translateTime,
            minRange: (1500 / 60)
        };

        let saveDraftRequestToLocalStorage = function (request) {
            let bcrc = request.bcrc;
            let mechanicsStep = request.mechanicsStep;
            let bicyclesStep = request.bicyclesStep;
            let availabilityStep = request.availabilityStep;

            let data = {
                routeParams: $routeParams
            };

            data.selectedTime = availabilityStep.selectedTime;
            data.selectedMechanic = mechanicsStep.selectedMechanic;
            data.selectedMechanicService = mechanicsStep.service;
            data.selectedService = bcrc.services.selected;
            data.mechanicId = bcrc.mechanicId;
            data.organisationId = bcrc.organisationId;
            data.stationId = bcrc.stationId;
            data.bikeId = bcrc.bikeId;
            data.taskId = bcrc.taskId;
            data.taskCode = bcrc.taskCode;
            data.disabledSteps = bcrc.disabledSteps;
            data.completedSteps = bcrc.completedSteps;
            data.request = bcrc.request;
            data.totalPrice = bcrc.totalPrice;
            data.appointmentDates = bcrc.appointmentDates;
            data.serviceLocation = bcrc.serviceLocation;
            data.onlineOnly = request.onlineOnly;
            data.locationOffset = bcrc.locationOffset;
            data.disabledSubmitLocationForm = bcrc.disabledSubmitLocationForm;
            data.zip = bcrc.zip;
            data.requestCreatorId = request.userId;
            data.newBikes = bicyclesStep.newBikes;
            data.concreteBike = bicyclesStep.concreteBike;
            data.selectedBikes = bicyclesStep.selectedBikes;
            data.oldBikes = bicyclesStep.oldBikes;
            $requestStorage.putDraftRequest(data);
        };

        let toggleWatchers = function (scope) {
            scope.taskStep.onServiceChange();
            scope.mechanicsStep.onServiceChange();
            scope.mechanicsStep.onLocationChange();
            scope.availabilityStep.toggleWatchers();
            scope.bicyclesStep.toggleWatchers();
        };

        let parseDraftedRequestFromLocalStorage = function ($scope, draftRequestJson) {
            let draftRequest = draftRequestJson;
            toggleWatchers($scope);

            let requestCreatorId = $scope.bcrc.requestCreatorId;
            if (!requestCreatorId && !AuthService.isUser()) {
                toggleWatchers($scope);
                return;
            }

            if (requestCreatorId && AuthService.user().id !== requestCreatorId) {
                toggleWatchers($scope);
                return;
            }

            $scope.bcrc.services.selected = draftRequest.selectedService;
            $scope.bcrc.mechanicId = draftRequest.mechanicId;
            $scope.bcrc.organisationId = draftRequest.organisationId;
            $scope.bcrc.stationId = draftRequest.stationId;
            $scope.bcrc.bikeId = draftRequest.bikeId;
            $scope.bcrc.taskId = draftRequest.taskId;
            $scope.bcrc.taskCode = draftRequest.taskCode;
            $scope.availabilityStep.selectedTime = draftRequest.selectedTime;
            $scope.bcrc.request = draftRequest.request;
            $scope.onlineOnly = draftRequest.onlineOnly;
            $scope.bcrc.totalPrice = draftRequest.totalPrice;
            $scope.bcrc.title = draftRequest.title;
            $scope.bcrc.disabledSave = draftRequest.disabledSave;
            $scope.bcrc.locationOffset = draftRequest.locationOffset;
            $scope.bcrc.disabledSubmitLocationForm = draftRequest.disabledSubmitLocationForm;
            $scope.bcrc.serviceLocation = draftRequest.serviceLocation;
            $scope.bcrc.zip = draftRequest.zip;
            $scope.bicyclesStep.newBikes = draftRequest.newBikes;
            $scope.bicyclesStep.concreteBike = draftRequest.concreteBike;
            $scope.bicyclesStep.selectedBikes = draftRequest.selectedBikes;
            $scope.bicyclesStep.oldBikes = draftRequest.oldBikes;
            $scope.bcrc.disabledSteps = draftRequest.disabledSteps;
            $scope.bcrc.completedSteps = draftRequest.completedSteps;
            $scope.bcrc.requestCreatorId = draftRequest.userId;

            angular.forEach($scope.bcrc.disabledSteps, function (step) {
                $scope.wizard.disableStep(step);
            });

            angular.forEach($scope.bcrc.completedSteps, function (step) {
                $scope.wizard.completeStep(step);
            });

            let mechanicId = $scope.bcrc.request.mechanicId;
            $pageableList.get('mechanic-list-step').refreshPage();
            $scope.mechanicsStep.applyConcreteMechanic(mechanicId, function () {
            }, function () {
                //TODO change on modal dialog
                Notification.warning("Mechanic does not provide this service any more. Please select other mechanic");
                $scope.wizard.goTo('mechanics');
            });

            $location.search(draftRequest.routeParams);

            toggleWatchers($scope);
            $requestStorage.removeDraftRequest();
        };

        return {
            getTimeOptions: getTimeOptions,
            getTips: getTips,
            saveDraftRequestToLocalStorage: saveDraftRequestToLocalStorage,
            parseDraftedRequestFromLocalStorage: parseDraftedRequestFromLocalStorage,
            getRequestResource: getRequestResource
        };
    }
);

