import app from "js/legacy-app";

app.directive('reviewAddBike', [function () {
    return {
        templateUrl: "template/dashboard/bikes/add-bike/review-add-bike.html",
        scope: {
            review: '=reviewAddBike',
            next: '&',
            countImages: '&'
        },
        controller: ['$scope', '$bikeService', '$location', 'AuthService', function ($scope, $bikeService, $location, AuthService) {

            $scope.close = $bikeService.cancelCreateBike;

            $scope.isCanEditComponent = function () {
                return false;
            };

            $scope.submit = function () {
                if (!AuthService.isLogin()) {
                    $bikeService.saveBikeToLocalStorage($scope.review);
                    $location.path('/sign-up-user');
                } else {
                    $scope.next()(5);
                }
            };
        }]
    };
}]);
