import angular from "angular";

import ButtonComponent from "./directives/button/button.component";
import InputContainerComponent from "./directives/input-container/input-container.component";
import SelectComponent from "./directives/select/select.component";
import OptionComponent from "./directives/option/option.component";
import DropdownListComponent from "./directives/dropdown-list/dropdown-list.component";
import AutocompleteComponent from "./directives/autocomplete/autocomplete.component";
import SimpleScrollbar from "./directives/scroll/simple-scrollbar.component";
import LabelComponent from "./directives/label/label.component";
import RequiredLabelComponent from "./directives/required-label/required-label.component";
import LinkComponent from "./directives/link/link.component";
import RemoveAutocomplete from "./directives/remove-autocomplete/remove-autocomplete.component";
import RemoveGoogleAnalytics from "./directives/remove-ga/remove-ga.component";
import RadioButton from "./directives/radio-button/radio-button.component";
import ContentComponent from "./components/content/content.component";
import FormComponent from "./components/form/form.component";
import FormInputGroupComponent from "./components/input-group/input-group.component";
import BackBtnComponent from "./components/back-btn/back-btn.component";
import PhoneNumberComponent from "./components/phone-number/phone-number.component";

import LocationApi from "./resources/location/location.resource";

export default angular.module("vt-shared.module", [])
    .directive("vuiButton", ButtonComponent)
    .directive("vuiInputContainer", InputContainerComponent)
    .directive("vuiSelect", SelectComponent)
    .directive("vuiOption", OptionComponent)
    .directive("vuiDropdownList", DropdownListComponent)
    .directive("vuiAutocomplete", AutocompleteComponent)
    .directive("vuiSimpleScrollbar", SimpleScrollbar)
    .directive("vuiLabel", LabelComponent)
    .directive("vuiRequiredLabel", RequiredLabelComponent)
    .directive("vuiLink", LinkComponent)
    .directive("vuiRmAutocomplete", RemoveAutocomplete)
    .directive("vuiRmGa", RemoveGoogleAnalytics)
    .directive("vuiRadioButton", RadioButton)
    .component("vuiContent", ContentComponent)
    .component("vuiForm", FormComponent)
    .component("vuiInputGroup", FormInputGroupComponent)
    .component("vuiBackBtn", BackBtnComponent)
    .component("vuiPhoneNumberInput", PhoneNumberComponent)
    .service("LocationApi", LocationApi);
