import app from "js/legacy-app";

app.controller('CoreServiceCategoryViewCtrl',
    function ($scope, $location, $routeParams, ErrorHandler, CoreServiceCategories, ToggleLD,
              ModalService, Notification, CoreServiceBuckets) {

        'ngInject';

        $scope.category = {
            id: $routeParams.id
        };

        $scope.categoryCtrl = {

            refresh: function () {
                ToggleLD.show();
                if ($scope.category.id) {
                    CoreServiceCategories.get({id: $scope.category.id}, function (c) {
                        ToggleLD.hide();
                        $scope.category = c;
                    }, function (error) {
                        ToggleLD.hide();
                        $location.path("/admin/service-management/categories");
                        ErrorHandler.onError(error, "Failed to load category details");
                    });
                }
            },

            update: function (form) {
                if (!form.$valid) {
                    return;
                }

                ToggleLD.show();
                if ($scope.category.id) {
                    CoreServiceCategories.patch({id: $routeParams.id}, this.get(), function (c) {
                        ToggleLD.hide();
                        $scope.category = c;
                        Notification.success("Category was successfully updated");
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error, "Failed to update service category", "ERROR", form);
                    });
                }
            },

            remove: function (categoryId) {
                if ($scope.category.id) {
                    var msg = 'Are you sure you want to remove \'' + $scope.category.name + '\' category?';
                    ModalService.yesNoMessage(null, '', msg, function (response) {
                        if (response === true) {
                            ToggleLD.show();
                            CoreServiceCategories.delete({id: categoryId}, function () {
                                ToggleLD.hide();
                                $location.path("/admin/service-management/categories");
                                Notification.success("Category was successfully removed");
                            }, function (error) {
                                ToggleLD.hide();
                                ErrorHandler.onError(error, "Failed to remove category");
                            });
                        }
                    });
                }
            },

            close: function () {
                $location.path("/admin/service-management/categories");
            },

            get: function () {
                var category = $scope.category;
                var dto = {};

                dto.name = category.name;
                dto.description = category.description;
                dto.serviceBucket = category.serviceBucket;
                return dto;
            }
        };

        $scope.categoryCtrl.refresh();

        (function init() {
            CoreServiceBuckets.get({size: 100}, function (res) {
                $scope.categoryCtrl.serviceBuckets = [].concat(res.data.map((sb) => {
                    return {id: sb.id, val: sb.type, display: sb.name};
                }));
            });
        })();
    }
);
