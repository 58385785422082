import app from "js/legacy-app";

app.filter("mechanicLevel", [function () {
    return function (status) {
        switch (status) {
            case 'INTERMEDIATE':
                return 'Intermediate';
            case 'EXPERT':
                return 'Expert';
            default:
                return 'N/A';
        }
    };
}]);