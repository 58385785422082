import app from "js/legacy-app";

app.service('CoreServiceBuckets', ['$resource', function ($resource) {
    return $resource('/api/core-service-buckets/:id/:id1', {}, {
        update: {
            method: "PUT",
            params: {id: ""},
            isArray: false
        },

        searchByNameLike: {
            method: "GET",
            isArray: false,
            params: {id: "search/by-name-like"}
        }
    });
}]);