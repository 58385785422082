import app from "js/legacy-app";

app.service('MechanicWorkArea', ['$resource', function($resource){
    return $resource('/api/work-areas/:id/:path/:subId', {}, {
        update: {
            method: "PATCH",
            isArray: false
        },
        view: {
            method: "GET",
            isArray: false,
            params: {path: "view"}
        },
        updateZipCodeActivity: {
            method: "PATCH",
            isArray: false,
            params: {path: "zipCode"}
        }
    });
}]);