import app from "js/legacy-app";

app.controller('ToolListCtrl', ['$scope', 'ErrorHandler', 'Tools', 'User', 'ToggleLD', '$location', '$timeout', '$q',
    function ($scope, ErrorHandler, Tools, User, ToggleLD, $location, $timeout, $q) {

        $scope.tlc = {
            search: function (query) {
                return Tools.list(query, {}).$promise;
            },

            view: function (item) {
                $location.url("/admin/view-tool/" + item.id);
            },

            filter: {
                tool: {
                    displayItem: function (item) {
                        return item.name;
                    },
                    search: function (query) {
                        var deferred = $q.defer();
                        Tools.searchByNameLike({name: query}, function (response) {
                            deferred.resolve(response.items);
                        }, function (error) {
                            deferred.reject(error);
                        });
                        return deferred.promise;
                    }
                },

                type: {
                    options: [
                        {val: null, display: 'All'},
                        {val: 'INTERMEDIATE', display: 'Intermediate'},
                        {val: 'EXPERT', display: 'Expert'}
                    ]
                }
            }
        };
    }
]);