import "./link.component.scss";

export default () => {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        template: require('./link.component.html'),
        controller: function () {
            'ngInject';
        }
    }
};
