export default {
    template: require('./landing-page-hero.component.html'),
    bindings: {
        title: "@lphTitle",
        subtitle: "@lphSubtitle"
    },
    controller: function () {
        'ngInject';

    }
};
