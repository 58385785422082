import app from "js/legacy-app";

app.controller('AddDealerDialogCtrl', ['$scope', '$routeParams', '$mdDialog', 'ToggleLD', 'PartnerDealerApi',
    'Notification', 'ErrorHandler', '$q', 'AuthService',
    function ($scope, $routeParams, $mdDialog, ToggleLD, PartnerDealerApi, Notification, ErrorHandler, $q, AuthService) {

        const organisationId = AuthService.isBikeBusinessSupervisor() ? $routeParams.organisationId : AuthService.user().companyId;

        $scope.autocomplete = {};

        $scope.close = function () {
            $mdDialog.cancel();
        };

        $scope.submitForm = function (form) {
            if (form.$valid) {
                addDealer();
            }
        };

        $scope.autocomplete.querySearch = function (query) {
            var deferred = $q.defer();
            PartnerDealerApi.autocomplete({name: query}, function (response) {
                response.items.map(item => {
                    item.label = item.name + ' ' + item.surname + ' (' + item.email + ')';
                })
                deferred.resolve(response.items);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };

        const addDealer = function () {
            const data = {};
            if ($scope.autocomplete.selectedItem) {
                data.mechanicId = $scope.autocomplete.selectedItem.id;
                data.organisationId = organisationId;
            } else {
                Notification.error("Please select a service provider");
                return;
            }
            ToggleLD.show();
            PartnerDealerApi.save({}, data, function (response) {
                $mdDialog.hide({});
                ToggleLD.hide();
                Notification.success("Dealer has been added successfully");
            }, function (error) {
                ToggleLD.hide();
                ErrorHandler.onError(error, "Failed to add dealer");
            });
        };

    }]);
