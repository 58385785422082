import "./form.component.scss";

export default {
    template: require('./form.component.html'),
    bindings: {
        submit: "=vuiSubmit",
        size: "@?vuiSize",
        back: "@?vuiBack",
        vuiShowTitle: "=vuiShowTitle"
    },
    transclude: {
        title: "?vuiFormTitle",
        body: "?vuiFormBody",
        actions: "?vuiFormActions"
    },
    controller: function () {
        'ngInject';

        const size = this.size ? this.size : 'md';
        this.formSize = 'vui-form-' + size;
        this.showTitle = this.vuiShowTitle !== false;

        if (this.back) {
            this.formSize += " vui-form__with-back"
        }
    }
};
