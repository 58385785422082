import angular from "angular";

(function () {

    'use strict';

    function UnsavedChangesTracker($location, ModalService) {

        var setup = function (scope, callback) {

            var locationUnsubscribeEvent = scope.$on("$routeChangeStart", function (event, next, current) {

                if (next === undefined) {
                    return;
                }

                var nextUrl = next.$$route.originalPath;

                if (nextUrl && callback && callback(nextUrl)) {
                    return;
                }

                event.preventDefault();

                if (countModals === 0 && !$location.search()['open-modal']) {
                    countModals++;
                    ModalService.yesNoMessage(null, '', "Are you sure you want to leave the page?", function (response) {
                        if (response === true) {
                            locationUnsubscribeEvent();
                            for (var i in scope.$$listeners.$locationChangeStart) {
                                if (scope.$$listeners.$locationChangeStart[i] == locationUnsubscribeEvent) {
                                    scope.$$listeners.$locationChangeStart.splice(i, 1);
                                }
                            }
                            locationUnsubscribeEvent = null;

                            $location.url(nextUrl);
                        }
                        countModals--;
                    });
                }
            });
        }, countModals = 0;

        return {
            setup: setup
        };
    }

    var app = angular.module('legacy-app');
    app.factory('UnsavedChangesTracker', ['$location', 'ModalService', UnsavedChangesTracker]);
})();