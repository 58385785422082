import angular from 'angular';

(function () {

    'use strict';

    function cardNumberInput() {
        return {
            restrict: 'E',
            template: '<input type="text" x-autocompletetype="cc-number" card-number-validator/>',
            replace: true
        };
    }

    function cardNumberValidator($timeout, $parse, Payment) {


        function setValue(elm, value, scope, ngModelCtrl) {
            elm.val(value);
            ngModelCtrl.$setViewValue(value);

            $timeout(function() {
                elm.val(value);
                scope.$apply();
            });
        }

        return {
            require: 'ngModel',
            link: function postLink(scope, element, attrs, ngModelCtrl) {

                var cardType = $parse(attrs.cardType);

                function applyCardType(value) {
                    if (attrs.cardType) {
                        var card = Payment.cardFromNumber(value);
                        cardType.assign(scope, (card && cardType !== card.type) ? card.type : null);
                    }
                }

                function reFormatCardNumber(e) {

                    var elm = angular.element(e.currentTarget || e.srcElement);
                    var value = elm.val();

                    if(!value || value === ' ') {
                        setValue(elm, '', scope, ngModelCtrl);
                        return;
                    }

                    value = Payment.formatCardNumber(value);

                    var valid = Payment.validateCardNumber(value);
                    ngModelCtrl.$setValidity('cardNumber', valid);

                    ngModelCtrl.$formatters.unshift(function (value) {
                        applyCardType(value);
                        return Payment.formatCardNumber(value);
                    });

                    ngModelCtrl.$parsers.unshift(function (value) {
                        applyCardType(value);
                        return value;
                    });

                    var card = Payment.cardFromNumber(value);
                    var testValue = value.replace(/\D/g,'');

                    if(!valid && card && card.type && card.type === 'maestro' && testValue.length >= 12 && testValue.length <= 19) {
                        ngModelCtrl.invalid = true;
                    }
                    else if(!valid && card && card.type && card.type === 'mastercard' && testValue.length === 16) {
                        ngModelCtrl.invalid = true;
                    }
                    else if(!valid && card && card.type && card.type === 'amex' && testValue.length === 15) {
                        ngModelCtrl.invalid = true;
                    }
                    else if(!valid && card && card.type && card.type === 'visa' && testValue.length >= 13 && testValue.length <= 16) {
                        ngModelCtrl.invalid = true;
                    }
                    else if(!valid && card && card.type && card.type === 'visaelectron' && testValue.length === 16) {
                        ngModelCtrl.invalid = true;
                    }
                    else if(!valid && card && card.type && card.type === 'discover' && testValue.length === 16) {
                        ngModelCtrl.invalid = true;
                    }
                    else if(!valid && card && card.type && card.type === 'dinersclub' && testValue.length === 14) {
                        ngModelCtrl.invalid = true;
                    }
                    else if(!valid && card && card.type && card.type === 'jcb' && (testValue.length === 15 || testValue.length === 16)) {
                        ngModelCtrl.invalid = true;
                    }
                    else if(!card && testValue.length > 6) {
                        ngModelCtrl.invalidCardType = true;
                    }
                    else {
                        ngModelCtrl.invalid = false;
                        ngModelCtrl.invalidCardType = false;
                    }

                    setValue(elm, value, scope, ngModelCtrl);

                    e.preventDefault();
                }

                element.on('input', reFormatCardNumber);
                element.on('paste', reFormatCardNumber);
            }
        };
    }

    var app = angular.module('legacy-app');
    app.directive('cardNumberInput', cardNumberInput);
    app.directive('cardNumberValidator', ['$timeout', '$parse', 'Payment', cardNumberValidator]);

})();