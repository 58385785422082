import app from "js/legacy-app";

app.directive('plBody', [function () {
    return {
        template: '<div class="{{pageableList.type}}-list__body pageable-list__body">' +
            '               <pl-count-found ng-if="pageableList.type === \'grid\' || pageableList.viewTotalCount === \'true\'" text="items found"></pl-count-found>' +
            '               <pl-items pl-add-new-label="{{ ::plb.addNewLabel }}" pl-empty-data-text="{{ ::plb.emptyDataText}}" pl-add-new-link="{{ ::plb.addNewLink }}" pl-add-new-fn="{{ ::plb.addNewFn }}">' +
        '                   <div class="pageable-list__item pageable-list__item_rendering {{pageableList.type}}-list__item">' +
        '                       <div pl-on-finish-render class="{{pageableList.type}}-list__item-container" data-transcope>' +
        '                       </div>' +
        '                   </div>' +
        '           </pl-items>' +
        '           <pl-paging></pl-paging>' +
        '         </div>',
        replace: true,
        transclude: true,
        link: function ($scope, element, $attrs) {
            $scope.plb = {};
            $scope.plb.addNewLink = $attrs.plAddNewLink;
            $scope.plb.addNewLabel = $attrs.plAddNewLabel;
            $scope.plb.addNewFn = $attrs.plAddNewFn;
            $scope.plb.emptyDataText = $attrs.plEmptyDataText;
            $scope.plb.headers = {};
        }
    };
}]);