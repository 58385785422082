import app from "js/legacy-app";

app.directive('vtTimer', [ '$interval', function ($interval) {
    return {
        replace: true,
        scope: true,
        link: function ($scope) {
            $scope.clock = 0;

            function updateClock() {
                $scope.clock++;
            }

            $interval(function () {
                updateClock();
            }, 1000);
        },
        template: '<div class="vt-timer">Active: {{ clock | timerFilter }}</div>'
    };
}]);