export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/admin/all-mechanics-map', 'admin-mechanics-map')

        .segment('admin-mechanics-map', {
            access: {requireLogin: true},
            template: '<all-mechanics-map></all-mechanics-map>',
            loadModule: () => {
                return import(/* webpackChunkName: "mechanic-list.module" */ "./mechanic-list.module");
            }
        });

}
