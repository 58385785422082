import app from "js/legacy-app";

app.controller('MechanicCancellationReasonDialogCtrl', [
    '$scope',
    '$mdDialog',
    'Notification',

    function ($scope, $mdDialog, Notification) {

        $scope.ctrl = {};

        let descriptions = {
            REQUEST_IS_TOO_FAR_AWAY: 'The request is too far away',
            I_AM_NOT_CONFIDENT_IN_MY_SKILLS_IN_THIS_AREA: 'I am not confident in my skills in this area',
            MY_STORE_IS_TOO_BUSY_RIGHT_NOW: 'My store/business is too busy right now',
            PRICE_FOR_THIS_SERVICE_IS_TOO_LOW: 'The price for this service is too low',
            OTHER: ''
        };

        $scope.ctrl.cancel = () => {
            $mdDialog.hide();
        };

        $scope.ctrl.submit = (form) => {
            if (!form.$valid) {
                return;
            }

            if (!isValid()) {
                Notification.warning("Please select the cancellation reason");
                return;
            }

            $mdDialog.hide({reason: $scope.ctrl.reason, description: $scope.ctrl.reasonDescription});
        };

        $scope.ctrl.onSelectReason = (reason) => {
            $scope.ctrl.reason = reason;
            $scope.ctrl.reasonDescription = descriptions[reason] ? '' : $scope.ctrl.reasonDescription;
        };

        let isValid = () => {
            if (!$scope.ctrl.reason) {
                return false;
            }

            return !($scope.ctrl.reason === 'OTHER' && !$scope.ctrl.reasonDescription);
        };

    }
]);