/**
 * Created by eugineer on 7/14/16.
 */
import app from "js/legacy-app";

app.service('Rating', ['$resource', function ($resource) {
    return $resource('/api/rating/:param1/:param2', {}, {
        getMechanicReviews: {
            method: "GET",
            isArray: false,
            params: {param1: "mechanic"}
        },
        getUserReviews: {
            method: "GET",
            isArray: false,
            params: {param1: "user"}
        }
    });
}]);
