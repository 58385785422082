import './dropdown-list.component.scss';

export default () => {
    return {
        template: require('./dropdown-list.component.html'),
        transclude: true,
        controller: function () {
            'ngInject';
        }
    };
}
