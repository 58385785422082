import app from "js/legacy-app";

app.directive('backButton', ['HistoryService', 'ModalService', '$location', function (HistoryService, ModalService, $location) {
    return {
        template : '<md-button type="button" class="md-primary back" ng-click="back()" aria-label="<">' +
        '               <i class="fa fa-arrow-left" aria-hidden="true"></i>' +
        '           </md-button>',
        scope: {
            altUrl: '@',
            altParams: '@',
            backButton: '&'
        },
        link: function (scope, element, attrs) {
            scope.getUrl = function() {
                return HistoryService.getPrevUrl(scope.altUrl);
            };

            scope.back = function() {

                if(location.href && location.href.indexOf('pass-quiz?verification') >= 0) {
                    var message = 'Are you sure you want to leave this page?';
                    ModalService.yesNoMessage(null, '', message, function(response) {
                        if (response === true) {
                            goBack();
                        }


                    });
                } else {
                    goBack();
                }
            };

            function goBack() {
                if (scope.backButton()) {
                    HistoryService.back(scope.altUrl,
                        scope.altParams ? JSON.parse(scope.altParams.replace(/'/g, "\"")) : scope.altParams);
                } else {
                    $location.path(scope.altUrl);
                }
            }
        }
    };
}]);