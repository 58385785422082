import angular from "angular";

const changeLocationAction = {
    template: require("./booking-loaction-change-action.html"),
    require: {
        parent: '^vuiBookingLocation'
    },
    bindings: {
        title: '@vuiCaTitle'
    },

    controller: function ($mdDialog) {
        'ngInject';

        this.change = (event) => {
            $mdDialog.show({
                targetEvent: event,
                template: require('./booking-location-edit-dialog.html'),
                locals: {
                    model: this.parent.location
                },
                autoWrap: false,
                bindToController: true,
                controllerAs: '$ctrl',
                controller: function ($mdDialog, LocationService, Notification) {
                    'ngInject';

                    this.save = (form) => {
                        if (!this.validAddress(this.model)) {
                            return;
                        }

                        if (form.$valid) {
                            let address = LocationService.mapAddress(this.model);
                            $mdDialog.hide(address);
                        }
                    };

                    this.cancel = () => {
                        $mdDialog.hide();
                    };

                    this.validAddress = (address) => {
                        let error = LocationService.validateAddress(address);
                        if (error) {
                            Notification.error(error);
                            return false;
                        }

                        return true;
                    };
                }
            }).then((location) => {
                if (!location) return;

                this.parent.submit(location);
            });
        };
    }
};

export default angular.module("vui-booking-location-actions.module", [])
    .component("vuiBlChangeAction", changeLocationAction);