import app from "js/legacy-app";
import angular from "angular";

app.directive('vtSidenav', ['$vtSidenav', '$mdMedia', function ($vtSidenav, $mdMedia) {
    return {
        templateUrl: "template/component/widget/sidenav/vt-sidenav.html",
        replace: true,
        restrict: 'E',
        transclude: {
            header: 'vtSidenavHeader',
            body: 'vtSidenavBody',
            footer: '?vtSidenavFooter'
        },
        scope: {
            id: '@vsId',
            options: '=vsOptions',
            readonly: '=?vsReadonly',
            submit: '=?vsSubmit',
            model: '=vsModel'
        },
        controller: ['$scope', 'SidenavLD', function ($scope, SidenavLD) {
            $vtSidenav($scope.id, {options: $scope.options});
            this.sidenavId = $scope.id;

            $scope.vs = {
                sidenavLD: SidenavLD,
                submit: $scope.submit
            };

            this.vs = $scope.vs;

            $scope.$watch('vs.isOpen', function (newValue, oldValue) {
                if (angular.isDefined(newValue) && angular.isDefined(oldValue) && !newValue && oldValue) {
                    clear($scope.vs.sidenavForm);
                    $scope.model = {};
                }
            });

            function clear(form) {
                form.$setPristine();
                form.$setUntouched();
                form.$submitted = false;
                form.$valid = true;
                form.$error = {};

                angular.forEach(form, function (v, k) {
                    if (k.indexOf('$') === 0 || !v.$setValidity) {
                        return;
                    }

                    v.$setValidity('validation', true);
                    v.validationError = null;
                    v.$valid = true;
                });
            }
        }],

        link: function (scope, element, attr) {
            scope.vs.sidenavForm = scope.sidenavForm;

            scope.vs.top = function () {
                var maxTop = $mdMedia('(max-width: 343px)') ? 59 : 64;
                var scrollTop = document.body.scrollTop + document.getElementsByTagName('html')[0].scrollTop;
                if (scrollTop > maxTop) {
                    return 0;
                }
                return maxTop - scrollTop + 'px';
            };
        }
    };
}]);