import angular from "angular";

import "oclazyload";
import AppMarketplaceModule from "./modules/app-marketplace/app-marketplace.module";
import ApplicationsModule from "./modules/application/application.module";
import DealerModule from "./modules/dealer/dealer.module";

export default angular
    .module("vt-partner-app", [
        AppMarketplaceModule.name,
        ApplicationsModule.name,
        DealerModule.name
    ]);
