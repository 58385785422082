import app from "js/legacy-app";

app.component('customServiceNode', {
        templateUrl: "components/service/item/custom-service-node.html",
        replace: true,
        transclude: {
            services: 'csnServices',
            category: 'csnCategory'
        },
        bindings: {
            createNewService: '&csnCreateNewService'
        }
    }
);