import "./landing-page-service-list.component.scss";

export default {
    template: require('./landing-page-service-list.component.html'),
    require: ['ngModel'],
    bindings: {
        ngModel: "=",
        services: "="
    },
    controller: function ($timeout, ServicePublicApi, ErrorHandler) {
        'ngInject';

        const self = this;
        const MIN_SCREEN_WIDTH = 991;

        self.onServiceHover = (index) => {
            changeActive(index)
        }

        const changeActive = (index) => {
            if (window.outerWidth <= MIN_SCREEN_WIDTH) {
                if (clearActive(true)) {
                    $timeout(() => {
                        updateActiveClass(index);
                    }, 650);
                } else {
                    updateActiveClass(index);
                }
            } else {
                clearActive();
                updateActiveClass(index);
            }

            self.ngModel = self.services[index];
        }

        const clearActive = (isAnimate) => {
            for (let i = 0; i < self.services.length; i++) {
                if (self.services[i].className === "active") {
                    if (isAnimate) {
                        self.services[i].className = "active closing";
                        $timeout(() => {
                            self.services[i].className = "closing";
                        }, 10)
                        $timeout(() => {
                            self.services[i].className = "";
                        }, 1000)
                    } else {
                        self.services[i].className = "";
                    }
                    return true;
                }
            }

            return false;
        }

        const updateActiveClass = (index) => {
            setClassName(index, "active")
            for (let i = 0; i < self.services.length; i++) {
                if (i < index - 2 || i > index + 2) {
                    self.services[i].className = "";
                }
            }
        }

        const setClassName = (index, className) => {
            if (index >= 0 && self.services.length > index && self.services[index].className !== className) {
                self.services[index].className = className;
            }
        }

        if (!self.services) {
            self.services = [];

            ServicePublicApi.getSteps(function (steps) {
                appendServices(steps.view);
            }, function (error) {
                ErrorHandler.onError(error);
            });
        } else {
            self.onServiceHover(0);
        }

        const appendServices = (view) => {
            view.forEach(service => {
                if (!service.nextStep || !service.nextStep.view) {
                    service.link = '/our-coverage?serviceCode=' + service.code;
                    service.toString = () => {
                        return service.name;
                    }
                    service.description = service.description.replaceAll('\n', '<br>')
                    self.services.push(service);
                }
            });
            changeActive(0);
        }
    }
};
