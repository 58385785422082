import app from "js/legacy-app";
import angular from "angular";

app.directive('onVideoEnded', [function () {
    return {
        scope: {
            onVideoEnded: '='
        },
        link: function ($scope, $element, $attrs, ngModel, $timeout) {
            $element.on("ended", () => {
                $scope.$apply(() => {
                    $scope.onVideoEnded()
                })
            });
        }
    };
}]);
