import app from "js/legacy-app";

app.controller('CreateCoreServiceCtrl',
    function ($scope, $location, ErrorHandler, Tools, CoreServiceManagement, $q, ToggleLD,
              Notification, CoreServiceCategories, Documents, CoreServiceBuckets) {

        'ngInject';

        $scope.task = {
            tools: [],
            additionalServices: [],
            mechanicsCustomizationAvailable: false,
            enableByDefault: true
        };

        $scope.taskController = {

            imageContainer: {},

            save: function (form) {

                if (!form.$valid) {
                    return;
                }

                if ($scope.task.serviceBucket !== 'GENERAL' && (!$scope.task.selectedCategory || !$scope.task.selectedCategory.id)) {
                    Notification.error("Service category is required");
                    ToggleLD.hide();
                    return;
                }

                ToggleLD.show();
                CoreServiceManagement.save(this.get(), function (t) {
                    ToggleLD.hide();
                    $location.url("/admin/view-service/" + t.id);
                    Notification.success("Service was successfully created");
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error, "ERROR", "Failed to create service", form);
                });
            },

            close: function () {
                $location.url("/admin/service-management/services");
            },

            addTool: function () {
                $scope.task.tools.push({
                    name: ''
                });
            },

            removeTool: function (index) {
                $scope.task.tools.splice(index, 1);
            },

            addService: function () {
                $scope.task.additionalServices.push({
                    name: ''
                });
            },

            removeService: function (index) {
                $scope.task.additionalServices.splice(index, 1);
            },

            searchTools: function (query) {
                var deferred = $q.defer();
                Tools.searchByNameLike({name: query}, function (response) {
                    deferred.resolve(response.items);
                }, function (error) {
                    deferred.reject(error);
                });
                return deferred.promise;
            },

            searchCategories: function (query) {
                return CoreServiceCategories.autocomplete({
                    name: query,
                    serviceBucket: $scope.task.serviceBucket
                }).$promise;
            },

            disabledSwitch: function () {
                return $scope.task.serviceBucket && $scope.task.serviceBucket !== 'GENERAL';
            },

            onChangeServiceBucket: (serviceBucket) => {
                if (serviceBucket !== 'GENERAL') {
                    $scope.task.main = false;
                }
            },

            onSwitchChange: function () {
                if ($scope.task.main) {
                    $scope.createTaskForm.categoryId.validationError = null;
                    $scope.createTaskForm.categoryId.$setValidity('validation', true);
                }
            },

            flows: [
                'DEFAULT',
                'WHITE_GLOVE_DELIVERY'
            ],

            get: function () {
                var task = $scope.task;
                var dto = {};
                var tools = [];
                if (task.tools && task.tools.length > 0) {
                    for (let i = 0; i < $scope.task.tools.length; i++) {
                        if ($scope.task.tools[i].id) {
                            tools.push($scope.task.tools[i].id);
                        } else {
                            if ($scope.task.tools[i].selectedTool && $scope.task.tools[i].selectedTool.id) {
                                tools.push($scope.task.tools[i].selectedTool.id);
                            }
                        }
                    }
                }

                var additionalServices = [];
                if (task.additionalServices && task.additionalServices.length > 0) {
                    for (let i = 0; i < $scope.task.additionalServices.length; i++) {
                        if ($scope.task.additionalServices[i].name) {
                            additionalServices.push($scope.task.additionalServices[i].name);
                        }
                    }
                }

                dto.tools = tools;
                dto.additionalServices = additionalServices;
                dto.name = task.name;
                dto.description = task.description;
                dto.price = task.price;
                dto.timeToComplete = task.timeToComplete;
                dto.flow = task.flow;
                dto.needBike = task.needBike;
                dto.online = task.online;
                dto.deliverySupported = task.deliverySupported;
                dto.group = task.group;
                dto.serviceBucket = task.serviceBucket;
                dto.main = task.main;
                dto.mechanicNotes = task.mechanicNotes;
                dto.mechanicPrice = task.mechanicPrice;
                dto.mechanicsCustomizationAvailable = task.mechanicsCustomizationAvailable;
                dto.defaultState = task.enableByDefault ? 'ENABLED' : 'DISABLED';

                if (!task.main && $scope.task.selectedCategory) {
                    dto.categoryId = $scope.task.selectedCategory.id;
                }
                return dto;
            }
        };

        (function init() {
            CoreServiceBuckets.get({size: 100}, function (res) {
                $scope.taskController.serviceBuckets = [].concat(res.data.map((sb) => {
                    return {id: sb.id, val: sb.type, display: sb.name};
                }));

            });
        })();
    }
);
