import app from "js/legacy-app";

app.controller('ProfileAddressListCtrl',
    ['$scope', 'User', '$pageableList', 'ToggleLD', 'LocationService', '$q',
        function ($scope, User, $pageableList, ToggleLD, LocationService, $q) {

            $scope.$parent.title = 'Saved Addresses';
            $scope.pam = {};

            $scope.pam.search = function (query) {
                var deferred = $q.defer();

                User.getProfileLocations(query, function (response) {
                    $scope.pam.favorite = response.favorite;
                    deferred.resolve(response.other);
                }, function (error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            $scope.pam.onRemoveAddress = function () {
                $pageableList.get('profile-address-list').refreshPage();
            };

            $scope.pam.onEditAddress = function () {
                $pageableList.get('profile-address-list').refreshPage();
            };

            $scope.pam.addNew = function ($event) {
                LocationService.editProfileAddress({type: 'OTHER'}, $event).then(function () {
                    $pageableList.get('profile-address-list').refreshPage();
                });
            };

        }]);