import app from "js/legacy-app";

app.directive('mechanicPublicServices', [function () {
    return {
        templateUrl: "components/mechanic-public-services/mechanic-public-services.html",
        replace: true,
        restrict: 'E',
        scope: {
            mechanic: '=mpsMechanic',
            location: '=mpsLocation',
            readonly: '@mpsReadonly'
        },
        controller: ['$scope', 'Service', 'ToggleLD', 'ErrorHandler', function ($scope, Service, ToggleLD, ErrorHandler) {

            var mps = $scope.mps = {};

            ToggleLD.show();
            Service.getMechanicServiceTree({mechanicId: $scope.mechanic.id}, function (data) {
                ToggleLD.hide();
                mps.nodes = data.nodes;
            }, function (error) {
                ToggleLD.hide();
                ErrorHandler.onError(error, "Unable to load service list");
            });

        }]
    };
}]);