import app from "js/legacy-app";

app.service('$mechanicPublicServicesProvider', ['$mdDialog',
    function ($mdDialog) {

        var showServicesAndPrices = function ($event, mechanic, location, readonly) {
            $mdDialog.show({
                targetEvent: $event,
                escapeToClose: true,
                clickOutsideToClose: true,
                template: '<md-dialog aria-label="Description" class="vt-modal mechanic-list-item-pricing margin-top-0">' +
                '  <div class="vt-modal__body vt-modal__body_no-padding">' +
                '       <mechanic-public-services ' +
                '                   data-mps-mechanic="mechanic" ' +
                '                   data-mps-location="location"' +
                '                   data-mps-readonly="{{readonly}}">' +
                '       </mechanic-public-services>' +
                '  </div>' +
                '  <div class="vt-modal__footer align-right">' +
                '    <button ng-click="closeDialog()" class="md-button">' +
                '      Close' +
                '    </button>' +
                '  </div>' +
                '</md-dialog>',
                locals: {
                    mechanic: mechanic,
                    location: location,
                    readonly: readonly
                },
                controller: ['$scope', '$rootScope', 'mechanic', 'location', 'readonly', 'Service', DialogController]
            });

            function DialogController($scope, $rootScope, mechanic, location, readonly, Service) {

                $scope.mechanic = mechanic;
                $scope.location = location;
                $scope.readonly = readonly;

                $scope.closeDialog = function () {
                    $mdDialog.hide();
                };

                // hack for closing dialog on route change https://github.com/angular/material/issues/3418
                var deregister = $rootScope.$on('$routeChangeStart', function () {
                    $scope.closeDialog();
                    deregister();
                });
            }
        };

        return {
            showServicesAndPrices: showServicesAndPrices
        };
    }
]);
