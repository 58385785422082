import app from "js/legacy-app";

app.directive('plListing', [function () {
    return {
        templateUrl: "template/component/widget/pageable-list/pl-listing.html",
        replace: true,
        transclude: true,
        link: function ($scope, element, $attrs) {
            $scope.pll = {};
            $scope.pll.pageSize = $attrs.plPageSize;
            $scope.pll.id = $attrs.plId;
            $scope.pll.type = $attrs.plType;
            $scope.pll.viewTotalCount = $attrs.plViewTotalCount;
            $scope.pll.global = $attrs.plGlobal === undefined ? true : $attrs.plGlobal;
            $scope.pll.items = $attrs.plDataItems;
            $scope.pll.smoothRendering = $attrs.plSmoothRendering;
            $scope.pll.smoothRenderingMaxIterations = $attrs.plSmoothRenderingMaxIterations;
        }
    };
}]);