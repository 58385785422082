import './coming-soon.component.scss';

export default {
    template: require('./coming-soon.component.html'),
    transclude: true,
    controller: function ($pageHeader) {
        'ngInject';

        $pageHeader.hide();
    }
};

