import app from "js/legacy-app";

app.directive('chatInput', ['EMOJI_HEX', '$mdMedia', function (EMOJI_HEX, $mdMedia) {
    return {
        scope: {
            chatInput: '='
        },
        link: function (scope, element, attrs) {

            element[0].addEventListener("input", function () {
                restrictMaxLength(this);
            });

            element.bind('keydown keypress', function (event) {
                if (event.which === 13) {
                    if (!event.shiftKey && !$mdMedia('(max-width: 470px)')) {
                        scope.$apply(function () {
                            scope.chatInput.addComment();
                        });

                        event.preventDefault();
                    }
                }
            });

            //Set cursor to input field
            setCursorToEnd();

            scope.chatInput.addSmile = function (cssClass) {
                insertSmileAtCursor(cssClass);
            };

            scope.chatInput.getText = function () {
                var text = element[0].innerHTML;
                if (isEmpty(text)) {
                    return null;
                }

                text = text.replace(/<div>/g, '<br>');
                text = text.replace(/<\/div>/g, '');
                return text;
            };

            scope.chatInput.clearChatInput = function () {
                element[0].innerHTML = '';
            };

            function isEmpty(text) {
                if (!text) {
                    return true;
                }

                text = text.trim();
                return !text || text.length < 1;
            }

            function insertSmileAtCursor(smile) {
                if (isMaxLength(element[0].innerText.length)) {
                    return;
                }

                var img = document.createElement("IMG");
                img.setAttribute('src', require('img/chat/opacity.png'));
                img.classList.add('smile');
                img.classList.add(smile);

                if ("onresizestart" in img) { // IE
                    img.onresizestart = function () {
                        return false;
                    };
                }

                element[0].appendChild(img);
                setCursorToEnd();
            }

            function setRange(range) {
                if (document.getSelection) {
                    var sel = window.getSelection();
                    sel.removeAllRanges();
                    sel.addRange(range);

                    //Scroll Bottom
                    element[0].scrollTop = element[0].scrollHeight;
                }
            }

            function setCursorAfterElement(el) {
                var range = document.createRange();
                range.setStartAfter(el);
                range.setEndAfter(el);
                setRange(range);
            }

            function setCursorToEnd() {
                element[0].focus();
                if (window.getSelection && document.createRange) {
                    var range = document.createRange();
                    range.selectNodeContents(element[0]);
                    range.collapse(false);
                    setRange(range);
                }
            }

            function restrictMaxLength(self) {
                var length = self.innerText.length;

                if (isMaxLength(length)) {
                    self.innerText = self.innerText.substring(0, 1000);
                    setCursorToEnd();
                }
            }

            function isMaxLength(length) {
                var maxLength = 1000;
                return maxLength <= length;
            }

        }
    };
}]);