import app from "js/legacy-app";

app.service('MechanicRequest', ['$resource', function ($resource) {
    return $resource('/api/mechanic-request/:id/:id1/:id2', {}, {
        history: {
            method: "GET",
            params: {id: "history/list"},
            isArray: false
        },
        setRating: {
            method: "PUT",
            params: {id1: "set-rating"},
            isArray: false
        },
        couldNotFix: {
            method: "PUT",
            params: {id1: "could-not-fix"},
            isArray: false
        },
        resolve: {
            method: "PUT",
            params: {id1: "resolve"},
            isArray: false
        },
        cancel: {
            method: "PUT",
            params: {id1: "cancel"},
            isArray: false
        },
        fixBike: {
            method: "PUT",
            params: {id1: "fix-bike"},
            isArray: false
        },
        fixAllBikes: {
            method: "PUT",
            params: {id1: "fix-all-bikes"},
            isArray: false
        },
        findOneFromHistory: {
            method: "GET",
            params: {id1: "history"},
            isArray: false
        },
        updateAppointmentDate: {
            method: "PUT",
            params: {id1: "appointment-date"},
            isArray: false
        },
        confirmAppointmentDate: {
            method: "PUT",
            params: {id1: "appointment-date/confirm"},
            isArray: false
        }
    });
}]);
