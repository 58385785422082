import './landing-page-header.component.scss';

export default {
    template: require('./landing-page-header.component.html'),
    controller: function () {
        'ngInject';

        const self = this;
        self.isMenuOpenned = false;

        self.toggleMenu = () => {
            self.isMenuOpenned = !self.isMenuOpenned;
        }
    }
};
