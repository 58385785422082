import app from "js/legacy-app";

app.service("$pageableList", [function () {

    let lists = {};

    function register(id, pl) {
        if (id) {
            lists[id] = pl;
        } else {
            throw new Error("WARN: Unable to register pageable list as element 'id' was not specified");
        }

    }

    function get(id) {
        return lists[id];
    }

    return {
        register: register,
        get: get
    };
}]);