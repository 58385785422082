import angular from "angular";
import defaultMechanicCard from "../default";

const selectableMechanicCard = {
    template: require('./selectable-mechanic-card.html'),
    transclude: {
        actions: "?vuiSelectableMechanicCardActions"
    },
    bindings: {
        model: '<vuiSmcModel',
        price: '@?vuiSmcPrice',
        isSelected: '&?vuiSmcIsSelected',
        onSelect: '&vuiSmcOnSelect'
    },
    controller: function () {
        const self = this;
        let selectedMechanic = false;

        self.onSelectMechanic = () => {
            if (!self.isSelectedMechanic()) {
                selectedMechanic = true;
                self.onSelect({mechanic: self.model});
            } else {
                selectedMechanic = false;
                self.onSelect({mechanic: null});
            }
        };

        self.isSelectedMechanic = () => {
            if (angular.isFunction(self.isSelected)) {
                return self.isSelected({mechanic: self.model})
            }

            return !!selectedMechanic;
        };
    }
};

export default angular.module("vui-selectable-mechanic-card", [defaultMechanicCard.name])
    .component('vuiSelectableMechanicCard', selectableMechanicCard);
