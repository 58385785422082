import app from "js/legacy-app";
import angular from "angular";

app.directive('locationStep', ['$location', function ($location) {
    return {
        templateUrl: "components/request/create/steps/location-step.html",
        controller: ['$scope', '$timeout', 'LocationService', 'ModalService', 'AuthService', 'Notification', '$q',
            '$routeParams', '$route', 'LocationResource', '$serviceCoverageChecker', 'ErrorHandler', 'ToggleLD',

            function ($scope, $timeout, LocationService, ModalService, AuthService, Notification, $q, $routeParams,
                      $route, LocationResource, $serviceCoverageChecker, ErrorHandler, ToggleLD) {

                $scope.locationStep = {
                    position: 0,
                    id: "location",
                    title: "Where are you located?",
                    onLocationChange: function () {
                        if ($scope.bcrc.isCompletedStep("location")) {
                            $scope.bcrc.removeStepFromCompleted("location");
                        } else {
                            $scope.wizard.uncompleteStep("location");
                        }
                    },
                    next: function () {
                        $scope.locationForm.$setSubmitted();
                        locationStep.submitLocationForm($scope.locationForm);
                    }
                };

                var locationStep = {};

                locationStep.isValidLocation = function (serviceLocation) {
                    if (!serviceLocation) {
                        return false;
                    }

                    if (!serviceLocation.latitude || !serviceLocation.longitude) {
                        Notification.error('Service is not available in this location');
                        return false;
                    }

                    return true;
                };

                locationStep.submitLocationForm = function (form) {
                    if (form && !form.$valid) {
                        return;
                    }

                    var serviceLocation = $scope.bcrc.serviceLocation;
                    if (!locationStep.isValidLocation(serviceLocation)) {
                        return;
                    }

                    ToggleLD.show();

                    locationStep.getLocationOffsetIfNotExists(serviceLocation).then(function (offset) {
                        ToggleLD.hide();
                        serviceLocation.offset = offset;
                        $serviceCoverageChecker.checkCoverage(
                            serviceLocation, locationStep.onLocationCovered,
                            locationStep.onLocationNotCovered, locationStep.onCheckCoverageFailure
                        );
                    }, function (error) {
                        ToggleLD.hide();
                        Notification.error('Service is not available in this location');
                    });
                };

                locationStep.getLocationOffsetIfNotExists = function (serviceLocation) {
                    var deferred = $q.defer();
                    if (!serviceLocation.utcOffset) {
                        LocationService.getLocationOffset(serviceLocation, function (utcOffset) {
                            if (isNaN(utcOffset)) {
                                deferred.reject();
                            } else {
                                deferred.resolve(utcOffset * 60);
                            }
                        });
                    } else {
                        deferred.resolve(null);
                    }

                    return deferred.promise;
                };

                locationStep.onLocationCovered = function () {
                    ToggleLD.hide();
                    locationStep.proceedToNextStep();
                };

                locationStep.proceedToNextStep = function () {
                    $scope.onlineOnly = false;
                    $scope.wizard.next();
                };

                locationStep.onCheckCoverageFailure = function (error) {
                    ToggleLD.hide();
                    if (!$scope.bcrc.selectedTab) {
                        $scope.wizard.goTo('location');
                    }
                    ErrorHandler.onError(error, "Something went wrong");
                };

                locationStep.onLocationNotCovered = function (data) {
                    ToggleLD.hide();

                    if (!$scope.bcrc.selectedTab) {
                        $scope.wizard.goTo('location');
                    }

                    if (!AuthService.isLogin()) {
                        ModalService.notifyUserAboutServiceAvailability({
                            location: $scope.bcrc.serviceLocation,
                            trackingId: data.trackingId
                        }, function (res) {
                            locationStep.offerOnlineConsultation();
                        });
                    } else {
                        locationStep.offerOnlineConsultation();
                    }
                };

                locationStep.offerOnlineConsultation = function () {
                    if (angular.equals([], $scope.bcrc.services.all)) {
                        $scope.taskStep.loadServices().then(function (data) {
                            selectOnlineConsultation();
                        });
                    } else {
                        selectOnlineConsultation();
                    }

                    function selectOnlineConsultation() {
                        $scope.buildTaskView($scope.bcrc.services.all, "onlineconsultation");
                        if (!$scope.bcrc.services.selected) {
                            Notification.error('At the moment we do not have a physical presence in your area');
                            return;
                        }

                        ModalService.yesNoMessage(null, '', 'At the moment we do not have a physical presence in your area. <br>' +
                            'However, you can get an online consultation that might be of some help while<br> we work hard to ' +
                            'put a mechanic in your area.<br> <br>Would you like an online consultation ($' +
                            $scope.bcrc.services.selected.price + ')?', function (response) {
                            if (response) {
                                locationStep.openOnlineTask();
                            }
                        });
                    }
                };

                locationStep.openOnlineTask = function () {
                    $scope.onlineOnly = true;
                    $scope.wizard.disableStep("service");
                    $scope.wizard.next();
                };

                locationStep.updateAddressRouteParam = function () {
                    if (!$scope.bcrc.lastTab) {
                        $location.search('address', $scope.bcrc.serviceLocation.address);
                    } else {
                        $scope.bcrc.lastTab = null;
                    }
                };

                $scope.$watch('bcrc.serviceLocation.address', function () {
                    if (!($scope.bcrc && $scope.bcrc.serviceLocation)) {
                        return;
                    }

                    var address = $scope.bcrc.serviceLocation;

                    $scope.locationStep.location = address.address;
                    if (!address.latitude && !address.longitude) {
                        return;
                    }

                    locationStep.updateAddressRouteParam();
                });

                (function init() {
                    if ($routeParams.address) {
                        LocationResource.geocode({address: $routeParams.address}, function (res) {
                            var locationDetails = res;
                            locationDetails.address = $routeParams.address;
                            $scope.bcrc.serviceLocation = locationDetails;
                            $scope.bcrc.serviceLocation.apartment = $routeParams.apartment;
                        }, function (error) {
                            $scope.bcrc.serviceLocation = {};
                        });
                    }
                })();
            }]
    };
}]);