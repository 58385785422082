import app from "js/legacy-app";
import angular from 'angular';

app.directive('removeServiceFromPopularAction', ['$mdMedia',
    function ($mdMedia) {
        return {
            templateUrl: "components/service/actions/remove-service-from-popular-action.html",
            replace: true,
            scope: {
                model: "=rsaModel",
                onRemove: "&rsaOnRemove"
            },
            controller: ['$scope', 'CustomServiceManagement', 'ToggleLD', 'ErrorHandler',
                function ($scope, CustomServiceManagement, ToggleLD, ErrorHandler) {

                    $scope.rsac = {
                        mdMedia: $mdMedia
                    };

                    $scope.rsac.remove = function (event) {
                        ToggleLD.show();
                        CustomServiceManagement.updatePopularityFlag(
                            {
                                id: $scope.model.id
                            },
                            {
                                value: false
                            },
                            function () {
                                ToggleLD.hide();
                                if (angular.isFunction($scope.onRemove)) {
                                    $scope.onRemove({service: $scope.model, popularityFlag: false});
                                }
                            }, function (error) {
                                ToggleLD.hide();
                                ErrorHandler.onError(error);
                            });
                    };

                }]
        };
    }]);