import app from "js/legacy-app";

app.directive('userAvatarWithName', [function () {
    return {
        templateUrl: "components/user-avatar-with-name/user-avatar-with-name.html",
        replace: true,
        scope: {
            model: "=uModel",
            noUserText: "@uNoUserText"
        },
        link: function ($scope) {

        }
    };
}]);