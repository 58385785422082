import app from "js/legacy-app";

app.controller('DashboardCtrl', ['$scope', 'AuthService', '$location', '$rootScope', 'NotificationWSService',
    function ($scope, AuthService, $location, $rootScope, NotificationWSService) {

        if ($location.$$path === '/dashboard') {
            AuthService.currentUser(function(user) {
                if (AuthService.isUser()) {
                    $location.path('/dashboard/bicycles').search({});
                } else if (AuthService.isAdmin()) {
                    $location.path('/dashboard/admin').search({});
                } else if (AuthService.isAcceptAgreement() || AuthService.isProbationaryMechanic() || AuthService.isDealer()) {
                    $location.path('/dashboard/requests').search({});
                } else if (AuthService.isVerifiedMechanic() && !AuthService.isAcceptAgreement()) {
                    $location.path('/dashboard/agreement').search({});
                } else if (AuthService.isNotVerifiedMechanic()) {
                    $location.path('/dashboard/verification').search({});
                } else if (AuthService.isBikeBusinessSupervisor()) {
                    $location.path('/dashboard/organisations').search({});
                } else if (AuthService.isBikeOrganisationOwner()) {
                    $location.path('/company-dashboard/bicycles').search({});
                }
            });
        }

    }
]);
