import app from "js/legacy-app";

app.directive('vtSidenavBody', [function () {
    return {
        templateUrl: "template/component/widget/sidenav/vt-sidenav-body.html",
        replace: true,
        transclude: true,
        require: '^^vtSidenav',
        link: function ($scope, element, attr, sidenav) {

            $scope.vsb = {};
            $scope.sidenavForm = sidenav.vs.sidenavForm;
        }
    };
}]);