import app from "js/legacy-app";

app.directive('enterEvent', function () {
    return {
        scope: {
            enterEvent: '&'
        },
        link: function (scope, element, attrs) {
            element.bind("keydown keypress", function (event) {
                if (event.which === 13) {
                    scope.$apply(function () {
                        scope.enterEvent()();
                    });

                    event.preventDefault();
                }
            });
        }
    };
});