import app from "js/legacy-app";

app.controller('ChangeBikeStatusDialogController', ['$scope', '$mdDialog', 'status',
    function ($scope, $mdDialog, status) {

        $scope.csdc = {
            current: status
        };

        $scope.csdc.close = function () {
            $mdDialog.hide();
        };

        $scope.csdc.change = function (newStatus) {
            $mdDialog.hide(newStatus);
        };

    }]);