import angular from "angular";

const mechanics = function ($resource) {
    'ngInject';

    return $resource('/api/admin/mechanics/:path', {}, {
        list: {
            method: "GET",
            isArray: false
        }
    });
};

export default angular.module('admin-mechanics.resource', [])
    .service('AdminMechanicsApi', mechanics);