import app from "js/legacy-app";

app.controller('ProfileVerificationCtrl',
    ['$scope', 'User', '$location', 'ToggleLD', 'MECHANIC_CATEGORIES', 'ErrorHandler',
        'LocationService', 'Notification',
        function ($scope, User, $location, ToggleLD, MECHANIC_CATEGORIES, ErrorHandler,
                  LocationService, Notification) {

            $scope.mechanicCategories = MECHANIC_CATEGORIES;

            $scope.pvc = {
                get: function () {
                    return {
                        name: $scope.pvc.user.name,
                        surname: $scope.pvc.user.surname,
                        mechanicCategory: $scope.pvc.user.mechanicCategory,
                        phone: $scope.pvc.user.phone.number,
                        dateOfBirthday: new Date($scope.pvc.user.dateOfBirthday)
                    };
                },

                set: function (data) {
                    $scope.pvc.user = data;
                    $scope.pvc.user.phone = {
                        number: $scope.pvc.user.phone
                    };

                    $scope.pvc.maxDate = new Date();

                    if ($scope.pvc.user.dateOfBirthday) {
                        $scope.pvc.user.dateOfBirthday = new Date($scope.pvc.user.dateOfBirthday);
                    }

                    $scope.pvc.maxDate.setYear($scope.pvc.maxDate.getFullYear() - 18);
                }
            };

            $scope.pvc.save = function (form) {
                if (form.$valid && !$scope.pvc.user.phone.$error) {

                    var request = $scope.pvc.get();
                    $scope.pvc.disabledChange = true;

                    ToggleLD.show();
                    User.profileVerification(request, function () {
                        $location.path('/dashboard/verification');
                        $scope.pvc.disabledChange = false;
                        ToggleLD.hide();
                    }, function (error) {
                        ErrorHandler.onError(error, "Failed to save profile", 'ERROR', form);
                        $scope.pvc.disabledChange = false;
                        ToggleLD.hide();
                    });
                }
            };

            $scope.pvc.resendActivationEmail = function () {
                ToggleLD.show();
                User.reactivationEmail({}, {}, function () {
                    ToggleLD.hide();
                    Notification.success("Go to this email and click on the link to finish your account registration");
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error, "Failed to resend activation email");
                });
            };

            function getCurrentUser() {
                User.current(function (data) {
                    $scope.pvc.disabledChange = false;
                    $scope.pvc.set(data);
                    ToggleLD.hide();
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error, "Failed to load data");
                    $location.path('/dashboard/verification');
                });
            }

            function init() {
                ToggleLD.show();
                getCurrentUser();
            }

            init();
        }
    ]);
