import app from "js/legacy-app";

app.controller('YesNoMessageDialogController',
    ['$scope', '$mdDialog', 'title', 'message', 'yesButtonText', 'noButtonText',
        function ($scope, $mdDialog, title, message, yesButtonText, noButtonText) {

            $scope.ctrl = {
                title: title,
                message: message,
                yesButtonText: yesButtonText,
                noButtonText: noButtonText
            };

            $scope.ctrl.cancel = () => {
                $mdDialog.hide();
            };

            $scope.ctrl.submit = () => {
                $mdDialog.hide(true);
            };
        }
    ]);