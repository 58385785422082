import angular from "angular";

const card = {
    template: require('./card.html'),
    transclude: {
        title: "?vuiCardTitle",
        content: "vuiCardContent"
    },
    bindings: {
        titleClass: "@?cardTitleClass",
        contentClass: "@?cardContentClass"
    },
    controller: function () {
        this.titleClass = this.titleClass || 'tw-px-4 tw-font-bold tw-text-lg';
        this.contentClass = this.contentClass || 'tw-px-4';
    }
};

const cardSeparator = {
    template: require('./card-separator.html'),
};

export default angular.module("vui-card", [])
    .component('vuiCard', card)
    .component('vuiCardSeparator', cardSeparator);
