import app from "js/legacy-app";

app.directive('blDuplicateAction',
    ['ErrorHandler', 'ToggleLD', 'Notification', 'ModalService', '$location', '$bikeService',
        function (ErrorHandler, ToggleLD, Notification, ModalService, $location, $bikeService) {
            return {
                template: "<md-menu-item>" +
                "              <md-button ng-click='blda.duplicateBike()'>Duplicate bicycle</md-button>" +
                "          </md-menu-item>",
                replace: true,
                link: function ($scope) {

                    $scope.blda = {
                        resource: $bikeService.getResourceClass()
                    };

                    $scope.blda.duplicateBike = function () {
                        var message = 'Are you sure you want to duplicate this bicycle?';
                        ModalService.yesNoMessage(null, '', message, function (response) {
                            if (!response) return;

                            ToggleLD.show();
                            $scope.blda.resource.duplicate({id: $scope.bike.id}, {}, function (data) {
                                ToggleLD.hide();
                                Notification.success("Bicycle successfully duplicated");
                                $location.path('/bike/' + data.id).search({});
                            }, function (error) {
                                ToggleLD.hide();
                                ErrorHandler.onError(error, "Failed to duplicate bicycle");
                            });
                        });
                    };
                }
            };
        }]);