import "./landing-page-main-block.component.scss"

export default {
    template: require('./landing-page-main-block.component.html'),
    bindings: {
        showIcon: "@?showVtIcon"
    },
    transclude: {
        title: "?lpMainBlockTitle",
        body: "?lpMainBlockBody",
        action: "?lpMainBlockAction"
    },
    controller: function () {

        'ngInject';
    }
};
