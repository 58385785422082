import angular from "angular";
import "./mechanic-list-card.component.scss";
import ClipboardComponent from "../../../vt-generic/components/clipboard/copy-to-clipboard.component";

const mechanicImages = require.context('img/icons/mechanic', true);

const component = {
    template: require("./mechanic-list-card.component.html"),
    bindings: {
        mechanic: '<vuiMlcMechanic',
        location: '<?vuiMlcLocation',
        mechanicSearchFn: '&?vuiMlcMechanicSearchFn'
    },
    controller: function () {
        'ngInject';

        this.getMechanicCategoryIcon = (category) => {
            if (!category) {
                return;
            }

            return mechanicImages('./' + category.toLowerCase() + '-type.png', true);
        };
    }
};

export default angular.module("vui-mechanic-list-card.module", [ClipboardComponent.name])
    .component("vuiMechanicListCard", component);