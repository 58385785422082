import app from "js/legacy-app";

app.service('Security', ['AuthService', '$location', function (AuthService, $location) {

    return {
        check: function (page) {
            var pathRootPart = page.split('/')[1];
            var path = '/';
            if (pathRootPart) {
                path += pathRootPart;
            }

            switch (path) {
                case '/':
                case '/home':
                case '/sign-up-user':
                case '/become-a-mechanic':
                case '/sign-up-mechanic':
                case '/login':
                    if (AuthService.isFullyLoggedIn()) {
                        $location.path('/dashboard');
                    }
                    break;
                case '/request':
                    if (!AuthService.isFullyLoggedIn()) {
                        $location.path('/login');
                    }
                    break;
                case '/pass-quiz':
                case '/experience-verification':
                case '/bike-shop-experience':
                case '/profile-verification':
                case '/profile-picture-verification':
                case '/biography-verification':
                case '/upload-verification-document':
                case '/confirm-billing-address':
                case '/choose-tools':
                    if (!AuthService.isNotVerifiedMechanic()) {
                        $location.path('/dashboard');
                    }
                    break;
                case '/upload-insurance-verification':
                case '/settings/payment/connected-account/stripe':
                case '/work-area':
                case '/mechanic-settings':
                    if (!AuthService.isMechanic()) {
                        $location.path('/dashboard');
                    }
                    break;
                case '/admin':
                    if (!AuthService.isAdmin()) {
                        $location.path('/dashboard');
                    }
                    break;
                case '/complete-social-registration':
                    if (!AuthService.isPartiallyRegistered()) {
                        $location.path('/');
                    }
                    break;
                case '/create-request': {
                    if (!AuthService.isFullyLoggedIn() || AuthService.isMechanic() || AuthService.isAdmin()) {
                        $location.path('/dashboard');
                    }
                    break;
                }
                case '/partner': {
                    if (page.match(/^\/partner\/.+\/book-service$/)) {
                        break;
                    }

                    if (!AuthService.isBusinessAccount()) {
                        $location.path('/dashboard');
                    }
                    break;
                }
                case '/mechanic': {
                    if (!AuthService.isMechanic()) {
                        $location.path('/dashboard');
                    }
                    break;
                }
                case '/mechanics': {
                    if (!AuthService.isFullyLoggedIn()) {
                        $location.path('/login');
                    }
                    break;
                }
            }

            if (page.match(/\/dashboard\/agreement\/*/) && !AuthService.isMechanic()) {
                $location.path('/dashboard');
            }

            if (page.match(/\/dashboard\/admin\/*/) && !AuthService.isAdmin()) {
                $location.path('/dashboard');
            }
        }
    };
}]);
