import app from "js/legacy-app";

app.controller('PaymentSettingsCtrl', ['$scope', 'AuthService', 'User', function ($scope, AuthService, User) {

    $scope.$parent.selectedTab = 3;
    $scope.$parent.title = 'Payment';

    $scope.refreshStripeAccount = function () {
        AuthService.currentUser(function (user) {
            if (AuthService.isMechanic()) {
                User.getStripeAccount(function (data) {
                    if (data.createdAccount) {
                        $scope.stripeAccount = data;
                    } else {
                        $scope.stripeAccount = null;
                    }
                });
            } else {
                $scope.stripeAccount = null;
            }
        });
    };

    $scope.refreshStripeAccount();

}]);