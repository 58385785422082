import app from "js/legacy-app";

app.directive('plTextFilter', ['$location', function ($location) {
    return {
        template: '<div class="pageable-list__filter-field">' +
        '              <md-input-container>' +
        '                  <label class="md-label">{{ label }}</label>' +
        '                  <input type="{{ type }}"' +
        '                         name="{{ name }}"' +
        '                         data-ng-model="tf.selected"' +
        '                         data-ng-change="tf.onItemChange()"' +
        '                         data-ng-keyup="$event.keyCode == 13 ? tf.apply() : null"' +
            '                         class="md-input padding-right50"/>' +
        '                   <button class="md-button md-primary pageable-list__inline-filter-button" data-ng-show="tf.showApplyButton" data-ng-click="tf.apply()">' +
        '                         <span>Apply</span>' +
        '                   </button>' +
        '              </md-input-container>' +
        '          </div>',
        replace: true,
        require: '^^plFilter',
        scope: {
            label: '@tfLabel',
            name: '@tfName',
            type: '@?tfType',
            model: '=tfModel',
            dependsOn: '@tfDependsOn'
        },
        link: function ($scope, element, $attrs, plFilter) {
            $scope.tf = {};
            $scope.tf.filter = plFilter.filter;
            $scope.type = $scope.type || 'text';

            $scope.tf.filter.register($scope.name, $scope.tf);

            $scope.tf.onItemChange = function () {
                $scope.tf.showApplyButton = true;
            };

            $scope.tf.apply = function () {
                $scope.tf.filter.apply();
                $scope.tf.showApplyButton = false;
            };

            $scope.tf.isSelected = function () {
                return !!$scope.tf.selected;
            };

            $scope.tf.getValue = function () {
                return $scope.tf.isSelected() ? $scope.tf.selected : null;
            };

            $scope.tf.serialize = function () {
                var serialized = {};
                serialized[$scope.name] = $scope.tf.getValue();
                return serialized;
            };

            $scope.tf.deserialize = function (queryString) {
                if (queryString[$scope.name]) {
                    $scope.tf.selected = queryString[$scope.name];
                }
            };

            $scope.tf.clear = clear;
            $scope.tf.init = init;

            function clear() {
                $scope.tf.selected = null;
            }

            function init() {
                $scope.tf.deserialize($location.search());

                $scope.tf.filter.initialized($scope.name);

                if ($scope.dependsOn) {
                    $scope.$watch('tf.filter.filters.' + $scope.dependsOn + '.getValue()', function (newVal, oldVal) {
                        if (newVal !== oldVal) {
                            clear();
                        }
                    });
                }
            }

            init();
        }
    };
}]);