import angular from "angular";

const component = {
    template: require("./service-autocomplete.html"),
    bindings: {
        onSelect: '&?vuiSaOnSelect',
        filter: '<?vuiSaFilter'
    },
    controller: function (Service, $q) {
        'ngInject';

        this.$onInit = () => {
            this.filter = this.filter || {};
        };

        this.select = (service) => {
            Service.get({id: service.id}, (fullService) => {
                if (angular.isFunction(this.onSelect)) {
                    this.onSelect({service: fullService});
                }
            });
        };

        this.search = (name) => {
            return Service.autocomplete(this.filter).$promise;
        };
    }

};

export default angular.module("vui-service-autocomplete.module", [])
    .component("vuiServiceAutocomplete", component);