import angular from "angular";
import partCreationDialog from "../../bicycle-parts/bicycle-part-creation-dialog"

const addAction = {
    template: require('./bicycle-parts-add-action.html'),
    bindings: {
    },
    require: {
        parent: "^vuiBicycleParts"
    },
    controller: function ($mdDialog) {
        'ngInject';

        this.add = (event) => {
            const ctrl = this;

            $mdDialog.show({
                targetEvent: event,
                template: `<vui-bicycle-part-creation-dialog></vui-bicycle-part-creation-dialog>`,
                autoWrap: false,
                controllerAs: '$ctrl',
                bindToController: true,
                controller: function () {
                }
            }).then((part) => {
                if (!part) {
                    return;
                }

                this.parent.addNewPart(part);
            });
        };
    }
};

export default angular.module("vui-bicycle-parts-actions.module", [partCreationDialog.name])
    .component("vuiBpAddAction", addAction);