import app from "js/legacy-app";

app.service('CustomerBikeResource', ['$resource', function ($resource) {
    return $resource('/api/customer-bike/:id/:id1/:id2', {}, {
        getMainInfo: {
            method: "GET",
            params: {id1: "main-info"},
            isArray: false
        },
        list: {
            method: "GET",
            isArray: false,
            params: {id: "list"}
        },
        duplicate: {
            method: "PUT",
            isArray: false,
            params: {id1: "duplicate"}
        },
        getPublic: {
            method: "GET",
            params: {id1: "public"},
            isArray: false
        },
        transferBikeToNewOwner: {
            method: "POST",
            params: {id1: "transfer"},
            isArray: false
        },
        acceptBikeTransfer: {
            method: "POST",
            params: {id1: "transfer/accept"},
            isArray: false
        },
        declineBikeTransfer: {
            method: "POST",
            params: {id1: "transfer/decline"},
            isArray: false
        },
        getTransferDetails: {
            method: 'GET',
            params: {id1: "transfer/details"},
            isArray: false
        },
        generatePublicId: {
            method: "GET",
            params: {id1: "generate-public-id"},
            isArray: false
        },
        updateMainDesc: {
            method: "PUT",
            params: {id1: "main-description"},
            isArray: false
        },
        updateComponents: {
            method: "PUT",
            params: {id1: "components"},
            isArray: false
        },
        updateFixAddress: {
            method: "PUT",
            params: {id1: "address"},
            isArray: false
        },
        checkSN: {
            method: "GET",
            params: {id: "check-serial-number"},
            isArray: false
        },
        restore: {
            method: "GET",
            isArray: false,
            params: {id1: "restore"}
        },
        resendConfirmationEmail: {
            method: "GET",
            isArray: false,
            params: {id1: "resend-confirmation-email"}
        },
        changeStatus: {
            method: "PUT",
            params: {id1: "status"},
            isArray: false
        },
        enterSN: {
            method: "PUT",
            isArray: false,
            params: {id1: "serial-number"}
        },
        autocomplete: {
            method: "GET",
            isArray: true,
            params: {id: "autocomplete"}
        }
    });
}]);
