import "./landing-page-grid-three.component.scss"

export default {
    template: require('./landing-page-grid-three.component.html'),
    bindings: {
        direction: "@?gtDirection",
        align: "@?gtAlign"
    },
    transclude: {
        section1: "gtSection1",
        section2: "gtSection2",
        section3: "gtSection3"
    },
    controller: function () {
        'ngInject';

        this.directionClass = this.direction === 'rtl' ? "tw-flex-row-reverse" : "tw-flex-row";

    }
};
