import app from "js/legacy-app";

app.directive('imgShow', [function () {
    return {
        restrict: 'A',
        template: `
                    <div data-ng-show="loading" data-ng-style="{ height: height, width: width }">
                    <img src="${require(`img/loading.gif`)}"  class="loading-gif img-tmb"></div>
                    <img id="container" data-ng-show="!loading" src="{{  imgShow.original }}" class="img-tmb">
        `,
        scope: {
            imgShow: '='
        },
        link: function (scope, element, attrs) {
            scope.loading = true;
            scope.width = '500px';
            scope.height = '500px';
            var container = element.children().next();
            container.on('load', function () {
                scope.$apply(function () {
                    scope.loading = false;
                });
                scope.$apply(function () {
                    scope.height = container.prop('height') !== 0 ? (container.prop('height') + 'px') : scope.height;
                    scope.width = container.prop('width') !== 0 ? (container.prop('width') + 'px') : scope.width;
                });
            });
            scope.$watch('imgShow', function (newValue, oldValue) {
                if (newValue.original !== oldValue.original) {
                    scope.loading = true;
                }
            });
        }
    };
}]);