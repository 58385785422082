import app from "js/legacy-app";

app.filter('timerFilter', function () {
    return function (seconds) {
        if (isNaN(seconds)) {
            return seconds;
        }

        var secondPart = seconds % 60;
        var minutePart = Math.floor(seconds / 60) % 60;
        var hourPart = Math.floor(seconds / (60 * 60)) % 24;
        var dayPart = Math.floor(seconds / (60 * 60 * 24));


        return (dayPart ? (dayPart + " days ") : "") + prependZero(hourPart) + ":" + prependZero(minutePart) + ":" + prependZero(secondPart);
    };

    function prependZero(number) {
        return (number < 10) ? "0" + number : number;
    }
});