import app from "js/legacy-app";
import angular from "angular";

app.directive('dashboardRequests', ['$bookingCancellationService', function ($bookingCancellationService) {
    return {
        templateUrl: "components/request/list/dashboard-requests.html",
        scope: {
            type: '=dashboardRequests'
        },
        controller: [
            '$scope',
            '$routeParams',
            '$location',
            '$rootScope',
            'AuthService',
            'ModalService',
            'ToggleLD',
            '$requestService',
            'ErrorHandler',
            'Notification',
            '$dateTimeService',
            function (
                $scope, $routeParams, $location, $rootScope, AuthService, ModalService, ToggleLD, $requestService,
                ErrorHandler, Notification, $dateTimeService
            ) {

                let size = 10, page = 0;

                $scope.dashboardRequests = {};
                $scope.filters = {
                    status: undefined,
                    location: undefined,
                    statuses: undefined
                };
                if ($scope.type === 'history') {
                    $scope.filters.statuses = [
                        {value: 'CANCELED', label: 'Canceled'},
                        {value: 'COULD_NOT_BE_FIXED', label: 'Could Not Be Fixed'},
                        {value: 'DONE', label: 'Done'},
                        {value: 'CHARGE_FAILED', label: 'Charge Failed'},
                    ];
                } else {
                    $scope.filters.statuses = [
                        {value: 'PENDING', label: 'Pending'},
                        {value: 'IN_PROGRESS', label: 'Scheduled'},
                        {value: 'DRAFT', label: 'Awaiting Payment'},
                        {value: 'IN_DISPUTE', label: 'In Dispute'},
                        {value: 'READY_FOR_REASSIGN', label: 'Ready For Reassign'},
                    ];
                }

                $scope.loadingData = false;
                $scope.AuthService = AuthService;

                $scope.organisationId = $routeParams.organisationId;

                const requestResource = $requestService.getRequestResource(AuthService);

                $scope.searchParam = {
                    currentImg: require('img/no-bike-medium.png')
                };

                if ($routeParams.pageRequest) {
                    page = $routeParams.pageRequest;
                }

                if ($routeParams.status) {
                    $scope.filters.status = $routeParams.status;
                }

                if ($routeParams.location) {
                    $scope.filters.location = deserializeAddress($routeParams.location);
                }

                $scope.getRequestStatus = function (request) {
                    if (request && request.status) {
                        return request.status.toLowerCase().replace(/_/g, '-');
                    }
                };

                $scope.needAppointmentConfirmationFromMechanic = function (request) {
                    const activeNegotiation = getActiveNegotiation(request);
                    return activeNegotiation &&
                        activeNegotiation.appointmentDateConfirmation &&
                        activeNegotiation.appointmentDateConfirmation.needConfirmFromMechanic;
                };

                $scope.needAppointmentConfirmationFromCustomer = function (request) {
                    const activeNegotiation = getActiveNegotiation(request);
                    return !AuthService.isBikeBusinessSupervisor() && activeNegotiation &&
                        activeNegotiation.appointmentDateConfirmation &&
                        activeNegotiation.appointmentDateConfirmation.needConfirmFromCustomer;
                };

                function getActiveNegotiation(request) {
                    const inProgressNegotiations = request.negotiations.filter(function (n) {
                        return n.status === 'IN_PROGRESS';
                    });
                    return inProgressNegotiations && inProgressNegotiations.length ? inProgressNegotiations[0] : null;
                }

                function addDropdownClass($event, className) {
                    let element = angular.element($event.currentTarget);
                    let showDatesClassName = className;

                    if (element.hasClass(showDatesClassName)) {
                        element.removeClass(showDatesClassName);
                    } else {
                        element.addClass(showDatesClassName);
                    }
                }

                $scope.showAllBikes = function ($event) {
                    let className = 'rg__bikes-dropdown--opened';
                    addDropdownClass($event, className);
                };

                $scope.refresh = function () {
                    let searchParam = {
                        pageRequest: page
                    };
                    if ($scope.filters.status) {
                        searchParam.status = $scope.filters.status
                    }
                    if ($scope.filters.location) {
                        searchParam.location = serializeAddress($scope.filters.location)
                    }
                    $location.search(searchParam);
                    $scope.getRequests();
                };

                $scope.search = function () {
                    let searchParam = {
                        pageRequest: 0
                    };
                    if ($scope.filters.status) {
                        searchParam.status = $scope.filters.status
                    }
                    if ($scope.filters.location) {
                        searchParam.location = serializeAddress($scope.filters.location)
                    }
                    $location.search(searchParam);
                    $scope.getRequests();
                };

                $scope.getRequests = function () {
                    $scope.loadingData = true;
                    getRequests();
                };

                $scope.getDatesInLocalTimeZone = function (request) {
                    if (!request.appointmentDate) {
                        return {};
                    }

                    let localOffset = $dateTimeService.getLocalOffset() || 0;
                    let locationOffset = request.serviceLocation.offset || 0;

                    return {
                        from: AuthService.isMechanic() && request.online ?
                            $dateTimeService.getTimeByOffset(localOffset, request.appointmentDate.startDate) :
                            $dateTimeService.getTimeByOffset(locationOffset, request.appointmentDate.startDate),
                        to: AuthService.isMechanic() && request.online ?
                            $dateTimeService.getTimeByOffset(localOffset, request.appointmentDate.endDate) :
                            $dateTimeService.getTimeByOffset(locationOffset, request.appointmentDate.endDate)
                    };
                };

                $scope.getDateOfCompleteInLocalTimeZone = function (request) {
                    let date = !request.dateOfComplete ? request.updatedAt : request.dateOfComplete;
                    if (!date) {
                        return null;
                    }

                    let localOffset = $dateTimeService.getLocalOffset() || 0;
                    let locationOffset = request.serviceLocation.offset || 0;

                    return AuthService.isMechanic() && request.online ?
                        $dateTimeService.getTimeByOffset(localOffset, date) :
                        $dateTimeService.getTimeByOffset(locationOffset, date);
                };

                function getRequests() {
                    let query = {
                        page: page,
                        size: size
                    };

                    if (AuthService.isBikeBusinessSupervisor()) {
                        query.organisation = $scope.organisationId;
                    }

                    if ($scope.filters.status) {
                        query.status = $scope.filters.status;
                    }

                    if ($scope.filters.location) {
                        query.location = serializeAddress($scope.filters.location);
                    }

                    if ($scope.type === 'history') {
                        requestResource.history(query, function (data) {
                            getRequestsCallback(data);
                        }, function (error) {
                            $scope.loadingData = false;
                            ErrorHandler.onError(error, "Failed to load requests");
                        });
                    } else {
                        requestResource.get(query, function (data) {
                            getRequestsCallback(data);
                        }, function (error) {
                            $scope.loadingData = false;
                            ErrorHandler.onError(error, "Failed to load requests");
                        });
                    }
                }

                function serializeAddress(address) {
                    if (!address) {
                        return;
                    }
                    let result = "";
                    result += (address.address ? address.address : "") + ";";
                    result += (address.zip ? address.zip : "") + ";";
                    result += (address.city ? address.city : "") + ";";
                    result += (address.state ? address.state : "") + ";";
                    result += (address.country ? address.country : "") + ";";
                    result += address.longitude + ";";
                    result += address.latitude + ";";
                    result += (address.bounds ? address.bounds.east : "") + ";";
                    result += (address.bounds ? address.bounds.north : "") + ";";
                    result += (address.bounds ? address.bounds.south : "") + ";";
                    result += (address.bounds ? address.bounds.west : "");

                    return result;
                }

                function deserializeAddress(string) {
                    let parts = string.split(';'), result = {};

                    if (parts.length !== 11) {
                        return null;
                    }

                    result.address = parts[0];
                    result.zip = parts[1];
                    result.city = parts[2];
                    result.state = parts[3];
                    result.country = parts[4];
                    result.longitude = parts[5];
                    result.latitude = parts[6];

                    if (parts[7] && parts[8] && parts[9] && parts[10]) {
                        result.bounds = {};
                        result.bounds.east = parts[7];
                        result.bounds.north = parts[8];
                        result.bounds.south = parts[9];
                        result.bounds.west = parts[10];
                    }


                    return result;
                }

                function defaultCancel(requestId) {
                    let msg = 'Are you sure you want to cancel this booking?';

                    ModalService.yesNoMessage(null, '', msg, function (response) {
                        if (!response) return;

                        cancel(requestId);
                    });
                }

                function cancelWithCharges(requestId, penalties, group) {
                    let message = $bookingCancellationService.getCancellationMessage(penalties, group);

                    ModalService.yesNoMessage(null, '', message, function (response) {
                        if (!response) return;

                        cancel(requestId);
                    });
                }

                $scope.cancelRequest = function (requestId, group) {
                    if (!AuthService.isMechanic()) {
                        requestResource.checkCancellationWithCharges({id: requestId},
                            function (response) {
                                if (response.cancellationWithCharges) {
                                    cancelWithCharges(requestId, response.penalties, group);
                                } else {
                                    defaultCancel(requestId);
                                }
                            }, function (error) {
                                ErrorHandler.onErrorDialog(error);
                            });
                    } else {
                        ModalService.showMechanicCancellationReasonDialog(event).then(function (reason) {
                            if (!reason) {
                                return;
                            }

                            cancel(requestId, reason);
                        });
                    }
                };

                function cancel(requestId, reason) {
                    ToggleLD.show();
                    requestResource.cancel({id: requestId}, reason || {}, function (data) {
                        ToggleLD.hide();
                        Notification.success('Request has been cancelled');
                        $scope.refresh();
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onErrorDialog(error);
                    });
                }

                $scope.search = function () {
                    page = 0;
                    $scope.refresh();
                };

                $scope.prev = function () {
                    if ($scope.dashboardRequests.prev || (page !== 0 && !$scope.dashboardRequests.data)) {
                        page--;
                        $scope.refresh();
                    }
                };

                $scope.next = function () {
                    if ($scope.dashboardRequests.next) {
                        page++;
                        $scope.refresh();
                    }
                };

                let getRequestsCallback = function (data) {
                    $scope.dashboardRequests = data;

                    if (data.data.length === 0 && data.prev) {
                        $scope.prev();
                        return;
                    }
                    $scope.loadingData = false;
                };

                $scope.hoverRequestIn = function (request) {
                    if (request.images && request.images[0]) {
                        $scope.searchParam.currentImg = request.images[0].original;
                    } else {
                        $scope.searchParam.currentImg = require('img/no-bike-medium.png');
                    }
                };

                $scope.hoverRequestOut = function (request) {
                    $scope.searchParam.currentImg = require('img/no-bike-medium.png');
                };

                $scope.getCountNotification = function (request) {
                    let count = 0;

                    for (let i = 0; i < $rootScope.notifications.length; i++) {
                        let notification = $rootScope.notifications[i];
                        let metadata = notification.metadata;
                        if (metadata && request.id === metadata.requestId && !notification.read) {
                            count++;
                        }
                    }

                    return count;
                };

                $scope.refresh();

                $scope.getHref = function (request) {
                    let requestFromHistory = 'request/' + request.id + '/history';
                    let defaultRequest = 'request/' + request.id;

                    if ($scope.organisationId) {
                        requestFromHistory += '?organisationId=' + $scope.organisationId;
                        defaultRequest += '?organisationId=' + $scope.organisationId;
                    }

                    if ($scope.type === 'history') {
                        return requestFromHistory;
                    }

                    return defaultRequest;
                };

                $scope.getCreateRequestButtonHref = function () {
                    let href = '/create-request';

                    if ($routeParams.organisationId) {
                        href += '?organisationId=' + $routeParams.organisationId;

                        if ($routeParams.stationId) {
                            href += '&stationId=' + $routeParams.stationId;
                        }
                        return href;
                    }

                    return href;
                };
            }]
    };
}]);
