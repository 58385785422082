import angular from 'angular';

(function () {

    'use strict';

    function phoneNumber(PhoneService) {

        function link($scope, $element, $attrs, ngModel) {

            $scope.$watch('profile.user.phone', function (newValue, oldValue, scope) {
                var defaultCountry = 'US';

                if (!newValue && !oldValue) {
                    scope.profile = {
                        user: {
                            phone: PhoneService.getNumberPrefix(defaultCountry)
                        }
                    };

                    return;
                }

                if (!newValue) {
                    return (scope.profile.user.phone = PhoneService.getNumberPrefix(scope.selectedCountry.id));
                }

                if (!scope.selectedCountry) {
                    scope.selectedCountry = {
                        value: 1,
                        id: 'US'
                    };
                }

                scope.profile.user.phone = PhoneService.formNumber(scope.selectedCountry.id, newValue);

                PhoneService.validateNumberByLength(scope.profile.user.phone, scope);
            });

            $scope.$watch('billingAddress.address.phone', function (newValue, oldValue, scope) {
                if (!newValue && !oldValue)
                    return;

                if (!newValue) {
                    return (scope.billingAddress.address.phone = PhoneService.getNumberPrefix(scope.selectedCountry.id));
                }

                if (!scope.selectedCountry) {
                    scope.selectedCountry = {
                        value: 1,
                        id: 'US'
                    };
                }

                scope.billingAddress.address.phone = PhoneService.formNumber(scope.selectedCountry.id, newValue);

                PhoneService.validateNumberByLength(scope.billingAddress.address.phone, scope);
            });

            $scope.$watch('createOrganisation.user.phone', function (newValue, oldValue, scope) {
                if (!newValue && !oldValue)
                    return;

                if (!newValue) {
                    return (scope.createOrganisation.user.phone = PhoneService.getNumberPrefix(scope.selectedCountry.id));
                }

                if (!scope.selectedCountry) {
                    scope.selectedCountry = {
                        value: 1,
                        id: 'US'
                    };
                }

                scope.createOrganisation.user.phone = PhoneService.formNumber(scope.selectedCountry.id, newValue);

                PhoneService.validateNumberByLength(scope.createOrganisation.user.phone, scope);
            });
        }

        return {
            restrict: 'A',
            link: link
        };
    }

    var app = angular.module('legacy-app');
    app.directive('phoneNumber', ['PhoneService', phoneNumber]);
}());