import SimpleBar from 'simplebar/dist/simplebar.min';
import 'simplebar/dist/simplebar.css';

import './simple-scrollbar.scss';

export default () => {
    return {
        restrict: 'A',
        link: (scope, element, attr) => {
            scope.ps = new SimpleBar(element[0], {
                scrollbarMaxSize: 60,
            });
        },
        controller: function ($scope, $timeout) {
            'ngInject';

            $timeout(() => {
                $scope.ps.recalculate()
            }, 10);
        }
    };
}
