import app from "js/legacy-app";
import angular from "angular";

app.directive('vtModalDynamicHeight', ['$mobileBodyHeightCalculator', '$timeout', function ($mobileBodyHeightCalculator, $timeout) {
    return {
        link: function ($scope, $element, $attrs, ngModel) {
            let body = $element[0].querySelector('.vt-modal-mobile__body');
            let wrappedBody = angular.element(body);

            window.addEventListener('resize', onResize);

            function onResize() {
                let header = $element[0].querySelector('.vt-modal-mobile__header');
                let footer = $element[0].querySelector('.vt-modal-mobile__footer');

                let height = $mobileBodyHeightCalculator.calculate(header, footer);
                wrappedBody.css('height', height);
            }

            $timeout(function () {
                onResize();
            }, 10);
        }
    };
}]);
