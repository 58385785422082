import app from "js/legacy-app";

app.directive('profileTools', [function () {
    return {
        templateUrl: "template/settings/tabs/tools/profile-tools.html",
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: ['$scope', 'AuthService', 'User', 'Tools', 'Notification', 'ModalService', '$q', 'ErrorHandler',
            function ($scope, AuthService, User, Tools, Notification, ModalService, $q, ErrorHandler) {

                var ptc = {};

                $scope.changeTools = {
                    checkedAllIntermediate: false,
                    checkedAllExpert: false
                };

                $scope.checkAllTools = function (level) {
                    var newValue = false;
                    if (level === 'INTERMEDIATE') {
                        newValue = $scope.changeTools.checkedAllIntermediate;
                    } else {
                        newValue = $scope.changeTools.checkedAllExpert;
                    }
                    for (var i in $scope.changeTools.allTools) {
                        if ($scope.changeTools.allTools[i].type === level) {
                            $scope.changeTools.allTools[i].checked = newValue;
                        }
                    }
                };

                $scope.checkTool = function (level) {
                    for (var i in $scope.changeTools.allTools) {
                        if ($scope.changeTools.allTools[i].type === level) {
                            if (!$scope.changeTools.allTools[i].checked) {
                                if (level === 'INTERMEDIATE') {
                                    $scope.changeTools.checkedAllIntermediate = false;
                                    return;
                                } else {
                                    $scope.changeTools.checkedAllExpert = false;
                                    return;
                                }
                            }
                        }
                    }

                    if (level === 'INTERMEDIATE') {
                        $scope.changeTools.checkedAllIntermediate = true;
                    } else {
                        $scope.changeTools.checkedAllExpert = true;
                    }
                };

                $scope.changeTools.updateTools = function () {
                    var message = 'Are you sure you want to change your tools?';
                    ModalService.yesNoMessage(null, '', message, function (response) {
                        if (response === true) {
                            var ids = [],
                                showWarning = false;
                            for (var i in $scope.changeTools.allTools) {
                                if ($scope.changeTools.allTools[i].checked) {
                                    ids.push($scope.changeTools.allTools[i].id);
                                } else if ($scope.changeTools.allTools[i].type === 'INTERMEDIATE') {
                                    showWarning = true;
                                }
                            }

                            if (showWarning) {
                                var message = 'You must have all required tools otherwise you will lose all checked mandatory tools. Save anyway?';
                                warnAboutTools(message, ids);
                            }
                            else {
                                saveTools(ids);
                            }
                        }
                    });
                };

                var warnAboutTools = function (message, toolsIds) {
                    ModalService.yesNoMessage(null, '', message, function (response) {
                        if (response === true) {
                            uncheckExpertToolsAndSave(toolsIds);
                        }
                    });
                };

                var uncheckExpertToolsAndSave = function (toolsIds) {
                    for (var i in $scope.changeTools.allTools) {
                        if ($scope.changeTools.allTools[i].type === 'EXPERT') {
                            $scope.changeTools.allTools[i].checked = false;

                            var indexOfElement = toolsIds.indexOf($scope.changeTools.allTools[i].id);
                            if (indexOfElement < 0) {
                                continue;
                            }

                            toolsIds.splice(indexOfElement, 1);
                        }
                    }

                    saveTools(toolsIds);
                };

                var saveTools = function (toolsIds) {
                    User.chooseToolsVerification(toolsIds, function (data) {
                        Notification.success('Tools were successfully updated');
                        $scope.checkTool('INTERMEDIATE');
                        $scope.checkTool('EXPERT');
                    });
                };

                $scope.changeTools.checkAllIntermediateTools = function () {
                    for (var i in $scope.changeTools.allTools) {
                        if ($scope.changeTools.allTools[i].type === 'INTERMEDIATE' && !$scope.changeTools.allTools[i].checked) {

                            return false;
                        }
                    }

                    return true;
                };

                ptc.getVerificationState = function () {
                    return User.getVerification(function (data) {
                        $scope.verification = data;
                    }).$promise;
                };

                ptc.getTools = function () {
                    return Tools.get(function (data) {
                        $scope.changeTools.allTools = data.data;
                    }).$promise;
                };

                ptc.markToolsAsChecked = function () {
                    if ($scope.verification && $scope.verification.toolsVerification && $scope.verification.toolsVerification.toolIds) {
                        for (var i in $scope.changeTools.allTools) {
                            if ($scope.verification.toolsVerification.toolIds.indexOf($scope.changeTools.allTools[i].id) > -1) {
                                $scope.changeTools.allTools[i].checked = true;
                            }
                        }
                    }

                    $scope.checkTool('INTERMEDIATE');
                    $scope.checkTool('EXPERT');
                };

                (function init() {
                    $q.all([ptc.getVerificationState(), ptc.getTools()]).then(function () {
                        ptc.markToolsAsChecked();
                    }).catch(function (error) {
                        ErrorHandler.onError(error, "Failed to load tools");
                    });
                })();
            }
        ]
    };
}]);
