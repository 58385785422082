import angular from "angular";
import bicyclesActions from "./booking-bicycles-actions";
import defaultBikeImage from "img/no-bike.png";
import bicycleParts from "../../bicycle-parts";
import "./booking-bicycles-card.scss";

const component = {
    template: require('./booking-bicycles-card.html'),
    bindings: {
        onSubmit: "&?vuiBbOnSubmit",
        model: "<?vuiBbModel",
        options: "<?vuiBbOptions"
    },
    transclude: {
        actions: "?vuiBookingBicyclesActions"
    },
    controller: function (Notification) {
        'ngInject';

        this.$onInit = () => {
            this.defaultBikeImage = defaultBikeImage;
            this.bikes = {
                showMore: false,
                animate: "0.3",
                minHeight: 200
            };
            this.model = this.model || [];
        };

        this.$onChanges = (changes) => {
            if (this.options && this.options.maxBikes && this.model) {
                if (this.options.maxBikes < this.model.length) {

                    if (changes.model && (!changes.model.previousValue || changes.model.currentValue.length !== changes.model.previousValue.length)) {
                        Notification.warning("Amount of bicycles must be no more than " + this.options.maxBikes);
                        return;
                    }

                    if (changes.options && (changes.options.currentValue !== changes.options.previousValue)) {
                        Notification.warning("Amount of bicycles must be no more than " + this.options.maxBikes);
                        return;
                    }
                }
            }

            if (this.options && this.options.minBikes && this.model) {
                if (this.options.minBikes > this.model.length && changes.model) {
                    if (this.options.minBikes === 1) {
                        Notification.warning("At least" + this.options.minBikes + " bicycle must be specified");
                    } else {
                        Notification.warning("At least" + this.options.minBikes + " bicycles must be specified");
                    }
                }
            }

        };

        this.submit = function () {
            if (angular.isFunction(this.onSubmit)) {
                this.onSubmit({bikes: this.model});
            }
        };

        this.remove = (index) => {
            if (this.model.length === 0) {
                return;
            }

            this.model.splice(index, 1);
            this.submit();
        };

        this.onUpdateParts = (bikeId, parts) => {
            let index = this.model.indexOfObject('id', bikeId);
            if (index < 0) return;

            this.model[index].parts = parts;
        };

    }
};

export default angular.module("vui-booking-bicycles-card.module", [bicyclesActions.name, bicycleParts.name])
    .component('vuiBookingBicycles', component);
