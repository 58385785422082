import app from "js/legacy-app";

app.directive('tpNegotiationMinTime', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            var tpNegotiationMinValidator = function (value) {
                var min = new Date('1/01/2000' + ' ' + attr.tpNegotiationMinTime);
                var newValue = new Date('1/01/2000' + ' ' + value.split(' - ')[0]);
                var newValue2 = new Date('1/01/2000' + ' ' + value.split(' - ')[1]);
                if (newValue && newValue.getTime() < min.getTime() || newValue2 && newValue2.getTime() < min.getTime()) {
                    ctrl.$setValidity('tpNegotiationMinTime', false);
                    return value;
                } else {
                    ctrl.$setValidity('tpNegotiationMinTime', true);
                    return value;
                }
            };

            ctrl.$parsers.push(tpNegotiationMinValidator);
            ctrl.$formatters.push(tpNegotiationMinValidator);
        }
    };
});
