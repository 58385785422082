import app from "js/legacy-app";

app.service('$pageHeader', ['$rootScope', '$timeout', '$location', '$mdMedia', function ($rootScope, $timeout, $location, $mdMedia) {

    var pagesWithoutHeader = [
        {path: "/home"},
        {path: "/coming-soon"},
        {path: "/create-request", media: "(max-width: 1000px)"},
        {path: "/maintenance"},
        {path: '/add-card', mobileOnly: true},
        {path: '/our-coverage'},
        {path: '/locations'},
        {path: '/for-bike-fleets'},
        {path: '/for-bike-brands'},
        {path: '/bicycle-assembly'},
        {path: '/diagnostic-and-repair'},
        {path: '/become-a-mechanic'},
        {path: '/sign-up-bike-shop'},
        {path: '/sign-up-mobile-mechanic'},
        {path: '/sign-up-individual-mechanic'},
        {path: '/login'},
        {path: '/forgot-password'},
        {path: '/restore-password'},
        {path: '/activate-account'},
        {path: '/complete-registration'},
        {path: '/sign-up-user'},
        {path: '/sign-up-mechanic'},
        {path: '/completed-registration'},
        {path: "/confirm-registration"},
        {path: "/cancel-registration"},
        {path: '/sso/'},
        {path: '/complete-social-registration'},
        {path: '/contact-us'},
        {path: '/terms-and-conditions'},
        {path: '/privacy-policy'},
        {path: '/payment-process'},
    ];

    $rootScope.$on('$locationChangeSuccess', function () {
        $timeout(function () {
            var filtered = pagesWithoutHeader.filter(function (p) {
                return $location.path() === "/" || $location.path().startsWith(p.path);
            }).filter(function (p) {
                if (p.mobileOnly) {
                    return $mdMedia('(max-width: 470px)');
                } else if (p.media) {
                    return $mdMedia(p.media);
                }
                return p;
            });
            if (filtered.length > 0) {
                hide();
            } else {
                show();
            }
        });
    });


    var hide = function () {
        $rootScope.noHeader = true;
    };

    var show = function () {
        $rootScope.noHeader = false;
    };

    return {
        hide: hide,
        show: show
    };
}
]);

