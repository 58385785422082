import app from "js/legacy-app";

app.controller('ChooseAvatarDialogController',
    ['$scope', '$mdDialog', 'domain', 'Upload', 'FileService', 'Notification',
        'ToggleLD', 'User', 'AuthService', 'Documents', 'type', 'title',
        function ($scope, $mdDialog, domain, Upload, FileService, Notification,
                  ToggleLD, User, AuthService, Documents, type, title) {

            $scope.uploadAvatar = {
                user: domain,
                showImg: true,
                disabledUpload: false,
                title: title
            };

            $scope.cropper = {};
            $scope.cropper.area = {};
            $scope.cropper.sourceImage = null;
            $scope.cropper.croppedImage = null;

            $scope.getBgImgClass = function () {
                if (type === 'user') {
                    if ($scope.uploadAvatar.user.role === "MECHANIC") {
                        return "mechanic-bg-img";
                    }
                    if ($scope.uploadAvatar.user.role === "ADMIN") {
                        return "admin-bg-img";
                    }
                } else if (type === 'organisation' || type === 'station') {
                    return "organisation-bg-img";
                }

                return "";
            };

            $scope.chooseFile = function (files, invalidFiles) {
                var file;
                if (files[0]) {
                    file = files[0];
                } else if (invalidFiles[0]) {
                    file = invalidFiles[0];
                }

                if (!FileService.isValidFile(file)) {
                    return;
                }
                $scope.cropper.sourceImage = file;
            };

            $scope.close = function () {
                $mdDialog.hide();
            };

            $scope.upload = function () {
                if (!$scope.cropper.sourceImage || !$scope.cropper.croppedImage) {
                    return;
                }

                $scope.uploadAvatar.disabledUpload = true;
                var image = $scope.cropper.sourceImage;
                if (!FileService.isValidFile(image, [FileService.mimeImages])) {
                    return;
                }

                ToggleLD.show();
                var cropOptions = {
                    x: $scope.cropper.area.cropImageLeft,
                    y: $scope.cropper.area.cropImageTop,
                    w: $scope.cropper.area.cropImageWidth,
                    h: $scope.cropper.area.cropImageHeight
                };

                if (type === 'organisation') {
                    Documents.organisationAvatar().upload($scope.cropper.sourceImage, cropOptions, $scope.uploadAvatar.user.id,
                        onSuccess, onUploadError);
                } else if (type === 'station') {
                    Documents.stationAvatar().upload($scope.cropper.sourceImage, cropOptions, $scope.uploadAvatar.user.id,
                        onSuccess, onUploadError);
                } else if (type === 'user') {
                    Documents.avatar().upload($scope.cropper.sourceImage, cropOptions, onSuccess, onUploadError);
                } else if (type === 'user-profile') {
                    Documents.userProfileAvatar($scope.uploadAvatar.user.id).upload($scope.cropper.sourceImage, cropOptions, onSuccess, onUploadError);
                }
            };

            var onSuccess = function (response) {
                ToggleLD.hide();
                Notification.success('Avatar picture has been successfully uploaded');
                $mdDialog.hide(response);
                $scope.images = [];
                $scope.uploadAvatar.disabledUpload = false;
                if (type === 'user') {
                    AuthService.user().avatar = response.avatar;
                }
            };

            var onUploadError = function (error) {
                ToggleLD.hide();
                $scope.uploadAvatar.disabledUpload = false;
                Notification.error("Failed to upload avatar picture");
            };
        }
    ]);
