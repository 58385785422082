import angular from "angular";
import mechanicCard from "../mechanic-card/selectable";

const component = {
    template: require('./choose-mechanic.html'),
    bindings: {
        filter: "<vuiCmFilter",
        onSelect: "&vuiCmOnSelect"
    },
    controller: function (Mechanics, ErrorHandler, $q, $routeParams) {
        'ngInject';

        this.onSelectMechanic = (mechanic) => {
            this.onSelect({mechanic: mechanic});
            this.selectedMechanic = mechanic;
        };

        this.isSelected = (mechanic) => {
            return !!(this.selectedMechanic && mechanic && this.selectedMechanic.id === mechanic.id);
        };

        this.search = (filter) => {
            angular.extend(filter, this.filter);
            return Mechanics.search(filter).$promise;
        };

        this.applyConcreteMechanic = function (mechanicId) {
            let self = this;

            $q.when(self.search({
                mechanic: mechanicId,
                page: 0,
                size: 1
            })).then(function (mechanics) {
                if (!mechanics || !mechanics.data) {
                    return;
                }

                let foundMechanics = mechanics.data.filter(function (mechanic) {
                    return mechanicId === mechanic.id;
                });

                if (!foundMechanics || foundMechanics.length === 0) {
                    self.onSelect({mechanic: null});
                    self.selectedMechanic = null;
                    return;
                }


                self.onSelect({mechanic: foundMechanics[0]});
                self.selectedMechanic = foundMechanics[0];
            }, function (error) {
                ErrorHandler.onError(error, "Mechanic does not provide this service any more");
            });
        };

        this.$onInit = () => {
            let mechanicId = $routeParams.mechanicId;
            if (mechanicId) {
                this.applyConcreteMechanic(mechanicId);
            }
        };
    }
};

export default angular.module("vui-choose-mechanic.module", [mechanicCard.name])
    .component('vuiChooseMechanic', component);
