"use strict";

import angular from "angular";
import "angular-elastic";
import "angular-input-stars";
import "angular-password";
import "angularjs-slider";
import "angular-chart.js";
import "chart.js";
import "exif-js";
import "md-pickers";
import "ng-file-upload";
import "angular-file-upload-shim";
import "ng-img-crop-full-extended";
import "ng-mask";
import "ua-parser-js";
import "ui-lite/dist/js/ui-lite";
import "angular-sticky-plugin";
import "angular-socialshare";
import "angular-timeago";
import "smartbanner.js/dist/smartbanner.js";
import "ng-infinite-scroll";
import "ng-csv";
import "vt-misc/strong-smooth-scroll";

import "ui-lite/dist/css/ui-lite.css";
import "md-pickers/dist/mdPickers.css";
import "smartbanner.js/dist/smartbanner.min.css";
import "angular-input-stars/angular-input-stars.css";
import "angularjs-slider/dist/rzslider.css";
import "ng-img-crop-full-extended/compile/minified/ng-img-crop.css";

import "../sass/app.scss";

export default angular.module('legacy-app', [
    'ngFileUpload',
    'ngMask',
    'StrongSmoothScroll',
    'monospaced.elastic',
    'angular-input-stars',
    'ngPassword',
    'rzModule',
    'ngImgCrop',
    'mdPickers',
    'chart.js',
    'feature-flags',
    'hl.sticky',
    '720kb.socialshare',
    'yaru22.angular-timeago',
    'infinite-scroll',
    'ngCsv'
]);
