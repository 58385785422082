import app from "js/legacy-app";

app.service('BikeHistory', ['$resource', function($resource){
    return $resource('/api/bike-history/:id/:id1', {}, {
        byBike: {
            method: "GET",
            params: {id : "by-bike"},
            isArray: false
        },
        byPublicBikeId: {
            method: "GET",
            params: {id : "by-public-bike-id"},
            isArray: false
        }
    });
}]);