import app from "js/legacy-app";

app.directive('profileAddressCard', ['User', 'ErrorHandler', 'ToggleLD', 'LocationService', 'Notification',
    function (User, ErrorHandler, ToggleLD, LocationService, Notification) {
        return {
            restrict: 'E',
            transclude: true,
            templateUrl: 'template/profile/address/profile-address-card.html',
            scope: {
                model: "=pacModel",
                editCallback: "=?pacOnEdit",
                removeCallback: "=?pacOnRemove"
            },

            link: function ($scope, element, $attr) {

                $scope.pa = {
                    highlightIcons: {
                        'HOME': 'fa fa-home',
                        'WORK': 'fa fa-briefcase',
                        'OTHER': 'fa fa-star'
                    }
                };

                $scope.pa.edit = function ($event) {
                    LocationService.editProfileAddress($scope.model, $event).then(function () {
                        if ($scope.editCallback) {
                            $scope.editCallback($scope.model);
                        }
                    });
                };

                $scope.pa.remove = function ($event) {
                    LocationService.removeProfileAddress($scope.model, $event).then(function () {
                        if ($scope.removeCallback) {
                            $scope.removeCallback($scope.model);
                        }
                    });
                };
            }
        };
    }]);