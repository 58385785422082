import app from "js/legacy-app";
import angular from "angular";

app.directive('bicyclesStep', ['$bikeService', '$timeout', '$q', '$routeParams', 'AuthService', 'ModalService',
    function ($bikeService, $timeout, $q, $routeParams, AuthService, ModalService) {
        return {
            templateUrl: "components/request/create/steps/bicycles-step.html",
            controller: ['$scope', 'Notification', 'Brand', 'ErrorHandler', 'Organisation', 'Station',
                function ($scope, Notification, Brand, ErrorHandler, Organisation, Station) {

                    var bicyclesStep = {
                        resource: $bikeService.getResourceClass(),
                        paging: {
                            current: -1,
                            hasNext: true,
                            hasPrev: false
                        },

                        refresh: function () {
                            this.paging = {
                                current: -1,
                                hasNext: true,
                                hasPrev: false
                            };
                            $scope.bicyclesStep.bikeList = {};
                            $scope.bicyclesStep.oldBikes = [];
                            return $scope.bicyclesStep.search($scope.bicyclesStep.filter);
                        }
                    };

                    $scope.bicyclesStep = {
                        bikeList: {},
                        tabs: {
                            'select-bicycles': {name: 'Select bicycles', selected: true},
                            'add-bicycle': {name: 'Add new bicycle', selected: false}
                        },
                        page: 0,
                        filter: {
                            name: '',
                            size: 10,
                            detalization: 'M'
                        },

                        bikeQuery: '',
                        newBikes: [],
                        concreteBike: null,
                        selectedBikes: [],
                        oldBikes: [],
                        options: {
                            id: "bicycles",
                            position: 4,
                            title: "Add bicycle",

                            back: function () {
                                $scope.wizard.back();
                            },
                            next: function () {
                                var selectedBikes = $scope.bicyclesStep.selectedBikes;
                                if ($scope.bcrc.needBike() && selectedBikes.length === 0) {
                                    Notification.warning('You need to select a bicycle');
                                    return;
                                }

                                var maxBikes = $scope.bcrc.maxBikes;
                                if ($scope.bcrc.needBike() && $scope.bcrc.requestIsGroup() &&
                                    selectedBikes.length > maxBikes) {
                                    Notification.warning('You can select max ' + maxBikes + ' bicycles');
                                    return;
                                }

                                $scope.wizard.next();
                            },
                            onEnter: function () {
                                bicyclesStep.setSelectedBikeToBikeList();
                                return bicyclesStep.refresh().then(function (bikes) {
                                    var step = $scope.bicyclesStep;
                                    if (bikes.data.length === 0 &&
                                        step.selectedBikes.length === 0 &&
                                        !step.concreteBike &&
                                        step.newBikes.length === 0) {
                                        step.selectOption('add-bicycle');
                                    } else {
                                        step.selectOption('select-bicycles');
                                    }
                                });
                            }
                        },
                        auth: AuthService
                    };

                    bicyclesStep.generateBikeId = function () {
                        return 'id' + (new Date()).getTime();
                    };

                    $scope.bicyclesStep.onTextChange = function () {
                        bicyclesStep.refresh();
                    };

                    $scope.bicyclesStep.search = function (query) {
                        if (!bicyclesStep.paging.hasNext || !$scope.bcrc.services.selected) {
                            return;
                        }
                        query.page = bicyclesStep.paging.current + 1;
                        var promise = $scope.bicyclesStep.loadBikes(query);
                        bicyclesStep.paging.hasNext = false;
                        return promise;
                    };

                    $scope.bicyclesStep.clearBikeInput = function () {
                        $scope.bicyclesStep.filter.name = '';
                        bicyclesStep.refresh();
                    };

                    $scope.bicyclesStep.onSelect = function (item) {
                        var bike = $scope.bicyclesStep.getAllBikes().filter(function (bike) {
                            return bike.id === item.id;
                        })[0];

                        if (!bike) return;

                        if (bike.selected) {
                            bike.selected = false;
                            var index = $scope.bicyclesStep.selectedBikes.indexOfObject("id", bike.id);
                            if (index !== -1) {
                                $scope.bicyclesStep.selectedBikes.splice(index, 1);
                            }
                        } else {
                            bike.selected = true;
                            $scope.bicyclesStep.selectedBikes.push(bike);
                            if ($scope.bcrc.needBike() && !$scope.bcrc.requestIsGroup()) {
                                $scope.bicyclesStep.selectedBikes = [];
                                $scope.bicyclesStep.selectedBikes.push(bike);
                                $scope.wizard.next();
                            }
                        }

                        if ($scope.bicyclesStep.selectedBikes.length === 0) {
                            $scope.bicyclesStep.uncomplete();
                        }
                    };

                    $scope.bicyclesStep.onSelectAll = () => {
                        const bikes = $scope.bicyclesStep.getAllBikes().filter(function (bike) {
                            return !bike.selected;
                        });

                        for (let i = 0; i < bikes.length; i++) {
                            if (!bikes[i].selected) {
                                $scope.bicyclesStep.onSelect(bikes[i])
                            }
                        }
                    }

                    $scope.bicyclesStep.isSelected = function (item) {
                        return $scope.bicyclesStep.selectedBikes.filter(function (bike) {
                            return bike.id === item.id && bike.selected;
                        })[0];
                    };

                    $scope.bicyclesStep.selectBike = function (bike) {
                        bike.selected = true;
                        $scope.bicyclesStep.selectedBikes.push(bike);
                        $scope.bicyclesStep.concreteBike = bike;
                    };

                    $scope.bicyclesStep.selected = function () {
                        return $scope.bicyclesStep.selectedBikes.length > 0;
                    };

                    $scope.bicyclesStep.uncomplete = function () {
                        $scope.bicyclesStep.selectedBikes = [];
                        $scope.wizard.uncompleteStep('bicycles');
                    };

                    $scope.bicyclesStep.getBrands = function (brand) {
                        return Brand.autocomplete({name: brand, page: 0, size: 10}).$promise;
                    };

                    $scope.bicyclesStep.addBicycle = function (form) {

                        if (!form.$valid || !isValid()) return;

                        $bikeService.checkBikeStatus($scope.bcrc.newBike.serialNumber, continueCallback);

                        function continueCallback() {
                            var newBike = $scope.bcrc.newBike;
                            if (!bicyclesStep.existsSerialNumber(newBike.serialNumber)) {
                                var bike = {
                                    serialNumber: newBike.serialNumber,
                                    make: newBike.make,
                                    model: newBike.model,
                                    type: $scope.bcrc.bicycle.type.name,
                                    id: bicyclesStep.generateBikeId(),
                                    new: true,
                                    selected: true
                                };

                                bicyclesStep.addOrganisationInfoToBike(bike);
                                $scope.bicyclesStep.newBikes.push(bike);
                                $scope.bicyclesStep.selectedBikes.unshift(bike);
                                $scope.bicyclesStep.selectOption('select-bicycles');
                                bicyclesStep.clearInputFieldsFromBikeForm();
                            }
                        }

                        function isValid() {
                            if ($scope.bcrc.needBike() && !$scope.bcrc.requestIsGroup() &&
                                $scope.bicyclesStep.selectedBikes.length > 0) {
                                Notification.warning('You can select only one bicycle');
                                return false;
                            }

                            if (AuthService.isBikeBusinessSupervisor()) {
                                var selectedOrganisation = $scope.autocomplete.selectedOrganisation;
                                if (!selectedOrganisation || !selectedOrganisation.id) {
                                    Notification.warning("You need select organisation for this bicycle");
                                    return false;
                                }
                            }

                            return true;
                        }
                    };

                    $scope.bicyclesStep.loadBikes = function (query) {
                        return bicyclesStep.resource.list(query, function (data) {
                            bicyclesStep.onLoadBikes(data);
                        }).$promise;
                    };

                    $scope.bicyclesStep.selectOption = function (option) {
                        $timeout(function () {
                            for (var tab in $scope.bicyclesStep.tabs) {
                                $scope.bicyclesStep.tabs[tab].selected = false;
                            }

                            $scope.bicyclesStep.tabs[option].selected = true;
                        }, 100);
                    };

                    $scope.bicyclesStep.isSelectedTab = function (tab) {
                        return $scope.bicyclesStep.tabs[tab].selected;
                    };


                    $scope.autocomplete.searchOrganisation = function (organisationSearchText) {
                        var deferred = $q.defer();
                        if (!organisationSearchText || organisationSearchText.length < 3) {
                            return [];
                        }

                        Organisation.findByName({name: organisationSearchText}, {}, function (data) {
                            return deferred.resolve(data);
                        }, function (error) {
                            return deferred.reject(error);
                        });

                        return deferred.promise;
                    };

                    $scope.autocomplete.searchStation = function (stationSearchText) {
                        var deferred = $q.defer();

                        Station.findByName({
                                param2: $scope.autocomplete.selectedOrganisation.id,
                                name: stationSearchText
                            },
                            function (data) {
                                return deferred.resolve(data);
                            }, function (error) {
                                return deferred.reject(error);
                            });

                        return deferred.promise;
                    };

                    $scope.autocomplete.selectStation = function (selectedStation) {
                        if (!selectedStation) {
                            $scope.autocomplete.selectedStation = null;
                            $scope.autocomplete.showSelectAllFromStationBtn = false;
                            $scope.autocomplete.selectedBikes = [];
                            return;
                        }

                        $scope.autocomplete.showSelectAllFromStationBtn = true;
                        $scope.autocomplete.selectedStation = selectedStation;

                        for (var i = 0; i < $scope.autocomplete.selectedBikes.length; i++) {
                            var selectedBike = $scope.autocomplete.selectedBikes[i];
                            if (selectedBike.new) {
                                selectedBike.owningOrganization.stationId = $scope.autocomplete.selectedStation.id;
                            }
                        }
                    };

                    $scope.autocomplete.needShowStationAutocomplete = function () {
                        var selectedOrganisation = $scope.autocomplete.selectedOrganisation;

                        if (AuthService.isUser()) {
                            return false;
                        }

                        if (AuthService.isBikeBusinessSupervisor() && !selectedOrganisation) {
                            return false;
                        }

                        return true;
                    };

                    $scope.bicyclesStep.getAllBikes = function () {
                        var bikes = [];
                        var bikesObj = {};

                        var concreteBike = $scope.bicyclesStep.concreteBike;
                        var oldBikes = $scope.bicyclesStep.oldBikes;
                        var newBikes = $scope.bicyclesStep.newBikes;

                        if (concreteBike) {
                            bikes.push(concreteBike);
                            bikesObj[concreteBike.id] = true;
                        }

                        addUniqueBikes(newBikes);
                        addUniqueBikes(oldBikes);

                        function addUniqueBikes(list) {
                            angular.forEach(list, function (bike) {
                                if (!bikesObj[bike.id]) {
                                    bikesObj[bike.id] = true;
                                    bikes.push(bike);
                                }
                            });
                        }

                        return bikes;
                    };

                    $scope.bicyclesStep.getBikes = function () {
                        var bikes = [];
                        if (!$scope.bcrc.needBike()) {
                            return bikes;
                        }

                        var selectedBikes = $scope.bicyclesStep.selectedBikes;
                        angular.forEach(selectedBikes, function (bike) {
                            var rBike = {};
                            if (bike.new) {
                                rBike.serialNumber = bike.serialNumber;
                                rBike.make = bike.make;
                                rBike.model = bike.model;
                                rBike.type = bike.type;
                                rBike.new = true;
                                rBike.owningOrganization = bike.owningOrganization;
                            } else {
                                rBike.old = true;
                                rBike.id = bike.id;
                            }
                            bikes.push(rBike);
                        });

                        return bikes;
                    };

                    bicyclesStep.setSelectedBikeToBikeList = function () {
                        var selectedBikes = $scope.bicyclesStep.selectedBikes;
                        var bikeId = $scope.bcrc.bikeId;
                        if ($scope.bcrc.requestIsGroup() && bikeId && selectedBikes.length === 0) {
                            bicyclesStep.resource.getMainInfo({id: bikeId}, function (bikeInfo) {
                                bikeInfo.old = true;
                                $scope.bicyclesStep.selectedBikes.push(bikeInfo);
                            }, function (error) {
                                ErrorHandler.onError(error, "Failed to load bicycle");
                            });
                        }
                    };

                    bicyclesStep.addOrganisationInfoToBike = function (bike) {
                        var selectedOrganisation = $scope.autocomplete.selectedOrganisation;
                        if (AuthService.isBikeBusinessSupervisor() || AuthService.isBikeOrganisationOwner()) {
                            bike.owningOrganization = {
                                organisationId: selectedOrganisation.id
                            };

                            var selectedStation = $scope.autocomplete.selectedStation;
                            if (selectedStation && selectedStation.id) {
                                bike.owningOrganization.stationId = selectedStation.id;
                            }
                        }
                    };

                    bicyclesStep.existsSerialNumber = function (serialNumber) {
                        if (!serialNumber) {
                            return false;
                        }

                        var allBikes = $scope.bicyclesStep.getAllBikes();
                        var exists = false;
                        angular.forEach(allBikes, function (bike) {
                            if (serialNumber === bike.serialNumber) {
                                var msg = 'Bicycle with the serial number ' + serialNumber + ' is already selected';
                                ModalService.showMessage(null, msg);
                                exists = true;
                            }
                        });

                        return exists;
                    };

                    bicyclesStep.clearInputFieldsFromBikeForm = function () {
                        $timeout(function () {
                            $scope.bcrc.newBike.serialNumber = '';
                            $scope.bcrc.newBike.make = '';
                            $scope.bcrc.newBike.model = '';
                            $scope.bcrc.bicycle.type = undefined;
                        }, 300);
                    };

                    bicyclesStep.onLoadBikes = function (data) {
                        bicyclesStep.paging = {
                            hasNext: data.next,
                            hasPrev: data.prev,
                            current: data.current,
                        };

                        var step = $scope.bicyclesStep;
                        bicyclesStep.addBikesToList(data);
                        bicyclesStep.mergeBikes(step.oldBikes, step.selectedBikes);
                    };

                    bicyclesStep.addBikesToList = function (message) {
                        var bikeList = message.data;
                        for (var i = 0; i < bikeList.length; i++) {
                            if (!$scope.bicyclesStep.bikeList[bikeList[i].id]) {
                                $scope.bicyclesStep.bikeList[bikeList[i].id] = true;
                                bikeList[i].old = true;
                                $scope.bicyclesStep.oldBikes.push(bikeList[i]);
                            }
                        }
                    };

                    bicyclesStep.mergeBikes = function (allBikes, selectedBikes) {
                        if (selectedBikes.length === 0) {
                            return;
                        }

                        angular.forEach(selectedBikes, function (sBike) {
                            allBikes.filter(function (bike) {
                                return bike.id === sBike.id;
                            }).forEach(function (b) {
                                b.selected = true;
                            });
                        });
                    };

                    var toggleWatchers = function () {
                        var watcher;
                        return function () {
                            if (watcher) {
                                watcher();
                                watcher = undefined;
                            } else {
                                watcher = $scope.$watch('bcrc.services.selected', function (newVal, oldVal) {
                                    if (newVal && !angular.equals(newVal, oldVal)) {
                                        $scope.bicyclesStep.uncomplete();
                                    }
                                });
                            }
                        };
                    };

                    $scope.bicyclesStep.toggleWatchers = toggleWatchers();
                    $scope.bicyclesStep.toggleWatchers();


                    $scope.$watch('autocomplete.selectedOrganisation', function (newVal, oldVal) {
                        if (newVal && !angular.equals(newVal, oldVal)) {
                            $scope.bicyclesStep.filter.organisation = newVal.id;
                        }
                    });

                    $scope.$watch('autocomplete.selectedStation', function (newVal, oldVal) {
                        if (!angular.equals(newVal, oldVal)) {
                            $scope.bicyclesStep.filter.station = newVal ? newVal.id : null;
                            bicyclesStep.refresh();
                        }
                    });

                    $scope.bicyclesStep.applyConcreteBike = function () {
                        var bikeId = $scope.bcrc.bikeId;
                        if (bikeId) {
                            bicyclesStep.resource.getMainInfo({id: bikeId}, function (bike) {
                                bike.old = true;
                                $scope.bicyclesStep.selectBike(bike);
                            });
                        }
                    };

                    (function init() {
                        $scope.bicyclesStep.applyConcreteBike();
                    })();
                }
            ]
        };
    }])
;
