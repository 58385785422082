import app from "js/legacy-app";

app.directive('vtFormFooter', [function () {
    return {
        templateUrl: "components/form/vt-form-footer.html",
        replace: true,
        transclude: true,
        require: '^^vtForm',

        link: function ($scope, elem, attrs, form) {
            $scope.vtForm = form.vf.vtForm;
        }
    };
}]);