import app from "js/legacy-app";

app.directive('mechanicListItem',
    ['ToggleLD', 'ErrorHandler', '$mdDialog', '$mechanicService', '$mechanicPublicServicesProvider', 'AuthService',
        function (ToggleLD, ErrorHandler, $mdDialog, $mechanicService, $mechanicPublicServicesProvider, AuthService) {
            return {
                templateUrl: 'components/mechanic-list/mechanic-list-item/mechanic-list-item.html',
                scope: {
                    mechanic: '=mliMechanic',
                    location: '=?mliLocation',
                    mechanicSearchFn: '=?mliMechanicSearchFn'
                },
                controller: ['$scope', function ($scope) {

                    $scope.mli = {};

                    let msi = {};

                    $scope.mli.schedule = function ($event) {
                        $mechanicService.scheduleMechanic($event, $scope.mechanic, $scope.location, $scope.mechanicSearchFn);
                    };

                    $scope.mli.canSchedule = function (mechanic, event) {
                        if (!AuthService.isLogin()) {
                            return true;
                        }

                        if (AuthService.isMechanic() || AuthService.isAdmin() || AuthService.isPartiallyRegistered()) {
                            return false;
                        }

                        if (mechanic.services.length === 0) {
                            return false;
                        }

                        return true;
                    };

                    $scope.mli.showProfileDescription = function (bio, $event) {
                        $mdDialog.show({
                            targetEvent: $event,
                            template: '<md-dialog aria-label="Description" class="vt-modal">' +
                            '  <div class="vt-modal__body">' +
                            '    {{bio}}' +
                            '  </div>' +
                            '  <div class="vt-modal__footer align-right">' +
                            '    <button data-ng-click="closeDialog()" class="md-button">' +
                            '      Close' +
                            '    </button>' +
                            '  </div>' +
                            '</md-dialog>',
                            locals: {
                                bio: bio
                            },
                            controller: ['$scope', 'bio', DialogController]
                        });

                        function DialogController($scope, bio) {
                            $scope.bio = bio;
                            $scope.closeDialog = function () {
                                $mdDialog.hide();
                            };
                        }
                    };

                    $scope.mli.getDisplayedServices = function () {
                        var mainServices = $scope.mechanic.services.filter(function (s) {
                            return s.main;
                        });

                        return mainServices.length > 0 ? mainServices.slice(0, 3) : $scope.mechanic.services.slice(0, 3);
                    };

                    msi.readOnly = function (selectedMechanic) {
                        if (!AuthService.isLogin()) {
                            return false;
                        }

                        if (selectedMechanic.services.length === 0) {
                            return false;
                        }

                        return AuthService.isMechanic()
                            || AuthService.isAdmin()
                            || AuthService.isPartiallyRegistered();
                    };

                    $scope.mli.showPricing = function (mechanic, location, $event) {
                        $mechanicPublicServicesProvider.showServicesAndPrices($event, mechanic, location, msi.readOnly(mechanic));
                    };
                }]
            };
        }]);