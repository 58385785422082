import angular from "angular";

(function () {

    'use strict';

    function BikeComponentService(Notification, $bikeService, Brand) {

        var self = this;

        this.resource = $bikeService.getResourceClass();

        this.getBikeComponents = function (id, bike) {
            return this.resource.get({id: id}, function (data) {
                self.setAdditionalDesc(data, bike);
            });
        };

        this.getComponents = function (scope, searchText) {
            var searchResult = [];

            if (scope.showBike.currentType && scope.showBike.currentType.components) {
                var components = scope.showBike.currentType.components;

                if (!searchText)
                    return components;

                searchText = searchText.toLowerCase();

                for (var i = 0; components.length > i; i++) {
                    var name = components[i].name.toLowerCase();

                    if (name.indexOf(searchText) >= 0) {
                        searchResult.push(components[i]);
                    }
                }
            }

            return searchResult;
        };

        this.addComponent = function (components) {
            for (var c = 0; c < components.length; c++) {
                var name = components[c].name;
                var brand = components[c].brand;
                var model = components[c].model;

                if (!name && !brand && !model) {
                    return;
                }
            }

            components.push({
                'id': components.length + 1,
                condition: {
                    rate: 1
                }
            });
        };

        this.removeComponent = function (bike, id) {
            for (var c = 0; c < bike.components.length; c++) {
                if(bike.components[c].id === id) {
                    bike.components.splice(c, 1);
                }
            }
        };

        this.queryBrand = function (scope) {
            Brand.autocomplete(function (data) {
                scope.brands = [];
                for (var i = 0; i < data.length; i++) {
                    scope.brands.push(data[i]);
                }
            });
        };

        this.getGroupsets = function(type) {
            var groupsets = [];
            for (var i in type.groupsets) {
                var groupset = {
                    components: [],
                    name: type.groupsets[i].name,
                    brand: type.groupsets[i].brand,
                    model: type.groupsets[i].model
                };
                for (var j in type.groupsets[i].components) {
                    groupset.components.push({
                        name: type.groupsets[i].components[j].name,
                        brand: type.groupsets[i].components[j].brand,
                        model: type.groupsets[i].components[j].model
                    });
                }
                groupsets.push(groupset);
            }

            return groupsets;
        };

        this.changeBrand = function(components, brand) {
            for (var i in components) {
                if (components[i].custom === false) {
                    components[i].brand = brand;
                }
            }
        };

        this.changeModel = function(components, model) {
            for (var i in components) {
                if (components[i].custom === false) {
                    components[i].model = model;
                }
            }
        };

        this.transformComponents = function(data) {
            data.components.unshift({
                name: 'frame',
                brand: data.make || 'NOT SPECIFIED',
                model: data.model || 'NOT SPECIFIED',
                condition: {
                    rate: (data.frameCondition && data.frameCondition.condition ?  data.frameCondition.condition.rate : '1')
                },
                certified: (data.frameCondition && data.frameCondition.certified ?  data.frameCondition.certified : false),
                id: 0
            });
            for (var i = 1; i < data.components.length; i++) {
                data.components[i].condition = {
                    rate: data.components[i].condition ? data.components[i].condition.rate : '1'
                };
                data.components[i].id = i;
            }

            return data.components;
        };

        this.setAdditionalDesc = function(data, bike) {
            if (data.components) {
                bike.components = self.transformComponents(data);
                bike.frameCondition = {
                    description: data.frameCondition ? data.frameCondition.description : ''
                };
                bike.type = data.type;
                bike.id = data.id;
                bike.make = data.make;
                bike.model = data.model;
            }
        };

        this.saveAdditionalDescription = function (data) {
            if (data.form.$valid) {
                var frameCondition = data.bike.components[0].condition.rate;
                var certified = data.bike.components[0].certified !== undefined ? data.bike.components[0].certified : false;
                var components = self.transrofmData(data.bike.components);
                self.update({
                    bike: data.bike,
                    components: components,
                    frameCondition: {
                        condition: {
                            rate: frameCondition
                        },
                        certified: certified,
                        description: data.bike.frameCondition.description || data.bike.components[0].description
                    }
                });
            } else {
                var required = data.form.$error ? data.form.$error.required : false;
                if (required) {
                    Notification.error('Component ' + required[0].$name + ' is required');
                }
            }
        };

        this.transrofmData = function(bikeComponents) {
            var components = [];
            for (var c = 1; c < bikeComponents.length; c++) {
                var name = bikeComponents[c].name;
                var brand = bikeComponents[c].brand;
                var model = bikeComponents[c].model || '';
                var condition = bikeComponents[c].condition ? bikeComponents[c].condition : {rate: 1};
                var certified = bikeComponents[c].certified !== undefined ? bikeComponents[c].certified : false;
                if (name && brand) {
                    components.push({
                        name: name,
                        brand: brand,
                        model: model,
                        condition: condition,
                        certified: certified
                    });
                }
            }

            return components;
        };

        this.update = function(data) {
            this.resource.updateComponents({id: data.bike.id}, {
                components: data.components,
                frameCondition: data.frameCondition
            }, function (response) {
                Notification.success('Bicycles components were successfully saved');
                response.canEdit = data.bike.canEdit;

                self.setAdditionalDesc(response, data.bike);
            });
        };
    }

    var app = angular.module('legacy-app');
    app.service('$bikeComponentService', ['Notification', '$bikeService', 'Brand', BikeComponentService]);
})();