import angular from "angular";
import imageViewer from "../image-viewer";
import imageSliderActions from "./image-slider-actions";
import "./image-slider.scss";

const imageSlider = {
    template: require("./image-slider.html"),
    transclude: {
        actions: '?vuiImageSliderActions'
    },
    bindings: {
        images: '<vuiIsPhotos',
        template: '<vuiIsViewTemplate',
        documents: '<vuiIsDocuments',
        onUpload: '&?vuiIsOnUpload'
    },
    controller: function ($mdDialog) {
        'ngInject';

        this.virtualRepeatCtrl = {};

        this.viewImage = (event, index) => {
            const ctrl = this;

            $mdDialog.show({
                targetEvent: event,
                template: ctrl.template,
                clickOutsideToClose: true,
                escapeToClose: true,
                autoWrap: false,
                controllerAs: '$ctrl',
                bindToController: true,
                locals: {
                    images: ctrl.images,
                    currentImageIdx: index,
                    documents: ctrl.documents
                },
                controller: function () {
                }
            });
        };

        this.applyUploadedImages = (uploadedImages) => {
            this.images = Array.prototype.concat.apply([], [uploadedImages, this.images]);

            if (angular.isFunction(this.onUpload)) {
                this.onUpload({images: this.images});
            }
        };
    }

};

export default angular.module("vui-image-slider.module", [imageViewer.name, imageSliderActions.name])
    .component("vuiImageSlider", imageSlider);