import angular from 'angular';
/*eslint no-useless-escape: "error"*/
(function () {

    'use strict';

    function ngPhone($timeout) {

        function setValue(elm, value, scope, ngModelCtrl) {
            elm.val(value);
            ngModelCtrl.$setViewValue(value);

            $timeout(function() {
                elm.val(value);
                scope.$apply();
            });
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModelCtrl) {

                function formatePhoneNumber(e) {
                    var elm = angular.element(e.currentTarget || e.srcElement);
                    var value = elm.val();
                    if(!value || value === ' ') {
                        setValue(elm, '', scope, ngModelCtrl);
                        return;
                    }

                    value = value.replace(/[^\\+\d\s-()\\.]/g, '');
                    value = value.replace(/\((?=\()/g, ''); /* Delete double parenthes */
                    value = value.replace(/\)(?=\))/g, ''); /* Delete double parenthes */
                    value = value.replace(/\+(?=\+)/g, ''); /* Delete double plus */
                    value = value.replace(/\s(?=\s)/g, ''); /* Delete double spaces */
                    value = value.replace(/^\s+/, ''); /* Delete first spaces */
                    value = value.replace(/^-+/, ''); /* Delete first hyphens */
                    value = value.replace(/-(?=-)/g, ''); /* Delete double hyphens */
                    value = value.replace(/^\.+/, ''); /* Delete first dots */
                    value = value.replace(/\.(?=\.)/g, ''); /* Delete double dots */
                    value = value.substr(0, 22);

                    setValue(elm, value, scope, ngModelCtrl);

                    e.preventDefault();
                }

                function minValidator (value) {
                    if (value && !/\d/g.test(value)) {
                        ngModelCtrl.$setValidity('ngPhone', false);
                        return value;
                    } else {
                        ngModelCtrl.$setValidity('ngPhone', true);
                        return value;
                    }
                }

                ngModelCtrl.$parsers.push(minValidator);
                ngModelCtrl.$formatters.push(minValidator);
                element.on('input', formatePhoneNumber);
                element.on('paste', formatePhoneNumber);
            }

        };
    }

    var app = angular.module('legacy-app');
    app.directive('ngPhone', ['$timeout', ngPhone]);
})();
    

