export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/service-booking', 'service-booking')

        .segment('service-booking', {
            access: {requireLogin: true},
            template: '<mechanic-service-booking></mechanic-service-booking>',
            loadModule: () => {
                return import(/* webpackChunkName: "mechanic-service-booking.module" */ "./service-booking.module");
            }
        });

}
