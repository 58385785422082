import angular from "angular";

(function () {

    'use strict';

    function phoneCountry(PhoneService) {

        function link($scope, $element, $attrs, ngModel) {

            $scope.countries = (function () {
                return PhoneService.getCountries();
            })();

            $scope.$watch('selectedCountry', function (newValue, oldValue, scope) {

                var type,
                    phone,
                    prevCountry,
                    viewPhoneNumber,
                    selectedCountry;

                if (!newValue)
                    return;

                selectedCountry = scope.selectedCountry;

                if (!scope.profile && !scope.billingAddress && !scope.createOrganisation)
                    return;

                if (scope.createOrganisation) {
                    type = 1;
                }
                else if (scope.billingAddress) {
                    type = 2;
                }
                else if (scope.profile) {
                    type = 3;
                }

                switch (type) {
                    case 1:
                        phone = scope.createOrganisation.user.phone;
                        break;
                    case 2:
                        phone = scope.billingAddress.address.phone;
                        break;
                    case 3:
                        phone = scope.profile.user.phone;
                        break;
                }

                if (!phone)
                    return;

                prevCountry = PhoneService.getCountryFromNumber(phone);
                viewPhoneNumber = PhoneService.formNumber(selectedCountry.id, phone, true, prevCountry);

                switch (type) {
                    case 1:
                        scope.createOrganisation.user.phone = viewPhoneNumber;
                        break;
                    case 2:
                        scope.billingAddress.address.phone = viewPhoneNumber;
                        break;
                    case 3:
                        scope.profile.user.phone = viewPhoneNumber;
                        break;
                }
            });
        }

        return {
            restrict: 'A',
            link: link
        };
    }

    var app = angular.module('legacy-app');
    app.directive('phoneCountry', ['PhoneService', phoneCountry]);
}());