export default function ($routeSegmentProvider) {
    "ngInject";

    $routeSegmentProvider
        .when('/mechanic-settings/corporate-program', 'mechanic-corporate-program-settings')
        .when('/mechanic-settings/schedule', 'mechanic-schedule-settings')
        .when('/dashboard/verification', 'mechanic-verification')
        .when('/settings/verification', 'mechanic-verification')
        .when('/verification/pass-quiz', 'pass-quiz')
        .when('/connected-payment-account/stripe/failure', 'stripe-failure')
        .when('/settings/payment/connected-account/stripe', 'stripe-connected-account-view')

        .segment('mechanic-corporate-program-settings', {
            access: {requireLogin: true},
            module: () => {
                return import(/* webpackChunkName: "corporate-program-settings.module" */ "./pages/corporate-program-settings-page");
            }
        })
        .segment('mechanic-schedule-settings', {
            access: {requireLogin: true},
            module: () => {
                return import(/* webpackChunkName: "mechanic-schedule-settings.module" */ "./pages/schedule-settings-page");
            }
        })
        .segment('mechanic-verification', {
            access: {requireLogin: true},
            module: () => {
                return import(/* webpackChunkName: "verification-dashboard.module" */ "./pages/verification-page/dashboard");
            }
        })
        .segment('pass-quiz', {
            access: {requireLogin: true},
            module: () => {
                return import(/* webpackChunkName: "quiz-verification.module" */ "./pages/verification-page/quiz-page");
            }
        })
        .segment('stripe-failure', {
            access: {requireLogin: true},
            module: () => {
                return import(/* webpackChunkName: "stripe-connected-payment-account-failure.module" */ "./pages/connected-account/stripe/failure");
            }
        })
        .segment('stripe-connected-account-view', {
            access: {requireLogin: true},
            module: () => {
                return import(/* webpackChunkName: "stripe-connected-payment-account-view.module" */ "./pages/connected-account/stripe/view");
            }
        })
    ;

}