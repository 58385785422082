import app from "js/legacy-app";
import angular from "angular";

app.directive('imgUpload', function () {
    return {
        replace: true,
        templateUrl: "components/upload-image/img-upload.html",
        scope: {
            imageContainer: "=imgContainer",
            documents: "=",
            choosePhotosText: "@",
            imageSize: "=?imageSize",
            multiple: "=?iuMultiple",
            maxFiles: "@?iuMaxFiles",
            extensions: "@?iuExtensions",
            validationOptions: "=?iuValidationOptions",
            onUpload: "&?iuOnUpload",
            beforeUpload: "&?iuBeforeUpload",
        },
        controller: ['$scope', 'ModalService', 'Upload', 'FileService', 'Notification',
            function ($scope, ModalService, Upload, FileService, Notification) {

                $scope.imageSize = $scope.imageSize || {width: 140, height: 140};
                $scope.imageStyle = "{width: '" + $scope.imageSize.width + "px', height: '" + $scope.imageSize.height + "px'}";
                $scope.choosePhotosText = $scope.choosePhotosText || "Choose photos";

                $scope.imageContainer = {
                    images: [],
                    activeUploadsCount: 0
                };

                $scope.needShowUploadBtn = function () {
                    if (!$scope.multiple) {
                        return $scope.imageContainer.images.length < 1;
                    }

                    return $scope.imageContainer.images.length < $scope.maxFiels;
                };

                $scope.chooseFile = function (files, invalidFiles) {
                    var i;
                    for (i in files) {
                        if (!FileService.isValidFile(files[i])) {
                            Notification.warning("File " + files[i] + "has invalid format");
                        } else {
                            if (angular.isFunction($scope.beforeUpload)) {
                                $scope.beforeUpload({});
                            }

                            $scope.upload(files[i]);
                        }
                    }

                    for (i in invalidFiles) {
                        var invalidFile = invalidFiles[i];
                        var errorMsg = "File " + invalidFile.name + " has invalid " + invalidFile.$error +
                            ".Valid " + invalidFile.$error + " is " +
                            invalidFile.$errorParam + (needPxPostfix(invalidFile.$error) ? 'px' : '');
                        Notification.warning(errorMsg);
                    }

                    function needPxPostfix(error) {
                        return error === 'maxWidth' || error === 'minWidth' ||
                            error === 'maxHeight' || error === 'minHeight';
                    }
                };

                $scope.upload = function (file) {
                    var imageUpload = {
                        id: "",
                        origin: file,
                        thumbnail: "",
                        originUrl: "",
                        thumbnailUrl: "",
                        progress: 0,
                        failed: false,
                        done: false
                    };

                    $scope.imageContainer.images.push(imageUpload);
                    $scope.imageContainer.activeUploadsCount++;

                    $scope.documents.uploadTemp(file, function () {
                    }, function (response) {
                        imageUpload.id = response.imageId;
                        imageUpload.originUrl = response.origin;
                        imageUpload.thumbnailUrl = response.thumbnail;
                        imageUpload.done = true;
                        $scope.imageContainer.activeUploadsCount--;
                        onUploadAll();
                    }, function (error) {
                        imageUpload.failed = true;
                        imageUpload.done = true;
                        $scope.imageContainer.activeUploadsCount--;
                        Notification.error("Failed to upload image");
                        onUploadAll();
                    }, function (progress) {
                        imageUpload.progress = parseInt(100.0 * progress.loaded / progress.total);
                    });
                };

                let onUploadAll = () => {
                    if ($scope.imageContainer.activeUploadsCount === 0) {
                        if (angular.isFunction($scope.onUpload)) {
                            $scope.onUpload({});
                        }
                    }
                };

                $scope.removeImage = function (index) {
                    if (!$scope.imageContainer.images[index].id) {
                        $scope.imageContainer.images.splice(index, 1);
                    } else {
                        $scope.documents.removeTemp($scope.imageContainer.images[index].id);
                        $scope.imageContainer.images.splice(index, 1);
                    }
                };

                $scope.retryUpload = function (index) {
                    var imageUpload = $scope.imageContainer.images[index];
                    imageUpload.failed = false;
                    imageUpload.done = false;
                    $scope.documents.uploadTemp(imageUpload.origin, function () {
                        $scope.imageContainer.activeUploadsCount++;
                    }, function (response) {
                        imageUpload.id = response.imageId;
                        imageUpload.originUrl = response.origin;
                        imageUpload.thumbnailUrl = response.thumbnail;
                        imageUpload.done = true;
                        $scope.imageContainer.activeUploadsCount--;
                    }, function (error) {
                        imageUpload.failed = true;
                        imageUpload.done = true;
                        $scope.imageContainer.activeUploadsCount--;
                        Notification.error("Failed to upload image");
                    }, function (progress) {
                        imageUpload.progress = parseInt(100.0 * progress.loaded / progress.total);
                    });
                };

                (function init() {
                    if (!$scope.extensions) {
                        $scope.extensions = '.jpg,.jpeg,.png,.bmp,.gif';
                    }
                    if (angular.isUndefined($scope.multiple)) {
                        $scope.multiple = true;
                    }
                    if (angular.isUndefined($scope.maxFiels)) {
                        $scope.maxFiels = 10;
                    }

                    if (angular.isUndefined($scope.validationOptions)) {
                        $scope.validationOptions = {};
                    }
                })();
            }]
    };
});