import app from "js/legacy-app";

app.directive('initialLoadBanner', ['$rootScope', '$timeout', function ($rootScope, $timeout) {
    return {
        restrict: 'A',
        link: function ($scope, $element, $attrs, ngModel) {
            $timeout(function () {
                $element.css("display", "none");
            });
        }
    };
}]);
