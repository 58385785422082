import app from "js/legacy-app";
import intlTelInputComponent from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import "intl-tel-input/build/js/utils";
import './phone-number.scss';

const component = {
    templateUrl: "components/phone/phone-number.html",
    transclude: {
        actions: '?vuiPnActions'
    },
    require: {
        form: '^form'
    },
    bindings: {
        number: '<vuiPnNumber',
        onChange: '&vuiPnOnChange',
        isRequired: '@vuiPnIsRequired'
    },
    controller: function ($http, $timeout) {
        'ngInject';

        const self = this;
        let iti;

        this.$onInit = () => {
            iti = initializeComponent();

            if (self.number) {
                iti.setNumber(self.number);
            }
        };

        let onSubmit = () => {
            self.onChange({phone: self.number, isValid: iti.isValidNumber()});
        };

        let initializeComponent = () => {
            let input = document.querySelector("#vui-phone-number-id");
            let ipInfoToken = process.env.IP_INFO_TOKEN;

            let iti = intlTelInputComponent(input, {
                autoHideDialCode: true,
                nationalMode: false,
                autoPlaceholder: "aggressive",
                initialCountry: "auto",
                formatOnDisplay: true,
                customContainer: 'vui-phone-number',
                geoIpLookup: function (callback) {
                    let countries = this.onlyCountries;
                    $http.get("https://ipinfo.io?token=" + ipInfoToken, function () {
                    }, "jsonp").then(function (resp) {
                        let countryCode = (resp && resp.data && resp.data.country) ? resp.data.country : "us";
                        if (countries.indexOf(countryCode) >= 0) {
                            callback(countryCode);
                        } else {
                            callback('us');
                        }
                    }, function () {
                        callback('us');
                    });
                },
                hiddenInput: "fullNumber",
                onlyCountries: [
                    'at', 'au', 'be', 'br', 'ca',
                    'ch', 'de', 'dk', 'ee', 'es',
                    'fi', 'fr', 'gb', 'gr', 'ie',
                    'it', 'lt', 'lu', 'lv', 'my',
                    'mx', 'nl', 'no', 'nz', 'pl',
                    'pt', 'se', 'si', 'sk', 'us'
                ],
                placeholderNumberType: "MOBILE",
                preferredCountries: ['us', 'ca'],
                separateDialCode: true
            });

            let errorMsg = document.querySelector("#vui-number-error-msg");
            let phoneLabel = document.querySelector("#vui-phone-number-label");

            let errorMap = ["Invalid number", "Invalid country code", "Number is too short", "Number is too long", "Invalid number"];

            let reset = function () {
                input.classList.remove("vui-phone-number__error");
                phoneLabel.classList.remove("vui-phone-number__error");
                errorMsg.innerHTML = "";
                self.number = iti.getNumber();
                onSubmit();
            };

            let onFocus = () => {
                phoneLabel.classList.remove("tw-text-primary-dark-gray");
                phoneLabel.classList.add("tw-text-yellow");
            };

            input.addEventListener('blur', function () {
                phoneLabel.classList.add("tw-text-primary-dark-gray");
                phoneLabel.classList.remove("tw-text-yellow");
                reset();

                if (input.value.trim() && !iti.isValidNumber()) {
                    input.classList.add("vui-phone-number__error");
                    phoneLabel.classList.add("vui-phone-number__error");
                    let errorCode = iti.getValidationError();
                    errorMsg.innerHTML = errorMap[errorCode];
                    errorMsg.classList.remove("hide");
                } else if (self.isRequired === 'true' && !input.value.trim()) {
                    input.classList.add("vui-phone-number__error");
                    phoneLabel.classList.add("vui-phone-number__error");
                    errorMsg.innerHTML = 'Phone number is required';
                    errorMsg.classList.remove("hide");
                }
            });

            input.addEventListener('change', reset);
            input.addEventListener('keyup', reset);
            input.addEventListener('focus', onFocus);

            return iti;
        }
    }
};

app.component("vtPhoneNumber", component);
