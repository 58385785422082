import app from "js/legacy-app";

app.directive('addComponents', ['$bikeService', '$bikeComponentService', 'Notification', 'Brand',
    function ($bikeService, $bikeComponentService, Notification, Brand) {
        return {
            templateUrl: "template/dashboard/bikes/add-bike/add-components.html",
            scope: {
                bike: '=addComponents',
                next: '&'
            },
            controller: ['$scope', function ($scope) {

                $scope.isCanEditComponent = function () {
                    return true;
                };

                $scope.changeGroupset = function () {
                    var groupsetComponents = setCustomComponents($scope.addComponents.currentGroupset.components);
                    var currentComponents = $scope.bike.components;

                    if (isContainsCustom(currentComponents)) {
                        groupsetList:for (var g = 0; g < groupsetComponents.length; g++) {
                            for (var c = 1; c < currentComponents.length; c++) {
                                var grName = groupsetComponents[g].name;
                                var cName = currentComponents[c].name;
                                var custom = (currentComponents[c].custom === false);
                                if (grName === cName && custom) {
                                    $scope.bike.components[c] = groupsetComponents[g];
                                    continue groupsetList;
                                }
                            }
                        }
                    } else {
                        if (groupsetComponents) {
                            $scope.bike.components = currentComponents.concat(groupsetComponents);
                        }
                    }

                    $scope.addComponents.brand = $scope.addComponents.currentGroupset.brand;
                    $scope.addComponents.model = $scope.addComponents.currentGroupset.model;

                    $scope.changeBrand();
                    $scope.changeModel();

                    $bikeService.setGroupset($scope.addComponents.currentGroupset.name);
                };

                $scope.changeBrand = function () {
                    $bikeComponentService.changeBrand($scope.bike.components, $scope.addComponents.brand);
                    $bikeService.setBrand($scope.addComponents.brand);
                };

                $scope.changeModel = function () {
                    $bikeComponentService.changeModel($scope.bike.components, $scope.addComponents.model);
                    $bikeService.setModel($scope.addComponents.model);
                };

                $scope.getComponents = function (component) {
                    var components = [];
                    if ($scope.addComponents.currentType()) {
                        var type = $scope.addComponents.currentType();
                        for (var i in type.components) {
                            if (type.components[i].name.toLowerCase().indexOf(component.toLowerCase()) > -1) {
                                components.push(type.components[i]);
                            }
                        }
                    }

                    return components;
                };

                $scope.getBrands = function (brand) {
                    return Brand.autocomplete({name: brand, page: 0, size: 10}).$promise;
                };

                $scope.close = $bikeService.cancelCreateBike;

                $scope.addComponents = {
                    showComponents: true,
                    customGroupset: {
                        name: 'Custom groupset',
                        custom: true,
                        components: []
                    },
                    currentType: $bikeService.getBikeType
                };

                $scope.addComponent = function () {
                    $bikeComponentService.addComponent($scope.bike.components);
                };

                if ($bikeService.getGroupset()) {
                    $scope.addComponents.currentGroupset = $bikeService.getGroupsetByName($bikeService.getGroupset());
                }
                if ($bikeService.getBrand()) {
                    $scope.addComponents.brand = $bikeService.getBrand();
                }
                if ($bikeService.getModel()) {
                    $scope.addComponents.model = $bikeService.getModel();
                }

                $scope.submit = function (form) {
                    if (form.$valid) {
                        $scope.next()(3);
                    } else {
                        if (form.name.$invalid) {
                            Notification.error('Component name is required');
                            return;
                        }

                        if (!form.brand || form.brand.$invalid) {
                            Notification.error('Component brand is required');
                            return;
                        }

                        if (form.model.$invalid) {
                            Notification.error('Component model is required');
                        }
                    }
                };

                function isContainsCustom(components) {
                    for (var c = 0; c < components.length; c++) {
                        if (components[c].custom === false)
                            return true;
                    }

                    return false;
                }

                function setCustomComponents(components) {
                    for (var c = 0; c < components.length; c++) {
                        components[c].custom = false;
                        components[c].id = c;
                        components[c].condition = {
                            rate: 1
                        };
                    }

                    return components;
                }
            }]
        };
    }]);