import app from "js/legacy-app";

app.service('Images', ['$http', 'Notification', function ($http, Notification) {
    return {
        remove: function (images, index, folder, successCallback) {
            $http.delete("/api/images/" + folder + "/" + images[index].id)
                .then(
                    function () {
                        images.splice(index, 1);
                        if (successCallback) {
                            successCallback();
                        }
                        Notification.success("Image has been successfully removed");
                    }, function (error) {
                        Notification.error("Failed to remove the image");
                    });
        }
    };
}]);
