import app from "js/legacy-app";
import angular from "angular";

app.directive('ngDataTable', [function () {
    return {
        templateUrl: "template/component/data-table.tpl.html",
        scope: {
            ngDataTable: '='
        },
        link: function ($scope, $element, $attrs, ngModel) {
            $scope.path = $scope.ngDataTable.path;
            $scope.service = $scope.ngDataTable.service;
            /*$scope.searchColumnName = 'id';
             $scope.searchType = 'ASC';*/
            if ($scope.ngDataTable.showFilters === false) {
                $scope.showFilters = $scope.ngDataTable.showFilters;
            } else {
                $scope.showFilters = true;
            }

            if ($scope.ngDataTable.select) {
                $scope.showSelect = true;
                $scope.selectValues = $scope.ngDataTable.select.values;
                $scope.selectValue = $scope.ngDataTable.select.defaultValues;
            } else {
                $scope.showSelect = false;
            }

            $scope.search();
        },
        controller: ['$scope', '$sce', function ($scope, $sce) {
            $scope.selected = {};
            $scope.sizeList = [5, 10, 25, 50, 100];
            $scope.ngDataTable.currentSize = $scope.sizeList[1];
            $scope.ngDataTable.currentAdditionalFilter = null;
            if ($scope.ngDataTable.customFilters) {
                $scope.ngDataTable.currentAdditionalFilter = $scope.ngDataTable.customFilters[0];
            }

            $scope.ngDataTable.getData = function () {
                return $scope.data;
            };
            $scope.ngDataTable.refresh = function () {
                $scope.search();
            };

            $scope.setData = function (data) {
                $scope.size = data.size;
                $scope.data = data.data;
                $scope.next = data.next;
                $scope.current = data.current;
                $scope.count = data.total;
                $scope.prev = data.prev;
                $scope.pages = data.pages;
            };
            $scope.setData({
                size: $scope.ngDataTable.currentSize,
                current: 0
            });

            $scope.changePageSize = function () {
                $scope.current = 0;
                $scope.search();
            };

            // get data for current page
            $scope.getPage = function (page) {
                if ($scope.current !== page) {
                    $scope.current = page;
                    $scope.search();
                }
            };

            $scope.getPageInfo = function () {
                var from, to;
                if ($scope.count) {
                    from = ($scope.count !== 0 ? ($scope.current * $scope.size + 1) : 0);
                    to = ($scope.count < ($scope.current * $scope.size + $scope.size) ? $scope.count : ($scope.current * $scope.size + $scope.size));
                    return 'From ' + from + ' to ' + to + ' of ' + $scope.count;
                }

                return 'No records';
            };

            // get data for next page
            $scope.getNextPage = function () {
                if ($scope.current !== $scope.pages[$scope.pages.length - 1]) {
                    $scope.current++;
                    $scope.search();
                }
            };

            // get data for previous page
            $scope.getPrevPage = function () {
                if ($scope.current !== 0) {
                    $scope.current--;
                    $scope.search();
                }
            };

            $scope.getValue = function (model, field) {
                var fieldName = field.name,
                    fields = fieldName.split('.'),
                    value = model;

                angular.forEach(fields, function (index) {
                    value = value[index];
                    if (value === null || value === undefined) {
                        value = "";
                    }
                });

                if (field.getValue) {
                    value = field.getValue(value);
                }

                return value;
            };

            $scope.selectedAll = function () {
                var selectedCount = 0, i;
                for (i in $scope.data) {
                    if ($scope.data[i].selected) {
                        selectedCount++;
                    }
                }

                var selected = true;
                if (selectedCount === $scope.data.length) {
                    selected = false;
                }

                for (i in $scope.data) {
                    $scope.data[i].selected = selected;
                }
            };

            $scope.search = function () {
                $scope.buildFilters();
                if (!$scope.path) {
                    $scope.path = 'search';
                }
                var search = {
                    page: $scope.current,
                    size: $scope.ngDataTable.currentSize
                };

                if ($scope.ngDataTable.filtersAsSeparateFields) {
                    angular.extend(search, $scope.filters);
                } else {
                    search.query = JSON.stringify($scope.filters);
                }

                if ($scope.searchColumnName && $scope.searchType) {
                    search.sort = {
                        field: $scope.searchColumnName,
                        direction: $scope.searchType
                    };
                }

                if ($scope.service.search) {
                    $scope.service.search({id: $scope.path}, search, function (result) {
                        $scope.setData(result);
                    }, function () {
                        //$scope.showLoadingIndicator = false;
                    });
                } else {
                    $scope.service.get(search, function (result) {
                        $scope.setData(result);
                    }, function () {
                        //$scope.showLoadingIndicator = false;
                    });
                }

            };

            $scope.sort = function (column) {
                if (column.sort === true) {
                    if ($scope.searchColumnName === column.filterColumnName) {
                        revertSortType();
                    } else {
                        $scope.searchColumnName = column.filterColumnName;
                        $scope.searchType = 'ASC';
                    }

                    $scope.search();
                }
            };

            $scope.getSearchType = function (column) {
                if ($scope.searchColumnName === column.filterColumnName) {
                    return $scope.searchType + ' search';
                } else if (column.sort === true) {
                    return 'search';
                }

                return '';
            };

            $scope.countLoad = 0;
            $scope.changeFilter = function () {
                $scope.countLoad++;
                setTimeout(function () {
                    $scope.countLoad--;
                    if ($scope.countLoad === 0) {
                        $scope.current = 0;
                        $scope.search();
                    }
                }, 500);
            };

            $scope.addFilter = function () {
                $scope.getPage();
            };

            $scope.disableAction = function (data, action) {
                if (action.disable) {
                    return action.disable(data);
                }

                var selectedCount = 0, i;
                for (i in $scope.data) {
                    if ($scope.data[i].selected) {
                        selectedCount++;
                    }
                }

                return selectedCount !== 1;
            };

            function revertSortType() {
                if ($scope.searchType === 'ASC') {
                    $scope.searchType = 'DESC';
                } else {
                    $scope.searchType = 'ASC';
                }
            }

            function pushFilter(filters, columnName, value) {
                filters[columnName] = value;
            }

            function addFilterToFilters(list) {
                for (var i in list) {
                    $scope.filters[i] = list[i];
                }
            }

            $scope.buildFilters = function () {
                $scope.filters = {};

                if ($scope.ngDataTable.defaultFilters) {
                    addFilterToFilters($scope.ngDataTable.defaultFilters());
                }

                if ($scope.ngDataTable.currentAdditionalFilter && $scope.ngDataTable.currentAdditionalFilter.filter) {
                    addFilterToFilters($scope.ngDataTable.currentAdditionalFilter.filter);
                }

                var i;
                if ($scope.ngDataTable.radioButtonFilters) {
                    for (i = 0; i < $scope.ngDataTable.radioButtonFilters.length; i++) {
                        if ($scope.ngDataTable.radioButtonFilters[i].active && $scope.ngDataTable.radioButtonFilters[i].filter) {
                            clearPage();
                            addFilterToFilters($scope.ngDataTable.radioButtonFilters[i].filter);
                        }
                    }
                }

                for (i in $scope.ngDataTable.columns) {
                    if ($scope.ngDataTable.columns[i].filterValue) {
                        var columnName = $scope.ngDataTable.columns[i].filterColumnName,
                            value = {$regex: $scope.ngDataTable.columns[i].filterValue, $options: 'i'};
                        if ($scope.ngDataTable.columns[i].filter === 'date') {
                            value = $scope.ngDataTable.columns[i].filterValue.format('yyyy-mm-dd');
                        }
                        pushFilter($scope.filters, columnName, value);
                    }
                }

                return $scope.filters;
            };

            $scope.selectRow = function (row) {
                if ($scope.ngDataTable.selectRow) {
                    $scope.ngDataTable.selectRow(row);
                }
            };

            var clearPage = function () {
                $scope.current = 0;
            };

            $scope.changeAdditionalFilter = function () {
                $scope.current = 0;
                $scope.search();
            };

            $scope.selectFilter = function (index, button) {
                button.active = !button.active;

                if (!button.type) {
                    for (var i = 0; i < $scope.ngDataTable.radioButtonFilters.length; i++) {
                        if (i !== index && $scope.ngDataTable.radioButtonFilters[i].type === button.type && $scope.ngDataTable.radioButtonFilters[i].active) {
                            $scope.ngDataTable.radioButtonFilters[i].active = false;
                        }
                    }
                }

                $scope.search();
            };

            $scope.trustAsHtml = function (html) {
                return $sce.trustAsHtml(html + '');
            };

            $scope.ngDataTable.buildFilters = $scope.buildFilters;
            $scope.ngDataTable.getFilter = function () {
                return angular.merge($scope.ngDataTable.persistentFilters || {}, {
                    page: $scope.current,
                    size: $scope.ngDataTable.currentSize,
                    sort: {
                        field: $scope.searchColumnName,
                        direction: $scope.searchType
                    },
                    query: JSON.stringify($scope.buildFilters())
                });
            };
        }]
    };
}]);
