import angular from "angular";

const changeAppointmentDateAction = {
    template: require("./booking-appointmnt-date-change-action.html"),
    require: {
        parent: '^vuiBookingAppointmentDate'
    },
    bindings: {
        title: '@vuiCaTitle'
    },

    controller: function ($mdDialog, $q, ErrorHandler) {
        'ngInject';

        this.change = (event) => {

            $q.when(this.parent.loadAvailabilityDates()).then((dateContainer) => {
                let availabilities = {
                    dates: dateContainer.dates
                };

                $mdDialog.show({
                    targetEvent: event,
                    template: require('./booking-appointment-date-change-dialog.html'),
                    locals: {
                        availabilityDates: availabilities,
                        weeklyAvailability: dateContainer.weeklyAvailability,
                        vacationPeriod: dateContainer.vacationPeriod
                    },
                    autoWrap: false,
                    bindToController: true,
                    controllerAs: '$ctrl',
                    controller: function ($mdDialog, Notification, $mdMedia, $availabilityDates) {
                        'ngInject';

                        this.$onInit = () => {
                            this.dates = $availabilityDates.generate(this.availabilityDates, this.weeklyAvailability, this.vacationPeriod);
                        };

                        this.apply = (event) => {
                            if (!this.selectedTime || !this.selectedTime.time) {
                                Notification.warning("Please select time");
                                return;
                            }

                            $mdDialog.hide(this.selectedTime);
                        };

                        this.cancel = () => {
                            $mdDialog.hide();
                        };
                    }
                }).then((date) => {
                    if (!date) return;

                    this.parent.submit(date);
                });
            }).catch((err) => {
                return ErrorHandler.onError(err);
            });
        };
    }
};

export default angular.module("vui-booking-appointment-date-actions.module", [])
    .component("vuiBadChangeAction", changeAppointmentDateAction);