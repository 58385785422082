import app from "js/legacy-app";

app.service('ShowMenuService', ['$rootScope', '$location', 'AuthService',
    function ($rootScope, $location, AuthService) {

        $rootScope.showMainMenuAndFooter = function () {
            return $location.path() !== '/' && $location.path() !== '/home' && !AuthService.isLogin();
        };

        $rootScope.currentYear = new Date().getFullYear();
    }
]);
