import "./landing-page-accordion-item.component.scss";

export default {
    template: require('./landing-page-accordion-item.component.html'),
    transclude: {
        title: "landingPageAccordionItemTitle",
        content: "landingPageAccordionItemContent"
    },
    controller: function () {
        'ngInject';

        const self = this;
        self.active = false;

        self.toggle = () => {
            self.active = !self.active;
        };
    }
};
