import angular from "angular";
import bikeAutocomplete from "../bike-autocomplete";
import bikeForm from "../simple-bike-form";
import defaultBikeImage from "img/no-bike.png";

const component = {
    template: require('./select-bikes.html'),
    bindings: {
        filter: '<?vuiSbFilter',
        onSelect: '&vuiSbOnSelect',
        excludeBikes: '<?vuiSbExcludeBikes',
        options: '<?vuiSbOptions'
    },
    controller: function ($timeout) {
        'ngInject';

        this.$onInit = () => {
            this.defaultBikeImage = defaultBikeImage;
            this.selectedBikes = this.selectedBikes || [];

            this.tabs = {
                'select-bicycles': {name: 'Select bicycles', selected: true},
                'add-bicycle': {name: 'Add new bicycle', selected: false}
            };
            this.bikes = [];
            this.newBikes = [];

            this.filter = this.filter || {};
            this.bikeAutocompleteFilter = {
                organisation: this.filter.organisation,
                station: this.filter.station,
                user: this.filter.user
            };
        };

        this.isSelectedTab = (tab) => {
            return this.tabs[tab].selected;
        };

        this.selectOption = (option) => {
            const ctrl = this;

            $timeout(function () {
                for (let tab in ctrl.tabs) {
                    ctrl.tabs[tab].selected = false;
                }

                ctrl.tabs[option].selected = true;
            }, 100);
        };

        this.submit = () => {
            if (angular.isFunction(this.onSelect)) {
                this.onSelect({bikes: this.bikes});
            }
        };

        this.addNew = (bike) => {
            bike.new = true;
            bike.selected = true;
            bike.displayName = bike.make + " " + bike.model;
            this.bikes.push(bike);
            this.newBikes.push(bike);
            this.selectedBikes.push(bike);
            this.selectOption('select-bicycles');
            this.submit();
        };

        this.removeAutocompleteBike = (bike) => {
            let index = this.bikes.indexOfObject("id", bike.id);
            if (index !== -1) {
                this.bikes.splice(index, 1);
            }

            index = this.selectedBikes.indexOfObject("id", bike.id);
            if (index !== -1) {
                this.selectedBikes.splice(index, 1);
            }

            this.submit();
        };

        this.addAutocompleteBike = (bike) => {
            let autocompleteBike = buildAutocompleteBike(bike);
            this.bikes.push(autocompleteBike);
            this.selectedBikes.push(autocompleteBike);
            this.submit();
        };

        let buildAutocompleteBike = (bike) => {
            return {
                id: bike.id,
                displayName: bike.make + " " + bike.model,
                old: true,
                avatar: {
                    thumbnail: bike.image && bike.image.thumbnail ? bike.image.thumbnail : null
                }
            };
        };
    }
};

export default angular.module("vui-select-bikes.module", [bikeAutocomplete.name, bikeForm.name])
    .component('vuiSelectBikes', component);
