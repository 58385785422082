import app from "js/legacy-app";

app.controller('BikeInfoCtrl', ['$scope', 'AuthService', '$routeParams', '$location', 'CustomerBikeResource',
    function ($scope, AuthService, $routeParams, $location, CustomerBikeResource) {

        (function init() {
            if (!AuthService.isFullyLoggedIn()) {
                $location.path("/login").search({redirect: '/bike-info/' + $routeParams.bikeId});
            } else {
                CustomerBikeResource.getPublic({id: $routeParams.bikeId}, function (data) {
                    $location.path("/bike/" + data.id).search({});
                }, function () {
                    Notification.error("Bicycle hasn't been found");
                    $location.path("/").search({});
                });
            }
        })();
    }
]);