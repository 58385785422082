import app from "js/legacy-app";

app.factory('$bodyHandler', ['$document', function ($document) {
    var bodyHandler = {};

    bodyHandler.elements = [];
    bodyHandler.$body = $document;

    bodyHandler.add = function (elem, fun) {
        if (bodyHandler.elements.length === 0) {
            var callFun = function (e) {
                bodyHandler.elements.forEach(function (item) {
                    item.fun.call(item, e);
                });
            };

            bodyHandler.$body.on('click', callFun);
            bodyHandler.$body.on('mousemove', callFun);
        }
        bodyHandler.elements.push({
            'elem': elem,
            'fun': fun
        });
    };

    bodyHandler.remove = function (elem) {
        for (var i = bodyHandler.elements.length - 1; i >= 0; i--) {
            if (bodyHandler.elements[i].elem === elem) {
                bodyHandler.elements.splice(i, 1);
            }
        }
        if (bodyHandler.elements.length === 0) {
            bodyHandler.$body.off('click');
            bodyHandler.$body.off('mousemove');
        }
    };

    return {
        add: bodyHandler.add,
        remove: bodyHandler.remove
    };
}]);