import angular from "angular";
import "./page.scss";

const pageLayout = {
    template: require('./page.html'),
    bindings: {
      style: "@?vuiStyle"
    },
    transclude: {
        title: "vuiPageTitle",
        content: "vuiPageContent",
        actions: "?vuiPageActions",
        footer: "?vuiPageFooter",
        stickyTitle: "?vuiStickyTitle"
    },
    controller: function ($transclude) {
        'ngInject';

        this.showStickyHeader = $transclude.isSlotFilled('actions');
    }
};

export default angular.module("page-layout", [])
    .component('vuiPage', pageLayout);
