// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../../../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("/img/user-no-avatar.png");
// Module
var ___HTML_LOADER_REPLACEMENT_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var code = "<md-autocomplete md-clear-button=\"true\"\n                 md-delay=\"500\"\n                 md-floating-label=\"Start typing user name...\"\n                 md-input-maxlength=\"50\"\n                 md-input-name=\"name\"\n                 md-item-text=\"$ctrl.displayItem(user)\"\n                 md-items=\"user in $ctrl.search($ctrl.filter.name)\"\n                 md-min-length=\"1\"\n                 md-no-cache=\"true\"\n                 md-search-text=\"$ctrl.filter.name\"\n                 md-selected-item=\"$ctrl.selectedUser\"\n                 md-selected-item-change=\"$ctrl.select($ctrl.selectedUser)\">\n    <md-item-template class=\"autocomplete-custom-template\">\n        <img data-ng-if=\"user.image\" src=\"{{ user.image }}\">\n        <img data-ng-if=\"!user.image\" src=\"" + ___HTML_LOADER_REPLACEMENT_0___ + "\"/>\n        <span class=\"autocomplete__item-text\" md-highlight-flags=\"^i\"\n              md-highlight-text=\"$ctrl.searchQuery\">{{user.name}} {{user.surname}}</span>\n    </md-item-template>\n</md-autocomplete>\n";
// Exports
var _module_exports = code;;
var path = 'components/autocomplete/user/user-autocomplete.component.html';
window.angular.module('ng').run(['$templateCache', function(c) { c.put(path, _module_exports) }]);
module.exports = path;