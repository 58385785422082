import app from "js/legacy-app";

app.controller('UploadInsuranceVerificationCtrl', ['$scope', '$location', function ($scope, $location) {

    $scope.windowTitle = 'Insurance verification';

    $scope.onComplete = () => {
        $location.path('/dashboard/verification').search({});
    }

}]);