import angular from "angular";
import './label.component.scss';

export default () => {
    return {
        restrict: 'EA',
        replace: true,
        transclude: true,
        template: (element, attr) => {
            if (angular.isDefined(attr.for)) {
                return '<label class="vui-label" ng-transclude></label>';
            } else {
                return '<div class="vui-label" ng-transclude></div>';
            }
        },
        link: (scope, element, attr) => {
        }
    };
}
