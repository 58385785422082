import app from "js/legacy-app";

app.directive('profileSchedule', [function () {
    return {
        templateUrl: "template/profile/widget/profile-schedule.html",
        replace: true,
        scope: {
            model: '<psModel'
        },
        controller: ['$scope', '$dateTimeService', function ($scope, $dateTimeService) {

            $scope.toHours = $dateTimeService.toHours;
        }]
    };
}]);