import app from "js/legacy-app";
import angular from 'angular';
import mechanicDefaultImage from "img/mechanic-no-avatar.png";
import customerDefaultImage from "img/user-no-avatar.png";

app.directive('vtSidebar', ['$window', '$document', '$timeout', function ($window, $document, $timeout) {
    return {
        templateUrl: "template/component/vt-sidebar.tpl.html",
        link: function ($scope, $element) {
            var sidebar = $element[0].firstChild;
            new window.ui.sidebar(sidebar);
            window.ui.eventService.scrollMenu();

            $scope.isMobile = window.ui.resolutionService.isMobile();

            window.addEventListener('resize', function () {
                $scope.$apply(function () {
                    $scope.isMobile = window.ui.resolutionService.isMobile();
                });
            });

            function animateSidebar() {
                var $e = angular.element(sidebar);
                var last_position = 0;
                var onScroll = function (ev) {
                    if (!$scope.isMobile) {
                        $e.removeClass('vt-sidebar_opened');
                        $e.removeClass('vt-sidebar_hidden');
                        return;
                    }

                    var pos = $window.scrollY;

                    if (pos === 0) {
                        return;
                    }

                    if (pos === 1) {
                        $e.addClass('vt-sidebar_hidden');
                        $e.removeClass('vt-sidebar_opened');
                        $e.removeClass('vt-sidebar_hidden');
                    }

                    if (pos > last_position + 1) {
                        $e.addClass('vt-sidebar_hidden');
                        $e.removeClass('vt-sidebar_opened');
                    }

                    if (pos + 10 < last_position) {
                        $e.addClass('vt-sidebar_opened');
                        $e.removeClass('vt-sidebar_hidden');
                    }

                    last_position = pos;
                };
                $document.on('scroll', onScroll);
                $document.on('$destroy', function () {
                    $document.off('scroll', onScroll);
                });
            }

            (function init() {
                $timeout(function () {
                    animateSidebar();
                }, 200);
            })();
        },
        controller: ['$scope', '$location', '$route', 'AuthService', 'Organisation', '$routeParams', '$rootScope',
            'NotificationWSService', '$pageScroll',
            function ($scope, $location, $route, AuthService, Organisation, $routeParams,
                      $rootScope, NotificationWSService, $pageScroll) {

                $scope.auth = AuthService;

                $scope.getCountNotifications = function () {
                    return $rootScope.notificationTotalCount;
                };

                $scope.isOpenOrganisation = function (nextUrl) {
                    return nextUrl &&
                        nextUrl.match(/\/organisation.*/) &&
                        nextUrl.match(/\/organisation.*/).length > 0;
                };

                $scope.isOpenOrganisationByRouteParams = function () {
                    return $route.current.segment &&
                        $route.current.segment.match(/^organisation.*/) &&
                        $route.current.segment.match(/^organisation.*/).length > 0;
                };

                $rootScope.$on('$locationChangeStart', function (event, next, current) {
                    $pageScroll.show();
                    if ($scope.isOpenOrganisation(next)) {
                        loadOrganisation();
                    } else {
                        $rootScope.currentOrganisation = null;
                    }
                });

                $scope.getUserAvatar = function () {
                    if (AuthService.user().avatar) {
                        return AuthService.user().avatar.thumbnail;
                    }

                    if (AuthService.isMechanic()) {
                        return mechanicDefaultImage;
                    }

                    return customerDefaultImage;
                };

                function loadOrganisation() {
                    var organisationId = $routeParams.organisationId;
                    if (organisationId) {
                        if (!$rootScope.currentOrganisation || $rootScope.currentOrganisation.id !== organisationId) {
                            Organisation.get({param1: organisationId}, function (response) {
                                $rootScope.currentOrganisation = response;
                            });
                        }
                    }
                }

                if ($scope.isOpenOrganisationByRouteParams()) {
                    loadOrganisation();
                }

                $scope.openNotificationList = function () {
                    if ($scope.isMobile) {
                        $pageScroll.hide();
                    }
                    viewAllNotifications();
                };

                var viewAllNotifications = function () {
                    AuthService.currentUser(function (user) {
                        if (user) {
                            NotificationWSService.send({
                                operation: 3,
                                token: AuthService.getToken().access_token
                            });
                        }
                    });
                };
            }
        ]
    };
}]);
