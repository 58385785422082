import angular from 'angular';

(function () {

    'use strict';

    function rmSpecial($timeout) {

        function setValue(elm, value, scope, ngModelCtrl) {
            elm.val(value);
            ngModelCtrl.$setViewValue(value);

            $timeout(function () {
                elm.val(value);
                scope.$apply();
            });
        }

        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attr, ngModelCtrl) {

                function removeSpecial(e) {
                    var elm = angular.element(e.currentTarget || e.srcElement);
                    var value = elm.val();

                    if (!value || value === ' ') {
                        setValue(elm, '', scope, ngModelCtrl);
                        return;
                    }

                    value = value.replace(/[!@#$%^&*(){}[\]~<>\\/:"?№;]/g, "");
                    /* Delete special */

                    setValue(elm, value, scope, ngModelCtrl);

                    e.preventDefault();
                }

                element.on('input', removeSpecial);
                element.on('paste', removeSpecial);
            }
        };
    }

    var app = angular.module('legacy-app');
    app.directive('rmSpecial', ['$timeout', rmSpecial]);
})();