import app from "js/legacy-app";

app.controller('RateUserDialogController', ['$scope', '$mdDialog', '$pageFooter',
    function ($scope, $mdDialog, $pageFooter) {

        $scope.rateUserDialog = {
            rating: {
                overallExperience: 5,
                helpfulness: 5,
                punctuality: 5,
                communication: 5,
                comment: ''
            }
        };

        $scope.rateUserDialog.submit = function (form) {
            if (form.$valid) {
                $mdDialog.hide($scope.rateUserDialog.rating);
            }
        };

        $scope.rateUserDialog.close = function () {
            $mdDialog.hide();
        };

        (function init() {
            $pageFooter.hide();
        })();
    }]);
