import app from "js/legacy-app";

app.controller('NotifyUserDialogController', [
    '$scope',
    '$mdDialog',
    'ToggleLD',
    'UncoatedArea',
    'ErrorHandler',
    'location',
    'trackingId',
    'LocationService',
    function ($scope, $mdDialog, ToggleLD, UncoatedArea, ErrorHandler, location, trackingId, LocationService) {

        $scope.user = {};

        $scope.nudc = {
            cancel: function () {
                $mdDialog.hide();
            },

            notify: function (form) {
                if (!form.$valid) {
                    return;
                }

                ToggleLD.show();
                if (trackingId) {
                    UncoatedArea.update(
                        {
                            id: trackingId
                        },
                        {
                            email: $scope.user.email
                        }, function () {
                            ToggleLD.hide();
                            $mdDialog.hide($scope.user.email);
                        }, function (error) {
                            ToggleLD.hide();
                            ErrorHandler.onError(error);
                        });
                } else {
                    UncoatedArea.save({
                        location: LocationService.mapAddress(location),
                        email: $scope.user.email
                    }, function () {
                        ToggleLD.hide();
                        $mdDialog.hide($scope.user.email);
                    }, function (error) {
                        ToggleLD.hide();
                        ErrorHandler.onError(error);
                    });
                }
            }
        };
    }
]);