import app from "js/legacy-app";

app.directive('blBasicFilters', ['Brand', function (Brand) {
    return {
        templateUrl: "components/bike/list/base/bl-basic-filters.html",
        replace: true,
        transclude: true,
        scope: {
            options: "=?blfOptions"
        },
        link: function ($scope, element, $attrs) {

            $scope.blbf = {};

            $scope.blbf.filter = {
                status: {
                    options: [
                        {val: null, display: 'All'},
                        {val: 'USED', display: 'In Use'},
                        {val: 'BROKEN', display: 'Repairing'},
                        {val: 'IN_TRANSFER', display: 'In Transfer'}
                    ]
                },
                brand: {
                    search: function (query) {
                        return Brand.autocomplete({name: query, size: 10}, {}).$promise;
                    }
                }
            };
        }
    };
}]);