import app from "js/legacy-app";
import defaultBikeImg from 'img/no-bike.png';
import bikeLocationImg from 'img/bike-location.png';

app.directive('viewBike', [function () {
    return {
        templateUrl: "template/dashboard/bikes/view-bike/view-bike.html",
        scope: {
            type: '=viewBike'
        },
        link: function ($scope, $element, $attrs, ngModel) {
        },
        controller: ['$scope', 'CustomerBikeResource', '$routeParams', 'ModalService', '$timeout', 'MetaInfo', '$bikeService',
            '$location', 'Notification', '$bikeComponentService', 'SaleCertificateService', 'User', 'ToggleLD',
            function ($scope, CustomerBikeResource, $routeParams, ModalService, $timeout, MetaInfo, $bikeService,
                      $location, Notification, $bikeComponentService, SaleCertificateService, User, ToggleLD) {

                if ($scope.type === 'public') {
                    $scope.title = 'Public bicycle profile';
                }

                var vb = {
                    resource: $bikeService.getResourceClass()
                };

                $scope.showBike = {
                    map: {
                        scale: false
                    }
                };

                if ($scope.type !== 'public') {
                    $scope.showBike.id = $routeParams.bikeId;
                }
                $scope.bikeHistory = {
                    id: $routeParams.bikeId
                };

                $scope.showBike.getWheelSizesString = function (wheel) {
                    var result = "";
                    if (!wheel)
                        return result;

                    if (wheel.inch) {
                        result += wheel.inch;
                        if (wheel.iso || wheel.bicycleStandard) {
                            result += " / ";
                        }
                    }
                    if (wheel.iso) {
                        result += wheel.iso;
                        if (wheel.bicycleStandard) {
                            result += " / ";
                        }
                    }
                    if (wheel.bicycleStandard) {
                        result += wheel.bicycleStandard;
                    }

                    return result;
                };

                $scope.positionImages = {};

                $scope.showPicture = function ($event, index) {
                    var getImages = function () {
                        return $scope.bike.images;
                    };
                    ModalService.showPicture($event, getImages, index, null, null);
                };

                $scope.showBike.initMap = function () {
                    $timeout(function () {
                        if ($scope.showBike.map.init && $scope.bike.fixLocation) {
                            $scope.showBike.map.clear();
                            $scope.showBike.map.addMarker($scope.bike.fixLocation.latitude,
                                $scope.bike.fixLocation.longitude, bikeLocationImg);
                        }
                    }, 100);
                };

                $scope.refresh = function () {
                    if ($scope.type === 'public') {
                        return CustomerBikeResource.getPublic({id: $routeParams.bikeId}, function (data) {
                            $scope.showBike.id = data.id;
                            setBike(data);
                        }, function () {
                            Notification.error("Bicycle hasn't been found");
                            $location.path("/").search({});
                        }).$promise;
                    } else {
                        return vb.resource.get({id: $scope.showBike.id}, function (data) {
                            setBike(data);
                        }).$promise;
                    }
                };

                function setBike(bike) {
                    $scope.bike = bike;
                    $scope.isSaleCertificate = bike.totalCondition;
                    $scope.bike.components = $bikeComponentService.transformComponents(bike);
                    var thumbnail;
                    if ($scope.bike.images && $scope.bike.images.length > 0) {
                        $scope.showBike.avatar = $scope.bike.images[0].thumbnail;
                        thumbnail = $scope.bike.images[0].thumbnail;
                    } else {
                        $scope.showBike.avatar = defaultBikeImg;
                    }
                    if (!$scope.bike.colors) {
                        $scope.bike.colors = [];
                    }

                    $scope.certification = SaleCertificateService.getCertificationInfo($scope.bike.totalCondition);

                    if (!$scope.bike.userId) {
                        return;
                    }
                }

                $scope.getColorsClass = function (colors) {
                    if (colors && colors.length === 1 && colors[0] === 'black') {
                        return 'color-border';
                    }
                    return '';
                };

                $scope.getImagesWidth = function () {
                    return {
                        width: ($scope.bike.images.length * 100) + 'px'
                    };
                };

                $scope.getStatusClass = function () {
                    if ($scope.bike && $scope.bike.status) {
                        return $scope.bike.status.toLowerCase();
                    } else {
                        return '';
                    }
                };

                $scope.onTabSelected = function (tab) {
                    var searchParams = $location.search();
                    if ($routeParams.history) {
                        delete searchParams.history;
                    }
                    if ($routeParams.info) {
                        delete searchParams.info;
                    }
                    searchParams[tab] = true;
                    $location.search(searchParams);
                    $location.replace();
                };

                $scope.changeIndexTab = function () {
                    if ($routeParams.info) {
                        $scope.selectedTab = 0;
                        $scope.title = 'Bicycle info';
                        $scope.showBike.initMap();
                    } else if ($routeParams.history) {
                        $scope.selectedTab = 1;
                        $scope.title = 'History';
                    } else {
                        $scope.selectedTab = 0;
                        $scope.title = 'Bicycle Info';
                        $scope.showBike.initMap();
                    }
                };


                $scope.$on('$routeChangeSuccess', function () {
                    $scope.changeIndexTab();
                });

                $scope.isCanEditComponent = function () {
                    return false;
                };

                (function init() {
                    ToggleLD.show();
                    $scope.refresh().finally(function () {
                        $scope.showBike.loaded = true;
                        ToggleLD.hide();
                    });
                    $scope.changeIndexTab();
                })();

            }
        ]
    };
}]);
