import app from "js/legacy-app";

app.directive('dpMinDate', function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, elem, attr, ctrl) {
            var minDpValidator = function (value) {
                var min = new Date(attr.dpMinDate);
                var newValue = new Date(value);
                if (newValue && newValue.getTime() < min.getTime()) {
                    ctrl.$setValidity('dpMinDate', false);
                    return value;
                } else {
                    ctrl.$setValidity('dpMinDate', true);
                    return value;
                }
            };

            ctrl.$parsers.push(minDpValidator);
            ctrl.$formatters.push(minDpValidator);
        }
    };
});
