import app from "js/legacy-app";

app.directive('returnLabelStep', function (Documents) {
    'ngInject';

    return {
        templateUrl: 'components/request/create/steps/return-label-step.html',
        controller: function ($scope) {
            'ngInject';

            $scope.returnLabelStep = {
                options: {
                    id: "returnLabel",
                    position: 5,
                    title: "Upload return label",
                    back: function () {
                        $scope.wizard.back();
                    },

                    next: function () {
                        $scope.wizard.next();
                    },

                    onLeave: function () {
                        if (!$scope.returnLabelStep.imageContainer.images || !$scope.returnLabelStep.imageContainer.images.length) {
                            $scope.wizard.uncompleteStep("returnLabel");
                        }
                    }
                },

                documents: Documents.requestReturnLabel(),

                imageContainer: [],

                onUpload: () => {
                    $scope.wizard.showNextButton();
                },

                beforeUpload: () => {
                    $scope.wizard.hideNextButton();
                },

                isSupported: () => {
                    return !$scope.wizard.isDisabled($scope.returnLabelStep.options.step)
                        && $scope.returnLabelStep.imageContainer.images
                        && $scope.returnLabelStep.imageContainer.images[0]
                        && $scope.returnLabelStep.imageContainer.images[0].id
                }
            };
        }
    }
});