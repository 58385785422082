import angular from "angular";
import mechanicDefaultImage from "img/mechanic-default-picture.png";
import "./mechanic-card.scss";

const mechanicImages = require.context('img/icons/mechanic', true);

const mechanicCard = {
    template: require('./mechanic-card.html'),
    transclude: {
        actions: "?vuiMechanicCardActions",
        footerDetails: "?vuiMechanicCardFooterDetails",
    },
    bindings: {
        model: '<vuiMcModel',
        size: '@?vuiMcSize',
        noMechanicText: '@?vuiMcNoMechanicText'
    },
    controller: function () {

        this.mechanicDefaultImage = mechanicDefaultImage;

        this.getMechanicCategoryIcon = (category) => {
            if (!category) {
                return;
            }

            return mechanicImages('./' + category.toLowerCase() + '-type.png', true);
        };
    }
};

export default angular.module("vui-mechanic-card", [])
    .component('vuiMechanicCard', mechanicCard);
