import app from "js/legacy-app";

app.controller('AnalyticsCtrl', ['$scope', '$location', function ($scope, $location) {

    $scope.href = function (segment) {
        $location.url('/admin/analytics/' + segment);
    };

}]);

