import angular from "angular";
import actions from "./simple-bike-form-actions";

const component = {
    template: require("./simple-bike-form.html"),
    transclude: {
        actions: '?vuiSimpleBikeFormActions'
    },
    bindings: {
        onSubmit: '&?vuiSbfOnSubmit'
    },
    controller: function (Brand, $typeOfBikesService, $bikeService) {
        'ngInject';

        this.$onInit = () => {
            const ctrl = this;

            $bikeService.loadBikeDescDependencies(function () {
                ctrl.types = $typeOfBikesService.getAll();
            });
        };

        this.getBrands = (brand) => {
            return Brand.autocomplete({name: brand, page: 0, size: 10}).$promise;
        };

        this.submit = () => {
            if (this.simpleBikeForm.$valid && angular.isFunction(this.onSubmit)) {
                this.onSubmit({bike: this.getBike()});
            }
        };

        this.getBike = () => {
            return {
                make: this.make,
                model: this.model,
                type: this.type.name,
                serialNumber: this.serialNumber,
                id: 'id' + (new Date()).getTime()
            }
        };
    }

};

export default angular.module("vui-simple-bike-form.module", [actions.name])
    .component("vuiSimpleBikeForm", component);