import app from "js/legacy-app";
import angular from "angular";

app.controller('InsuranceSettingsCtrl', ['$scope', 'NotificationWSService', 'AuthService',
    function ($scope, NotificationWSService, AuthService) {

        $scope.$parent.selectedTab = 4;
        $scope.$parent.title = 'Insurance';

        var isc = {};

        isc.readNewNotification = function (message) {
            if (!message.data || !message.data.metadata || message.data.type !== 'EXPIRATION_DATE_INSURANCE') {
                return;
            }

            if (!message.data.read) {
                NotificationWSService.readNotification(message.data.id, AuthService.getToken());
            }
        };

        isc.readNotifications = function () {
            NotificationWSService.send({
                operation: 2,
                type: 'EXPIRATION_DATE_INSURANCE',
                token: AuthService.getToken().access_token
            });
        };

        (function init() {
            angular.element(document).ready(function () {
                NotificationWSService.registerHandler("NEW", "insurance-setting", isc.readNewNotification);
                isc.readNotifications();
            });
            $scope.$on("$destroy", function () {
                NotificationWSService.unregisterHandler("NEW", "insurance-setting");
            });
        })();

    }]);
