import app from "js/legacy-app";

app.service('Quiz', ['$resource', function($resource){
    return $resource('/api/quiz/:id/:userId', {}, {
        update: {
            method: "PUT",
            params: {id : ""},
            isArray: false
        },
        search: {
            method: "POST",
            isArray: false,
            params: {id: "search"}
        },
        getQuiz: {
            method: "GET",
            isArray: true,
            params: {id: "get-quiz"}
        },
        pass: {
            method: "POST",
            isArray: false,
            params: {id: "pass"}
        },
        getUserAnswers: {
            method: "GET",
            isArray: false,
            params: {id: "get-user-answers"}
        }
    });
}]);