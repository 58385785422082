import angular from "angular";
import serviceDetailsActions from "./booking-service-details-actions"

const component = {
    template: require('./booking-service-details-card.html'),
    bindings: {
        onSubmit: "&?vuiBsdOnSubmit",
        model: "<vuiBsdService",
        options: "<vuiBsdOptions"
    },
    transclude: {
        actions: 'vuiBookingServiceDetailsActions'
    },
    controller: function () {
        const ctrl = this;

        ctrl.submitForm = function (form) {
            form.$setSubmitted();
            if (form.$valid && angular.isFunction(ctrl.onSubmit)) {
                ctrl.onSubmit({serviceDetails: ctrl.model});
            }
        };

        let previousService = undefined;

        ctrl.$onInit = () => {
            previousService = angular.copy(this.model);
            this.options = this.options || {
                totalPriceFactor: 1
            };
        };

        ctrl.onChange = () => {
            this.model = {
                name: this.model.name,
                price: this.model.price,
                timeToComplete: this.model.timeToComplete
            }
        };

        ctrl.$doCheck = () => {
            if (this.bookingDetailsForm && this.bookingDetailsForm.$valid && !angular.equals(this.model, previousService)) {
                ctrl.submitForm(this.bookingDetailsForm);
                previousService = angular.copy(this.model);
            }
        };

    }
};

export default angular.module("vui-booking-service-details.module", [serviceDetailsActions.name])
    .component('vuiBookingServiceDetails', component);
