import app from "js/legacy-app";

app.controller('EditOrganisationCtrl', ['$scope', 'Organisation', '$routeParams', '$location',
    'Notification', 'ModalService', 'ToggleLD', '$rootScope', 'ErrorHandler', 'LocationService',
    function ($scope, Organisation, $routeParams, $location, Notification, ModalService,
              ToggleLD, $rootScope, ErrorHandler, LocationService) {

        $scope.organisation = {

            organisationId: $routeParams.organisationId,

            getId: function () {
                return $scope.organisation.organisationId;
            },

            set: function (organisation) {
                $scope.organisation.id = organisation.id;
                $scope.organisation.name = organisation.name;
                $scope.organisation.address = organisation.address;
                $scope.organisation.addressQuery = (organisation.address) ? organisation.address.address : null;
                $scope.organisation.image = organisation.image;
                $scope.organisation.role = organisation.role;
                $scope.organisation.creatorId = organisation.creatorId;
                $scope.organisation.link = organisation.link;
                $scope.organisation.user = organisation.user;
                $scope.organisation.user.phone = organisation.user.phone || "+1";
                $scope.organisation.serviceBucket = organisation.serviceBucket.replace("_", " ");

                $rootScope.currentOrganisation = this.newOrganisation(organisation);
                $scope.organisation.user.fullName =
                    $scope.organisation.user.name + ' ' + $scope.organisation.user.surname;
            },

            validAddress: function (address) {
                if (!address || !address.address) {
                    return true;
                }
                var error = LocationService.validateProfileAddress(address);
                if (error) {
                    Notification.error(error);
                    return false;
                }

                return true;
            },

            update: function (form) {
                if (form.$valid) {
                    if (!this.validAddress($scope.organisation.address)) {
                        return;
                    }

                    Organisation.patch({param1: $scope.organisation.getId()}, this.get(), function (data) {
                        $scope.organisation.set(data);
                        Notification.success('Organisation has been successfully changed');
                    }, function (error) {
                        ErrorHandler.onError(error, "Failed to update organisation");
                    });
                }
            },

            refresh: function () {
                Organisation.get({param1: $scope.organisation.getId()}, function (data) {
                    $scope.organisation.set(data);
                }, function (error) {
                    ErrorHandler.onError(error, "Failed to load organisation");
                    $location.path('/dashboard').search({});
                });
            },

            get: function () {
                var obj = {
                    name: $scope.organisation.name,
                    link: $scope.organisation.link,
                    address: LocationService.mapAddress($scope.organisation.address)
                };

                return obj;
            },

            newOrganisation: function (organisation) {
                return {
                    id: organisation.id,
                    name: organisation.name,
                    address: organisation.address,
                    image: organisation.image,
                    role: organisation.role,
                    creatorId: organisation.creatorId,
                    link: organisation.link,
                    user: organisation.user
                };
            },

            changeAvatar: function ($event) {
                ModalService.chooseUserAvatar($event, $scope.organisation, 'organisation', null,
                    function (response) {
                        if (response) {
                            $scope.organisation.image = response.image;
                        }
                    });
            },

            clearAvatar: function () {
                ToggleLD.show();
                Organisation.clearAvatar({param2: $scope.organisation.organisationId}, function (data) {
                    ToggleLD.hide();
                    $scope.organisation.image = null;
                    Notification.success('Avatar was successfully removed');
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error, "Failed to remove avatar");
                });
            }
        };

        $scope.organisation.refresh();
    }
]);
