/*
 import app from "js/legacy-app";
 import angular from "angular";

 app.service('$i18n', [function () {

    var CURRENT_LOCALE = 'en-001';

    function i18nDynamicLoader(locale) {
        var imported = document.createElement('script');
        var fileImport = 'angular-locale_' + locale + '.js';
        imported.src = 'dist/js/i18n/' + fileImport;
        document.head.appendChild(imported);
    }

    i18nDynamicLoader(CURRENT_LOCALE);

 }]);*/