import angular from "angular";

const component = {
    template: require("./user-autocomplete.html"),
    bindings: {
        onSelect: '&?vuiUaOnSelect',
        filter: '<?vuiUaFilter'
    },
    controller: function (User, $q) {
        'ngInject';

        this.$onInit = () => {
            this.filter = this.filter || {};
        };

        this.select = (user) => {
            if (angular.isFunction(this.onSelect)) {
                this.onSelect({user: user});
            }
        };

        this.search = () => {
            let deferred = $q.defer();
            User.findByNameLike(this.filter, function (response) {
                deferred.resolve(response.items);
            }, function (error) {
                deferred.reject(error);
            });
            return deferred.promise;
        };
    }

};

export default angular.module("vui-user-autocomplete.module", [])
    .component("vuiUserAutocomplete", component);