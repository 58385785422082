import app from "js/legacy-app";

app.directive('detailedLocationAutocomplete', [function () {
    return {
        restrict: 'E',
        transclude: true,
        replace: true,
        templateUrl: 'template/component/geo/detailed-location-autocomplete.html',
        scope: {
            model: '=dlaModel',
            label: '@dlaLabel',
            detectCurrentLocation: '=?dlaDetectCurrentLocation',
            query: '=?dlaQuery',
            required: '=?dlaRequired',
            queryMinLength: '=?dlaQueryMinLength',
            restrictions: '=?dlaRestrictions',
            types: '@?dlaTypes'
        },
        link: function ($scope, element, $attrs) {
            $scope.dla = {};

            $scope.$watch('dla.apartment', function () {
                var model = $scope.model;
                if (!model) {
                    return;
                }


                $scope.model.apartment = $scope.dla.apartment;
            });

            $scope.$watch('model', function () {
                if (!$scope.model) {
                    return;
                }

                $scope.dla.apartment = $scope.model.apartment;
            });

            function init() {
                if ($scope.model) {
                    $scope.dla.apartment = $scope.model.apartment;
                }

                if (!$scope.queryMinLength) {
                    $scope.queryMinLength = 0;
                }
            }

            init();
        }
    };
}]);
