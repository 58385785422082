import app from "js/legacy-app";

app.controller('ManageQuizCtrl', ['$scope', 'Quiz', '$q', '$location', 'ModalService', function ($scope, Quiz, $q, $location, ModalService) {

    var countChecked = function() {
        if (!$scope.initDataTable.getData) {
            return;
        }
        var count = 0,
            data =  $scope.initDataTable.getData();
        for (var i in data) {
            if (data[i].selected) {
                count++;
            }
        }

        return count;
    };

    var update = function() {
        var id,
            data =  $scope.initDataTable.getData();

        for (var i in data) {
            if (data[i].selected) {
                id = data[i].id;
            }
        }

        if (id) {
            $location.url('/admin/quiz/' + id);
        }
    };

    var remove = function() {
        var message = 'Are you sure you want to remove this quiz?';
        ModalService.yesNoMessage(null, '', message, function(response) {
            if (response === true) {
                var promises = [],
                    data = $scope.initDataTable.getData();
                for (var i in data) {
                    if (data[i].selected) {
                        promises.push(Quiz.delete({id: data[i].id}).$promise);
                    }
                }

                $q.all(promises).finally($scope.initDataTable.refresh);
            }
        });
    };

    $scope.initDataTable = {
        service: Quiz,
        selected: true,
        name: 'admin-manage-quiz-list',
        showFilters: true,
        columns: [
            {
                name: 'question',
                columnName: 'Question',
                getValue: function(value) {
                    return '<div style="white-space: nowrap;text-overflow: ellipsis;overflow: hidden;" title="' + value + '">' +
                        value + '</div>';
                },
                show: true,
                sort: true,
                filterColumnName: 'question',
                filter: 'string',
                style: {
                    'max-width': '250px'
                }
            },
            {
                name: 'type',
                columnName: 'Type',
                show: true,
                sort: true,
                filterColumnName: 'type',
                filter: 'string'
            },
            {
                name: 'answers',
                columnName: 'Count answer',
                getValue: function(value) {
                    return  value.length;
                },
                show: true
            }
        ],
        actions: [
            {
                html: '<i class="fa fa-plus" aria-hidden="true"></i>',
                title: 'Add',
                apply: function (data, refrash) {
                    $location.path('/admin/quiz/create');
                },
                disable: function () {
                    return false;
                }
            },
            {
                html: '<i class="fa fa-pencil" aria-hidden="true"></i>',
                title: 'Edit',
                apply: update
            },
            {
                html: '<i class="fa fa-times" aria-hidden="true"></i>',
                title: 'Remove',
                apply: remove,
                disable: function () {
                    return countChecked() === 0;
                }
            }
        ]
    };

}]);