import angular from "angular";
import autocompleteDialog from "../../../service-autocomplete/dialog"

const searchAction = {
    template: require('./booking-service-details-search-action.html'),
    bindings: {
        filter: '<?vuiSaFilter'
    },
    require: {
        parent: "^vuiBookingServiceDetails"
    },
    controller: function ($mdDialog) {
        'ngInject';

        this.search = (event) => {
            const ctrl = this;

            $mdDialog.show({
                targetEvent: event,
                template: `<vui-service-autocomplete-dialog vui-sad-filter="$ctrl.filter"></vui-service-autocomplete-dialog>`,
                autoWrap: false,
                locals: {
                    filter: ctrl.filter
                },
                controllerAs: '$ctrl',
                bindToController: true,
                controller: function () {
                }
            }).then((service) => {
                if (!service) {
                    return;
                }

                this.parent.model = service;
                this.parent.submitForm(this.parent.bookingDetailsForm);
            });
        };
    }
};

export default angular.module("vui-booking-service-details-actions.module", [autocompleteDialog.name])
    .component("vuiBsdSearchAction", searchAction);