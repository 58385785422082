import app from "js/legacy-app";

app.directive('vtPageContainer', ['AuthService', function (AuthService) {
    return {
        restriction: "A",
        link: function (scope) {
            scope.auth = AuthService;

            scope.menuOffsetClass = function () {
                if (AuthService.isFullyLoggedIn() && !scope.noHeader) {
                    return "menu-offset";
                }
            };
        }
    };
}]);

