import app from "js/legacy-app";

app.controller('OrganisationProfileCtrl',
    ['$scope', 'ToggleLD', 'Organisation', 'ModalService', 'LocationService', 'Notification', '$location', 'ErrorHandler',
        function ($scope, ToggleLD, Organisation, ModalService, LocationService, Notification, $location, ErrorHandler) {

            $scope.$parent.selectedTab = 5;
            $scope.$parent.title = 'Organisation';

            $scope.eoc = {
                get: function () {
                    return {
                        name: $scope.eoc.organisation.name,
                        link: $scope.eoc.organisation.link,
                        address: LocationService.mapAddress($scope.eoc.organisation.address)
                    };
                },

                getId: function () {
                    return $scope.eoc.organisation.id;
                },

                validAddress: function (address) {
                    var error = LocationService.validateProfileAddress(address);
                    if (error) {
                        Notification.error(error);
                        return false;
                    }

                    return true;
                }
            };

            $scope.eoc.update = function (form) {
                if (form.$valid) {

                    if (!$scope.eoc.validAddress($scope.eoc.organisation.address)) {
                        return;
                    }

                    Organisation.patch({param1: $scope.eoc.getId()}, $scope.eoc.get(), function (data) {
                        $scope.eoc.organisation = data;
                        Notification.success('Organisation has been successfully changed');
                    }, function (error) {
                        ErrorHandler.onError(error, "Failed to update organisation");
                    });
                }
            };

            $scope.eoc.changeAvatar = function ($event) {
                ModalService.chooseUserAvatar($event, $scope.eoc.organisation, 'organisation', null,
                    function (response) {
                        if (response) {
                            $scope.eoc.organisation.image = response.image;
                        }
                    });
            };

            $scope.eoc.clearAvatar = function () {
                ToggleLD.show();
                Organisation.clearAvatar({param2: $scope.eoc.organisation.id}, function (data) {
                    ToggleLD.hide();
                    $scope.eoc.organisation.image = null;
                    Notification.success('Avatar was successfully cleared');
                }, function (error) {
                    ToggleLD.hide();
                    ErrorHandler.onError(error, "Failed to clear avatar");
                });
            };

            function init() {
                Organisation.getCurrent(function (data) {
                    $scope.eoc.organisation = data;
                    $scope.eoc.address = data.address.address;
                }, function (error) {
                    $location.path('/dashboard').search({});
                });
            }

            init();

        }
    ]);