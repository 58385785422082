import './landing-page-info-section.component.scss';

export default {
    template: require('./landing-page-info-section.component.html'),
    bindings: {
        headerPosition: "@?isHeaderPosition",
        bodyContent: "@?isBodyContent"
    },
    transclude: {
        header: "infoSectionHeader",
        body: "infoSectionBody"
    },
    controller: function () {
        'ngInject';

        this.bodyContentClass = this.bodyContent === 'center' ? 'is-content-center' : '';

        switch (this.headerPosition) {
            case 'left':
                this.headerClass = 'is-header-left';
                break;
            case 'right':
                this.headerClass = 'is-header-right';
                break;
            case 'center':
                this.headerClass = 'is-header-center';
                break;
            default:
                this.headerClass = '';
        }
    }
};
