import app from "js/legacy-app";

app.directive('blImport', ['ModalService', '$routeParams', function (ModalService, $routeParams) {
    return {
        templateUrl: "components/bike/list/business/bl-import.html",
        replace: true,
        require: '^^pageableList',
        transclude: true,
        link: function ($scope, $elem, $attrs, plCtrl) {

            $scope.bli = {};

            $scope.bli.import = function ($event) {
                var request = {organisationId: $routeParams.organisation, stationId: $routeParams.station};
                ModalService.importBikes($event, request, function (response) {
                    if (response) {
                        if (response.bikes && response.bikes.length > 0) {
                            ModalService.showRepeatedBikes($event, response.bikes);
                        }
                        plCtrl.pl.refreshPage();
                    }
                });
            };
        }
    };
}]);