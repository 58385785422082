import app from "js/legacy-app";

app.controller('AvailabilityDialogCtrl', [
    '$scope',
    '$mdDialog',
    'Notification',
    '$mdMedia',
    'dates',
    'weeklyAvailability',
    'vacationPeriod',
    'autoacceptBookingEnabled',
    'AuthService',
    '$availabilityDates',
    function ($scope, $mdDialog, Notification, $mdMedia, dates, weeklyAvailability, vacationPeriod, autoacceptBookingEnabled, AuthService, $availabilityDates) {

        $scope.adc = {
            availability: dates,
            weeklyAvailability: weeklyAvailability,
            vacationPeriod: vacationPeriod,
            autoacceptBookingEnabled: autoacceptBookingEnabled,
            mdMedia: $mdMedia
        };

        $scope.adc.cancel = function (event) {
            $mdDialog.hide();
        };

        $scope.adc.apply = function (event) {
            if (!$scope.adc.selectedTime || !$scope.adc.selectedTime.time) {
                Notification.warning("Please select time");
                return;
            }
            $mdDialog.hide($scope.adc.selectedTime);
        };

        $scope.adc.auth = AuthService;

        (function init() {
            $scope.adc.dates = $availabilityDates.generate(
                $scope.adc.availability,
                $scope.adc.weeklyAvailability,
                $scope.adc.vacationPeriod
            );
        })();
    }
]);