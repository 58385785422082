import app from "js/legacy-app";
import angular from "angular";

app.directive('vtFlex', ['$window', '$document', function ($window, $document) {
    return {
        replace: true,
        scope: {
            options: '=vtFlex',
            parentEl: '@?vtFlexParentSelector'
        },
        link: function (scope, elem) {
            var $win = angular.element($window);
            var parent = scope.parentEl ? $document[0].querySelector(scope.parentEl) : elem.parent()[0];
            var currentWidth;
            $win.bind('resize', onResize);
            elem.parent().css('display', 'flex');
            elem.parent().css('flex-wrap', 'wrap');

            var options = scope.options.sort(function (o1, o2) {
                return o2.parent < o1.parent;
            });

            function onResize() {
                var actualParentWidth = parent.clientWidth;
                var targetOptions = options.filter(function (o) {
                    return actualParentWidth <= o.parent;
                });

                if (actualParentWidth && targetOptions && targetOptions.length) {
                    var targetWidth = targetOptions[0].width;
                    if (currentWidth !== targetWidth) {
                        currentWidth = targetWidth;
                        elem.css('width', targetWidth);
                        elem.css('flex-basis', targetWidth);
                    }
                } else {
                    currentWidth = null;
                    elem.css("width", null);
                    elem.css("flex-basis", null);
                }
            }

            onResize();

            /**
             * Clean up after ourselves
             */
            scope.$on('$destroy', function () {
                $win.unbind('resize', onResize);
            });

        }
    };
}]);



