import app from "js/legacy-app";
import angular from 'angular';

app.directive('customServiceCategoryItem', ['$mdMedia', 'ErrorHandler', 'Notification',
    function ($mdMedia, ErrorHandler, Notification) {
        return {
            templateUrl: "components/service/item/custom-service-category-item.html",
            replace: true,
            transclude: true,
            scope: {
                model: '=mciModel',
                viewCategory: '=mciView',
                onRemoveCategory: '&mciOnRemove',
                readOnly: '<mciReadonly'
            },
            controller: ['$scope', 'CustomServiceCategories', 'ToggleLD', 'ModalService',
                function ($scope, CustomServiceCategories, ToggleLD, ModalService) {
                    $scope.mcic = {
                        mdMedia: $mdMedia
                    };

                    $scope.mcic.view = function (event) {
                        if (event.defaultPrevented) {
                            event.preventDefault();
                            return;
                        }
                        $scope.viewCategory($scope.model.id);
                    };

                    $scope.mcic.remove = function (event) {
                        var msg = `When you delete a category, all relevant services will be deleted. <br> <br> 
                            Are you sure you want to delete this category?`;
                        ModalService.yesNoMessage(event, null, msg, function (res) {
                            if (!res) return;

                            ToggleLD.show();
                            CustomServiceCategories.delete({id: $scope.model.id}, function () {
                                ToggleLD.hide();
                                if (angular.isFunction($scope.onRemoveCategory)) {
                                    $scope.onRemoveCategory({categoryId: $scope.model.id});
                                }
                                Notification.success("Service category has been deleted");
                            }, function (error) {
                                ToggleLD.hide();
                                ErrorHandler.onError(error);
                            });
                        });
                    };
                }]
        };
    }]);